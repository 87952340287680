import { Button, ButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { lightGreen, blue, green, lightBlue } from '@mui/material/colors';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

const ButtonAddDetail = styled(Button)<ButtonProps>(({ theme }) => ({
    color: theme.palette.getContrastText(lightGreen[700]),
    backgroundColor: green['A400'],
    width: "150px",
    fontSize: "18px",
    borderRadius: "60px",
    '&:hover': {
        backgroundColor: green['A700'],
    },
}));

const ButtonBlueRadius = styled(Button)<ButtonProps>(({ theme }) => ({
    color: theme.palette.getContrastText(blue[700]),
    backgroundColor: blue['A400'],
    width: "250px",
    height: "50px",
    fontSize: "20px",
    borderRadius: "60px",
    '&:hover': {
        backgroundColor: blue['A700'],
    },
}));

const StepLineColorConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: blue['A400'],
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: blue['A400'],
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
}));

const AccordionDetailsCustom = styled(MuiAccordionDetails)(({ theme }) => ({
    //padding: theme.spacing(2),
    //borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const AccordionCustom = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    borderRadius: "15px",
    '&:before': {
        display: 'none',
    },
    boxShadow: "0px 0px 17px 1px rgba(0,0,0,0.3)",
}));

const AccordionSummaryCustom = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor: lightBlue[200],
    color: "white",
    background: "linear-gradient(90deg, rgba(61,194,255,1) 0%, rgba(0,133,194,1) 100%)",
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
    borderRadius: "50px"
}));

export { ButtonAddDetail, ButtonBlueRadius, StepLineColorConnector, AccordionSummaryCustom, AccordionCustom, AccordionDetailsCustom }