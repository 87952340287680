import MyJobApply from "components/MyJob/MyJobApply";

const MyJobApplied = () => {
  return (
    <>
      <MyJobApply />
    </>
  );
};

export default MyJobApplied;
