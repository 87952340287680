import Layout_Font from "layout/CommonLayout/Layout_Font";
import Company from "view/font/Company/Company";
import CompanyAd from "view/font/Company/CompanyAd";

const CompanyRoute = [
  {
    path: "/Guest/Company",
    component: Company,
    layout: Layout_Font,
    exact: true,
  },
  {
    path: "/Guest/CompanyAd",
    component: CompanyAd,
    layout: Layout_Font,
    exact: true,
  },
];

export default CompanyRoute;
