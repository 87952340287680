import Layout_Font from "layout/CommonLayout/Layout_Font";
import RegisterEmployer from "view/font/RegisterEmployer/RegisterEmployerForm";

const Register = [
  {
    path: "/RegisterEmployer",
    component: RegisterEmployer,
    layout: Layout_Font,
    exact: true,
  },
];

export default Register;