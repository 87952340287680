
import { useState, useEffect, useContext } from 'react';
import { FormProvider } from 'react-hook-form';
import { Grid, Typography, Box, Paper, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { i18n } from 'i18n';
import Input from 'components/Common/ElementInForm/InputCustomLabelFormItem';
import AutoCompleteSelect from 'components/Common/ElementInForm/AutoCompleteSelect';
import { ProfileContext } from './Context/ProfileContext';
import { IPorpsEducationHistory } from './Interface';
import { DialogActionCreators } from "store/redux/DialogAlert";
import { useDispatch } from "react-redux";
import DatePickerFormItem from 'components/Common/ElementInForm/DatePickerFormItem';
import moment from 'moment';


const i18nField = 'entities.SeekerProfile';
const i18nCommon = 'common';
const EducationHistory = (props: IPorpsEducationHistory) => {
    const { form, index, item } = props;
    const dispatch = useDispatch();

    const { educationContext, setEducation, optionEducationLevel, optionUniversity } = useContext(ProfileContext);

    const [isClean, setIsClean] = useState(false);

    const handleSetData = (itemIndex) => {
        educationContext.forEach((data) => {
            if (data.Index === itemIndex) {
                form.setValue("Academy" + data.Index, data.Academy || '');
                form.setValue("EducateLevel" + data.Index, data.EducateLevel || '');
                form.setValue("University" + data.Index, data.University || '');
                form.setValue("Faculty" + data.Index, data.Faculty || '');
                form.setValue("Major" + data.Index, data.Major || '');
                form.setValue("GraduationYear" + data.Index, data.GraduationYear || '');
                form.setValue("RemarkEdu" + data.Index, data.EduRemark || '');
            }
        });

        setIsClean(false);
    }

    const handleChangeValue = (form, index) => {
        let newData = educationContext.map((item) => {
            if (item.Index === index) {
                // item.Academy = form.getValues("Academy" + index);
                item.Academy = form.getValues("University" + index);
                item.EducateLevel = form.getValues("EducateLevel" + index);
                item.University = form.getValues("University" + index);
                item.Faculty = form.getValues("Faculty" + index);
                item.Major = form.getValues("Major" + index);
                item.GraduationYear = form.getValues("GraduationYear" + index);
                item.EduRemark = form.getValues("RemarkEdu" + index);
            }
            return item;
        });

        setEducation(newData);
    }

    const handleRemoveEducation = (itemIndex: number) => {
        dispatch(DialogActionCreators.OpenDialogSubmit(i18n(`${i18nCommon}.AlertConfrimDel`), () => {
            dispatch(DialogActionCreators.CloseDialogSubmit() as any);
            dispatch(DialogActionCreators.LoadSubmit("Waiting...") as any);
            setIsClean(true);
            let newData = educationContext.filter((data) => { return data.Index !== itemIndex });
            setEducation(newData);
            form.unregister("Academy" + itemIndex);
            form.unregister("EducateLevel" + itemIndex);
            form.unregister("University" + itemIndex);
            form.unregister("Faculty" + itemIndex);
            form.unregister("Major" + itemIndex);
            form.unregister("GraduationYear" + itemIndex);
            form.unregister("RemarkEdu" + itemIndex);
        }));
    }

    useEffect(() => {
        handleSetData(item.Index);
    }, [isClean]);

    useEffect(() => {
        handleSetData(item.Index);
    }, [educationContext])

    return (
        isClean ? null : (
            <FormProvider {...form} key={index}>
                <Box component={Paper} sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}>
                    {index === 0 ? null :
                        <IconButton aria-label="delete" color="error" onClick={() => handleRemoveEducation(item.Index)}>
                            <CloseIcon />
                        </IconButton>}
                    <Grid container justifyContent="center" alignItems="center" spacing={1}>
                        <Grid item container spacing={2} xs={12} >
                            <Grid item xs={12} sm={4} md={6}>
                                <AutoCompleteSelect
                                    name={"University" + item.Index}
                                    label={i18n(`${i18nField}.University`)}
                                    onChange={(value) => {
                                        handleChangeValue(form, item.Index);
                                    }}
                                    required
                                    freeSolo
                                    options={optionUniversity}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={6}>
                                <AutoCompleteSelect
                                    name={"EducateLevel" + item.Index}
                                    label={i18n(`${i18nField}.EducateLevel`)}
                                    onChange={(value) => {
                                        handleChangeValue(form, item.Index);
                                    }}
                                    required
                                    options={optionEducationLevel}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={5}>
                                <Input
                                    name={"Faculty" + item.Index}
                                    label={<Typography sx={{ fontWeight: 400 }} component="label">{i18n(`${i18nField}.Faculty`)}</Typography>}
                                    small={true}
                                    maxLength={300}
                                    onBlur={(value) => {
                                        handleChangeValue(form, item.Index);
                                    }}
                                // required
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={5}>
                                <Input
                                    name={"Major" + item.Index}
                                    label={<Typography sx={{ fontWeight: 400 }} component="label">{i18n(`${i18nField}.Major`)}</Typography>}
                                    small={true}
                                    maxLength={300}
                                    onBlur={(value) => {
                                        handleChangeValue(form, item.Index);
                                    }}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={2}>
                                <DatePickerFormItem
                                    name={"GraduationYear" + item.Index}
                                    label={<Typography sx={{ fontWeight: 400 }} component="label">{i18n(`${i18nField}.GraduationYear`)}</Typography>}
                                    small={true}
                                    views={["year"]}
                                    isEnOnly
                                    onChange={() => {
                                        handleChangeValue(form, item.Index);
                                    }}
                                    format="YYYY"
                                    maxDate={moment()}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Input
                                    name={"RemarkEdu" + item.Index}
                                    fullWidth
                                    multiline
                                    label={<Typography sx={{ fontWeight: 400 }} component="label">{i18n(`${i18nField}.EduRemark`)}</Typography>}
                                    rows={4}
                                    maxLength={3000}
                                    onBlur={(value) => {
                                        handleChangeValue(form, item.Index);
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </FormProvider>
        ))
}

export default EducationHistory;