import React, { useEffect, useState } from "react";
import Filter from "components/EmployerSearch/Filter"
import { Grid, CircularProgress, Pagination, Badge, Backdrop, SpeedDial, SpeedDialAction } from "@mui/material"
import CardItem from "components/EmployerSearch/CardItem";
import { AxiosPost } from "service/CommonFunction/TS_function";
import { FnBlock_UI } from "service/SystemFunction/SystemFunction";
import { useDispatch } from "react-redux";
import { DialogActionCreators } from "store/redux/DialogAlert";
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import ModalAddTag from "components/JobSeekerRegisJob/ModalAddTag";
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import { JobPostSentGroupStatus, TabFilterJobseekerCandidate } from "service/CommonInterface/CommonInterface";
import ModalInvite from "components/EmployerSearch/ModalInvite";
import ModalIntroduction from "components/JobSeekerRegisJob/ModalIntroduction";
import { getLanguage, i18n } from "i18n";
import ModalBuyProfile from "components/EmployerSearch/ModalBuyProfile";
import secureLocalStorage from "react-secure-storage";
import ModalDownloandProfile from "components/EmployerSearch/ModalDownload";

export default function JobSeekerRegisJob() {
    interface DataJobPostSelect {
        sJobSeekerId: string,
        sJobPostId: string,
        sRelationId: string,
        nTypeJobPostSentFormTable: number,
        sManageId: string
    }

    const dispatch = useDispatch();
    const [lstData, setLstData] = useState([]);
    
    const { BlockUI, UnBlockUI } = FnBlock_UI();
    const [isLoading, setLoading] = useState(false)
    const ControllerApi = "JobSeekerRegisJob";
    const [resultFilter, setResultFilter] = useState(null);
    const [isOpenModal, setIsOpenModal] = useState(false)
    const [isOpenModalInvite, setIsOpenModalInvite] = useState(false)
    const [isChangePage, setChangePage] = useState(false)
    const [sJsId, setJsId] = useState("");
    const [arrSelectJobPost, setSelectJobPost] = useState<DataJobPostSelect[]>([])
    const [valueTabs, setValueTabs] = useState<number>(0);
    const [isOpenAction, setOpenAction] = useState(false)
    const [isBookmarkTag, setBookmarkTag] = useState(false)
    const [sIntroductionView, setIntroductionView] = useState("");
    const [isOpenModalIntroduction, setIsOpenModalIntroduction] = useState(false)
    const i18nFieldTag = "JobSeekerRegisJob.ModalAddTag."
    const i18nFieldTab = "JobSeekerRegisJob.FilterTabs."
    const [isOpenModalBuyProfile, setIsOpenModalBuyProfile] = useState(false)
    const [isOpenModalDownload, setIsOpenModalDownload] = useState(false)
    const [objPaging, setPaging] = useState({
        nPage: 1,
        nPageSize: 10,
        nTotal: 0,
    });
    const onChangeTabs = (ele, v) => {
        setPaging({
            nPage: 1,
            nPageSize: 10,
            nTotal: 0,
        })
        setValueTabs(v)
        setChangePage(true)
        setSelectJobPost([])
        setOpenAction(false)
        setIsOpenModalInvite(false)
        setIsOpenModalIntroduction(false)
    }
    const getFilterOption = () => {
        let lang = "";
        let isTH = (getLanguage().id === "th");
        isTH ? lang = "th" : lang = "en"
        BlockUI();
        AxiosPost(`${ControllerApi}/GetFilterOption`, { lang }, (res) => {
            let objResult = res.Data;
            const getLangCode = secureLocalStorage.getItem("language").toString();
            for (const [key, value] of Object.entries(objResult)) {
                if (key == "lstAddr") {
                    objResult[key] = (value as any[] || []).sort((a, b) => a[`label_${getLangCode.toLocaleLowerCase()}`] - b[`label_${getLangCode.toLocaleLowerCase()}`])
                }
            }
            setResultFilter(objResult)
        }, (err) => {
            dispatch(DialogActionCreators.OpenDialogWarning(err.Message) as any)
        }, UnBlockUI)
    }
    useEffect(() => {
        getFilterOption();
    }, [])

    const onOpenModalTag = (isBookmark = false) => {
        setOpenAction(false);
        setIsOpenModal(true);
        setBookmarkTag(isBookmark)
    }

    const Download = () => {
        setIsOpenModalDownload(true);        
    }

    const onGroup = (nStatus) => {
        let nStatusForm = JobPostSentGroupStatus.WaitingProcess;
        if (valueTabs == TabFilterJobseekerCandidate.Allow_group) {
            nStatusForm = JobPostSentGroupStatus.Shortlist;
        } else if (valueTabs == TabFilterJobseekerCandidate.Not_pass) {
            nStatusForm = JobPostSentGroupStatus.NotSuitable;
        }

        dispatch(DialogActionCreators.OpenDialogSubmit(
            nStatus == JobPostSentGroupStatus.Shortlist ? "คุณต้องการจัดกลุ่มใช่หรือไม่ ?" :
                nStatus == JobPostSentGroupStatus.NotSuitable ? "คุณต้องการปฏิเสธใช่หรือไม่ ?" :
                    "คุณต้องการส่งไปรอตรวจสอบใช่หรือไม่", () => {
                        let objReq = {
                            nStatus: nStatus,
                            nReqFromStatus: nStatusForm,
                            arrJobPost: arrSelectJobPost
                        }
                        dispatch(DialogActionCreators.CloseDialogSubmit() as any)
                        dispatch(DialogActionCreators.LoadSubmit(true) as any)
                        AxiosPost(`${ControllerApi}/OnSentToGroup`, objReq, (res) => {
                            dispatch(DialogActionCreators.LoadSubmit(false) as any)
                            if (res.Data) {
                                dispatch(DialogActionCreators.OpenDialogSuccess(
                                    nStatus == JobPostSentGroupStatus.Shortlist ? "จัดกลุ่มสำเร็จ" :
                                        nStatus == JobPostSentGroupStatus.NotSuitable ? "ปฏิเสธสำเร็จ" :
                                            "ส่งไปรอตรวจสอบสำเร็จ", () => {
                                                onChangeTabs(null, valueTabs)
                                                setTimeout(() => {
                                                    setOpenAction(false)
                                                }, 300);
                                            }) as any)
                            } else {
                                dispatch(DialogActionCreators.OpenDialogWarning("พบข้อมผิดพลาดกรุณาลองใหม่อีกครั้ง", () => {
                                    setTimeout(() => {
                                        setOpenAction(false)
                                    }, 300);
                                }) as any)
                            }
                        }, (err) => {
                            dispatch(DialogActionCreators.LoadSubmit(false) as any)
                            dispatch(DialogActionCreators.OpenDialogWarning(err.Message, () => {
                                setTimeout(() => {
                                    setOpenAction(false)
                                }, 300);
                            }) as any)
                        })
                    }, () => {
                        setTimeout(() => {
                            setOpenAction(false)
                        }, 300);
                    }) as any)
    }

    const onCloseModal = () => {
        setIsOpenModal(false)
        setTimeout(() => {
            setOpenAction(false)
        }, 300);
    }
    //Download={() => {Download()}
    return (
        <>
            <ModalBuyProfile sJsId={sJsId} setJsId={() => setJsId("")} isOpen={isOpenModalBuyProfile} setOpen={setIsOpenModalBuyProfile} lstData={lstData} setLstData={setLstData} Download={Download} />
            <ModalInvite sJsId={sJsId} setJsId={() => setJsId("")} isOpen={isOpenModalInvite} setOpen={setIsOpenModalInvite} />
            <ModalIntroduction isOpen={isOpenModalIntroduction} sIntroduction={sIntroductionView} onClose={() => setIsOpenModalIntroduction(false)} />
            <ModalDownloandProfile sJsId={sJsId} setJsId={() => setJsId("")} isOpen={isOpenModalDownload} setOpen={setIsOpenModalDownload} lstData={lstData} setLstData={setLstData} /> 
            {valueTabs == TabFilterJobseekerCandidate.Allow_group && <ModalAddTag arrSelectJobPost={arrSelectJobPost}
                isBookmark={isBookmarkTag} isOpen={isOpenModal} onClose={() => {
                    onCloseModal()
                    if (!isBookmarkTag) {
                        setChangePage(true)
                    }
                }} />}
            {/* sm:!w-[calc(85%+24px)] */}
            <Grid
                container
                spacing={3}
                direction="column"
                sx={{ marginTop: "0.5%" }}
                className="p-[2em] rounded-[1em] bg-[white] min-h-[350px] shadow mb-[1em] "
            >
                <Filter objPaging={objPaging} setChangePage={setChangePage} onChangeTabs={onChangeTabs} valueTabs={valueTabs} isChangePage={isChangePage} setPaging={setPaging} setLstData={setLstData} setLoading={setLoading} dataFilter={resultFilter} />
                <div className="px-[1em] flex flex-col gap-[1em] mt-[1em]">
                    {!isLoading ? lstData.map((item, index) => {
                        return (
                            <CardItem arrSelectJobPost={arrSelectJobPost} setSelectJobPost={setSelectJobPost} valueTabs={valueTabs}
                                setJsId={setJsId} setIsOpenModal={() => setIsOpenModalInvite(true)} optionFilter={resultFilter}
                                setIntroductionView={(e) => setIntroductionView(e)} setIsOpenModalIntroduction={() => setIsOpenModalIntroduction(true)}
                                key={`card_item_${index}`} lstData={lstData} setLstData={setLstData} item={item}
                                setIsOpenModalBuyProfile={() => setIsOpenModalBuyProfile(true)} 
                                ReloadFunction={() => {
                                    setPaging({
                                        ...objPaging,
                                        nPage: 1,
                                    });
                                    setChangePage(true)
                                }}
                                 />
                        )
                    }) : (
                        <div className="flex flex-1 justify-center items-center">
                            <CircularProgress />
                        </div>
                    )}
                </div>
                {!isLoading && objPaging.nTotal > objPaging.nPageSize && (
                    <Grid container justifyContent={"center"} sx={{ marginTop: "3rem" }}>
                        <Grid item>
                            <Pagination
                                variant="outlined"
                                shape="rounded"
                                showFirstButton
                                showLastButton
                                count={Math.ceil(objPaging.nTotal / objPaging.nPageSize)}
                                page={objPaging.nPage}
                                onChange={(e, page) => {
                                    setPaging({ ...objPaging, nPage: page })
                                    setChangePage(true)
                                }}
                            />
                        </Grid>
                    </Grid>
                )}
            </Grid>

            <Backdrop open={isOpenAction} />
            <SpeedDial
                ariaLabel="action"
                className={`opacity-0 transition-[opacity] duration-[300ms] ${(arrSelectJobPost.length > 0 || valueTabs == TabFilterJobseekerCandidate.Allow_group) && "opacity-100"}`}
                sx={{
                    position: "fixed", bottom: 100, right: 10,
                    ".MuiSpeedDial-fab": {
                        backgroundColor: "#e60278", ":hover": {
                            backgroundColor: "#CA036A",
                        },
                        width: "80px", height: "80px",
                        // width: "40px", height: "40px",
                    }
                }}
                icon={
                    <Badge
                        badgeContent={arrSelectJobPost.length}
                        sx={{ ".MuiBadge-badge ": { zIndex: "9999 !important", right: "-7px !important", top: "-3px  !important", borderRadius: "20px", width: "30px", height: "30px", fontSize: "18px" } }}
                        color="secondary"
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                        }}
                    >
                        <EditIcon sx={{ width: "40px", height: "40px" }} />
                    </Badge>
                }
                onClose={() => setOpenAction(false)}
                onOpen={() => { if (arrSelectJobPost.length > 0 || valueTabs == TabFilterJobseekerCandidate.Allow_group) setOpenAction(true) }}
                open={isOpenAction}
            >
                {(valueTabs == TabFilterJobseekerCandidate.Allow_group && arrSelectJobPost.length > 0) &&
                    (
                        <SpeedDialAction
                            sx={{
                                backgroundColor: "#00bfa5", width: "80px", height: "80px",
                                ":hover": {
                                    backgroundColor: "#00bfa5",
                                }
                            }}
                            icon={<BookmarkAddedIcon sx={{ color: "white", width: "40px", height: "40px" }} />}
                            tooltipTitle={i18n(`${i18nFieldTag}sTag`)}
                            onClick={() => onOpenModalTag()}
                        />
                    )}
                {(valueTabs == TabFilterJobseekerCandidate.Allow_group) &&
                    (<SpeedDialAction
                        sx={{
                            backgroundColor: "#673ab7", width: "80px", height: "80px",
                            ":hover": {
                                backgroundColor: "#673ab7",
                            }
                        }}
                        icon={<BookmarkAddIcon sx={{ color: "white", width: "40px", height: "40px" }} />}
                        tooltipTitle={i18n(`${i18nFieldTag}sTagFavorite`)}
                        onClick={() => onOpenModalTag(true)}
                    />)}
                {(valueTabs == TabFilterJobseekerCandidate.Not_pass || (valueTabs == TabFilterJobseekerCandidate.Allow_group && arrSelectJobPost.length > 0)) &&
                    (<SpeedDialAction
                        sx={{
                            backgroundColor: "#ff7f00", width: "80px", height: "80px",
                            ":hover": {
                                backgroundColor: "#ff7f00",
                            }
                        }}
                        icon={<QuestionMarkIcon sx={{ color: "white", width: "40px", height: "40px" }} />}
                        tooltipTitle={i18n(`${i18nFieldTab}pending`)}
                        onClick={() => onGroup(JobPostSentGroupStatus.WaitingProcess)}
                    />)}
                {(valueTabs != TabFilterJobseekerCandidate.Allow_group) &&
                    (<SpeedDialAction
                        sx={{
                            backgroundColor: "#00C851", width: "80px", height: "80px",
                            ":hover": {
                                backgroundColor: "#00C851",
                            }
                        }}
                        icon={<CheckIcon sx={{ color: "white", width: "40px", height: "40px" }} />}
                        tooltipTitle={i18n(`${i18nFieldTab}shortlist`)}
                        onClick={() => onGroup(JobPostSentGroupStatus.Shortlist)}
                    />)}
                {(valueTabs != TabFilterJobseekerCandidate.Not_pass && arrSelectJobPost.length > 0) &&
                    (<SpeedDialAction
                        sx={{
                            backgroundColor: "#ff4444", width: "80px", height: "80px",
                            ":hover": {
                                backgroundColor: "#ff4444",
                            }
                        }}
                        icon={<CloseIcon sx={{ color: "white", width: "40px", height: "40px" }} />}
                        tooltipTitle={i18n(`${i18nFieldTab}not_suitable`)}
                        onClick={() => onGroup(JobPostSentGroupStatus.NotSuitable)}
                    />)}

            </SpeedDial>

            {/* <div className={`fixed z-[15] right-[17px] bottom-[100px] opacity-0 transition-[opacity] duration-[300ms] ${arrSelectJobPost.length > 0 && "opacity-100"}`}>
                <Badge
                    badgeContent={arrSelectJobPost.length}
                    sx={{ ".MuiBadge-badge ": { zIndex: "9999 !important", left: "2px !important", bottom: "2px  !important" } }}
                    color="secondary"
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <BtnCustomIcon sxCustom={{ backgroundColor: "rgb(255,193,7)" }} tooltipPlacement="left" isCircleWithOutText txt="Action" sIconComponent={
                        <EditIcon fontSize="medium" sx={{ color: "" }} />
                    } onClick={() => console.log("first")} />
                </Badge>
            </div> */}
        </>
    )
}
