import yupFormSchemas from 'components/Common/yup/yupFormSchemas';
import * as yup from "yup";
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Stack, Typography } from '@mui/material';
import AutoCompleteSelect from 'components/Common/ElementInForm/AutoCompleteSelect';
import { AxiosGet, AxiosPost, GetPermission, ResultAPI } from 'service/CommonFunction/TS_function';
import DatePickerFormItem from 'components/Common/ElementInForm/DatePickerFormItem';
import moment from 'moment';
import Input from "components/Common/ElementInForm/InputCustomLabelFormItem";
import { BtnBack, BtnCancel, BtnSave } from 'components/Common/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import { DialogActionCreators } from 'store/redux/DialogAlert';
import { FnBlock_UI } from 'service/SystemFunction/SystemFunction';
import { useDispatch } from 'react-redux';
import LogTablePausePackage from './LogTablePausePackage';
import secureLocalStorage from 'react-secure-storage';


const HoldPackage = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const searchParams = location.search;
    const sID = new URLSearchParams(searchParams).get('sID');
    const isEditMode = sID ? true : false;
    const [nPermission, setPermission] = useState(0);
    const [StartDate, setStartDate] = useState(moment());
    const history = useNavigate();
    const onBack = () => { history("/AdminPausePackage"); }
    const [arrEmployer, setarrEmployer] = useState([]);
    const [lstStatus, setlstStatus] = useState([]);
    const [arrOrder, setarrOrder] = useState([]);
    const [nOrder, setOrder] = useState(0);
    const [nStatus, setStatus] = useState(0);
    const [isCancle, setCancle] = useState(false);
    const [StartPackage, setStartPackage] = useState(moment(new Date()));
    const [EndDate, setEndDate] = useState(moment(new Date()));
    const isPausePackage = new URLSearchParams(searchParams).get("sMode") === "7";
    const objSchema = {
        sCompany_ID: yupFormSchemas.object("ชื่อผู้ประกอบการ", { required: true, isAdmin: true }),
        sOrder_ID: yupFormSchemas.object("ข้อมูลแพ็กเกจ", { required: true, isAdmin: true }),
        dStart_Date: yupFormSchemas.string("วันเริ่มระงับใช้งาน", { required: true, isAdmin: true }),
        sRemark: yupFormSchemas.string("เหตุผลการระงับใช้งาน", { required: true, isAdmin: true }),
        // sNote_Cancel: yupFormSchemas.string("เหตุผลการยกเลิก", { required: isCancle, isAdmin: true }),
    }

    const schema = yup.object().shape(objSchema);
    const form = useForm({
        resolver: yupResolver(schema),
        shouldUnregister: false,
        shouldFocusError: true,
        mode: "all",
    });

    useEffect(() => {
        GetPermission(54, setPermission);
        OnGetData();
        onGetDetail();
        OnGetSatus();
    }, []);

    useEffect(() => {
        if (!secureLocalStorage.getItem("sUserId")) {
            if (isPausePackage) {
                history(`/AdminLogin?sID=${encodeURI(sID)}&sMode=7`);
            } 
        }
    })

    const OnGetData = () => {
        AxiosGet("FromBanner/GetData_DropDown", {}, (result) => {         
            setarrEmployer(result.lstCompany || [])
        });
    }
    const OnGetSatus = () => {
        AxiosGet("HoldPackage/GetData_DropDown", {}, (result) => {        
            setlstStatus(result.lstStatus || [])
        });
    }
    //lstStatus
    const OnGetDataOrder = () => {
        let nID = (form.getValues("sCompany_ID").value)
        AxiosGet("HoldPackage/GetData_DropDown", { nID: nID }, (result) => {   
            setarrOrder(result.lstOrderPause || [])
        });
    }

    const OnGetDataDate = () => {
        let nID = (form.getValues("sOrder_ID").value)
        AxiosGet("HoldPackage/GetData_DropDown", { nID: nID }, (result) => {
            setEndDate(moment(result.objOrderPauseDate.dEnd_Date));
        });
    }

    const onSubmit = (nStatusID) => {


        dispatch(DialogActionCreators.OpenDialogSubmit("คุณต้องการบันทึกข้อมูลหรือไม่ ?", () => {
            dispatch(DialogActionCreators.CloseDialogSubmit() as any);


            if (nStatusID === 12 && moment(form.getValues("dStart_Date")).toDate() > moment(Date.now()).toDate()) {
                nStatusID = 11;
            }
            if (nStatusID === 14 && moment(form.getValues("dEnd_Date")).toDate() > moment(Date.now()).toDate()) {
                nStatusID = 12;
            }


            let objSave = {
                sID: isEditMode ? sID : "",
                nCompany_ID: +(form.getValues("sCompany_ID").value),
                nOrder_ID: +(form.getValues("sOrder_ID").value),
                dStart_Date: moment(form.getValues("dStart_Date")).set({ "hour": 23, "minute": 59, "second": 59 }).toDate(),
                sRemark: form.getValues("sRemark"),
                dEnd_Date: moment(form.getValues("dEnd_Date")).set({ "hour": 23, "minute": 59, "second": 59 }).toDate(),
                //   dEnd_Date: moment(form.getValues("dEnd_Date")),
                nStatusID,
                sNote_Cancel: form.getValues("sNote_Cancel"),
            }
          
            AxiosPost("HoldPackage/SaveData_PausePackage", objSave, (result) => {
                ResultAPI(result, "บันทึกเรียบร้อยแล้ว", () => {
                    dispatch(DialogActionCreators.OpenDialogSuccess("บันทึกข้อมูลสำเร็จ",
                        () => onBack()
                    ) as any);
                });
            }, (err) => {
                if (err.Status === 400) {
                    dispatch(DialogActionCreators.OpenDialogWarning("เลขที่ใบสั่งซื้อมีการบันทึกข้อมูลระงับการใช้งานในระบบแล้ว", () => {
                    }) as any)
                } else if (err.Status === 401) {
                    dispatch(DialogActionCreators.OpenDialogWarning("ไม่สามารถระงับแพ็กเกจได้เนื่องจากมีการใช้แพ็กเกจหมดแล้ว", () => {
                    }) as any)
                }

            });
        }))
        //}
    }

    const onGetDetail = () => {

        AxiosPost("HoldPackage/EditData_PausePackagedetail", { sID: sID }, (result) => {
            ResultAPI(result, "", () => {
                if (isEditMode) {
                    form.setValue("sCompany_ID", result.objEdit.objEmployer);
                    form.setValue("sOrder_ID", result.objEdit.objOrder);
                    form.setValue("dStart_Date", moment(result.objEdit.dStart_Date).format("MM/DD/yyyy HH:mm").toString());
                    form.setValue("sRemark", result.objEdit.sRemark);
                    form.setValue("dEnd_Date", moment(result.objEdit.dEnd_Date).format("MM/DD/yyyy HH:mm").toString());
                    setOrder(result.objEdit.sID);
                    setStatus(result.objEdit.nStatusID);
                    form.setValue("sStatus_ID", result.objEdit.objStatus);
                    form.setValue("sNote_Cancel", result.objEdit.sNote_Cancel);
                    setStartPackage(moment(result.objEdit.dStart_Date));
                    OnGetDataDate();
                }
            });
        },

        );
    };

    return (
        <FormProvider {...form}>
            <Stack sx={{ border: "2px #eeeeee solid", backgroundColor: "rgba(255,255,255,0.75)", borderRadius: 5, p: 2, mx: "2%" }}>
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    spacing={3}
                >

                    <Grid item xs={12} sm={12} md={4}>
                        <AutoCompleteSelect
                            required
                            name={'sCompany_ID'}
                            label={"ชื่อผู้ประกอบการ"}
                            options={arrEmployer}
                            onChange={(v) => {
                                form.setValue('sCompany_ID', v ? v : null);
                                OnGetDataOrder();
                               
                            }}
                            disabled={nPermission !== 2 || isEditMode === true || nStatus === 14 || nStatus === 15}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={8}></Grid>
                    <Grid item md={4} xs={12}>
                        <AutoCompleteSelect
                            required
                            name={'sOrder_ID'}
                            label={"ข้อมูลแพ็กเกจ"}
                            options={arrOrder}
                            onChange={(v) => {
                                form.setValue('sOrder_ID', v ? v : null);
                                OnGetDataDate();
                            }}
                            disabled={nPermission !== 2 || isEditMode === true || nStatus === 14 || nStatus === 15}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={8}></Grid>
                    <Grid item md={4} xs={12}>
                        <DatePickerFormItem
                            disabled={nPermission !== 2 || nStatus === 14 || nStatus === 15}
                            required
                            name={"dStart_Date"}
                            label={"วันเริ่มระงับใช้งาน"}
                            fullWidth
                            minDate={StartDate}
                            maxDate={EndDate}
                            onChange={(v) => {
                              //form.getValues("dStart_Date")
                                setStartPackage(moment( form.getValues("dStart_Date")));
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={8}></Grid>
                    <Grid item md={12} xs={12} >
                        <Input name="sRemark" rows={3}
                            disabled={nPermission !== 2 || nStatus === 14 || nStatus === 15}
                            required
                            multiline
                            maxLength={3000}
                            label={
                                <>
                                    <Typography sx={{ fontWeight: 400 }} component="label">{"เหตุผลการระงับใช้งาน"}</Typography>
                                </>}
                        />
                    </Grid>
                                     
                        <Grid item md={12} xs={12} >
                            <DatePickerFormItem
                                disabled={isEditMode === false || nStatus === 14 || nStatus === 15 || nStatus === 18 }
                                name={"dEnd_Date"}
                                label={"วันกลับมาใช้งาน"}
                                fullWidth
                                minDate={StartPackage}
                                maxDate={EndDate}
                            />
                        </Grid>
                  
                    {nStatus === 18 ? (
                        <Grid item md={12} xs={12} >
                            <Input name="sNote_Cancel" rows={3}
                                disabled={nPermission !== 2 || isEditMode === false || nStatus === 18 || nStatus === 14 || nStatus === 15}
                                multiline
                                maxLength={3000}
                                required={isCancle}
                                label={
                                    <>
                                        <Typography sx={{ fontWeight: 400 }} component="label">{"เหตุผลการยกเลิก"}</Typography>
                                    </>}
                            />
                        </Grid>
                    ) : null}
                    <Grid item md={6} xs={5} sm={5} justifyContent={"start"} >
                        <BtnBack txt='ย้อนกลับ' onClick={() => onBack()} />
                    </Grid>
            
                    {nStatus !== 14 ? (
                        <Grid item container md={6} xs={3} sm={3} justifyContent={"end"}>
                            <BtnSave txt='บันทึก' onClick={form.handleSubmit(e => { onSubmit(12) })}
                                IsDisabled={nPermission !== 2|| nStatus === 15 }
                            />
                        </Grid>
                    ) : <Grid item container md={6} xs={3} sm={3} justifyContent={"end"}> </Grid>}
                </Grid>
            </Stack >
        </FormProvider >

    );

}
export default HoldPackage



