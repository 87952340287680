import { Grid, Stack, } from '@mui/material';
import { useEffect, useState } from 'react';
import { AxiosGet, GetPermission } from 'service/CommonFunction/TS_function';
import FormShowTotalPackage from './FormShowTotalPackage';



const ShowTotalPackage = () => {
    const [loadingTable, setLoadingTable] = useState(true);
    const [TNamebox, setTNamebox] = useState([]);
    const [nPermission, setPermission] = useState(0);

    useEffect(() => {
        GetPermission(5, setPermission);
        loadData();
    }, [])

    const loadData = () => {
        setLoadingTable(true)
        AxiosGet("AdminUserPackage/SetDataTotalPackage", {}, (res) => {
            setTNamebox(res.lstData);
        });
        setLoadingTable(false)
    };
    return (
        <Grid className="ShowTotalPackage" direction="row" >
            <Stack  width={"auto"} sx={{ border: "2px #eeeeee solid", backgroundColor: "rgba(255,255,255,0.75)", borderRadius: 5, p: 2}}>
                <Grid container spacing={2} justifyContent="center">
                    {TNamebox != null && TNamebox.length > 0 ? TNamebox.map((sNameBox, index) => (
                        <Grid item key={index} xs={12} md={3}>
                            <FormShowTotalPackage sNameBox={sNameBox} />
                        </Grid>
                    )) : null}

                </Grid>
            </Stack>
        </Grid>
    );
}
export default ShowTotalPackage

//ไม่ใช้

