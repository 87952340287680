import React, { useEffect, useState, Fragment } from "react";
import {
    Autocomplete,
    TextField,
    Box,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    Stack,
    Typography,
    Select,
    SelectChangeEvent,
    Paper,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Table,
    TableBody,
    Checkbox,
    Card,
    Hidden,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { i18n } from "i18n";
import yupFormSchemas, {
    RegexpPassword,
} from "components/Common/yup/yupFormSchemas";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import {
    AxiosGet,
    AxiosPost,
    Extension,
    GetPermission,
    ResultAPI,
} from "service/CommonFunction/TS_function";
import {
    BtnCancel,
    BtnPreview,
    BtnSaveDraft,
    BtnSubmit,
    BtnBack,
    BtnReject,
    BtnApprove
} from "components/Common/Button";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { DialogActionCreators } from "store/redux/DialogAlert";
import AutoCompleteSelect from "components/Common/ElementInForm/AutoCompleteSelect";
import UploadFile from 'components/Common/ElementInForm/UploadFile/UploadFile';
import DatePickerFormItem from "components/Common/ElementInForm/DatePickerFormItem";
import Input from "components/Common/ElementInForm/InputCustomLabelFormItem";
import InputNumber from "components/Common/ElementInForm/InputNumber";
import secureLocalStorage from 'react-secure-storage';
let sController = "AdminOrder";
let sPackageType = sController + "/GetData_DropDown_PackageType";
let sPackage = sController + "/GetData_DropDown_Package";
let sPricePakage = sController + "/GetData_PricePakage";
let sDetail = sController + "/GetData_DetailOrderEmployer";
let sSave = sController + "/SaveDataOrderFreePackage";
let sGetEmployer = sController + "/GetData_Employer";
let sSpecial = sController + "/GetData_DropDown_Special";
let sStatus = sController + "/GetData_DropDown_Status_Order";
export default function AdminFreePackageDetail(props) {
    const location = useLocation();
    const searchParams = location.search;
    const sID = new URLSearchParams(searchParams).get("sID");
    const isEditMode = sID ? true : false;

    const [isDisabled, setisDisabled] = useState(false);
    const [isDisabledPackage, setisDisabledPackage] = useState(true);
    const [isShowPrice, setisShowPrice] = useState(false);
    const i18nField = "OrderEmployer";
    const history = useNavigate();
    const dispatch = useDispatch();
    const [isBanner, setIsBanner] = useState(false);
    const [nPackageType, setnPackageType] = useState("");
    const [detail, setDetail] = useState<{ [key: string]: any }>({});

    const [lstPackage, setlstPackage] = useState([]);
    const [lstPackageType, setlstPackageType] = useState([]);
    const [lstEmployer, setlstEmployer] = useState([]);
    const [lstSpecial, setlstSpecial] = useState([]);
    const [lstStatusFree, setStatusFree] = useState([]);
    const [sStatusOrder_ID, setStatus_ID] = useState(0);
    const [ShowStatus, setStatus] = useState(0);
    //Banner Package
    const [arrDuration, setarrDuration] = useState([]);
    const [arrlstRow, setarrlstRow] = useState([]);
    const [lstarrValue, setarrValue] = useState([]);
    const [lstCheck, setlstCheck] = useState([]);
    const [IsJobPost, setIsJobPost] = useState(false);
    const [IsnCoin, setIsCoin] = useState(false);
    const [IsExpirey, setIsExpirey] = useState(false);
    const [nPermission, setPermission] = useState(0);
    const [lstPo, setlstPo] = useState([]);
    const [Type_ID, setType_ID] = useState(0);
    const [cancelStatus, setCancelStatus] = useState(false);
    const [lstStatusFreeAll, setlstStatusFreeAll] = useState([]);
    const isFreeApproveDetail = new URLSearchParams(searchParams).get("sMode") === "4";
    const isFreePackageDetail = new URLSearchParams(searchParams).get("sMode") === "5";
    //order status
    const freepackageRequest = ["1"]; //request status
    const freepackageWaitandCancel = ["1", "7"]; //request status
    const freepackageApprove = ["2", "3"]; //approval status
    const statusCancle = ["7"]; //cancle status    

    //ถ้าเป็น Banner ไม่ต้อง required
    const objSchema = {
        // sType_ID: yupFormSchemas.string(i18n(`${i18nField}.TypePackage`), {
        sType_ID: yupFormSchemas.string("ประเภทแพ็กเกจ", {
            required: true,
            isAdmin: true
        }),
        sEmployer_ID: yupFormSchemas.string("ผู้ประกอบการ", {
            required: true,
            isAdmin: true
        }),
        nJobPost: yupFormSchemas.string("จำนวนโพสต์", {
            required: IsJobPost,
            isAdmin: true
        }),
        nCoinAmount: yupFormSchemas.string("เหรียญ", {
            required: IsnCoin,
            isAdmin: true
        }),
        nExpirey: yupFormSchemas.string("วันหมดอายุ", {
            required: IsExpirey,
            isAdmin: true
        }),
        sDescription: yupFormSchemas.string("เหตุผลในการขออนุญาต", {
            required: (sStatusOrder_ID === 0 || sStatusOrder_ID === 3),
            isAdmin: true
        }),
        sComment: yupFormSchemas.string("หมายเหตุ", {
            // required: (cancelStatus || (props.nType_ID === 1 && sStatusOrder_ID === "1")),
            required: (props.nType_ID === 1),
            isAdmin: true
        }),
    };

    const onBack = () => {
        history(-1);
    };

    useEffect(() => {
        GetData_DropDown_Status();
        OnGetData_DropDown_Employer();
        OnGetData_DropDown_PackageType();
        OnGetData_BannerPackage();
        GetData_DropDown_Special();
        GetPermission(35, setPermission);
    }, []);

    useEffect(() => {
        OnGetData();
    }, [lstStatusFreeAll]);


    useEffect(() => {
        if (!secureLocalStorage.getItem("sUserId")) {
            if (isFreeApproveDetail) {
                history(`/AdminLogin?sID=${encodeURI(sID)}&sMode=4`);
            } else if (isFreePackageDetail) {
                history(`/AdminLogin?sID=${encodeURI(sID)}&sMode=5`);
            }

        }
    })
    //step2.2นำข้อมูลมาทำเป็น Yup object
    const schema = yup.object().shape(objSchema);

    const form = useForm({
        resolver: yupResolver(schema),
        shouldUnregister: false,
        shouldFocusError: true,
        mode: "all",
    });

    const GetData_DropDown_Status = () => {
        AxiosGet(
            sStatus,
            {},
            (result) => {
                let lstStatusFree = result.lst_Status_FreeOrder
                setlstStatusFreeAll(lstStatusFree || []);
            },
            (err) => {
                if (!err.response) {
                    history("/error", { replace: true });
                    return;
                }
            }
        );
    };
    const OnGetData_DropDown_Employer = () => {
        AxiosGet(
            sGetEmployer,
            {},
            (result) => {
                setlstEmployer(result.lstOptionEmployer || []);
            },
            (err) => {
                if (!err.response) {
                    history("/error", { replace: true });
                    return;
                }
            }
        );
    };
    const GetData_DropDown_Special = () => {
        AxiosGet(
            sSpecial,
            {},
            (result) => {
                setlstSpecial(result.lstOptionSpecial || []);
            },
            (err) => {
                if (!err.response) {
                    history("/error", { replace: true });
                    return;
                }
            }
        );
    };
    const OnGetData_DropDown_PackageType = () => {
        AxiosGet(
            sPackageType,
            {},
            (result) => {
                setlstPackageType(result.lstOptionPackageType || []);
            },
            (err) => {
                if (!err.response) {
                    history("/error", { replace: true });
                    return;
                }
            }
        );
    };

    const OnGetData_DropDown_Package = () => {
        let sType_ID = form.getValues("sType_ID");
        if (sType_ID === "3") {
            setIsBanner(true);
        } else {
            setIsBanner(false);
            AxiosGet(
                sPackage,
                {
                    sID: sType_ID,
                },
                (result) => {
                    setlstPackage(result.lstOptionPackage || []);
                    if (result != null) {
                        setisShowPrice(true);
                    }
                },
                (err) => {
                    if (!err.response) {
                        history("/error", { replace: true });
                        return;
                    }
                }
            );
        }
        setisDisabledPackage((sType_ID === "" ? true : false));
    };

    const OnGetOrder = () => {
        let nID = form.getValues("sEmployer_ID") + ""
        AxiosGet("AdminOrder/GetData_ShowOrder", { nID: nID }, (result) => {
            setlstPo(result.lstPo || [])
        });
    }
    const OnGetData_BannerPackage = () => {
        AxiosPost(
            "AdminUserPackage/SetFilter_TableBannerDataPackage",
            {},
            (result) => {
                setarrDuration(result.lstDuration);
                setarrlstRow(result.lstRow);
                setarrValue(result.lstValue);
            },
            (err) => {
                if (!err.response) {
                    history("/error", { replace: true });
                    return;
                }
            }
        );
    };

    const handleChangeCheckboxBanner = (e) => {
        let nPosition_ID = +e.target.value.split("_")[0];
        let nBannerPackage_ID = +e.target.value.split("_")[1];
        let objCheck = lstCheck.filter((f) => f.nPosition_ID === nPosition_ID)[0];
        if (objCheck) {
            if (e.target.checked) {
                objCheck.nBannerPackage_ID = nBannerPackage_ID;
            } else {
                objCheck.nBannerPackage_ID = null;
            }
        }
        setlstCheck([...lstCheck]);
        CalGrandTotal();
    };

    const CalGrandTotal = () => {
        let total = 0;
        lstCheck.map((item, index) => {
            let obj = lstarrValue.filter(
                (f) =>
                    f.nPosition_ID === item.nPosition_ID &&
                    f.nBannerPackage_ID === item.nBannerPackage_ID
            )[0];
            if (obj) {
                total = total + obj.nValue;
            }
        });
        detail.sAfterVat = (Math.round(total * 100) / 100).toLocaleString();
        setDetail(detail);
    };

    const OnGetData = () => {
        let obj = {
            sOrder_ID: sID,
        };

        AxiosPost(
            sDetail,
            obj,
            (result) => {
                if (isEditMode) {
                    form.setValue("sType_ID", result.sType_ID);
                    OnGetData_DropDown_Package();
                    form.setValue("sPackage_ID", result.sPackage_ID);
                    form.setValue("nJobPost", result.sJobPost);
                    form.setValue("nCoinAmount", result.nCoinAmount);
                    form.setValue("nExpirey", result.sDate_Expiry);
                    form.setValue("sEmployer_ID", result.sCompany_ID);
                    form.setValue("sComment", result.sComment);
                    form.setValue("sDescription", result.sDescription);
                    form.setValue("status", result.sStatus_ID);
                    setnPackageType(result.sType_ID);
                    setDetail(result);
                    if (result.sStatus_ID === 2) {
                        setisDisabled(true);
                    }
                    if (result.sType_ID === 3) {
                        setIsBanner(true);
                    }
                    setStatus_ID(result.sStatus_ID);
                    setStatus(result.sStatus_ID)
                    if (result.sStatus_ID === 7 || result.sStatus_ID === 3 || result.sStatus_ID === 2 && result.sType_ID === 2) {
                        setCancelStatus(true)
                    }
                    setlstCheck(result.lstBanner || []);
                    // NOTE!!
                    // status 1 == wait for apporve
                    // status 2 == approve
                    // status 3 == reject 
                    // status 7 == cancel 

                    if (result.sStatus_ID === "1") {
                        let lstStatusTemp = lstStatusFreeAll.filter(w => w.value == result.sStatus_ID || statusCancle.includes(w.value))
                        setStatusFree(lstStatusTemp || []);
                    } else if (result.sStatus_ID === "3") {
                        let lstStatusTemp = lstStatusFreeAll.filter(w => w.value == result.sStatus_ID || freepackageRequest.includes(w.value) || statusCancle.includes(w.value))
                        setStatusFree(lstStatusTemp || []);


                    } else if (result.sStatus_ID === "2") {
                        let lstStatusTemp = lstStatusFreeAll.filter(w => w.value == result.sStatus_ID)
                        setStatusFree(lstStatusTemp || []);
                        setCancelStatus(true)
                    }
                    else if (result.sStatus_ID === "7") {
                        let lstStatusTemp = lstStatusFreeAll.filter(w => w.value == result.sStatus_ID)
                        setStatusFree(lstStatusTemp || []);
                        setCancelStatus(true)
                    }

                } else {
                    setlstCheck(result.lstBanner || []);
                    let lstStatusTemp = lstStatusFreeAll.filter(w => w.value == result.sStatus_ID || freepackageRequest.includes(w.value))
                    setStatusFree(lstStatusTemp || []);
                }


                // if (result.sStatus_ID === null) {
                //     form.setValue("status", "1");
                // }
            },
            (err) => {
                if (!err.response) {
                    history("/error", { replace: true });
                    return;
                }
            }
        );
    };

    const onSubmit = (e, status) => {
        let sTitle = status === "1" ? "ยืนยันการบันทึกข้อมูลใช่หรือไม่" : status === "2" ? "ยืนยันการอนุมัติข้อมูลใช่หรือไม่" : status === "3" ? "ยืนยันการส่งกลับแก้ไขข้อมูลใช่หรือไม่" : "ยืนยันการบันทึกข้อมูลใช่หรือไม่";
        dispatch(
            DialogActionCreators.OpenDialogSubmit(sTitle, () => { //Do you want to save ?
                dispatch(DialogActionCreators.CloseDialogSubmit() as any);
                let sStatus_ID = status;
                if (sStatus_ID === "1") {
                    setCancelStatus(false);
                }

                let objSaveData = {
                    sCompany_ID: form.getValues("sEmployer_ID"),
                    sOrder_ID: sID,
                    sType_ID: form.getValues("sType_ID"),
                    sPackage_ID: "",
                    sJobPost: form.getValues("nJobPost") + "",
                    nCoinAmount: form.getValues("sType_ID") == 3 ? 0 : form.getValues("nCoinAmount") + "",
                    sDate_Expiry: form.getValues("nExpirey") + "",
                    sPrice: detail.sPrice,
                    sDiscount: detail.sDiscount,
                    sNetPrice: detail.sNetPrice,
                    sVat: detail.sVat,
                    sAfterVat: detail.sAfterVat,
                    // sStatus_ID: form.getValues("status"),
                    sStatus_ID: sStatus_ID,
                    lstBanner: lstCheck,
                    sEmail: "",
                    sDescription: form.getValues("sDescription"),
                    sComment: form.getValues("sComment"),
                };

                AxiosPost(
                    sSave,
                    objSaveData,
                    (result) => {
                        // ResultAPI(result, "Saved successfully.", () => {
                        //   onBack()
                        // });
                        dispatch(DialogActionCreators.CloseDialogSubmit() as any);
                        ResultAPI(result, "บันทึกข้อมูลสำเร็จ", () => {
                            //     dispatch(DialogActionCreators.OpenDialogSuccess("บันทึกข้อมูลสำเร็จ") as any)
                            //     // );
                            //     //,()=>{onBack()}
                            //     onBack();

                            dispatch(
                                DialogActionCreators.OpenDialogSuccess("บันทึกข้อมูลสำเร็จ", () => {
                                    // dispatch(DialogActionCreators.LoadSubmit(false) as any);
                                    onBack()
                                }
                                ) as any
                            );
                        });
                    },
                    (err) => {
                        if (err.Message != "") {
                            dispatch(
                                DialogActionCreators.OpenDialogWarning(err.Message) as any
                            );
                        }
                    }
                );
            }) as any
        );
    };

    const ShowDetail_by_Type = () => {
        if (isBanner) {
            return (
                <TableHead>
                    <TableRow>
                        <TableCell>{i18n(`${i18nField}.GrandTotal`)}</TableCell>
                        <TableCell align="right">{detail.sAfterVat}</TableCell>
                        <TableCell align="left">บาท</TableCell>
                    </TableRow>
                </TableHead>
            );
        } else {
            return (
                <TableHead>
                    {
                        nPackageType === "1" ?
                            <TableRow hidden={true}>
                                <TableCell>จำนวนโพสต์ <span style={{ color: "red" }}>*</span></TableCell>
                                <TableCell align="right">
                                    <InputNumber
                                        name={"nJobPost"}
                                        // required

                                        disabled={sStatusOrder_ID !== 0 && sStatusOrder_ID !== 3 || nPermission !== 2}
                                    /></TableCell>
                                <TableCell align="left">โพสต์</TableCell>
                            </TableRow>

                            : null
                    }

                    <TableRow hidden={true}>
                        <TableCell>จำนวนเหรียญ <span style={{ color: "red" }}>*</span></TableCell>
                        <TableCell align="right">
                            <InputNumber
                                name={"nCoinAmount"}
                                disabled={sStatusOrder_ID !== 0 && sStatusOrder_ID !== 3 || nPermission !== 2}
                            /></TableCell>
                        <TableCell align="left">เหรียญ</TableCell>

                    </TableRow>
                    {
                        nPackageType === "1" || nPackageType === "2" ?
                            <TableRow hidden={true}>
                                <TableCell>วันที่หมดอายุ <span style={{ color: "red" }}>*</span></TableCell>
                                <TableCell align="right">
                                    <InputNumber
                                        name={"nExpirey"}
                                        // required

                                        disabled={sStatusOrder_ID !== 0 && sStatusOrder_ID !== 3 || nPermission !== 2}
                                    /></TableCell>
                                <TableCell align="left">เดือน</TableCell>
                            </TableRow>
                            : null

                    }
                </TableHead>
            );
        }
    };

    return (
        <FormProvider {...form}>
            <Card sx={{
                padding: "1em",
                borderRadius: "1em",
                position: "relative",
                // background: "white"
            }}>
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                // className="fade-bg-color"
                >
                    <Grid item container spacing={1} xs={12}>
                        <Grid item xs={12} sm={12} md={12}>
                            <AutoCompleteSelect
                                required
                                name={"sEmployer_ID"}
                                label={"ผู้ประกอบการ"}
                                options={lstEmployer}
                                customValue={{ key: "value" }}
                                onChange={(v) => {
                                    form.setValue("sEmployer_ID", v ? v : "");
                                    OnGetOrder();
                                }}
                                disabled={sStatusOrder_ID !== 0 && sStatusOrder_ID !== 3 || nPermission !== 2}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={1} xs={12}>
                        <Grid item xs={12} sm={12} md={12}>
                            <AutoCompleteSelect
                                required
                                name={"sType_ID"}
                                label={"เลือกประเภทแพ็กเกจ"}
                                options={lstPackageType}
                                customValue={{ key: "value" }}
                                onChange={(v) => {
                                    form.setValue("sType_ID", v ? v : "");
                                    setDetail({});
                                    setisShowPrice(false);
                                    OnGetData_DropDown_Package();
                                    setnPackageType(v);
                                    setIsJobPost((v === "1"))
                                    setIsCoin((v === "1" || v === "2"))
                                    setIsExpirey((v === "1"))
                                    setType_ID((v.value))
                                }}
                                disabled={sStatusOrder_ID !== 0 && sStatusOrder_ID !== 3 || nPermission !== 2}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={1} xs={12}>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            hidden={isBanner !== true ? true : false}
                        >
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow
                                            sx={{
                                                background: "#eeeeee",
                                                border: " 1px solid #dddddd",
                                            }}
                                        >
                                            <TableCell component="th" scope="row" align="center">
                                                {i18n(`${i18nField}.Package`)}
                                            </TableCell>
                                            {arrDuration != null && arrDuration.length > 0
                                                ? arrDuration.map((item, index) => {
                                                    return (
                                                        <TableCell
                                                            align="center"
                                                            key={index}
                                                            sx={{ border: " 1px solid #dddddd" }}
                                                        >
                                                            {item.sName} {i18n(`${i18nField}.Month`)}
                                                        </TableCell>
                                                    );
                                                })
                                                : null}
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {arrlstRow != null && arrlstRow.length > 0
                                            ? arrlstRow.map((item, index) => {
                                                return (
                                                    <TableRow key={index}>
                                                        <TableCell
                                                            key={index}
                                                            align="left"
                                                            sx={{ border: " 1px solid #dddddd" }}
                                                        >
                                                            {item.sName}
                                                        </TableCell>

                                                        {arrDuration != null && item.lstValue.length > 0 ? arrDuration.map((itemSub, indexSub) => {
                                                            let nvar = item.lstValue.find(e => e.nBannerPackage_ID == itemSub.nBannerPackage_ID)
                                                            return (
                                                                <TableCell
                                                                    key={
                                                                        nvar.nPosition_ID +
                                                                        "_" + nvar.nBannerPackage_ID
                                                                    }
                                                                    align="center"
                                                                    sx={{
                                                                        border: " 1px solid #dddddd",
                                                                    }}
                                                                >
                                                                    <FormControlLabel
                                                                        label={""}
                                                                        control={
                                                                            <Checkbox
                                                                                disabled={sStatusOrder_ID !== 0 && sStatusOrder_ID !== 3 || nPermission !== 2}
                                                                                checked={
                                                                                    lstCheck.filter(
                                                                                        (f) =>
                                                                                            f.nPosition_ID ===
                                                                                            nvar.nPosition_ID &&
                                                                                            f.nBannerPackage_ID ===
                                                                                            nvar.nBannerPackage_ID
                                                                                    )[0]
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={
                                                                                    nvar.nPosition_ID +
                                                                                    "_" +
                                                                                    nvar.nBannerPackage_ID
                                                                                }
                                                                                onChange={(e) =>
                                                                                    handleChangeCheckboxBanner(e)
                                                                                }
                                                                            />
                                                                        }
                                                                    />
                                                                </TableCell>
                                                            )
                                                        }) : null}
                                                    </TableRow>
                                                );
                                            })
                                            : null}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>

                    {isShowPrice ?
                        <>
                            <Grid item container spacing={1} xs={12}>
                                <Grid item lg={12} xs={12}>
                                    <TableContainer component={Paper}>
                                        <Table aria-label="simple table">{ShowDetail_by_Type()}</Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </>
                        : null
                    }

                    {props.nType_ID === 1 ?
                        null :
                        <Grid item container spacing={1} xs={12}>
                            <Grid item xs={12} sm={12} md={12}>
                                <AutoCompleteSelect
                                    required
                                    name={"status"}
                                    label={"สถานะใบคำสั่งซื้อ"}
                                    options={lstStatusFree}
                                    customValue={{ key: "value" }}
                                    onChange={(v) => {
                                        // setStatus(v.value)
                                        setStatus(v)

                                        // if (v === "1") {
                                        //     setCancelStatus(false);
                                        // }
                                        // if (v === 7) {
                                        //     setCancelStatus(true);
                                        // }

                                        form.setValue("status", v ? v : "");
                                    }}
                                    disabled={cancelStatus || nPermission !== 2}
                                />
                            </Grid>
                        </Grid>
                    }

                    <Grid item container spacing={1} xs={12}>
                        <Grid item xs={12} sm={12} md={12}>
                            <Input name="sDescription" rows={3}
                                required={true}
                                disabled={nPermission !== 2 || sStatusOrder_ID === 2 || props.nType_ID === 1 || cancelStatus}
                                multiline
                                maxLength={5000}
                                label={
                                    <>
                                        <Typography sx={{ fontWeight: 400 }} component="label">เหตุผลในการขออนุญาต</Typography>
                                    </>
                                }
                            />
                        </Grid>
                    </Grid>
                    {(props.nType_ID === 0 && ShowStatus != 1) || props.nType_ID === 1 ?
                        <Grid item container spacing={1} xs={12}>
                            <Grid item xs={12} sm={12} md={12}>
                                <Input name="sComment" rows={3}
                                    required={props.nType_ID === 1}
                                    disabled={(props.nType_ID === 0 && ShowStatus == 1) || cancelStatus}
                                    multiline
                                    maxLength={5000}
                                    label={
                                        <>
                                            <Typography sx={{ fontWeight: 400 }} component="label">หมายเหตุ</Typography>
                                        </>
                                    }
                                />
                            </Grid>
                        </Grid>
                        : null
                    }

                    {/* phone size */}
                    <Hidden smUp>

                        {sStatusOrder_ID === 2 ?
                            null :
                            props.nType_ID === 1 ?
                                <Fragment>
                                    <Grid
                                        container

                                        justifyContent="space-between"
                                        alignItems="flex-start"
                                        sx={{ mt: "4%" }}
                                    >
                                        <Grid item sx={{ ml: "5%" }} md={6} justifyContent={"start"}>
                                            <BtnReject
                                                txt={"ส่งกลับแก้ไข"} //pending invoice
                                                IsDisabled={nPermission !== 2}
                                                onClick={form.handleSubmit((e) => {
                                                    onSubmit(e, "3");
                                                })}
                                                IsHisabled={sStatusOrder_ID === 2}
                                            />

                                        </Grid>

                                        <Grid item md={6} justifyContent={"end"}>
                                            <BtnApprove
                                                txt={"อนุมัติ"} //pending invoice
                                                IsDisabled={nPermission !== 2}
                                                onClick={form.handleSubmit((e) => {
                                                    onSubmit(e, "2");
                                                })}
                                                IsHisabled={sStatusOrder_ID === 2}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} justifyContent={"start"}>
                                        <BtnBack
                                            txt={"ย้อนกลับ"}
                                            onClick={() => onBack()} />
                                    </Grid>
                                </Fragment>
                                :
                                <Fragment>
                                    <Grid
                                        container
                                        justifyContent="space-between"
                                        alignItems="flex-start"
                                        sx={{ mt: "4%" }}
                                    >


                                        <Grid item sx={{ ml: "5%" }} md={6} justifyContent={"start"}>
                                            <BtnBack
                                                txt={"ย้อนกลับ"}
                                                onClick={() => onBack()} />
                                        </Grid>
                                        {cancelStatus ?
                                            null :
                                            <Grid item md={6} justifyContent={"end"}>
                                                <BtnSubmit
                                                    txt={"บันทึก"} //pending invoice
                                                    IsDisabled={nPermission !== 2}
                                                    onClick={form.handleSubmit((e) => {
                                                        onSubmit(e, form.getValues("status"));
                                                    })}
                                                    IsHisabled={false}
                                                />
                                            </Grid>
                                        }
                                    </Grid>

                                </Fragment>
                        }



                        {/* {
                            sStatusOrder_ID === "2" ?

                                <Grid item xs={6} justifyContent={"start"}>
                                    <BtnBack
                                        txt={"ย้อนกลับ"}
                                        onClick={() => onBack()} />
                                </Grid>

                                : <Grid item xs={12} justifyContent={"start"}>
                                    <BtnBack
                                        txt={"ย้อนกลับ"}
                                        onClick={() => onBack()} />
                                </Grid>
                        } */}
                    </Hidden>

                    {/* normal size */}
                    <Hidden smDown>
                        {
                            sStatusOrder_ID === 2 ? <Grid item md={12} xs={6} justifyContent={"start"}>
                                <Box>
                                    <BtnBack
                                        txt={"ย้อนกลับ"}
                                        onClick={() => onBack()} />
                                </Box>
                            </Grid> : <Grid item md={6} xs={12} justifyContent={"start"}>
                                <Box >
                                    <BtnBack
                                        txt={"ย้อนกลับ"}
                                        onClick={() => onBack()} />
                                </Box>
                            </Grid>
                        }

                        {sStatusOrder_ID === 2 ?
                            null :
                            props.nType_ID === 1 ?
                                <Fragment>
                                    <Grid item container md={5} xs={6} justifyContent={"end"}>
                                        <BtnReject
                                            txt={"ส่งกลับแก้ไข"} //pending invoice
                                            IsDisabled={nPermission !== 2}
                                            onClick={form.handleSubmit((e) => {
                                                onSubmit(e, "3");
                                            })}
                                            IsHisabled={sStatusOrder_ID === 2}
                                        />

                                    </Grid>

                                    <Grid item container md={1} xs={2} justifyContent={"end"}>
                                        <BtnApprove
                                            txt={"อนุมัติ"} //pending invoice
                                            IsDisabled={nPermission !== 2}
                                            onClick={form.handleSubmit((e) => {
                                                onSubmit(e, "2");
                                            })}
                                            IsHisabled={sStatusOrder_ID === 2}
                                        />
                                    </Grid>
                                </Fragment>
                                :
                                <Grid item container md={6} xs={6} justifyContent={"end"}>
                                    {cancelStatus ?
                                        null :
                                        <BtnSubmit
                                            txt={"บันทึก"} //pending invoice
                                            IsDisabled={nPermission !== 2}
                                            onClick={form.handleSubmit((e) => {
                                                //  onSubmit(e, "1");
                                                onSubmit(e, form.getValues("status"));
                                            })}
                                            IsHisabled={false}
                                        />
                                    }
                                </Grid>
                        }
                    </Hidden>
                </Grid>
            </Card>
        </FormProvider >
    );
}
