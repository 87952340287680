import { Stack } from "@mui/material";
import { getLanguage, setLanguageCode } from "i18n";
import { useEffect } from "react";
import AdminOrderTable from "../../../components/AdminWorkflow/AdminOrder/AdminOrder";

export default function AdminOrder() {
  useEffect(() => {
    setLanguageCode(getLanguage().id === "th" );
  }, [])

  return (
    <Stack sx={{ mx: "1%", }}>
      <AdminOrderTable />
    </Stack>
  );
}