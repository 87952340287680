import { Grid } from '@mui/material';
import ShowTotalPackage from "../Show/ShowTotalPackage";
import FormCreateBannerPackage from './FormCreateBannerPackage';
import { i18n } from 'i18n';

const CreateBannerPackage = () => {
    const i18nField = 'entities.Package';
    return (

        <Grid className="CreateBannerPackage" direction="row" sx={{ width: "100%" }}>
            <Grid container xs={12}>
                <Grid item sx={{ mx: "2%", width: "100%"  }}>
                    <ShowTotalPackage />
                </Grid>
                <Grid item xs={12} sx={{ mx: "2%", marginTop: "2%" }}>
                    <h3>{i18n(`${i18nField}.Banner`)}</h3>
                </Grid>
            </Grid>
            <Grid item sx={{ mx: "2%", marginTop: "2%" }}>
                <FormCreateBannerPackage />
            </Grid>
        </Grid>
    );
}
export default CreateBannerPackage


