import React, { useEffect, useState, useMemo } from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  ToggleButtonGroup,
  ToggleButton,
  TextField,
  FormControl,
  FormHelperText,
  InputLabel,
  Hidden,
  IconButton,
  TextFieldProps,
} from "@mui/material";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import { th as LocaleTH, enUS as LocaleEN } from "date-fns/locale";
import moment from "moment";
import EventIcon from '@material-ui/icons/Event';
import { mobileCheck } from "service/SystemFunction/SystemFunction";
import secureLocalStorage from "react-secure-storage";
import AlarmIcon from '@mui/icons-material/Alarm';
import { DatePicker, DesktopDatePicker } from "@mui/x-date-pickers";

const localeMap = (lang) => {
  switch (lang) {
    case "th":
      moment.locale("th");
      break;
    case "en":
      moment.locale("en");
      break;
    default:
      break;
  }
};

export default function DatePickerFormItem(props) {
  const {
    name,
    required,
    disabled,
    onChange,
    label,
    labelString,
    fullWidth,
    minDate,
    maxDate,
    small,
    format,
    views,
    isEnOnly
  } = props;

  const {
    register,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useFormContext();

  const ReplaceThFormat = (text) => {
    return text
      .toLowerCase()
      .replaceAll("d", "ว")
      .replaceAll("m", "ด")
      .replaceAll("y", "ป");
  };

  const formatDefalut = format;
  const MapPlaceHolder = {
    en: formatDefalut,
    th: ReplaceThFormat(formatDefalut),
  };

  const [locale] = React.useState<string>(() => {
    if (isEnOnly) {
      return "en";
    } else {
      return secureLocalStorage.getItem('language')
        ? secureLocalStorage.getItem('language') + ""
        : "th";
    }

  }
  );
  const inputPropsCustoms: any = mobileCheck(window) ? {} : { inputProps: { readOnly: true, } };

  const [objCheckReplace] = useState(() => {
    localeMap(locale);
    let objReturn = { isReplaceYear: false, indexArr: 0, splitText: "" };
    var formatLower = formatDefalut.toLowerCase();
    if (formatLower.includes("yyyy")) {
      var splitText = formatDefalut
        .substr(formatLower.indexOf("yyyy") - 1, 1)
        .toLowerCase();
      if (splitText !== "y") {
        objReturn.splitText = splitText;
        objReturn.indexArr = formatLower
          .split(splitText)
          .findIndex((e) => e === "yyyy");
      }
      objReturn.isReplaceYear = true;
    }
    return objReturn;
  });

  const MapLocale = {
    en: LocaleEN,
    th: LocaleTH,
  };


  return (
    <LocalizationProvider
      dateAdapter={CustomAdapter}
      locale={locale}
    // dateAdapter={AdapterDateFns}
    // locale={th}
    >
      <FormControl
        sx={{
          "label.MuiInputLabel-shrink": {
            backgroundColor: "rgba(255,255,255,0.95)",
            top: "0px",
          },
          ".MuiInputLabel-outlined": {
            top: "0px",
          },
          ".MuiInputLabel-asterisk": {
            color: "red",
          },
          "div.MuiOutlinedInput-root fieldset.MuiOutlinedInput-notchedOutline":
          {
            borderColor: Boolean(errors[name]) ? "red" : "",
          },
        }}
      >
        {/* <DatePicker
          inputRef={register(name).ref}
          {...register(name)}
          // mask={ "__/__/____"}
          // mask={maskMap[locale]}
          value={watch(name) || null}
          onChange={(e) => {
            setValue(name, e, { shouldValidate: true });
            onChange && onChange(e);
          }}
          views={views}
          minDate={minDate || undefined}
          maxDate={maxDate || undefined}
          label={label}
          inputFormat={formatDefalut}
          disabled={disabled || false}
          // components={{
          //   OpenPickerIcon: AbcIcon
          // }}
          // DialogProps={{ sx: { '& .PrivateDatePickerToolbar-penIcon,& .MuiPickersToolbar-penIconButton': { display: 'none' } } }}
          renderInput={(propsInput) => {
            propsInput.inputProps.placeholder = MapPlaceHolder[locale];

            if (locale === "th") {
              if (propsInput.inputProps.value && objCheckReplace.isReplaceYear) {
                var year = objCheckReplace.splitText
                  ? propsInput.inputProps.value.split(objCheckReplace.splitText)[
                  objCheckReplace.indexArr
                  ]
                  : propsInput.inputProps.value.substr(0, 4);
                // var addyear = parseInt(year) + 543;
                var addyear = parseInt(year);
                propsInput.inputProps.value = propsInput.inputProps.value.replaceAll(
                  `${year}`,
                  `${addyear}`
                );
              }
            }

            return (
              <TextField
                {...propsInput}
                sx={{
                  ".MuiOutlinedInput-root": {
                    padding: "0px 15px !important",
                    " fieldset > legend > span": {
                      padding: "0px !important",
                    },
                  },
                  fontWeight: 600,

                }}
                size={small ? "small" : "medium"}
                label={label}
                fullWidth={fullWidth}
                name={name}
                error={Boolean(errors[name])}
                required={required || false}
                // inputProps={
                //   { readOnly: true, }
                // }
                {...inputPropsCustoms}
                disabled={disabled || false}
                value={propsInput.inputProps.value || ""}
                // value={watch(name) || ""}
                autoComplete={"off"}
                // onClick={()=>alert("check")}
              />
            );
          }}
        /> */}

        <DesktopDatePicker
          inputRef={register(name).ref}
          {...register(name)}
          value={watch(name) || null}
          onChange={(e) => {
            setValue(name, e, { shouldValidate: true });
            onChange && onChange(e);
          }}
          views={views}
          minDate={minDate || undefined}
          maxDate={maxDate || undefined}
          label={label}
          inputFormat={formatDefalut}
          disabled={disabled || false}
          // DialogProps={{ sx: { '& .PrivateDatePickerToolbar-penIcon,& .MuiPickersToolbar-penIconButton': { display: 'none' } } }}
          renderInput={(propsInput) => {
            propsInput.inputProps.placeholder = MapPlaceHolder[locale];

            if (locale === "th") {
              if (propsInput.inputProps.value && objCheckReplace.isReplaceYear) {
                var year = objCheckReplace.splitText
                  ? propsInput.inputProps.value.split(objCheckReplace.splitText)[
                  objCheckReplace.indexArr
                  ]
                  : propsInput.inputProps.value.substr(0, 4);
                // var addyear = parseInt(year) + 543;
                var addyear = parseInt(year);
                propsInput.inputProps.value = propsInput.inputProps.value.replaceAll(
                  `${year}`,
                  `${addyear}`
                );
              }
            }
            return (
              <TextField
                // type="date"
                {...propsInput}
                sx={{
                  ".MuiOutlinedInput-root": {
                    padding: "0px 15px !important",
                    " fieldset > legend > span": {
                      padding: "0px !important",
                    },
                  },
                  fontWeight: 600,
                }}
                size={small ? "small" : "medium"}
                label={label}
                fullWidth={fullWidth}
                name={name}
                error={Boolean(errors[name])}
                required={required || false}
                {...inputPropsCustoms}
                disabled={disabled || false}
                value={propsInput.inputProps.value || ""}
                autoComplete={"off"}
                inputProps={{
                  readOnly: true
                }}
              />
            );
          }}
        />

        {errors && errors[name] ? (
          <FormHelperText sx={{ color: "red" }}>
            {errors[name].message}
          </FormHelperText>
        ) : null}
      </FormControl>
    </LocalizationProvider>
  );
}

DatePickerFormItem.defaultProps = {
  fullWidth: true,
  small: true,
  views: ["year", "month", "day"],
  // format: "dd/MM/yyyy", // Default For DNS
  format: "DD/MM/YYYY", // Default For Moment
  isEnOnly: false
};

DatePickerFormItem.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.any,
  labelString: PropTypes.any,
  fullWidth: PropTypes.bool,
  minDate: PropTypes.any,
  maxDate: PropTypes.any,
  small: PropTypes.bool,
  format: PropTypes.string,
  views: PropTypes.array,
  isEnOnly: PropTypes.bool
};


class CustomAdapter extends AdapterMoment {
  // REF : https://th-samranrit0193.medium.com/reactjs-materialui-buddhist-era-%E0%B8%9B%E0%B8%B5-%E0%B8%9E-%E0%B8%A8-1f293af00382
  constructor({ locale, formats, instance }) {
    super({ locale, formats, instance });
  }

  calYear(moment) {
    switch (this.locale) {
      case "th":
        return (parseInt(moment.format('YYYY'))).toString();
      case "en":
        return parseInt(moment.format('YYYY'));
      default:
        return parseInt(moment.format('YYYY'));
    }
  }

  renderLabel(moment, format) {

    switch (format) {
      case "year":
        return this.calYear(moment);
      case "month":
        return moment.format("MMMM");
      case "fullDate":
        return moment.format("DDMMYYYY");
      case "dayOfMonth":
        return moment.format("D");
      case "monthShort":
        return moment.format("MMMM");
      case "monthAndYear":
        return `${moment.format("MMMM ")} ${this.calYear(moment)}`;
      case "normalDate":
        return `${moment.format("dd")} ${moment.format("MMM")} ${moment.format("D")}`;
      default:
        return moment.format(format);
    }
  }

  startOfMonth = (date) => date ? date.clone().startOf("month") : moment(new Date());

  format = (date, formatKey) => {
    return date ? this.renderLabel(date, formatKey) : moment(new Date());
  };
}