import React from "react";
import { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@mui/styles";
import { i18n } from "i18n";
import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Skeleton,
  Tooltip,
} from "@mui/material";
import Content from "./Content";
import { padding } from "@mui/system";
import { useLocation, useNavigate } from "react-router-dom";
import JobDetail from "view/font/JobDetail/JobDetail";
import secureLocalStorage from "react-secure-storage";

const useStyles = makeStyles((theme) => ({
  JobName: {
    color: "#232474",
  },
  JobQuantity: {
    color: "#34bae6",
  },
  JobLabel1: {
    color: "#232474",
    fontSize: "1rem !important",
  },
  JobLabel2: {
    color: "#34bae6",
    fontSize: "1rem !important",
  },
  JobDivider1: {
    color: "#232474",
    marginRight: "30px !important",
  },
  JobDivider2: {
    color: "#34bae6",
  },
}));

const DetailJob = (props) => {
  const location = useLocation();
  const classes = useStyles();
  const navigate = useNavigate();
  const i18nLabel = "jobDetail.label";
  const { jobPost, sJobPostID = "", linkCompany } = props;
  const isLoading = props.isLoading;
  const [lang, setLang] = useState("");

  const objJobDetail = location?.state ? location.state["objJobDetail"] : null;

  useEffect(() => {
    setLang(secureLocalStorage.getItem("language").toString());
  }, []);

  return (
    <>
      {isLoading ? (
        <Skeleton variant="rectangular" width="100%" sx={{ marginBottom: 3 }}>
          <div style={{ paddingTop: "25%" }} />
        </Skeleton>
      ) : (
        // sx={{ maxWidth: "calc(100vw - 26%);" }}
        <Grid container >
          <Grid container item xs={12}>
            <Grid item xs={10}>
              <Grid item xs={12}>
                <Typography variant="h4" className={classes.JobName}>
                  {lang == "th" ? jobPost.sJob_TH : jobPost.sJob_EN}
                </Typography>
              </Grid>
              <Grid item xs={12} marginBottom={4}>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">
                  {i18n(`${i18nLabel}.jobDescription`)}
                </Typography>
                <Divider style={{ width: "30%" }} />
              </Grid>
              <Grid item xs={12} marginTop={2} >  {/* No scrollbar */}
                {jobPost.lstJobContent != null &&
                  jobPost.lstJobContent.length > 0 ? (
                  <Content lstContent={jobPost.lstJobContent} lstJobPost={jobPost.lstCompanyJob} />
                ) : (
                  ""
                )}
                {/* <Typography
                  className="ellipsis-label"
                  sx={{
                    whiteSpace: "normal",
                    wordBreak: "break-word",
                  }}
                >
                  {jobPost.lstJobContent != null &&
                    jobPost.lstJobContent.length > 0 ? (
                    <Content lstContent={jobPost.lstJobContent} lstJobPost={jobPost.lstCompanyJob} />
                  ) : (
                    ""
                  )}
                </Typography> */}
              </Grid>

              <Grid item xs={12} marginTop={3}>
                <Typography variant="h6">
                  {i18n(`${i18nLabel}.qualifications`)}
                </Typography>
                <Divider style={{ width: "30%" }} />
              </Grid>
              <Grid item xs={12} marginBottom={2}>
                <List>
                  {jobPost.lstJobPoint != null && jobPost.lstJobPoint.length > 0
                    ? jobPost.lstJobPoint.map((item, index) => {
                      return (
                        <ListItem key={index} sx={{ ml: 2, p: 0 }}>
                          <ListItemText
                            primary={`${index + 1}. ${item.sPoint_Detail}`}
                          />
                        </ListItem>
                      );
                    })
                    : null}
                </List>
              </Grid>
            </Grid>
            <Grid item xs={2}>
              {sJobPostID != "" ?
                <Tooltip title={i18n(`home.btnViewCompany`)}>

                  <img height="700" src={jobPost.sCompanyFile_Logo} alt=""
                    onClick={() => {
                      // navigate(`/JobDetail?sID=${sJobPostID}`);
                      navigate(`/Guest/Company?sID=${sJobPostID}`);
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </Tooltip>
                :
                <img height="700" src={jobPost.sCompanyFile_Logo} alt="" />
              }
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" className={classes.JobLabel1}>
              {i18n(`${i18nLabel}.careerLevel`)}
            </Typography>
            <Divider className={classes.JobDivider1} />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" className={classes.JobLabel2}>
              {(jobPost.lstCareerLevel_TH != null || jobPost.lstCareerLevel_EN != null) &&
                (jobPost.lstCareerLevel_TH.length > 0 || jobPost.lstCareerLevel_EN.length > 0) ?
                lang == "th"
                  ? jobPost.lstCareerLevel_TH.join(", ")
                  : jobPost.lstCareerLevel_EN.join(", ")
                : "-"}
            </Typography>
            <Divider className={classes.JobDivider2} />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" className={classes.JobLabel1}>
              {i18n(`${i18nLabel}.yearsExperience`)}
            </Typography>
            <Divider className={classes.JobDivider1} />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" className={classes.JobLabel2}>
              {jobPost.sYearsExperience} {lang === "th" ? " ปี" : " Years"}
            </Typography>
            <Divider className={classes.JobDivider2} />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" className={classes.JobLabel1}>
              {i18n(`${i18nLabel}.qualification`)}
            </Typography>
            <Divider className={classes.JobDivider1} />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" className={classes.JobLabel2}>
              {(jobPost.lstEducation_TH != null || jobPost.lstEducation_EN != null) &&
                (jobPost.lstEducation_TH.length > 0 || jobPost.lstEducation_EN.length > 0) ?
                lang == "th"
                  ? jobPost.lstEducation_TH.join(", ")
                  : jobPost.lstEducation_EN.join(", ")
                : "-"}
            </Typography>
            <Divider className={classes.JobDivider2} />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" className={classes.JobLabel1}>
              {i18n(`${i18nLabel}.industry`)}
            </Typography>
            <Divider className={classes.JobDivider1} />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" className={classes.JobLabel2}>
              {lang == "th"
                ? jobPost.sIndustry_TH || "-"
                : jobPost.sIndustry_EN || "-"}
            </Typography>
            <Divider className={classes.JobDivider2} />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" className={classes.JobLabel1}>
              {i18n(`${i18nLabel}.salary`)}
            </Typography>
            <Divider className={classes.JobDivider1} />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" className={classes.JobLabel2}>
              {jobPost.sSalaryRange}
            </Typography>
            <Divider className={classes.JobDivider2} />
          </Grid>
          {/* other information */}
          <Grid item xs={6}>
            <Typography variant="h6" className={classes.JobLabel1}>
              {i18n(`${i18nLabel}.otherInfo`)}
            </Typography>
            <Divider className={classes.JobDivider1} />
          </Grid>
          {/* other information */}
          <Grid item xs={6}>
            <Typography variant="h6" className={classes.JobLabel2}>
              {(jobPost.lstOtherInfo_TH != null || jobPost.lstOtherInfo_EN != null) &&
                (jobPost.lstOtherInfo_TH.length > 0 || jobPost.lstOtherInfo_EN.length > 0) ?
                lang == "th"
                  ? jobPost.lstOtherInfo_TH.join(", ")
                  : jobPost.lstOtherInfo_EN.join(", ")
                : "-"}
            </Typography>
            <Divider className={classes.JobDivider2} />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" className={classes.JobLabel1}>
              {i18n(`${i18nLabel}.jobType`)}
            </Typography>
            <Divider className={classes.JobDivider1} />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" className={classes.JobLabel2}>
              {(jobPost.lstEmploymentType_TH != null || jobPost.lstEmploymentType_EN != null) && (jobPost.lstEmploymentType_TH.length > 0 || jobPost.lstEmploymentType_EN.length > 0) ?
                lang == "th" ?
                  jobPost.lstEmploymentType_TH.join(", ")
                  : jobPost.lstEmploymentType_EN.join(", ")
                : "-"
              }
            </Typography>
            <Divider className={classes.JobDivider2} />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" className={classes.JobLabel1}>
              {i18n(`${i18nLabel}.benefits`)}
            </Typography>
            <Divider className={classes.JobDivider1} />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" className={classes.JobLabel2}>
              {jobPost.lstJobBenefit_TH != null &&
                jobPost.lstJobBenefit_TH.length > 0
                ? lang == "th"
                  ? jobPost.lstJobBenefit_TH.join(", ")
                  : jobPost.lstJobBenefit_EN.join(", ")
                : ""}
            </Typography>
            <Divider className={classes.JobDivider2} />
          </Grid>
        </Grid>
      )}
    </>
  );
};
export default DetailJob;