import React, { useEffect, useState } from "react";
import Filter from "components/EmployerSearch/Filter"
import { Grid, CircularProgress, Pagination } from "@mui/material"
import CardItem from "components/EmployerSearch/CardItem";
import { AxiosPost } from "service/CommonFunction/TS_function";
import { FnBlock_UI } from "service/SystemFunction/SystemFunction";
import { useDispatch } from "react-redux";
import { DialogActionCreators } from "store/redux/DialogAlert";
import ModalInvite from "components/EmployerSearch/ModalInvite"
import ModalBuyProfile from "components/EmployerSearch/ModalBuyProfile";
import { getLanguage } from "i18n";
import ModalDownloandProfile from "components/EmployerSearch/ModalDownload";
export default function EmployerSearch() {
    const dispatch = useDispatch();
    const [lstData, setLstData] = useState([]);
    const { BlockUI, UnBlockUI } = FnBlock_UI();
    const [isLoading, setLoading] = useState(false)
    const ControllerApi = "EmployerSearch";
    const [resultFilter, setResultFilter] = useState(null);
    const [isOpenModal, setIsOpenModal] = useState(false)
    const [isOpenModalBuyProfile, setIsOpenModalBuyProfile] = useState(false)
    const [isChangePage, setChangePage] = useState(false)
    const [sJsId, setJsId] = useState("");
    const [isOpenModalDownload, setIsOpenModalDownload] = useState(false)
    const [objPaging, setPaging] = useState({
        nPage: 1,
        nPageSize: 10,
        nTotal: 0,
    });
    const getFilterOption = () => {
        let lang = "";
        let isTH = (getLanguage().id === "th");
        isTH ? lang = "th" : lang = "en"
        BlockUI();
        AxiosPost(`${ControllerApi}/GetFilterOption`, { lang }, (res) => {
            setResultFilter(res.Data)

        }, (err) => {
            dispatch(DialogActionCreators.OpenDialogWarning(err.Message) as any)
        }, UnBlockUI)
    }
    useEffect(() => {
        getFilterOption();
    }, [])

    const Download = () => {
        setIsOpenModalDownload(true);
    }
    return (
        <>
            <ModalBuyProfile sJsId={sJsId} setJsId={() => setJsId("")} isOpen={isOpenModalBuyProfile} setOpen={setIsOpenModalBuyProfile} lstData={lstData} setLstData={setLstData} Download={Download} />
            <ModalInvite sJsId={sJsId} setJsId={() => setJsId("")} isOpen={isOpenModal} setOpen={setIsOpenModal} />
            <ModalDownloandProfile sJsId={sJsId} setJsId={() => setJsId("")} isOpen={isOpenModalDownload} setOpen={setIsOpenModalDownload} lstData={lstData} setLstData={setLstData} />
            <Grid
                container
                spacing={3}
                direction="column"
                sx={{ marginTop: "0.5%" }}
                // fade-bg-color
                className="p-[1em] rounded-[1em] bg-[white] min-h-[350px] shadow mb-[1em]"
            >
                <Filter objPaging={objPaging} setChangePage={setChangePage} isChangePage={isChangePage} setPaging={setPaging} setLstData={setLstData} setLoading={setLoading} dataFilter={resultFilter} />
                <div className="px-[1em] flex flex-col gap-[1em] mt-[1em]">
                    {!isLoading ? lstData.map((item, index) => {
                        return (
                            <CardItem setJsId={setJsId} setIsOpenModalBuyProfile={() => setIsOpenModalBuyProfile(true)}
                                setIsOpenModal={() => setIsOpenModal(true)} optionFilter={resultFilter}
                                key={`card_item_${index}`} lstData={lstData} setLstData={setLstData} item={item} />
                        )
                    }) : (
                        <div className="flex flex-1 justify-center items-center">
                            <CircularProgress />
                        </div>
                    )}
                </div>
                {!isLoading && objPaging.nTotal > objPaging.nPageSize && (
                    <Grid container justifyContent={"center"} sx={{ marginTop: "3rem" }}>
                        <Grid item>
                            <Pagination
                                variant="outlined"
                                shape="rounded"
                                showFirstButton
                                showLastButton
                                count={Math.ceil(objPaging.nTotal / objPaging.nPageSize)}
                                page={objPaging.nPage}
                                onChange={(e, page) => {
                                    setPaging({ ...objPaging, nPage: page })
                                    setChangePage(true)
                                }}
                            />
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </>
    )
}