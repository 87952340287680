import { Box } from '@mui/material';
import ProfileProvider from 'components/SeekerProfile/Context/ProfileContext';
import React from 'react';
import MyProfile from 'components/SeekerProfile/MyProfile';

export default function BoxContentProfile() {
    return (
        <ProfileProvider>
            <Box sx={{ width: '100%' }}>
                <MyProfile />
            </Box>
        </ProfileProvider>
    )
}