import { useState, useEffect } from 'react';
import { AxiosPost, GetPermission, ResultAPI } from "service/CommonFunction/TS_function";
import { useNavigate } from "react-router-dom";
import { BtnEditOnTable } from "components/Common/Button"
import { useDispatch } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { Button, Grid, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { i18n } from 'i18n';


const FormTablelBannerPackage = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const [nPermission, setPermission] = useState(0);
  const [loadingTable, setLoadingTable] = useState(true);
  const [arrDuration, setarrDuration] = useState([]);
  const [arrlstRow, setarrlstRow] = useState([]);
  const isEditMode = (true);
  const i18nField = 'entities.Package';


  const objSchema = {};
  const schema = yup.object().shape(objSchema);

  const form = useForm({
    resolver: yupResolver(schema),
    shouldUnregister: false,
    shouldFocusError: true,
    mode: "all"
  });

  useEffect(() => {
    GetPermission(5, setPermission);
    loadData();
  }, [])

  const loadData = () => {
    setLoadingTable(true)
    AxiosPost("AdminUserPackage/SetFilter_TableBannerDataPackage", {}, (res) => {
      setarrDuration(res.lstDuration);
      setarrlstRow(res.lstRow);
      setLoadingTable(false)

    });
  };

  const goToEdit = () => {
    history(`/CreateBannerPackage?isEditMode=${(isEditMode)}`);
  };

  return (
    <FormProvider {...form}>
      <Grid className="FormCreateBannerPackage">
        <Stack spacing={2} direction="column" >
          <Grid item xs={12}>
            <Grid container >
              <Grid item md={2}xs={6} >
                <Button
                  disabled={nPermission !== 2}
                  variant="contained" fullWidth sx={{
                    borderRadius: "20px", backgroundColor: "#ffc107", color: "black", ":hover": { bgcolor: "#cc9900" }
                  }} onClick={() => goToEdit()}  > {'แก้ไข'}
                </Button>
              </Grid>
              <Grid item xs={10} ></Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}
            sx={{
              overflow: "scroll",
              maxWidth: '100%',
              borderRadius: "10px",
            }}

          >
            <TableContainer component={Paper} >
              <Table aria-label="simple table">
                <TableHead >
                  <TableRow sx={{ background: "#eeeeee", border: " 1px solid #dddddd" }}>
                    <TableCell component="th" scope="row" align="center" sx={{ width: "200px"}} >
                      {"แพ็กเกจ"}
                    </TableCell>
                    {arrDuration != null && arrDuration.length > 0 ? arrDuration.map((item, index) => {
                      return (
                        <TableCell align="center" key={index} sx={{ border: " 1px solid #dddddd", width: "200px"}}>
                          {item.sName} {"เดือน"}
                        </TableCell>

                      )
                    }) : null}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {arrlstRow != null && arrlstRow.length > 0 ? arrlstRow.map((item, index) => {
                    return (<TableRow key={index}>
                      <TableCell key={index} align="center" sx={{ border: " 1px solid #dddddd" }} >
                        {item.sName}
                      </TableCell>

                      {arrDuration != null && item.lstValue.length > 0 ? arrDuration.map((itemSub, indexSub) => {
                        let nvar = item.lstValue.find(e => e.nBannerPackage_ID == itemSub.nBannerPackage_ID)
                        if (nvar != null) {
                          return (<TableCell key={nvar.nPosition_ID + "_" + nvar.nBannerPackage_ID} align="center" sx={{ border: " 1px solid #dddddd" }}>
                            {nvar.sName}
                          </TableCell>
                          )
                        }
                      }) : null}
                    </TableRow>)
                  }) : null}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Stack>
      </Grid >
    </FormProvider >
  );
}
export default FormTablelBannerPackage
