import { useState, useEffect, useContext } from "react";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Grid,
  Typography,
  Stack,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  FormControl,
  FormGroup,
  InputAdornment,
  IconButton,
  Dialog,
  AppBar,
  Toolbar,
} from "@mui/material";
import { i18n } from "i18n";
import yupFormSchemas, {
  RegexpPassword,
} from "components/Common/yup/yupFormSchemas";
import Input from "components/Common/ElementInForm/InputCustomLabelFormItem";
import { ButtonBlueRadius } from "components/SeekerProfile/ElementCustom";
import { AxiosPost, ResultAPI } from "service/CommonFunction/TS_function";
import { ProfileContext } from "components/SeekerProfile/Context/ProfileContext";
import { IMyProfile } from "components/SeekerProfile/Interface";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Link } from "react-router-dom";
import {
  dataContent,
  objContent,
} from "components/Banner/AdminContent/ManageContent";
import CloseIcon from "@mui/icons-material/Close";
import ContentPage from "view/font/Home/ContentPage";
import AutoCompleteRenderItem from "components/Common/ElementInForm/AutoCompleteRenderItem";
import { DialogActionCreators } from "store/redux/DialogAlert";
import { useDispatch } from "react-redux";

interface ICreateProfile {
  Name: string;
  Surname: string;
  NameEN: string;
  SurnameEN: string;
  Email: string;
  PWD: string;

}

interface IPorpsProfileFirst {
  children?: React.ReactNode;
  onSuccessCreate: Function;
}
const ProfileFirst = (props: IPorpsProfileFirst) => {
  const i18nField = "entities.SeekerProfile";
  const dispatch = useDispatch();
  const { onSuccessCreate } = props;

  const { myProfileContext, setMyProfile } = useContext(ProfileContext);
  const [showCPassword, setShowCPassword] = useState(false);

  const [isCheckedConsent2, SetIsCheckedConsent2] = useState<boolean>(true);
  const [isCheckedConsent3, SetIsCheckedConsent3] = useState<boolean>(true);
  const [errorConsent, setErrorConsent] = useState(false);
  const [isEmailDup, setIsEmailDup] = useState(false);

  //Policy
  const [lstContentTerm, setlstContentTerm] = useState([]);
  const [lstContent, setLstPreviewContent] = useState<objContent[]>([]);
  const [isShowPreview, setIsShowPreview] = useState<boolean>(false);

  const objSchema = {
    // Name: yupFormSchemas.string(i18n(`${i18nField}.Name`), { required: true }).matches(/^[\u0E00-\u0E7F]+$/, i18n(`entities.SeekerProfile.ErrorName`)),
    // Surname: yupFormSchemas.string(i18n(`${i18nField}.Surname`), { required: true }).matches(/^[\u0E00-\u0E7F]+$/, i18n(`entities.SeekerProfile.ErrorName`)),
    Name: yupFormSchemas.string(i18n(`${i18nField}.Name`), { required: true }),
    Surname: yupFormSchemas.string(i18n(`${i18nField}.Surname`), { required: true }),
    NameEN: yupFormSchemas
      .string(i18n(`${i18nField}.NameEN`), {
        required: true,
      })
      .matches(/^[a-zA-Z]+$/, i18n(`${i18nField}.ErrorNameEN`)),
    SurnameEN: yupFormSchemas
      .string(i18n(`${i18nField}.SurnameEN`), {
        required: true,
      })
      .matches(/^[a-zA-Z]+$/, i18n(`${i18nField}.ErrorNameEN`)),
    Tel: yupFormSchemas.string(i18n(`${i18nField}.Tel`), { required: true }),
    Email: yupFormSchemas.email(i18n(`${i18nField}.Email`), { required: true }),
    PWD: yupFormSchemas.string(i18n(`${i18nField}.PWD`), {
      required: true,
      matches: RegexpPassword(),
      labelmatches: i18n("validation.string.Password"),
    }),
  };
  const initialValue = {
    Name: "",
    Surname: "",
    NameEN: "",
    SurnameEN: "",
    Email: "",
    PWD: "",
  } as ICreateProfile;
  const schema = yup.object().shape(objSchema);

  const form = useForm({
    resolver: yupResolver(schema),
    shouldUnregister: false,
    shouldFocusError: true,
    defaultValues: initialValue,
    mode: "all",
  });

  const CheckExistMail = (value) => {
    const param = {
      sEmail: value,
    };
    AxiosPost("Guest/GetAlreadyEmail", param, (res) => {
      const data = res.Data;
      if (data.IsAlready) {
        setIsEmailDup(true);
        form.setError("Email", { type: "custom" });
      } else {
        setIsEmailDup(false);
        // form.clearErrors("Email");
      }
    });
  };

  const onSubmit = (values) => {
    if (isEmailDup) {
      form.setError("Email", { type: "custom" });
      return;
    }
    if (isCheckedConsent3) {
      const account = {
        Name: values.Name,
        Surname: values.Surname,
        NameEN: values.NameEN,
        SurnameEN: values.SurnameEN,
        Tel: values.Tel,
        Email: values.Email,
        PWD: values.PWD,
        IsGetNews: isCheckedConsent2,
        IsUnderage: isCheckedConsent3,
      } as IMyProfile;

      setMyProfile(account);
      onSuccessCreate();
    } else {
      setErrorConsent(true);
    }
  };

  const onPreview = async (nID) => {
    let param = {
      nID,
    };

    let lstPreviewContent = [];
    let show = [];
    await AxiosPost(
      `HomeFront/getData_ContentDetail`,
      param,
      (res) => {
        ResultAPI(res, "", () => {
          setlstContentTerm(res.contentShow);
          show = res.contentShow;
        });
      },
      (err) => {
        if (!err.response) {
          return;
        }
      }
    );
    show.forEach((f, i) => {
      const nType = f.nType;
      let sContent = f.sContent;
      let fileContent = f.sFilePath;
      let objViewContent = {
        nType: nType,
        sContent: sContent,
        sFileNameSys: fileContent,
      };
      lstPreviewContent.push(objViewContent);
    });
    setLstPreviewContent(lstPreviewContent);
    setIsShowPreview(true);
  };

 

  return (
    <FormProvider {...form}>
      <Stack>
        <Grid container justifyContent="center" alignItems="center" spacing={3}>
          <Grid item container spacing={5} xs={12}>
            <Grid item xs={12} sm={12} md={6}>
              <Input
                name={"Name"}
                fullWidth
                label={
                  <Typography sx={{ fontWeight: 400 }} component="label">
                    {i18n(`${i18nField}.Name`)}
                  </Typography>
                }
                small
                required
                maxLength={200}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Input
                name={"Surname"}
                fullWidth
                required
                maxLength={200}
                small
                label={
                  <Typography sx={{ fontWeight: 400 }} component="label">
                    {i18n(`${i18nField}.Surname`)}
                  </Typography>
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Input
                name={"NameEN"}
                fullWidth
                label={
                  <Typography sx={{ fontWeight: 400 }} component="label">
                    {i18n(`${i18nField}.NameEN`)}
                  </Typography>
                }
                small
                required
                maxLength={200}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Input
                name={"SurnameEN"}
                fullWidth
                required
                maxLength={200}
                small
                label={
                  <Typography sx={{ fontWeight: 400 }} component="label">
                    {i18n(`${i18nField}.SurnameEN`)}
                  </Typography>
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Input
                name={"Tel"}
                fullWidth
                required
                maxLength={100}
                small
                label={
                  <Typography sx={{ fontWeight: 400 }} component="label">
                    {i18n(`${i18nField}.Tel`)}
                  </Typography>
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Input
                name={"Email"}
                fullWidth
                required
                maxLength={200}
                small
                label={
                  <Typography sx={{ fontWeight: 400 }} component="label">
                    {i18n(`${i18nField}.Email`)}
                  </Typography>
                }
                onBlur={(value) => {
                  CheckExistMail(value.target.value);
                }}
              />
              {isEmailDup ? (
                <FormHelperText style={{ color: "red" }}>
                  {i18n(`${i18nField}.ErrorEmail`)}
                </FormHelperText>
              ) : null}
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Input
                name={"PWD"}
                fullWidth
                required
                //maxLength={100}
                //label={i18n(`${i18nField}.PWD`)}
                small={true}
                type={showCPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle p0assword visibility"
                      edge="end"
                      onClick={() => setShowCPassword(!showCPassword)}
                    >
                      {showCPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label={
                  <Typography sx={{ fontWeight: 400 }} component="label">
                    {i18n(`${i18nField}.PWD`)}
                  </Typography>
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent="center" alignItems="center" spacing={1}>
          <Grid item xs={12}>
            <FormControl
              required
              error={errorConsent}
              component="fieldset"
              variant="standard"
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isCheckedConsent2}
                      onChange={() => {
                        SetIsCheckedConsent2((prev) => !prev);
                      }}
                    />
                  }
                  label={i18n(`${i18nField}.ConsentLine2`)}
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isCheckedConsent3}
                      onChange={() => {
                        SetIsCheckedConsent3((prev) => !prev);
                      }}
                    />
                  }
                  label={i18n(`${i18nField}.ConsentLine3`)}
                />
              </FormGroup>

              {errorConsent ? (
                <FormHelperText>
                  {i18n(`${i18nField}.ErrorConsent`)}
                </FormHelperText>
              ) : null}
            </FormControl>
          </Grid>
        </Grid>
      </Stack>
      <Grid container
        spacing={2}
        direction="row"
        justifyContent="left"
        alignItems="left"
        sx={{ marginTop: "1%" }}
      >
        <Grid item xs={12}>
          <Typography sx={{ color: "#b2b2b2", fontSize: "14px" }}>
            {i18n(`${i18nField}.Policy`)}{" "}
            <span
              style={{ color: "#2979ff", cursor: "pointer" }}
              onClick={() => onPreview(3)}
            >
              {i18n(`${i18nField}.Policy2`)}{" "}
            </span>
            {i18n(`${i18nField}.Policy3`)}{" "}
            <span
              style={{ color: "#2979ff", cursor: "pointer" }}
              onClick={() => onPreview(1)}
            >
              {i18n(`${i18nField}.Policy4`)}{" "}
            </span>
            {i18n(`${i18nField}.Policy5`)}
          </Typography>
        </Grid>
        {/* <Grid item xs={12}>
          <ButtonBlueRadius onClick={form.handleSubmit(onSubmit)}>
            {i18n(`${i18nField}.BtnCreateProfile`)}
          </ButtonBlueRadius>
        </Grid> */}
      
        <Grid item xs={12}>
          <Grid container spacing={1} sx={{ justifyContent: "center" }}>
            <Grid item>
              <ButtonBlueRadius onClick={form.handleSubmit(onSubmit)}>
                {i18n(`${i18nField}.BtnCreateProfile`)}
              </ButtonBlueRadius>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Dialog
        fullScreen
        open={isShowPreview}
        onClose={() => setIsShowPreview(false)}
      >
        <AppBar sx={{ position: "relative", marginBottom: "3rem" }}>
          <Toolbar>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => setIsShowPreview(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Grid container>
          {/* <Grid item sx={{ mx: "25%" }}> */}
          <Grid xs={12} item sx={{ mx: "11%" }}>
            <ContentPage lstContent={lstContent} OrderEmp={false} sContendHead={undefined} />
          </Grid>
        </Grid>
      </Dialog>
    </FormProvider >
  );
};
export default ProfileFirst;