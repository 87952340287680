import InputCustomLabel from 'components/Common/ElementInForm/InputCustomLabelFormItem'
import { useForm, FormProvider } from "react-hook-form"
import { Box, Card, Grid, Hidden, Stack, Typography } from "@mui/material"
import { BtnLoginWithLoading } from "components/Common/Button"
import yupFormSchemas from 'components/Common/yup/yupFormSchemas'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup";
import { useDispatch } from "react-redux"
import { useNavigate } from 'react-router-dom'
import { AxiosPost, AzureFunction, GoogleFunction } from 'service/CommonFunction/TS_function'
import { FnBlock_UI } from 'service/SystemFunction/SystemFunction'
import secureLocalStorage from 'react-secure-storage';
import { useState } from 'react'
import { i18n } from 'i18n'
import Modal_Forgot from 'layout/CommonLayout/Layout_Font/Modal_Forgot'
import { DialogActionCreators } from 'store/redux/DialogAlert'
import Cookies from 'js-cookie';

export default function LoginEmployer(props: { lstMenu, FocusMenu, setFocusMenu, connection, SetisLoadMenu, isFromJobSeeker }) {
    const AuthenController = "Authen/";
    const { lstMenu, FocusMenu, setFocusMenu, connection, SetisLoadMenu, isFromJobSeeker } = props;
    const { LogoutAzure, LoginAzure, GetStateAzure, GetAccountAzure, SetConfigAzure } = AzureFunction();
    const { SetUpGoogleProvider } = GoogleFunction()
    const navigate = useNavigate();
    const i18nField = 'entities.LoginForm';
    const i18nCommon = 'common';
    const { BlockUI, UnBlockUI } = FnBlock_UI();
    const dispatchRedux = useDispatch();
    const [isLogout, setLogout] = useState<boolean>(false);
    const [nFocusIndex, setFocusIndex] = useState(null);
    const [lang, setLang] = useState("");
    const [sUser, setUser] = useState<any>();
    const [isOpenForgot, setOpenForgot] = useState<boolean>(false)
    const [GroupUser, setGroupUser] = useState<number>(0);
    const [isFormJobSeeker, setIsFormJobSeeker] = useState<boolean>(false);
    const [isLoadingSentForgot, setIsLoadingSendForgot] = useState<boolean>(false);
    const [modalLoginOpen, setModalLoginOpen] = useState(false);
    const handleLoginClose = () => {
        setModalLoginOpen(false);
    };

    const objSchema = {
        f_username: yupFormSchemas.string(i18n(`${i18nCommon}.email`), { required: true }),//email
        f_usercode: yupFormSchemas.string(i18n(`${i18nCommon}.memberPass`), { required: true }),//password
        b_remember: yupFormSchemas.string(i18n(`${i18nField}.RememberMe`))//ให้ฉันอยู่ในระบบต่อไป        
    }

    const initValue: any = {
        f_username: "",
        f_usercode: "",
        b_remember: "0",
    };
    const schema = yup.object().shape(objSchema);
    const formResolver = yupResolver(schema)
    const form = useForm({
        resolver: formResolver,
        shouldUnregister: false,
        shouldFocusError: true,
        mode: "all",
        defaultValues: initValue,
    });

    const onSubmit = (e) => {
        BlockUI();
        let userlogin = {
            sUserName: e.f_username,
            sUserCode: e.f_usercode,
            isRemember: e.b_remember,
            nType: 2,
        }

        // Login type : job seeker = 1 , employer = 2
        AxiosPost("Authen/NormalAuthen", userlogin, (res) => {
            if (res.sUserKeys) {
                if (e.b_remember == "1") { secureLocalStorage.setItem("isRemember", "1"); /*Cookies.set("isRemember", "1"); */}
                secureLocalStorage.setItem("sUserId", res.sUserKeys)
                secureLocalStorage.setItem("sUrlQrCode", res.sUrlQrCode)
                secureLocalStorage.setItem("isVerify", res.isEnable2Fa ? "1" : "")
                secureLocalStorage.setItem("sUserFullNameTH", res.sFullNameTH)
                secureLocalStorage.setItem("sUserFullNameEN", res.sFullNameEN)
                secureLocalStorage.setItem("nGroupType", res.nGroupType)
                secureLocalStorage.setItem("sUserNickName", res.sNickName)
                secureLocalStorage.setItem("sUserName", res.sUserName)
                secureLocalStorage.setItem(
                    process.env.REACT_APP_JWT_KEY as string,
                    res.sToken
                );

                // Cookies.set("sUserId", res.sUserKeys)
                // Cookies.set("sUrlQrCode", res.sUrlQrCode)
                // Cookies.set("isVerify", res.isEnable2Fa ? "1" : "")
                // Cookies.set("sUserFullNameTH", res.sFullNameTH)
                // Cookies.set("sUserFullNameEN", res.sFullNameEN)
                // Cookies.set("nGroupType", res.nGroupType)
                // Cookies.set("sUserNickName", res.sNickName)
                // Cookies.set("sUserName", res.sUserName);
                // Cookies.set(process.env.REACT_APP_JWT_KEY as string, res.sToken);
                // // navigate("/OrderEmployer");
                CheckUserRights(e);
            }
        }, (err) => {
            if (err.Status === 409) {
                dispatchRedux(DialogActionCreators.OpenDialogWarning(
                    i18n(`${i18nCommon}.msgAlertUsernameOrPassWrong`), () => { //This user was not found in the system
                    }) as any)
            } else if (err.Status === 405) {
                dispatchRedux(DialogActionCreators.OpenDialogWarning(
                    i18n(`${i18nCommon}.msAlertStatus`), () => { //Username or Password is Wrong
                    }) as any)
            } else {
                dispatchRedux(DialogActionCreators.OpenDialogWarning(err.Message) as any)
            }
        }, () => UnBlockUI())
    }

    const CheckUserRights = (e) => {
        let userlogin = {
            sUserName: e.f_username,
            sUserCode: e.f_usercode,
            isRemember: e.b_remember,
            nType: 2,
        }

        AxiosPost("CompanyEmployee/SearchData_CheckUserRights", userlogin, (res) => {
            if (res.isOrder) {
                navigate("/OrderEmployer");
            } else {
                navigate("/MyPageEmployer");
            }
        });
    }

    const handleClickForgot = () => {
        // handleLoginClose()
        setOpenForgot(true);
    }

    const onSubmitForgot = (e) => {
        setIsLoadingSendForgot(true)
        AxiosPost(`${AuthenController}SendRequestForgotPw`, { isJobSeeker: isFormJobSeeker, inp_email: e.inp_email, isMember: true }, (res) => {
            dispatchRedux(DialogActionCreators.OpenDialogSuccess(i18n(`common.AlertRequestSuccess`), () => {
                setOpenForgot(false)
            }) as any)
        }, err => {
            dispatchRedux(DialogActionCreators.OpenDialogWarning(err.Message) as any)
        }, () => {
            setIsLoadingSendForgot(false)
        })
    }

    const EmptyGrid = () => <Grid item xs={1} md={4} ></Grid>;

    return (
        <Grid
            container spacing={2}
            // columns={{ xs: 6 }}
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ height: "100%" }}
        >
            <EmptyGrid />
            <Grid item xs={10} md={4} >
                <Card sx={{ padding: "1em", borderRadius: "1em" }}>
                    <FormProvider {...form}>
                        <Stack spacing={1}>
                            <Typography variant={'h4'}
                                align='center'
                                sx={{ padding: "0.5em 1em" }} >{i18n(`${i18nCommon}.loginformember`)}</Typography>
                            <Box sx={{ paddingX: 1, width: "100%" }}>
                                <Typography variant={'caption'} sx={{ paddingLeft: "1em" }} >{i18n(`${i18nCommon}.memberName`)}</Typography>
                                <InputCustomLabel
                                    rounded
                                    name="f_username"
                                    onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                            form.setFocus("f_usercode")
                                        }
                                    }}
                                />
                            </Box>
                            <Box sx={{ paddingX: 1, width: "100%" }}>
                                <Typography variant={'caption'} sx={{ paddingLeft: "1em" }} >{i18n(`${i18nCommon}.memberPass`)}
                                    <span style={{ paddingLeft: "4px", color: '#1976d2', cursor: "pointer" }}
                                        onClick={() => { handleClickForgot() }}
                                    >{i18n(`${i18nField}.FotgetPassword`)}</span>
                                </Typography>
                                <InputCustomLabel
                                    rounded
                                    name="f_usercode"
                                    type="Password"
                                    onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                            const objForm = form.getValues();
                                            if (!objForm.f_username) {
                                                form.setValue("f_username", '', { shouldValidate: true });
                                                // form.setError("f_username")
                                            } else {
                                                onSubmit(objForm);
                                            }
                                        }
                                    }}
                                />
                            </Box>
                            {/* phone size */}
                            <Hidden smUp>
                                <Grid container>
                                    <Grid item xs={12} sx={{ padding: 1, width: "100%" }} >
                                        <BtnLoginWithLoading
                                            txt={i18n(`${i18nCommon}.loginmember`)}
                                            isLoading={false}
                                            onClick={form.handleSubmit(e => {
                                                onSubmit(e);
                                            })}
                                        />
                                    </Grid>
                                </Grid>
                            </Hidden>

                            {/* normal size */}
                            <Hidden smDown>
                                <Stack direction="row" sx={{ mx: "3vw" }}>
                                    <Grid container>
                                        <Grid item md={4} justifyContent="flex-start" sx={{ padding: 1, width: "100%" }} >
                                            <BtnLoginWithLoading
                                                txt={i18n(`${i18nCommon}.loginmember`)}
                                                isLoading={false}
                                                onClick={form.handleSubmit(e => {
                                                    onSubmit(e);
                                                })}
                                            />
                                        </Grid>
                                    </Grid>
                                </Stack>
                            </Hidden>
                        </Stack>
                    </FormProvider >
                </Card>
            </Grid>
            <EmptyGrid />

            <Modal_Forgot
                isOpen={isOpenForgot}
                setOpen={setOpenForgot}
                funcSubmitForgot={onSubmitForgot}
                isJobSeeker={isFormJobSeeker}
                isLoading={isLoadingSentForgot} />
        </Grid >
    )
}
function dispatch(arg0: any) {
    throw new Error('Function not implemented.')
}

function _onReceiveLoginHub(sConnectionId: any, isLogin: any) {
    throw new Error('Function not implemented.')
}
