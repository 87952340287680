import moment from "moment";
import { useState, createContext, useEffect, } from "react";
import { AxiosGet, AxiosPost } from "service/CommonFunction/TS_function";
import { cFile } from "service/CommonInterface/CommonInterface";
import {
  IWorkExperience,
  IProfileContext,
  IAddress,
  IEducation,
  ICertificate,
  ILanguageSkill,
  IMyJobPosition,
  IMyProfile,
  IComputerSkill,
} from "../Interface";
import { FnBlock_UI } from "service/SystemFunction/SystemFunction";
import { getLanguage } from "i18n";
import secureLocalStorage from "react-secure-storage";

const OptionLanguageTH = [
  { label: "ไทย", value: 0 },
  { label: "อังกฤษ", value: 1 },
  { label: "จีน", value: 2 },
  { label: "พม่า", value: 3 },
  { label: "ลาว", value: 4 },
  { label: "ญี่ปุ่น", value: 5 },
];
const OptionLanguageEN = [
  { label: "Thai", value: 0 },
  { label: "English", value: 1 },
  { label: "Chinese", value: 2 },
  { label: "Myanmar", value: 3 },
  { label: "Laos", value: 4 },
  { label: "japanese", value: 5 },
];

const OptionSkillTH = [
  { label: "ดีมาก", value: 0 },
  { label: "ดี", value: 1 },
  { label: "พอใช้", value: 2 },
];

const OptionSkillEN = [
  { label: "Very Good", value: 0 },
  { label: "Good", value: 1 },
  { label: "Fair", value: 2 },
];
export const ProfileContext = createContext<IProfileContext>({
  isLoading: true,
  setIsLoading: (data: boolean) => { },
  myProfileContext: {} as IMyProfile,
  setMyProfile: (data: any) => { },
  yearExperience: null,
  setYearExperience: (data: any) => { },
  isWorkExperience: false,
  setIsWorkExperience: (data: any) => { },
  arrWorkExperienceContext: [],
  setWorkExperience: (data: any) => { },
  addresssContext: {} as IAddress,
  setAddress: (data: any) => { },
  educationContext: [],
  setEducation: (data: any) => { },
  certificateContext: [],
  setCertificate: (data: any) => { },
  ability: [],
  setAbility: (data: any) => { },
  languageSkillContext: [],
  setLanguageSkill: (data: any) => { },
  jobPositionContext: [],
  setJobPosition: (data: any) => { },
  fileUploadContext: [],
  setFileUpload: (data: any) => { },
  profileContext: {},
  setProfileContext: (data: any) => { },
  computerSkillContext: [],
  setComputerSkill: (data: any) => { },
  optionPosition: [],
  setOptionPosition: (data: any) => { },
  optionCompany: [],
  setOptionCompany: (data: any) => { },
  optionJobFunction: [],
  setOptionJobFunction: (data: any) => { },
  optionJobDetail: [],
  setOptionJobDeatail: (data: any) => { },
  optionEducationLevel: [],
  setOptionEducationLevel: (data: any) => { },
  optionJobType: [],
  setOptionJobType: (data: any) => { },
  optionJobIndustry: [],
  setOptionJobIndustry: (data: any) => { },
  optionCountry: [],
  setOptionCountry: (data: any) => { },
  optionReion: [],
  setOptionRegion: (data: any) => { },
  optionProvice: [],
  setOptionProvince: (data: any) => { },
  optionSubDistrict: [],
  setOptionSubDistrict: (data: any) => { },
  optionDistrict: [],
  setOptionDistrict: (data: any) => { },
  optionUniversity: [],
  setOptionUniversity: (data: any) => { },
  optionLanguage: [],
  setOptionLanguage: (data: any) => { },
  optionLangSkill: [],
  setOptionLangSkill: (data: any) => { },
  optionWorkEXP: [],
  setOptionEXP: (data: any) => { },
});

const lang = secureLocalStorage.getItem("language") || "th";
const optionWorkYear = [];
for (let index = 0; index <= 21; index++) {
  let value = {};
  switch (index) {
    case 0:
      let lessThan = lang === "th" ? "น้อยกว่า 1 ปี" : "Less than 1 year";
      value = { label: lessThan, value: index };
      break;
    case 21:
      let moreThan = lang === "th" ? "มากกว่า 20 ปี" : "more than 20 years";
      value = { label: moreThan, value: index };
      break;
    default:
      value = { label: index + "", value: index };
      break;
  }
  optionWorkYear.push(value);
}

const optionLang = lang === "th" ? OptionLanguageTH : OptionLanguageEN;
const optionLngSkill = lang === "th" ? OptionSkillTH : OptionSkillEN;

const ProfileProvider = (props) => {
  const { BlockUI, UnBlockUI } = FnBlock_UI();

  const [isFetch, setIsFetch] = useState(true);
  const [yearEXP, setYearEXP] = useState(null);
  const [isEXP, setIsEXP] = useState(true);
  const [arrWorkExperience, setArrWorkExperience] = useState<IWorkExperience[]>(
    []
  );
  const [myAddress, setMyAddress] = useState<IAddress>();
  const [myEducation, setMyEducation] = useState<IEducation[]>([]);
  const [myCertificate, setMyCertificate] = useState<ICertificate[]>([]);
  const [myAbility, setMyAbility] = useState<string[]>();
  const [myComputerSkill, setMyComputerSkill] = useState<IComputerSkill[]>([]);
  const [myLanguageSkill, setMyLanguageSkill] = useState<ILanguageSkill[]>([]);
  const [myJobPosition, setMyJobPosition] = useState<IMyJobPosition[]>([]);
  const [myFile, setMyFile] = useState<cFile[]>([]);
  const [selfProfile, setSelfProfile] = useState<IMyProfile>();
  const [arrJobPosition, setArrJobPosition] = useState([]);
  const [arrCompany, setArrCompany] = useState([]);
  const [arrJobFunction, setArrJobFunction] = useState([]);
  const [arrJobDetail, setArrJobDetail] = useState([]);
  const [arrEducationLevel, setArrEducationLevel] = useState([]);
  const [arrJobType, setArrJobType] = useState([]);
  const [arrJobIndustry, setArrJobIndustry] = useState([]);
  const [arrCountry, setArrCountry] = useState([]);
  const [arrReion, setArrRegion] = useState([]);
  const [arrProvice, setArrProvince] = useState([]);
  const [arrSubDistrict, setArrSubDistrict] = useState([]);
  const [arrDistrict, setArrDistrict] = useState([]);
  const [arrUniversity, setArrUniversity] = useState([]);
  const [dataProfile, setDataProfile] = useState({}) as any;

  const [isSuccessLoadingCount, setSuccessLoadingCount] = useState(0);

  const [valueProvider, setValueProvider] = useState({
    isLoading: isFetch,
    setIsLoading: (data: boolean) => {
      setIsFetch(() => data);
    },
    myProfileContext: selfProfile,
    setMyProfile: (data: IMyProfile) => {
      valueProvider.myProfileContext = data
      if (!valueProvider.isLoading)
        setValueProvider({ ...valueProvider })
    },
    yearExperience: yearEXP,
    setYearExperience: (data: object) => {
      valueProvider.yearExperience = data
      if (!valueProvider.isLoading)
        setValueProvider({ ...valueProvider })
    },
    isWorkExperience: isEXP,
    setIsWorkExperience: (data: boolean) => {
      valueProvider.isWorkExperience = data
      if (!valueProvider.isLoading)
        setValueProvider({ ...valueProvider })
    },
    arrWorkExperienceContext: arrWorkExperience,
    setWorkExperience: (data: IWorkExperience[]) => {
      valueProvider.arrWorkExperienceContext = data
      if (!valueProvider.isLoading)
        setValueProvider({ ...valueProvider })
    },
    addresssContext: myAddress,
    setAddress: (data: IAddress) => {
      valueProvider.addresssContext = data
      if (!valueProvider.isLoading)
        setValueProvider({ ...valueProvider })
    },
    educationContext: myEducation,
    setEducation: (data: IEducation[]) => {
      valueProvider.educationContext = data
      if (!valueProvider.isLoading)
        setValueProvider({ ...valueProvider })
    },
    certificateContext: myCertificate,
    setCertificate: (data: ICertificate[]) => {
      valueProvider.certificateContext = data
      if (!valueProvider.isLoading)
        setValueProvider({ ...valueProvider })
    },
    ability: myAbility,
    setAbility: (data: string[]) => {
      valueProvider.ability = data
      if (!valueProvider.isLoading)
        setValueProvider({ ...valueProvider })
    },
    languageSkillContext: myLanguageSkill,
    setLanguageSkill: (data: ILanguageSkill[]) => {
      valueProvider.languageSkillContext = data
      if (!valueProvider.isLoading)
        setValueProvider({ ...valueProvider })
    },
    jobPositionContext: myJobPosition,
    setJobPosition: (data: IMyJobPosition[]) => {
      valueProvider.jobPositionContext = data
      if (!valueProvider.isLoading)
        setValueProvider({ ...valueProvider })
    },
    fileUploadContext: myFile,
    setFileUpload: (data: cFile[]) => {
      valueProvider.fileUploadContext = data
      if (!valueProvider.isLoading)
        setValueProvider({ ...valueProvider })
    },
    computerSkillContext: myComputerSkill,
    setComputerSkill: (data: IComputerSkill[]) => {
      valueProvider.computerSkillContext = data
      if (!valueProvider.isLoading)
        setValueProvider({ ...valueProvider })
    },

    optionPosition: arrJobPosition,
    setOptionPosition: (data: any) => {
      setArrJobPosition(() => data);
    },
    optionCompany: arrCompany,
    setOptionCompany: (data: any) => {
      setArrCompany(() => data);
    },
    optionJobFunction: arrJobFunction,
    setOptionJobFunction: (data: any) => {
      setArrJobFunction(() => data);
    },
    optionJobDetail: arrJobDetail,
    setOptionJobDeatail: (data: any) => {
      setArrJobDetail(() => data);
    },
    optionEducationLevel: arrEducationLevel,
    setOptionEducationLevel: (data: any) => {
      setArrEducationLevel(() => data);
    },
    optionJobType: arrJobType,
    setOptionJobType: (data: any) => {
      setArrJobType(() => data);
    },
    optionJobIndustry: arrJobIndustry,
    setOptionJobIndustry: (data: any) => {
      setArrJobIndustry(() => data);
    },
    optionCountry: arrCountry,
    setOptionCountry: (data: any) => {
      setArrCountry(() => data);
    },
    optionReion: arrReion,
    setOptionRegion: (data: any) => {
      setArrJobType(() => data);
    },
    optionProvice: arrProvice,
    setOptionProvince: (data: any) => {
      setArrProvince(() => data);
    },
    optionSubDistrict: arrSubDistrict,
    setOptionSubDistrict: (data: any) => {
      setArrSubDistrict(() => data);
    },
    optionDistrict: arrDistrict,
    setOptionDistrict: (data: any) => {
      setArrDistrict(() => data);
    },
    optionUniversity: arrUniversity,
    setOptionUniversity: (data: any) => {
      setArrUniversity(() => data);
    },
    optionLanguage: optionLang,
    setOptionLanguage: (data: any) => { },
    optionLangSkill: optionLngSkill,
    setOptionLangSkill: (data: any) => { },
    optionWorkEXP: optionWorkYear,
    setOptionEXP: (data: any) => { },
    profileContext: dataProfile,
    setProfileContext: (data: any) => {
      setDataProfile(() => data);
    },
  });

  const fetchOptionJobPosition = () => {
    return AxiosGet("DataCollect/GetDataJobPosition", {}, (res) => {
      const option = (res.Data || []).map((item) => {
        return { label: item.sPosition_Name, value: item.nPosition_ID };
      });
      valueProvider.optionPosition = option
    }, null, () => setSuccessLoadingCount(prev => prev += 1));
  };
  const fetchOptionCompany = () => {
    return AxiosGet("DataCollect/GetDataCompany", {}, (res) => {
      const option = (res.Data || []).map((item) => {
        return { label: item.sCompany_Name, value: item.nCompany_ID };
      });
      valueProvider.optionCompany = option
    }, null, () => setSuccessLoadingCount(prev => prev += 1));
  };

  const fetchOptionJobFunction = () => {
    let lang = "";
    let isTH = (getLanguage().id === "th");
    isTH ? lang = "th" : lang = "en"
    return AxiosGet(`DataFilter/SetFilter_SelectIndustrytype`, { lang }, (res) => {
      valueProvider.optionJobFunction = res.lstOptionGroupWork || []
    }, null, () => setSuccessLoadingCount(prev => prev += 1));
  };

  const fetchOptionJobDetail = () => {
    let lang = "";
    let isTH = (getLanguage().id === "th");
    isTH ? lang = "th" : lang = "en"
    return AxiosGet(`DataFilter/SetFilter_SelectIndustrytype`, { lang }, (res) => {
      valueProvider.optionJobDetail = res.lstOptionWork || []
    }, null, () => setSuccessLoadingCount(prev => prev += 1));
  };

  const fetchOptionEducationLevel = () => {
    return AxiosGet("DataCollect/GetEducateLevel", {}, (res) => {
      const option = (res.Data || []).map((item) => {
        return {
          label: lang === "th" ? item.sData_Name_TH : item.sData_Name_ENG,
          value: item.nData_ID,
        };
      });
      valueProvider.optionEducationLevel = option
    }, null, () => setSuccessLoadingCount(prev => prev += 1));
  };

  const fetchOptionJobType = () => {
    return AxiosGet("DataCollect/GetJobType", {}, (res) => {
      const option = (res.Data || []).map((item) => {
        return {
          label: lang === "th" ? item.sData_Name_TH : item.sData_Name_ENG,
          value: item.nData_ID,
        };
      });
      valueProvider.optionJobType = option
    }, null, () => setSuccessLoadingCount(prev => prev += 1));
  };

  const fetchOptionJobIndustry = () => {
    let lang = "";
    let isTH = (getLanguage().id === "th");
    isTH ? lang = "th" : lang = "en"
    return AxiosGet(`DataFilter/SetFilter_SelectIndustrytype`, { lang }, (res) => {
      valueProvider.optionJobIndustry = res.lstOptionAdmin || []
    }, null, () => setSuccessLoadingCount(prev => prev += 1));
  };

  const fetchOptionCountry = () => {
    let lang = "";
    let isTH = (getLanguage().id === "th");
    isTH ? lang = "th" : lang = "en"
    return AxiosGet(`DataFilter/SetFilter_SelectIndustrytype`, { lang }, (res) => {
      valueProvider.optionCountry = res.lstOptionCountry || []
    }, null, () => setSuccessLoadingCount(prev => prev += 1));
  };

  const fetchOptionRegion = () => {
    let lang = "";
    let isTH = (getLanguage().id === "th");
    isTH ? lang = "th" : lang = "en"
    return AxiosGet(`DataFilter/SetFilter_SelectIndustrytype`, { lang }, (res) => {
      valueProvider.optionReion = res.lstOptionRegion || []
    }, null, () => setSuccessLoadingCount(prev => prev += 1));
  };

  const fetchOptionProvince = () => {
    let lang = "";
    let isTH = (getLanguage().id === "th");
    isTH ? lang = "th" : lang = "en"
    return AxiosGet(`DataFilter/SetFilter_SelectIndustrytype`, { lang }, (res) => {
      valueProvider.optionProvice = res.lstOptionProvince || []
    }, null, () => setSuccessLoadingCount(prev => prev += 1));
  };
  const fetchOptionSubDistrict = () => {
    return AxiosGet("DataCollect/GetSubDistrict", {}, (res) => {
      const option = (res.Data || []).map((item) => {
        return {
          label: lang === "th" ? item.sData_Name_TH : item.sData_Name_ENG,
          value: item.nData_ID,
        };
      });
      valueProvider.optionSubDistrict = option
    }, null, () => setSuccessLoadingCount(prev => prev += 1));
  };
  const fetchOptionDistrict = () => {
    return AxiosGet("DataCollect/GetDistrict", {}, (res) => {
      const option = (res.Data || []).map((item) => {
        return {
          label: lang === "th" ? item.sData_Name_TH : item.sData_Name_ENG,
          value: item.nData_ID,
        };
      });
      setArrDistrict(option);
      valueProvider.optionDistrict = option
    }, null, () => setSuccessLoadingCount(prev => prev += 1));
  };

  const fetchOptionUniversity = () => {
    let lang = "";
    let isTH = (getLanguage().id === "th");
    isTH ? lang = "th" : lang = "en"
    return AxiosGet(`DataFilter/SetFilter_SelectIndustrytype`, { lang }, (res) => {
      valueProvider.optionUniversity = res.lstOptionUniversity || []
      console.log(res.lstOptionUniversity)
    }, null, () => setSuccessLoadingCount(prev => prev += 1));
  };

  const fetchMyProfile = () => {
    return AxiosPost("JobSeeker/MyProfile", {}, (res) => {
      if (res.StatusCode === 200) {
        valueProvider.profileContext = res.Data
      }
    }, null, () => setSuccessLoadingCount(prev => prev += 1));
  };

  useEffect(() => {
    const fetchData = async () => {
      fetchOptionJobPosition();
      fetchOptionCompany();
      fetchOptionJobFunction();
      fetchOptionJobDetail();
      fetchOptionEducationLevel();
      fetchOptionJobType();
      fetchOptionJobIndustry();
      fetchOptionCountry();
      fetchOptionRegion();
      fetchOptionProvince();
      fetchOptionSubDistrict();
      fetchOptionDistrict();
      fetchOptionUniversity();
      let localJwtKey = process.env.REACT_APP_JWT_KEY as string;
      let auth_token =
        secureLocalStorage.getItem(localJwtKey) != null
          ? (secureLocalStorage.getItem(localJwtKey) as string)
          : undefined;
      if (auth_token) {
        fetchMyProfile();
      }
    };
    BlockUI()
    fetchData()
  }, []);

  useEffect(() => {
    if (isSuccessLoadingCount == 14 || (window.location.pathname.indexOf('/Guest/Register') > -1 && isSuccessLoadingCount == 13)) {
      valueProvider.isLoading = false;
      setValueProvider({ ...valueProvider })
      UnBlockUI();
    }
  }, [isSuccessLoadingCount])

  return (
    <ProfileContext.Provider value={valueProvider} >
      {props.children}
    </ProfileContext.Provider>
  );
};

export default ProfileProvider;