import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Typography,
  Box,
  Tooltip,
  Hidden,
  Skeleton,
  Stack,
  Button,
  Autocomplete,
  TextField,
} from "@mui/material";
import { i18n } from "i18n";
import { useNavigate } from "react-router-dom";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import JobDetail from "view/font/JobDetail/JobDetail";
import SkeletonJobTab from "components/Common/ElementInForm/SkeletonJobTab";
import NotSelectJob from "view/font/JobDetail/NotSelectJob";
import secureLocalStorage from "react-secure-storage";
import { Pagination_Custom } from "components/Pagination/Pagination";

const i18nForthisPage = "home";
const i18nCommon = "common";
const i18nField = "entities.MyJobApply";

export default function JobTab({ lstJob = [], isLoading = false, setCompanyName, NewsPage, Page, setPage }) {
  const language = secureLocalStorage.getItem("language").toString().toUpperCase();

  const navigate = useNavigate();
  const [sActiveID, setActiveID] = useState("");
  const [arrOptPage, setOptPage] = useState([]);
  const oFormRef = useRef() as any;

  const PER_PAGE = 10;
  const count = NewsPage;
  const _DATA = Pagination_Custom(NewsPage || [], PER_PAGE);

  useEffect(() => {
    if (count > 0) {
      let arrPage = [];
      for (let i = 1; i <= count; i++) {
        arrPage.push(i);
      }
      setOptPage(arrPage);
    }
  }, [count]);

  const NextPage = () => {
    if (Page < count) {
      setPage(Page + 1);
    }
  }

  const PreviousPage = () => {
    if (Page > 1) {
      setPage(Page - 1);
    }
  }

  // pagination
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
    if (oFormRef.current != null) {
      window.scrollTo({
        top: oFormRef.current.offsetTop - 100,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (lstJob.length > 0) {
      setTimeout(() => {
        setActiveID(lstJob[0].sEncryptJobPostID || "");
      }, 500);
    }
  }, [lstJob])

  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        {isLoading ? (
          <SkeletonJobTab />
        ) : (
          lstJob.length > 0 && (
            <>
              {/* Desktop */}
              <Hidden smDown>
                <Grid item md={4} sm={4} xs={12}>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      overflow: "scroll",
                      overflowX: "hidden",
                      height: "85vh",
                      borderRadius: "10px",
                      pb: "10px",
                    }}
                    className="custom-scroll-bar-search-job"
                  >
                    {lstJob.map((item, index) => (
                      <Grid
                        key={index}
                        item
                        xs={12}
                        sx={{ mt: 1, ml: 1, mr: 1 }}
                      >
                        <Box
                          sx={{
                            borderRadius: "10px",
                            boxShadow:
                              sActiveID === item.sEncryptJobPostID
                                ? "0px 0px 7px 2px #4F9BC3 !important"
                                : "0px 0px 7px 2px #C9C9C9",
                            p: "15px",
                            cursor: "pointer",
                            ":hover": {
                              boxShadow: "0px 0px 7px 2px #A4A4A4",
                            },
                            backgroundColor: "white",
                          }}
                          onClick={() => {
                            setActiveID(item.sEncryptJobPostID || "");
                          }}
                        >
                          {item.sLogoURL && (
                            <Tooltip
                              title={i18n(`${i18nForthisPage}.btnViewCompany`)}
                            >
                              <img
                                src={item.sLogoURL}
                                alt="company"
                                style={{
                                  height: "30px",
                                }}
                                onClick={(e) => {
                                  navigate(
                                    `/Guest/Company?sID=${item.sEncryptJobPostID}`
                                  );
                                  e.stopPropagation();
                                }}
                              />
                            </Tooltip>
                          )}
                          <Typography
                            className="ellipsis-label"
                            sx={{
                              fontWeight: 400,
                              color: "#295697",
                              pt: "16px",
                              ":hover": {
                                textDecoration: "underline",
                                color: "#295697",
                              },
                            }}
                            onClick={() => {
                              setActiveID(item.sEncryptJobPostID || "");
                            }}
                          >
                            {item[`sJobName${language}`]}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: 14,
                              fontWeight: 400,
                              color: "#000",
                              pb: "16px",
                              ":hover": {
                                textDecoration: "underline",
                                color: "#000",
                              },
                            }}
                            onClick={(e) => {
                              setCompanyName(item.sCompanyName)
                            }}
                          >
                            {item.sCompanyName}
                          </Typography>

                          <Typography
                            className="ellipsis-label"
                            sx={{
                              fontSize: 14,
                              fontWeight: 400,
                              color: "#000",
                            }}
                          >
                            {item[`sProvince${language}`] || ""}
                            {/* {item[`sLocation${language}`] || ""} */}
                          </Typography>

                          <Typography
                            className="ellipsis-label"
                            sx={{
                              fontSize: 13,
                              fontWeight: 500,
                              color: "#C91643",
                            }}
                          >
                            {`${item.sSalaryStart} - ${item.sSalaryEnd} ${item[`sCurrency${language}`]
                              }`}
                          </Typography>
                          <Box sx={{ pt: "16px", pb: "16px" }}>
                            {item.lstPoint.length > 0 &&
                              item.lstPoint.map((iP, i) => (
                                <Box key={i} sx={{ display: "flex" }}>
                                  <FiberManualRecordIcon
                                    sx={{ fontSize: "6px", mt: "7px" }}
                                  />
                                  <Typography
                                    className="ellipsis-label"
                                    sx={{
                                      fontSize: 14,
                                      fontWeight: 400,
                                      color: "#000",
                                      marginLeft: "5px",
                                    }}
                                  >
                                    {iP}
                                  </Typography>
                                </Box>
                              ))}
                          </Box>
                          <Typography
                            className="ellipsis-label"
                            sx={{
                              fontSize: 12,
                              fontWeight: 400,
                              color: "#000",
                            }}
                          >
                            {i18n(`${i18nField}.SendDate`) + " "}
                            {item.sApplyDate}
                          </Typography>
                        </Box>
                      </Grid>
                    ))}

                    {/* pagination */}
                    {/* <Grid container justifyContent={"center"} sx={{ my: "2%" }}>
                      <Grid item >
                        <Button onClick={() => PreviousPage()} variant="outlined" sx={{ width: 'auto', borderColor: "#d2d2d2", color: "#848483" }}>{i18n(`${i18nCommon}.previous`)}</Button>
                      </Grid>

                      <Grid item xs={4}>
                        <Autocomplete
                          disablePortal
                          options={arrOptPage.map(item => item)}
                          onChange={(e, p) => handleChange(e, p)}
                          sx={{ width: 'auto', p: "-100px", "&:hover": { borderColor: "#cbdff5 !important", backgroundColor: "#f6fafd !important", color: "red !important" } }}
                          renderInput={(params) =>
                            <TextField {...params} label={Page} sx={{ p: "-100px" }} />
                          }
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Button onClick={() => NextPage()} variant="outlined" sx={{ width: 'auto', borderColor: "#d2d2d2", color: "#848483" }}>{i18n(`${i18nCommon}.next`)}</Button>
                      </Grid>
                    </Grid> */}
                  </Grid>
                </Grid>
                <Grid item md={8} sm={8} xs={12}>
                  {sActiveID ? (
                    <Box
                      sx={{
                        overflow: "scroll",
                        overflowX: "hidden",
                        height: "85vh",
                      }}
                      className="custom-scroll-bar-search-job"
                    >
                      <JobDetail sJobPostID={sActiveID} />
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        height: "85vh",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <NotSelectJob />
                    </Box>
                  )}
                </Grid>
              </Hidden>

              {/* Mobile */}
              <Hidden smUp>
                <Grid container>
                  {lstJob.map((item, index) => (
                    <Grid key={index} item xs={12} sx={{ mt: 1, ml: 1, mr: 1 }}>
                      <Box
                        sx={{
                          borderRadius: "10px",
                          boxShadow: "0px 0px 7px 2px #C9C9C9",
                          p: "15px",
                          cursor: "pointer",
                          ":hover": {
                            boxShadow: "0px 0px 7px 2px #A4A4A4",
                          },
                          backgroundColor: "white",
                        }}
                        onClick={() => {
                          navigate("/JobDetail?sID=" + item.sEncryptJobPostID);
                        }}
                      >
                        {item.sLogoURL && (
                          <Tooltip
                            title={i18n(`${i18nForthisPage}.btnViewCompany`)}
                          >
                            <img
                              src={item.sLogoURL}
                              alt="company"
                              style={{
                                height: "30px",
                              }}
                              onClick={(e) => {
                                navigate(
                                  `/Guest/Company?sID=${item.sEncryptJobPostID}`
                                );
                                e.stopPropagation();
                              }}
                            />
                          </Tooltip>
                        )}
                        <Typography
                          sx={{
                            fontWeight: 400,
                            color: "#295697",
                            pt: "16px",
                            ":hover": {
                              textDecoration: "underline",
                              color: "#295697",
                            },
                          }}
                          onClick={() => {
                            navigate(
                              "/JobDetail?sID=" + item.sEncryptJobPostID
                            );
                          }}
                        >
                          {item[`sJobName${language}`]}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: 14,
                            fontWeight: 400,
                            color: "#000",
                            pb: "16px",
                            ":hover": {
                              textDecoration: "underline",
                              color: "#000",
                            },
                          }}
                          onClick={(e) => {
                            setCompanyName(item.sCompanyName)
                          }}
                        >
                          {item.sCompanyName}
                        </Typography>
                        <Typography
                          className="ellipsis-label"
                          sx={{
                            fontSize: 14,
                            fontWeight: 400,
                            color: "#000",
                          }}
                        >
                          {item[`sLocation${language}`] || ""}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: 13,
                            fontWeight: 500,
                            color: "#C91643",
                          }}
                        >
                          {`${item.sSalaryStart} - ${item.sSalaryEnd} ${item[`sCurrency${language}`]
                            }`}
                        </Typography>
                        <Box sx={{ pt: "16px", pb: "16px" }}>
                          {item.lstPoint.length > 0 &&
                            item.lstPoint.map((iP, i) => (
                              <Box key={i} sx={{ display: "flex" }}>
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "6px", mt: "7px" }}
                                />
                                <Typography
                                  sx={{
                                    fontSize: 14,
                                    fontWeight: 400,
                                    color: "#000",
                                    marginLeft: "5px",
                                  }}
                                >
                                  {iP}
                                </Typography>
                              </Box>
                            ))}
                        </Box>
                        <Typography
                          sx={{
                            fontSize: 12,
                            fontWeight: 400,
                            color: "#000",
                          }}
                        >
                          {i18n(`${i18nField}.SendDate`) + " "}
                          {item.sApplyDate}
                        </Typography>
                      </Box>
                    </Grid>
                  ))}


                  {/* pagination */}
                  {/* <Grid xs={12} justifyContent={"center"} sx={{ my: "2%" }}>
                    <Stack direction={"row"}>
                      <Grid xs={1}>  </Grid>
                      <Grid xs={3}>
                        <Button onClick={() => PreviousPage()} variant="outlined" sx={{ width: 'auto', borderColor: "#d2d2d2", color: "#848483" }}>{i18n(`${i18nCommon}.previous`)}</Button>
                      </Grid>

                      <Grid xs={4}>
                        <Autocomplete
                          disablePortal
                          // id="combo-box-demo"
                          options={arrOptPage.map(item => item)}
                          onChange={(e, p) => handleChange(e, p)}
                          sx={{ width: 'auto', p: "-100px", "&:hover": { borderColor: "#cbdff5 !important", backgroundColor: "#f6fafd !important", color: "red !important" } }}
                          renderInput={(params) =>
                            <TextField {...params} label={Page} sx={{ p: "-100px" }} />
                          }
                        />
                      </Grid>
                      <Grid xs={3}>
                        <Button onClick={() => NextPage()} variant="outlined" sx={{ width: 'auto', borderColor: "#d2d2d2", color: "#848483" }}>{i18n(`${i18nCommon}.next`)}</Button>
                      </Grid>
                      <Grid xs={1}>  </Grid>
                    </Stack>
                  </Grid> */}
                </Grid>
              </Hidden>
            </>
          )
        )}
      </Grid>
    </Grid>
  );
}
