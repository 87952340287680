import React from "react";
import { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { i18n } from "i18n";
import { AxiosGet, AxiosPost } from "service/CommonFunction/TS_function";
import {
  Button,
  Grid,
  Divider,
  Paper,
  InputBase,
  Snackbar,
  Alert,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import DetailJob from "components/JobDetail/DetailJob";
import DetailCompany from "components/JobDetail/DetailCompany";
import { PDFViewer } from "react-view-pdf/lib";


const Company = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = location.search;
  const sID = new URLSearchParams(searchParams).get("sID");

  const [jobPost, setJobPost] = useState<{ [key: string]: any }>({});
  const [banner, setBanner] = useState<{ [key: string]: any }>({});

  const onGetDetailBanner = () => {
    AxiosGet("JobDetail/GetCompanyAd", { sID: sID }, (res) => {
      setBanner(res);
      onGetDetail(res.sCompany_ID);
    });
  };

  const onGetDetail = (sID) => {
    AxiosGet("JobDetail/detail", { sID: sID }, (res) => {
      setJobPost(res);
    });
  };

  useEffect(() => {
    //setLang(window.localStorage.getItem("language"));
    onGetDetailBanner();
  }, []);

  return (
    <Grid
      container
      sx={{
        border: "1px #eeeeee solid",
        backgroundColor: "rgba(255,255,255,0.75)",
        borderRadius: 5,
        p: 2,
        flex: 1,
      }}
    >
      {/* <Grid item container xs={12}>
          <DetailCompany lstCompanyContent={jobPost.lstCompanyContent} />
        </Grid> */}
      <Grid item container xs={12}>
        <img height="700" src={banner.sCompanyFile_Banner} alt="" />
        <PDFViewer url={banner.sCompanyFile_Banner} />
        {/* <img height="700" src={jobPost.sCompanyFile_Logo} alt="" /> */}
      </Grid>


      <Grid item container xs={12}></Grid>
      <Grid item container xs={12}>
        {/* <img height="700" src={jobPost.sCompanyFile_Banner} alt="" /> */}
        {/* <img height="700" src={ jobPost.sCompanyFile_Logo} alt="" />  */}
      </Grid>
    </Grid>
  );
};
export default Company;
