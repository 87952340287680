import React, { useContext, useEffect, useRef, useState } from 'react';
import {
	Box, Button, Divider, Modal, Popover, Popper, Stack, Typography, Tab, Tabs, Grid,
} from '@mui/material';
// import BackgroundBack from "assets/images/BPS-Banner_Login_1_full.png"
import BackgroundBack from "assets/images/bg-long2.png"

export default function EmptyLayout(props: any) {

	const stylesCustom = {
		paperContainer: {
			backgroundImage: `url(${BackgroundBack})`,
			backgroundPosition: 'center',
			backgroundSize: '106vw',
			width: "100%",
		},
	}
	return (
		<Grid container className="h-screen overflow-x-hidden flex flex-row gap-[16px]" >
			<div style={stylesCustom.paperContainer} >
				{/* background-position: bottom;
					background-repeat: no-repeat;
					background - size: 106vw; */}
				{props.children}
			</div>
		</Grid>
	)
}