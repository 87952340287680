import { useEffect, useContext, useState } from 'react';
import { FormProvider } from 'react-hook-form'
import { Grid, Box, IconButton, Paper } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { i18n } from 'i18n';
import { ProfileContext } from 'components/SeekerProfile/Context/ProfileContext';
import AutoCompleteSelect from 'components/Common/ElementInForm/AutoCompleteSelect';
import { IComputerSkill, IPropsComputerSkill } from './Interface';
import { DialogActionCreators } from "store/redux/DialogAlert";
import { useDispatch } from "react-redux";

const i18nCommon = 'common';
const i18nField = 'entities.SeekerProfile';
const OptionProgram = [
    { label: "Word", value: 1 },
    { label: "Excel", value: 2 },
    { label: "Power Point", value: 3 },
    { label: "Java", value: 4 },
    { label: "Javascript", value: 5 },
    { label: "C#", value: 6 },
    { label: "PHP", value: 7 },
    { label: "C++", value: 8 },
    { label: "Python", value: 9 },
    { label: "C", value: 10 },
    { label: "SQL", value: 11 },
    { label: "RUBY", value: 12 },
    { label: "Objective-C", value: 13 },
    { label: "Perl", value: 14 },
    { label: ".NET", value: 15 },
    { label: "Visual", value: 16 },
];


const MyComputerSkill = (props: IPropsComputerSkill) => {
    const { form, item, index } = props;
    const dispatch = useDispatch();

    const { computerSkillContext, setComputerSkill,
        optionLangSkill
    } = useContext(ProfileContext);

    const [isClean, setIsClean] = useState(false);

    const handleSetData = (itemIndex) => {
        computerSkillContext.forEach((data) => {
            if (data.Index === itemIndex) {
                form.setValue("Program" + data.Index, data.Program || '');
                form.setValue("SkillLevel" + data.Index, data.SkillLevel || '');
            }
        });
        setIsClean(false);
    }

    const handleChangeValue = (form, index) => {
        let newData = computerSkillContext.map((item: IComputerSkill) => {
            if (item.Index === index) {
                item.Program = form.getValues("Program" + index);
                item.SkillLevel = form.getValues("SkillLevel" + index);
            }
            return item;
        });
        setComputerSkill(newData);
    }

    const handleRemoveComputerSkill = (itemIndex: number) => {
        dispatch(DialogActionCreators.OpenDialogSubmit(i18n(`${i18nCommon}.AlertConfrimDel`), () => {
            dispatch(DialogActionCreators.CloseDialogSubmit() as any);
            dispatch(DialogActionCreators.LoadSubmit("Waiting...") as any);
            setIsClean(true);
            let newData = computerSkillContext.filter((data) => { return data.Index !== itemIndex });
            setComputerSkill(newData);
            form.unregister("Program" + itemIndex);
            form.unregister("SkillLevel" + itemIndex);
        }));

    }

    useEffect(() => {
        handleSetData(item.Index);
    }, [isClean]);

    useEffect(() => {
        handleSetData(item.Index);
    }, [computerSkillContext]);

    return (
        <>
            <FormProvider {...form} key={index}>
                <Box component={Paper} sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}>
                    {index === 0 ? null :
                        <IconButton aria-label="delete" color="error" onClick={() => handleRemoveComputerSkill(item.Index)}>
                            <CloseIcon />
                        </IconButton>}
                    <Grid container justifyContent="center" alignItems="center" spacing={1} >
                        <Grid item container spacing={2} xs={12} >
                            <Grid item md={3} xs={12} >
                                <AutoCompleteSelect
                                    name={"Program" + item.Index}
                                    label={i18n(`${i18nField}.Program`)}
                                    options={OptionProgram}
                                    onChange={(value) => {
                                        form.setValue("Program" + item.Index, value);
                                        handleChangeValue(form, item.Index);
                                    }}
                                    freeSolo
                                    required
                                />
                            </Grid>
                            <Grid item md={3} xs={12} >
                                <AutoCompleteSelect
                                    name={"SkillLevel" + item.Index}
                                    label={i18n(`${i18nField}.SkillLevel`)}
                                    options={optionLangSkill}
                                    onChange={(value) => {
                                        form.setValue("SkillLevel" + item.Index, value);
                                        handleChangeValue(form, item.Index);
                                    }}
                                    required
                                />
                            </Grid>
                            <Grid item md={3} xs={6} >

                            </Grid>
                            <Grid item md={3} xs={6} >

                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </FormProvider>
        </>
    )
}

export default MyComputerSkill;