import { Modal, Box, Tooltip, Typography, Grid, Fade } from "@mui/material"
import { Close } from '@mui/icons-material';
import Input from "components/Common/ElementInForm/InputCustomLabelFormItem";
import { BtnSave } from 'components/Common/Button';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from "yup";
import yupFormSchemas from 'components/Common/yup/yupFormSchemas';
import { yupResolver } from '@hookform/resolvers/yup';
import { DialogActionCreators } from 'store/redux/DialogAlert';
import { AxiosPost, ResultAPI } from 'service/CommonFunction/TS_function';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '0px solid #000',
    boxShadow: 24,
    p: 4,
};
export default function ModalPauseCancle({ isOpen, setOpen, sID, sOrder, setOrder }) {

    const objSchema = {
        sNote_Cancel: yupFormSchemas.string("เหตุผลการยกเลิก", { required: true, isAdmin: true }),
    }
    const schema = yup.object().shape(objSchema);
    const form = useForm({
        resolver: yupResolver(schema),
        shouldUnregister: false,
        shouldFocusError: true,
        mode: "all",
    });
    const history = useNavigate();
    const onBack = () => { history("/AdminPausePackage"); window.location.reload(); }
    const dispatch = useDispatch();
    const onSubmit = () => {
        dispatch(DialogActionCreators.OpenDialogSubmit("คุณต้องการบันทึกข้อมูลหรือไม่ ?", () => {
            dispatch(DialogActionCreators.CloseDialogSubmit() as any);

            let objSave = {
                sID: sID,
                sNote_Cancel: form.getValues("sNote_Cancel"),
                sOrder: sOrder
            }

            AxiosPost("HoldPackage/SaveCanclePause", objSave, (result) => {
                ResultAPI(result, "บันทึกเรียบร้อยแล้ว", () => {
                    dispatch(DialogActionCreators.OpenDialogSuccess("บันทึกข้อมูลสำเร็จ",
                        () => onBack()
                    ) as any);
                });
            });
        }))
    }

    return (
        <FormProvider {...form}>
            <div>

                <Modal
                    open={isOpen}
                >
                    <Fade in={isOpen}>
                        <Box sx={style}>
                            <div className="flex flex-[1] justify-end  ">
                                <Tooltip title="ปิด">
                                    <div onClick={() => {
                                        setOpen(false)
                                        window.location.reload();

                                    }} className='rounded-[50%] bg-[#d1d1d1] w-[1.5em] h-[1.5em] flex cursor-pointer ease-linear duration-[100ms]  hover:bg-[#8c8c8c]'>
                                        <Close fontSize='small' className='m-[auto] text-[white]' />
                                    </div>
                                </Tooltip>
                            </div>
                            <Grid xs={12} >
                                <Typography variant="h6">{"เหตุผลการยกเลิก"}</Typography>
                            </Grid>
                            <Grid xs={12} sx={{ marginTop: "2%" }}>

                                <Input name="sNote_Cancel" rows={3}
                                    // disabled={nPermission !== 2 }
                                    required
                                    multiline
                                    maxLength={500}
                                    label={
                                        <>
                                            <Typography sx={{ fontWeight: 400 }} component="label">{"เหตุผลการยกเลิก"}</Typography>
                                        </>}
                                />

                            </Grid>



                            <Grid item container xs={12} justifyContent={"end"} sx={{ marginTop: "2%" }}>
                                <BtnSave txt='บันทึก' onClick={form.handleSubmit(e => { onSubmit() })} />
                            </Grid>

                        </Box>
                    </Fade>
                </Modal>

            </div>
        </FormProvider>
    );
}

