import Layout_Font from "layout/CommonLayout/Layout_Font";
import JobDetail from "view/font/JobDetail/JobDetail";

const JobDetailRoute = [
  {
    path: "/JobDetail",
    component: JobDetail,
    layout: Layout_Font,
    exact: true,
    propsData: {
      // imgBGType: 3,
      // isPaddingTop: true,
    }
  },
];

export default JobDetailRoute;
