import React from 'react';
import {
    Dialog, DialogTitle, useTheme, Stack,
    Typography, SxProps, Box, Tooltip
} from "@mui/material"
import { Close } from "@mui/icons-material"
export default function DialogPreview(
    { open, handleClose, Title = "Preview", Content, isFontSite = false }
) {
    const objStyle = {
        title: {
            backgroundColor: " #f8f8f8;",
            borderBottom: "1px solid rgba(199.9,200,200,0.1);",
            position: "fixed",
            width: "100vw",
            zIndex: 999
        } as SxProps
    }
    // const theme = useTheme();
    // const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
    return <Dialog
        fullScreen
        sx={{ ".MuiDialog-paperScrollPaper": { overflowX: "hidden" } }}
        open={open}
        onClose={handleClose}>
        <DialogTitle sx={objStyle.title} >
            <Stack direction="row" justifyContent="space-between">
                <Typography variant="h5" sx={{ color: isFontSite ? "#444c5f" : Title === "Preview" ? "#81ccfd" : "black", fontWeight: isFontSite ? 400 : 600 }} >{Title}</Typography>
                <Tooltip title={"Close"}>
                    <Box onClick={handleClose} sx={{
                        cursor: "pointer", bgcolor: "rgba(0,0,0,0.18)",
                        color: "white", display: "flex", justifyContent: "center", alignContent: "center",
                        width: '1.5em', height: '1.5em', borderRadius: "50%"
                    }}>
                        <Close sx={{ fontSize: "1em", alignSelf: "center" }} />
                    </Box>
                </Tooltip>
            </Stack>
        </DialogTitle>
        <Box sx={{ marginTop: "65px" }} />
        <Content />
    </Dialog>
}
