import React, { useEffect, useState } from 'react'
import { Modal, Box, Tooltip, CircularProgress, Typography, Grid } from "@mui/material"
import { Close } from "@mui/icons-material"
import { i18n } from 'i18n';
import { useForm, FormProvider } from "react-hook-form"
import InputCustomLabel from 'components/Common/ElementInForm/InputCustomLabelFormItem';
import { BtnCustomIconWithLoading } from "components/Common/Button"
import { AxiosPost } from 'service/CommonFunction/TS_function';
import { useDispatch } from 'react-redux';
import { DialogActionCreators } from 'store/redux/DialogAlert';
import AutoCompleteRenderItem from 'components/Common/ElementInForm/AutoCompleteRenderItem';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ModalDownloandProfile from './ModalDownload';
import { useNavigate } from 'react-router-dom';
export const th = {
    search: "ตำแหน่งงาน",
    title: "ซื้อประวัติ",
    emailTo: "ส่งสำเนาอีเมลไปที่",
    currentCoin: "Coin คงเหลือ :",
    packageLabel: "แพ็กเกจ :",
    notPackage: "ไม่พบแพ็กเกจที่เหลืออยู่",
    buySuccess: "ซื้อโปรไฟล์สำเร็จ",
    coinNotEnough: "Coin ของคุณไม่เพียงพอ",
    jobseekerDeleted: "บัญชีผู้สมัครงานถูกลบ",
    postNotFound: "ไม่พบ Job Post",
    err: "คำขอล้มเหลวด้วยรหัสสถานะไม่ได้รับอนุญาต",
    notcoin: "ไม่สามารถซื้อประวัติได้เนื่องจากเหรียญไม่เพียงพอ"
}

export const en = {
    search: "Job Title",
    title: "Buy Profile",
    emailTo: "Email a copy of this resume to",
    currentCoin: "Coin balance :",
    packageLabel: "Package :",
    notPackage: "Package not found.",
    buySuccess: "Buy profile success.",
    coinNotEnough: "Coin not enough.",
    jobseekerDeleted: "Jobseeker deleted account.",
    postNotFound: "Jobpost not found.",
    err: "Request failed with status code Unauthorized",
    notcoin: "Profile cannot be purchased due to insufficient coins."
}


export default function ModalBuyProfile({ isOpen, setOpen, sJsId, setJsId, lstData, setLstData, Download }) {
    const i18nField = "EmployerSearch.ModalBuyProfile."
    const form = useForm({
        defaultValues: {
            arrEmailTo: []
        }
    });
    const dispatch = useDispatch();
    const ControllerApi = "EmployerSearch"
    const [isLoading, setLoading] = useState(false);
    // const [currentCoin, setCurrentCoin] = useState(false);
    // const [lstDataJobPost, setDataJobPost] = useState<any[]>([])
    const [sCoinAmountInPackage, setCoinAmountInPackage] = useState("0");
    const history = useNavigate();
    const [sPackageName, setPackageName] = useState("");
    const styles = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        borderRadius: "10px",
        p: 2,
        "@media screen and (min-width:640px)": {
            width: 600,
        }
    };
    const [objPaging, setPaging] = useState({
        nPage: 1,
        nPageSize: 5,
        nTotal: 0
    });

    const _replaceMessageError = (sMessage) => {
        switch (sMessage) {
            case "Coin not enough.":
                return i18n(`${i18nField}coinNotEnough`)
            case "Jobseeker deleted account.":
                return i18n(`${i18nField}jobseekerDeleted`)
            case "Jobpost not found.":
                return i18n(`${i18nField}postNotFound`)
            default:
                return sMessage;
        }
    }

    const getCoin = () => {
        setLoading(true)
        AxiosPost(`${ControllerApi}/GetCoin`, {}, (res) => {
            if (res.Data) {
                setPackageName(res.Data.sPackageName + "\n")
                setCoinAmountInPackage(res.Data.sCoinAmountInPackage)
            } else {
                setPackageName(i18n(`${i18nField}notPackage`))
                setCoinAmountInPackage("")
            }
            // setCurrentCoin(res.Data)
        }, err => dispatch(DialogActionCreators.OpenDialogWarning(err.Message) as any), () => {
            setLoading(false)
        })
    }

    useEffect(() => {
        if (isOpen) getCoin()

    }, [isOpen])


    const onSubmit = (e) => {
     
        setLoading(true)
        AxiosPost(`${ControllerApi}/BuyProfile`, { sId: sJsId, arrEmailTo: e.arrEmailTo }, (res) => {
            Download();
            dispatch(DialogActionCreators.OpenDialogSuccess(i18n(`${i18nField}buySuccess`)) as any)
            lstData.find(f => f.sId == sJsId).isBuy = res.Data.isBuyProfile;
            // setLstData([...lstData])
            setOpen(false)
            // setJsId();
            //  <ModalDownloandProfile  sJsId={sJsId} />
            // window.location.reload();
        }, (err) => {
            if (err.StatusCode === 400) {
                dispatch(DialogActionCreators.OpenDialogWarning(i18n(`${i18nField}notcoin`), () => {
                }) as any)
                setLoading(false)
            }
        }

        )
    }

    return (
        <Modal
            open={isOpen}
            onClose={() => {
                setOpen(false)
                setJsId();
            }}
            closeAfterTransition
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Box sx={{ ...styles, width: 350, maxHeight: "80vh", overflow: "hidden" }} className={" flex flex-col"} >
                <div className="flex flex-row ">
                    <div className="flex">
                        <Typography variant='h6' >{i18n(`${i18nField}title`)}</Typography>
                    </div>
                    <div className="flex flex-[1] justify-end  ">
                        <Tooltip title={i18n(`common.close`)}>
                            <div onClick={() => {
                                setOpen(false)
                            }} className='rounded-[50%] bg-[#d1d1d1] w-[1.5em] h-[1.5em] flex cursor-pointer ease-linear duration-[100ms]  hover:bg-[#8c8c8c]'>
                                <Close fontSize='small' className='m-[auto] text-[white]' />
                            </div>
                        </Tooltip>
                    </div>
                </div>
                <div className='flex flex-col gap-[10px] py-[1em] overflow-hidden whitespace-pre-wrap'>
                    <FormProvider {...form}>
                       
                        <AutoCompleteRenderItem isFreeCreate fullWidth isClearable options={[]} label={i18n(`${i18nField}emailTo`)} name='arrEmailTo' />
                        {/* <span className='text-[12px]'>{i18n(`${i18nField}currentCoin`)} {currentCoin}</span> */}
                        {sPackageName && <span className='text-[12px]'>{i18n(`${i18nField}packageLabel`)} {sPackageName}</span>}
                        {<span className='text-[12px]'>{i18n(`${i18nField}currentCoin`)} {sCoinAmountInPackage}</span>}
                        <Grid container direction={"row"} justifyContent="center">
                            <BtnCustomIconWithLoading
                                txt='1 Coin'
                                isLoading={isLoading}
                                sxCustom={{
                                    borderRadius: "20px",
                                    backgroundColor: "#ffa500",
                                    ":hover": {
                                        backgroundColor: "#b37400"
                                    }
                                }}
                                sIconComponent={<MonetizationOnIcon />}
                                onClick={form.handleSubmit((e) => {
                                    onSubmit(e)
                                })}
                            />
                        </Grid>

                    </FormProvider>
                </div>
            </Box>
        </Modal>

    )
}
