import { useState, useEffect } from 'react';
import { AxiosPost, GetPermission, ResultAPI } from "service/CommonFunction/TS_function";
import { useNavigate } from "react-router-dom";
import { BtnEditOnTable } from "components/Common/Button"
import { useDispatch } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { Button, Dialog, Grid, Hidden, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar } from '@mui/material';
import { i18n } from 'i18n';
import { objContent } from '../SetupTemplate/ManageContent';
import ContentPage from '../Home/ContentPage';
import CloseIcon from '@mui/icons-material/Close';

const BannerPackageTable = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const [nPermission, setPermission] = useState(0);
  const [loadingTable, setLoadingTable] = useState(true);
  const [arrDuration, setarrDuration] = useState([]);
  const [arrlstRow, setarrlstRow] = useState([]);
  const [lstContent, setLstPreviewContent] = useState<objContent[]>([]);
  const [isShowPreview, setIsShowPreview] = useState<boolean>(false);
  const [lstContentTerm, setlstContentTerm] = useState([]);
  const [sContendHead, setContentHead] = useState<objContent[]>([]);
  const isEditMode = (true);
  const i18nField = 'entities.Package';
  const i18nDialog = "dialog";

  const objSchema = {};
  const schema = yup.object().shape(objSchema);

  const form = useForm({
    resolver: yupResolver(schema),
    shouldUnregister: false,
    shouldFocusError: true,
    mode: "all"
  });

  useEffect(() => {
    GetPermission(3, setPermission);
    loadData();
  }, [])

  const loadData = () => {
    setLoadingTable(true)
    AxiosPost("AdminUserPackage/SetFilter_TableBannerDataPackage", {}, (res) => {
      setarrDuration(res.lstDuration);
      setarrlstRow(res.lstRow);
      setLoadingTable(false)
    });
  };

  const onOpenCondition = async (nID) => {
    let param = {
      nID,
    };

    let lstPreviewContent = []
    let show = []
    let HeadName = []
    await AxiosPost(`HomeFront/getData_ContentDetail`, param, (res) => {
      ResultAPI(res, "", () => {
        setlstContentTerm(res.contentShow);
        show = res.contentShow
        HeadName = res.contentHead
      });
    },
      (err) => {
        if (!err.response) {
          history("/error");
          return;
        }
      }
    );
    show.forEach((f, i) => {
      const nType = f.nType
      let sContent = f.sContent
      let sPageName = f.sPageName
      let fileContent = f.sFilePath;
      let objViewContent = {
        nType: nType,
        sContent: sContent,
        sPageName: sPageName,
        sFileNameSys: fileContent
      }
      lstPreviewContent.push(objViewContent)
    })
    setContentHead(HeadName);
    setLstPreviewContent(lstPreviewContent)
    setIsShowPreview(true)
  }

  return (
    <FormProvider {...form}>
      <Stack spacing={2} direction="column" >
        {/* term and condition modal */}
        {/* normal size */}
        <Hidden smDown>
          <Grid>
            <Button
              sx={{ float: "right", marginTop: "2%", px: "2%", width: "auto", backgroundColor: "#009688", color: "#feffff", borderRadius: "50px", "&:hover": { backgroundColor: "#006E64" } }}
              onClick={() => onOpenCondition(6)}
            >
              {i18n(`${i18nDialog}.Terms`)}
            </Button>
          </Grid>
        </Hidden>

        {/* phone size */}
        <Hidden smUp>
          <Grid lg={2} md={3} sm={5} xs={12} justifyContent="end" >
            <Button
              sx={{ float: "right", marginTop: "2%", width: "100%", backgroundColor: "#009688", color: "#feffff", borderRadius: "50px", "&:hover": { backgroundColor: "#006E64" } }}
              onClick={() => onOpenCondition(6)}
            >
              {i18n(`${i18nDialog}.Terms`)}
            </Button>
          </Grid>
        </Hidden>
      </Stack>
      <Grid className="FormCreateBannerPackage">
        <Stack spacing={2} direction="column" >
          <Grid item spacing={2} >
            <Grid container justifyContent="end" className="head-container" direction="row"
              sx={{
                px: 0.5,
                pb: 0.5,
                bgcolor: "white",
              }}
            >
              <Grid lg={2} md={3} sm={5} xs={12} justifyContent="end" >
                {/* <Button
                  sx={{ marginTop: "2%", backgroundColor: "#3d5afe", width: "100%", color: "#feffff", borderRadius: "50px", "&:hover": { backgroundColor: "#3149CB" } }}
                  onClick={() => condition()}
                >
                  {i18n(`${i18nField1}.Terms`)}
                </Button> */}
              </Grid>
            </Grid>
            <TableContainer component={Paper} >
              <Table aria-label="simple table">
                <TableHead >
                  <TableRow sx={{ background: "#eeeeee", border: " 1px solid #dddddd" }}>
                    <TableCell component="th" scope="row" align="center" >
                      {i18n(`${i18nField}.Package`)}
                    </TableCell>
                    {arrDuration != null && arrDuration.length > 0 ? arrDuration.map((item, index) => {
                      return (
                        <TableCell align="center" key={index} sx={{ border: " 1px solid #dddddd" }}>
                          {item.sName} {i18n(`${i18nField}.Month`)}
                        </TableCell>
                      )
                    }) : null}

                    {/* <TableCell component="th" scope="row" align="center" >
                      ss
                    </TableCell> */}

                    {/* <TableCell align="center" sx={{ border: " 1px solid #dddddd" }}>
                      Net
                    </TableCell>

                    <TableCell align="center" sx={{ border: " 1px solid #dddddd" }}>
                      Vat 7%
                    </TableCell>

                    <TableCell align="center" sx={{ border: " 1px solid #dddddd" }}>
                      Grand Total
                    </TableCell> */}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {arrlstRow != null && arrlstRow.length > 0 ? arrlstRow.map((item, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell key={index} align="center" sx={{ border: " 1px solid #dddddd" }} >
                          {item.sName}
                        </TableCell>

                        {arrDuration != null && item.lstValue.length > 0 ? arrDuration.map((itemSub, indexSub) => {
                          let nvar = item.lstValue.find(e => e.nBannerPackage_ID == itemSub.nBannerPackage_ID)
                          if (nvar != null) {
                            return (
                              <TableCell key={nvar.nPosition_ID + "_" + nvar.nBannerPackage_ID} align="center" sx={{ border: " 1px solid #dddddd" }}>
                                {nvar.sName}
                              </TableCell>
                            )
                          }
                          // <TableCell key={nvar.nPosition_ID + "_" + nvar.nBannerPackage_ID} align="center" sx={{ border: " 1px solid #dddddd" }}>
                          //   {nvar.sVat}
                          // </TableCell>
                        }) : null}

                        {/* <TableCell key={index} align="center" sx={{ border: " 1px solid #dddddd" }} >
                          {item.sVat}
                        </TableCell> */}

                        {/* <TableCell align="center" sx={{ border: " 1px solid #dddddd" }}>dd</TableCell> */}
                        {/* <TableCell align="center" sx={{ border: " 1px solid #dddddd" }}>dd</TableCell>
                        <TableCell align="center" sx={{ border: " 1px solid #dddddd" }}>dd</TableCell> */}
                      </TableRow>
                    )
                  }) : null}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Stack>
      </Grid >

      <Dialog
        fullScreen
        open={isShowPreview}
        onClose={() => setIsShowPreview(false)}
      >
        <Toolbar sx={{ position: "relative", marginBottom: "1rem" }}>
          <IconButton
            edge="end"
            color="inherit"
            onClick={() => setIsShowPreview(false)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
        <Grid container>
          <Grid item xs={12}>
            <ContentPage lstContent={lstContent} OrderEmp={true} sContendHead={sContendHead} />
          </Grid>
        </Grid>
      </Dialog>
    </FormProvider >
  );
}
export default BannerPackageTable
