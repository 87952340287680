import React from "react";
import _get from "lodash/get";
import moment from "moment";
import "moment/min/moment-with-locales";
import "moment/locale/th";
// import {setLocale as setYupLocale} from 'yup';
import en from "./en";
import th from "./th";
import secureLocalStorage from "react-secure-storage";
// import SyncStorage from 'sync-storage';
import ParseHTML from "html-react-parser";

let currentLanguageCode = "";
let defalutLanguage = "th";

const languages = {
  en: {
    id: "en",
    label: "English",
    //flag: '/images/flags/24/United-States.png',
    dictionary: en,
    dateFns: undefined,
  },
  th: {
    id: "th",
    label: "Thai",
    //flag: '/images/flags/24/United-States.png',
    dictionary: th,
    dateFns: undefined,
  },
};

async function init() {
  // currentLanguageCode = "th";
  currentLanguageCode = secureLocalStorage.getItem("language") + "" || defalutLanguage;
  // secureLocalStorage.getItem("language").toString() || defalutLanguage;
  setLanguageCode(currentLanguageCode);
}

export function getLanguage() {
  return languages[getLanguageCode()];
}

function format(message, args) {
  if (!message) {
    return null;
  }

  try {
    return message.replace(/{(\d+)}/g, function (match, number) {
      return typeof args[number] != "undefined" ? args[number] : match;
    });
  } 
  catch (error) {
    console.error(message, error);
    throw error;
  }
}

export function getLanguages() {
  return Object.keys(languages).map((language) => {
    return languages[language];
  });
}

export function getLanguageCode() {
  if (!currentLanguageCode) {
    init();
  }
  return currentLanguageCode;
}

export async function setLanguageCode(arg) {
  // if (!languages[arg]) {
  //   // throw new Error(`Invalid language ${arg}.`);
  // }
  if (!languages[arg]) {
    arg = defalutLanguage;
    if (!arg) {
      throw new Error(`Invalid language ${arg}.`);
    }
    // throw new Error(`Invalid language ${arg}.`);
  }

  moment.locale(arg);
  currentLanguageCode = moment.locale();

  if (currentLanguageCode != secureLocalStorage.getItem("language")) {
    window.location.reload();
    await secureLocalStorage.setItem("language", arg);
  }

  await secureLocalStorage.setItem("language", arg);
  if (arg === "th") {
    moment.updateLocale("th", {
      relativeTime: {
        future: 'อีก %s',
        past: '%sที่แล้ว',
        s: 'ไม่กี่วินาที',
        ss: '%d วินาที',
        m: '1 นาที',
        mm: '%d นาที',
        h: '1 ชั่วโมง',
        hh: '%d ชั่วโมง',
        d: '1 วัน',
        dd: '%d วัน',
        w: '1 สัปดาห์',
        ww: '%d สัปดาห์',
        M: '1 เดือน',
        MM: '%d เดือน',
        y: '1 ปี',
        yy: '%d ปี',
      },
    });
  } else {
    moment.updateLocale("en", {
      relativeTime: {
        future: "in %s",
        past: "%s ago",
        s: "a few seconds",
        ss: "%d seconds",
        m: "a minute",
        mm: "%d minutes",
        h: "an hour",
        hh: "%dh",
        d: "a day",
        dd: "%d days",
        w: '1 weeks',
        ww: '%d weeks',
        M: "a month",
        MM: "%d months",
        y: "a year",
        yy: "%d years",
      },
    });
  }
  // window.location.reload();
}

export function i18nExists(key) {
  const message = _get(getLanguage().dictionary, key);
  return !!message;
}

export function i18n(key, ...args) {
  const message = _get(getLanguage().dictionary, key);
  if (!message) {
    return key;
  }
  return format(message, args);
}

export function i18nHtml(key, ...args) {
  return (
    <span>{ParseHTML(i18n(key, ...args))}</span>
  );
}