import { useState, useEffect } from "react";
import "./styles.css";
import { i18n } from "i18n";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Divider,
  Card,
  CardContent,
  Skeleton,
  Hidden,
} from "@mui/material";
import MuiGrid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  AxiosGet,
} from "service/CommonFunction/TS_function";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

const MyPageEmployer = () => {
  const navigate = useNavigate();
  const Grid = styled(MuiGrid)(({ theme }) => ({
    width: "100%",
    ...theme.typography.body2,
    '& [role="separator"]': {
      margin: theme.spacing(0, 2),
    },
  }));
  const [myPage, setMyPage] = useState<{ [key: string]: any }>({});
  const [order, setOrder] = useState<{ [key: string]: any }>({});
  const [candidate, setCandidate] = useState<{ [key: string]: any }>({});
  const [job, setJob] = useState<{ [key: string]: any }>({});
  const [lang, setLang] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [isMemberOrder, setMemberOrder] = useState(false);
  const [isMember_PostandSearch, setMember_PostandSearch] = useState(false);
  const [isMember_OrderandSearch, setMember_OrderandSearch] = useState(false);
  const [isMember_Search, setMember_Search] = useState(false);

  const i18nCard = "MyPageEmployer.card";
  const i18nDashboard = "MyPageEmployer.dashboard";

  const onGetDetail = () => {
    AxiosGet("MyPageEmployer/detail", {}, (res) => {
      setMyPage(res);

      setOrder(res.dataOrder);
      setCandidate(res.dataCandidate);
      setJob(res.dataJob);
      setLoading(false);
      setMemberOrder(res.isMemberOrder);
      setMember_PostandSearch(res.isPostandSearch);
      setMember_OrderandSearch(res.isOrderandSearch);
      setMember_Search(res.isSearch);
    });
  };

  useEffect(() => {
    setLang(secureLocalStorage.getItem("language").toString());
    onGetDetail();
  }, []);

  return (
    <>
      {/* phone size */}
      <Hidden smUp>
        <Card
          sx={{
            padding: "2em",
            borderRadius: "1em",
            position: "relative",
            // mx: "10%"
          }}
        >
          <Grid container spacing={2} sx={{ marginLeft: "-9px" }}>

            {/* Order card dashboard */}
            <Grid item md={4} xs={12}>
              {isLoading ? (
                <Skeleton variant="text" width="100%" />
              ) : (
                <Card sx={{ borderRadius: 5 }}>
                  <Typography
                    align="center"
                    color="#FFFFFF"
                    variant="h6"
                    sx={{ backgroundColor: "#262346" }}
                  >
                    {i18n(`${i18nCard}.Order`)}
                  </Typography>
                </Card>
              )}
              {isLoading ? (
                <Skeleton height={120} variant="rectangular" />
              ) : (
                <Card sx={{ borderRadius: 5, marginTop: "3%", color: "#262346" }}>
                  <CardContent sx={{ padding: "10px", height: "120px", justifyContent: "center" }}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      sx={{ height: "100%" }}
                    >
                      <Grid item md={4} xs={3}>
                        <Typography fontSize={15} textAlign="center" sx={{ height: "45px" }}>
                          {i18n(`${i18nCard}.OrderAll`)}
                        </Typography>
                        <Typography fontSize={30} textAlign="center" >
                          {order.nValue1}
                        </Typography>
                      </Grid>
                      <Divider orientation="vertical" flexItem />
                      <Grid item md={4} xs={3} >
                        <Typography fontSize={15} textAlign="center" sx={{ height: "45px" }}>
                          {i18n(`${i18nCard}.OrderActive`)}
                        </Typography>
                        <Typography fontSize={30} textAlign="center" >
                          {order.nValue2}
                        </Typography>
                      </Grid>
                      <Divider orientation="vertical" flexItem />
                      <Grid item md={3} xs={3}>
                        <Typography fontSize={15} textAlign="center" sx={{ height: "45px" }}>
                          {i18n(`${i18nCard}.OrderPending`)}
                        </Typography>
                        <Typography fontSize={30} textAlign="center"  >
                          {order.nValue3}
                        </Typography>
                      </Grid>
                    </Grid>

                  </CardContent>
                </Card>
              )}
            </Grid>
            {/* End order card dashboard */}

            {/* Candidate card dashboard */}
            <Grid item md={4} xs={12}>
              {isLoading ? (
                <Skeleton variant="text" width="100%" height={50} />
              ) : (
                <Card sx={{ borderRadius: 5 }}>
                  <Typography
                    align="center"
                    color="#FFFFFF"
                    variant="h6"
                    sx={{ backgroundColor: "#043a99" }}
                  >
                    {i18n(`${i18nCard}.Candidate`)}
                  </Typography>
                </Card>
              )}
              {isLoading ? (
                <Skeleton height={120} variant="rectangular" />
              ) : (
                <Card sx={{ borderRadius: 5, marginTop: "3%", color: "#043a99" }}>
                  <CardContent sx={{ padding: "10px", height: "120px" }}>
                    <Grid container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      sx={{ height: "100%" }}
                    >
                      <Grid item md={4} xs={3}>
                        <Typography fontSize={15} textAlign="center" sx={{ whiteSpace: "pre", height: "45px" }}>
                          {i18n(`${i18nCard}.CandidateAll`)}
                        </Typography>
                        <Typography fontSize={30} textAlign="center">
                          {candidate.nValue1}
                        </Typography>
                      </Grid>
                      <Divider orientation="vertical" flexItem />
                      <Grid item md={4} xs={3} className="divMyPageMiddle">
                        <Typography fontSize={15} textAlign="center" sx={{ height: "45px" }}>
                          {i18n(`${i18nCard}.CandidateUsed`)}
                        </Typography>
                        <Typography fontSize={30} textAlign="center" >
                          {candidate.nValue2}
                        </Typography>
                      </Grid>
                      <Divider orientation="vertical" flexItem />
                      <Grid item md={3} xs={3}>
                        <Typography fontSize={15} textAlign="center" sx={{ height: "45px" }} >
                          {i18n(`${i18nCard}.CandidateInvitation`)}
                        </Typography>
                        <Typography fontSize={30} textAlign="center" >
                          {candidate.nValue3}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              )}
            </Grid>
            {/* End candidate card dashboard */}

            {/* Job card dashboard */}
            <Grid item md={4} xs={12}>
              {isLoading ? (
                <Skeleton variant="text" width="100%" height={50} />
              ) : (
                <Card sx={{ borderRadius: 5 }}>
                  <Typography
                    align="center"
                    color="#FFFFFF"
                    variant="h6"
                    sx={{ backgroundColor: "#007ae0" }}
                  >
                    {i18n(`${i18nCard}.JobTitle`)}
                  </Typography>
                </Card>
              )}
              {isLoading ? (
                <Skeleton height={120} variant="rectangular" />
              ) : (
                <Card sx={{ borderRadius: 5, marginTop: "3%", color: "#007ae0" }}>
                  <CardContent sx={{ padding: "10px", height: "120px" }}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      sx={{ height: "100%" }}
                    >

                      <Grid item md={4} xs={3}>
                        <Typography fontSize={15} textAlign="center" sx={{ height: "45px" }}>
                          {i18n(`${i18nCard}.JobTitleAnnounced`)}
                        </Typography>
                        <Typography fontSize={30} textAlign="center">
                          {job.nValue1}
                        </Typography>
                      </Grid>
                      <Divider orientation="vertical" flexItem />
                      <Grid item md={4} xs={3} className="divMyPageMiddle">
                        <Typography fontSize={15} textAlign="center" sx={{ height: "45px" }}>
                          {i18n(`${i18nCard}.JobTitleQuota`)}

                        </Typography>
                        <Typography fontSize={30} textAlign="center">
                          {job.nValue2}
                        </Typography>
                      </Grid>
                      <Divider orientation="vertical" flexItem />
                      <Grid item md={3} xs={3}>
                        <Typography fontSize={15} textAlign="center" sx={{ height: "45px" }}>
                          {i18n(`${i18nCard}.JobTitleView`)}
                        </Typography>
                        <Typography fontSize={30} textAlign="center">
                          {job.nValue3}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              )}
            </Grid>
            {/* End job card dashboard */}

            {isMember_OrderandSearch ?
              (
                <>
                  <Grid item md={6} xs={12}>
                    {isLoading ? (
                      <Skeleton variant="text" width="100%" height={50} />
                    ) : (
                      <Card sx={{ borderRadius: 5 }}>
                        <Typography
                          align="center"
                          color="#FFFFFF"
                          variant="h6"
                          sx={{ backgroundColor: "#262346" }}
                        >
                          {i18n(`${i18nDashboard}.Dashboard`)}
                        </Typography>
                      </Card>
                    )}
                  </Grid>
                  <Grid item md={6} xs={12}>
                    {isLoading ? (
                      <Skeleton variant="text" width="100%" height={50} />
                    ) : (
                      <Card sx={{ borderRadius: 5 }}>
                        <Grid container>
                          <Grid item xs={10} justifyContent={"start"}>
                            <Typography
                              align="left"
                              color="#007ae0"
                              paddingLeft="20px"
                              variant="h6"
                            >
                              {i18n(`${i18nDashboard}.AccountStatus`)}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            justifyContent={"end"}
                            onClick={() => {
                              navigate("/OrderEmployer");
                            }}
                            className="cursor-pointer"
                          >
                            <Typography
                              align="right"
                              color="#007ae0"
                              variant="h6"
                              paddingRight="20px"
                            >
                              {i18n(`${i18nDashboard}.AccountStatusAll`)}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Card>
                    )}
                    {isLoading ? (
                      <Skeleton height={120} variant="rectangular" />
                    ) : (
                      <TableContainer sx={{ marginTop: "15px" }}>
                        <Table
                          size="small"
                          aria-label="simple table"
                          sx={{
                            border: 0,
                          }}
                        >
                          <TableHead
                            sx={{
                              backgroundColor: "#007ae0",
                            }}
                          >
                            <TableRow
                              sx={{
                                "& th": {
                                  color: "#FFFFFF",
                                },
                              }}
                            >
                              <TableCell align="center">
                                {i18n(`${i18nDashboard}.AccountStatusPackageTable`)}
                              </TableCell>
                              <TableCell align="center">
                                {i18n(`${i18nDashboard}.AccountStatusExpiredTable`)}
                              </TableCell>
                              <TableCell align="center">
                                {i18n(`${i18nDashboard}.AccountStatusJobTable`)}
                              </TableCell>
                              <TableCell align="center">
                                {i18n(`${i18nDashboard}.AccountStatusCoinsTable`)}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody
                            sx={{
                              "& td": {
                                border: "1px solid #d6dce1",
                              },
                            }}
                          >
                            {myPage.lstPackageMyPage != null &&
                              myPage.lstPackageMyPage.length > 0 ? (
                              myPage.lstPackageMyPage.map((row, index) => (
                                <>
                                  <TableRow>
                                    <TableCell align="left">{row.sName}</TableCell>
                                    <TableCell align="center">{row.sExpired}</TableCell>
                                    <TableCell align="center">{row.nJob}</TableCell>
                                    <TableCell align="center">{row.nCoin}</TableCell>
                                  </TableRow>
                                  {index === myPage.lstPackageMyPage.length - 1 ? (
                                    <TableRow>
                                      <TableCell align="right" colSpan={2}>
                                        {i18n(`${i18nDashboard}.AccountStatusTotalTable`)}
                                      </TableCell>
                                      <TableCell align="center">
                                        {myPage.nSumtotalJob}
                                      </TableCell>
                                      <TableCell align="center">
                                        {myPage.nSumtotalCoin}
                                      </TableCell>
                                    </TableRow>
                                  ) : (
                                    ""
                                  )}
                                </>
                              ))
                            ) : (
                              <TableRow>
                                <TableCell align="center" colSpan={4}>
                                  {i18n(`${i18nDashboard}.AccountStatusNodataTable`)}
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                  </Grid>
                  <Grid item md={6} xs={12}>
                    {isLoading ? (
                      <Skeleton variant="text" width="100%" height={50} />
                    ) : (
                      <Card sx={{ borderRadius: 5 }}>
                        <Grid container>
                          <Grid item xs={12} justifyContent={"start"}>
                            <Typography
                              align="left"
                              color="#007ae0"
                              paddingLeft="20px"
                              variant="h6"
                            >
                              {i18n(`${i18nDashboard}.Banner`)}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Card>
                    )}
                    {isLoading ? (
                      <Skeleton height={120} variant="rectangular" />
                    ) : (
                      <TableContainer sx={{ marginTop: "15px" }}>
                        <Table
                          size="small"
                          aria-label="simple table"
                          sx={{
                            border: 0,
                          }}
                        >
                          <TableHead
                            sx={{
                              backgroundColor: "#007ae0",
                            }}
                          >
                            <TableRow
                              sx={{
                                "& th": {
                                  color: "#FFFFFF",
                                },
                              }}
                            >
                              <TableCell align="center">
                                {i18n(`${i18nDashboard}.BannerTable`)}
                              </TableCell>
                              <TableCell align="center">
                                {i18n(`${i18nDashboard}.BannerExpiredTable`)}
                              </TableCell>
                              <TableCell align="center">
                                {i18n(`${i18nDashboard}.BannerLinkTable`)}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody
                            sx={{
                              "& td": {
                                border: "1px solid #d6dce1",
                              },
                            }}
                          >
                            {myPage.lstBannerMyPage != null &&
                              myPage.lstBannerMyPage.length > 0 ? (
                              myPage.lstBannerMyPage.map((row) => (
                                <TableRow>
                                  <TableCell align="left">{row.sName}</TableCell>
                                  <TableCell align="center">{row.sExpired}</TableCell>
                                  <TableCell align="left">{row.sLink}</TableCell>
                                </TableRow>
                              ))
                            ) : (
                              <TableRow>
                                <TableCell align="center" colSpan={3}>
                                  {i18n(`${i18nDashboard}.BannerNodataTable`)}
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                  </Grid>
                  <Grid item md={6} xs={12}>
                    {isLoading ? (
                      <Skeleton variant="text" width="100%" height={50} />
                    ) : (
                      <Card sx={{ borderRadius: 5 }}>
                        <Grid item xs={12} justifyContent={"start"}>
                          <Typography
                            align="left"
                            color="#FFFFFF"
                            paddingLeft="20px"
                            sx={{ backgroundColor: "#007ae0" }}
                            variant="h6"
                          >
                            {i18n(`${i18nDashboard}.TitleAccountManager`)}
                          </Typography>
                        </Grid>
                      </Card>
                    )}
                    {isLoading ? (
                      <Skeleton height={120} variant="rectangular" />
                    ) : (
                      <TableContainer sx={{ marginTop: "15px" }}>
                        <Table
                          size="small"
                          aria-label="simple table"
                          sx={{
                            border: 0,
                          }}
                        >
                          <TableHead
                            sx={{
                              backgroundColor: "#424343",
                            }}
                          >
                            <TableRow
                              sx={{
                                "& th": {
                                  color: "#FFFFFF",
                                },
                              }}
                            >
                              <TableCell colSpan={2} align="center">
                                {i18n(`${i18nDashboard}.AccountManager`)}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody
                            sx={{
                              "& td": {
                                border: "1px solid #d6dce1",
                              },
                            }}
                          >
                            {myPage.lstAccountManagerMyPage != null &&
                              myPage.lstAccountManagerMyPage.length > 0 ? (
                              myPage.lstAccountManagerMyPage.map((row, index) => (
                                <>
                                  <TableRow>
                                    <TableCell colSpan={2} align="left">
                                      <Typography fontSize={16} style={{ fontWeight: 600 }}>
                                        {row.sName}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell align="left">
                                      {i18n(`${i18nDashboard}.AccountManagerTelTable`)}
                                    </TableCell>
                                    <TableCell align="center">{row.sTel}</TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell align="left">
                                      {i18n(`${i18nDashboard}.AccountManagerEmailTable`)}
                                    </TableCell>
                                    <TableCell align="center">{row.sEmail}</TableCell>
                                  </TableRow>
                                </>
                              ))
                            ) : (
                              <TableRow>
                                <TableCell align="center" colSpan={2}>
                                  {i18n(`${i18nDashboard}.AccountManagerNodataTable`)}
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                  </Grid>
                  <Grid item md={6} xs={12}>
                    {isLoading ? (
                      <Skeleton variant="text" width="100%" height={50} />
                    ) : (
                      null
                    )}
                    {isLoading ? (
                      <Skeleton height={120} variant="rectangular" />
                    ) : (
                      null
                    )}
                  </Grid>
                </>
              ) : isMember_PostandSearch ?
                (
                  <>
                    <Grid item md={6} xs={12}>
                      {isLoading ? (
                        <Skeleton variant="text" width="100%" height={50} />
                      ) : (
                        <Card sx={{ borderRadius: 5 }}>
                          <Grid container>
                            <Grid item xs={10} justifyContent={"start"}>
                              <Typography
                                align="left"
                                color="#007ae0"
                                paddingLeft="20px"
                                variant="h6"
                              >
                                {i18n(`${i18nDashboard}.JobPost`)}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={2}
                              justifyContent={"end"}
                              onClick={() => {
                                navigate("/JobPostList");
                              }}
                              className="cursor-pointer"
                            >
                              <Typography
                                // align="right"
                                align="center"
                                color="#007ae0"
                                variant="h6"
                                paddingRight="20px"
                              >
                                {i18n(`${i18nDashboard}.JobPostAll`)}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Card>
                      )}
                      {isLoading ? (
                        <Skeleton height={120} variant="rectangular" />
                      ) : (
                        <TableContainer sx={{ marginTop: "15px" }}>
                          <Table
                            size="small"
                            aria-label="simple table"
                            sx={{
                              border: 0,
                            }}
                          >
                            <TableHead
                              sx={{
                                backgroundColor: "#007ae0",
                              }}
                            >
                              <TableRow
                                sx={{
                                  "& th": {
                                    color: "#FFFFFF",
                                  },
                                }}
                              >
                                <TableCell align="center">
                                  {i18n(`${i18nDashboard}.JobPostTable`)}
                                </TableCell>
                                <TableCell align="center">
                                  {i18n(`${i18nDashboard}.JobPostExpiredTable`)}
                                </TableCell>
                                <TableCell align="center">
                                  {i18n(`${i18nDashboard}.JobPostJobSeekerTable`)}
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody
                              sx={{
                                "& td": {
                                  border: "1px solid #d6dce1",
                                },
                              }}
                            >
                              {myPage.lstJobPostMyPage != null &&
                                myPage.lstJobPostMyPage.length > 0 ? (
                                myPage.lstJobPostMyPage.map((row) => (
                                  <TableRow>
                                    <TableCell align="left" sx={{ width: "50%" }}>
                                      {lang == "th" ? row.sName_TH : row.sName_EN}
                                    </TableCell>
                                    <TableCell align="center" sx={{ width: "10%" }}>{row.sExpired}</TableCell>
                                    <TableCell align="center" sx={{ width: "40%", whiteSpace: "pre" }}>
                                      {row.nJobSeeker}<br />({row.nJobSeeker_UnRead} unread)
                                    </TableCell>
                                  </TableRow>
                                ))
                              ) : (
                                <TableRow>
                                  <TableCell align="center" colSpan={3}>
                                    {i18n(`${i18nDashboard}.JobPostNodataTable`)}
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      )}
                    </Grid>
                    <Grid item md={6} xs={12}>
                      {isLoading ? (
                        <Skeleton variant="text" width="100%" height={50} />
                      ) : (
                        <Card sx={{ borderRadius: 5 }}>
                          <Grid item xs={12} justifyContent={"start"}>
                            <Typography
                              align="left"
                              color="#FFFFFF"
                              paddingLeft="20px"
                              sx={{ backgroundColor: "#007ae0" }}
                              variant="h6"
                            >
                              {i18n(`${i18nDashboard}.TitleAccountManager`)}
                            </Typography>
                          </Grid>
                        </Card>
                      )}
                      {isLoading ? (
                        <Skeleton height={120} variant="rectangular" />
                      ) : (
                        <TableContainer sx={{ marginTop: "15px" }}>
                          <Table
                            size="small"
                            aria-label="simple table"
                            sx={{
                              border: 0,
                            }}
                          >
                            <TableHead
                              sx={{
                                backgroundColor: "#424343",
                              }}
                            >
                              <TableRow
                                sx={{
                                  "& th": {
                                    color: "#FFFFFF",
                                  },
                                }}
                              >
                                <TableCell colSpan={2} align="center">
                                  {i18n(`${i18nDashboard}.AccountManager`)}
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody
                              sx={{
                                "& td": {
                                  border: "1px solid #d6dce1",
                                },
                              }}
                            >
                              {myPage.lstAccountManagerMyPage != null &&
                                myPage.lstAccountManagerMyPage.length > 0 ? (
                                myPage.lstAccountManagerMyPage.map((row, index) => (
                                  <>
                                    <TableRow>
                                      <TableCell colSpan={2} align="left">
                                        <Typography fontSize={16} style={{ fontWeight: 600 }}>
                                          {row.sName}
                                        </Typography>
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell align="left">
                                        {i18n(`${i18nDashboard}.AccountManagerTelTable`)}
                                      </TableCell>
                                      <TableCell align="center">{row.sTel}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell align="left">
                                        {i18n(`${i18nDashboard}.AccountManagerEmailTable`)}
                                      </TableCell>
                                      <TableCell align="center">{row.sEmail}</TableCell>
                                    </TableRow>
                                  </>
                                ))
                              ) : (
                                <TableRow>
                                  <TableCell align="center" colSpan={2}>
                                    {i18n(`${i18nDashboard}.AccountManagerNodataTable`)}
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      )}
                    </Grid>
                    {/* <Grid item md={6} xs={12}>
                    {isLoading ? (
                      <Skeleton variant="text" width="100%" height={50} />
                    ) : (
                      null
                    )}
                    {isLoading ? (
                      <Skeleton height={120} variant="rectangular" />
                    ) : (
                      null
                    )}
                  </Grid> */}
                  </>
                ) : isMember_Search ?
                  (
                    <>
                      <Grid item md={6} xs={12}>
                        {isLoading ? (
                          <Skeleton variant="text" width="100%" height={50} />
                        ) : (
                          <Card sx={{ borderRadius: 5 }}>
                            <Grid item xs={12} justifyContent={"start"}>
                              <Typography
                                align="left"
                                color="#FFFFFF"
                                paddingLeft="20px"
                                sx={{ backgroundColor: "#007ae0" }}
                                variant="h6"
                              >
                                {i18n(`${i18nDashboard}.TitleAccountManager`)}
                              </Typography>
                            </Grid>
                          </Card>
                        )}
                        {isLoading ? (
                          <Skeleton height={120} variant="rectangular" />
                        ) : (
                          <TableContainer sx={{ marginTop: "15px" }}>
                            <Table
                              size="small"
                              aria-label="simple table"
                              sx={{
                                border: 0,
                              }}
                            >
                              <TableHead
                                sx={{
                                  backgroundColor: "#424343",
                                }}
                              >
                                <TableRow
                                  sx={{
                                    "& th": {
                                      color: "#FFFFFF",
                                    },
                                  }}
                                >
                                  <TableCell colSpan={2} align="center">
                                    {i18n(`${i18nDashboard}.AccountManager`)}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody
                                sx={{
                                  "& td": {
                                    border: "1px solid #d6dce1",
                                  },
                                }}
                              >
                                {myPage.lstAccountManagerMyPage != null &&
                                  myPage.lstAccountManagerMyPage.length > 0 ? (
                                  myPage.lstAccountManagerMyPage.map((row, index) => (
                                    <>
                                      <TableRow>
                                        <TableCell colSpan={2} align="left">
                                          <Typography fontSize={16} style={{ fontWeight: 600 }}>
                                            {row.sName}
                                          </Typography>
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell align="left">
                                          {i18n(`${i18nDashboard}.AccountManagerTelTable`)}
                                        </TableCell>
                                        <TableCell align="center">{row.sTel}</TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell align="left">
                                          {i18n(`${i18nDashboard}.AccountManagerEmailTable`)}
                                        </TableCell>
                                        <TableCell align="center">{row.sEmail}</TableCell>
                                      </TableRow>
                                    </>
                                  ))
                                ) : (
                                  <TableRow>
                                    <TableCell align="center" colSpan={2}>
                                      {i18n(`${i18nDashboard}.AccountManagerNodataTable`)}
                                    </TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        )}
                      </Grid>
                      {/* <Grid item md={6} xs={12}>
                      {isLoading ? (
                        <Skeleton variant="text" width="100%" height={50} />
                      ) : (
                        null
                      )}
                      {isLoading ? (
                        <Skeleton height={120} variant="rectangular" />
                      ) : (
                        null
                      )}
                    </Grid> */}
                    </>
                  ) : (
                    <>
                      <Grid item md={6} xs={12}>
                        {isLoading ? (
                          <Skeleton variant="text" width="100%" height={50} />
                        ) : (
                          <Card sx={{ borderRadius: 5 }}>
                            <Typography
                              align="center"
                              color="#FFFFFF"
                              variant="h6"
                              sx={{ backgroundColor: "#262346" }}
                            >
                              {i18n(`${i18nDashboard}.Dashboard`)}
                            </Typography>
                          </Card>
                        )}
                      </Grid>
                      <Grid item md={6} xs={12}>
                        {isLoading ? (
                          <Skeleton variant="text" width="100%" height={50} />
                        ) : (
                          <Card sx={{ borderRadius: 5 }}>
                            <Grid container>
                              <Grid item xs={10} justifyContent={"start"}>
                                <Typography
                                  align="left"
                                  color="#007ae0"
                                  paddingLeft="20px"
                                  variant="h6"
                                >
                                  {i18n(`${i18nDashboard}.JobPost`)}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={2}
                                justifyContent={"end"}
                                onClick={() => {
                                  navigate("/JobPostList");
                                }}
                                className="cursor-pointer"
                              >
                                <Typography
                                  // align="right"
                                  align="center"
                                  color="#007ae0"
                                  variant="h6"
                                  paddingRight="20px"
                                >
                                  {i18n(`${i18nDashboard}.JobPostAll`)}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Card>
                        )}
                        {isLoading ? (
                          <Skeleton height={120} variant="rectangular" />
                        ) : (
                          <TableContainer sx={{ marginTop: "15px" }}>
                            <Table
                              size="small"
                              aria-label="simple table"
                              sx={{
                                border: 0,
                              }}
                            >
                              <TableHead
                                sx={{
                                  backgroundColor: "#007ae0",
                                }}
                              >
                                <TableRow
                                  sx={{
                                    "& th": {
                                      color: "#FFFFFF",
                                    },
                                  }}
                                >
                                  <TableCell align="center">
                                    {i18n(`${i18nDashboard}.JobPostTable`)}
                                  </TableCell>
                                  <TableCell align="center">
                                    {i18n(`${i18nDashboard}.JobPostExpiredTable`)}
                                  </TableCell>
                                  <TableCell align="center">
                                    {i18n(`${i18nDashboard}.JobPostJobSeekerTable`)}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody
                                sx={{
                                  "& td": {
                                    border: "1px solid #d6dce1",
                                  },
                                }}
                              >
                                {myPage.lstJobPostMyPage != null &&
                                  myPage.lstJobPostMyPage.length > 0 ? (
                                  myPage.lstJobPostMyPage.map((row) => (
                                    <TableRow>
                                      <TableCell align="left" sx={{ width: "50%" }}>
                                        {lang == "th" ? row.sName_TH : row.sName_EN}
                                      </TableCell>
                                      <TableCell align="center" sx={{ width: "10%" }}>{row.sExpired}</TableCell>
                                      <TableCell align="center" sx={{ width: "40%", whiteSpace: "pre" }}>
                                        {row.nJobSeeker}<br />({row.nJobSeeker_UnRead} unread)
                                      </TableCell>
                                    </TableRow>
                                  ))
                                ) : (
                                  <TableRow>
                                    <TableCell align="center" colSpan={3}>
                                      {i18n(`${i18nDashboard}.JobPostNodataTable`)}
                                    </TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        )}
                      </Grid>
                      <Grid item md={6} xs={12}>
                        {isLoading ? (
                          <Skeleton variant="text" width="100%" height={50} />
                        ) : (
                          <Card sx={{ borderRadius: 5 }}>
                            <Grid container>
                              <Grid item xs={10} justifyContent={"start"}>
                                <Typography
                                  align="left"
                                  color="#007ae0"
                                  paddingLeft="20px"
                                  variant="h6"
                                >
                                  {i18n(`${i18nDashboard}.AccountStatus`)}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={2}
                                justifyContent={"end"}
                                onClick={() => {
                                  navigate("/OrderEmployer");
                                }}
                                className="cursor-pointer"
                              >
                                <Typography
                                  align="right"
                                  color="#007ae0"
                                  variant="h6"
                                  paddingRight="20px"
                                >
                                  {i18n(`${i18nDashboard}.AccountStatusAll`)}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Card>
                        )}
                        {isLoading ? (
                          <Skeleton height={120} variant="rectangular" />
                        ) : (
                          <TableContainer sx={{ marginTop: "15px" }}>
                            <Table
                              size="small"
                              aria-label="simple table"
                              sx={{
                                border: 0,
                              }}
                            >
                              <TableHead
                                sx={{
                                  backgroundColor: "#007ae0",
                                }}
                              >
                                <TableRow
                                  sx={{
                                    "& th": {
                                      color: "#FFFFFF",
                                    },
                                  }}
                                >
                                  <TableCell align="center">
                                    {i18n(`${i18nDashboard}.AccountStatusPackageTable`)}
                                  </TableCell>
                                  <TableCell align="center">
                                    {i18n(`${i18nDashboard}.AccountStatusExpiredTable`)}
                                  </TableCell>
                                  <TableCell align="center">
                                    {i18n(`${i18nDashboard}.AccountStatusJobTable`)}
                                  </TableCell>
                                  <TableCell align="center">
                                    {i18n(`${i18nDashboard}.AccountStatusCoinsTable`)}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody
                                sx={{
                                  "& td": {
                                    border: "1px solid #d6dce1",
                                  },
                                }}
                              >
                                {myPage.lstPackageMyPage != null &&
                                  myPage.lstPackageMyPage.length > 0 ? (
                                  myPage.lstPackageMyPage.map((row, index) => (
                                    <>
                                      <TableRow>
                                        <TableCell align="left">{row.sName}</TableCell>
                                        <TableCell align="center">{row.sExpired}</TableCell>
                                        <TableCell align="center">{row.nJob}</TableCell>
                                        <TableCell align="center">{row.nCoin}</TableCell>
                                      </TableRow>
                                      {index === myPage.lstPackageMyPage.length - 1 ? (
                                        <TableRow>
                                          <TableCell align="right" colSpan={2}>
                                            {i18n(`${i18nDashboard}.AccountStatusTotalTable`)}
                                          </TableCell>
                                          <TableCell align="center">
                                            {myPage.nSumtotalJob}
                                          </TableCell>
                                          <TableCell align="center">
                                            {myPage.nSumtotalCoin}
                                          </TableCell>
                                        </TableRow>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  ))
                                ) : (
                                  <TableRow>
                                    <TableCell align="center" colSpan={4}>
                                      {i18n(`${i18nDashboard}.AccountStatusNodataTable`)}
                                    </TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        )}
                      </Grid>
                      <Grid item md={6} xs={12}>
                        {isLoading ? (
                          <Skeleton variant="text" width="100%" height={50} />
                        ) : (
                          <Card sx={{ borderRadius: 5 }}>
                            <Grid container>
                              <Grid item xs={12} justifyContent={"start"}>
                                <Typography
                                  align="left"
                                  color="#007ae0"
                                  paddingLeft="20px"
                                  variant="h6"
                                >
                                  {i18n(`${i18nDashboard}.Banner`)}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Card>
                        )}
                        {isLoading ? (
                          <Skeleton height={120} variant="rectangular" />
                        ) : (
                          <TableContainer sx={{ marginTop: "15px" }}>
                            <Table
                              size="small"
                              aria-label="simple table"
                              sx={{
                                border: 0,
                              }}
                            >
                              <TableHead
                                sx={{
                                  backgroundColor: "#007ae0",
                                }}
                              >
                                <TableRow
                                  sx={{
                                    "& th": {
                                      color: "#FFFFFF",
                                    },
                                  }}
                                >
                                  <TableCell align="center">
                                    {i18n(`${i18nDashboard}.BannerTable`)}
                                  </TableCell>
                                  <TableCell align="center">
                                    {i18n(`${i18nDashboard}.BannerExpiredTable`)}
                                  </TableCell>
                                  <TableCell align="center">
                                    {i18n(`${i18nDashboard}.BannerLinkTable`)}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody
                                sx={{
                                  "& td": {
                                    border: "1px solid #d6dce1",
                                  },
                                }}
                              >
                                {myPage.lstBannerMyPage != null &&
                                  myPage.lstBannerMyPage.length > 0 ? (
                                  myPage.lstBannerMyPage.map((row) => (
                                    <TableRow>
                                      <TableCell align="left">{row.sName}</TableCell>
                                      <TableCell align="center">{row.sExpired}</TableCell>
                                      <TableCell align="left">{row.sLink}</TableCell>
                                    </TableRow>
                                  ))
                                ) : (
                                  <TableRow>
                                    <TableCell align="center" colSpan={3}>
                                      {i18n(`${i18nDashboard}.BannerNodataTable`)}
                                    </TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        )}
                      </Grid>
                      <Grid item md={6} xs={12}>
                        {isLoading ? (
                          <Skeleton variant="text" width="100%" height={50} />
                        ) : (
                          <Card sx={{ borderRadius: 5 }}>
                            <Grid item xs={12} justifyContent={"start"}>
                              <Typography
                                align="left"
                                color="#FFFFFF"
                                paddingLeft="20px"
                                sx={{ backgroundColor: "#007ae0" }}
                                variant="h6"
                              >
                                {i18n(`${i18nDashboard}.TitleAccountManager`)}
                              </Typography>
                            </Grid>
                          </Card>
                        )}
                        {isLoading ? (
                          <Skeleton height={120} variant="rectangular" />
                        ) : (
                          <TableContainer sx={{ marginTop: "15px" }}>
                            <Table
                              size="small"
                              aria-label="simple table"
                              sx={{
                                border: 0,
                              }}
                            >
                              <TableHead
                                sx={{
                                  backgroundColor: "#424343",
                                }}
                              >
                                <TableRow
                                  sx={{
                                    "& th": {
                                      color: "#FFFFFF",
                                    },
                                  }}
                                >
                                  <TableCell colSpan={2} align="center">
                                    {i18n(`${i18nDashboard}.AccountManager`)}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody
                                sx={{
                                  "& td": {
                                    border: "1px solid #d6dce1",
                                  },
                                }}
                              >
                                {myPage.lstAccountManagerMyPage != null &&
                                  myPage.lstAccountManagerMyPage.length > 0 ? (
                                  myPage.lstAccountManagerMyPage.map((row, index) => (
                                    <>
                                      <TableRow>
                                        <TableCell colSpan={2} align="left">
                                          <Typography fontSize={16} style={{ fontWeight: 600 }}>
                                            {row.sName}
                                          </Typography>
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell align="left">
                                          {i18n(`${i18nDashboard}.AccountManagerTelTable`)}
                                        </TableCell>
                                        <TableCell align="center">{row.sTel}</TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell align="left">
                                          {i18n(`${i18nDashboard}.AccountManagerEmailTable`)}
                                        </TableCell>
                                        <TableCell align="center">{row.sEmail}</TableCell>
                                      </TableRow>
                                    </>
                                  ))
                                ) : (
                                  <TableRow>
                                    <TableCell align="center" colSpan={2}>
                                      {i18n(`${i18nDashboard}.AccountManagerNodataTable`)}
                                    </TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        )}
                      </Grid>
                    </>
                  )
            }
          </Grid>
        </Card>
      </Hidden>

      <Hidden smDown>
        {/* normal sizse */}
        <Card
          sx={{
            padding: "2em",
            borderRadius: "1em",
            position: "relative",
            mx: "10%"
          }}
        >
          <Grid container spacing={2} sx={{ marginLeft: "-9px" }}>

            {/* Order card dashboard */}
            <Grid item md={4} xs={12}>
              {isLoading ? (
                <Skeleton variant="text" width="100%" />
              ) : (
                <Card sx={{ borderRadius: 5 }}>
                  <Typography
                    align="center"
                    color="#FFFFFF"
                    variant="h6"
                    sx={{ backgroundColor: "#262346" }}
                  >
                    {i18n(`${i18nCard}.Order`)}
                  </Typography>
                </Card>
              )}
              {isLoading ? (
                <Skeleton height={120} variant="rectangular" />
              ) : (
                <Card sx={{ borderRadius: 5, marginTop: "3%", color: "#262346" }}>
                  <CardContent sx={{ padding: "10px", height: "120px", justifyContent: "center" }}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      sx={{ height: "100%" }}
                    >
                      <Grid item md={4} xs={3}>
                        <Typography fontSize={15} textAlign="center" sx={{ height: "45px" }}>
                          {i18n(`${i18nCard}.OrderAll`)}
                        </Typography>
                        <Typography fontSize={30} textAlign="center" >
                          {order.nValue1}
                        </Typography>
                      </Grid>
                      <Divider orientation="vertical" flexItem />
                      <Grid item md={4} xs={3} >
                        <Typography fontSize={15} textAlign="center" sx={{ height: "45px" }}>
                          {i18n(`${i18nCard}.OrderActive`)}
                        </Typography>
                        <Typography fontSize={30} textAlign="center" >
                          {order.nValue2}
                        </Typography>
                      </Grid>
                      <Divider orientation="vertical" flexItem />
                      <Grid item md={3} xs={3}>
                        <Typography fontSize={15} textAlign="center" sx={{ height: "45px" }}>
                          {i18n(`${i18nCard}.OrderPending`)}
                        </Typography>
                        <Typography fontSize={30} textAlign="center"  >
                          {order.nValue3}
                        </Typography>
                      </Grid>
                    </Grid>

                  </CardContent>
                </Card>
              )}
            </Grid>
            {/* End order card dashboard */}

            {/* Candidate card dashboard */}
            <Grid item md={4} xs={12}>
              {isLoading ? (
                <Skeleton variant="text" width="100%" height={50} />
              ) : (
                <Card sx={{ borderRadius: 5 }}>
                  <Typography
                    align="center"
                    color="#FFFFFF"
                    variant="h6"
                    sx={{ backgroundColor: "#043a99" }}
                  >
                    {i18n(`${i18nCard}.Candidate`)}
                  </Typography>
                </Card>
              )}
              {isLoading ? (
                <Skeleton height={120} variant="rectangular" />
              ) : (
                <Card sx={{ borderRadius: 5, marginTop: "3%", color: "#043a99" }}>
                  <CardContent sx={{ padding: "10px", height: "120px" }}>
                    <Grid container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      sx={{ height: "100%" }}
                    >
                      <Grid item md={4} xs={3}>
                        <Typography fontSize={15} textAlign="center" sx={{ whiteSpace: "pre", height: "45px" }}>
                          {i18n(`${i18nCard}.CandidateAll`)}
                        </Typography>
                        <Typography fontSize={30} textAlign="center">
                          {candidate.nValue1}
                        </Typography>
                      </Grid>
                      <Divider orientation="vertical" flexItem />
                      <Grid item md={4} xs={3} className="divMyPageMiddle">
                        <Typography fontSize={15} textAlign="center" sx={{ height: "45px" }}>
                          {i18n(`${i18nCard}.CandidateUsed`)}
                        </Typography>
                        <Typography fontSize={30} textAlign="center" >
                          {candidate.nValue2}
                        </Typography>
                      </Grid>
                      <Divider orientation="vertical" flexItem />
                      <Grid item md={3} xs={3}>
                        <Typography fontSize={15} textAlign="center" sx={{ height: "45px" }} >
                          {i18n(`${i18nCard}.CandidateInvitation`)}
                        </Typography>
                        <Typography fontSize={30} textAlign="center" >
                          {candidate.nValue3}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              )}
            </Grid>
            {/* End candidate card dashboard */}

            {/* Job card dashboard */}
            <Grid item md={4} xs={12}>
              {isLoading ? (
                <Skeleton variant="text" width="100%" height={50} />
              ) : (
                <Card sx={{ borderRadius: 5 }}>
                  <Typography
                    align="center"
                    color="#FFFFFF"
                    variant="h6"
                    sx={{ backgroundColor: "#007ae0" }}
                  >
                    {i18n(`${i18nCard}.JobTitle`)}
                  </Typography>
                </Card>
              )}
              {isLoading ? (
                <Skeleton height={120} variant="rectangular" />
              ) : (
                <Card sx={{ borderRadius: 5, marginTop: "3%", color: "#007ae0" }}>
                  <CardContent sx={{ padding: "10px", height: "120px" }}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      sx={{ height: "100%" }}
                    >

                      <Grid item md={4} xs={3}>
                        <Typography fontSize={15} textAlign="center" sx={{ height: "45px" }}>
                          {i18n(`${i18nCard}.JobTitleAnnounced`)}
                        </Typography>
                        <Typography fontSize={30} textAlign="center">
                          {job.nValue1}
                        </Typography>
                      </Grid>
                      <Divider orientation="vertical" flexItem />
                      <Grid item md={4} xs={3} className="divMyPageMiddle">
                        <Typography fontSize={15} textAlign="center" sx={{ height: "45px" }}>
                          {i18n(`${i18nCard}.JobTitleQuota`)}

                        </Typography>
                        <Typography fontSize={30} textAlign="center">
                          {job.nValue2}
                        </Typography>
                      </Grid>
                      <Divider orientation="vertical" flexItem />
                      <Grid item md={3} xs={3}>
                        <Typography fontSize={15} textAlign="center" sx={{ height: "45px" }}>
                          {i18n(`${i18nCard}.JobTitleView`)}
                        </Typography>
                        <Typography fontSize={30} textAlign="center">
                          {job.nValue3}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              )}
            </Grid>
            {/* End job card dashboard */}

            {isMember_OrderandSearch ?
              (
                <>
                  <Grid item xs={12}>
                    {isLoading ? (
                      <Skeleton variant="text" width="100%" height={50} />
                    ) : (
                      <Card sx={{ borderRadius: 5 }}>
                        <Typography
                          align="center"
                          color="#FFFFFF"
                          variant="h6"
                          sx={{ backgroundColor: "#262346" }}
                        >
                          {i18n(`${i18nDashboard}.Dashboard`)}
                        </Typography>
                      </Card>
                    )}
                  </Grid>
                  <Grid item md={6} xs={12}>
                    {isLoading ? (
                      <Skeleton variant="text" width="100%" height={50} />
                    ) : (
                      <Card sx={{ borderRadius: 5 }}>
                        <Grid container>
                          <Grid item xs={10} justifyContent={"start"}>
                            <Typography
                              align="left"
                              color="#007ae0"
                              paddingLeft="20px"
                              variant="h6"
                            >
                              {i18n(`${i18nDashboard}.AccountStatus`)}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            justifyContent={"end"}
                            onClick={() => {
                              navigate("/OrderEmployer");
                            }}
                            className="cursor-pointer"
                          >
                            <Typography
                              align="right"
                              color="#007ae0"
                              variant="h6"
                              paddingRight="20px"
                            >
                              {i18n(`${i18nDashboard}.AccountStatusAll`)}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Card>
                    )}
                    {isLoading ? (
                      <Skeleton height={120} variant="rectangular" />
                    ) : (
                      <TableContainer sx={{ marginTop: "15px" }}>
                        <Table
                          size="small"
                          aria-label="simple table"
                          sx={{
                            border: 0,
                          }}
                        >
                          <TableHead
                            sx={{
                              backgroundColor: "#007ae0",
                            }}
                          >
                            <TableRow
                              sx={{
                                "& th": {
                                  color: "#FFFFFF",
                                },
                              }}
                            >
                              <TableCell align="center">
                                {i18n(`${i18nDashboard}.AccountStatusPackageTable`)}
                              </TableCell>
                              <TableCell align="center">
                                {i18n(`${i18nDashboard}.AccountStatusExpiredTable`)}
                              </TableCell>
                              <TableCell align="center">
                                {i18n(`${i18nDashboard}.AccountStatusJobTable`)}
                              </TableCell>
                              <TableCell align="center">
                                {i18n(`${i18nDashboard}.AccountStatusCoinsTable`)}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody
                            sx={{
                              "& td": {
                                border: "1px solid #d6dce1",
                              },
                            }}
                          >
                            {myPage.lstPackageMyPage != null &&
                              myPage.lstPackageMyPage.length > 0 ? (
                              myPage.lstPackageMyPage.map((row, index) => (
                                <>
                                  <TableRow>
                                    <TableCell align="left">{row.sName}</TableCell>
                                    <TableCell align="center">{row.sExpired}</TableCell>
                                    <TableCell align="center">{row.nJob}</TableCell>
                                    <TableCell align="center">{row.nCoin}</TableCell>
                                  </TableRow>
                                  {index === myPage.lstPackageMyPage.length - 1 ? (
                                    <TableRow>
                                      <TableCell align="right" colSpan={2}>
                                        {i18n(`${i18nDashboard}.AccountStatusTotalTable`)}
                                      </TableCell>
                                      <TableCell align="center">
                                        {myPage.nSumtotalJob}
                                      </TableCell>
                                      <TableCell align="center">
                                        {myPage.nSumtotalCoin}
                                      </TableCell>
                                    </TableRow>
                                  ) : (
                                    ""
                                  )}
                                </>
                              ))
                            ) : (
                              <TableRow>
                                <TableCell align="center" colSpan={4}>
                                  {i18n(`${i18nDashboard}.AccountStatusNodataTable`)}
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                  </Grid>
                  <Grid item md={6} xs={12}>
                    {isLoading ? (
                      <Skeleton variant="text" width="100%" height={50} />
                    ) : (
                      <Card sx={{ borderRadius: 5 }}>
                        <Grid container>
                          <Grid item xs={12} justifyContent={"start"}>
                            <Typography
                              align="left"
                              color="#007ae0"
                              paddingLeft="20px"
                              variant="h6"
                            >
                              {i18n(`${i18nDashboard}.Banner`)}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Card>
                    )}
                    {isLoading ? (
                      <Skeleton height={120} variant="rectangular" />
                    ) : (
                      <TableContainer sx={{ marginTop: "15px" }}>
                        <Table
                          size="small"
                          aria-label="simple table"
                          sx={{
                            border: 0,
                          }}
                        >
                          <TableHead
                            sx={{
                              backgroundColor: "#007ae0",
                            }}
                          >
                            <TableRow
                              sx={{
                                "& th": {
                                  color: "#FFFFFF",
                                },
                              }}
                            >
                              <TableCell align="center">
                                {i18n(`${i18nDashboard}.BannerTable`)}
                              </TableCell>
                              <TableCell align="center">
                                {i18n(`${i18nDashboard}.BannerExpiredTable`)}
                              </TableCell>
                              <TableCell align="center">
                                {i18n(`${i18nDashboard}.BannerLinkTable`)}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody
                            sx={{
                              "& td": {
                                border: "1px solid #d6dce1",
                              },
                            }}
                          >
                            {myPage.lstBannerMyPage != null &&
                              myPage.lstBannerMyPage.length > 0 ? (
                              myPage.lstBannerMyPage.map((row) => (
                                <TableRow>
                                  <TableCell align="left">{row.sName}</TableCell>
                                  <TableCell align="center">{row.sExpired}</TableCell>
                                  <TableCell align="left">{row.sLink}</TableCell>
                                </TableRow>
                              ))
                            ) : (
                              <TableRow>
                                <TableCell align="center" colSpan={3}>
                                  {i18n(`${i18nDashboard}.BannerNodataTable`)}
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                  </Grid>
                  <Grid item md={6} xs={12}>
                    {isLoading ? (
                      <Skeleton variant="text" width="100%" height={50} />
                    ) : (
                      <Card sx={{ borderRadius: 5 }}>
                        <Grid item xs={12} justifyContent={"start"}>
                          <Typography
                            align="left"
                            color="#FFFFFF"
                            paddingLeft="20px"
                            sx={{ backgroundColor: "#007ae0" }}
                            variant="h6"
                          >
                            {i18n(`${i18nDashboard}.TitleAccountManager`)}
                          </Typography>
                        </Grid>
                      </Card>
                    )}
                    {isLoading ? (
                      <Skeleton height={120} variant="rectangular" />
                    ) : (
                      <TableContainer sx={{ marginTop: "15px" }}>
                        <Table
                          size="small"
                          aria-label="simple table"
                          sx={{
                            border: 0,
                          }}
                        >
                          <TableHead
                            sx={{
                              backgroundColor: "#424343",
                            }}
                          >
                            <TableRow
                              sx={{
                                "& th": {
                                  color: "#FFFFFF",
                                },
                              }}
                            >
                              <TableCell colSpan={2} align="center">
                                {i18n(`${i18nDashboard}.AccountManager`)}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody
                            sx={{
                              "& td": {
                                border: "1px solid #d6dce1",
                              },
                            }}
                          >
                            {myPage.lstAccountManagerMyPage != null &&
                              myPage.lstAccountManagerMyPage.length > 0 ? (
                              myPage.lstAccountManagerMyPage.map((row, index) => (
                                <>
                                  <TableRow>
                                    <TableCell colSpan={2} align="left">
                                      <Typography fontSize={16} style={{ fontWeight: 600 }}>
                                        {row.sName}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell align="left">
                                      {i18n(`${i18nDashboard}.AccountManagerTelTable`)}
                                    </TableCell>
                                    <TableCell align="center">{row.sTel}</TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell align="left">
                                      {i18n(`${i18nDashboard}.AccountManagerEmailTable`)}
                                    </TableCell>
                                    <TableCell align="center">{row.sEmail}</TableCell>
                                  </TableRow>
                                </>
                              ))
                            ) : (
                              <TableRow>
                                <TableCell align="center" colSpan={2}>
                                  {i18n(`${i18nDashboard}.AccountManagerNodataTable`)}
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                  </Grid>
                  <Grid item md={6} xs={12}>
                    {isLoading ? (
                      <Skeleton variant="text" width="100%" height={50} />
                    ) : (
                      null
                    )}
                    {isLoading ? (
                      <Skeleton height={120} variant="rectangular" />
                    ) : (
                      null
                    )}
                  </Grid>
                </>
              ) : isMember_PostandSearch ?
                (
                  <>
                    <Grid item md={6} xs={6}>
                      {isLoading ? (
                        <Skeleton variant="text" width="100%" height={50} />
                      ) : (
                        <Card sx={{ borderRadius: 5 }}>
                          <Grid container>
                            <Grid item xs={10} justifyContent={"start"}>
                              <Typography
                                align="left"
                                color="#007ae0"
                                paddingLeft="20px"
                                variant="h6"
                              >
                                {i18n(`${i18nDashboard}.JobPost`)}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={2}
                              justifyContent={"end"}
                              onClick={() => {
                                navigate("/JobPostList");
                              }}
                              className="cursor-pointer"
                            >
                              <Typography
                                // align="right"
                                align="center"
                                color="#007ae0"
                                variant="h6"
                                paddingRight="20px"
                              >
                                {i18n(`${i18nDashboard}.JobPostAll`)}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Card>
                      )}
                      {isLoading ? (
                        <Skeleton height={120} variant="rectangular" />
                      ) : (
                        <TableContainer sx={{ marginTop: "15px" }}>
                          <Table
                            size="small"
                            aria-label="simple table"
                            sx={{
                              border: 0,
                            }}
                          >
                            <TableHead
                              sx={{
                                backgroundColor: "#007ae0",
                              }}
                            >
                              <TableRow
                                sx={{
                                  "& th": {
                                    color: "#FFFFFF",
                                  },
                                }}
                              >
                                <TableCell align="center">
                                  {i18n(`${i18nDashboard}.JobPostTable`)}
                                </TableCell>
                                <TableCell align="center">
                                  {i18n(`${i18nDashboard}.JobPostExpiredTable`)}
                                </TableCell>
                                <TableCell align="center">
                                  {i18n(`${i18nDashboard}.JobPostJobSeekerTable`)}
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody
                              sx={{
                                "& td": {
                                  border: "1px solid #d6dce1",
                                },
                              }}
                            >
                              {myPage.lstJobPostMyPage != null &&
                                myPage.lstJobPostMyPage.length > 0 ? (
                                myPage.lstJobPostMyPage.map((row) => (
                                  <TableRow>
                                    <TableCell align="left" sx={{ width: "50%" }}>
                                      {lang == "th" ? row.sName_TH : row.sName_EN}
                                    </TableCell>
                                    <TableCell align="center" sx={{ width: "10%" }}>{row.sExpired}</TableCell>
                                    <TableCell align="center" sx={{ width: "40%", whiteSpace: "pre" }}>
                                      {row.nJobSeeker}<br />({row.nJobSeeker_UnRead} unread)
                                    </TableCell>
                                  </TableRow>
                                ))
                              ) : (
                                <TableRow>
                                  <TableCell align="center" colSpan={3}>
                                    {i18n(`${i18nDashboard}.JobPostNodataTable`)}
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      )}
                    </Grid>
                    <Grid item md={6} xs={6}>
                      {isLoading ? (
                        <Skeleton variant="text" width="100%" height={50} />
                      ) : (
                        <Card sx={{ borderRadius: 5 }}>
                          <Grid item xs={12} justifyContent={"start"}>
                            <Typography
                              align="left"
                              color="#FFFFFF"
                              paddingLeft="20px"
                              sx={{ backgroundColor: "#007ae0" }}
                              variant="h6"
                            >
                              {i18n(`${i18nDashboard}.TitleAccountManager`)}
                            </Typography>
                          </Grid>
                        </Card>
                      )}
                      {isLoading ? (
                        <Skeleton height={120} variant="rectangular" />
                      ) : (
                        <TableContainer sx={{ marginTop: "15px" }}>
                          <Table
                            size="small"
                            aria-label="simple table"
                            sx={{
                              border: 0,
                            }}
                          >
                            <TableHead
                              sx={{
                                backgroundColor: "#424343",
                              }}
                            >
                              <TableRow
                                sx={{
                                  "& th": {
                                    color: "#FFFFFF",
                                  },
                                }}
                              >
                                <TableCell colSpan={2} align="center">
                                  {i18n(`${i18nDashboard}.AccountManager`)}
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody
                              sx={{
                                "& td": {
                                  border: "1px solid #d6dce1",
                                },
                              }}
                            >
                              {myPage.lstAccountManagerMyPage != null &&
                                myPage.lstAccountManagerMyPage.length > 0 ? (
                                myPage.lstAccountManagerMyPage.map((row, index) => (
                                  <>
                                    <TableRow>
                                      <TableCell colSpan={2} align="left">
                                        <Typography fontSize={16} style={{ fontWeight: 600 }}>
                                          {row.sName}
                                        </Typography>
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell align="left">
                                        {i18n(`${i18nDashboard}.AccountManagerTelTable`)}
                                      </TableCell>
                                      <TableCell align="center">{row.sTel}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell align="left">
                                        {i18n(`${i18nDashboard}.AccountManagerEmailTable`)}
                                      </TableCell>
                                      <TableCell align="center">{row.sEmail}</TableCell>
                                    </TableRow>
                                  </>
                                ))
                              ) : (
                                <TableRow>
                                  <TableCell align="center" colSpan={2}>
                                    {i18n(`${i18nDashboard}.AccountManagerNodataTable`)}
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      )}
                    </Grid>
                    {/* <Grid item md={6} xs={12}>
                    {isLoading ? (
                      <Skeleton variant="text" width="100%" height={50} />
                    ) : (
                      null
                    )}
                    {isLoading ? (
                      <Skeleton height={120} variant="rectangular" />
                    ) : (
                      null
                    )}
                  </Grid> */}
                  </>
                ) : isMember_Search ?
                  (
                    <>
                      <Grid item xs={12}>
                        {isLoading ? (
                          <Skeleton variant="text" width="100%" height={50} />
                        ) : (
                          <Card sx={{ borderRadius: 5 }}>
                            <Grid item xs={12} justifyContent={"start"}>
                              <Typography
                                align="left"
                                color="#FFFFFF"
                                paddingLeft="20px"
                                sx={{ backgroundColor: "#007ae0" }}
                                variant="h6"
                              >
                                {i18n(`${i18nDashboard}.TitleAccountManager`)}
                              </Typography>
                            </Grid>
                          </Card>
                        )}
                        {isLoading ? (
                          <Skeleton height={120} variant="rectangular" />
                        ) : (
                          <TableContainer sx={{ marginTop: "15px" }}>
                            <Table
                              size="small"
                              aria-label="simple table"
                              sx={{
                                border: 0,
                              }}
                            >
                              <TableHead
                                sx={{
                                  backgroundColor: "#424343",
                                }}
                              >
                                <TableRow
                                  sx={{
                                    "& th": {
                                      color: "#FFFFFF",
                                    },
                                  }}
                                >
                                  <TableCell colSpan={2} align="center">
                                    {i18n(`${i18nDashboard}.AccountManager`)}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody
                                sx={{
                                  "& td": {
                                    border: "1px solid #d6dce1",
                                  },
                                }}
                              >
                                {myPage.lstAccountManagerMyPage != null &&
                                  myPage.lstAccountManagerMyPage.length > 0 ? (
                                  myPage.lstAccountManagerMyPage.map((row, index) => (
                                    <>
                                      <TableRow>
                                        <TableCell colSpan={2} align="left">
                                          <Typography fontSize={16} style={{ fontWeight: 600 }}>
                                            {row.sName}
                                          </Typography>
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell align="left">
                                          {i18n(`${i18nDashboard}.AccountManagerTelTable`)}
                                        </TableCell>
                                        <TableCell align="center">{row.sTel}</TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell align="left">
                                          {i18n(`${i18nDashboard}.AccountManagerEmailTable`)}
                                        </TableCell>
                                        <TableCell align="center">{row.sEmail}</TableCell>
                                      </TableRow>
                                    </>
                                  ))
                                ) : (
                                  <TableRow>
                                    <TableCell align="center" colSpan={2}>
                                      {i18n(`${i18nDashboard}.AccountManagerNodataTable`)}
                                    </TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        )}
                      </Grid>
                      {/* <Grid item md={6} xs={12}>
                      {isLoading ? (
                        <Skeleton variant="text" width="100%" height={50} />
                      ) : (
                        null
                      )}
                      {isLoading ? (
                        <Skeleton height={120} variant="rectangular" />
                      ) : (
                        null
                      )}
                    </Grid> */}
                    </>
                  ) : (
                    <>
                      <Grid item xs={12}>
                        {isLoading ? (
                          <Skeleton variant="text" width="100%" height={50} />
                        ) : (
                          <Card sx={{ borderRadius: 5 }}>
                            <Typography
                              align="center"
                              color="#FFFFFF"
                              variant="h6"
                              sx={{ backgroundColor: "#262346" }}
                            >
                              {i18n(`${i18nDashboard}.Dashboard`)}
                            </Typography>
                          </Card>
                        )}
                      </Grid>
                      <Grid item md={6} xs={12}>
                        {isLoading ? (
                          <Skeleton variant="text" width="100%" height={50} />
                        ) : (
                          <Card sx={{ borderRadius: 5 }}>
                            <Grid container>
                              <Grid item xs={10} justifyContent={"start"}>
                                <Typography
                                  align="left"
                                  color="#007ae0"
                                  paddingLeft="20px"
                                  variant="h6"
                                >
                                  {i18n(`${i18nDashboard}.JobPost`)}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={2}
                                justifyContent={"end"}
                                onClick={() => {
                                  navigate("/JobPostList");
                                }}
                                className="cursor-pointer"
                              >
                                <Typography
                                  // align="right"
                                  align="center"
                                  color="#007ae0"
                                  variant="h6"
                                  paddingRight="20px"
                                >
                                  {i18n(`${i18nDashboard}.JobPostAll`)}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Card>
                        )}
                        {isLoading ? (
                          <Skeleton height={120} variant="rectangular" />
                        ) : (
                          <TableContainer sx={{ marginTop: "15px" }}>
                            <Table
                              size="small"
                              aria-label="simple table"
                              sx={{
                                border: 0,
                              }}
                            >
                              <TableHead
                                sx={{
                                  backgroundColor: "#007ae0",
                                }}
                              >
                                <TableRow
                                  sx={{
                                    "& th": {
                                      color: "#FFFFFF",
                                    },
                                  }}
                                >
                                  <TableCell align="center">
                                    {i18n(`${i18nDashboard}.JobPostTable`)}
                                  </TableCell>
                                  <TableCell align="center">
                                    {i18n(`${i18nDashboard}.JobPostExpiredTable`)}
                                  </TableCell>
                                  <TableCell align="center">
                                    {i18n(`${i18nDashboard}.JobPostJobSeekerTable`)}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody
                                sx={{
                                  "& td": {
                                    border: "1px solid #d6dce1",
                                  },
                                }}
                              >
                                {myPage.lstJobPostMyPage != null &&
                                  myPage.lstJobPostMyPage.length > 0 ? (
                                  myPage.lstJobPostMyPage.map((row) => (
                                    <TableRow>
                                      <TableCell align="left" sx={{ width: "50%" }}>
                                        {lang == "th" ? row.sName_TH : row.sName_EN}
                                      </TableCell>
                                      <TableCell align="center" sx={{ width: "10%" }}>{row.sExpired}</TableCell>
                                      <TableCell align="center" sx={{ width: "40%", whiteSpace: "pre" }}>
                                        {row.nJobSeeker}<br />({row.nJobSeeker_UnRead} unread)
                                      </TableCell>
                                    </TableRow>
                                  ))
                                ) : (
                                  <TableRow>
                                    <TableCell align="center" colSpan={3}>
                                      {i18n(`${i18nDashboard}.JobPostNodataTable`)}
                                    </TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        )}
                      </Grid>
                      <Grid item md={6} xs={12}>
                        {isLoading ? (
                          <Skeleton variant="text" width="100%" height={50} />
                        ) : (
                          <Card sx={{ borderRadius: 5 }}>
                            <Grid container>
                              <Grid item xs={10} justifyContent={"start"}>
                                <Typography
                                  align="left"
                                  color="#007ae0"
                                  paddingLeft="20px"
                                  variant="h6"
                                >
                                  {i18n(`${i18nDashboard}.AccountStatus`)}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={2}
                                justifyContent={"end"}
                                onClick={() => {
                                  navigate("/OrderEmployer");
                                }}
                                className="cursor-pointer"
                              >
                                <Typography
                                  align="right"
                                  color="#007ae0"
                                  variant="h6"
                                  paddingRight="20px"
                                >
                                  {i18n(`${i18nDashboard}.AccountStatusAll`)}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Card>
                        )}
                        {isLoading ? (
                          <Skeleton height={120} variant="rectangular" />
                        ) : (
                          <TableContainer sx={{ marginTop: "15px" }}>
                            <Table
                              size="small"
                              aria-label="simple table"
                              sx={{
                                border: 0,
                              }}
                            >
                              <TableHead
                                sx={{
                                  backgroundColor: "#007ae0",
                                }}
                              >
                                <TableRow
                                  sx={{
                                    "& th": {
                                      color: "#FFFFFF",
                                    },
                                  }}
                                >
                                  <TableCell align="center">
                                    {i18n(`${i18nDashboard}.AccountStatusPackageTable`)}
                                  </TableCell>
                                  <TableCell align="center">
                                    {i18n(`${i18nDashboard}.AccountStatusExpiredTable`)}
                                  </TableCell>
                                  <TableCell align="center">
                                    {i18n(`${i18nDashboard}.AccountStatusJobTable`)}
                                  </TableCell>
                                  <TableCell align="center">
                                    {i18n(`${i18nDashboard}.AccountStatusCoinsTable`)}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody
                                sx={{
                                  "& td": {
                                    border: "1px solid #d6dce1",
                                  },
                                }}
                              >
                                {myPage.lstPackageMyPage != null &&
                                  myPage.lstPackageMyPage.length > 0 ? (
                                  myPage.lstPackageMyPage.map((row, index) => (
                                    <>
                                      <TableRow>
                                        <TableCell align="left">{row.sName}</TableCell>
                                        <TableCell align="center">{row.sExpired}</TableCell>
                                        <TableCell align="center">{row.nJob}</TableCell>
                                        <TableCell align="center">{row.nCoin}</TableCell>
                                      </TableRow>
                                      {index === myPage.lstPackageMyPage.length - 1 ? (
                                        <TableRow>
                                          <TableCell align="right" colSpan={2}>
                                            {i18n(`${i18nDashboard}.AccountStatusTotalTable`)}
                                          </TableCell>
                                          <TableCell align="center">
                                            {myPage.nSumtotalJob}
                                          </TableCell>
                                          <TableCell align="center">
                                            {myPage.nSumtotalCoin}
                                          </TableCell>
                                        </TableRow>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  ))
                                ) : (
                                  <TableRow>
                                    <TableCell align="center" colSpan={4}>
                                      {i18n(`${i18nDashboard}.AccountStatusNodataTable`)}
                                    </TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        )}
                      </Grid>
                      <Grid item md={6} xs={12}>
                        {isLoading ? (
                          <Skeleton variant="text" width="100%" height={50} />
                        ) : (
                          <Card sx={{ borderRadius: 5 }}>
                            <Grid container>
                              <Grid item xs={12} justifyContent={"start"}>
                                <Typography
                                  align="left"
                                  color="#007ae0"
                                  paddingLeft="20px"
                                  variant="h6"
                                >
                                  {i18n(`${i18nDashboard}.Banner`)}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Card>
                        )}
                        {isLoading ? (
                          <Skeleton height={120} variant="rectangular" />
                        ) : (
                          <TableContainer sx={{ marginTop: "15px" }}>
                            <Table
                              size="small"
                              aria-label="simple table"
                              sx={{
                                border: 0,
                              }}
                            >
                              <TableHead
                                sx={{
                                  backgroundColor: "#007ae0",
                                }}
                              >
                                <TableRow
                                  sx={{
                                    "& th": {
                                      color: "#FFFFFF",
                                    },
                                  }}
                                >
                                  <TableCell align="center">
                                    {i18n(`${i18nDashboard}.BannerTable`)}
                                  </TableCell>
                                  <TableCell align="center">
                                    {i18n(`${i18nDashboard}.BannerExpiredTable`)}
                                  </TableCell>
                                  <TableCell align="center">
                                    {i18n(`${i18nDashboard}.BannerLinkTable`)}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody
                                sx={{
                                  "& td": {
                                    border: "1px solid #d6dce1",
                                  },
                                }}
                              >
                                {myPage.lstBannerMyPage != null &&
                                  myPage.lstBannerMyPage.length > 0 ? (
                                  myPage.lstBannerMyPage.map((row) => (
                                    <TableRow>
                                      <TableCell align="left">{row.sName}</TableCell>
                                      <TableCell align="center">{row.sExpired}</TableCell>
                                      <TableCell align="left">{row.sLink}</TableCell>
                                    </TableRow>
                                  ))
                                ) : (
                                  <TableRow>
                                    <TableCell align="center" colSpan={3}>
                                      {i18n(`${i18nDashboard}.BannerNodataTable`)}
                                    </TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        )}
                      </Grid>
                      <Grid item md={6} xs={12}>
                        {isLoading ? (
                          <Skeleton variant="text" width="100%" height={50} />
                        ) : (
                          <Card sx={{ borderRadius: 5 }}>
                            <Grid item xs={12} justifyContent={"start"}>
                              <Typography
                                align="left"
                                color="#FFFFFF"
                                paddingLeft="20px"
                                sx={{ backgroundColor: "#007ae0" }}
                                variant="h6"
                              >
                                {i18n(`${i18nDashboard}.TitleAccountManager`)}
                              </Typography>
                            </Grid>
                          </Card>
                        )}
                        {isLoading ? (
                          <Skeleton height={120} variant="rectangular" />
                        ) : (
                          <TableContainer sx={{ marginTop: "15px" }}>
                            <Table
                              size="small"
                              aria-label="simple table"
                              sx={{
                                border: 0,
                              }}
                            >
                              <TableHead
                                sx={{
                                  backgroundColor: "#424343",
                                }}
                              >
                                <TableRow
                                  sx={{
                                    "& th": {
                                      color: "#FFFFFF",
                                    },
                                  }}
                                >
                                  <TableCell colSpan={2} align="center">
                                    {i18n(`${i18nDashboard}.AccountManager`)}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody
                                sx={{
                                  "& td": {
                                    border: "1px solid #d6dce1",
                                  },
                                }}
                              >
                                {myPage.lstAccountManagerMyPage != null &&
                                  myPage.lstAccountManagerMyPage.length > 0 ? (
                                  myPage.lstAccountManagerMyPage.map((row, index) => (
                                    <>
                                      <TableRow>
                                        <TableCell colSpan={2} align="left">
                                          <Typography fontSize={16} style={{ fontWeight: 600 }}>
                                            {row.sName}
                                          </Typography>
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell align="left">
                                          {i18n(`${i18nDashboard}.AccountManagerTelTable`)}
                                        </TableCell>
                                        <TableCell align="center">{row.sTel}</TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell align="left">
                                          {i18n(`${i18nDashboard}.AccountManagerEmailTable`)}
                                        </TableCell>
                                        <TableCell align="center">{row.sEmail}</TableCell>
                                      </TableRow>
                                    </>
                                  ))
                                ) : (
                                  <TableRow>
                                    <TableCell align="center" colSpan={2}>
                                      {i18n(`${i18nDashboard}.AccountManagerNodataTable`)}
                                    </TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        )}
                      </Grid>
                    </>
                  )
            }
          </Grid>
        </Card>
      </Hidden>
    </>
  );
};

export default MyPageEmployer;