const HomeEn = {
  // title1: "FIND THE RIGHT JOB",
  // title2: "AND A LARGE OF PEOPLE APPLYING FOR JOBS",
  title1: "YES! FIND THE RIGHT JOBS",
  title2: "AND RIGHT PEOPLE HERE",
  what: "What",
  placeholderWhat: "Position, Keyword, Company",
  where: "Where",
  placeholderWhere: "Locations",
  placeholderWhereALL: "All Locations",
  function: "Job Types",
  functionALL: "All Job Types",
  btnFindJob: "Search",
  lastedJob: "Lasted Jobs",
  popPosition: "Popular Positions",
  viewAll: "View All",
  leadingCompany: "Leading Companies",
  btnViewCompany: "View Company Detail",
  viewJobDetail: "View Job Detail",
  boxResume:
    // "If you are looking for a job<br />that suit you We have a company ready to accept you<br />go to work a lot.",
    "If you are looking for jobs<br />We have several companies ready for your joining.",
  btnResume: "Deposit Your Detail",
  boxAddPosition:
    // "If you are looking for employees<br />that is suitable for your company or agency<br />We are ready to be a medium to connect<br />unemployed people.",
    " If you want to find people ready for your companies<br />Please scout them here!",
  btnAddPosition: "Add job vacancies",
  boxSeekerTitle: "ARE YOU A JOB SEEKER?",
  boxSeekerDesc:
    "HAVE A CHAT WITH EXHIBITORS, INFORM POTENTIAL EMPLOYEES ABOUT EVENTS<br />YOU ARE TAKING PART IN, OR ARRANGE A JOB INTERVIEW.",
  boxEmployerTitle: "ARE YOU AN EMPLOYER?",
  boxEmployerDesc:
    "BECOME AN EXHIBITOR AT ONE OF THE EUROPEAN JOB DAYS EVENTS.",
  btnRegister: "Free Registration",
  selectAll: "All",
  searchButt: "Search",
  LoginModalWord: "Please Login First",
  postdateCount: " day ago",

  //Footer
  callUs: "Call Us",
  address:
    "BUSINESS PROFESSIONAL SOLUTIONS RECRUITMENT Co.,Ltd<br /><br />555/1 Energy Complex, Building A, <br /> 10th Floor, Vibhavadi Rangsit Road, Chatuchak, Bangkok,<br />10900 Thailand",
  guest: "Guest",
  jobSeeker: "Job Seeker",
  employer: "Employer",
  mainPage: "Main Page",
  phone: "02-140-3158-59 or 02-140-3191-92",
  selectJob: "Select a job to view details",
};
const HomeTh = {
  title1: "ค้นหางานที่ถูกใจ",
  title2: "และผู้คนที่เปิดรับสมัครงานจำนวนมาก",
  what: "ค้นหาจากคำสำคัญ",
  placeholderWhat: "ชื่อตำแหน่ง, คำค้น, ชื่อบริษัท",
  where: "ที่ไหน",
  placeholderWhere: "พื้นที่ปฏิบัติงาน ",
  placeholderWhereALL: "พื้นที่ปฏิบัติงาน ทั้งหมด",
  function: "ประเภทงาน",
  functionALL: "ประเภทงาน ทั้งหมด",
  btnFindJob: "ค้นหา",
  lastedJob: "งานล่าสุด",
  popPosition: "ตำแหน่งงานยอดนิยม",
  viewAll: "ดูทั้งหมด",
  leadingCompany: "บริษัทชั้นนำ",
  btnViewCompany: "ดูข้อมูลบริษัท",
  viewJobDetail: "ดูรายละเอียดงาน",
  boxResume:
    "หากคุณกำลังมองหาตำแหน่งงาน<br />ที่เหมาะกับคุณ เรามีบริษัทที่พร้อมรับคุณ<br />เข้าทำงานมากมาย",
  btnResume: "ฝากประวัติของคุณ",
  boxAddPosition:
    "หากคุณกำลังมองหาพนักงาน<br />ที่เหมาะกับบริษัทหรือหน่วยงานของคุณ<br />เราพร้อมเป็นสื่อกลางในการเชื่อมโยง<br />ผู้คนที่กำลังว่างงาน",
  btnAddPosition: "เพิ่มตำแหน่งงานว่าง",
  boxSeekerTitle: "คุณเป็นผู้หางานหรือไม่?",
  boxSeekerDesc:
    "พูดคุยกับผู้รับสมัครงาน แจ้งศักยภาพที่มีเกี่ยวกับงาน<br />คุณกำลังเข้าร่วมหรือจัดให้มีการสัมภาษณ์งาน",
  boxEmployerTitle: "คุณเป็นนายจ้างหรือไม่?",
  boxEmployerDesc: "ร่วมเป็นผู้จัดหางาน ในงาน EUROPEAN JOB DAYS",
  btnRegister: "สมัครสมาชิกฟรี",
  selectAll: "ทั้งหมด",
  searchButt: "ค้นหา",
  LoginModalWord: "กรุณาเข้าสู่ระบบ",
  postdateCount: " วันที่แล้ว",

  //Footer
  callUs: "ติดต่อเรา",
  address:
    "บริษัท จัดหางาน บิซิเนส โปรเฟสชั่นแนล โซลูชั่นส์ จำกัด <br /><br />555/1 ศูนย์เอนเนอร์ยี่คอมเพล็กซ์ อาคาร A<br /> ชั้น 10, ถนนวิภาวดีรังสิต, จตุจักร, กรุงเทพ,<br />  10900 ประเทศไทย",
  guest: "ผู้ใช้งานทั่วไป",
  jobSeeker: "สมาชิกเว็บไซต์",
  employer: "ผู้ประกอบการ",
  mainPage: "หน้าหลัก",
  phone: "02-140-3158-59 หรือ 02-140-3191-92",
  selectJob: "เลือกตำแหน่งงานเพื่อดูรายละเอียด",
};

export { HomeEn, HomeTh };
