import React, { useEffect, useState } from "react";
import { Box, Grid, Stack } from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { AxiosGet } from "service/CommonFunction/TS_function";
import { useLocation, useNavigate } from "react-router-dom";
import { FnBlock_UI } from "service/SystemFunction/SystemFunction";

const Cardshow = () => {
    const { BlockUI, UnBlockUI } = FnBlock_UI();
    const [arrCard, setarrCard] = useState([]);
    const history = useNavigate();
    const [arrPosition, setarrPosition] = useState([]);
    const location = useLocation();
    const searchParams = location.search;
    const sID = new URLSearchParams(searchParams).get('sID');
    const sPositionID = new URLSearchParams(searchParams).get('sPositionID');
    const [value, setValue] = useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    interface TabPanelProps {
        children?: React.ReactNode;
        index: number;
        value: number;
    }

    const TabPanel = (props: TabPanelProps) => {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }
    const TabsClick = (index: number) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }
    useEffect(() => {
        OnGetData();
        GetData();
    }, []);


    const OnGetData = () => {
        BlockUI();
        AxiosGet("FromBanner/GetData_DropDown", {}, (result) => {
            UnBlockUI();
            setarrPosition(result.lstOptionPosition || []);
        },
            (err) => {
                if (!err.response) {
                    history("/error", { replace: true })
                    return;
                }
            });
    }
    const GetData = () => {
        BlockUI();
        AxiosGet("FromBanner/GetData_Show", {}, (result) => {
            UnBlockUI();
            setarrCard(result.lstShow || []);
        });
    }

    return (
        <Stack spacing={2} width={"100%"} sx={{ marginTop: "2%", border: "2px #eeeeee solid", backgroundColor: "rgba(255,255,255,0.75)", borderRadius: 5, p: 3 }}>
            <Grid item sx={{ p: 1, borderRadius: 5, boxShadow: 3, bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff') }}>
                {arrCard.map((item, index) => (
                    <Grid container>
                        <Grid item xs={12} lg={12} >
                            <CardMedia component="img" sx={{ width: "50%", height: "50%" }}
                                image={item.sURL}
                            />
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </Stack >
    );
}
export default Cardshow

