import { useState, useEffect, useContext } from "react";
import { FormProvider } from "react-hook-form";
import moment from "moment";
import {
  Grid,
  Typography,
  Box,
  FormControlLabel,
  Checkbox,
  Paper,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { i18n } from "i18n";
import DatePickerFormItem from "components/Common/ElementInForm/DatePickerFormItem";
import Input from "components/Common/ElementInForm/InputCustomLabelFormItem";
import AutoCompleteSelect from "components/Common/ElementInForm/AutoCompleteSelect";
import DateRangePicker from "components/Common/ElementInForm/DateRange";
import { ProfileContext } from "./Context/ProfileContext";
import { IPorpsWorkUnit } from "./Interface";
import { DialogActionCreators } from "store/redux/DialogAlert";
import { useDispatch } from "react-redux";
import { AxiosGet } from "service/CommonFunction/TS_function";
import yupFormSchemas from "components/Common/yup/yupFormSchemas";
import InputNumber from "components/Common/ElementInForm/InputNumber";
import secureLocalStorage from "react-secure-storage";

const i18nField = "entities.SeekerProfile";
const i18nCommon = "common";
const WorkExperience = (props: IPorpsWorkUnit) => {
  const { item, index, form, schemaWork, setSchemaWork } = props;
  const dispatch = useDispatch();
  const {
    arrWorkExperienceContext,
    setWorkExperience,
    optionPosition,
    optionCompany,
    optionJobFunction,
    optionJobIndustry,
    optionJobDetail,
    setOptionJobDeatail,
  } = useContext(ProfileContext);

  console.log("optionJobDetail", optionJobDetail);

  const [isClean, setIsClean] = useState(false);
  const [isCurrent, setIsCureant] = useState(item.IsCurrent);

  const [minDate, setMinDateExpire] = useState(null);
  const [rawJobDetail, setRawJobDetail] = useState([]);
  const [Parent, setParent] = useState(0);
  const lang = secureLocalStorage.getItem("language").toString() || "th";
  const fetchJobDetail = () => {
    AxiosGet("DataCollect/GetJobDetail", {}, (res) => {
      setRawJobDetail(res.Data);
    });
  };

  const handleSetData = (itemIndex) => {
    arrWorkExperienceContext.forEach((data) => {
      if (data.Index === itemIndex) {
        form.setValue("Position" + data.Index, data.Position || "");
        form.setValue("Company" + data.Index, data.Company || "");
        form.setValue("StartDate" + data.Index, data.StartDate || "");
        form.setValue("EndDate" + data.Index, data.EndDate || "");
        form.setValue("checkCurrent" + data.Index, data.IsCurrent);
        form.setValue("JobFunc" + data.Index, data.JobFunc || "");
        form.setValue("JobDetail" + data.Index, data.JobDetail || "");
        form.setValue("JobIndustry" + data.Index, data.JobIndustry || "");
        form.setValue("WorkRemark" + data.Index, data.WorkRemark || "");
        form.setValue("WorkSalary" + data.Index, data.WorkSalary || "");
        form.setValue("LeaveRemark" + data.Index, data.LeaveRemark || "");
   
        if (data.JobFunc != null) {
          let ObjData: JobFuncParent = {};
          ObjData = data.JobFunc
          let ObjProblem = {
            nParent: ObjData.value,
          };
          setParent(ObjProblem.nParent)
        }
      }
    });
    setIsClean(false);
  };

  const handleChangeValue = (form, index) => {
    let newData = arrWorkExperienceContext.map((item) => {
      if (item.Index === index) {
        item.Position = form.getValues("Position" + index);
        item.Company = form.getValues("Company" + index);
        item.StartDate = form.getValues("StartDate" + index);
        item.EndDate = form.getValues("EndDate" + index);
        item.IsCurrent = form.getValues("checkCurrent" + index) || false;
        item.JobFunc = form.getValues("JobFunc" + index);
        item.JobDetail = form.getValues("JobDetail" + index);
        item.JobIndustry = form.getValues("JobIndustry" + index);
        item.WorkRemark = form.getValues("WorkRemark" + item.Index);
        item.WorkSalary = form.getValues("WorkSalary" + item.Index);
        item.LeaveRemark = form.getValues("LeaveRemark" + item.Index);
      }
      return item;
    });

    setWorkExperience(newData);
  };

  const handleRemoveWorkUnit = (itemIndex: number) => {
    dispatch(
      DialogActionCreators.OpenDialogSubmit(
        i18n(`${i18nCommon}.AlertConfrimDel`),
        () => {
          dispatch(DialogActionCreators.CloseDialogSubmit() as any);
          dispatch(DialogActionCreators.LoadSubmit("Waiting...") as any);
          setIsClean(true);
          let newData = arrWorkExperienceContext.filter((data) => {
            return data.Index !== itemIndex;
          });
          setWorkExperience(newData);

          form.unregister("Position" + itemIndex);
          form.unregister("Company" + itemIndex);
          form.unregister("StartDate" + itemIndex);
          form.unregister("EndDate" + itemIndex);
          form.unregister("checkCurrent" + itemIndex);
          form.unregister("JobFunc" + itemIndex);
          form.unregister("JobDetail" + itemIndex);
          form.unregister("JobIndustry" + itemIndex);
          form.unregister("WorkRemark" + itemIndex);
          form.unregister("WorkSalary" + itemIndex);
          form.unregister("LeaveRemark" + itemIndex);
        }
      )
    );
  };

  useEffect(() => {
    handleSetData(item.Index);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClean]);

  useEffect(() => {
    const fetchData = async () => {
      await fetchJobDetail();
    };
    fetchData();
  }, []);

  return isClean ? null : (
    <FormProvider {...form} key={index}>
      <Box component={Paper} sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}>
        {index === 0 ? null : (
          <IconButton
            aria-label="delete"
            color="error"
            onClick={() => handleRemoveWorkUnit(item.Index)}
          >
            <CloseIcon />
          </IconButton>
        )}
        <Grid container justifyContent="center" alignItems="center" spacing={1}>
          <Grid item container spacing={2} xs={12}>
            <Grid item xs={12} sm={6} md={6}>
              <AutoCompleteSelect
                name={"Position" + item.Index}
                fullWidth
                label={i18n(`${i18nField}.Position`)}
                options={optionPosition}
                onChange={(value) => {
                  handleChangeValue(form, item.Index);
                }}
                required
                freeSolo
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <AutoCompleteSelect
                name={"Company" + item.Index}
                fullWidth
                label={i18n(`${i18nField}.Company`)}
                options={optionCompany}
                required
                freeSolo
                onChange={(value) => {
                  handleChangeValue(form, item.Index);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <DatePickerFormItem
                name={"StartDate" + item.Index}
                label={
                  <Typography sx={{ fontWeight: 400 }} component="label">
                    {i18n(`${i18nField}.StartDate`)}
                  </Typography>
                }
                small={true}
                onChange={(value) => {
                  let dEnd = form.getValues("EndDate" + item.Index);
                  if (dEnd) {
                    let nDiffDate = moment(dEnd).diff(value, "days");
                    if (nDiffDate < 0) {
                      form.setValue("EndDate" + item.Index, value);
                    }
                  }
                  handleChangeValue(form, item.Index);
                  setMinDateExpire(() => value);
                }}
                required
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <DatePickerFormItem
                name={"EndDate" + item.Index}
                minDate={minDate}
                label={i18n(`${i18nField}.EndDate`)}
                small={true}
                onChange={(value) => {
                  handleChangeValue(form, item.Index);
                }}
                required={!isCurrent}
                disabled={isCurrent}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    name={"checkCurrent" + item.Index}
                    checked={isCurrent}
                    onChange={(e, value) => {
                      if (value) {
                        form.setValue("EndDate" + item.Index, null);
                      }
                      schemaWork[`EndDate${item.Index}`] = yupFormSchemas.datetime(
                        i18n(`${i18nField}.EndDate`),
                        { required: !value }
                      );
                      setSchemaWork({ ...schemaWork })
                      setIsCureant((prev) => !prev);
                      form.setValue("checkCurrent" + item.Index, value);
                      handleChangeValue(form, item.Index);
                    }}
                  />
                }
                label={i18n(`${i18nField}.ToCurrent`)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <AutoCompleteSelect
                name={"JobFunc" + item.Index}
                label={i18n(`${i18nField}.JobFunction`)}
                onChange={(value) => {
                  handleChangeValue(form, item.Index);
                  setParent(value ? value.value : 0);
                  const newJobDetail = rawJobDetail.filter((item) => {
                    return item.nHeader_ID === value.value;
                  });
                  const option = (newJobDetail || []).map((item) => {
                    return {
                      label:
                        lang === "th"
                          ? item.sData_Name_TH
                          : item.sData_Name_ENG,
                      value: item.nData_ID,
                    };
                  });
                  setOptionJobDeatail(option);
                }}
                options={optionJobFunction}
                required
              />

            </Grid>
            <Grid item xs={12} md={6}>
              <AutoCompleteSelect
                name={"JobDetail" + item.Index}
                label={i18n(`${i18nField}.JobDetail`)}
                onChange={(value) => {
                  handleChangeValue(form, item.Index);
                }}
                // options={optionJobDetail}
                options={optionJobDetail.filter((f) => f.sParent === Parent)}
                required
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <AutoCompleteSelect
                name={"JobIndustry" + item.Index}
                label={i18n(`${i18nField}.JobIndustry`)}
                onChange={(value) => {
                  handleChangeValue(form, item.Index);
                }}
                options={optionJobIndustry}
                required
              />
            </Grid>
            <Grid item md={4}>
              <InputNumber
                // disabled={nPermission !== 2}
                name={`WorkSalary${item.Index}`}
                required
                label={
                  <Typography component="label">{i18n(`${i18nField}.WorkSalary`)}</Typography>
                }
                onBlur={(value) => {
                  handleChangeValue(form, item.Index);
                }}
                labelString={""}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                name={"WorkRemark" + item.Index}
                fullWidth
                multiline
                label={
                  <Typography sx={{ fontWeight: 400 }} component="label">
                    {i18n(`${i18nField}.WorkRemark`)}
                  </Typography>
                }
                rows={4}
                maxLength={3000}
                required
                onBlur={(value) => {
                  handleChangeValue(form, item.Index);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                name={"LeaveRemark" + item.Index}
                fullWidth
                multiline
                label={
                  <Typography sx={{ fontWeight: 400 }} component="label">
                    {i18n(`${i18nField}.LeaveRemark`)}
                  </Typography>
                }
                rows={4}
                maxLength={3000}
                onBlur={(value) => {
                  handleChangeValue(form, item.Index);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </FormProvider>
  );
};

export default WorkExperience;
export interface JobFuncParent {
  value?: number;

}
