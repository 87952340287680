import Layout_Font from "layout/CommonLayout/Layout_Font";
import JobPostList from "view/font/JobPost/JobPostList";
import JobPostModify from "view/font/JobPost/JobPostModify";

const JobPostRoute = [
  {
    path: "/JobPostList",
    component: JobPostList,
    layout: Layout_Font,
    exact: true
  },
  {
    path: "/JobPostModify",
    component: JobPostModify,
    layout: Layout_Font,
    exact: true,
    propsData: {
      imgBGType: 5
    }
  },
  {
    path: "/JobPostModify/:sID",
    component: JobPostModify,
    layout: Layout_Font,
    exact: true,
    propsData: {
      imgBGType: 5
    }
  },
];

export default JobPostRoute;