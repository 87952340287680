import {
  DataGridMui as Table,
  initRows,
  PaginationInterface,
  FilterFieldInterface,
} from "components/Common/Table/DataGridMui";
import { GridColumns } from "@mui/x-data-grid";
import { BtnAddOnTable, BtnEditOnTable, BtnViewOnTable } from "components/Common/Button";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  AxiosPost, GetPermission,
} from "service/CommonFunction/TS_function";
import { DialogActionCreators } from "store/redux/DialogAlert";
import { Grid, Tooltip } from "@mui/material";
let sController = "AdminOrder";
let sSearch = sController + "/SearchDataFreePackage";
let sDetail = "AdminFreePackageDetail";

export default function AdminOrder() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loadingTable, setLoadingTable] = useState(true);
  const [arrEmployer_ID, setarrEmployer_ID] = useState([]);
  const [arrPackage_ID, setarrPackage_ID] = useState([]);
  const [arrStatus_ID, setarrStatus_ID] = useState([]);
  const [nPermission, setPermission] = useState(0);
  const [dataRow, setDataRow] = useState<PaginationInterface>({
    ...initRows,
    sSortExpression: "sDate_Buy",
    sSortDirection: "desc",
  });
  const filter: FilterFieldInterface[] = [
    { sTypeFilterMode: "input", sFieldName: "sEmployer_Name" },
    { sTypeFilterMode: "input", sFieldName: "sPackage_Name" },
    { sTypeFilterMode: "input", sFieldName: "sCreator" },
    { sTypeFilterMode: "daterange", sFieldName: "sDate_Buy" },
    {
      sTypeFilterMode: "select", sFieldName: "sStatus_Name", lstDataSelect:
        [...arrStatus_ID]
    }
  ];

  useEffect(() => {
    GetPermission(35, setPermission);
    loadData(dataRow);
  }, []);

  const loadData = (p: PaginationInterface) => {
    setLoadingTable(true);
    const objFilter = { ...p, nType_ID: 2 };
    AxiosPost(sSearch,
      objFilter,
      (result) => {
        setLoadingTable(false);
        setarrStatus_ID(result.arrStatus);
        setDataRow({
          ...p,
          arrRows: result.arrData,
          nDataLength: result.nDataLength,
          nPageIndex: result.nPageIndex,
        });
      },
      (err) => {
        if (err.response && err.response.status === 401) {
          dispatch(
            DialogActionCreators.OpenDialogWarning("Token expired.", () => {
              navigate("/");
            }) as any
          );
        }
      }
    );
  };

  const onEdit = (sID: string) => {
    // window.localStorage.removeItem("sSubRouteName");
    navigate(`/${sDetail}?sID=${sID}`);
  };

  const dataColumn: GridColumns = [
    {
      renderHeader: (item) => <BtnAddOnTable
        IsDisabled={nPermission !== 2}
        onClick={() => { navigate('/AdminFreePackageDetail') }}
        txt="เพิ่ม"
      />,
      headerName: "ปุ่ม (เพิ่ม/แก้ไข)",
      field: "ปุ่ม (เพิ่ม/แก้ไข)",
      type: "actions",
      width: 70,
      resizable: false,
      sortable: false,
      getActions: (item) => {
        if (item.row.nStatus_ID === 1 || item.row.nStatus_ID === 3) {
          return [
            <BtnEditOnTable
              IsDisabled={nPermission !== 2}
              txt="แก้ไข" //Edit
              onClick={() => onEdit(item.row.sID)}
            />
          ];
        } else {
          return [
            <BtnViewOnTable
              IsDisabled={nPermission !== 2}
              txt="ดู" //Edit
              onClick={() => onEdit(item.row.sID)}
            />,
          ];
        }
      }
    },
    {
      field: "sEmployer_Name",
      headerName: "ชื่อผู้ประกอบการ",
      headerAlign: "center",
      align: "left",
      resizable: false,
      sortable: true,
      flex: 3,
      minWidth: 350,
      renderCell: (item) => {
        return [
          (
            <Tooltip placement="top" title={item.row.sEmployer_Name}>
              <div id={item.row.sID}
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '300px',
                }}>
                {item.row.sEmployer_Name}
              </div>
            </Tooltip>
          )
        ];
      }
    },
    {
      field: "sPackage_Name",
      headerName: "แพ็กเกจ",
      headerAlign: "center",
      align: "left",
      resizable: false,
      sortable: true,
      flex: 3,
      minWidth: 550,
      renderCell: (item) => {
        return [
          (
            <Tooltip placement="top" title={item.row.sPackage_Name}>
              <div id={item.row.sID}
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '300px',
                }}>
                {item.row.sPackage_Name}
              </div>
            </Tooltip>
          )
        ];
      }
    },
    {
      field: "sCreator",
      headerName: "ผู้สร้าง",
      headerAlign: "center",
      align: "left",
      resizable: false,
      sortable: true,
      flex: 2,
      minWidth: 200,
    },
    {
      field: "sDate_Buy",
      headerName: "วันที่ทำรายการ",
      headerAlign: "center",
      align: "center",
      resizable: false,
      sortable: true,
      flex: 1,
      minWidth: 180,
    },
    {
      field: "sStatus_Name",
      headerName: "สถานะ",
      headerAlign: "center",
      align: "left",
      resizable: false,
      sortable: true,
      flex: 1,
      minWidth: 200,
    },
  ];

  return (
    <Grid container>
      <Grid item className="table" sx={{ mx: "2%", width: "100%" }}>
        <Table
          isLoading={loadingTable}
          filterField={filter}
          Rows={dataRow}
          Columns={dataColumn}
          OnLoadData={(p) => loadData(p)}
        />
      </Grid>
    </Grid>
  );
}