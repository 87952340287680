import React, { Fragment, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { makeStyles, useTheme } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ReplayIcon from "@mui/icons-material/Replay";
import ImageIcon from "@mui/icons-material/Image";
import Edit from "@mui/icons-material/Edit";
import Check from "@mui/icons-material/Check";
import FilePopup from "../../FilePopup/FilePopup";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import TextField from "@mui/material/TextField";
import CheckIcon from "@mui/icons-material/Check";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DownloadIcon from "@mui/icons-material/Download";
// import { BsFillFileEarmarkWordFill } from "react-icons/bs";
// import { SiMicrosoftexcel } from "react-icons/si";
// import { SiMicrosoftpowerpoint } from "react-icons/si";
import { Extension } from "../../../../../../service/CommonFunction/TS_function";
import { i18n } from "i18n";

const useStyles = makeStyles((theme: Theme) => ({
  WordColor: {
    color: "#fff !important",
    backgroundColor: "#2372ba !important",
    borderColor: "#2372ba !important",
    margin: theme.spacing(1),
    "&:hover": {
      backgroundColor: "rgb(56, 151, 234) !important",
    },
  },
  ExcelColor: {
    color: "#fff !important",
    backgroundColor: "#14a73c !important",
    borderColor: "#14a73c !important",
    margin: theme.spacing(1),
    "&:hover": {
      backgroundColor: "rgb(66, 201, 127) !important",
    },
  },
  PDFColor: {
    color: "#fff !important",
    backgroundColor: "#ff0000 !important",
    borderColor: "#ff0000 !important",
    margin: theme.spacing(1),
    "&:hover": {
      backgroundColor: "rgb(255, 79, 79) !important",
    },
  },
  PowerPointColor: {
    color: "#fff !important",
    backgroundColor: "#f26522 !important",
    borderColor: "#f26522 !important",
    margin: theme.spacing(1),
    "&:hover": {
      backgroundColor: "rgb(255, 79, 79) !important",
    },
  },

  FileItem: {
    margin: "0 !important",
    padding: "20px 16px 20px 22px !important",
    borderBottom: "1px solid #ebeef1 !important",
    animationDuration: ".6s !important",
  },
  ColumnThumbnail: {
    paddingLeft: "10px",
  },
  ColumnTitle: {
    flex: "1 !important",
    paddingLeft: "16px !important",
    paddingRight: "16px !important",
    color: "#74809d !important",
    overflow: "hidden !important",
    backgroundColor: "#fffff !important",
    cursor: "pointer !important",
  },
  ColumnAction: {
    margin: "0 16px",
  },
  Title: {
    fontWeight: 600,
  },
  DangerColor: {
    color: "#fff !important",
    backgroundColor: "#ed3847 !important",
    borderColor: "#ed3847 !important",
    margin: theme.spacing(1),
    "&:hover": {
      backgroundColor: "rgb(220, 53, 69) !important",
    },
  },
  WarningColor: {
    color: "#fff !important",
    backgroundColor: "#ffce3d !important",
    borderColor: "#ffce3d !important",
    margin: theme.spacing(1),
    "&:hover": {
      backgroundColor: "rgb(252, 189, 0) !important",
    },
  },
  PrimaryColor: {
    color: "#fff !important",
    backgroundColor: "#A0E4FF !important",
    borderColor: "#A0E4FF !important",
    margin: theme.spacing(1),
    "&:hover": {
      backgroundColor: "#64D2FF !important",
    },
  },
  SuccessColor: {
    color: "#fff !important",
    backgroundColor: "#13C516 !important",
    borderColor: "#13C516 !important",
    margin: theme.spacing(1),
    "&:hover": {
      backgroundColor: "#00AC03 !important",
    },
  },

  IConColor: {
    fill: "currentColor",
    width: "1em",
    height: "1em",
    display: "inline-block",
    fontSize: "1.5rem",
    transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
}));
const itemRow = (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const classes = useStyles();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [IsopenPopUp, setIsopenPopUp] = useState<any>(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [Result, setResult] = useState<any>({} as any);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [IsEditText, setIsEditText] = useState<any>(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [sTextName, setTextName] = useState<any>(props.sFileName);

  const ClosePopUp = () => {
    setIsopenPopUp(false);
  };
  const OpenPopUp = () => {
    props.onOpenFile && props.onOpenFile(props.nFile_ID);
    setIsopenPopUp(true);
  };

  const onClickEditText = () => {
    setIsEditText(!IsEditText);
  };

  const onClickConfirmText = () => {
    let cloneArrFile = props.ArrFileRename;
    let objFile = cloneArrFile.filter((f) => f.nFile_ID === props.nFile_ID);
    if (objFile.length > 0) {
      cloneArrFile.filter((f) => f.nFile_ID === props.nFile_ID)[0].sFileName =
        sTextName;
      props.SetarrFileRename([...cloneArrFile]);
    }
    setIsEditText(!IsEditText);
  };

  const onDownload = () => {
    var a = document.createElement("a"); //Create <a>
    a.href = props.sFileLink + ""; //Image Goes here
    a.download = props.sFileName; //File name Here
    a.click(); //Downloaded file
  };

  return (
    <Fragment>
      <Grid
        item
        container
        justifyContent="flex-start"
        alignItems="center"
        spacing={0}
        xs={12}
        className={!props.IsOneLine && classes.FileItem}
      >
        {props.IsComplete && !props.disabled && !props.IsHiddenUploadBox ? (
          <Tooltip title={ i18n(`common.destroy`)}>
            <IconButton
              className={classes.DangerColor}
              size="small"
              onClick={() => {
                props.onDelete(props.nFile_ID);
              }}
            >
              <DeleteForeverIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <></>
        )}
        {props.IsCanRename && (
          <div style={{ paddingLeft: "10px" }}>
            {IsEditText ? (
              <Tooltip title="Confirm">
                <IconButton
                  className={classes.SuccessColor}
                  size="small"
                  onClick={() => onClickConfirmText()}
                >
                  <Check />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Rename">
                <IconButton
                  className={classes.PrimaryColor}
                  size="small"
                  onClick={() => onClickEditText()}
                >
                  <Edit />
                </IconButton>
              </Tooltip>
            )}
          </div>
        )}
        <Grid item className={classes.ColumnThumbnail}>
          {Extension.Image.indexOf(props.sFileType) > -1 ? (
            <Fragment>
              {Result.sUrl ? (
                <img src={Result.sUrl || ""} width={30} height={30} />
              ) : props.sFileLink ? (
                <img src={props.sFileLink || ""} width={30} height={30} />
              ) : (
                <Tooltip title="">
                  <IconButton className={classes.WordColor} size="small">
                    <ImageIcon className={classes.IConColor} />
                  </IconButton>
                </Tooltip>
              )}
            </Fragment>
          ) : Extension.Video.indexOf(props.sFileType) > -1 ? (
            <Tooltip title="">
              <IconButton className={classes.WordColor} size="small">
                <VideoLibraryIcon className={classes.IConColor} />
              </IconButton>
            </Tooltip>
          ) : Extension.PDF.indexOf(props.sFileType) > -1 ? (
            <Tooltip title="">
              <IconButton className={classes.PDFColor} size="small">
                <PictureAsPdfIcon className={classes.IConColor} />
              </IconButton>
            </Tooltip>
          // ) : Extension.Word.indexOf(props.sFileType) > -1 ? (
          //   <Tooltip title="">
          //     <IconButton className={classes.WordColor} size="small">
          //       <BsFillFileEarmarkWordFill className={classes.IConColor} />
          //     </IconButton>
          //   </Tooltip>
          // ) : Extension.Excel.indexOf(props.sFileType) > -1 ? (
          //   <Tooltip title="">
          //     <IconButton className={classes.ExcelColor} size="small">
          //       <SiMicrosoftexcel className={classes.IConColor} />
          //     </IconButton>
          //   </Tooltip>
          // ) : Extension.Powpoint.indexOf(props.sFileType) > -1 ? (
          //   <Tooltip title="">
          //     <IconButton className={classes.PowerPointColor} size="small">
          //       <SiMicrosoftpowerpoint className={classes.IConColor} />
          //     </IconButton>
          //   </Tooltip>
          ) : Extension.txt.indexOf(props.sFileType) > -1 ? (
            <Tooltip title="">
              <IconButton className={classes.WordColor} size="small">
                <DownloadIcon className={classes.IConColor} />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="">
              <IconButton className={classes.WordColor} size="small">
                <DownloadIcon className={classes.IConColor} />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
        <Grid
          item
          xs
          className={classes.ColumnTitle}
          onClick={
            (Extension.Image.indexOf(props.sFileType) > -1 ||
              Extension.Video.indexOf(props.sFileType) > -1 ||
              Extension.PDF.indexOf(props.sFileType) > -1) &&
              !props.IsCanRename
              ? OpenPopUp
              : onDownload
          }
        >
          {!props.IsCanRename ? (
            <Typography className={classes.Title}>{props.sFileName}</Typography>
          ) : (
            <TextField
              style={{ marginBottom: "10px" }}
              id="standard-basic"
              defaultValue={props.sFileName}
              onChange={(e) => setTextName(e.target.value || "")}
              variant="standard"
              disabled={!IsEditText}
              fullWidth
              inputProps={{ maxLength: 30 }}
            />
          )}
        </Grid>
        <Grid
          item
          xs
          container
          justifyContent="flex-end"
          alignItems="center"
          className={classes.ColumnAction}
        >
          {props.IsComplete ? (
            <></>
          ) : props.IsProgress ? null : (
            <Tooltip title="">
              <IconButton
                className={classes.WarningColor}
                size="small"
                onClick={() => {
                  props.onLoad(props.nFile_ID);
                }}
              >
                <ReplayIcon />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
        {!props.IsHiddenUploadBox && (
          <Grid item xs={12}>
            <Box display="flex" alignItems="center">
              <Box width="100%" mr={1}>
                <LinearProgress
                  variant="determinate"
                  value={props.sProgress || 100}
                />
              </Box>
              <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">
                  {props.sProgress
                    ? props.sProgress + "%"
                    : props.IsComplete
                      ? "100%"
                      : ""}
                </Typography>
              </Box>
            </Box>
          </Grid>
        )}
      </Grid>
      {IsopenPopUp ? (
        <FilePopup
          file={props}
          SourceCrop={Result ? Result.SourceCrop : ""}
          open={IsopenPopUp}
          Fn={ClosePopUp}
          Result={setResult}
        />
      ) : (
        ""
      )}
    </Fragment>
  );
};

export default itemRow;
