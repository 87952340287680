import { Box } from "@mui/material";
import MemberRegister from "components/CompanyEmployee/MemberRegister";
import MemberSetting from "components/Member_ProfileSetting/MemberSetting";

const MemberProfileSetting = () => {
  return (
    <>
      <Box sx={{ width: '100%' }}>
        <MemberSetting />
      </Box>
    </>
  )
};

export default MemberProfileSetting;