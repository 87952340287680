const JobDetailEn = {
  button: {
    btnApply: "Apply",
    btnFavourite: "Favourite",
    btnFavourited: "Remove",
    btnPrint: "Print",
    btnShare: "Share",
    btnClipboard: "Copy link",
    btnSendApply: "Send Apply",
  },
  label: {
    jobDescription: "Job Description/Qualification",
    qualifications: "Conclude key point or key word of job ad for job seekers",
    careerLevel: "Career Level",
    yearsExperience: "Years of Experience",
    qualification: "Qualification",
    industry: "Industry",
    salary: "Salary",
    jobType: "Employment Type",
    benefits: "Benefits",
    share: "Share",
    messageShare: "Recommended from your friend: ",
    messageClipboard: "Link copied",
    expired: "Job not found in system.",
    otherInfo: "Other Information"
  },
  common: {
    login: "Please login",
  },
};

const JobDetailTh = {
  button: {
    btnApply: "สมัครงาน",
    btnFavourite: "บันทึกงานที่สนใจ",
    btnFavourited: "ลบงานที่สนใจ",
    btnPrint: "พิมพ์",
    btnShare: "แชร์",
    btnClipboard: "คัดลอกลิงก์",
    btnSendApply: "ส่งใบสมัคร",
  },
  label: {
    jobDescription: "รายละเอียดงาน/คุณสมบัติ",
    qualifications: "ลักษณะเด่นของงานที่ประกาศ",
    careerLevel: "ระดับปฏิบัติงาน",
    yearsExperience: "ประสบการณ์ทำงาน",
    qualification: "ระดับการศึกษา",
    industry: "ประเภทธุรกิจ",
    salary: "เงินเดือน",
    jobType: "ลักษณะการจ้างงาน",
    benefits: "สวัสดิการ",
    share: "แชร์",
    messageShare: "งานแนะนำจากเพื่อนตำแหน่ง ",
    messageClipboard: "คัดลอกแล้ว",
    expired: "ไม่พบตำแหน่งงานนี้ในระบบแล้ว",
    otherInfo: "ข้อมูลอื่นๆ"
  },
  common: {
    login: "กรุณาเข้าสู่ระบบ",
  },
};

export { JobDetailTh, JobDetailEn };
