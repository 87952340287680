import { Grid } from "@mui/material";
import Admin_MasterDataAdd from "components/BPS/BPS_Admin/Admin_MasterDataAdd";
import { useState } from "react";
import { useLocation } from "react-router-dom";

export default function Admin_Form() {
  const [nDataType_ID, setnDataType_ID] = useState(1);
  const location = useLocation();
  const searchParams = location.search;
  const sID = new URLSearchParams(searchParams).get('sID');
  const isEditMode = sID ? true : false;

  return (
    <Grid container>
      <Grid item className="table" sx={{ mx: "2%", width: "100%" }}>
        <Admin_MasterDataAdd/>
      </Grid>
    </Grid>
  );
}