import { Stack } from "@mui/material";
import AdminUserMasterTable from "./AdminUserMasterTable";

export default function FromMasterTable() {
 
  return (
    <Stack sx={{ mx: "1%" }}>
        <AdminUserMasterTable />
    </Stack>
  );
}