import React from "react";
import { useFormContext } from "react-hook-form";
import {
    Autocomplete,
    TextField,
    FormControl,
    InputLabel,
    FormHelperText,
    OutlinedInput,
} from "@mui/material";
import PropTypes, { InferProps } from "prop-types";
import secureLocalStorage from "react-secure-storage";
export default function AutoCompleteSelect({
    name,
    options,
    label,
    size,
    fullWidth,
    disableClearable,
    required,
    disabled,
    shrink,
    sxLabel,
    small,
    freeSolo,
    onChange,
    hidden,
    customValue,
    rounded,
    isOptionTwoLanguage,
    isPagination
}: InferProps<typeof AutoCompleteSelect.propTypes>) {
    const {
        register,
        setValue,
        watch,
        clearErrors,
        formState: { errors },
    } = useFormContext();

    const langCode = secureLocalStorage.getItem("language") || "th";

    return (
        <FormControl hiddenLabel={hidden || false} disabled={disabled} sx={{
            width: "100%",
            "label.MuiInputLabel-shrink": {
                top: "0!important",
            },
            "div.MuiOutlinedInput-root": {
                borderRadius: rounded ? '2em' : '',
            },
            "label.MuiInputLabel-formControl": {
                color: Boolean(errors[name]) ? "red" : ""
            },
            "label.MuiInputLabel-formControl > span": {
                color: "red"
            },
            "div.MuiOutlinedInput-root fieldset.MuiOutlinedInput-notchedOutline": {
                borderColor: Boolean(errors[name]) ? 'red' : '',
            },
        }}>
            <Autocomplete
                hidden={hidden || false}
                renderOption={(props, option: any) => {
                    return (
                        <li {...props} key={option.value}>
                            {isOptionTwoLanguage ? option[`label_${langCode}`] : option.label}
                        </li>
                    );
                }}
                sx={{
                    // backgroundColor: "green",
                }}
                disabled={disabled || null}
                value={
                    (customValue
                        ? options.find((f) => f[customValue.key] === watch(name))
                        : watch(name)) || null
                }
                fullWidth={fullWidth || null}
                disableClearable={disableClearable || null}
                size={size as "small" | "medium"}
                name={name}
                freeSolo={freeSolo || null}
                {...register(name)}
                onChange={(e, v) => {
                    let value = v ? (customValue ? v[customValue.key] : v) : v;
                    setValue(name, value, { shouldValidate: true });
                    if (isPagination) {
                        (onChange) && onChange(value);
                    } else {
                        (onChange && value) && onChange(value);
                    }
                }}
                onBlur={(v) => {
                    if (freeSolo) {
                        let sLabelValue = v.target['value'] as string;
                        let objValue = options.find(f => f.label === sLabelValue);
                        setValue(name, { label: sLabelValue, value: objValue != null ? objValue.value : undefined });
                        onChange && onChange({ label: sLabelValue, value: objValue != null ? objValue.value : undefined });
                    }
                }}
                onInputChange={(e, v, r) => {
                    if (freeSolo && v && errors && errors[name]) {
                        clearErrors(name);
                    }
                }}
                options={options || []}
                getOptionLabel={(itemOption: any) => {
                    return `${isOptionTwoLanguage ? itemOption[`label_${langCode}`] : itemOption.label}`
                }}
                isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                }
                renderInput={(params) => <TextField
                    // error={errors && errors[name]}
                    required={required || null}
                    disabled={disabled || null}
                    label={label}
                    {...params}
                />}
            />
            {/* {errors && errors[name] && <span className='text-[12px] text-[red] mx-[14px]'>{errors[name].message}</span>} */}
            {errors && errors[name] ?
                <FormHelperText sx={{ color: "red" }} >{errors[name].message}</FormHelperText>
                :
                null}
        </FormControl>
    )
}
AutoCompleteSelect.propTypes = {
    required: PropTypes.bool,
    name: PropTypes.string.isRequired,
    options: PropTypes.array,
    label: PropTypes.string,
    size: PropTypes.oneOf(["small", "medium"]),
    fullWidth: PropTypes.bool,
    disableClearable: PropTypes.bool,
    freeSolo: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    hidden: PropTypes.bool,
    shrink: PropTypes.bool,
    sxLabel: PropTypes.any,
    small: PropTypes.bool,
    rounded: PropTypes.bool,
    customValue: PropTypes.any,
    isOptionTwoLanguage: PropTypes.bool,
    isPagination: PropTypes.bool
};

AutoCompleteSelect.defaultProps = {
    required: false,
    options: [],
    label: "",
    size: "small",
    customValue: null,
    isOptionTwoLanguage: false
};
