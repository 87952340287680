import {
  Grid,
  Typography,
  Stack,
  FormControlLabel,
  Checkbox,
  Skeleton,
  Divider,
} from "@mui/material";
import ProfileProvider from "components/SeekerProfile/Context/ProfileContext";
import React, { useEffect, useState } from "react";
import MyProfile from "components/SeekerProfile/MyProfile";
import { FnBlock_UI } from "service/SystemFunction/SystemFunction";
import { AxiosGet, AxiosPost } from "service/CommonFunction/TS_function";
import { DialogActionCreators } from "store/redux/DialogAlert";
import { useDispatch } from "react-redux";
import { i18n } from "i18n";
import {
  BtnBack,
  BtnCancel,
  BtnRadius,
  BtnSubmit,
} from "components/Common/Button";
import { useNavigate, useLocation } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import yupFormSchemas from "components/Common/yup/yupFormSchemas";
import * as yup from "yup";
import Input from "components/Common/ElementInForm/InputCustomLabelFormItem";
import DetailJob from "components/JobDetail/DetailJob";
import DetailCompany from "components/JobDetail/DetailCompany";
import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";
import InputNumber from "components/Common/ElementInForm/InputNumber";

export default function JobApplySalary() {
  const location = useLocation();
  const { BlockUI, UnBlockUI } = FnBlock_UI();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const history = useNavigate();

  const i18nField = "entities.SeekerProfile";
  const i18nCommon = "common";

  const objJobDetail = location?.state ? location.state["objJobDetail"] : null;

  const [isSpecified, setIsSpecified] = useState(false);
  const [jobPost, setJobPost] = useState<{ [key: string]: any }>({});
  const [isLoading, setLoading] = useState(true);
  const [isExpireDate, setIsExpireDate] = useState(true);

  const [initialValues] = useState(() => {
    return {
      nSalary: null,
      sIntroduce: "",
    };
  });

  const objSchema = {
    nSalary: yupFormSchemas.integer(i18n(`${i18nField}.RequireSalary`), {
      required: true,
    }),
  };

  const schema = yup.object().shape(objSchema);
  const formResolver = yupResolver(schema);
  const form = useForm({
    resolver: formResolver,
    shouldUnregister: false,
    shouldFocusError: true,
    mode: "all",
    defaultValues: initialValues as any,
  });

  const onGetDetail = () => {
    AxiosGet("JobDetail/detail", { sID: objJobDetail.sID }, (res) => {
      setIsExpireDate(res.IsExpired)
      setJobPost(res);
     
      setLoading(false);
    });
  };

  const OnApply = (value) => {
    //กด Apply จากหน้า Search Job
    if (objJobDetail && objJobDetail.sFromPage === "JobDetail") {
      dispatch(
        DialogActionCreators.OpenDialogSubmit(
          i18n(`${i18nField}.ConfirmMsgJobApply`),
          () => {
            BlockUI();
            AxiosPost(
              "JobDetail/apply",
              {
                sID: objJobDetail.sID,
                isApply: true,
                nSalary: value.nSalary,
                isSpecified: isSpecified,
                sIntroduction: value.sIntroduce,
              },
             
              (res) => {
                UnBlockUI();
                if (res.Status === 200) {
                  dispatch(
                    DialogActionCreators.OpenDialogSuccess(
                      i18n(`${i18nField}.MsgJobApply`),
                      () => {
                        if (res.sUrl) {
                          window.open(res.sUrl, "_blank")
                        }
                        history("/SearchJob", {})
                      }
                    ) as any
                  );
                } else {
                  dispatch(
                    DialogActionCreators.OpenDialogError(res.Message) as any
                  );
                }
              },
              (err) => {
                UnBlockUI();
                if (err.Status === 401) {
                  dispatch(
                    DialogActionCreators.OpenDialogWarning(
                      "Token expired.",
                      () => {
                        history("/");
                      }
                    ) as any
                  );
                } else {
                  dispatch(
                    DialogActionCreators.OpenDialogError(err.Message) as any
                  );
                }
              }
            );
          }
        ) as any
      );
    } else if (objJobDetail && objJobDetail.sFromPage === "MyJobInterested") {
      //กด Apply จากหน้า My Job Interested
      dispatch(
        DialogActionCreators.OpenDialogSubmit(
          i18n(`${i18nField}.ConfirmMsgJobApply`),
          () => {
            BlockUI();

            const param = {
              sJobPostId: objJobDetail.sID,
              sCompanyId: objJobDetail.sCompanyID,
              nSalary: value.nSalary,
              isSpecified: isSpecified,
              sIntroduction: value.sIntroduce,
            };

            AxiosPost(
              `MyJob/JobApply`,
              param,
              (res) => {
                UnBlockUI();
                if (res.StatusCode === 200) {
                  dispatch(
                    DialogActionCreators.OpenDialogSuccess(
                      i18n(`${i18nField}.MsgJobApply`),
                      () => {
                        history("/MyJobInterested", {});
                      }
                    ) as any
                  );
                }
              },
              (err) => {
                UnBlockUI();
                if (err.Status === 401) {
                  dispatch(
                    DialogActionCreators.OpenDialogWarning(
                      "Token expired.",
                      () => {
                        history("/");
                      }
                    ) as any
                  );
                } else {
                  dispatch(
                    DialogActionCreators.OpenDialogError(err.Message) as any
                  );
                }
              },
              () => {
                dispatch(DialogActionCreators.LoadSubmit(false) as any);
              }
            );
          }
        ) as any
      );
    }
  };

  useEffect(() => {
    onGetDetail();
  }, []);

  return (
    <FormProvider {...form}>
      <Grid className="fade-bg-color" container spacing={2} sx={{ m: 0, backgroundColor: "white" }}>
        {/* Job Detail */}
        <Grid item xs={12}>
          <Grid item container xs={12}>
            <DetailJob jobPost={jobPost} isLoading={isLoading} />
            <DetailCompany
              lstCompanyContent={jobPost.lstCompanyContent}
              lstCompanyJob={jobPost.lstCompanyJob}
              isLoading={isLoading}
              isViewJob={true}
              isExpireDate={isExpireDate}
            />
          </Grid>
        </Grid>
        {/* Salary */}
        <Grid item md={3} xs={6}>
          <InputNumber
            name={"nSalary"}
            small
            label={
              <Typography sx={{ fontWeight: 400 }} component="label">
                {i18n(`${i18nField}.RequireSalary`)}
              </Typography>
            }
           // type="number"
            required
          />
        </Grid>
        <Grid item md={9} xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isSpecified}
                onChange={(e, value) => {
                  setIsSpecified((prev) => !prev);
                }}
              />
            }
            label={i18n(`${i18nField}.ApplySalaryByCompany`)}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            label={
              <Typography sx={{ fontWeight: 400 }} component="label">
                {i18n(`${i18nField}.Introduction`)}
              </Typography>
            }
            small
            maxLength={2000}
            name={"sIntroduce"}
            multiline
            rows={5}
          />
        </Grid>

        {/* Button */}
        <Grid item xs={12}>
          <Stack
            spacing={1}
            sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
            direction="row"
            justifyContent="right"
            alignItems="right"
          >
            <BtnSubmit
              onClick={form.handleSubmit(OnApply)}
              txt={i18n(`jobDetail.button.btnSendApply`)}
            />
            {objJobDetail ? (
              <BtnBack
                onClick={() => {
                  history(
                    objJobDetail && objJobDetail.sFromPage === "MyJobInterested"
                      ? "/MyJobInterested"
                      : "/SearchJob"
                  );
                }}
                txt={i18n(`${i18nCommon}.back`)}
              />
            ) : (
              <BtnCancel
                onClick={() => {
                  history("/");
                }}
                txt={i18n(`${i18nCommon}.cancel`)}
              />
            )}
          </Stack>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
