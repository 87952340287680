import { Block } from "@mui/icons-material";
import { Button, Card, CardMedia, Divider, Grid, TextField, Typography, Box } from "@mui/material"
import BackgroundDashboard from "assets/images/BPS-Banner_Dashboard_full.png"
import BackgroundLogin from "assets/images/businesswomanjpg-edit.png"
import BackgroundDetail from "assets/images/BPS-Banner-detail.png"
import BackgroundDetail2 from "assets/images/BPS-Banner-detail2.png"
import BackgroundHome from "assets/images/BPS-Banner_Home.png"
import { useLocation } from "react-router-dom";
import { useState } from "react";
import FooterFront from "./Footer_Front";

export default function Background_Home(props: any) {

    const location = useLocation();

    const ImgBGBanner = () => {
        if (props && props.imgBGType) {
            switch (props.imgBGType) {
                case 1:
                    return BackgroundHome;
                case 2:
                    return BackgroundLogin;
                case 3:
                    return BackgroundDetail;
                case 4:
                    return BackgroundDetail2;
                case 5:
                    return BackgroundDashboard;
                default:
                    return null;
            }
        }
    }

    const arrPageNotAddMargin = ["/", "/MyPageEmployer", "/JobPostList", "/OrderEmployer", "/OrderEmployerForm", "/MyProfile"];
    const arrPageNotAddInnerMargin = ["/", "/SearchJob", "/MyJobApply", "/MyJobInterested", "/MyJob"];

    return (
        <Grid container className="relative" >
            <Grid item className="bg-top" xs={12}>
                <img src={ImgBGBanner()} alt="" />
            </Grid>

            <Grid item className="min-h-[calc(100vh-70px)] " xs={12}
                sx={{
                    marginTop: "1%",
                    // marginTop: "-10%",
                    mx: arrPageNotAddMargin.findIndex(f => f === location.pathname) === -1 ? "10%" : "",
                    zIndex: 5,
                }}
            >
                <Box sx={{
                    border: "10px",
                    p: arrPageNotAddInnerMargin.findIndex(f => f === location.pathname) === -1 ? 1 : 0,
                    flex: 1,
                    m: arrPageNotAddInnerMargin.findIndex(f => f === location.pathname) === -1 ? "2%" : "",
                    // marginLeft: "-1%",
                    minHeight: "100%",
                    pt: (props.isPaddingTop ? '35%' : ''),
                }}>
                    {props.children}
                </Box>
            </Grid>
        </Grid>
    )
}