import MyJobInvite from "components/MyJob/MyJobInvitation";

const MyJobInvitation = () => {
  return (
    <>
      <MyJobInvite />
    </>
  );
};

export default MyJobInvitation;
