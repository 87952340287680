import {
  Box,
  Grid,
  Typography,
  Paper,
  ButtonGroup,
  Button,
} from "@mui/material";
import ProfileProvider from "components/SeekerProfile/Context/ProfileContext";
import React, { useEffect, useState } from "react";
import MyProfile from "components/SeekerProfile/MyProfile";
import { useNavigate, useLocation } from "react-router-dom";
import { FnBlock_UI } from "service/SystemFunction/SystemFunction";
import { AxiosGet, AxiosPost } from "service/CommonFunction/TS_function";
import { DialogActionCreators } from "store/redux/DialogAlert";
import { useDispatch } from "react-redux";
import { i18n } from "i18n";
import JobApplySalary from "view/font/JobApply/JobApplySalary";
import secureLocalStorage from "react-secure-storage";

export default function JobApply() {
  const location = useLocation();
  const { BlockUI, UnBlockUI } = FnBlock_UI();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [lang, setLang] = useState("th");

  const i18nField = "entities.SeekerProfile";

  const objJobDetail = location?.state ? location.state["objJobDetail"] : null;

  const [objCompanyDetail, setCompanyDetail] = useState(null);
  const [isProfile, setIsProfile] = useState(true);

  const onGetDetail = () => {
    BlockUI();
    AxiosPost(
      "JobDetail/GetCompanyDetail",
      { sID: objJobDetail.sID },
      (res) => {
        UnBlockUI();
        if (res.Status === 200) {
          setCompanyDetail(res);
        } else {
          dispatch(DialogActionCreators.OpenDialogError(res.Message) as any);
        }
      },
      (err) => {
        UnBlockUI();
        if (err.Status === 401) {
          dispatch(
            DialogActionCreators.OpenDialogWarning("Token expired.", () => {
              navigate("/");
            }) as any
          );
        } else {
          dispatch(DialogActionCreators.OpenDialogError(err.Message) as any);
        }
      }
    );
  };

  useEffect(() => {
    setLang(secureLocalStorage.getItem("language").toString());
    onGetDetail();
    setTimeout(() => {
      document.getElementById("scroll_Top") &&
        document.getElementById("scroll_Top").scrollIntoView();
    }, 200);
  }, []);

  return (
    <ProfileProvider>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">{i18n(`${i18nField}.jobApply`)}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Grid container>
            <Grid item md={9} sm={12} xs={12}>
              <Paper elevation={3} sx={{ p: 2 }}>
                <Typography>
                  {objCompanyDetail && objCompanyDetail.sCompanyName}
                </Typography>
                {/* <Typography>
                    8 ซ.ขวัญพัฒนา2 ถ.ดินแดง แขวงดินแดง เขต ดินแดง กรุงเทพมหานคร
                    10400
                  </Typography> */}
              </Paper>
            </Grid>
            <Grid
              item
              md={3}
              sm={12}
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Typography fontSize={"26px"}>
                {objCompanyDetail &&
                  (lang === "th"
                    ? objCompanyDetail.sPositionTH
                    : objCompanyDetail.sPositionEN)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ButtonGroup size="large" aria-label="large button group">
            <Button
              style={{
                backgroundColor: isProfile ? "#1976D2" : "white",
                color: isProfile ? "white" : "#1976D2",
                borderRadius: "20px"
              }}
              onClick={() => setIsProfile(true)}
            >
              {i18n(`${i18nField}.UpdateProfile`)}
            </Button>
            <Button
              style={{
                backgroundColor: !isProfile ? "#1976D2" : "white",
                color: !isProfile ? "white" : "#1976D2",
                borderRadius: "20px"
              }}
              onClick={() => setIsProfile(false)}
            >
              {i18n(`${i18nField}.SalaryDetail`)}
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid item xs={12}>
          {isProfile ? (
            <Grid className="fade-bg-color" sx={{ backgroundColor: "white" }}>
              <MyProfile fnAfterSubmit={() => {
                setIsProfile(false)
              }} />
            </Grid>
          ) : (
            <JobApplySalary />
          )}
        </Grid>
      </Grid>
    </ProfileProvider>
  );
}