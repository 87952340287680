import { AuthenticationState, MsalAuthProvider, LoginType } from 'react-aad-msal';
export interface AzureState {
    AuthenticateionState: AuthenticationState
    userData: any,
    ProviderMsal: MsalAuthProvider
}

const initSoftthaiConfig = {
    auth: {
        authority: 'https://login.microsoftonline.com/d303db8c-d034-4010-a7ee-c47bebf5bca3',
        clientId: '7d4ca790-83ee-4168-90f2-bd03659e29d2',
        postLogoutRedirectUri: window.location.origin,
        validateAuthority: true,
        redirectUri: window.location.origin+"/Adminlogin/"
    },
    cache: {
        CacheStorage: "sessionStorage",
        storeAuthStateInCookie: true
    }
};

export const initAuthenParams = {
    scopes: [
        "offline_access",
        "openid",
        "profile",
        "email"
    ]
}

export const initOption = {
    loginType: LoginType.Redirect,
    tokenRefreshUri: window.location.origin
}

const initialState: AzureState = {
    AuthenticateionState: AuthenticationState.Unauthenticated as AuthenticationState,
    userData: null,
    ProviderMsal: new MsalAuthProvider(initSoftthaiConfig, initAuthenParams, initOption),
};


export const AzureReducers = (state = initialState, { type, payload }) => {
    switch (type) {
        case 'LOGIN': {
            const newState = { ...state };
            newState.AuthenticateionState = payload.AuthenticateionState ? payload.AuthenticateionState : AuthenticationState.Unauthenticated;
            newState.userData = payload.userData ? payload.userData : null;
            return newState;
        }
        case 'LOGOUT': {
            const newState = { ...state };
            newState.AuthenticateionState = payload.AuthenticateionState ? payload.AuthenticateionState : AuthenticationState.Unauthenticated;
            newState.userData = null;
            return newState;
        }
        case 'SET_CONFIG': {
            const newState = { ...state };
            newState.ProviderMsal = payload.ProviderMsal ? payload.ProviderMsal : initialState.ProviderMsal;
            return newState;
        }
        default:
            return state;
    }
}