import { Stack } from "@mui/material";
import FromMasterTableContent from "./FromMasterTableContent";


export default function FromMasterTable() {
  return (   
    <Stack  sx={{mx: "1%"}}>
      <FromMasterTableContent />      
    </Stack>   
  );
}