import { useState, useEffect } from 'react';
import { DataGridMui as Table, FilterFieldInterface, initRows, PaginationInterface } from "components/Common/Table/DataGridMui";
import { GridColumns } from "@mui/x-data-grid";
import { AxiosPost, } from "service/CommonFunction/TS_function";
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { i18n } from "i18n";
import { FormProvider, useForm } from 'react-hook-form';
import { Tooltip } from '@mui/material';

const JobPackageTable = () => {
  const i18nField = "TablePackage";
  const form = useForm();
  const [loadingTable, setLoadingTable] = useState(true);
  const [dataRow, setDataRow] = useState<PaginationInterface>({
    ...initRows,
    sSortExpression: "sEndDate",
    sSortDirection: "asc"
  });

  const filter: FilterFieldInterface[] = [
    { sTypeFilterMode: "input", sFieldName: "sPackageName" },

    { sTypeFilterMode: "input", sFieldName: "nDuration" },
    { sTypeFilterMode: "input", sFieldName: "sJobPackagePriceNormal" },
    { sTypeFilterMode: "input", sFieldName: "sPackageVat" },
    { sTypeFilterMode: "input", sFieldName: "sPackageTotalPrice" },
  ];

  useEffect(() => {
    loadData(dataRow);
  }, []);

  const loadData = (p: PaginationInterface) => {
    setLoadingTable(true)
    const objFilter = { ...p, }
    AxiosPost("OrderEmployer/SearchData_TableJobDataPackage", objFilter, (result) => {

      setLoadingTable(false)
      setDataRow({
        ...p,
        arrRows: result.lstMaster,
        nDataLength: result.nDataLength,
        nPageIndex: result.nPageIndex,
      });
    })

  }

  const dataColumn: GridColumns = [
    {
      field: "sPostAmount",
      headerName: i18n(`${i18nField}.JobPosting`), //Job Posting
      headerAlign: "center",
      align: "center",
      resizable: false,
      minWidth: 150,
    },
    {
      field: "sPackageName",
      headerName: i18n(`${i18nField}.PackageTable`),
      headerAlign: "center",
      align: "left",
      resizable: false,
      flex: 2,
      minWidth: 300,
      renderCell: (item) => {
        return [
          (
            <Tooltip placement="top" title={item.row.sPackageName}>
              <div id={item.row.sID}
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '300px',
                }}>
                {item.row.sPackageName}
              </div>
            </Tooltip>
          )
        ];
      }
    },
    {
      field: "nDuration",
      headerName: i18n(`${i18nField}.JobPostingPeriod`), //Job Posting Period
      headerAlign: "center",
      align: "center",
      resizable: false,
      flex: 2,
      minWidth: 200,
    },
    {
      field: "sJobPackagePriceNormal",
      headerName: i18n(`${i18nField}.NormalPricePerJob`), //Normal Price/Job
      headerAlign: "center",
      align: "right",
      resizable: false,
      flex: 2,
      minWidth: 160,
    },
    {
      field: "",
      resizable: false,
      minWidth: 410,
      flex: 2,
      renderHeader: (p) => {
        return (
          <FormProvider {...form}>
            <div className='flex flex-col' style={{ width: 410, height: 70, paddingTop: "4%", paddingBottom: "4%" }}>
              <div className='flex flex-row flex-[1] w-full' style={{ justifyContent: "center", alignItems: "center", borderBottom: "solid 1px #D0D0D0" }}>
                <label className='font-[500]'>{i18n(`${i18nField}.Special`)}</label> {/* Special*/}
              </div>
              <div className='flex flex-row w-full flex-[1] items-center gap-[10px]'>
                <div className='flex flex-[1]' style={{ justifyContent: "center" }}>
                  <label className='font-[500]'>{i18n(`${i18nField}.PricePerJob`)}</label> {/* Price Per Job*/}
                </div>
                <div className='flex flex-[1]' style={{ justifyContent: "center" }}>
                  <label className='font-[500]'>{i18n(`${i18nField}.TotalPrice`)}</label> {/* Total Price*/}
                </div>
                <div className='flex flex-[1]' style={{ justifyContent: "center" }}>
                  <label className='font-[500]'>{i18n(`${i18nField}.PercentSave`)}</label> {/* % Save*/}
                </div>
                <div className='flex flex-[1]' style={{ justifyContent: "center" }}>
                  <label className='font-[500]'>{i18n(`${i18nField}.Net`)}</label> {/* Net*/}
                </div>
              </div>
            </div>
          </FormProvider>
        )
      },
      renderCell: (p) => {
        return (
          <FormProvider {...form}>
            <div className='flex flex-col' style={{ width: 410, height: 70 }}>
              <div className='flex flex-row w-full flex-[1] items-center gap-[10px]'>
                <div className='flex flex-[1] justify-center'>
                  <label className='font-[500]'>{p.row.sJobPackagePrice}</label>
                </div>
                <div className='flex flex-[1] justify-center'>
                  <label className='font-[500]'>{p.row.sTotalPrice}</label>
                </div>
                <div className='flex flex-[1] justify-center'>
                  <label className='font-[500]'>{p.row.sPackageDiscount}</label>
                </div>
                <div className='flex flex-[1] justify-center'>
                  <label className='font-[500]'>{p.row.sPackageNetPrice}</label>
                </div>
              </div>
            </div>
          </FormProvider>
        )
      }
    },
    {
      field: "sPackageVat",
      headerName: i18n(`${i18nField}.Vat`), //Vat 7%
      headerAlign: "center",
      align: "center",
      resizable: false,
      flex: 2,
      minWidth: 150,
    },
    {
      field: "sPackageTotalPrice",
      headerName: i18n(`${i18nField}.GrandTotal`), //Grand Total
      headerAlign: "center",
      align: "right",
      resizable: false,
      flex: 2,
      minWidth: 150,
    },
  ];

  return (
    <Table
      sxCustomTable={{
        ".MuiDataGrid-virtualScrollerRenderZone": {
          ">:last-child>div": {
            borderBottom: "1px solid rgba(224, 224, 224, 1) !important"
          },
        },
        ".MuiDataGrid-columnHeaders": {
          lineHeight: "20px!important",
        },
      }}
      isLoading={loadingTable}
      filterField={filter}
      Rows={dataRow}
      Columns={dataColumn}
      OnLoadData={(p) => loadData(p)}
      isJobPackage={true}
      isAddCondition={true}
      TermsType={4}//T_Page_Content: id
    />
  );
}

export default JobPackageTable