import React, { createElement } from "react";
import { Button, Fab, Theme, Tooltip, Icon, TooltipProps } from "@mui/material";
import {
  Login,
  Search,
  Send,
  Delete,
  ArrowBackIos,
  Logout,
  Settings,
  FileDownload,
  Save,
  Visibility,
  DeleteForever,
  Add,
  RemoveRedEye,
  Image,
  Edit,
  Link,
  NotInterested,
  Done,
  LockOpen,
  ArrowBackIosNew,
  
} from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLine, faMicrosoft } from "@fortawesome/free-brands-svg-icons";
import * as Icons from "@mui/icons-material";
import HistoryIcon from '@mui/icons-material/History';
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
//import { LoadingButton } from "@mui/x-data-grid";
import LoadingButton from "@mui/lab/LoadingButton";
import { i18n } from "i18n";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
const sizeDefault = { width: 40, height: 40 };
export const styleX = {
  btnRadius: {
    my: 2,
    border: "1px solid #f2f2f2",
    borderRadius: "20px",
    height: "40px",
    ".MuiFilledInput-input": { paddingTop: "10px" },
  },
  btnRadiusProfile: {
    my: 2,
    // border: "1px solid #f2f2f2",
    borderRadius: "20px",
    height: "40px",
    width: "50%",
    ".MuiFilledInput-input": { paddingTop: "10px" },
  },
  btnPrimaryColor: {
    color: "white",
    padding: "0.5em 2em",
    borderRadius: "2em",
    backgroundColor: "##1976d2",
    ":hover": {
      backgroundColor: "rgb(17, 82, 147)",
    },
    width: "max-content",
  },
  btnSubmit: {
    color: "white",
    backgroundColor: "#212529",
    ":hover": {
      backgroundColor: "rgb(84, 87, 89)",
    },
    borderRadius: "20px",

  },
  btnLogout: {
    color: "white",
    backgroundColor: "#fe796a",
    ":hover": {
      backgroundColor: "rgb(220, 53, 69)",
    },
    borderRadius: "20px"
  },
  btnCancel: {
    color: "white",
    backgroundColor: "#ed3847",
    ":hover": {
      backgroundColor: "rgb(220, 53, 69)",
    },
    borderRadius: "20px"
  },
  btnDownload: {
    color: "#fff",
    backgroundColor: "#212529",
    borderColor: "#212529",
    "&:hover": {
      backgroundColor: "rgb(84, 87, 89)",
    },
    margin: 1,
  },
  btnSetting: {
    color: "#fff",
    backgroundColor: "#1976d2",
    "&:hover": {
      backgroundColor: "#0946a2",
    },
    borderRadius: "20px",
  },
  btnSave: {
    backgroundColor: "rgb(51, 166, 76)",
    ":hover": {
      backgroundColor: "rgb(24, 142, 50)",
    },
    borderRadius: "20px"
  },
  btnSaveDraft: {
    backgroundColor: "rgb(101 104 102)",
    ":hover": {
      backgroundColor: "rgb(78 75 75)",
    },
    borderRadius: "20px",

  },
  btnBack: {
    backgroundColor: "#a7a7a7",
    ":hover": {
      backgroundColor: "#878787",
    },
    borderRadius: "20px",
  },
  btnPreview: {
    backgroundColor: "#e39a2d",
    ":hover": {
      backgroundColor: "#b9812d",
    },
    borderRadius: "20px",

  },
  violetColor: {
    color: "#fff",
    backgroundColor: "#9e3eda",
    borderColor: "#9e3eda",
    ":hover": { bgcolor: "#671d96" },
  },
};
export const BtnLogin = ({
  IsDisabled = false,
  IsHisabled = false,
  onClick = () => { },
  txt = "Login",
  isCircleWithOutText = false,
  tooltipPlacement = "bottom",
}) => {
  return (
    <Tooltip placement={tooltipPlacement as any} title={txt}>
      {!isCircleWithOutText ? (
        <Button
          variant="contained"
          size="small"
          startIcon={<Login />}
          disabled={IsDisabled === true ? true : false}
          hidden={IsHisabled === true ? true : false}
          onClick={onClick}
          sx={{ borderRadius: "20px" }}
        >
          {txt}
        </Button>
      ) : (
        <Fab
          sx={{ width: 40, height: 40 }}
          color="default"
          onClick={onClick}
          aria-label="login"
        >
          <Login />
        </Fab>
      )}
    </Tooltip>
  );
};

export const BtnLineLogin = ({
  IsDisabled = false,
  IsHisabled = false,
  onClick = () => { },
  txt = "Line Login",
  isCircleWithOutText = false,
  tooltipPlacement = "bottom",
}) => {
  return (
    <Tooltip placement={tooltipPlacement as any} title={txt}>
      {!isCircleWithOutText ? (
        <Button
          variant="contained"
          size="small"
          sx={{
            borderRadius: "20px",
            backgroundColor: "#20af4c",
            ":hover": {
              backgroundColor: "#198639",
            },
          }}
          startIcon={<FontAwesomeIcon icon={faLine} />}
          disabled={IsDisabled === true ? true : false}
          hidden={IsHisabled === true ? true : false}
          onClick={onClick}
        >
          {txt}
        </Button>
      ) : (
        <Fab
          sx={{ width: 40, height: 40, backgroundColor: "#20af4c" }}
          onClick={onClick}
          aria-label="Line Login"
        >
          <Login />
        </Fab>
      )}
    </Tooltip>
  );
};

export const BtnAzureLogin = ({
  IsDisabled = false,
  IsHisabled = false,
  onClick = () => { },
  txt = "Azure Login",
  isCircleWithOutText = false,
  tooltipPlacement = "bottom",
}) => {
  return (
    <Tooltip placement={tooltipPlacement as any} title={txt}>
      {!isCircleWithOutText ? (
        <Button
          variant="contained"
          size="small"
          sx={{
            borderRadius: "20px",
            backgroundColor: "#f34f1c",
            padding: "0.5em 2em",
            ":hover": {
              backgroundColor: "#c2350a",
            },
          }}
          startIcon={<FontAwesomeIcon icon={faMicrosoft} />}
          disabled={IsDisabled === true ? true : false}
          hidden={IsHisabled === true ? true : false}
          onClick={onClick}
        >
          {txt}
        </Button>
      ) : (
        <Fab
          sx={{ width: 40, height: 40 }}
          color="default"
          onClick={onClick}
          aria-label="Azure Login"
        >
          <Login />
        </Fab>
      )}
    </Tooltip>
  );
};

export const BtnSetting = ({
  IsDisabled = false,
  IsHisabled = false,
  onClick = () => { },
  txt = "Setting",
  isCircleWithOutText = false,
  tooltipPlacement = "bottom",
}) => {
  return (
    <Tooltip placement={tooltipPlacement as any} title={txt}>
      {!isCircleWithOutText ? (
        <Button
          variant="contained"
          size="small"
          startIcon={<Settings />}
          disabled={IsDisabled === true ? true : false}
          hidden={IsHisabled === true ? true : false}
          onClick={onClick}
          sx={styleX.btnSetting}
        >
          {txt}
        </Button>
      ) : (
        <Fab
          sx={{
            width: 40,
            height: 40,
            bgcolor: "#1976d2",
            ...styleX.btnSetting,
          }}
          onClick={onClick}
          aria-label="setting"
        >
          <Settings />
        </Fab>
      )}
    </Tooltip>
  );
};

export const BtnLogout = ({
  IsDisabled = false,
  IsHisabled = false,
  onClick = () => { },
  txt = "Logout",
  isCircleWithOutText = false,
  sx = {},
  tooltipPlacement = "bottom",
}) => {
  return (
    <Tooltip placement={tooltipPlacement as any} title={txt}>
      {!isCircleWithOutText ? (
        <Button
          variant="contained"
          size="small"
          startIcon={<Logout />}
          disabled={IsDisabled === true ? true : false}
          hidden={IsHisabled === true ? true : false}
          onClick={onClick}
          sx={{ ...styleX.btnCancel, ...sx }}
        >
          {txt}
        </Button>
      ) : (
        <Fab
          sx={{
            width: 40,
            height: 40,
            bgcolor: "#ed3847",
            ...styleX.btnCancel,
            ...sx,
          }}
          onClick={onClick}
          aria-label="logout"
        >
          <Logout />
        </Fab>
      )}
    </Tooltip>
  );
};

export const BtnLoginWithLoading = ({
  IsDisabled = false,
  IsHisabled = false,
  onClick = () => { },
  txt = "Login",
  isLoading,
}) => {
  return (
    <Tooltip title={txt}>
      <LoadingButton
        loading={isLoading}
        variant="contained"
        size="small"
        startIcon={<Login />}
        disabled={IsDisabled === true ? true : false}
        hidden={IsHisabled === true ? true : false}
        onClick={onClick}
        sx={styleX.btnPrimaryColor}
      >
        {txt}
      </LoadingButton>
    </Tooltip>
  );
};

export const BtnChangePassword = ({
  IsDisabled = false,
  IsHisabled = false,
  onClick = () => { },
  txt = "Login",
  isLoading,
}) => {
  return (
    <Tooltip title={txt}>
      <LoadingButton
        loading={isLoading}
        variant="contained"
        size="small"
        startIcon={<LockOpen />}
        disabled={IsDisabled === true ? true : false}
        hidden={IsHisabled === true ? true : false}
        onClick={onClick}
        sx={styleX.btnPrimaryColor}
      >
        {txt}
      </LoadingButton>
    </Tooltip>
  );
};

export const BtnSubmitWithLoading = ({
  IsDisabled = false,
  IsHisabled = false,
  onClick = () => { },
  txt = "Submit",
  isLoading,
}) => {
  // return (
  //   <Tooltip title={txt}>
  //     <LoadingButton
  //       loading={isLoading}
  //       variant="contained"
  //       size="small"
  //       startIcon={<Send />}
  //       disabled={IsDisabled === true ? true : false}
  //       hidden={IsHisabled === true ? true : false}
  //       onClick={onClick}
  //       sx={styleX.btnSubmit}
  //     >
  //       {txt}
  //     </LoadingButton>
  //   </Tooltip>
  // );
};

export const BtnSubmit = ({
  IsDisabled = false,
  IsHisabled = false,
  onClick = () => { },
  txt = "Submit",
  isCircleWithOutText = false,
  tooltipPlacement = "bottom",
}) => {
  return (
    <Tooltip placement={tooltipPlacement as any} title={txt}>
      {!isCircleWithOutText ? (
        <Button
          variant="contained"
          size="small"
          startIcon={<Send />}
          disabled={IsDisabled === true ? true : false}
          hidden={IsHisabled === true ? true : false}
          onClick={onClick}
          sx={styleX.btnSubmit}
        >
          {txt}
        </Button>
      ) : (
        <Fab
          sx={[styleX.btnSubmit, { ...sizeDefault }]}
          onClick={onClick}
          aria-label="submit"
        >
          <Send />
        </Fab>
      )}
    </Tooltip>
  );
};

export const BtnCancel = ({
  IsDisabled = false,
  IsHisabled = false,
  onClick = () => { },
  txt = i18n(`common.remove`),
  isCircleWithOutText = false,
  tooltipPlacement = "bottom",
}) => {
  return (
    <Tooltip placement={tooltipPlacement as any} title={txt}>
      {!isCircleWithOutText ? (
        <Button
          variant="contained"
          size="small"
          startIcon={<DeleteForever />}
          disabled={IsDisabled === true ? true : false}
          hidden={IsHisabled === true ? true : false}
          onClick={onClick}
          sx={styleX.btnCancel}
        >
          {txt}
        </Button>
      ) : (
        <Fab
          sx={[styleX.btnCancel, { ...sizeDefault }]}
          onClick={onClick}
          aria-label="cancel"
          disabled={IsDisabled === true ? true : false}
        >
          <DeleteForever />
        </Fab>
      )}
    </Tooltip>
  );
};

export const BtnReject = ({
  IsDisabled = false,
  IsHisabled = false,
  onClick = () => { },
  txt = i18n(`common.remove`),
  isCircleWithOutText = false,
  tooltipPlacement = "bottom",
}) => {
  return (
    <Tooltip placement={tooltipPlacement as any} title={txt}>
      {!isCircleWithOutText ? (
        <Button
          variant="contained"
          size="small"
          startIcon={<NotInterested />}
          disabled={IsDisabled === true ? true : false}
          hidden={IsHisabled === true ? true : false}
          onClick={onClick}
          sx={styleX.btnCancel}
        >
          {txt}
        </Button>
      ) : (
        <Fab
          sx={[styleX.btnCancel, { ...sizeDefault }]}
          onClick={onClick}
          aria-label="cancel"
          disabled={IsDisabled === true ? true : false}
        >
          <NotInterested />
        </Fab>
      )}
    </Tooltip>
  );
};

export const BtnSearch = ({
  IsDisabled = false,
  IsHisabled = false,
  onClick = () => { },
  txt = "Search",
  isCircleWithOutText = false,
  tooltipPlacement = "bottom",
}) => {
  return (
    <Tooltip placement={tooltipPlacement as any} title={txt}>
      {!isCircleWithOutText ? (
        <Button
          variant="contained"
          size="small"
          startIcon={<Search />}
          disabled={IsDisabled === true ? true : false}
          hidden={IsHisabled === true ? true : false}
          onClick={onClick}
          sx={styleX.btnSetting}
        >
          {txt}
        </Button>
      ) : (
        <Fab
          sx={[styleX.btnSetting, { ...sizeDefault }]}
          onClick={onClick}
          aria-label="search"
        >
          <Search />
        </Fab>
      )}
    </Tooltip>
  );
};

export const BtnCustomIcon = ({
  IsDisabled = false,
  IsHisabled = false,
  onClick = () => { },
  txt = "",
  isCircleWithOutText = false,
  sIconComponent = null,
  eIconComponent = null,
  sxCustom = {},
  tooltipPlacement = "bottom",
}) => {
  return (
    <Tooltip placement={tooltipPlacement as any} title={txt}>
      {!isCircleWithOutText ? (
        <Button
          variant="contained"
          size="small"
          startIcon={sIconComponent ? sIconComponent : null}
          endIcon={eIconComponent ? eIconComponent : null}
          disabled={IsDisabled === true ? true : false}
          hidden={IsHisabled === true ? true : false}
          onClick={onClick}
          sx={[styleX.btnBack, { ...sxCustom }]}
        >
          {txt}
        </Button>
      ) : (
        <Fab
          sx={[styleX.btnBack, { ...sizeDefault, ...sxCustom }]}
          onClick={onClick}
          disabled={IsDisabled === true ? true : false}
        >
          {sIconComponent ? sIconComponent : null}
        </Fab>
      )}
    </Tooltip>
  );
};

export const BtnCustomIconWithLoading = ({
  IsDisabled = false,
  IsHisabled = false,
  onClick = () => { },
  txt = "",
  isCircleWithOutText = false,
  sIconComponent = null,
  eIconComponent = null,
  sxCustom = {},
  tooltipPlacement = "bottom",
  isLoading = false,
}) => {
  return (
    <Tooltip placement={tooltipPlacement as any} title={txt}>
      {!isCircleWithOutText ? (
        <LoadingButton
          loading={isLoading}
          variant="contained"
          size="small"
          startIcon={sIconComponent ? sIconComponent : null}
          endIcon={eIconComponent ? eIconComponent : null}
          disabled={IsDisabled === true ? true : false}
          hidden={IsHisabled === true ? true : false}
          onClick={onClick}
          sx={[styleX.btnBack, { ...sxCustom }]}
        >
          {txt}
        </LoadingButton>
      ) : (
        <Fab
          sx={[styleX.btnBack, { ...sizeDefault, ...sxCustom }]}
          onClick={onClick}
          disabled={IsDisabled === true ? true : false}
        >
          {sIconComponent ? sIconComponent : null}
        </Fab>
      )}
    </Tooltip>
  );
};

export const BtnNextWithLoading = ({
  IsDisabled = false,
  IsHisabled = false,
  onClick = () => { },
  txt = "Next",
  isLoading,
}) => {
  // return <Tooltip title={txt}>
  //   <LoadingButton
  //     loading={isLoading}
  //     variant="contained"
  //     size="small"
  //     endIcon={<ArrowForwardIos />}
  //     disabled={IsDisabled === true ? true : false}
  //     hidden={IsHisabled === true ? true : false}
  //     onClick={onClick}
  //     sx={styleX.btnSetting}
  //   >
  //     {txt}
  //   </LoadingButton>
  // </Tooltip>
};

export const BtnBack = ({
  IsDisabled = false,
  IsHisabled = false,
  onClick = () => { },
  txt = "Back",
  isCircleWithOutText = false,
  tooltipPlacement = "bottom",
}) => {
  return (
    <Tooltip placement={tooltipPlacement as any} title={txt}>
      {!isCircleWithOutText ? (
        <Button
          variant="contained"
          size="small"
          startIcon={<ArrowBackIosNew />}
          disabled={IsDisabled === true ? true : false}
          hidden={IsHisabled === true ? true : false}
          onClick={onClick}
          sx={styleX.btnBack}
        >
          {txt}
        </Button>
      ) : (
        <Fab
          onClick={onClick}
          sx={{ width: 40, height: 40, color: "#a6a6a6" }}
          aria-label="back"
        >
          <ArrowBackIos />
        </Fab>
      )}
    </Tooltip>
  );
};

export const BtnDownload = ({
  IsDisabled = false,
  IsHisabled = false,
  onClick,
  txt = "Download",
  isCircleWithOutText = false,
  tooltipPlacement = "bottom",
}) => {
  return (
    <Tooltip placement={tooltipPlacement as any} title={txt}>
      {!isCircleWithOutText ? (
        <Button
          variant="contained"
          size="small"
          startIcon={<FileDownload />}
          disabled={IsDisabled === true ? true : false}
          hidden={IsHisabled === true ? true : false}
          onClick={onClick}
          sx={styleX.btnDownload}
        >
          {txt}
        </Button>
      ) : (
        <Fab sx={{ width: 40, height: 40, color: "#a6a6a6" }} aria-label="back">
          <FileDownload />
        </Fab>
      )}
    </Tooltip>
  );
};

export const BtnSave = ({
  IsDisabled = false,
  IsHisabled = false,
  onClick = () => { },
  txt = "Save",
  isCircleWithOutText = false,
  tooltipPlacement = "bottom",
}) => {
  return (
    <Tooltip placement={tooltipPlacement as any} title={txt}>
      {!isCircleWithOutText ? (
        <Button
          variant="contained"
          size="small"
          startIcon={<Save />}
          disabled={IsDisabled === true ? true : false}
          hidden={IsHisabled === true ? true : false}
          onClick={onClick}
          sx={styleX.btnSave}
        >
          {txt}
        </Button>
      ) : (
        <Fab sx={{ width: 40, height: 40, color: "#a6a6a6" }} aria-label="back">
          <Save />
        </Fab>
      )}
    </Tooltip>
  );
};
export const BtnApprove = ({
  IsDisabled = false,
  IsHisabled = false,
  onClick = () => { },
  txt = "Save",
  isCircleWithOutText = false,
  tooltipPlacement = "bottom",
}) => {
  return (
    <Tooltip placement={tooltipPlacement as any} title={txt}>
      {!isCircleWithOutText ? (
        <Button
          variant="contained"
          size="small"
          startIcon={<Done />}
          disabled={IsDisabled === true ? true : false}
          hidden={IsHisabled === true ? true : false}
          onClick={onClick}
          sx={styleX.btnSave}
        >
          {txt}
        </Button>
      ) : (
        <Fab sx={{ width: 40, height: 40, color: "#a6a6a6" }} aria-label="back">
          <Done />
        </Fab>
      )}
    </Tooltip>
  );
};

export const BtnSaveDraft = ({
  IsDisabled = false,
  IsHisabled = false,
  onClick = () => { },
  txt = "SaveDraft",
  isCircleWithOutText = false,
  tooltipPlacement = "bottom",
}) => {
  return (
    <Tooltip placement={tooltipPlacement as any} title={txt}>
      {!isCircleWithOutText ? (
        <Button
          variant="contained"
          size="small"
          startIcon={<Save />}
          disabled={IsDisabled === true ? true : false}
          hidden={IsHisabled === true ? true : false}
          onClick={onClick}
          sx={styleX.btnSaveDraft}
        >
          {txt}
        </Button>
      ) : (
        <Fab sx={{ width: 40, height: 40, color: "#a6a6a6" }} aria-label="back">
          <Save />
        </Fab>
      )}
    </Tooltip>
  );
};

export const BtnPreview = ({
  IsDisabled = false,
  IsHisabled = false,
  onClick = () => { },
  // txt = "Preview",
  txt = i18n(`common.preview`),
  isCircleWithOutText = false,
  tooltipPlacement = "bottom",
}) => {
  return (
    <Tooltip placement={tooltipPlacement as any} title={txt}>
      {!isCircleWithOutText ? (
        <Button
          variant="contained"
          size="small"
          startIcon={<Visibility />}
          disabled={IsDisabled === true ? true : false}
          hidden={IsHisabled === true ? true : false}
          onClick={onClick}
          sx={styleX.btnPreview}
        >
          {txt}
        </Button>
      ) : (
        <Fab sx={{ width: 40, height: 40, color: "#a6a6a6" }} aria-label="back">
          <Visibility />
        </Fab>
      )}
    </Tooltip>
  );
};

export const BtnAddOnTable = ({
  IsDisabled = false,
  IsHisabled = false,
  txt = "Add",
  onClick = () => { },
}) => (
  <GridActionsCellItem
    disabled={IsDisabled}
    hidden={IsHisabled}
    icon={
      <Tooltip title={txt}>
        <Add />
      </Tooltip>
    }
    label={txt}
    sx={{
      bgcolor: "#1976d2",
      color: "white",
      ":hover": {
        bgcolor: "#0946a2",
      },
    }}
    onClick={onClick}
    aria-label={txt}
  />
);

export const BtnPreviewOnTable = ({
  IsDisabled = false,
  IsHisabled = false,
  txt = "Preview",
  onClick = () => { },
}) => (
  <GridActionsCellItem
    disabled={IsDisabled}
    hidden={IsHisabled}
    icon={
      <Tooltip title={txt}>
        <RemoveRedEye />
      </Tooltip>
    }
    label={txt}
    sx={(theme: Theme) => ({
      color: "#fff",
      backgroundColor: theme.palette.info.main,
      borderColor: theme.palette.info.light,
      ":hover": { bgcolor: theme.palette.info.dark },
    })}
    onClick={onClick}
    aria-label={txt}
  />
);

export const BtnLinkOnTable = ({
  IsDisabled = false,
  IsHisabled = false,
  txt = "Link",
  onClick = () => { },
}) => (
  <GridActionsCellItem
    disabled={IsDisabled}
    hidden={IsHisabled}
    icon={
      <Tooltip title={txt}>
        <Link />
      </Tooltip>
    }
    label={txt}
    sx={(theme: Theme) => ({
      color: "#fff",
      backgroundColor: "#9e3eda",
      borderColor: "#9e3eda",
      ":hover": { bgcolor: "#671d96" },
    })}
    onClick={onClick}
    aria-label={txt}
  />
);

export const BtnViewImageOnTable = ({
  IsDisabled = false,
  IsHisabled = false,
  txt = "View Image",
  onClick = () => { },
}) => (
  <GridActionsCellItem
    disabled={IsDisabled}
    hidden={IsHisabled}
    icon={
      <Tooltip title={txt}>
        <Image />
      </Tooltip>
    }
    label={txt}
    sx={{
      backgroundColor: "tranparent",
      color: "#4f5bef",
      ":hover": { bgcolor: "#d7d7d7" },
    }}
    onClick={onClick}
    aria-label={txt}
  />
);

export const BtnEditOnTable = ({
  IsDisabled = false,
  IsHisabled = false,
  txt = "Edit",
  onClick = () => { },
}) => (
  <GridActionsCellItem
    disabled={IsDisabled}
    hidden={IsHisabled}
    icon={
      <Tooltip title={txt}>
        <Edit />
      </Tooltip>
    }
    label={txt}
    sx={{
      backgroundColor: "#ffc107",
      color: "black",
      ":hover": { bgcolor: "#cc9900" },
    }}
    onClick={onClick}
    aria-label={txt}
  />
);

export const BtnTimeTable = ({
  IsDisabled = false,
  IsHisabled = false,
  IsShow = false,
  txt = "History",
  onClick = () => { },
}) => (
  <GridActionsCellItem
    disabled={IsDisabled}
    hidden={IsHisabled}
    icon={
      <Tooltip title={txt}>
        <HistoryIcon/>
      </Tooltip>
    }
    label={txt}
    sx={{
      backgroundColor: "#ffc107",
      color: "black",
      ":hover": { bgcolor: "#cc9900" },
    }}
    onClick={onClick}
    aria-label={txt}
  />
);

export const BtnDeleteTable = ({
  IsDisabled = false,
  IsHisabled = false,
  IsShow = false,
  txt = "Delete",
  onClick = () => { },
}) => (
  <GridActionsCellItem
    disabled={IsDisabled}
    hidden={IsHisabled}
    icon={
      <Tooltip title={txt}>
        <DeleteForeverIcon/>
      </Tooltip>
    }
    label={txt}
    sx={{
      backgroundColor: "#FF0000",
      color: "white",
      ":hover": { bgcolor: "#B84B40" },
    }}
    onClick={onClick}
    aria-label={txt}
  />
);

export const BtnViewOnTable = ({
  IsDisabled = false,
  IsHisabled = false,
  txt = "View",
  onClick = () => { },
}) => (
  <GridActionsCellItem
    disabled={IsDisabled}
    hidden={IsHisabled}
    icon={
      <Tooltip title={txt}>
        <Visibility />
      </Tooltip>
    }
    label={txt}
    sx={{
      backgroundColor: "#6FDBFF",
      color: "white",
      ":hover": { bgcolor: "#5DB6D4" },
    }}
    onClick={onClick}
    aria-label={txt}
  />
);

export const BtnViewOn = ({
  IsDisabled = false,
  IsHisabled = false,
  txt = "View",
  onClick = () => { },
}) => (
  <GridActionsCellItem
    disabled={IsDisabled}
    hidden={IsHisabled}
    icon={
      <Tooltip title={txt}>
        <Visibility />
      </Tooltip>
    }
    label={txt}
    sx={{
      backgroundColor: "#6FDBFF",
      color: "white",
      ":hover": { bgcolor: "#5DB6D4" },
    }}
    onClick={onClick}
    aria-label={txt}
  />
);
export const BtnRadius = ({
  IsDisabled = false,
  IsHisabled = false,
  txt = "Add",
  onClick = () => { },
  sBackgroundColor = "rgb(102, 187, 106)",
  sFontColor = "rgb(255, 255, 255)",
}) => (
  <Button
    disabled={IsDisabled === true ? true : false}
    hidden={IsHisabled === true ? true : false}
    onClick={onClick}
    variant="contained"
    size="small"
    fullWidth
    sx={styleX.btnRadius}
    style={{ backgroundColor: sBackgroundColor, color: sFontColor }}
    endIcon={<Add />}
  >
    {txt}
  </Button>
);

export const BtnRadiusProfile = ({
  IsDisabled = false,
  IsHisabled = false,
  txt = "Add",
  onClick = () => { },
  sBackgroundColor = "rgb(102, 187, 106)",
  sFontColor = "rgb(255, 255, 255)",
}) => (
  <Button
    disabled={IsDisabled === true ? true : false}
    hidden={IsHisabled === true ? true : false}
    onClick={onClick}
    variant="contained"
    size="small"
    sx={styleX.btnRadiusProfile}
    style={{
      background:
        "linear-gradient(90deg, rgba(100,214,105,1) 0%, rgba(57,133,61,1) 100%)",
    }}
    endIcon={<Add />}
  >
    {txt}
  </Button>
);
