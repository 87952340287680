import React, { Fragment, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { makeStyles, useTheme } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ReplayIcon from "@mui/icons-material/Replay";
import CloseIcon from "@mui/icons-material/Close";
import ImageIcon from "@mui/icons-material/Image";
import CheckIcon from "@mui/icons-material/Check";
import FilePopup from "../../FilePopup/FilePopup";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import FormControlLabel from "@mui/material/FormControlLabel";
import { styled } from "@mui/material/styles";
import IOSSwitchNormal from "components/Common/ElementInForm/IosSwitchNormal";

import InputCustomeLabel from "components/Common/ElementInForm/InputCustomLabelFormItem";
import { i18n } from "i18n";

const useStyles = makeStyles((theme: Theme) => ({
  WordColor: {
    color: "#fff",
    backgroundColor: "#2372ba",
    borderColor: "#2372ba",
    margin: theme.spacing(1),
    "&:hover": {
      backgroundColor: "rgb(56, 151, 234)",
    },
  },
  ExcelColor: {
    color: "#fff",
    backgroundColor: "#14a73c",
    borderColor: "#14a73c",
    margin: theme.spacing(1),
    "&:hover": {
      backgroundColor: "rgb(66, 201, 127)",
    },
  },
  PDFColor: {
    color: "#fff",
    backgroundColor: "#ff0000",
    borderColor: "#ff0000",
    margin: theme.spacing(1),
    "&:hover": {
      backgroundColor: "rgb(255, 79, 79)",
    },
  },
  PowerPointColor: {
    color: "#fff",
    backgroundColor: "#f26522",
    borderColor: "#f26522",
    margin: theme.spacing(1),
    "&:hover": {
      backgroundColor: "rgb(255, 79, 79)",
    },
  },

  FileItem: {
    margin: "0",
    padding: "20px 16px 20px 22px",
    borderBottom: "1px solid #ebeef1",
    animationDuration: ".6s",
  },
  ColumnTitle: {
    flex: "1",
    paddingLeft: "16px",
    paddingRight: "16px",
    color: "#74809d",
    overflow: "hidden",
    backgroundColor: "#fffff",
    cursor: "pointer",
  },
  ColumnAction: {
    margin: "0 16px",
  },
  Title: {
    fontWeight: "bold",
  },
  DangerColor: {
    color: "#fff !important",
    backgroundColor: "#ed3847 !important",
    borderColor: "#ed3847 !important",
    margin: theme.spacing(1),
    "&:hover": {
      backgroundColor: "rgb(220, 53, 69) !important",
    },
  },
  WarningColor: {
    color: "#fff",
    backgroundColor: "#ffce3d",
    borderColor: "#ffce3d",
    margin: theme.spacing(1),
    "&:hover": {
      backgroundColor: "rgb(252, 189, 0)",
    },
  },

  IConColor: {
    fill: "currentColor",
    width: "1em",
    height: "1em",
    display: "inline-block",
    fontSize: "1.5rem",
    transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
}));
const itemCard = (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const classes = useStyles();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [IsopenPopUp, setIsopenPopUp] = useState<any>(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [Result, setResult] = useState<any>({} as any);

  const ClosePopUp = () => {
    setIsopenPopUp(false);
  };
  const OpenPopUp = () => {
    setIsopenPopUp(true);
  };

  const onSwitchChange = (e, id) => {
    let objFile = props.arrFile.filter((f) => f.nFile_ID == id);
    if (objFile.length > 0) {
      objFile[0].IsNewTab = e.target.checked;
      props.SetarrFile([...props.arrFile]);
    }
  };


  return (
    <Fragment>
      <Grid item xs={12}>
        <Card sx={{ maxWidth: 280 }}>
          <div
            style={{
              backgroundColor: "#EAEAEA",
              position: "relative",
              maxWidth: "280px",
              maxHeight: "157px",
            }}
          >
            <div
              style={{
                position: "absolute",
                zIndex: "auto",
                right: "0",
              }}
            >
              {props.IsComplete ? (
                !props.disabled ? (
                  <Tooltip title={i18n(`common.destroy`)}>
                    <IconButton
                      size="small"
                      onClick={() => {
                        props.onDelete(props.nFile_ID);
                      }}
                      className={classes.DangerColor}
                    >
                      <DeleteForeverIcon />
                    </IconButton>
                  </Tooltip>
                ) : null
              ) : props.IsProgress ? null : (
                <Tooltip title="">
                  <IconButton
                    size="small"
                    onClick={() => {
                      props.onLoad(props.nFile_ID);
                    }}
                    className={classes.WarningColor}
                  >
                    <ReplayIcon />
                  </IconButton>
                </Tooltip>
              )}
            </div>
            <div
              style={{
                height: "157px",
                width: "280px",
                backgroundImage: `url(${props.sFileLink})`,
                backgroundSize: "cover",
                backgroundPositionX: "center",
                backgroundPositionY: "center",
                backgroundRepeat: "no-repeat",
              }}
              onClick={OpenPopUp}
            ></div>
          </div>
          <CardContent>
            <Typography
              variant="body2"
              component="div"
              style={{
                color: "#999999",
                marginBottom: "15px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {props.sFileName}
            </Typography>
            <>
              {/* 2 Input */}
              {props.sTextInput1 && (
                <InputCustomeLabel
                  name={"sInput1_" + props.nFile_ID}
                  labelString={props.sTextInput1}
                  fullWidth
                  required={props.IsReqInput1}
                  multiline={props.sTextInput1 == "Description" ? true : false}
                  rows={props.sTextInput1 == "Description" ? 3 : 1}
                  maxRow={props.sTextInput1 == "Description" ? 3 : 1}
                  maxLength={
                    props.sTextInput1 == "Description" ||
                      props.sTextInput1 == "URL"
                      ? 100
                      : 50
                  }
                  label={
                    <Typography sx={{ fontWeight: 600 }} component="label">
                      {props.sTextInput1}
                    </Typography>
                  }
                  disabled={props.disabled}
                />
              )}
              {props.sTextInput2 && (
                <>
                  <br />
                  <Grid item xs={12}>
                    <div style={{ height: "10px" }}></div>
                  </Grid>
                  <InputCustomeLabel
                    name={"sInput2_" + props.nFile_ID}
                    labelString={props.sTextInput2}
                    fullWidth
                    required={props.IsReqInput2}
                    multiline={
                      props.sTextInput2 == "Description" ? true : false
                    }
                    rows={props.sTextInput2 == "Description" ? 3 : 1}
                    maxRow={props.sTextInput2 == "Description" ? 3 : 1}
                    maxLength={props.sTextInput2 == "Description" ? 100 : 50}
                    label={
                      <Typography sx={{ fontWeight: 600 }} component="label">
                        {props.sTextInput2}
                      </Typography>
                    }
                    disabled={props.disabled}
                  />
                </>
              )}
            </>
          </CardContent>
          <CardActions>
            {props.IsSelectNewTab && (
              <FormControlLabel
                control={
                  <IOSSwitchNormal
                    sx={{ m: 1 }}
                    onChange={(e) => onSwitchChange(e, props.nFile_ID)}
                    checked={props.IsNewTab}
                    disabled={props.disabled}
                  />
                }
                label="New Tab"
                sx={{ marginLeft: "0px" }}
              />
            )}
          </CardActions>
        </Card>

        {props.sProgress != 100 && !props.IsComplete && (
          <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
              <LinearProgress
                variant="determinate"
                value={props.sProgress || 100}
              />
            </Box>
            <Box minWidth={35}>
              <Typography variant="body2" color="textSecondary">
                {props.sProgress ? props.sProgress + "%" : ""}
              </Typography>
            </Box>
          </Box>
        )}
      </Grid>
      {IsopenPopUp ? (
        <FilePopup
          file={props}
          SourceCrop={Result ? Result.SourceCrop : ""}
          open={IsopenPopUp}
          Fn={ClosePopUp}
          Result={setResult}
        />
      ) : (
        ""
      )}
    </Fragment>
  );
};

export default itemCard;
