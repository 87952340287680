import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AxiosGet } from "service/CommonFunction/TS_function";
import {
  Grid,
  Card,
  Hidden,
  CardContent,
} from "@mui/material";
import DetailCompany from "components/JobDetail/DetailCompany";
import secureLocalStorage from "react-secure-storage";

const Company = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = location.search;
  const sID = new URLSearchParams(searchParams).get("sID");
  const isFromCompanyID =
    new URLSearchParams(searchParams).get("isFromCompanyID") === "1" || false;

  const [jobPost, setJobPost] = useState<{ [key: string]: any }>({});
  const [companyJob, setcompanyJob] = useState([]);
  const [isPostJob, setPostJob] = useState(false);
  const [lang, setLang] = useState("");
  const onGetDetail = () => {
    if (isFromCompanyID) {
      AxiosGet("JobDetail/GetCompanyDetailByCompID", { sID: sID }, (res) => {
        setJobPost(res);
        onGetCompanyJob();
      });
    } else {
      AxiosGet(
        "JobDetail/detail",
        { sID: sID, isFromCompanyID: isFromCompanyID },
        (res) => {
          setJobPost(res);
        }
      );
    }
  };

  const onGetCompanyJob = () => {
    AxiosGet("JobDetail/GetCompanyDetailByCompID", { sID: sID }, (res) => {
      setcompanyJob(res.lstCompanyJob);
      setPostJob(true)
    });
  }

  useEffect(() => {
    setLang(secureLocalStorage.getItem("language").toString());

    //setLang(window.secureLocalStorage.getItem("language"));
    onGetDetail();
  }, []);

  return (
    <Grid
      container
      sx={{
        border: "1px #eeeeee solid",
        backgroundColor: "rgba(255,255,255,0.75)",
        borderRadius: 5,
        p: 2,
        flex: 1,
      }}
    >
      {/* phone size */}
      <Hidden smUp>
        <Grid item container xs={12} >
          {jobPost.sCompanyFile_Banner != null && jobPost.sCompanyFile_Banner.length > 0 ? (
            <img src={jobPost.sCompanyFile_Banner} style={{ width: "100%", height: "100px" }} />
          ) : (
            ""
          )}

          {/* <img height="700" src={jobPost.sCompanyFile_Logo} alt="" /> */}
        </Grid>
      </Hidden>

      {/* normal size */}
      <Hidden smDown>
        <Grid item container xs={12} >
          {jobPost.sCompanyFile_Banner != null && jobPost.sCompanyFile_Banner.length > 0 ? (
            // <Card sx={{ width: "100%", height: "600px" }}>
            //   <img className="image-companyBanner" src={jobPost.sCompanyFile_Banner} style={{ width: "100%", height: "100%" }} />
            // </Card>

            //ทำเหมือน banner หน้าบ้าน
            // <img src={jobPost.sCompanyFile_Banner} style={{ width: "100%", height: "500px",  objectFit: "cover"}} />
            <img src={jobPost.sCompanyFile_Banner} style={{ width: "100%", minHeight: "32vw", maxHeight: "37vw",  objectFit: "cover"}} />


            // <div className="image-company"
            //   style={{
            //     backgroundImage: `url(${jobPost.sCompanyFile_Banner})`,//รูป
            //   }}
            // />

          ) : (
            ""
          )}
        </Grid>
      </Hidden>
      <Grid item container xs={12}>
        <DetailCompany lstCompanyContent={jobPost.lstCompanyContent} lstCompanyJob={jobPost.lstCompanyJob} isViewJob={true} />
      </Grid>

      {/* {isPostJob ? (
        <Grid item xs={12} marginTop={3}>
          {companyJob != null && companyJob.length > 0 ? companyJob.map((item) => {
            return (
              <Card
                sx={{ my: "2%", borderRadius: "15px", cursor: "pointer", }}
                onClick={() => {
                  navigate("/JobDetail?sID=" + item.sJobPostID);
                }}
              >
                <Grid container sx={{ my: "2%", mx: "2%" }}>
                  <Grid item xs={6}>
                    <Grid sx={{ marginBottom: "4%", fontWeight: 600, fontSize: "18px" }}>
                      {lang == "th" ? item.sJob_TH : item.sJob_EN}
                    </Grid>

                    <Grid sx={{ fontSize: "14px" }}>
                      {lang == "th" ? item.sRegionNameTH : item.sRegionNameEN}
                    </Grid>
                  </Grid>

                  <Grid item xs={6}>
                    <Grid sx={{ marginBottom: "9%" }}>
                    </Grid>
                    <Grid sx={{ color: "#cb3556", fontWeight: 400, fontSize: "14px" }}>
                      THB {item.nSalaryStart} - THB {item.nSalaryEnd}
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            )
          }) : null}
        </Grid>
      ) : null} */}

    </Grid >
  );
};
export default Company;
