import { useState, useEffect, useContext } from "react";
import { FormProvider } from "react-hook-form";
import moment from "moment";
import {
  Grid,
  Typography,
  Box,
  FormControlLabel,
  Checkbox,
  Paper,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { i18n } from "i18n";
import DatePickerFormItem from "components/Common/ElementInForm/DatePickerFormItem";
import Input from "components/Common/ElementInForm/InputCustomLabelFormItem";
import { ProfileContext } from "./Context/ProfileContext";
import { IPorpsMyCertificate } from "./Interface";
import { DialogActionCreators } from "store/redux/DialogAlert";
import { useDispatch } from "react-redux";

const i18nField = "entities.SeekerProfile";
const i18nCommon = "common";
const MyCertificate = (props: IPorpsMyCertificate) => {
  const { item, index, form } = props;
  const dispatch = useDispatch();

  const { certificateContext, setCertificate } = useContext(ProfileContext);

  const [isClean, setIsClean] = useState(false);
  const [isNotExpird, setIsNotExpird] = useState(false);
  const [minDateExpire, setMinDateExpire] = useState(null);

  const handleSetData = (itemIndex) => {
    certificateContext.forEach((data) => {
      if (data.Index === itemIndex) {
        form.setValue("CertName" + data.Index, data.CertName || "");
        form.setValue("ApproveBy" + data.Index, data.ApproveBy || "");
        form.setValue("ApproveDate" + data.Index, data.ApproveDate || "");
        form.setValue("ExpirdDate" + data.Index, data.ExpirdDate || "");
        form.setValue("checkNoExpire" + data.Index, data.IsNotExpird || false);
        form.setValue("CertRemark" + data.Index, data.CertRemark || "");
      }
    });

    setIsClean(false);
  };

  const handleChangeValue = (form, index) => {
    let newData = certificateContext.map((item) => {
      if (item.Index === index) {
        item.CertName = form.getValues("CertName" + index);
        item.ApproveBy = form.getValues("ApproveBy" + index);
        item.ApproveDate = form.getValues("ApproveDate" + index);
        item.ExpirdDate = form.getValues("ExpirdDate" + index);
        item.IsNotExpird = form.getValues("checkNoExpire" + index) || false;
        item.CertRemark = form.getValues("CertRemark" + index);
      }

      return item;
    });
    setCertificate(newData);
  };

  const handleRemoveCertificate = (itemIndex: number) => {
    dispatch(
      DialogActionCreators.OpenDialogSubmit(
        i18n(`${i18nCommon}.AlertConfrimDel`),
        () => {
          dispatch(DialogActionCreators.CloseDialogSubmit() as any);
          dispatch(DialogActionCreators.LoadSubmit("Waiting...") as any);
          setIsClean(true);
          let newData = certificateContext.filter((data) => {
            return data.Index !== itemIndex;
          });
          setCertificate(newData);
          form.unregister("CertName" + itemIndex);
          form.unregister("ApproveBy" + itemIndex);
          form.unregister("ApproveDate" + itemIndex);
          form.unregister("ExpirdDate" + itemIndex);
          form.unregister("checkNoExpire" + itemIndex);
          form.unregister("CertRemark" + itemIndex);
        }
      )
    );
  };

  useEffect(() => {
    handleSetData(item.Index);
  }, [isClean]);

  useEffect(() => {
    handleSetData(item.Index);
    setIsNotExpird(item.IsNotExpird);
  }, [certificateContext]);

  return isClean ? null : (
    <FormProvider {...form} key={index}>
      <Box component={Paper} sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}>
        {index === 0 ? null : (
          <IconButton
            aria-label="delete"
            color="error"
            onClick={() => handleRemoveCertificate(item.Index)}
          >
            <CloseIcon />
          </IconButton>
        )}
        <Grid container justifyContent="center" alignItems="center" spacing={1}>
          <Grid item container spacing={2} xs={12}>
            <Grid item xs={12} sm={6} md={6}>
              <Input
                name={"CertName" + item.Index}
                fullWidth
                label={
                  <Typography sx={{ fontWeight: 400 }} component="label">
                    {i18n(`${i18nField}.CertName`)}
                  </Typography>
                }
                maxLength={200}
                onBlur={(value) => {
                  handleChangeValue(form, item.Index);
                }}
                small
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Input
                name={"ApproveBy" + item.Index}
                fullWidth
                label={
                  <Typography sx={{ fontWeight: 400 }} component="label">
                    {i18n(`${i18nField}.ApproveBy`)}
                  </Typography>
                }
                small
                maxLength={200}
                onBlur={(value) => {
                  handleChangeValue(form, item.Index);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <DatePickerFormItem
                name={"ApproveDate" + item.Index}
                label={
                  <Typography sx={{ fontWeight: 400 }} component="label">
                    {i18n(`${i18nField}.ApproveDate`)}
                  </Typography>
                }
                small={true}
                onChange={(value) => {
                  form.setValue("ApproveDate" + item.Index, value);
                  debugger
                  let dEnd = form.getValues("ExpirdDate" + item.Index);
                  if (dEnd) {
                    let nDiffDate = moment(dEnd).diff(value, "days");
                    if (nDiffDate < 0) {
                      form.setValue("ExpirdDate" + item.Index, value);
                    }
                  }
                  handleChangeValue(form, item.Index);
                  setMinDateExpire(() => value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <DatePickerFormItem
                name={"ExpirdDate" + item.Index}
                minDate={minDateExpire}
                label={
                  <Typography sx={{ fontWeight: 400 }} component="label">
                    {i18n(`${i18nField}.ExpirdDate`)}
                  </Typography>
                }
                small={true}
                disabled={isNotExpird}
                onChange={(value) => {
                  form.setValue("ExpirdDate" + item.Index, value);
                  handleChangeValue(form, item.Index);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    name={"checkNoExpire" + item.Index}
                    checked={isNotExpird}
                    onChange={(e, value) => {
                      setIsNotExpird((prev) => !prev);
                      form.setValue("checkNoExpire" + item.Index, value);
                      form.setValue("ExpirdDate" + item.Index, "");
                      handleChangeValue(form, item.Index);
                    }}
                  />
                }
                label={i18n(`${i18nField}.NotExpird`)}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                name={"CertRemark" + item.Index}
                fullWidth
                multiline
                maxLength={3000}
                label={
                  <Typography sx={{ fontWeight: 400 }} component="label">
                    {i18n(`${i18nField}.CertRemark`)}
                  </Typography>
                }
                rows={4}
                onBlur={(value) => {
                  handleChangeValue(form, item.Index);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </FormProvider>
  );
};

export default MyCertificate;