/* eslint-disable react/jsx-pascal-case */
import { useEffect, useState } from 'react';
import { Modal, Box, Grid } from "@mui/material"
import { FormProvider, useForm } from "react-hook-form"
import Header_Layout from './Header_Layout';
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { AxiosGet, AxiosPost } from 'service/CommonFunction/TS_function'
import { DialogActionCreators } from 'store/redux/DialogAlert'
import { QRCodeSVG } from 'qrcode.react';
import InputCustomLabel from 'components/Common/ElementInForm/InputCustomLabelFormItem'
import { BtnSubmit } from "components/Common/Button"
import yupFormSchemas from 'components/Common/yup/yupFormSchemas'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup";
import { HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import "layout/CommonLayout/Layout_Font/Scroll_Top.css";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import Background_Home from './Background_Home';
import BackgroundBack from "assets/images/bg-long2.png"
import Modal_Forgot from './Modal_Forgot';
import { i18n } from "i18n"
import secureLocalStorage from 'react-secure-storage';
import LoginEmployer from 'view/font/Login/LoginEmployer';
import Cookies from 'js-cookie';

export default function Layout_Font(props: any) {
    const i18nField = 'entities.LayoutFront';
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const [sUser, setUser] = useState<any>();
    const ischeckUser = Boolean(sUser);
    const [isShow2Fa, setIsShow2Fa] = useState(false)
    const [nFocusMenu, setFocusMenu] = useState(secureLocalStorage.getItem("sFocusIndex") && secureLocalStorage.getItem("sFocusIndex") != "" ? parseInt(secureLocalStorage.getItem("sFocusIndex") + "") : 0)
    const [objUserData, setUserData] = useState(null);
    const [sUserName, setUserName] = useState<any>();
    const [isCheck, setLogout] = useState<Boolean>(false);
    const [isLoadMenu, SetisLoadMenu] = useState<Boolean>(false);
    const [lstMenu, setMenu] = useState<any[]>([]);
    const AuthenController = 'Authen/'
    const [isOpenForgot, setOpenForgot] = useState<boolean>(false)
    const [isFormJobSeeker, setIsFormJobSeeker] = useState<boolean>(true);
    const [isMemberCompany, setIsMemberCompany] = useState<boolean>(false);
    const [isLoadingSentForgot, setIsLoadingSendForgot] = useState<boolean>(false);
    const handleClose2Fa = () => {
        dispatch(DialogActionCreators.OpenDialogSubmit("Do you want to exit ?", () => {
            dispatch(DialogActionCreators.CloseDialogSubmit() as any);
            setIsShow2Fa(false)
            setUserData(null)

            window.location.reload();
            // navigate({ pathname: "/" }, { replace: true });
        }) as any)
    }

    //Scroll
    const [showScrollToTop, setShowScrollToTop] = useState(false);
    const objSchema = {
        f_verifycode: yupFormSchemas.string("Verify Code", { required: false, max: 6, matches: /^[0-9]+$/ }),
    }
    const schema = yup.object().shape(objSchema);
    const formResolver = yupResolver(schema)
    const formVerify = useForm({
        resolver: formResolver,
        shouldUnregister: false,
        shouldFocusError: true,
        mode: "all",
    });

    const onSubmitForgot = (e) => {
        setIsLoadingSendForgot(true)
        AxiosPost(`${AuthenController}SendRequestForgotPw`, { isJobSeeker: isFormJobSeeker, isMember: isMemberCompany, ...e }, (res) => {

             dispatch(DialogActionCreators.OpenDialogSuccess(i18n(`${i18nField}.forgot_success`), () => {
                setOpenForgot(false)
            }) as any)
        }, err => {
            dispatch(DialogActionCreators.OpenDialogWarning(err.Message) as any)
        }, () => {
            setIsLoadingSendForgot(false)
        })
    }

    useEffect(() => {
        onLoadMenu();
    }, [isLoadMenu])

    const connection = new HubConnectionBuilder()
        .withUrl(process.env.REACT_APP_API_URL + "hubs/auth")
        .withAutomaticReconnect()
        .build();

    //2FA
    const _onSubmitVerifyCode = (e) => {
        // AxiosPost("Authen/Verify2FA", { sAuthenCode: e.f_verifycode, sUserKeys: objUserData.sUserId }, (res) => {
        //     // let localJwtKey = process.env.BPS_JOB_BOARD_PROJECT as string;
        //     // let sToken = localStorage.getItem(localJwtKey)
        //     let sToken = localStorage.getItem(
        //         process.env.BPS_JOB_BOARD_PROJECT as string
        //     );
        //     setUserName(res.sFullName);
        //     if (sToken) {
        //         localStorage.clear();
        //     }
        //     // setUserName(res.sFullName);
        //     sessionStorage.clear();
        //     localStorage.setItem("sUserId", res.sUserId)
        //     localStorage.setItem("sUserFullName", res.sFullName)
        //     localStorage.setItem("sUserNickName", res.sNickName)
        //     localStorage.getItem(process.env.BPS_JOB_BOARD_PROJECT as string)
        //     setIsShow2Fa(false)

        //     if (connection.state != HubConnectionState.Connected) {
        //         connection
        //             .start()
        //             .then(() => {
        //                 if (!localStorage.getItem("connectionID_bps")) {
        //                     localStorage.setItem("connectionID_bps", connection.connectionId);
        //                 }
        //                 connection.send("OnSignOutSignal", localStorage.getItem("connectionID_bps"), true)
        //             })
        //             .catch((e) => {
        //                 console.log("Connection failed: ", e);
        //             });
        //     } else {
        //         if (!localStorage.getItem("connectionID_bps")) {
        //             localStorage.setItem("connectionID_bps", connection.connectionId);
        //         }
        //         connection.send("OnSignOutSignal", localStorage.getItem("connectionID_bps"), true)
        //         navigate({ pathname: "/" })
        //     }
        // }, (e) => {
        //     dispatch(DialogActionCreators.OpenDialogWarning(e.Message, () => {
        //         navigate({ pathname: "/" }, { replace: true })
        //     }) as any)
        // })
    }

    const onLoadMenu = () => {
        AxiosGet("MenuBPS/SearchData_FrontMenuBPS", {}, (res) => {
            if (res.lstMenu && res.lstMenu.length > 0) {
                setMenu([...res.lstMenu])
               // Cookies.set("lstMenu", res.lstMenu);
                secureLocalStorage.setItem("lstMenuAll", res.lstMenu);
                secureLocalStorage.setItem("lstMenu", res.lstMenu.sUrl[0])
            } else {
                setMenu([])
            }
            secureLocalStorage.setItem("sNameTH", JSON.stringify(res.lstMenu));
        }, (e) => {
            dispatch(DialogActionCreators.OpenDialogWarning(e.Message) as any);
        })
    }

    const styles = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        borderRadius: "10px",
        p: 2,
    };

    const stylesCustom = {
        paperContainer: {
            backgroundImage: `url(${BackgroundBack})`
        },
    }

    const handleScroll = (event) => {
        setShowScrollToTop(
            event.currentTarget.scrollTop - 70 > window.innerHeight
        );
    };

    return (
        <Grid onScroll={handleScroll} container className="h-screen overflow-x-hidden flex flex-row gap-[16px]" sx={{ backgroundColor: "#fafafb" }}>
            {/* Modal 2FA */}
            <Modal
                open={isShow2Fa}
                onClose={handleClose2Fa}
                closeAfterTransition
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Box sx={{ ...styles, width: 300 }}>
                    <h2 className="w-full text-center mb-[10px] font-[500] text-[1.3em]" >
                        Scan here
                    </h2>
                    <div className="w-full flex flex-row justify-center items-center">
                        {objUserData != null && <QRCodeSVG value={objUserData.sUrlQrCode} />}
                    </div>
                    <div className="mt-[10px]" />
                    <FormProvider {...formVerify}>
                        <div className="flex flex-row gap-[0.5em] ">
                            <InputCustomLabel maxLength={6} small name="f_verifycode" label={<label>Verify Code</label>} />
                            <BtnSubmit isCircleWithOutText onClick={formVerify.handleSubmit(_onSubmitVerifyCode)} />
                        </div>
                    </FormProvider>
                </Box>
            </Modal>
            <Modal_Forgot
                isOpen={isOpenForgot}
                setOpen={setOpenForgot}
                funcSubmitForgot={onSubmitForgot}
                isJobSeeker={isFormJobSeeker}
                isLoading={isLoadingSentForgot} />
            <div id="scroll_Top" className="w-full m-[0px] min-h-full">
                <div className="flex flex-col">
                    <Header_Layout
                        FocusMenu={nFocusMenu}
                        connection={connection}
                        setFocusMenu={setFocusMenu}
                        lstMenu={lstMenu}
                        SetisLoadMenu={SetisLoadMenu}
                        setOpenForgot={() => setOpenForgot(true)}
                        setIsFormJobSeeker={setIsFormJobSeeker}
                        isFromJobSeeker={isFormJobSeeker}
                        setIsMemberCompany={setIsMemberCompany}
                        isMemberCompany={isMemberCompany}
                    />
                    <div style={stylesCustom.paperContainer} className="box-img">
                        <Background_Home {...props} />
                        {/* {localStorage.getItem(process.env.BPS_JOB_BOARD_PROJECT as string) ?
                            <Background_Home children={props.children}/> :
                            null
                        } */}
                        {/* <div className="bg-white min-h-[calc(100vh-70px)]">
                            {props.children}
                        </div> */}
                    </div>
                </div>
            </div>
            <div id="SCROLL">
                {showScrollToTop ? (
                    <a
                        onClick={() =>
                            document.getElementById("scroll_Top").scrollIntoView({
                                behavior: "smooth",
                            })
                        }
                        className="btn-scroll"
                    >
                        <div className="scroll-arrow">
                            <ArrowUpwardIcon />
                        </div>
                    </a>
                ) : null}
            </div>
        </Grid >
    );
}