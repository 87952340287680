import { useState, useEffect, Fragment } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { DialogActionCreators } from "store/redux/DialogAlert";
import {
  Stack,
  Typography,
  Divider,
  Button,
  Grid,
  Skeleton,
  Card,
  FormControlLabel,
  Checkbox,
  FormGroup,
  FormControl,
  FormHelperText,
  Hidden,
} from "@mui/material";
import Input from "components/Common/ElementInForm/InputCustomLabelFormItem";
import UploadFile from "components/Common/ElementInForm/UploadFile/UploadFile";
import { Extension } from "service/CommonFunction/TS_function";
import { BtnBack, BtnSubmit } from "components/Common/Button";
import { useDispatch } from "react-redux";
import {
  AxiosPost,
  AxiosGet,
} from "service/CommonFunction/TS_function";
import {
  dataContent,
  saveDataContent,
} from "components/Banner/AdminContent/ManageContent";
import { i18n, getLanguage } from "i18n";
import ChangePassword from "../Login/ChangePassword";
import AutoCompleteSelect from "components/Common/ElementInForm/AutoCompleteSelect";
import yupFormSchemas from "components/Common/yup/yupFormSchemas";
import BoxContent from "./BoxContent";
import secureLocalStorage from "react-secure-storage";

export default function SetupTemplate() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useNavigate();
  const searchParams = location.search;
  const sID = new URLSearchParams(searchParams).get("sID");

  const [isLoading, setLoading] = useState(true);

  const i18nLabel = "SetupTemplate.label";
  const i18nField = "RegisterEmployer";
  const i18nCommon = "common";

  const isEditMode = sID ? true : false;
  const [lstOrder, setLstOrder] = useState<number[]>([1]);
  const [arrFileLogo, setarrFileLogo] = useState([]);
  const [IsInter, setIsInter] = useState<boolean>(false);
  const [ProvinceParent, setProvinceParent] = useState(0);
  const [rawIndustry, setRawIndustry] = useState<any>([]);
  const [rawCountry, setRawCountry] = useState<any>([]);
  const [rawRegion, setRawRegion] = useState<any>([]);
  const [rawProvince, setRawProvince] = useState<any>([]);
  const [isEmailDup, setIsEmailDup] = useState(false);
  const [isEmployerDup, setIsEmployerDup] = useState(false);
  const [arrFileBanner, setarrFileBanner] = useState([]);
  const [isDelete, setIsDelete] = useState<boolean>(false)
  const [objStateContent, setObjStateContent] = useState({
    nTypeContent_1: 1,
  });
  const [lstComponent, setLstComponent] = useState<dataContent[]>([]);
  const [isAddAction, setIsAddAction] = useState(false);
  const [isChangeOrder, setIsChangeOrder] = useState(false);
  const [isCheckedConsent1, SetIsCheckedConsent1] = useState<boolean>(true);
  const [errorConsent, setErrorConsent] = useState(false);
  const [OpenChangePass, setOpenChangePass] = useState(false);
  const [initialValues] = useState(() => {
    return {
      lstTag: [],
    };
  });
  const [nPermission, setPermission] = useState(2);
  const [objSchemaBox, setObjSchemaBox] = useState({});
  const lang = (secureLocalStorage.getItem("language").toString() || "th");

  //Yup validate
  const objSchema = {
    sEmail: yupFormSchemas.string(i18n(`${i18nField}.Email`), {
      required: true,
    }),
    sRegisterName: yupFormSchemas.string(i18n(`${i18nField}.RegisterName`), {
      required: true,
    }),
    sComponyName: yupFormSchemas.string(i18n(`${i18nField}.CompanyName`), {
      required: true,
    }),
    sTel: yupFormSchemas.string(i18n(`${i18nField}.Phone`), {
      required: true,
    }),
    nCountryID: yupFormSchemas.object(i18n(`${i18nField}.Country`), {
      required: true,
    }),
  };

  //Yup object
  const schema = yup.object().shape(objSchema);
  const AuthenController = 'Authen/'
  const form = useForm({
    resolver: yupResolver(schema),
    shouldUnregister: false,
    shouldFocusError: true,
    mode: "all",
    defaultValues: initialValues as any,
  });

  const onGetIndustry = () => {
    let lang = "";
    let isTH = (getLanguage().id === "th");
    isTH ? lang = "th" : lang = "en"
    AxiosGet(`DataFilter/SetFilter_SelectIndustrytype`, { lang }, (res) => {
      setRawIndustry(res.lstOptionAdmin || []);
    });
  };

  const onGetCountry = () => {
    let lang = "";
    let isTH = (getLanguage().id === "th");
    isTH ? lang = "th" : lang = "en"
    AxiosGet(`DataFilter/SetFilter_SelectCountry`, { lang }, (res) => {
      setRawCountry(res.lstOptionAdmin || []);

      form.setValue('nCountryID', res.lstThailand[0]);
    });
  };

  const onGetRegion = () => {
    let lang = "";
    let isTH = (getLanguage().id === "th");
    isTH ? lang = "th" : lang = "en"
    AxiosGet(`DataFilter/SetFilter_SelectRegion`, { lang }, (res) => {
      setRawRegion(res.lstOptionAdmin.filter((f) => f.value != 1779));
    });
  };

  const onGetProvince = () => {
    let lang = "";
    let isTH = (getLanguage().id === "th");
    isTH ? lang = "th" : lang = "en"
    AxiosGet(`DataFilter/SetFilter_SelectProvince`, { lang }, (res) => {
      setRawProvince(res.lstOptionAdmin);
    });
  };

  const CheckExistMail = (value) => {
    const param = {
      sEmail: value,
      // isCompanySetting: true
    };
    AxiosPost("Guest/CheckAlreadyMailEmployer", param, (res) => {
      const data = res.Data;
      if (data.IsAlready) {
        setIsEmailDup(true);
        form.setError("Email", { type: "custom" });
      } else {
        setIsEmailDup(false);
        // form.clearErrors();
        form.clearErrors("Email");
      }
    });
  };

  const CheckExistEmployerName = (value) => {
    const param = {
      sCompany_Name: value,
    };
    AxiosPost("Guest/GetAlreadyEmployerName", param, (res) => {
      const data = res.Data;
      if (data.IsAlready) {
        setIsEmployerDup(true);
        form.setError("Company Name", { type: "custom" });
      } else {
        setIsEmployerDup(false);
        // form.clearErrors();
        form.clearErrors("Company Name");
      }
    });
  };

  const onGetDetail = () => {
    let lang = "";
    let isTH = (getLanguage().id === "th");
    isTH ? lang = "th" : lang = "en"

    let param = {
      lang: lang,
      sID: sID
    }

    AxiosPost(
      "SetupTemplate/detail",
      param,
      (res) => {
        if (res.Status === 200) {
          form.setValue("sEmail", res.lstEmpDetail[0].sEmail);
          form.setValue("sRegisterName", res.lstEmpDetail[0].sRegisterName);
          form.setValue("sComponyName", res.lstEmpDetail[0].sComponyName);
          form.setValue("sTel", res.lstEmpDetail[0].sTel);
          form.setValue("sPostCode", res.lstEmpDetail[0].sPostCode);
          form.setValue("sStreet", res.lstEmpDetail[0].sStreet);
          form.setValue("sMoo", res.lstEmpDetail[0].sMoo);
          form.setValue("sAddressNo", res.lstEmpDetail[0].sAddressNo);

          //dropdown
          form.setValue("nIndustryTypeID", res.objIndustryType);
          form.setValue("nCountryID", res.objCountry);
          form.setValue("nRegionID", res.objRegion || 0);
          form.setValue("nProvinceID", res.objProvince);

          //set province for filter region
          setProvinceParent(form.getValues("nRegionID") ? form.getValues("nRegionID").value : 0);

          if (res.lstDataContent.length > 0) {
            let lstTmpComponent: dataContent[] = [];
            let lstOrder = [];
            res.lstDataContent.forEach((e, i) => {
              objStateContent[`nTypeContent_${i + 1}`] = e.nTypeID;
              if (e.nTypeID === 2 || e.nTypeID === 3) {
                e.fImageOrVideo.sFileType =
                  e.fImageOrVideo.sSysFileName.split(".")[1];
                objStateContent[
                  `${e.nTypeID === 2 ? "fFileImg_" : "fFileVideo_"}${i + 1}`
                ] = [e.fImageOrVideo];
              }
              let dataComponent: dataContent = {
                nType: e.nTypeID,
                fFileImageOrVideo:
                  e.nTypeID === 2 || e.nTypeID === 3 ? [e.fImageOrVideo] : [],
                // sUrl: e.sUrl,
                sContent: e.sContent,
                nOrder: i + 1,
                id: i + 1
              };
              switch (e.nTypeID) {
                case 1:
                  objStateContent[`sContentOnly_${i + 1}`] = e.sContent;
                  break;
                case 2:
                  objStateContent[`sContentBottomImg_${i + 1}`] = e.sContent;
                  break;
                case 3:
                  objStateContent[`sContentBottomVideo_${i + 1}`] = e.sContent;
                  break;
                case 4:
                  objStateContent[`sUrl_${i + 1}`] = e.sUrl;
                  objStateContent[`sContentBottomUrl_${i + 1}`] = e.sContent;
                  break;
              }
              setObjStateContent({ ...objStateContent });
              lstTmpComponent.push(dataComponent);
              lstOrder.push(i + 1);
            });
            setLstComponent(lstTmpComponent);

            setLstOrder(lstOrder);

            setIsChangeOrder(true);

            setarrFileLogo([...res.lstFileLogo] || []);
            setarrFileBanner([...res.lstFileBanner] || []);
          } else {
            setLstComponent([
              {
                nType: 1,
                sContent: "",
                fFileImageOrVideo: [],
                id: 1,
                nOrder: 1,
              },
            ]);
          }

          if (res.objCountry.value === "519") {
            setIsInter(false);
          } else {
            setIsInter(true);
          }

          //dropdown
          setRawIndustry(res.lstIndustryType);
          setRawCountry(res.lstCountry);
          setRawRegion(res.lstRegion);
          setRawProvince(res.lstProvince);

          SetIsCheckedConsent1(res.lstEmpDetail[0].isGetNews);
        } else {
          dispatch(
            DialogActionCreators.OpenDialogWarning("Data not found.") as any
          );
        }
        setLoading(false);
      },
      (err) => {
        if (err.response && err.response.status === 401) {
          dispatch(
            DialogActionCreators.OpenDialogWarning("Token expired.", () => {
              history("/");
            }) as any
          );
        }
      }
    );
  };

  useEffect(() => {
    onGetDetail();
    onGetIndustry();
    onGetCountry();
    onGetRegion();
    onGetProvince();
  }, []);

  const onSubmit = (e) => {
    if (isEmailDup) {
      form.setError("Email", { type: "custom" });
      return;
    } else if (isEmployerDup) {
      form.setError("Company Name", { type: "custom" });
      return;
    }

    dispatch(
      DialogActionCreators.OpenDialogSubmit(
        i18n(`${i18nCommon}.AlertConfrimSave`),
        () => {
          dispatch(DialogActionCreators.CloseDialogSubmit() as any);
          let lstDataContent: saveDataContent[] = [];
          lstComponent.forEach((f, i) => {
            let objDataContent: saveDataContent = {
              nOrder: i + 1,
              nTypeID: objStateContent[`nTypeContent_${f.id}`],
            };
            switch (objStateContent[`nTypeContent_${f.id}`]) {
              case 1:
                objDataContent.sContent =
                  objStateContent[`sContentOnly_${f.id}`];
                break;
              case 2:
                objDataContent.fImageOrVideo =
                  objStateContent[`fFileImg_${f.id}`][0];
                objDataContent.sContent = e[`sTextBottomImage_${f.id}`];
                break;
              case 3:
                objDataContent.fImageOrVideo =
                  objStateContent[`fFileVideo_${f.id}`][0];
                objDataContent.sContent = e[`sTextBottomVideo_${f.id}`];
                break;
              case 4:
                objDataContent.sContent = e[`sTextBottomUrl_${f.id}`];
                break;
            }
            lstDataContent.push(objDataContent);
          });

          //ประกาศตัวแปร UpFile1
          let lstFileLogo = [];
          var objDataFileLogo = {};
          //เช็คค่า
          if (arrFileLogo.length > 0) {
            objDataFileLogo = {
              sFileName: arrFileLogo[0].sFileName + "",
              sSysFileName: arrFileLogo[0].sSysFileName + "",
              sURL: arrFileLogo[0].sFileLink + "",
              sFolderName: arrFileLogo[0].sFolderName + "",
              sRootURL: arrFileLogo[0].sRootURL + "",
              IsNew: arrFileLogo[0].IsNew,
              IsDelete: false,
              // uploadID: 1
            };
            lstFileLogo.push(objDataFileLogo);
          } else {
            objDataFileLogo = {
              sFileName: null,
              sSysFileName: null,
              sURL: null,
              sFolderName: null,
              sRootURL: null,
              IsNew: false,
              IsDelete: true,
              // uploadID: 1
            };
          }

          //ประกาศตัวแปร UpFile2
          let lstFileBanner = [];
          var objDataFileBanner = {};
          //เช็คค่า
          if (arrFileBanner.length > 0) {
            objDataFileBanner = {
              sFileName: arrFileBanner[0].sFileName + "",
              sSysFileName: arrFileBanner[0].sSysFileName + "",
              sURL: arrFileBanner[0].sFileLink + "",
              sFolderName: arrFileBanner[0].sFolderName + "",
              sRootURL: arrFileBanner[0].sRootURL + "",
              IsNew: arrFileBanner[0].IsNew,
              IsDelete: false,
              // uploadID: 2
            };
            lstFileBanner.push(objDataFileBanner);
          } else {
            objDataFileBanner = {
              sFileName: null,
              sSysFileName: null,
              sURL: null,
              sFolderName: null,
              sRootURL: null,
              IsNew: false,
              IsDelete: true,
              // uploadID: 2
            };
          }

          let objSaveData = {
            sID: isEditMode ? sID : "",
            lstFileLogo: lstFileLogo,
            lstFileBanner: lstFileBanner,
            lstDataContent: lstDataContent,
            isGetNews: isCheckedConsent1,
            // checkBanner: lstFileBanner == null ? 0 : 2,
            // checkLogo: lstFileLogo == null ? 0 : 1,
            // uploadID: lstFileBanner ? 2 : 1,

            nCountryID: +form.getValues("nCountryID").value,
            nRegionID: +form.getValues("nRegionID").value || 0,
            nProvinceID: form.getValues("nProvinceID") ? +form.getValues("nProvinceID").value : 0,
            sStreet: form.getValues("sStreet") || null,
            sMoo: form.getValues("sMoo") || null,
            sAddressNo: form.getValues("sAddressNo") || null,
            sPostCode: form.getValues("sPostCode") || null,
            nIndustryTypeID: +(form.getValues("nIndustryTypeID").value) || 0,
            sRegisterName: form.getValues("sRegisterName") || null,//e["sRegisterName"]
            sComponyName: form.getValues("sComponyName") || null,//e["sComponyName"]
            sEmail: form.getValues("sEmail") || null,//e["sEmail"],
            sTel: form.getValues("sTel") || null,//e["sTel"],            
          };
          AxiosPost(
            "SetupTemplate/save",
            objSaveData,
            (res) => {
              if (res.Status === 200) {
                dispatch(
                  DialogActionCreators.OpenDialogSuccess(
                    i18n(`${i18nCommon}.msgAlertSaveSuccess`),
                    () => {
                      window.location.reload();
                    }
                  ) as any
                );
              } else {
                dispatch(
                  DialogActionCreators.OpenDialogWarning(res.Message) as any
                );
              }
            },
            (err) => {
              if (!err.response) {
                history("/error", null);
                return;
              }
              if (err.response && err.response.status === 401) {
                dispatch(
                  DialogActionCreators.OpenDialogWarning(
                    "Token expired.",
                    () => {
                      history("/");
                    }
                  ) as any
                );
              }
            }
          );
        }
      )
    );

  };

  const onSubmitPassword = (e) => {
    AxiosPost(`${AuthenController}ChangePassword`, { ...e }, (res) => {
      if (res.Status === 200) {
        dispatch(DialogActionCreators.OpenDialogSuccess(i18n(`${i18nCommon}.msgAlertSaveSuccess`), () => { //save success
          setOpenChangePass(false)
        }) as any)
      } else {
        dispatch(
          DialogActionCreators.OpenDialogWarning(i18n(`${i18nCommon}.msgAlertDataNotFound`)) as any //Data not found.
        );
      }
    }, err => {
      if (err.Status === 409) {
        dispatch(DialogActionCreators.OpenDialogWarning(
          i18n(`${i18nCommon}.msgAlertIncorrectPassword`), () => { //password incorrect
          }) as any)
      } else {
        dispatch(DialogActionCreators.OpenDialogWarning(err.Message) as any)
      }
    }, () => {
      // setIsLoadingSendForgot(false)
    })
  }

  const onBack = () => {
    history("/MyPageEmployer");
  };

  return (
    <FormProvider {...form}>
      <Card sx={{
        padding: "1em",
        borderRadius: "1em",
        position: "relative",
        marginTop: "6px"
        // background: "white"
      }}>
        {/* <Grid container className="fade-bg-color"> */}

        <Grid container>
          <Grid item xs={12} sx={{ mx: "2%" }}>
            <Grid item>
              <Grid item marginBottom={3}>
                {/* <Box sx={{ marginLeft: "86%" }}> */}
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="flex-start"
                >
                  <Button
                    variant="contained"
                    onClick={() => setOpenChangePass(true)}
                    sx={{ fontSize: "12px", borderRadius: "20px" }}
                  // txt="Reset Passetword"
                  >{i18n(`${i18nLabel}.ChangePassword`)}</Button> {/*change password*/}
                </Grid>
                {/* </Box> */}
                <ChangePassword
                  isOpen={OpenChangePass}
                  setOpen={setOpenChangePass}
                  funcSubmitPass={onSubmitPassword}
                  isJobSeeker={undefined}
                  isLoading={undefined}
                />

                <Grid item md={12} xs={12} sx={{ marginTop: "3%" }}>
                  <Input
                    name={"sEmail"}
                    labelString={i18n(`${i18nField}.Email`)}
                    required
                    fullWidth
                    onBlur={(value) => {
                      CheckExistMail(value.target.value);
                    }}
                    label={
                      <>
                        <Typography sx={{ fontWeight: 400 }} component="label">
                          {i18n(`${i18nField}.Email`)}
                        </Typography>
                      </>
                    }
                  />
                  {isEmailDup ? (
                    <FormHelperText style={{ color: "red" }}>
                      {i18n(`${i18nField}.ErrorEmail`)}
                    </FormHelperText>
                  ) : null}
                </Grid>

                <Grid item md={12} xs={12} sx={{ marginTop: "3%" }}>
                  <Input
                    name={"sRegisterName"}
                    labelString={i18n(`${i18nField}.RegisterName`)}
                    required
                    fullWidth
                    label={
                      <>
                        <Typography sx={{ fontWeight: 400 }} component="label">
                          {i18n(`${i18nField}.RegisterName`)}
                        </Typography>
                      </>
                    }
                  />
                </Grid>

                <Grid item md={12} xs={12} sx={{ marginTop: "3%" }}>
                  <Input
                    name={"sComponyName"}
                    labelString={i18n(`${i18nField}.CompanyName`)}
                    required
                    fullWidth
                    onBlur={(value) => {
                      CheckExistEmployerName(value.target.value);
                      // CheckExist(value.target.value);
                    }}
                    label={
                      <>
                        <Typography sx={{ fontWeight: 400 }} component="label">
                          {i18n(`${i18nField}.CompanyName`)}
                        </Typography>
                      </>
                    }
                  />
                  {isEmployerDup ? (
                    <FormHelperText style={{ color: "red" }}>
                      {i18n(`${i18nField}.ErrorEmployer`)}
                    </FormHelperText>
                  ) : null}
                </Grid>

                <Grid item md={12} xs={12} sx={{ marginTop: "3%" }}>
                  <AutoCompleteSelect
                    required
                    fullWidth
                    name={"nIndustryTypeID"}
                    label={i18n(`${i18nField}.Industry`)}
                    options={rawIndustry}
                    onChange={(v) => {
                      if (v && v.value) {
                        form.setValue('nIndustryTypeID', v)
                      }
                    }}
                  />
                </Grid>

                {/* Tel */}
                <Grid item md={12} xs={12} sx={{ marginTop: "3%" }}>
                  <Input
                    name={"sTel"}
                    labelString={i18n(`${i18nField}.Phone`)}
                    required
                    fullWidth
                    label={
                      <>
                        <Typography sx={{ fontWeight: 400 }} component="label">
                          {i18n(`${i18nField}.Phone`)}
                        </Typography>
                      </>
                    }
                  />
                </Grid>

                {/* Address */}
                {/* phone size */}
                <Hidden smUp>
                  <Grid item md={12} xs={12}>
                    <Grid item container xs={12}>
                      <Grid item md={4} xs={12} sx={{ marginTop: "3%" }}>
                        <AutoCompleteSelect
                          required
                          fullWidth
                          name={"nCountryID"}
                          label={i18n(`${i18nField}.Country`)} //country
                          options={rawCountry}
                          onChange={(v) => {
                            if (v && v.value !== '519') {
                              setIsInter(true);
                              form.clearErrors("nRegionID");
                              form.clearErrors("nProvinceID");
                              form.setValue("nRegionID", 0);
                              form.setValue("nProvinceID", 0);
                            } else {
                              setIsInter(false);
                            }
                            form.setValue('nCountryID', v)
                          }}
                        />
                      </Grid>
                      {IsInter ?
                        null : (
                          <>
                            <Grid item md={4} xs={12} sx={{ marginTop: "3%" }}>
                              <AutoCompleteSelect
                                fullWidth
                                name={"nRegionID"}
                                label={i18n(`${i18nField}.Region`)} //region
                                options={rawRegion}
                                onChange={(v) => {
                                  if (v && v.value !== "nRegionID") {
                                    form.clearErrors("nProvinceID");
                                    form.setValue("nProvinceID", 0);
                                  } else {
                                    form.clearErrors("nProvinceID");
                                    form.setValue('nProvinceID', v ? v.value : 0);
                                  }
                                  setProvinceParent(v ? v.value : 0);

                                }}
                              />
                            </Grid>
                            <Grid item md={4} xs={12} sx={{ marginTop: "3%" }}>
                              <AutoCompleteSelect
                                fullWidth
                                name={"nProvinceID"}
                                label={i18n(`${i18nField}.Province`)} //province
                                options={rawProvince.filter(
                                  (f) => f.sParent == ProvinceParent
                                )}
                                onChange={(v) => {
                                  form.setValue("nProvinceID", v ? v : null);
                                }}
                              />
                            </Grid>
                          </>)}
                    </Grid>
                  </Grid>
                </Hidden>

                {/* normal size */}
                <Hidden smDown>
                  <Grid item md={12} xs={12} sx={{ marginTop: "3%" }}>
                    <Grid item container spacing={2} xs={12}>
                      <Grid item md={4} xs={12}>
                        <AutoCompleteSelect
                          required
                          fullWidth
                          name={"nCountryID"}
                          label={i18n(`${i18nField}.Country`)} //country
                          options={rawCountry}
                          onChange={(v) => {
                            if (v && v.value !== '519') {
                              setIsInter(true);
                              form.clearErrors("nRegionID");
                              form.clearErrors("nProvinceID");
                              form.setValue("nRegionID", 0);
                              form.setValue("nProvinceID", 0);
                            } else {
                              setIsInter(false);
                            }
                            form.setValue('nCountryID', v)
                          }}
                        />
                      </Grid>
                      {IsInter ?
                        null : (
                          <>
                            <Grid item md={4} xs={12}>
                              <AutoCompleteSelect
                                fullWidth
                                name={"nRegionID"}
                                label={i18n(`${i18nField}.Region`)} //region
                                options={rawRegion}
                                onChange={(v) => {
                                  if (v && v.value !== "nRegionID") {
                                    form.clearErrors("nProvinceID");
                                    form.setValue("nProvinceID", 0);
                                  } else {
                                    form.clearErrors("nProvinceID");
                                    form.setValue('nProvinceID', v ? v.value : 0);
                                  }
                                  setProvinceParent(v ? v.value : 0);

                                }}
                              />
                            </Grid>
                            <Grid item md={4} xs={12}>
                              <AutoCompleteSelect
                                fullWidth
                                name={"nProvinceID"}
                                label={i18n(`${i18nField}.Province`)} //province
                                options={rawProvince.filter(
                                  (f) => f.sParent == ProvinceParent
                                )}
                                onChange={(v) => {
                                  form.setValue("nProvinceID", v ? v : null);
                                }}
                              />
                            </Grid>
                          </>)}
                    </Grid>
                  </Grid>
                </Hidden>

                {/* phone size */}
                <Hidden smUp>
                  <Grid item md={12} xs={12}>
                    <Grid item container xs={12}>
                      {IsInter ?
                        null : (
                          <>
                            <Grid item md={2} xs={12} sx={{ marginTop: "3%" }}>
                              <Input
                                fullWidth
                                name={"sPostCode"}
                                label={
                                  <>
                                    <Typography sx={{ fontWeight: 400 }} component="label">
                                      {i18n(`${i18nField}.PostCode`)}
                                    </Typography>
                                  </>
                                }
                                // label={i18n(`${i18nField}.PostCode`)} //PostCode
                                maxLength={5}
                              />
                            </Grid>
                            <Grid item md={6} xs={12} sx={{ marginTop: "3%" }}>
                              <Input
                                fullWidth
                                name={"sStreet"}
                                label={
                                  <>
                                    <Typography sx={{ fontWeight: 400 }} component="label">
                                      {i18n(`${i18nField}.Street`)}
                                    </Typography>
                                  </>
                                }
                                // label={i18n(`${i18nField}.Street`)} //street
                                maxLength={500}
                              />
                            </Grid>
                            <Grid item md={2} xs={12} sx={{ marginTop: "3%" }}>
                              <Input
                                fullWidth
                                name={"sMoo"}
                                label={
                                  <>
                                    <Typography sx={{ fontWeight: 400 }} component="label">
                                      {i18n(`${i18nField}.Moo`)}
                                    </Typography>
                                  </>
                                }
                                // label={i18n(`${i18nField}.Moo`)} //moo
                                maxLength={3}
                              />
                            </Grid>
                            <Grid item md={2} xs={12} sx={{ marginTop: "3%" }}>
                              <Input
                                fullWidth
                                name={"sAddressNo"}
                                label={
                                  <>
                                    <Typography sx={{ fontWeight: 400 }} component="label">
                                      {i18n(`${i18nField}.AddressNo`)}
                                    </Typography>
                                  </>
                                }
                                // label={i18n(`${i18nField}.AddressNo`)} //address no.
                                maxLength={20}
                              />
                            </Grid>
                          </>)}
                    </Grid>
                  </Grid>
                </Hidden>

                {/* normal size */}
                <Hidden smDown>
                  <Grid item md={12} xs={12} sx={{ marginTop: "3%" }}>
                    <Grid item container spacing={2} xs={12}>
                      {IsInter ?
                        null : (
                          <>
                            <Grid item md={2} xs={12}>
                              <Input
                                fullWidth
                                name={"sPostCode"}
                                label={
                                  <>
                                    <Typography sx={{ fontWeight: 400 }} component="label">
                                      {i18n(`${i18nField}.PostCode`)}
                                    </Typography>
                                  </>
                                }
                                // label={i18n(`${i18nField}.PostCode`)} //PostCode
                                maxLength={5}
                              />
                            </Grid>
                            <Grid item md={6} xs={12}>
                              <Input
                                fullWidth
                                name={"sStreet"}
                                label={
                                  <>
                                    <Typography sx={{ fontWeight: 400 }} component="label">
                                      {i18n(`${i18nField}.Street`)}
                                    </Typography>
                                  </>
                                }
                                // label={i18n(`${i18nField}.Street`)} //street
                                maxLength={500}
                              />
                            </Grid>
                            <Grid item md={2} xs={12}>
                              <Input
                                fullWidth
                                name={"sMoo"}
                                label={
                                  <>
                                    <Typography sx={{ fontWeight: 400 }} component="label">
                                      {i18n(`${i18nField}.Moo`)}
                                    </Typography>
                                  </>
                                }
                                // label={i18n(`${i18nField}.Moo`)} //moo
                                maxLength={3}
                              />
                            </Grid>
                            <Grid item md={2} xs={12}>
                              <Input
                                fullWidth
                                name={"sAddressNo"}
                                label={
                                  <>
                                    <Typography sx={{ fontWeight: 400 }} component="label">
                                      {i18n(`${i18nField}.AddressNo`)}
                                    </Typography>
                                  </>
                                }
                                // label={i18n(`${i18nField}.AddressNo`)} //address no.
                                maxLength={20}
                              />
                            </Grid>
                          </>)}
                    </Grid>
                  </Grid>
                </Hidden>

                <Grid item xs={12} sx={{ marginTop: "3%" }}>
                  {isLoading ? (
                    <Skeleton height={120} variant="rectangular" />
                  ) : (
                    <UploadFile
                      id="sLogoFile"
                      keyID={1}
                      sTitle={i18n(`${i18nLabel}.UploadLogo`)}
                      IsRequired={false}
                      arrFile={arrFileLogo || []}
                      setarrFile={setarrFileLogo}
                      IsFolder={false}
                      IsFile={true}
                      IsMultiple={false}
                      Extension={[...Extension.Image]}
                      IsDrag={false}
                      nLimitFile={5}
                      isSetupEmployerLogo={true}
                      isSetupEmployerBanner={false}
                    // OnDelete={setIsDelete((prev) => !prev)}                      
                    />
                  )}
                </Grid>
              </Grid>
              <Grid item marginBottom={3} sx={{ marginTop: "3%" }}>
                <Grid item xs={12}>
                  {isLoading ? (
                    <Skeleton height={120} variant="rectangular" />
                  ) : (
                    <UploadFile
                      id="sBanner_File"
                      keyID={2}
                      sTitle={i18n(`${i18nLabel}.UploadBanner`)}
                      IsRequired={false}
                      arrFile={arrFileBanner || []}
                      setarrFile={setarrFileBanner}
                      IsFolder={false}
                      IsFile={true}
                      IsMultiple={false}
                      Extension={[...Extension.Image]}
                      IsDrag={false}
                      nLimitFile={5}
                      isSetupEmployerLogo={false}
                      isSetupEmployerBanner={true}
                    // OnDelete={setIsDelete((prev) => !prev)} 
                    />
                  )}
                </Grid>
              </Grid>

              {isLoading ? (
                <Skeleton height={120} variant="rectangular" />
              ) : (
                <>
                  <Grid item>
                    <Typography sx={{ fontWeight: 600 }} component="label">
                      {i18n(`${i18nLabel}.Content`)}
                    </Typography>
                    <Typography
                      component="label"
                      sx={{ color: "red", margin: "0 2px" }}
                    >
                      {" * "}
                    </Typography>
                    <Typography
                      component="span"
                      sx={{ color: "#B3B3B3", fontSize: 13 }}
                    ></Typography>
                    <FormHelperText>{i18n(`${i18nLabel}.HelperTXTContent`)}</FormHelperText>
                  </Grid>
                  <Stack
                    sx={{
                      marginTop: "0px !important",
                      border: "1px #eeeeee solid",
                      backgroundColor: "rgba(255,255,255,0.75)",
                      borderRadius: "5px",
                      p: 2,
                      flex: 1,
                    }}
                    spacing={2}
                    direction="column"
                  >
                    {lstComponent.map((item, index) => {
                      return (
                        <Fragment key={`f_${index}`}>
                          {index > 0 ? (
                            <Divider
                              key={`divider_c_${index}`}
                              sx={{ marginTop: "24px !important" }}
                            />
                          ) : null}
                          <BoxContent
                            keyID_content={3} //key for upload file in content
                            key={`box_form_c_${index}`}
                            objData={item}
                            lstComponent={lstComponent}
                            setLstComponent={setLstComponent}
                            objSchema={objSchema}
                            setSchema={setObjSchemaBox}
                            objStateContent={objStateContent}
                            setObjStateContent={setObjStateContent}
                            lstOrder={lstOrder}
                            setLstOrder={setLstOrder}
                            nOrder={index + 1}
                            disabled={nPermission != 2}
                            isAddAction={isAddAction}
                            setIsAddToDefault={() => setIsAddAction(false)}
                            setIsChangeOrder={(e) => setIsChangeOrder(e)}
                            isChangeOrder={isChangeOrder}
                            isAddPic={true}
                            isEmployerSetting={true}
                          />
                        </Fragment>
                      );
                    })}
                  </Stack>
                </>
              )}
            </Grid>
          </Grid>

          <Divider />

          <Grid item xs={12} sx={{ mx: "2%" }}>
            <FormControl required error={errorConsent} component="fieldset" variant="standard" >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isCheckedConsent1}
                      onChange={() => {
                        SetIsCheckedConsent1((prev) => !prev);
                      }}
                    />
                  }
                  label={i18n(`${i18nField}.ConfirmRegisDoc`)}//"ต้องการรับข่าวสาร และข้อมูลที่เกี่ยวข้องต่างๆ"
                />
              </FormGroup>
              {errorConsent ? (<FormHelperText>{i18n(`${i18nField}.ErrorConsent`)}</FormHelperText>) : null}
            </FormControl>
          </Grid>

          {isLoading ? (
            ""
          ) : (
            <Grid container sx={{ my: "2%", mx: "2%" }}>
              <Grid item xs={6} justifyContent={"start"}>
                <BtnBack
                  txt={i18n(`${i18nCommon}.back`)}
                  onClick={() => onBack()}
                />
              </Grid>
              <Grid item container xs={6} justifyContent={"end"}>
                <BtnSubmit
                  txt={i18n(`${i18nCommon}.save`)}
                  onClick={form.handleSubmit((e) => onSubmit(e))}
                />
              </Grid>
            </Grid>
          )}
        </Grid>

      </Card>
    </FormProvider>
  );
}