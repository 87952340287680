const MyJobInvitation_TH = {
  btn: {
    btnAccept: "ตอบรับ",
    btnDecline: "ปฏิเสธ",
  },
  send: "เชิญเมื่อ",
  acceptAt: "ตอบรับเมื่อ",
  declineAt: "ปฏิเสธเมื่อ",
  announce: "ประกาศปัจจุบัน {0} ตำแหน่ง",
  viewAt: "ดูข้อมูลเมื่อ {0}",
  buyAt: "ซื้อเมื่อ",
  confirm: {
    accept: "ต้องการตอบรับหรือไม่",
    decline: "ต้องการปฏิเสธหรือไม่",
  },
};
const MyJobInvitation_EN = {
  btn: {
    btnAccept: "Accept",
    btnDecline: "Decline",
  },
  send: "Invited",
  acceptAt: "Accept at",
  declineAt: "Decline at",
  announce: "Announce {0} position",
  viewAt: "Viewed {0}",
  buyAt: "Bought",
  confirm: {
    accept: "Do you want accept?",
    decline: "Do you want decline?",
  }
};

export { MyJobInvitation_EN, MyJobInvitation_TH };
