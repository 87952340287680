const OrderEmployerEn = {
  TypePackage: "Package Type",
  Select: "Select Package",
  JobPackage: "Job Package",
  CoinPackage: "Coin Package",
  BannerPackage: "Banner Package",
  PricePerJob: "Price per Job",
  Discount: "Discount",
  GrandTotal: "Grand Total",
  Baht: "THB",
  SelectType: "Select Package Type",
  Vatname: "Vat 7%",
  TotalPrice: "Total Price",
  Month: " Month",
  Package: "Package",
  BtnBack: "Back",
  BtnCancle: "Cancle order",
  BtnDraft: "Save Draft",
  BtnSave: "Save",
  BtnSavePayment: "Submit proof of payment",
  Coint: "Coin",
  JobPost: "Job Post",
  Expirey: "Expirey",
  Employer: "Employer",
  UploadPaymentButt: "Upload Payment",
  Invoice: "Invoice documents",
  Payment: "Proof of payment",
  Tax: "Tax invoice",
  Post: "Post",
  CoinNumber: "Number of coins",


};
const OrderEmployerTh = {
  TypePackage: "Package Type",
  Select: "เลือกแพ็กเกจ",
  JobPackage: "Job Package",
  CoinPackage: "แพ็กเกจ COIN",
  BannerPackage: "Banner Package",
  PricePerJob: "ราคา",
  Discount: "ส่วนลด",
  GrandTotal: "ราคารวมทั้งสิ้น",
  Baht: "บาท",
  SelectType: "เลือกประเภทแพ็กเกจ",
  Vatname: "ภาษีมูลค่าเพิ่ม 7%",
  TotalPrice: "ราคารวม",
  Month: " เดือน",
  Package: "Package",
  BtnBack: "ย้อนกลับ",
  BtnCancle: "ยกเลิกคำสั่งซื้อ",
  BtnDraft: "บันทึกร่าง",
  BtnSave: "บันทึก",
  BtnSavePayment: "ส่งหลักฐานยืนยันการชำระเงิน",
  Coint: "เหรียญ",
  JobPost: "จำนวนโพสต์",
  Expirey: "วันหมดอายุ",
  Employer: "ผู้ประกอบการ",
  UploadPaymentButt: "ส่งหลักฐานการชำระเงิน",
  Invoice: "เอกสารใบแจ้งหนี้",
  Payment: "หลักฐานการชำระเงิน",
  Tax: "ใบกำกับภาษี",
  Post: "โพสต์",
  CoinNumber: "จำนวนเหรียญ"

};

const TableOrderEmployerTh = {
  No: "ที่",
  OrderNumber: "เลขที่คำสั่งซื้อ",
  Package: "แพ็กเกจ",
  Price: "ราคารวมทั้งสิ้น",
  OrderDate: "วันที่สั่งซื้อ",
  ExpDate: "วันที่หมดอายุ",
  Status: "สถานะคำสั่งซื้อ",
  AlertConDel: "คุณต้องการลบข้อมูลการสั่งซื้อใช่หรือไม่ ?",
  StatusPackage: "สถานะแพ็กเกจ",
  PackageExpire: "แพ็กเกจหมดอายุ"
};
const TableOrderEmployerEn = {
  No: "No",
  OrderNumber: "Order Number",
  Package: "Package",
  Price: "Total Price",
  OrderDate: "Order Date",
  ExpDate: "Expiration Date",
  Status: "Order Status",
  AlertConDel: "Do you want to remove order ?",
  StatusPackage: "Package Status",
  PackageExpire: "Package Expiration Date"
};

const TablePackageTh = {
  TitlePackage: "แพ็กเกจ JOB POST",
  No: "ที่",
  Package: "แพ็กเกจ",
  PackageTable: "ชื่อแพ็กเกจ",
  Position: "ตำแหน่ง",
  Coin: "เหรียญ",
  ExpDate: "วันที่หมดอายุ",
  PriceBeforeTax: "ราคาก่อนภาษี",
  ExpiryDuration: "ระยะเวลาหมดอายุ/เดือน",
  NormalPrice: "ราคาปกติ",
  JobPosting: "จำนวน Job Post",
  JobPostingPeriod: "ระยะเวลาประกาศ (วัน)",
  NormalPricePerJob: "ราคาปกติต่องาน",
  Special: "พิเศษ",
  PricePerJob: "ราคาต่องาน",
  TotalPrice: "ราคารวม",
  PercentSave: "ส่วนลด (%)",
  Net: "ราคาสุทธิ",
  Vat: "ภาษีมูลค่าเพิ่ม 7%",
  GrandTotal: "ราคารวมทั้งสิ้น",
};
const TablePackageEn = {
  TitlePackage: "Job Post Package",
  No: "No",
  Package: "Package",
  PackageTable: "Free Token Package & Period",
  Position: "Position",
  Coin: "Coin",
  ExpDate: "Expiration Date",
  PriceBeforeTax: "Price Before Tax",
  ExpiryDuration: "Expiry Duration/Month",
  NormalPrice: "Normal Price",
  JobPosting: "Job Posting",
  JobPostingPeriod: "Job Posting Period",
  NormalPricePerJob: "Normal Price/Job",
  Special: "Special",
  PricePerJob: "Price Per Job",
  TotalPrice: "Total Price",
  PercentSave: "% Save",
  Net: "Net",
  Vat: "Vat 7%",
  GrandTotal: "Grand Total",
};

const TableBannerTh = {
  TitleBanner: "แพ็กเกจ BANNER",
  Position: "ตำแหน่ง",
  DurationMonth: " เดือน",
  CoinPackage: "แพ็กเกจ COIN"
};
const TableBannerEn = {
  TitleBanner: "Banner Package",
  Position: "Position",
  DurationMonth: " Month",
  CoinPackage: "Coin Package"
};
export {
  OrderEmployerEn, OrderEmployerTh,
  TableOrderEmployerTh, TableOrderEmployerEn,
  TablePackageTh, TablePackageEn,
  TableBannerTh, TableBannerEn
};
