import { Stack } from "@mui/material";
import AdminSpecialTable from "../../../components/AdminWorkflow/AdminOrder/AdminSpecial";

export default function AdminSpecial() {
 
  return (
    <Stack sx={{ mx: "1%" }}>
        <AdminSpecialTable />
    </Stack>
  );
}