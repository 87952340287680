export interface GoogleState {
    ProviderGoogle: any
}


const initialState: GoogleState = {
    ProviderGoogle: null,
};


export const GoogleReducers = (state = initialState, { type, payload }) => {
    switch (type) {
        case 'SET_UP': {
            const newState = { ...state };
            newState.ProviderGoogle = payload.ProviderGoogle ? payload.ProviderGoogle : null;
            return newState;
        }
        default:
            return state;
    }
}