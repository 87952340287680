import { useEffect, useState } from 'react';
import { Box, Grid, Stack, TextField, Typography } from '@mui/material';
import { AxiosGet, AxiosPost, Extension, GetPermission, ResultAPI } from "service/CommonFunction/TS_function";
import { useDispatch } from "react-redux";
import yupFormSchemas from '../Common/yup/yupFormSchemas';
import * as yup from "yup";
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { BtnBack, BtnPreview, BtnSave } from '../Common/Button';
import UploadFile from 'components/Common/ElementInForm/UploadFile/UploadFile';
import Input from "components/Common/ElementInForm/InputCustomLabelFormItem";
import DatePickerFormItem from 'components/Common/ElementInForm/DatePickerFormItem';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import { FnBlock_UI } from 'service/SystemFunction/SystemFunction';
import { DialogActionCreators } from 'store/redux/DialogAlert';
import { i18n } from 'i18n';
import DialogPreview from 'components/DialogPreview/DialogPreview';
import DetailComponent from './AdminBannerComponent';
import { MainHome } from 'styles_font';
import AutoCompleteSelect from "components/Common/ElementInForm/AutoCompleteSelect";
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DesktopDatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
interface dataContent {
    nType: number,
    sContent: string,
    sUrl: string,
    id: number,
    nOrder: number
}

const BannerAdd = () => {
    const dispatch = useDispatch();
    const [arrFile1, setarrFile1] = useState([]);
    const [arrFile2, setarrFile2] = useState([]);
    const history = useNavigate();
    const { BlockUI, UnBlockUI } = FnBlock_UI();
    const [arrPosition, setarrPosition] = useState([]);
    const [arrPostJob, setarrPostJob] = useState([]);
    const [arrLink, setarrLink] = useState([]);
    const [arrCount, setarrCount] = useState([]);
    const [arrEmployer, setarrEmployer] = useState([]);
    const [arrPo, setarrPo] = useState([]);
    const [nPermission, setPermission] = useState(0);
    const [BannerLink_ID, setBannerLink_ID] = useState(0);
    const [Employer_ID, setEmployer_ID] = useState(0);
    const [PO_ID, setPO_ID] = useState(0);
    const [Position_ID, setPosition_ID] = useState(0);
    const [SizeBanner, setSizeBanner] = useState({} as any);
    const location = useLocation();
    const searchParams = location.search;
    const sID = new URLSearchParams(searchParams).get('sID');
    const sPositionID = new URLSearchParams(searchParams).get('sPositionID');
    const navigate = useNavigate();
    const isEditMode = sID ? true : false;
    const [isShowPreview, setIsShowPreview] = useState<boolean>(false);
    const [lstUrl, setlstUrl] = useState<any[]>([]);
    const [lstImagePreview, setlstImagePreview] = useState<any[]>([]);
    const [lstImagePreview1, setlstImagePreview1] = useState<any[]>([]);
    const [lstImagePreview2, setlstImagePreview2] = useState<any[]>([]);
    const [lstImagePreview3, setlstImagePreview3] = useState<any[]>([]);
    const [lstImagePreview4, setlstImagePreview4] = useState<any[]>([]);
    const i18nField = 'entities.Banner'
    const i18nCommon = 'common';
    const [StartDate, setStartDate] = useState(moment());
    const onBack = () => { history("/AdminBannerShow"); }

    const objSchema = {
        sEmployer_ID: yupFormSchemas.object("ผู้ว่าจ้าง", { required: true, isAdmin: true }),
        sPO_ID: yupFormSchemas.object("หมายเลขใบสั่งซื้อ", { required: true, isAdmin: true }),
        nBanner_Day: yupFormSchemas.integer("ระยะเวลา(เดือน)", { required: true, isAdmin: true }),
        dStartDate: yupFormSchemas.string("วันที่เริ่ม", { required: true, isAdmin: true }),
        dEnd_Date: yupFormSchemas.string("วันที่สิ้นสุด", { required: true, isAdmin: true }),
        sPosition_ID: yupFormSchemas.object("ตำแหน่งโฆษณา", { required: true, isAdmin: true }),
        sBannerLink_ID: yupFormSchemas.object("เชื่อมโยงไปยัง", { required: true, isAdmin: true }),
        sLinkUrl: yupFormSchemas.string("ลิงค์ URL", { required: BannerLink_ID == 6, isAdmin: true }),
        nPostJob_ID: yupFormSchemas.object(i18n(`${i18nField}.PostJob`), { required: false, isAdmin: true }),
        sNote: yupFormSchemas.string("หมายเหตุ", { required: false, isAdmin: true }),
    }

    const schema = yup.object().shape(objSchema);

    const form = useForm({
        resolver: yupResolver(schema),
        shouldUnregister: false,
        shouldFocusError: true,
        mode: "all",
    });

    useEffect(() => {
        GetPermission(3, setPermission);
        OnGetData()
        onGetDetail()

        form.setValue("dStartDate", new Date());
    }, []);

    useEffect(() => {
        if (!isEditMode) {
            OnGetDuration()
        }
        OnGetSize()

    }, [Position_ID]);

    // useEffect(() => {
    //     if (isOpen) {
    //       form.clearErrors();
    //     }
    //   }, [isOpen])

    //set ค่าEdete
    const OnEnddete = () => {
        let nBanner_Day = (form.getValues("nBanner_Day"));
        let dStartDate = moment(form.getValues("dStartDate"));
        let data = dStartDate.add(nBanner_Day, "months");
        let dEnd_Date = data.add(-1, "days");
        form.setValue("dEnd_Date", dEnd_Date);
    }



    //#region event
    const OnGetData = () => {
        AxiosGet("FromBanner/GetData_DropDown", {}, (result) => {
            UnBlockUI();
            setarrLink(result.lstOptionLink || []);
            setarrEmployer(result.lstCompany || [])
            setarrCount(result.lstTotalbanner);

            form.setValue("sPosition_ID", result.lstOptionPosition.find(f => f.value == sPositionID));
            setPosition_ID(parseInt(sPositionID));
        },
            (err) => {
                if (!err.response) {
                    history("/error", { replace: true })
                    return;
                }
            }
        );
    }

    const OnGetOrder = () => {
        if (form.getValues("sPosition_ID")) {
            let param = {
                nEmployer_ID: (form.getValues("sEmployer_ID").value),
                nPosition_ID: (form.getValues("sPosition_ID").value),
            }
            AxiosPost("FromBanner/GetData_ShowOrder", param, (result) => {
                setarrPo(result.lstPo || [])

            });
        } else if (form.getValues("sEmployer_ID")) {
            let param = {
                nEmployer_ID: (form.getValues("sEmployer_ID").value),
            }
            AxiosPost("FromBanner/GetData_ShowOrder", param, (result) => {
                setarrPo(result.lstPo || [])

            });
        }
    }
    const OnGetPosition = () => {
        if (form.getValues("sPosition_ID")) {
            let param = {
                nEmployer_ID: (form.getValues("sEmployer_ID").value),
                nPosition_ID: (form.getValues("sPosition_ID").value),
            }
            AxiosPost("FromBanner/GetData_Position", param, (result) => {
                setarrPosition(result.lstOptionPosition || []);
            });
        } else if (form.getValues("sEmployer_ID")) {
            let param = {
                nEmployer_ID: (form.getValues("sEmployer_ID").value),
                nPO_ID: (form.getValues("sPO_ID").value),
            }
            AxiosPost("FromBanner/GetData_Position", param, (result) => {
                setarrPosition(result.lstOptionPosition || []);
            });
        }
    }
    const OnGetPostJob = () => {
        let nID = (form.getValues("sEmployer_ID").value)
        AxiosGet("FromBanner/GetData_ShowPostJob", { nID: nID }, (result) => {
            setarrPostJob(result.lstPostJob || [])

        });
    }

    const OnGetDuration = () => {
        // let nID = 0
        if (form.getValues("sEmployer_ID")) {
            let objParam = {
                nEmployer_ID: form.getValues("sEmployer_ID").value || null,
                nPO_ID: form.getValues("sPO_ID").value,
                nPosition_ID: form.getValues("sPosition_ID").value || null,
            }

            AxiosPost("FromBanner/GetData_DataDuration", objParam, (result) => {
                if (result.objDuration.nBanner_Day != null)
                    form.setValue("nBanner_Day", result.objDuration.nBanner_Day); OnEnddete();

            });

        }
    }
    // GetData_ShowSize
    const OnGetSize = () => {
        let nID = 0
        if (form.getValues("sPosition_ID")) {
            nID = (form.getValues("sPosition_ID").value)
            AxiosGet("FromBanner/GetData_ShowSize", { nID: nID }, (result) => {
                setSizeBanner(result.lstSize);
            });
        }
    }

    //saveData
    const onSubmit = (e) => {
        if ((arrFile1.length === 0)) {
            dispatch(DialogActionCreators.OpenDialogWarning("กรุณากรอกข้อมูลอัปโหลดไฟล์แบนเนอร์", () => {
                dispatch(DialogActionCreators.CloseDialogWarning() as any);
            }) as any);
        }
        else {
            dispatch(DialogActionCreators.OpenDialogSubmit("คุณต้องการบันทึกข้อมูลหรือไม่ ?", () => {
                dispatch(DialogActionCreators.CloseDialogSubmit() as any);

                //ประกาศตัวแปร UpFile1
                var sFileName = "", sFileName_Sys = "", sURL = "", sFolderName = "", sRootURL = "";
                var IsNew = true;
                //เช็คค่า
                if ((arrFile1.length > 0)) {
                    sFileName = arrFile1[0].sFileName + "";
                    sFileName_Sys = arrFile1[0].sSysFileName + "";
                    sURL = arrFile1[0].sFileLink + "";
                    sFolderName = arrFile1[0].sFolderName + "";
                    sRootURL = arrFile1[0].sRootURL + "";
                    IsNew = (arrFile1[0].IsNew);
                }
                //ประกาศตัวแปร UpFile2
                var sFileName2 = "", sFileName_Sys2 = "", sURL2 = "", sFolderName_Company2 = "", sRootURL_Company2 = "";
                var IsNew_Company2 = true;
                //เช็คค่า
                if ((arrFile2.length > 0)) {
                    sFileName2 = arrFile2[0].sFileName + "";
                    sFileName_Sys2 = arrFile2[0].sSysFileName + "";
                    sURL2 = arrFile2[0].sFileLink + "";
                    sFolderName_Company2 = arrFile2[0].sFolderName + "";
                    sRootURL_Company2 = arrFile2[0].sRootURL + "";
                    IsNew_Company2 = (arrFile2[0].IsNew);
                }

                //บันทึก
                let objSave = {
                    sID: isEditMode ? sID : "",
                    nEmployer_ID: +(form.getValues("sEmployer_ID").value),
                    nPO_ID: +(form.getValues("sPO_ID").value),
                    sPO_ID: (form.getValues("sPO_ID").label),
                    nBanner_Day: +(form.getValues("nBanner_Day")),
                    dStartDate: moment(form.getValues("dStartDate")).toDate(),
                    dEnd_Date: moment(form.getValues("dEnd_Date")).toDate(),
                    nStartDate :  moment(form.getValues("dStartDate")).valueOf(),
                    nEnd_Date :  moment(form.getValues("dEnd_Date")).valueOf(),
                    nPosition_ID: +(form.getValues("sPosition_ID").value),
                    sFileName: sFileName,
                    sFileName_Sys: sFileName_Sys,
                    sURL: sURL,
                    sFolderName: sFolderName,
                    IsNew: IsNew,
                    sRootURL: sRootURL,
                    sFileName_Company: sFileName2,
                    sFileName_Sys_Company: sFileName_Sys2,
                    sURL_Company: sURL2,
                    sFolderName_Company: sFolderName_Company2,
                    IsNew_Company: IsNew_Company2,
                    sRootURL_Company: sRootURL_Company2,
                    nBannerLink_ID: +(form.getValues("sBannerLink_ID").value),
                    nPostJob_ID: BannerLink_ID == 3 ? +(form.getValues("nPostJob_ID").value) : 0,
                    sNote: form.getValues("sNote"),
                    nMenuID: 9,
                    BannerLinkUrl: BannerLink_ID == 6 ? form.getValues("sLinkUrl") : null
                }

                AxiosPost("FromBanner/SaveData_FormBanner", objSave, (result) => {
                    ResultAPI(result, "บันทึกเรียบร้อยแล้ว", () => {
                        dispatch(DialogActionCreators.OpenDialogSuccess("บันทึกข้อมูลสำเร็จ",
                            () => onBack()
                        ) as any);
                    });
                }, (err) => {
                    if (err.Status === 400) {
                        dispatch(DialogActionCreators.OpenDialogWarning("ไม่สามารถเพิ่มข้อมูลได้เนื่องจากช่วงเวลาที่ระบุมีจำนวนแบนเนอร์เกินกำหนด", () => {
                        }) as any)
                    }
                    else if (err.Status === 409) {
                        dispatch(DialogActionCreators.OpenDialogWarning("ไม่สามารถบึนทึกข้อมูลได้เนื่องจากตำแหน่งโฆษณาที่เลือกไม่ตรงกับหมายเลขคำสั่งซื้อ", () => {

                        }) as any)
                    }
                });
            }) as any);
        }
    }
    const onGetDetail = () => {
        AxiosPost("FromBanner/EditData_Bannerdetail", { sID: sID }, (result) => {
            ResultAPI(result, "", () => {
                if (isEditMode) {
                    form.setValue("sEmployer_ID", result.objSave.objEmployer);
                    form.setValue("sPO_ID", result.objSave.objPO);
                    form.setValue("nBanner_Day", result.objSave.nBanner_Day);
                    form.setValue("dStartDate", moment(result.objSave.dStartDate).format("MM/DD/yyyy HH:mm").toString());
                    form.setValue("dEnd_Date", moment(result.objSave.dEnd_Date).format("MM/DD/yyyy HH:mm").toString());
                    form.setValue("sPosition_ID", result.objSave.objPosition);
                    setarrFile1([...result.lstBannerImage] || []);
                    setarrFile2([...result.lstBannerImagecom] || []);
                    form.setValue("sBannerLink_ID", result.objSave.objBanerlink);
                    form.setValue("nPostJob_ID", result.objSave.objPostJob);
                    form.setValue("sLinkUrl", result.objSave.sLinkUrl);
                    form.setValue("sNote", result.objSave.sNote);
                    if (result.objSave.objBanerlink) {
                        setBannerLink_ID(result.objSave.objBanerlink.value)
                    }
                    if (result.objSave.objEmployer) {
                        setEmployer_ID(result.objSave.objEmployer.value)
                        OnGetPostJob()
                    }

                    if (result.objSave.objPosition) {
                        setPosition_ID(result.objSave.objPosition.value)
                        OnGetSize()
                    }
                }
            });
        },
            (err) => {
                if (!err.response) {
                    navigate("/error", null);
                    return;
                }
            }
        );
    };

    const onPreview = () => {
        let lstfileImagereview1 = [];
        let lstfileImagereview2 = [];
        let lstfileImagereview3 = [];
        let lstfileImagereview4 = [];
        let lstfileImagereview5 = [];
        let lstUrl1 = [];
        let nPosition = (form.getValues("sPosition_ID.value"));
        let fileImage1, fileImage2, fileImage3, fileImage4, fileImage5, lstUrl = "";

        if (nPosition == 1) {
            fileImage1 = `${arrFile1[0].sFileLink}`;
            lstUrl = fileImage1.split('.');
        }
        else if (nPosition == 2) {
            fileImage2 = `${arrFile1[0].sFileLink} `;
            lstUrl = fileImage2.split('.');
        }
        else if (nPosition == 3) {
            fileImage3 = `${arrFile1[0].sFileLink} `;
            lstUrl = fileImage3.split('.');
        }
        else if (nPosition == 4) {
            fileImage4 = `${arrFile1[0].sFileLink} `;
            lstUrl = fileImage4.split('.');
        }
        else if (nPosition == 5) {
            fileImage5 = `${arrFile1[0].sFileLink} `;
            lstUrl = fileImage5.split('.');
        }

        lstfileImagereview1.push(fileImage1)
        lstUrl1.push(lstUrl)
        setlstUrl(lstUrl1)
        setlstImagePreview(lstfileImagereview1)
        lstfileImagereview2.push(fileImage2)
        setlstImagePreview1(lstfileImagereview2)
        lstfileImagereview3.push(fileImage3)
        setlstImagePreview2(lstfileImagereview3)
        lstfileImagereview4.push(fileImage4)
        setlstImagePreview3(lstfileImagereview4)
        lstfileImagereview5.push(fileImage5)
        setlstImagePreview4(lstfileImagereview5)
        setIsShowPreview(true)
    };

    return (

        <FormProvider {...form}>
            <DialogPreview open={isShowPreview}
                handleClose={() => setIsShowPreview(false)}
                Content={() => (<Box sx={MainHome}>
                    <DetailComponent
                        sFileurl={lstUrl}
                        sFileImg={lstImagePreview}
                        sFileImg2={lstImagePreview1}
                        sFileImg3={lstImagePreview2}
                        sFileImg4={lstImagePreview3}
                        sFileImg5={lstImagePreview4}
                    />
                </Box>)
                } />
            <Stack sx={{ border: "2px #eeeeee solid", backgroundColor: "rgba(255,255,255,0.75)", borderRadius: 5, p: 2, mx: "2%" }}>
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    spacing={3}
                >

                    <Grid item xs={12} sm={12} md={11} sx={{ mt: "2%" }}>
                        <AutoCompleteSelect
                            required
                            name={'sEmployer_ID'}
                            label={"ผู้ว่าจ้าง"}
                            options={arrEmployer}
                            onChange={(v) => {
                                setEmployer_ID(v.value)
                                form.setValue('sEmployer_ID', v ? v : null);
                                OnGetOrder();
                                OnGetPostJob();
                            }}
                            disabled={nPermission !== 2}
                        />
                    </Grid>
                    <Grid item md={11} xs={12}>
                        <AutoCompleteSelect
                            required
                            name={'sPO_ID'}
                            label={"หมายเลขใบสั่งซื้อ"}
                            options={arrPo}
                            onChange={(v) => {
                                setPO_ID(v.value)
                                OnGetPosition();
                                form.setValue('sPO_ID', v ? v : null);
                                if (form.getValues('sPosition_ID')) { OnGetDuration(); }
                            }}
                            disabled={Employer_ID == 0}
                        />
                    </Grid>
                    <Grid item md={11} xs={12}>
                        <AutoCompleteSelect
                            required
                            name={'sPosition_ID'}
                            label={"ตำแหน่งโฆษณา"}
                            options={arrPosition}
                            onChange={(v) => {
                                setPosition_ID(v.value)
                                form.setValue('sPosition_ID', v ? v : null);
                                OnGetDuration();
                                OnEnddete();
                                OnGetSize();
                            }}
                            disabled={PO_ID == 0}
                        />
                    </Grid>
                    <Grid item md={11} xs={12}>
                        <Grid container spacing={3}>

                            <Grid item md={4} xs={12}>
                                <Input
                                    name={"nBanner_Day"}
                                    disabled
                                    fullWidth
                                    required
                                    label={
                                        <>
                                            <Typography sx={{ fontWeight: 400 }} component="label">{"ระยะเวลา (เดือน)"}</Typography>
                                        </>}
                                    labelString={""}
                                />
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <Stack>
                                        <DatePickerFormItem
                                            disabled={nPermission !== 2}
                                            name={"dStartDate"}
                                            label={"วันที่เริ่ม"}
                                            onChange={OnEnddete}
                                            fullWidth
                                            minDate={StartDate}
                                        />
                                    </Stack>
                                </LocalizationProvider>
                            </Grid>
                            <Grid item md={4} xs={12} >
                                <Stack >
                                    <DatePickerFormItem
                                        name={"dEnd_Date"}
                                        label={"วันที่สิ้นสุด"}
                                        disabled
                                        fullWidth
                                        required
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item md={11} xs={12} >
                        <UploadFile
                            disabled={nPermission !== 2}
                            id="sAttach_File"
                            keyID={1}
                            sTitle={"Banner"}
                            IsRequired={true}
                            arrFile={arrFile1 || []}
                            setarrFile={setarrFile1}
                            IsFolder={false}
                            IsFile={true}
                            IsMultiple={false}
                            Extension={[...Extension.Image, ...Extension.Video]}
                            IsDrag={false}
                            isSetupEmployerBanner={false}
                            isSetupEmployerLogo={false}
                        />
                    </Grid>
                    {Position_ID >= 1 ? (
                        <Grid item md={11} xs={12} >
                            <Typography
                                style={{
                                    fontSize: "13px",
                                    padding: 0,
                                    marginLeft: "5px",
                                }}
                            >
                                {SizeBanner.sSize}
                            </Typography>
                        </Grid>
                    ) : (
                        <Grid item md={10} xs={12}></Grid>
                    )}
                    <Grid item md={11} xs={12}>
                        <AutoCompleteSelect
                            required
                            name={'sBannerLink_ID'}
                            label={"เชื่อมโยงไปยัง"}
                            options={arrLink}
                            onChange={(v) => {
                                setBannerLink_ID(v.value)
                                form.setValue('sBannerLink_ID', v ? v : null)
                            }}
                            disabled={nPermission !== 2}
                        />
                    </Grid>
                    {BannerLink_ID == 1 ? (
                        <Grid item md={11} xs={12} >
                            <UploadFile
                                disabled={nPermission !== 2}
                                id="sCompanyFile"
                                keyID={2}
                                sTitle={"เอกสารประกอบ Banner "}
                                arrFile={arrFile2 || []}
                                setarrFile={setarrFile2}
                                IsFolder={false}
                                IsFile={true}
                                IsMultiple={false}
                                Extension={[...Extension.PDF, ...Extension.Image, ...Extension.Video]}
                                IsDrag={false}
                                isSetupEmployerBanner={false}
                                isSetupEmployerLogo={false}
                            />
                        </Grid>
                    ) : null}
                    {BannerLink_ID == 3 ? (
                        <Grid item md={11} xs={12}>
                            <AutoCompleteSelect
                                name={'nPostJob_ID'}
                                label={"ตำแหน่งงาน"}
                                options={arrPostJob}
                                onChange={(v) => {
                                    form.setValue('nPostJob_ID', v ? v : null);
                                }}
                            />
                        </Grid>

                    ) : null}
                    {BannerLink_ID == 6 ? (
                        <Grid item md={11} xs={12}>
                            <Input
                                name="sLinkUrl"
                                required
                                disabled={nPermission !== 2}
                                multiline
                                maxLength={3000}
                                label={
                                    <>
                                        <Typography sx={{ fontWeight: 400 }} component="label">{"ลิงค์ URL"}</Typography>
                                    </>
                                }
                            />
                        </Grid>
                    ) : null}
                    <Grid item md={7} xs={12} >
                    </Grid>
                    <Grid item md={11} xs={12} >
                        <Input name="sNote" rows={3}
                            disabled={nPermission !== 2}
                            multiline
                            maxLength={3000}
                            label={
                                <>
                                    <Typography sx={{ fontWeight: 400 }} component="label">{"หมายเหตุ"}</Typography>
                                </>}
                        />
                    </Grid>


                    <Grid item md={5} xs={5} sm={5} justifyContent={"start"} >
                        <BtnBack txt='ย้อนกลับ' onClick={() => onBack()} />
                    </Grid>
                    <Grid item container md={5} xs={4} sm={5} justifyContent={"end"}>
                        <BtnPreview txt='ตัวอย่าง' onClick={() => onPreview()} IsDisabled={nPermission !== 2} />
                    </Grid>
                    <Grid item container md={1} xs={3} sm={3} justifyContent={"end"}>
                        <BtnSave txt='บันทึก' onClick={form.handleSubmit(e => { onSubmit(e) })} IsDisabled={nPermission !== 2} />
                    </Grid>

                </Grid>
            </Stack >
            <Grid item xs={12} sm={12} md={12} sx={{ mt: "2%" }}>  </Grid>
        </FormProvider >

    )
}
export default BannerAdd