import { useEffect, useState } from "react";
import {
  Button,
  ButtonBase,
  Grid,
  Paper,
  Skeleton,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import LogoBPS from "assets/images/BPS-Logo.png";
// import LogoBPS from "ClientApp/public/wwwroot/UploadFile/images/BPS-Logo.png"
import { AxiosPost } from "service/CommonFunction/TS_function";
import { Link, useLocation } from "react-router-dom";
import { i18n } from "i18n";
import CriteriaSearch, { EPageType } from "components/SearchJob/CriteriaSearch";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import HistoryIcon from "@mui/icons-material/History";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import AddToQueueIcon from "@mui/icons-material/AddToQueue";
import { DialogActionCreators } from "store/redux/DialogAlert";
import { useDispatch } from "react-redux";
import JobTab from "./JobTab";
import secureLocalStorage from "react-secure-storage";

const i18nField = "entities.MyJobApply";
const i18nForthisPage = "searchJob.";

const ComponentSkeletonSearch = () => {
  return (
    <>
      {Array.from(Array(6).keys()).map((item) => (
        <Grid item xs={12} key={item}>
          <Paper
            sx={{
              p: 2,
              margin: "auto",
              flexGrow: 1,
              cursor: "pointer",
              backgroundColor: (theme) =>
                theme.palette.mode === "dark" ? "#1A2027" : "#fff",
            }}
          >
            <Grid container spacing={2}>
              <Grid item sx={{ flexGrow: 1 }} xs container>
                <Grid item xs container direction="column" spacing={2}>
                  <Grid item xs>
                    <Typography variant="subtitle1" component="span">
                      <Skeleton />
                    </Typography>
                    <Typography variant="body2">
                      <Skeleton />
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography gutterBottom variant="body2" color="red">
                      <Skeleton />
                    </Typography>
                    <Typography variant="body2">
                      <Skeleton />
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <Grid container direction="column" spacing={2}>
                  <Grid item textAlign={"right"}>
                    <Typography variant="body2">
                      <Skeleton />
                    </Typography>
                  </Grid>
                  <Grid item textAlign={"right"}>
                    <Skeleton variant="circular" height={86} width={86} />
                  </Grid>
                  <Grid item textAlign={"right"}>
                    <Typography variant="body2">
                      <Skeleton />
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      ))}
    </>
  );
};

const MyJobApply = () => {
  const location = useLocation();
  const [isLoading, setLoading] = useState(false);
  const [arrJobApply, setArrJobApply] = useState([]);
  const objSearchFromHome = location?.state
    ? location.state["objSearchFromHome"]
    : null;
  const [isFindCompanyName, setCompanyName] = useState("");
  const [NewsPage, setNewsPage] = useState() as any;
  const [Page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(Page);
  const [objPaging, setPaging] = useState({
    nPage: 1,
    nPageSize: 1000,
    nTotal: 0,
    nSumFetch: 0,
  });

  const fetchJobApply = (param, nPageParam = null) => {
    setLoading(true);
    let nPageFocus = nPageParam ? nPageParam : Page
    if (nPageFocus != Page) {
      setPage(nPageFocus)
    } else {
      param = {
        ...objPaging,
        ...param,
      };

      AxiosPost(
        "MyJob/GetMyJobApply",
        param,
        (res) => {
          if (res.Data) {
            const jobItems = res.Data.items || [];
            const totalRec = res.Data.nTotalRecord || 0;
            const totalItem = res.Data.nTotalItem || 0;
            if (currentPage < objPaging.nPage) {
              setArrJobApply(() => [...arrJobApply, ...jobItems]);
            } else {
              setArrJobApply(() => jobItems);
            }
            setCurrentPage(objPaging.nPage);
            setPaging({
              ...objPaging,
              nTotal: totalRec,
              nSumFetch: objPaging.nSumFetch + totalItem,
            });
          }
        },
        null,
        () => setLoading(false)
      );
    }
  };

  useEffect(() => {
    //Search from Home
    if (objSearchFromHome) {
      let param = {
        arrJobFunctionID: objSearchFromHome["arrJobFunctionID"],
        arrLocationID: objSearchFromHome["arrLocationID"],
        sSearch: objSearchFromHome["sSearch"]
      };

      fetchJobApply(param);
    } else if (isFindCompanyName) {
      let param = {
        sSearch: isFindCompanyName,
      };

      fetchJobApply(param);
    } else {
      const setDataOnPageLoad = async () => {
        await fetchJobApply({});
      };
      setDataOnPageLoad();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [isFindCompanyName]);

  return (
    <>
      <Typography variant="h5" sx={{ mb: "10px", mt: "10px", fontWeight: 500 }}>
        {i18n(`common.myJobApply`)}
      </Typography>
      <CriteriaSearch
        objSearchFromHome={objSearchFromHome}
        onSearch={(val) => { fetchJobApply(val) }}
        pageType={EPageType.Apply}
        page={Page}
        isFindCompanyName={isFindCompanyName}
      />
      <Typography variant="subtitle1" gutterBottom>
        {isLoading ? (
          <Skeleton width={"20%"} />
        ) : (
          i18n(`searchJob.foundData`, objPaging.nTotal)
        )}
      </Typography>
      <Grid container spacing={2}>
        <JobTab lstJob={arrJobApply} isLoading={isLoading} setCompanyName={setCompanyName} NewsPage={NewsPage} Page={Page} setPage={setPage} />
      </Grid>
    </>
  );
};
const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

export default MyJobApply;
