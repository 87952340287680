import { useState, useEffect, useContext, useRef } from "react";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Grid,
  Typography,
  Stack,
  Box,
  FormControlLabel,
  Checkbox,
  FormControl,
  RadioGroup,
  Radio,
  InputAdornment,
  Paper,
  FormGroup,
  FormLabel,
  FormHelperText,
  Hidden,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import {
  AccordionCustom,
  AccordionDetailsCustom,
  AccordionSummaryCustom,
} from "components/SeekerProfile/ElementCustom";
import { i18n } from "i18n";
import yupFormSchemas from "components/Common/yup/yupFormSchemas";
import Input from "components/Common/ElementInForm/InputCustomLabelFormItem";
import { ProfileContext } from "components/SeekerProfile/Context/ProfileContext";
import WorkExperience from "components/SeekerProfile/WorkExperience";
import MyAddress from "components/SeekerProfile/MyAddress";
import EducationHistory from "components/SeekerProfile/EducationHistory";
import MyCertificate from "components/SeekerProfile/MyCertificate";
import AutoCompleteRenderItem from "components/Common/ElementInForm/AutoCompleteRenderItem";
import MyJobPositon from "components/SeekerProfile/MyJobPositon";
import UploadFile from "components/Common/ElementInForm/UploadFile/UploadFile";
import {
  ICertificate,
  IEducation,
  ILanguageSkill,
  IWorkExperience,
  IMyJobPosition,
  IMyProfile,
  IAddress,
  IComputerSkill,
} from "components/SeekerProfile/Interface";
import LanguageSkill from "components/SeekerProfile/LanguageSkill";
import { AxiosPost, Extension } from "service/CommonFunction/TS_function";
import moment from "moment";
import { DialogActionCreators } from "store/redux/DialogAlert";
import AutoCompleteSelect from "components/Common/ElementInForm/AutoCompleteSelect";
import DatePickerFormItem from "components/Common/ElementInForm/DatePickerFormItem";
import MyComputerSkill from "components/SeekerProfile/MyComputerSkill";
import {
  BtnBack,
  BtnCancel,
  BtnRadiusProfile,
  BtnSubmit,
} from "components/Common/Button";
import { FnBlock_UI } from "service/SystemFunction/SystemFunction";
import InputNumber from "components/Common/ElementInForm/InputNumber";
import secureLocalStorage from "react-secure-storage";
import React from "react";

const i18nField = "entities.SeekerProfile";
const i18nCommon = "common";
const OptionSexlTH = [
  { label: "ชาย", value: 0 },
  { label: "หญิง", value: 1 },
  { label: "ไม่ระบุ", value: 2 },
];
const OptionSexEN = [
  { label: "Male", value: 0 },
  { label: "Female", value: 1 },
  { label: "Not Specific", value: 2 },
];
const OptionArmyTH = [
  { label: "ไม่เกี่ยวข้อง", value: 0 },
  { label: "อยู่ในระหว่างรับราชการทหาร ", value: 1 },
  { label: "ผ่านการเกณฑ์ทหารมาแล้วโดยการเป็นทหารเกณฑ์", value: 2 },
  {
    label:
      "ได้รับการยกเว้นโดยการเรียน ร.ด.จบหลักสูตร หรือจับฉลากได้ใบดำ หรือร่างกายไม่ได้ขนาด หรือกำลังเป็นนักศึกษา",
    value: 3,
  },
];
const OptionArmyEN = [
  { label: "Not Related", value: 0 },
  { label: "Serving", value: 1 },
  { label: "Completed", value: 2 },
  { label: "Exempted", value: 3 },
];
const OptionProgram = [
  { label: "Word", value: 1 },
  { label: "Excel", value: 2 },
  { label: "Power Point", value: 3 },
  { label: "Java", value: 4 },
  { label: "Javascript", value: 5 },
  { label: "C#", value: 6 },
  { label: "PHP", value: 7 },
  { label: "C++", value: 8 },
  { label: "Python", value: 9 },
  { label: "C", value: 10 },
  { label: "SQL", value: 11 },
  { label: "RUBY", value: 12 },
  { label: "Objective-C", value: 13 },
  { label: "Perl", value: 14 },
  { label: ".NET", value: 15 },
  { label: "Visual", value: 16 },
];
//const lang = secureLocalStorage.getItem("language").toString() || "th";
const lang = secureLocalStorage.getItem("language").toString() || "th";
const optionSex = lang === "th" ? OptionSexlTH : OptionSexEN;
const OptionArmy = lang === "th" ? OptionArmyTH : OptionArmyEN;
const MyProfile = ({ fnAfterSubmit = null }) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const { BlockUI, UnBlockUI } = FnBlock_UI();
  const location = useLocation();
  const objJobDetail = location?.state ? location.state["objJobDetail"] : null;
  const [IsInterMain, setIsInterMain] = useState(false);

  const {
    myProfileContext,
    setMyProfile,
    arrWorkExperienceContext,
    setWorkExperience,
    addresssContext,
    setAddress,
    isWorkExperience,
    setIsWorkExperience,
    yearExperience,
    setYearExperience,
    educationContext,
    setEducation,
    certificateContext,
    setCertificate,
    ability,
    setAbility,
    languageSkillContext,
    setLanguageSkill,
    jobPositionContext,
    setJobPosition,
    fileUploadContext,
    setFileUpload,
    computerSkillContext,
    setComputerSkill,
    optionWorkEXP,
    setOptionEXP,
    optionPosition,
    optionCompany,
    optionJobFunction,
    optionJobDetail,
    optionCountry,
    optionReion,
    optionProvice,
    optionSubDistrict,
    optionDistrict,
    optionEducationLevel,
    optionUniversity,
    optionLanguage,
    optionLangSkill,
    optionJobType,
    optionJobIndustry,
    profileContext,
    setProfileContext,
    isLoading,
    setIsLoading,
  } = useContext(ProfileContext);

  const [expandedProfile, setExpandedProfile] = useState(true);
  const [expandedWork, setExpandedWork] = useState(true);
  const [expandedAddress, setExpandedAddress] = useState(true);
  const [expandedEducation, setExpandedEducation] = useState(true);
  const [expandedCertificate, setExpandedCertificate] = useState(true);
  const [expandedAbility, setExpandedAbility] = useState(true);
  const [expandedLangSkill, setExpandedLangSkill] = useState(true);
  const [expandedJobApply, setExpandedJobApply] = useState(true);
  const [expandedFile, setExpandedFile] = useState(true);
  const [expandComputerSkill, setExpandComputerSkill] = useState(true);
  const [expandDiverLicense, setExpandDiverLicense] = useState(true);
  const [selectWorkEXP, setselectWorkEXP] = useState(
    isWorkExperience ? "yes" : "no"
  );
  const [isMarried, setIsMarried] = useState(false);
  const [selectMarried, setSelectMarried] = useState(
    isMarried ? "married" : "single"
  );
  const [isStartNow, setIsStartNow] = useState(true);
  const [BirthDate, setBirthDate] = useState(null);
  const [selectLicense, setSelectLicense] = useState({
    selectCar: false,
    selectMotorcyc: false,
    selectOther: false,
  });
  const fieldRef = React.useRef<HTMLInputElement>(null);
  const oFormRef = useRef(null);
  const { selectCar, selectMotorcyc, selectOther } = selectLicense;

  const [ProfileImage, setProfileImage] = useState([]);

  const [schemaWork, setSchemaWork] = useState({});


  // Set up Schema Work Experience 
  const loopSetSchemaWork = () => {
    const schemaTemp = {};
    arrWorkExperienceContext.forEach((item) => {
      schemaTemp[`Position${item.Index}`] = yupFormSchemas.object(
        i18n(`${i18nField}.Position`),
        { required: isWorkExperience }
      );
      schemaTemp[`Company${item.Index}`] = yupFormSchemas.object(
        i18n(`${i18nField}.Company`),
        { required: isWorkExperience }
      );
      schemaTemp[`WorkDate${item.Index}`] = yupFormSchemas.dateRange(
        i18n(`${i18nField}.StartDate`),
        { required: isWorkExperience }
      );
      schemaTemp[`StartDate${item.Index}`] = yupFormSchemas.datetime(
        i18n(`${i18nField}.StartDate`),
        { required: isWorkExperience }
      );
      schemaTemp[`EndDate${item.Index}`] = yupFormSchemas.datetime(
        i18n(`${i18nField}.EndDate`),
        { required: isWorkExperience && !item.IsCurrent }
      );
      schemaTemp[`JobFunc${item.Index}`] = yupFormSchemas.object(
        i18n(`${i18nField}.JobFunction`),
        { required: isWorkExperience }
      );
      schemaTemp[`JobDetail${item.Index}`] = yupFormSchemas.object(
        i18n(`${i18nField}.JobDetail`),
        { required: isWorkExperience }
      );
      schemaTemp[`JobIndustry${item.Index}`] = yupFormSchemas.object(
        i18n(`${i18nField}.JobIndustry`),
        { required: isWorkExperience }
      );
      schemaTemp[`WorkSalary${item.Index}`] = yupFormSchemas.decimal(
        i18n(`${i18nField}.WorkSalary`),
        { required: isWorkExperience }
      );
      schemaTemp[`WorkRemark${item.Index}`] = yupFormSchemas.string(
        i18n(`${i18nField}.WorkRemark`),
        { required: isWorkExperience }
      );
      schemaTemp[`LeaveRemark${item.Index}`] = yupFormSchemas.string(
        i18n(`${i18nField}.LeaveRemark`)
      );
    });
    setSchemaWork(schemaTemp);
  };
  //

  // Set up Schema Skill
  const [schemaLanguageSkill, setSchemaLanguageSkill] = useState({});
  const loopSetSchemaLanguageSkill = () => {
    const schemaTemp = {};
    (languageSkillContext || []).forEach((item) => {
      schemaTemp[`Language${item.Index}`] = yupFormSchemas.object(
        i18n(`${i18nField}.Language`),
        { required: true }
      );
      schemaTemp[`ConvsSkill${item.Index}`] = yupFormSchemas.object(
        i18n(`${i18nField}.ConvsSkill`),
        { required: true }
      );
      schemaTemp[`ReadSkill${item.Index}`] = yupFormSchemas.object(
        i18n(`${i18nField}.ReadSkill`),
        { required: true }
      );
      schemaTemp[`WriteSkill${item.Index}`] = yupFormSchemas.object(
        i18n(`${i18nField}.WriteSkill`),
        { required: true }
      );
    });
    setSchemaLanguageSkill(schemaTemp);
  }
  //

  // Set up Schema Computer Skill 
  const [schemaComputerSkill, setSchemaComputerSkill] = useState({});
  const loopSetSchemaComputerSkill = () => {
    const schemaTemp = {};
    (computerSkillContext || []).forEach((item) => {
      schemaTemp[`Program${item.Index}`] = yupFormSchemas.object(
        i18n(`${i18nField}.Program`),
        { required: true }
      );
      schemaTemp[`SkillLevel${item.Index}`] = yupFormSchemas.object(
        i18n(`${i18nField}.SkillLevel`),
        { required: true }
      );
    });
    setSchemaComputerSkill(schemaTemp);
  };
  //

  // Set up Schema Position Job 
  const [shcemaPositionJob, setShcemaPositionJob] = useState({});
  const loopSetSchemaPositionJob = () => {
    const schemaTemp = {};
    jobPositionContext.forEach((item) => {
      schemaTemp[`ApplyPosition${item.Index}`] = yupFormSchemas.string(
        i18n(`${i18nField}.ApplyPosition`),
        { required: true }
      );
      schemaTemp[`ApplyJobFunction${item.Index}`] = yupFormSchemas.object(
        i18n(`${i18nField}.ApplyJobFunction`),
        { required: true }
      );
      schemaTemp[`ApplyJobDetail${item.Index}`] = yupFormSchemas.object(
        i18n(`${i18nField}.ApplyJobDetail`),
        { required: true }
      );
      schemaTemp[`ApplyJobType${item.Index}`] = yupFormSchemas.object(
        i18n(`${i18nField}.ApplyJobType`),
        { required: true }
      );
      schemaTemp[`ApplyIndustryType${item.Index}`] = yupFormSchemas.object(
        i18n(`${i18nField}.JobIndustry`),
        { required: true }
      );
      schemaTemp[`ApplySalaryStart${item.Index}`] = yupFormSchemas.integer(
        i18n(`${i18nField}.ApplySalaryStart`),
        { required: true }
      );
      schemaTemp[`ApplySalaryEnd${item.Index}`] = yupFormSchemas.integer(
        i18n(`${i18nField}.ApplySalaryEnd`)
      );
    });
    setShcemaPositionJob(schemaTemp);
  };
  //

  // Set up Schema Education
  const [schemaEducation, setSchemaEducation] = useState({});
  const loopSetSchemaEducation = () => {
    const schemaTemp = {};
    (educationContext || []).forEach((item) => {
      schemaTemp[`University${item.Index}`] = yupFormSchemas.object(
        i18n(`${i18nField}.University`),
        { required: true }
      );
      schemaTemp[`EducateLevel${item.Index}`] = yupFormSchemas.object(
        i18n(`${i18nField}.EducateLevel`),
        { required: true }
      );
      schemaTemp[`Faculty${item.Index}`] = yupFormSchemas.string(
        i18n(`${i18nField}.Faculty`),
        { required: false }
      );
      schemaTemp[`Major${item.Index}`] = yupFormSchemas.string(
        i18n(`${i18nField}.Major`),
        { required: true }
      );
      schemaTemp[`GraduationYear${item.Index}`] = yupFormSchemas.datetime(
        i18n(`${i18nField}.GraduationYear`),
        { required: true }
      );
      schemaTemp[`RemarkEdu${item.Index}`] = yupFormSchemas.string(
        i18n(`${i18nField}.EduRemark`)
      );
    });
    setSchemaEducation(schemaTemp);
  };
  //

  // Remove old schema 

  // -> Work Experience
  const arrSchemaNameWorkExp = [
    "Position",
    "Company",
    "WorkDate",
    "StartDate",
    "EndDate",
    "JobFunc",
    "JobDetail",
    "JobIndustry",
    "WorkSalary",
    "WorkRemark",
    "LeaveRemark",
  ];

  useEffect(() => {
    for (const [key, value] of Object.entries(formSchema)) {
      if (arrSchemaNameWorkExp.some((a) => key.includes(a))) {
        delete formSchema[key];
      }
    }
    setFormSchema({ ...formSchema, ...schemaWork });
  }, [schemaWork]);

  // -> Computer Skill
  const arrSchemaNameComputerSkill = [
    "Program",
    "SkillLevel",
  ];

  useEffect(() => {
    for (const [key, value] of Object.entries(formSchema)) {
      if (arrSchemaNameComputerSkill.some((a) => key.includes(a))) {
        delete formSchema[key];
      }
    }
    setFormSchema({ ...formSchema, ...schemaComputerSkill });
  }, [schemaComputerSkill]);


  // -> Position Job
  const arrSchemaNamePositionJob = [
    "ApplyPosition",
    "ApplyJobFunction",
    "ApplyJobDetail",
    "ApplyJobType",
    "ApplyIndustryType",
    "ApplySalaryStart",
    "ApplySalaryEnd",
  ];

  useEffect(() => {
    for (const [key, value] of Object.entries(formSchema)) {
      if (arrSchemaNamePositionJob.some((a) => key.includes(a))) {
        delete formSchema[key];
      }
    }
    setFormSchema({ ...formSchema, ...shcemaPositionJob });
  }, [shcemaPositionJob]);

  // -> Education
  const arrSchemaNameEducation = [
    "University",
    "EducateLevel",
    "Faculty",
    "Major",
    "GraduationYear",
    "RemarkEdu",
  ];

  useEffect(() => {
    for (const [key, value] of Object.entries(formSchema)) {
      if (arrSchemaNameEducation.some((a) => key.includes(a))) {
        delete formSchema[key];
      }
    }
    setFormSchema({ ...formSchema, ...schemaEducation });
  }, [schemaEducation]);
  //

  // -> Language Skill
  const arrSchemaNameLanguageSkill = [
    "ConvsSkill",
    "ReadSkill",
    "Language",
    "WriteSkill",
  ];

  useEffect(() => {
    for (const [key, value] of Object.entries(formSchema)) {
      if (arrSchemaNameLanguageSkill.some((a) => key.includes(a))) {
        delete formSchema[key];
      }
    }
    setFormSchema({ ...formSchema, ...schemaLanguageSkill });
  }, [schemaLanguageSkill]);
  //

  // useEffact set new schema 
  useEffect(() => {
    loopSetSchemaPositionJob();
  }, [jobPositionContext])
  useEffect(() => {
    loopSetSchemaWork();
  }, [isWorkExperience, arrWorkExperienceContext]);
  useEffect(() => {
    loopSetSchemaEducation();
  }, [educationContext]);
  useEffect(() => {
    loopSetSchemaLanguageSkill();
  }, [languageSkillContext]);
  useEffect(() => {
    loopSetSchemaComputerSkill();
  }, [computerSkillContext]);
  //

  const schemaCert = {};
  (certificateContext || []).forEach((item) => {
    schemaCert[`CertName${item.Index}`] = yupFormSchemas.string(
      i18n(`${i18nField}.CertName`)
    );
    schemaCert[`ApproveBy${item.Index}`] = yupFormSchemas.string(
      i18n(`${i18nField}.ApproveBy`)
    );
    schemaCert[`ApproveDate${item.Index}`] = yupFormSchemas.datetime(
      i18n(`${i18nField}.ApproveDate`)
    );
    schemaCert[`ExpirdDate${item.Index}`] = yupFormSchemas.datetime(
      i18n(`${i18nField}.ExpirdDate`)
    );
    schemaCert[`checkNoExpire${item.Index}`] = yupFormSchemas.boolean(
      i18n(`${i18nField}.checkNoExpire`)
    );
    schemaCert[`CertRemark${item.Index}`] = yupFormSchemas.string(
      i18n(`${i18nField}.CertRemark`)
    );
  });

  const [formSchema, setFormSchema] = useState<any>({
    WorkEXP: yupFormSchemas.object(i18n(`${i18nField}.WorkEXP`), {
      required: isWorkExperience,
    }),
    Country: yupFormSchemas.object(i18n(`${i18nField}.Country`), {
      required: true,
    }),
    Region: yupFormSchemas.object(i18n(`${i18nField}.Region`), {
      required: IsInterMain,
    }),
    Province: yupFormSchemas.object(i18n(`${i18nField}.Province`), {
      required: IsInterMain,
    }),

    //Address
    District: yupFormSchemas.object(i18n(`${i18nField}.District`), {
      required: false,
    }),
    SubDistrict: yupFormSchemas.object(i18n(`${i18nField}.SubDistrict`), {
      required: false,
    }),
    AddressNo: yupFormSchemas.string(i18n(`${i18nField}.AddressNo`), {
      required: IsInterMain,
    }),
    Moo: yupFormSchemas.string(i18n(`${i18nField}.Moo`), {
      required: IsInterMain,
    }),
    Street: yupFormSchemas.string(i18n(`${i18nField}.Street`), {
      required: IsInterMain,
    }),
    PostCode: yupFormSchemas.string(i18n(`${i18nField}.PostCode`), {
      required: IsInterMain,
    }),

    Ability: yupFormSchemas.stringArray(i18n(`${i18nField}.Ability`)),
    DayStart: yupFormSchemas.integer(i18n(`${i18nField}.Day`), {
      required: !isStartNow,
    }),
    CurrentSalary: yupFormSchemas.decimal(i18n(`${i18nField}.CurrentSalary`)),
    Name: yupFormSchemas.string(i18n(`${i18nField}.Name`), { required: true }),
    Surname: yupFormSchemas.string(i18n(`${i18nField}.Surname`), {
      required: true,
    }),
    NameEN: yupFormSchemas
      .string(i18n(`${i18nField}.NameEN`), {
        required: true,
      })
      .matches(/^[a-zA-Z]+$/, i18n(`${i18nField}.ErrorNameEN`)),
    SurnameEN: yupFormSchemas
      .string(i18n(`${i18nField}.SurnameEN`), {
        required: true,
      })
      .matches(/^[a-zA-Z]+$/, i18n(`${i18nField}.ErrorNameEN`)),
    Email: yupFormSchemas.email(i18n(`${i18nField}.Email`)),
    OtherContact: yupFormSchemas.string(i18n(`${i18nField}.OtherContact`)),
    Sex: yupFormSchemas.object(i18n(`${i18nField}.Sex`), { required: true }),
    Tel: yupFormSchemas.string(i18n(`${i18nField}.Tel`), { required: true }),
    DOB: yupFormSchemas.date(i18n(`${i18nField}.DOB`), { required: true }),
    Army: yupFormSchemas.object(i18n(`${i18nField}.Army`), { required: true }),
    CarNo: yupFormSchemas.string(i18n(`${i18nField}.LicenseNo`), {
      required: selectCar,
    }),
    MotorcycleNo: yupFormSchemas.string(i18n(`${i18nField}.LicenseNo`), {
      required: selectMotorcyc,
    }),
    OtherName: yupFormSchemas.string(i18n(`${i18nField}.Other`), {
      required: selectOther,
    }),
    OtherNo: yupFormSchemas.string(i18n(`${i18nField}.LicenseNo`), {
      required: selectOther,
    }),
    Tag: yupFormSchemas.stringArray(i18n(`${i18nField}.Tag`)),

    ...schemaWork,
    ...schemaEducation,
    ...schemaCert,
    ...schemaLanguageSkill,
    ...schemaComputerSkill,
    ...shcemaPositionJob,
  });


  const schema = yup.object().shape(formSchema);

  const DivAge = styled("div")(({ theme }) => ({
    ...theme.typography.button,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1),
    textTransform: "capitalize",
  }));

  const form = useForm({
    resolver: yupResolver(schema),
    shouldUnregister: false,
    shouldFocusError: true,
    mode: "all",
    //defaultValues: initValue as any,
  });

  const setFocusNewField = (sName) => {
    setTimeout(() => {
      try {
        form.setFocus(sName);
      } catch (error) { }
    }, 500);
  };

  const handleAddWorkUnit = () => {
    let arrIndex =
      arrWorkExperienceContext.map((item, index) => {
        return item.Index;
      }) || [];
    let nGenIndex = 0;
    if (arrIndex.length > 0) {
      nGenIndex = (Math.max.apply(Math, arrIndex) || 0) + 1;
    }
    let newData = [
      ...arrWorkExperienceContext,
      {
        Index: nGenIndex,
        Position: "",
        Company: "",
        StartDate: null,
        EndDate: null,
        IsCurrent: false,
        JobFunc: "",
        JobDetail: "",
        JobIndustry: "",
        WorkRemark: "",
        WorkSalary: null,
        LeaveRemark: "",
      },
    ] as Array<IWorkExperience>;

    setWorkExperience(newData);
    setFocusNewField(`Position${nGenIndex}`);
  };

  const handleAddEducate = () => {
    let nGenIndex =
      (Math.max.apply(
        Math,
        educationContext.map((item: IEducation, index) => {
          return item.Index;
        })
      ) || 0) + 1;
    let newData = [
      ...educationContext,
      {
        Index: nGenIndex,
        Academy: "",
        EducateLevel: "",
        University: "",
        Faculty: "",
        Major: "",
        GraduationYear: null,
        Remark: "",
      },
    ] as Array<IEducation>;
    setEducation(newData);
    setFocusNewField(`University${nGenIndex}`);
  };

  const handleAddCertificate = () => {
    let nGenIndex =
      (Math.max.apply(
        Math,
        certificateContext.map((item: ICertificate, index) => {
          return item.Index;
        })
      ) || 0) + 1;

    let newData = [
      ...certificateContext,
      {
        Index: nGenIndex,
        CertName: "",
        ApproveBy: "",
        ApproveDate: null,
        ExpirdDate: null,
        IsNotExpird: false,
        CertRemark: "",
      },
    ] as Array<ICertificate>;
    setCertificate(newData);
    setFocusNewField(`CertName${nGenIndex}`);
  };

  const handleAddLanguageSkill = () => {
    let nGenIndex =
      (Math.max.apply(
        Math,
        languageSkillContext.map((item: ILanguageSkill, index) => {
          return item.Index;
        })
      ) || 0) + 1;
    let newData = [
      ...languageSkillContext,
      {
        Index: nGenIndex,
        Language: null,
        ConvsSkill: null,
        ReadSkill: null,
        WriteSkill: null,
      },
    ] as Array<ILanguageSkill>;
    setLanguageSkill(newData);
    setFocusNewField(`Language${nGenIndex}`);
  };

  const handleAddComputerSkill = () => {
    let nGenIndex =
      (Math.max.apply(
        Math,
        computerSkillContext.map((item: IComputerSkill, index) => {
          return item.Index;
        })
      ) || 0) + 1;
    let newData = [
      ...computerSkillContext,
      {
        Index: nGenIndex,
        Program: null,
        LevelSkill: null,
      },
    ] as Array<IComputerSkill>;
    setComputerSkill(newData);
    setFocusNewField(`Program${nGenIndex}`);
  };

  const handleAddJobAppiy = () => {
    let nGenIndex =
      (Math.max.apply(
        Math,
        jobPositionContext.map((item: IMyJobPosition, index) => {
          return item.Index;
        })
      ) || 0) + 1;
    let newData = [
      ...jobPositionContext,
      {
        Index: nGenIndex,
        ApplyPosition: null,
        ApplyJobFunction: null,
        ApplyJobDetail: null,
        ApplyJobType: null,
        ApplyIndustryType: null,
        ApplySalaryStart: null,
        ApplySalaryEnd: null,
      },
    ] as Array<IMyJobPosition>;
    setJobPosition(newData);
    setFocusNewField(`ApplyPosition${nGenIndex}`);
  };

  const handleCalAge = () => {
    if (BirthDate) {
      const age = moment().diff(BirthDate, "years", false);
      return age + " " + i18n(`${i18nField}.AgeYear`);
    } else {
      return "";
    }
  };

  const onSubmit = (values) => {
    let arrWorkEXP = [];
    if (selectWorkEXP === "yes") {
      let dYearExp = 0;
      arrWorkEXP = arrWorkExperienceContext.map((item) => {
        if (item.IsCurrent) {
          var duration = moment.duration(moment().diff(moment(item.StartDate)));
          dYearExp += duration.asYears();
        } else {
          var duration = moment.duration(moment(item.EndDate).diff(moment(item.StartDate)));
          dYearExp += duration.asYears();
        }
        return {
          nWorkExperienceId: item.Index || 0,
          sPosition: item.Position && (item.Position["label"] || ""),
          sCompany: (item.Company && item.Company["label"]) || "",
          nStartDate: moment(item.StartDate).valueOf(),
          nEndDate: moment(item.EndDate).valueOf(),
          // dStartDate: moment(item.StartDate).toDate(),
          // dEndDate: item.EndDate ? moment(item.EndDate).toDate() : null,
          IsCurrent: item.IsCurrent,
          nJobFunction: item.JobFunc["value"] || 0,
          nJobDetail: item.JobDetail["value"] || 0,
          nJobIndustry: item.JobIndustry["value"] || 0,
          sRemark: item.WorkRemark,
          nWorkSalary: item.WorkSalary,
          sLeaveRemark: item.LeaveRemark,
        };
      });
      // if (yearExperience["value"] == 21 && Math.floor(dYearExp) < 21) {
      //   dispatch(DialogActionCreators.OpenDialogWarning("ประสบการณ์การทำงานที่ผ่านมาไม่สัมพันธ์กันกับจำนวนปีที่เลือก",
      //     () => {
      //       setTimeout(() => {
      //         if (oFormRef && oFormRef.current) {
      //           oFormRef.current.scrollIntoView();
      //         }
      //       }, 500);

      //     }))
      //   return
      // } else if (yearExperience["value"] != Math.floor(dYearExp)) {
      //   dispatch(DialogActionCreators.OpenDialogWarning(
      //     "ประสบการณ์การทำงานที่ผ่านมาไม่สัมพันธ์กันกับจำนวนปีที่เลือก",
      //     () => {
      //       setTimeout(() => {
      //         if (oFormRef && oFormRef.current) {
      //           oFormRef.current.scrollIntoView();
      //         }
      //       }, 500);

      //     }))
      //   return
      // }
    }
    const onBack = () => {
      onScroll();
      // window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    const onScroll = () => {
      oFormRef.current.scrollIntoView();
      // const onScroll = () => {
      // window.scrollTo({   
      //   top: - 100,
      //   left: 0,
      //   behavior: "smooth",
      // });

      // document.getElementById("scroll_Top").scrollIntoView({
      //   behavior: "smooth",
      // })
    };
    const arrEducateion = educationContext.map((item) => {
      return {
        nEducationId: item.Index,
        nEducationLevel: item.EducateLevel["value"] || 0,
        nUniversityId: item.University["value"] || 0,
        sEducateName: (item.University && item.University["label"]) || "",
        sFaculty: item.Faculty,
        sMajor: item.Major,
        // nGraduationYear: item.GraduationYear,
        nGraduationYear: moment(item.GraduationYear).valueOf(),
        sEducateRemark: item.EduRemark,
      };
    });

    const arrCertificate = certificateContext.map((item) => {
      return {
        nCertificateId: item.Index,
        sCertificateName: item.CertName,
        sApproveCert: item.ApproveBy,
        dApproveDate: item.ApproveDate
          ? moment(item.ApproveDate).toDate()
          : null,
          nApproveDate   : moment(item.ApproveDate).valueOf(),
          nExpireDate   : moment(item.ExpirdDate).valueOf(),
        dExpireDate: item.IsNotExpird ? null : moment(item.ExpirdDate).toDate(),
        IsNotExpire: item.IsNotExpird,
        sCertRemark: item.CertRemark,
      };
    });

    const arrLangSkill = languageSkillContext.map((item) => {
      return {
        nLanguageSkillId: item.Index,
        nLanguageId:
          item.Language["value"] === undefined ? -1 : item.Language["value"],
        nSpeekingId: item.ConvsSkill["value"],
        nReadingId: item.ReadSkill["value"],
        nWritingId: item.WriteSkill["value"],
        sOther:
          item.Language["value"] === undefined ? item.Language["label"] : "",
      };
    });

    const arrComputerSkill = computerSkillContext.map((item) => {
      return {
        nComputerSkillId: item.Index,
        nProgramId:
          item.Program == null || item.Program["value"] === undefined
            ? -1
            : item.Program["value"],
        nSkillLevel: item.SkillLevel == null ? null : item.SkillLevel["value"],
        sOther:
          item.Program == null || item.Program["value"] === undefined
            ? item.Program["label"]
            : "",
      };
    });
    const arrLicese = [];
    if (selectCar) {
      arrLicese.push({
        nLicenseId: 0,
        sLicenseNo: values.CarNo,
      });
    }
    if (selectMotorcyc) {
      arrLicese.push({
        nLicenseId: 1,
        sLicenseNo: values.MotorcycleNo,
      });
    }
    if (selectOther) {
      arrLicese.push({
        nLicenseId: 2,
        sLicenseNo: values.OtherNo,
        sOther: values.OtherName,
      });
    }

    const arrJobPosition = jobPositionContext.map((item) => {
      return {
        nPositionId: item.Index,
        sJobPositionName: item.ApplyPosition,
        nJobFunctionId: item.ApplyJobFunction["value"] || 0,
        nJobDetailId: item.ApplyJobDetail["value"] || 0,
        nJobTypeId: item.ApplyJobType["value"] || 0,
        nIndustryTypeId: item.ApplyIndustryType["value"] || 0,
        nSalaryStart: item.ApplySalaryStart,
        nSalaryEnd: item.ApplySalaryEnd || null,
        IsNotSpecified: item.IsNotSpeccified || false,
      };
    });

    //Check validate salary end
    let isInvalid = false;
    let nIndex = -1;
    arrJobPosition.forEach((f) => {
      if (f.nSalaryEnd && f.nSalaryEnd < +f.nSalaryStart) {
        nIndex = f.nPositionId;
        isInvalid = true;
        return;
      }
    });

    if (isInvalid) {
      form.setFocus("ApplySalaryEnd" + nIndex);
      form.setError("ApplySalaryEnd" + nIndex, {
        type: "custom",
      });
      return false;
    }

    const dataProfile = {
      sName: form.getValues("Name"),
      sSurname: form.getValues("Surname"),
      sNameEN: form.getValues("NameEN"),
      sSurnameEN: form.getValues("SurnameEN"),
      sEmail: form.getValues("Email"),
      sOtherContact: form.getValues("OtherContact"),
      // sPWD: myProfileContext.PWD,
       IsGetNews: myProfileContext.IsGetNews,
      nSex: values.Sex["value"],
      sTel: values.Tel,
      nBirthDate: moment(values.DOB).valueOf(),
      nConscription: values.Army["value"] || 0,
      IsMarried: isMarried,
      IsWorkExperience: isWorkExperience,
      nYearWorkExperience: yearExperience ? yearExperience["value"] || 0 : 0,
      IsWork: isStartNow,
      nDay: isStartNow ? 0 : values.DayStart,
      nLast_Salary: values.CurrentSalary || null,
      nCountryId:
        addresssContext.Country && (addresssContext.Country["value"] || 0),
      nRegionId:
        addresssContext.Region && (addresssContext.Region["value"] || null),
      nProvince:
        addresssContext.Province && (addresssContext.Province["value"] || null),
      nDistrictId:
        addresssContext.District && (addresssContext.District["value"] || null),
      nSubDistrictId:
        addresssContext.SubDistrict &&
        (addresssContext.SubDistrict["value"] || null),
      sAddressNo: addresssContext.AddressNo || "",
      sMoo: addresssContext.Moo || "",
      sStreet: addresssContext.Street || "",
      sPostCode: addresssContext.PostCode || "",
      listAbility: values.Ability || [],
      listWorkExperience: arrWorkEXP,
      listEducation: arrEducateion,
      listCertificate: arrCertificate,
      listLanguage: arrLangSkill,
      listFile: fileUploadContext,
      listFileProfile: ProfileImage,
      listPosition: arrJobPosition,
      listComputerSkill: arrComputerSkill,
      listLicense: arrLicese,
      listTag: values.Tag || [],
    };

    dispatch(
      DialogActionCreators.OpenDialogSubmit(
        i18n(`${i18nField}.ConfirmMsgUpdate`),
        () => {
          dispatch(DialogActionCreators.CloseDialogSubmit() as any);
          dispatch(DialogActionCreators.LoadSubmit("Waiting...") as any);
          BlockUI();
          AxiosPost(
            `JobSeeker/UpdateProfile`,
            dataProfile,
            (result) => {
              UnBlockUI();
              const res = result.Data;
              if (res.IsSuccess) {
                dispatch(
                  DialogActionCreators.OpenDialogSuccess(
                    i18n(`${i18nField}.MsgUpdated`),
                    () => {
                      if (objJobDetail && fnAfterSubmit != null) {
                        fnAfterSubmit();
                      } else if (!objJobDetail) {
                        window.location.reload();
                      }
                    }
                  )
                );
              }
            },
            (err) => {
              if (!err.response) {
                history("/error");
                return;
              }
            }
          );
        }
      ) as any
    );
  };

  const setDataMyProfile = () => {
    const data = profileContext as any;
    const selectWorkEXP = optionWorkEXP.find((option: any) => {
      return option.value === data.nYearWorkExperience;
    });
    const selectSex =
      optionSex.find((option: any) => {
        return option.value === data.nSex;
      }) || null;
    const selectArmy =
      OptionArmy.find((option: any) => {
        return option.value === data.nConscription;
      }) || null;

    const MyProfile = {
      UserId: "",
      Name: data.sName,
      Surname: data.sSurname,
      NameEN: data.sNameEN,
      SurnameEN: data.sSurnameEN,
      Sex: selectSex,
      Email: data.sEmail,
      OtherContact: data.sOtherContact,
      IsGetNews: data.IsGetNews,
      Tel: data.sTel,
      DOB: data.dBirthDate,
      Conscription: selectArmy,
      IsMarried: data.IsMarried,
      DayStart: data.nDay,
      CurrentSalary: data.nLast_Salary,
    } as IMyProfile;
    setMyProfile(MyProfile);

    form.setValue("Name", data.sName);
    form.setValue("Surname", data.sSurname);
    form.setValue("NameEN", data.sNameEN);
    form.setValue("SurnameEN", data.sSurnameEN);
    form.setValue("Email", data.sEmail);
    form.setValue("OtherContact", data.sOtherContact);
    form.setValue("Sex", selectSex);
    form.setValue("Tel", data.sTel);
    form.setValue("DOB", data.dBirthDate);
    form.setValue("Army", selectArmy);
    form.setValue("WorkEXP", selectWorkEXP);
    form.setValue("DayStart", data.nDay);
    form.setValue("CurrentSalary", data.nLast_Salary);
    form.setValue("Ability", data.listAbility);
    form.setValue("Tag", data.listTag);

    setProfileImage(data.listFileProfile || []);

    setIsStartNow(data.nDay === 0);
    if (data.dBirthDate) setBirthDate(data.dBirthDate);
    setIsMarried(data.IsMarried);
    setSelectMarried(data.IsMarried ? "married" : "single");

    setYearExperience(selectWorkEXP);
    setIsWorkExperience(data.IsWorkExperience);
    setselectWorkEXP(() => (data.IsWorkExperience ? "yes" : "no"));
    setAbility(data.listAbility || []);

    const selectCountry = optionCountry.find(
      (f: any) => f.value === data.nCountryId
    );
    const selectRegion = optionReion.find(
      (f: any) => f.value === data.nRegionId
    );
    const selectProvince = optionProvice.find(
      (f: any) => f.value === data.nProvince
    );
    const selectDistrict = optionDistrict.find(
      (f: any) => f.value === data.nDistrictId
    );
    const selectSubDistrict = optionSubDistrict.find(
      (f: any) => f.value === data.nSubDistrictId
    );
    const address = {
      Country: selectCountry,
      Region: selectRegion,
      Province: selectProvince,
      District: selectDistrict,
      SubDistrict: selectSubDistrict,
      AddressNo: data.sAddressNo || "",
      Moo: data.sMoo || "",
      Street: data.sStreet || "",
      PostCode: data.sPostCode || "",
    } as IAddress;
    setAddress(address);

    let listWorkExperience = [];
    if ((data.listWorkExperience || []).length > 0) {
      listWorkExperience = (data.listWorkExperience || []).map((item) => {
        const selectPosition = optionPosition.find((data: any) => {
          return data.label === item.sPosition;
        });
        const selectCompany = optionCompany.find((data: any) => {
          return data.label === item.sCompany;
        });
        const selectJobFunc = optionJobFunction.find((data: any) => {
          return data.value === item.nJobFunction;
        });
        const selectJobDetail = optionJobDetail.find((data: any) => {
          return data.value === item.nJobDetail;
        });
        const selectJobIndustry = optionJobIndustry.find((data: any) => {
          return data.value === (item.nJobIndustry.toString() || "");
        });
        return {
          Index: item.nWorkExperienceId,
          Position: selectPosition,
          Company: !selectCompany
            ? { label: item.sCompany, value: 0 }
            : selectCompany,
          StartDate: item.dStartDate,
          EndDate: item.dEndDate,
          IsCurrent: item.IsCurrent,
          JobFunc: selectJobFunc,
          JobDetail: selectJobDetail,
          JobIndustry: selectJobIndustry,
          WorkRemark: item.sRemark,
          WorkSalary: item.nWorkSalary,
          LeaveRemark: item.sLeaveRemark || "",
        } as IWorkExperience;
      }) as IWorkExperience[];
    } else {
      listWorkExperience = [
        {
          Index: 0,
          Position: null,
          Company: null,
          StartDate: null,
          EndDate: null,
          IsCurrent: false,
          JobFunc: null,
          JobDetail: null,
          JobIndustry: null,
          WorkRemark: "",
          WorkSalary: null,
          LeaveRemark: "",
        },
      ] as IWorkExperience[];
    }

    setWorkExperience(listWorkExperience);

    let listEducation = [];
    if ((data.listEducation || []).length > 0) {
      listEducation = data.listEducation.map((item) => {
        const selectEduLevel = optionEducationLevel.find((data: any) => {
          return data.value === item.nEducationLevel;
        });
        // const selectUniversity = optionUniversity.find((data: any) => {
        //   return data.value === item.nUniversityId;
        // });
        const selectUniversity = optionUniversity.find((data: any) => {
          return data.label === item.sEducateName;
        });

        return {
          Index: item.nEducationId,
          // Academy: item.sEducateName,
          Academy: (item.University && item.University["label"]) || "",
          EducateLevel: selectEduLevel,
          University: !selectUniversity
            ? { label: item.sEducateName, value: 0 }
            : selectUniversity,
          // University: selectUniversity,
          Faculty: item.sFaculty,
          Major: item.sMajor,
          // GraduationYear: new Date(item.nGraduationYear, 0, 1),
          GraduationYear: moment(new Date(item.nEducational_Year, 0, 1)),
          EduRemark: item.sEducateRemark,
        } as IEducation;
      }) as IEducation[];
    } else {
      listEducation = [
        {
          Index: 0,
          Academy: "",
          EducateLevel: null,
          University: null,
          Faculty: "",
          Major: "",
          GraduationYear: null,
          EduRemark: "",
        },
      ] as IEducation[];
    }
    setEducation(listEducation);

    let listCertificate = [];
    if ((data.listCertificate || []).length > 0) {
      listCertificate = data.listCertificate.map((item) => {
        return {
          Index: item.nCertificateId,
          CertName: item.sCertificateName,
          ApproveBy: item.sApproveCert,
          ApproveDate: item.dApproveDate,
          ExpirdDate: item.dExpireDate,
          nApproveDate   : moment(item.dApproveDate).valueOf(),
          nExpireDate   : moment(item.dExpireDate).valueOf(),
          IsNotExpird: item.IsNotExpire,
          CertRemark: item.sCertRemark,
        } as ICertificate;
      }) as ICertificate[];
    } else {
      listCertificate = [
        {
          Index: 0,
          CertName: "",
          ApproveBy: "",
          ApproveDate: null,
          ExpirdDate: null,
          IsNotExpird: false,
          CertRemark: "",
        },
      ] as ICertificate[];
    }
    setCertificate(listCertificate);

    let listLanguage = [];
    if ((data.listLanguage || []).length > 0) {
      listLanguage = data.listLanguage.map((item) => {
        const selectLang =
          optionLanguage.find((data: any) => {
            return data.value === item.nLanguageId;
          }) || [];
        const selectSkillCons =
          optionLangSkill.find((data: any) => {
            return data.value === item.nSpeekingId;
          }) || [];
        const selectSkillRead =
          optionLangSkill.find((data: any) => {
            return data.value === item.nReadingId;
          }) || [];
        const selectSkillWrite =
          optionLangSkill.find((data: any) => {
            return data.value === item.nWritingId;
          }) || [];
        return {
          Index: item.nLanguageSkillId,
          Language:
            item.nLanguageId === -1
              ? { label: item.sOther, value: item.nLanguageId }
              : selectLang,
          ConvsSkill: selectSkillCons,
          ReadSkill: selectSkillRead,
          WriteSkill: selectSkillWrite,
        } as ILanguageSkill;
      }) as ILanguageSkill[];
    } else {
      listLanguage = [
        {
          Index: 0,
          Language: null,
          ConvsSkill: null,
          ReadSkill: null,
          WriteSkill: null,
        },
      ] as ILanguageSkill[];
    }
    setLanguageSkill(listLanguage);

    let listPosition = [];
    if ((data.listPosition || []).length > 0) {
      listPosition = data.listPosition.map((item) => {
        const selectJobFunc = optionJobFunction.find((data: any) => {
          return data.value === item.nJobFunctionId;
        });
        const selectJobDetail = optionJobDetail.find((data: any) => {
          return data.value === item.nJobDetailId;
        });
        const selectJobType = optionJobType.find((data: any) => {
          return data.value === item.nJobTypeId;
        });
        const selectJobIndustry = optionJobIndustry.find((data: any) => {
          return data.value === (item.nIndustryTypeId.toString() || "");
        });
        return {
          Index: item.nPositionId,
          ApplyPosition: item.sJobPositionName,
          ApplyJobFunction: selectJobFunc,
          ApplyJobDetail: selectJobDetail,
          ApplyJobType: selectJobType,
          ApplyIndustryType: selectJobIndustry,
          ApplySalaryStart: item.nSalaryStart,
          ApplySalaryEnd: item.nSalaryEnd,
          IsNotSpeccified: item.IsNotSpecified,

        } as IMyJobPosition;
      }) as IMyJobPosition[];
    } else {
      listPosition = [
        {
          Index: 0,
          ApplyPosition: null,
          ApplyJobFunction: null,
          ApplyJobDetail: null,
          ApplyJobType: null,
          ApplyIndustryType: null,
          ApplySalaryStart: null,
          ApplySalaryEnd: null,
          IsNotSpeccified: false,
        },
      ] as IMyJobPosition[];
    }
    setJobPosition(listPosition);

    if ((data.listComputerSkill || []).length > 0) {
      const listComputerSkill = (data.listComputerSkill || []).map((item) => {
        const selectProgram =
          OptionProgram.find((f: any) => f.value === item.nProgramId) || {};
        const selectSkill =
          optionLangSkill.find((f: any) => f.value === item.nSkillLevel) || {};
        let objSkill = {
          Index: item.nComputerSkillId,
          Program:
            item.nProgramId === -1
              ? { label: item.sOther, value: item.nProgramId }
              : selectProgram,
          SkillLevel: selectSkill,
        } as IComputerSkill;
        return objSkill;
      }) as IComputerSkill[];
      setComputerSkill(listComputerSkill);

    } else {
      setComputerSkill([
        {
          Index: 0,
          Program: null,
          SkillLevel: null,
        },
      ] as Array<IComputerSkill>);
    }

    setFileUpload(data.listFile || []);

    (data.listLicense || []).forEach((item) => {
      let arrClone = selectLicense;

      if (item.nLicenseId === 0) {
        arrClone.selectCar = true;
        form.setValue("CarNo", item.sLicenseNo);
      }
      if (item.nLicenseId === 1) {
        arrClone.selectMotorcyc = true;
        form.setValue("MotorcycleNo", item.sLicenseNo);
      }
      if (item.nLicenseId === 2) {
        arrClone.selectOther = true;
        form.setValue("OtherNo", item.sLicenseNo);
        form.setValue("OtherName", item.sOther);
      }
      setSelectLicense({ ...arrClone });
    });
  };

  // useEffect(() => { }, [
  //   arrWorkExperienceContext,
  //   yearExperience,
  //   myProfileContext,
  //   computerSkillContext,
  //   isWorkExperience,
  // ]);

  useEffect(() => {
    if (!isLoading) {
      setDataMyProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoading,
  ]);


  return isLoading ? (
    <></>
  ) : (
    <FormProvider {...form}>
      {/* phone size */}
      <Hidden smUp>
        <AccordionCustom
          sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
          expanded={expandedProfile}
          onChange={() => setExpandedProfile((prev) => !prev)}
        >
          <AccordionSummaryCustom>
            <Typography sx={{ width: "80%", flexShrink: 0 }}>
              {i18n(`${i18nField}.PanelProfile`)}
            </Typography>
          </AccordionSummaryCustom>
          <AccordionDetailsCustom>
            <Box
              component={Paper}
              sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
            >
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Grid item container spacing={2} xs={12}>
                  <Grid item xs={12} sm={12} md={6}>
                    <Input
                      name={"Name"}
                      fullWidth
                      label={
                        <Typography sx={{ fontWeight: 400 }} component="label">
                          {i18n(`${i18nField}.Name`)}
                        </Typography>
                      }
                      small={true}
                      maxLength={100}
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Input
                      name={"Surname"}
                      fullWidth
                      required
                      maxLength={100}
                      small={true}
                      label={
                        <Typography sx={{ fontWeight: 400 }} component="label">
                          {i18n(`${i18nField}.Surname`)}
                        </Typography>
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Input
                      name={"NameEN"}
                      fullWidth
                      label={
                        <Typography sx={{ fontWeight: 400 }} component="label">
                          {i18n(`${i18nField}.NameEN`)}
                        </Typography>
                      }
                      small={true}
                      maxLength={100}
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Input
                      name={"SurnameEN"}
                      fullWidth
                      required
                      maxLength={100}
                      small={true}
                      label={
                        <Typography sx={{ fontWeight: 400 }} component="label">
                          {i18n(`${i18nField}.SurnameEN`)}
                        </Typography>
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={2}>
                    <AutoCompleteSelect
                      name={"Sex"}
                      fullWidth
                      label={i18n(`${i18nField}.Sex`)}
                      options={optionSex}
                      onChange={(value) => { }}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={5}>
                    <Input
                      name={"Email"}
                      fullWidth
                      required
                      maxLength={100}
                      small={true}
                      label={
                        <Typography sx={{ fontWeight: 400 }} component="label">
                          {i18n(`${i18nField}.Email`)}
                        </Typography>
                      }
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={5}>
                    <Input
                      name={"Tel"}
                      fullWidth
                      required
                      maxLength={50}
                      small={true}
                      label={
                        <Typography sx={{ fontWeight: 400 }} component="label">
                          {i18n(`${i18nField}.Tel`)}
                        </Typography>
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Input
                      name={"OtherContact"}
                      fullWidth
                      maxLength={200}
                      small={true}
                      label={
                        <Typography sx={{ fontWeight: 400 }} component="label">
                          {i18n(`${i18nField}.OtherContact`)}
                        </Typography>
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <div style={{ display: "flex" }}>
                      <Grid item xs={12} md={3}>
                        <DatePickerFormItem
                          name={"DOB"}
                          label={
                            <Typography sx={{ fontWeight: 400 }} component="label">
                              {i18n(`${i18nField}.DOB`)}
                            </Typography>
                          }
                          small={true}
                          onChange={(value) => {
                            setBirthDate(() => value);
                          }}
                          required
                        />
                      </Grid>
                      <Grid item xs={8} md={2}>
                        <DivAge>{handleCalAge()}</DivAge>
                      </Grid>
                    </div>
                  </Grid>

                  <Grid item md={4} xs={12}>
                    <FormControl>
                      <FormLabel>{i18n(`${i18nField}.MarriedStatus`)}</FormLabel>
                      <RadioGroup
                        row
                        value={selectMarried}
                        onChange={(e, v) => {
                          setIsMarried(v === "married");
                          setSelectMarried(v);
                        }}
                      >
                        <FormControlLabel
                          value="single"
                          control={<Radio />}
                          label={i18n(`${i18nField}.Single`)}
                        />
                        <FormControlLabel
                          value="married"
                          control={<Radio />}
                          label={i18n(`${i18nField}.Married`)}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormLabel>{i18n(`${i18nField}.Army`)}</FormLabel>
                    <AutoCompleteSelect
                      name={"Army"}
                      fullWidth
                      // label={i18n(`${i18nField}.Army`)}
                      options={OptionArmy}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>

                      <FormLabel>{i18n(`${i18nField}.PanelWorkEXP`)}</FormLabel>

                      <RadioGroup
                        row
                        value={selectWorkEXP}
                        onChange={(e, v) => {
                          setIsWorkExperience(v === "yes");

                          setselectWorkEXP(v);
                        }}
                      >
                        <FormControlLabel
                          value={"yes"}
                          control={<Radio />}
                          label={i18n(`${i18nField}.HaveWorkEXP`)}
                        />
                        <FormControlLabel
                          value={"no"}
                          control={<Radio />}
                          label={i18n(`${i18nField}.NotWordEXP`)}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  {isWorkExperience ? (
                    <Grid item xs={12} sm={12} md={6}>
                      <AutoCompleteSelect
                        name={"WorkEXP"}
                        fullWidth
                        label={i18n(`${i18nField}.WorkEXP`)}
                        options={optionWorkEXP}
                        onChange={(value) => {
                          setYearExperience(value);
                        }}
                        required
                      />
                    </Grid>
                  ) : null}

                  <Grid item xs={12}>
                    <FormControl>
                      <FormLabel>{i18n(`${i18nField}.UploadProfile`)}</FormLabel>
                      <UploadFile
                        id="myImage"
                        keyID={2}
                        arrFile={ProfileImage || []}
                        setarrFile={setProfileImage}
                        nLimitFile={2} //ขนาดไฟล์ไม่เกิน 2 Mb
                        IsFolder={false}
                        IsFile={true}
                        IsMultiple={false}
                        Extension={["jpg", "jpeg", "png", "gif"]}
                        IsDrag={false}
                        isSetupEmployer={false}
                        isUploadJobSeekerPicture={true}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </AccordionDetailsCustom>
        </AccordionCustom>

        {isWorkExperience ? (
          <AccordionCustom
            sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
            expanded={expandedWork}
            onChange={() => setExpandedWork((prev) => !prev)}
          >
            <AccordionSummaryCustom>
              <Typography sx={{ width: "80%", flexShrink: 0, whiteSpace: "nowrap" }}>
                {i18n(`${i18nField}.PanelWorkEXP`)}
              </Typography>
            </AccordionSummaryCustom>
            <AccordionDetailsCustom>
              {(arrWorkExperienceContext || []).map(
                (item: IWorkExperience, index) => {
                  return (
                    <WorkExperience
                      item={item}
                      index={index}
                      key={index}
                      form={form}
                      setSchemaWork={setSchemaWork}
                      schemaWork={schemaWork}
                      isRequired={isWorkExperience}
                    />
                  );
                }
              )}
              {!isWorkExperience ? null : (
                <Stack
                  sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
                  direction="row"
                  justifyContent="center"
                >
                  <BtnRadiusProfile
                    txt={i18n(`${i18nField}.AddWorkDetail`)}
                    onClick={handleAddWorkUnit}
                  />
                </Stack>
              )}
            </AccordionDetailsCustom>
          </AccordionCustom>
        ) : null}

        <AccordionCustom
          sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
          expanded={expandedAddress}
          onChange={() => setExpandedAddress((prev) => !prev)}
        >
          <AccordionSummaryCustom>
            <Typography sx={{ width: "80%", flexShrink: 0 }}>
              {i18n(`${i18nField}.PanelAddress`)}
            </Typography>
          </AccordionSummaryCustom>
          <AccordionDetailsCustom>
            <MyAddress form={form} setIsInterMain={setIsInterMain} />
          </AccordionDetailsCustom>
        </AccordionCustom>

        <AccordionCustom
          sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
          expanded={expandedEducation}
          onChange={() => setExpandedEducation((prev) => !prev)}
        >
          <AccordionSummaryCustom>
            <Typography sx={{ width: "80%", flexShrink: 0, whiteSpace: "nowrap" }}>
              {i18n(`${i18nField}.PanelEducation`)}
            </Typography>
          </AccordionSummaryCustom>
          <AccordionDetailsCustom>
            {(educationContext || []).map((item: IEducation, index) => {
              return (
                <EducationHistory
                  item={item}
                  index={index}
                  key={index}
                  form={form}
                />
              );
            })}
            <Stack
              sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
              direction="row"
              justifyContent="center"
            >
              <BtnRadiusProfile
                txt={i18n(`${i18nField}.AddWorkDetail`)}
                onClick={handleAddEducate}
              />
            </Stack>
          </AccordionDetailsCustom>
        </AccordionCustom>

        <AccordionCustom
          sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
          expanded={expandedCertificate}
          onChange={() => setExpandedCertificate((prev) => !prev)}
        >
          <AccordionSummaryCustom>
            <Typography sx={{ width: "80%", flexShrink: 0 }}>
              {i18n(`${i18nField}.PanelCert`)}
            </Typography>
          </AccordionSummaryCustom>
          <AccordionDetailsCustom>
            {(certificateContext || []).map((item: ICertificate, index) => {
              return (
                <MyCertificate
                  item={item}
                  index={index}
                  key={index}
                  form={form}
                />
              );
            })}
            <Stack
              sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
              direction="row"
              justifyContent="center"
            >
              <BtnRadiusProfile
                txt={i18n(`${i18nField}.AddWorkDetail`)}
                onClick={handleAddCertificate}
              />
            </Stack>
          </AccordionDetailsCustom>
        </AccordionCustom>

        <AccordionCustom
          sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
          expanded={expandedAbility}
          onChange={() => setExpandedAbility((prev) => !prev)}
        >
          <AccordionSummaryCustom>
            <Typography sx={{ width: "80%", flexShrink: 0 }}>
              {i18n(`${i18nField}.PanelAbility`)}
            </Typography>
          </AccordionSummaryCustom>
          <AccordionDetailsCustom>
            <Grid
              sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
              item
              md={12}
              xs={12}
            >
              <AutoCompleteRenderItem
                isFreeCreate
                name={"Ability"}
                fullWidth
                label={i18n(`${i18nField}.Ability`)}
                options={[]}
              />
              <FormHelperText sx={{ color: "grey" }}>
                {i18n(`${i18nField}.MsgSkill`)}
              </FormHelperText>
            </Grid>
          </AccordionDetailsCustom>
        </AccordionCustom>

        <AccordionCustom
          sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
          expanded={expandedLangSkill}
          onChange={() => setExpandedLangSkill((prev) => !prev)}
        >
          <AccordionSummaryCustom>
            <Typography sx={{ width: "80%", flexShrink: 0, whiteSpace: "nowrap" }}>
              {i18n(`${i18nField}.PanelLangPro`)}
            </Typography>
          </AccordionSummaryCustom>
          <AccordionDetailsCustom>
            {(languageSkillContext || []).map((item: ILanguageSkill, index) => {
              return (
                <LanguageSkill
                  item={item}
                  index={index}
                  key={index}
                  form={form}
                />
              );
            })}
            <Stack
              sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
              direction="row"
              justifyContent="center"
            >
              <BtnRadiusProfile
                txt={i18n(`${i18nField}.AddWorkDetail`)}
                onClick={handleAddLanguageSkill}
              />
            </Stack>
          </AccordionDetailsCustom>
        </AccordionCustom>

        <AccordionCustom
          sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
          expanded={expandedFile}
          onChange={() => setExpandedFile((prev) => !prev)}
        >
          <AccordionSummaryCustom>
            <Typography sx={{ width: "80%", flexShrink: 0 }}>
              {i18n(`${i18nField}.PanelMyFile`)}
            </Typography>
          </AccordionSummaryCustom>
          <AccordionDetailsCustom>
            <Grid sx={{ border: "10px", p: 1, flex: 1, m: "2%" }} item xs={12}>
              <UploadFile
                id="myFile"
                keyID={1}
                arrFile={fileUploadContext || []}
                setarrFile={setFileUpload}
                nLimitFile={2} //ขนาดไฟล์ไม่เกิน 2 Mb
                nLimitCountFile={5} //Upload ได้ 5 file
                IsFolder={false}
                IsFile={true}
                IsMultiple={true}
                Extension={["docx", "pptx", "doc", "ppt", "pdf"]}
                IsDrag={false}
                isSetupEmployer={false}
              />
            </Grid>
          </AccordionDetailsCustom>
        </AccordionCustom>

        <AccordionCustom
          sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
          expanded={expandComputerSkill}
          onChange={() => setExpandComputerSkill((prev) => !prev)}
        >
          <AccordionSummaryCustom>
            <Typography sx={{ width: "80%", flexShrink: 0, whiteSpace: "nowrap" }}>
              {i18n(`${i18nField}.PanelComputerSkill`)}
            </Typography>
          </AccordionSummaryCustom>
          <AccordionDetailsCustom>
            {(computerSkillContext || []).map((item: IComputerSkill, index) => {
              return (
                <MyComputerSkill
                  item={item}
                  index={index}
                  key={index}
                  form={form}
                />
              );
            })}
            <Stack
              sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
              direction="row"
              justifyContent="center"
            >
              <BtnRadiusProfile
                txt={i18n(`${i18nField}.AddWorkDetail`)}
                onClick={handleAddComputerSkill}
              />
            </Stack>
          </AccordionDetailsCustom>
        </AccordionCustom>

        <AccordionCustom
          sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
          expanded={expandDiverLicense}
          onChange={() => setExpandDiverLicense((prev) => !prev)}
        >
          <AccordionSummaryCustom>
            <Typography sx={{ width: "80%", flexShrink: 0, whiteSpace: "nowrap" }}>
              {i18n(`${i18nField}.PanelDriving`)}
            </Typography>
          </AccordionSummaryCustom>
          <AccordionDetailsCustom>
            <Box
              component={Paper}
              sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
            >
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Grid item container spacing={2} xs={12}>
                  <Grid item xs={12}>
                    <FormGroup>
                      <div style={{ display: "inherit", paddingBottom: "10px" }}>
                        <FormControlLabel
                          style={{ width: "200px" }}
                          control={
                            <Checkbox
                              checked={selectCar}
                              name="Car"
                              onChange={(e) => {
                                setSelectLicense({
                                  ...selectLicense,
                                  selectCar: e.target.checked,
                                });
                                if (!e.target.checked) {
                                  form.clearErrors("CarNo");
                                }
                              }}
                            />
                          }
                          label={i18n(`${i18nField}.Car`)}
                        />
                        <Grid item md={4} xs={12}>
                          {selectCar ? (
                            <Input
                              name={"CarNo"}
                              small
                              required
                              maxLength={50}
                              label={
                                <Typography
                                  sx={{ fontWeight: 400 }}
                                  component="label"
                                >
                                  {i18n(`${i18nField}.LicenseNo`)}
                                </Typography>
                              }
                            />
                          ) : null}
                        </Grid>
                      </div>
                      <div style={{ display: "inherit", paddingBottom: "10px" }}>
                        <FormControlLabel
                          style={{ width: "200px" }}
                          control={
                            <Checkbox
                              checked={selectMotorcyc}
                              name="Motorcycle"
                              onChange={(e) => {
                                setSelectLicense({
                                  ...selectLicense,
                                  selectMotorcyc: e.target.checked,
                                });
                                if (!e.target.checked) {
                                  form.clearErrors("MotorcycleNo");
                                }
                              }}
                            />
                          }
                          label={i18n(`${i18nField}.Motorcycle`)}
                        />
                        <Grid item md={4} xs={12}>
                          {selectMotorcyc ? (
                            <Input
                              name={"MotorcycleNo"}
                              small
                              required
                              maxLength={50}
                              label={
                                <Typography
                                  sx={{ fontWeight: 400 }}
                                  component="label"
                                >
                                  {i18n(`${i18nField}.LicenseNo`)}
                                </Typography>
                              }
                            />
                          ) : null}
                        </Grid>
                      </div>

                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectOther}
                            name="Other"
                            onChange={(e) => {
                              setSelectLicense({
                                ...selectLicense,
                                selectOther: e.target.checked,
                              });
                              if (!e.target.checked) {
                                form.clearErrors("OtherName");
                                form.clearErrors("OtherNo");
                              }
                            }}
                          />
                        }
                        label={i18n(`${i18nField}.Other`)}
                      />
                      {selectOther ? (
                        <Grid container spacing={1} xs={12}>
                          <Grid item md={4} xs={12}>
                            <Input
                              name={"OtherName"}
                              small
                              required
                              maxLength={50}
                            />
                          </Grid>
                          <Grid item md={4} xs={12}>
                            <Input
                              name={"OtherNo"}
                              small
                              required
                              maxLength={50}
                              label={
                                <Typography
                                  sx={{ fontWeight: 400 }}
                                  component="label"
                                >
                                  {i18n(`${i18nField}.LicenseNo`)}
                                </Typography>
                              }
                            />
                          </Grid>
                        </Grid>
                      ) : null}
                    </FormGroup>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </AccordionDetailsCustom>
        </AccordionCustom>

        <AccordionCustom
          sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
          expanded={expandedJobApply}
          onChange={() => setExpandedJobApply((prev) => !prev)}
        >
          <AccordionSummaryCustom>
            <Typography sx={{ width: "80%", flexShrink: 0, whiteSpace: "nowrap" }}>
              {i18n(`${i18nField}.PanelJobApply`)}
            </Typography>
          </AccordionSummaryCustom>
          <AccordionDetailsCustom>
            {(jobPositionContext || []).map((item: IMyJobPosition, index) => {
              return (
                <MyJobPositon item={item} index={index} key={index} form={form} />
              );
            })}
            <Stack
              sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
              direction="row"
              justifyContent="center"
            >
              <BtnRadiusProfile
                txt={i18n(`${i18nField}.AddWorkDetail`)}
                onClick={handleAddJobAppiy}
              />
            </Stack>
          </AccordionDetailsCustom>
        </AccordionCustom>

        <Box component={Paper} sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}>
          <Grid
            container
            spacing={1}
            sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
          >
            <Grid item xs={12}>
              <FormControl>
                <RadioGroup
                  value={isStartNow ? "now" : "late"}
                  onChange={(e, v) => {
                    setIsStartNow(v === "now");
                    if (v === "now") {
                      form.setValue("DayStart", null);
                      form.clearErrors("DayStart");
                    }
                  }}
                >
                  <FormControlLabel
                    value="now"
                    control={<Radio />}
                    label={i18n(`${i18nField}.StartNow`)}
                  />
                  <div style={{ display: "inherit" }}>
                    <FormControlLabel
                      style={{ width: "800px" }}
                      value="late"
                      control={<Radio />}
                      label={i18n(`${i18nField}.StartLate`)}
                    />
                    <Input
                      name={"DayStart"}
                      small
                      sx={{ m: 1, width: "10ch" }}
                      disabled={isStartNow}
                      required={!isStartNow}
                      endAdornment={
                        <InputAdornment position="end">
                          {i18n(`${i18nField}.Day`)}
                        </InputAdornment>
                      }
                    />
                  </div>
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item md={3} xs={11}>
              {/* <Input
              name={"CurrentSalary"}
              small
              label={
                <Typography sx={{ fontWeight: 400 }} component="label">
                  {i18n(`${i18nField}.CurrentSalary`)}
                </Typography>
              }
            /> */}

              <InputNumber
                name={"CurrentSalary"}
                small
                label={i18n(`${i18nField}.CurrentSalary`)}
              />
            </Grid>
          </Grid>

          <AccordionCustom
            sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
            expanded={expandedAbility}
            onChange={() => setExpandedAbility((prev) => !prev)}
          >
            <AccordionSummaryCustom>
              <Typography sx={{ flexShrink: 0, width: "80%", }}>
                {i18n(`${i18nField}.PanelTag`)}
              </Typography>
            </AccordionSummaryCustom>
            <AccordionDetailsCustom>
              <Grid
                sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
                item
                md={12}
                xs={12}
              >
                <AutoCompleteRenderItem
                  isFreeCreate
                  name={"Tag"}
                  fullWidth
                  label={i18n(`${i18nField}.TagName`)}
                  options={[]}
                />
                <FormHelperText sx={{ color: "grey" }}>
                  {i18n(`${i18nField}.MsgTag`)}
                </FormHelperText>
              </Grid>
            </AccordionDetailsCustom>
          </AccordionCustom>
        </Box>
        <Stack
          spacing={1}
          sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
          direction="row"
          justifyContent="right"
          alignItems="right"
        >
          <BtnSubmit
            onClick={form.handleSubmit(onSubmit, (err) =>
              console.log("error", err)
            )}
            txt={i18n(`${i18nCommon}.save`)}
          />
          {objJobDetail && (
            <BtnBack
              onClick={() => {
                history(
                  objJobDetail && objJobDetail.sFromPage === "MyJobInterested"
                    ? "/MyJobInterested"
                    : "/SearchJob"
                );
              }}
              txt={i18n(`${i18nCommon}.back`)}
            />
          )}
        </Stack>
      </Hidden>

      {/* normal size */}
      <Hidden smDown>
        <Grid sx={{ mx: "9%" }}>
          <AccordionCustom
            sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
            expanded={expandedProfile}
            onChange={() => setExpandedProfile((prev) => !prev)}
          >
            <AccordionSummaryCustom>
              <Typography sx={{ width: "80%", flexShrink: 0 }}>
                {i18n(`${i18nField}.PanelProfile`)}
              </Typography>
            </AccordionSummaryCustom>
            <AccordionDetailsCustom>
              <Box
                component={Paper}
                sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
              >
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item container spacing={2} xs={12}>
                    <Grid item xs={12} sm={12} md={6}>
                      <Input
                        name={"Name"}
                        fullWidth
                        label={
                          <Typography sx={{ fontWeight: 400 }} component="label">
                            {i18n(`${i18nField}.Name`)}
                          </Typography>
                        }
                        small={true}
                        maxLength={100}
                        required={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <Input
                        name={"Surname"}
                        fullWidth
                        required
                        maxLength={100}
                        small={true}
                        label={
                          <Typography sx={{ fontWeight: 400 }} component="label">
                            {i18n(`${i18nField}.Surname`)}
                          </Typography>
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <Input
                        name={"NameEN"}
                        fullWidth
                        label={
                          <Typography sx={{ fontWeight: 400 }} component="label">
                            {i18n(`${i18nField}.NameEN`)}
                          </Typography>
                        }
                        small={true}
                        maxLength={100}
                        required={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <Input
                        name={"SurnameEN"}
                        fullWidth
                        required
                        maxLength={100}
                        small={true}
                        label={
                          <Typography sx={{ fontWeight: 400 }} component="label">
                            {i18n(`${i18nField}.SurnameEN`)}
                          </Typography>
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                      <AutoCompleteSelect
                        name={"Sex"}
                        fullWidth
                        label={i18n(`${i18nField}.Sex`)}
                        options={optionSex}
                        onChange={(value) => { }}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={5}>
                      <Input
                        name={"Email"}
                        fullWidth
                        required
                        maxLength={100}
                        small={true}
                        label={
                          <Typography sx={{ fontWeight: 400 }} component="label">
                            {i18n(`${i18nField}.Email`)}
                          </Typography>
                        }
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={5}>
                      <Input
                        name={"Tel"}
                        fullWidth
                        required
                        maxLength={50}
                        small={true}
                        label={
                          <Typography sx={{ fontWeight: 400 }} component="label">
                            {i18n(`${i18nField}.Tel`)}
                          </Typography>
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <Input
                        name={"OtherContact"}
                        fullWidth
                        maxLength={200}
                        small={true}
                        label={
                          <Typography sx={{ fontWeight: 400 }} component="label">
                            {i18n(`${i18nField}.OtherContact`)}
                          </Typography>
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <div style={{ display: "flex" }}>
                        <Grid item xs={12} md={3}>
                          <DatePickerFormItem
                            name={"DOB"}
                            label={
                              <Typography sx={{ fontWeight: 400 }} component="label">
                                {i18n(`${i18nField}.DOB`)}
                              </Typography>
                            }
                            small={true}
                            onChange={(value) => {
                              setBirthDate(() => value);
                            }}
                            required
                          />
                        </Grid>
                        <Grid item xs={8} md={2}>
                          <DivAge>{handleCalAge()}</DivAge>
                        </Grid>

                      </div>
                    </Grid>

                    <Grid item md={4} xs={12}>
                      <FormControl>
                        <FormLabel>{i18n(`${i18nField}.MarriedStatus`)}</FormLabel>
                        <RadioGroup
                          row
                          value={selectMarried}
                          onChange={(e, v) => {
                            setIsMarried(v === "married");
                            setSelectMarried(v);
                          }}
                        >
                          <FormControlLabel
                            value="single"
                            control={<Radio />}
                            label={i18n(`${i18nField}.Single`)}
                          />
                          <FormControlLabel
                            value="married"
                            control={<Radio />}
                            label={i18n(`${i18nField}.Married`)}
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormLabel>{i18n(`${i18nField}.Army`)}</FormLabel>
                      <AutoCompleteSelect
                        name={"Army"}
                        fullWidth
                        // label={i18n(`${i18nField}.Army`)}
                        options={OptionArmy}
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl>
                        <FormLabel>{i18n(`${i18nField}.PanelWorkEXP`)}</FormLabel>
                        <RadioGroup
                          row
                          value={selectWorkEXP}
                          onChange={(e, v) => {
                            setIsWorkExperience(v === "yes");

                            setselectWorkEXP(v);
                          }}
                        >
                          <FormControlLabel
                            value={"yes"}
                            control={<Radio />}
                            label={i18n(`${i18nField}.HaveWorkEXP`)}
                          />
                          <FormControlLabel
                            value={"no"}
                            control={<Radio />}
                            label={i18n(`${i18nField}.NotWordEXP`)}
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    {isWorkExperience ? (
                      <Grid item xs={12} sm={12} md={6}>
                        <AutoCompleteSelect
                          name={"WorkEXP"}
                          fullWidth
                          label={i18n(`${i18nField}.WorkEXP`)}
                          options={optionWorkEXP}
                          onChange={(value) => {
                            setYearExperience(value);
                          }}
                          required
                        />
                      </Grid>
                    ) : null}

                    <Grid item xs={12}>
                      <FormControl>
                        <FormLabel>{i18n(`${i18nField}.UploadProfile`)}</FormLabel>
                        <UploadFile
                          id="myImage"
                          keyID={2}
                          arrFile={ProfileImage || []}
                          setarrFile={setProfileImage}
                          nLimitFile={2} //ขนาดไฟล์ไม่เกิน 2 Mb
                          IsFolder={false}
                          IsFile={true}
                          IsMultiple={false}
                          Extension={["jpg", "jpeg", "png", "gif"]}
                          IsDrag={false}
                          isSetupEmployer={false}
                          isUploadJobSeekerPicture={true}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </AccordionDetailsCustom>
          </AccordionCustom>

          {isWorkExperience ? (

            <AccordionCustom
              sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
              expanded={expandedWork}
              onChange={() => setExpandedWork((prev) => !prev)}
            >
              <div ref={oFormRef}>
                <AccordionSummaryCustom>
                  <Typography sx={{ width: "80%", flexShrink: 0 }}>
                    {i18n(`${i18nField}.PanelWorkEXP`)}
                  </Typography>
                </AccordionSummaryCustom>
              </div>
              <AccordionDetailsCustom>
                {(arrWorkExperienceContext || []).map(
                  (item: IWorkExperience, index) => {
                    return (
                      <WorkExperience
                        item={item}
                        index={index}
                        key={index}
                        form={form}
                        setSchemaWork={setSchemaWork}
                        schemaWork={schemaWork}
                        isRequired={isWorkExperience}
                      />
                    );
                  }
                )}
                {!isWorkExperience ? null : (
                  <Stack
                    sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
                    direction="row"
                    justifyContent="center"
                  >
                    <BtnRadiusProfile
                      txt={i18n(`${i18nField}.AddWorkDetail`)}
                      onClick={handleAddWorkUnit}
                    />
                  </Stack>
                )}
              </AccordionDetailsCustom>
            </AccordionCustom>

          ) : null}

          <AccordionCustom
            sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
            expanded={expandedAddress}
            onChange={() => setExpandedAddress((prev) => !prev)}
          >
            <AccordionSummaryCustom>
              <Typography sx={{ width: "80%", flexShrink: 0 }}>
                {i18n(`${i18nField}.PanelAddress`)}
              </Typography>
            </AccordionSummaryCustom>
            <AccordionDetailsCustom>
              <MyAddress form={form} setIsInterMain={setIsInterMain} />
            </AccordionDetailsCustom>
          </AccordionCustom>

          <AccordionCustom
            sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
            expanded={expandedEducation}
            onChange={() => setExpandedEducation((prev) => !prev)}
          >
            <AccordionSummaryCustom>
              <Typography sx={{ width: "80%", flexShrink: 0 }}>
                {i18n(`${i18nField}.PanelEducation`)}
              </Typography>
            </AccordionSummaryCustom>
            <AccordionDetailsCustom>
              {(educationContext || []).map((item: IEducation, index) => {
                return (
                  <EducationHistory
                    item={item}
                    index={index}
                    key={index}
                    form={form}
                  />
                );
              })}
              <Stack
                sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
                direction="row"
                justifyContent="center"
              >
                <BtnRadiusProfile
                  txt={i18n(`${i18nField}.AddWorkDetail`)}
                  onClick={handleAddEducate}
                />
              </Stack>
            </AccordionDetailsCustom>
          </AccordionCustom>

          <AccordionCustom
            sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
            expanded={expandedCertificate}
            onChange={() => setExpandedCertificate((prev) => !prev)}
          >
            <AccordionSummaryCustom>
              <Typography sx={{ width: "80%", flexShrink: 0 }}>
                {i18n(`${i18nField}.PanelCert`)}
              </Typography>
            </AccordionSummaryCustom>
            <AccordionDetailsCustom>
              {(certificateContext || []).map((item: ICertificate, index) => {
                return (
                  <MyCertificate
                    item={item}
                    index={index}
                    key={index}
                    form={form}
                  />
                );
              })}
              <Stack
                sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
                direction="row"
                justifyContent="center"
              >
                <BtnRadiusProfile
                  txt={i18n(`${i18nField}.AddWorkDetail`)}
                  onClick={handleAddCertificate}
                />
              </Stack>
            </AccordionDetailsCustom>
          </AccordionCustom>

          <AccordionCustom
            sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
            expanded={expandedAbility}
            onChange={() => setExpandedAbility((prev) => !prev)}
          >
            <AccordionSummaryCustom>
              <Typography sx={{ width: "80%", flexShrink: 0 }}>
                {i18n(`${i18nField}.PanelAbility`)}
              </Typography>
            </AccordionSummaryCustom>
            <AccordionDetailsCustom>
              <Grid
                sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
                item
                md={12}
                xs={12}
              >
                <AutoCompleteRenderItem
                  isFreeCreate
                  name={"Ability"}
                  fullWidth
                  label={i18n(`${i18nField}.Ability`)}
                  options={[]}
                />
                <FormHelperText sx={{ color: "grey" }}>
                  {i18n(`${i18nField}.MsgSkill`)}
                </FormHelperText>
              </Grid>
            </AccordionDetailsCustom>
          </AccordionCustom>

          <AccordionCustom
            sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
            expanded={expandedLangSkill}
            onChange={() => setExpandedLangSkill((prev) => !prev)}
          >
            <AccordionSummaryCustom>
              <Typography sx={{ width: "80%", flexShrink: 0 }}>
                {i18n(`${i18nField}.PanelLangPro`)}
              </Typography>
            </AccordionSummaryCustom>
            <AccordionDetailsCustom>
              {(languageSkillContext || []).map((item: ILanguageSkill, index) => {
                return (
                  <LanguageSkill
                    item={item}
                    index={index}
                    key={index}
                    form={form}
                  />
                );
              })}
              <Stack
                sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
                direction="row"
                justifyContent="center"
              >
                <BtnRadiusProfile
                  txt={i18n(`${i18nField}.AddWorkDetail`)}
                  onClick={handleAddLanguageSkill}
                />
              </Stack>
            </AccordionDetailsCustom>
          </AccordionCustom>

          <AccordionCustom
            sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
            expanded={expandedFile}
            onChange={() => setExpandedFile((prev) => !prev)}
          >
            <AccordionSummaryCustom>
              <Typography sx={{ width: "80%", flexShrink: 0 }}>
                {i18n(`${i18nField}.PanelMyFile`)}
              </Typography>
            </AccordionSummaryCustom>
            <AccordionDetailsCustom>
              <Grid sx={{ border: "10px", p: 1, flex: 1, m: "2%" }} item xs={12}>
                <UploadFile
                  id="myFile"
                  keyID={1}
                  arrFile={fileUploadContext || []}
                  setarrFile={setFileUpload}
                  nLimitFile={2} //ขนาดไฟล์ไม่เกิน 2 Mb
                  nLimitCountFile={5} //Upload ได้ 5 file
                  IsFolder={false}
                  IsFile={true}
                  IsMultiple={true}
                  Extension={["docx", "pptx", "doc", "ppt", "pdf"]}
                  IsDrag={false}
                  isSetupEmployer={false}
                />
              </Grid>
            </AccordionDetailsCustom>
          </AccordionCustom>

          <AccordionCustom
            sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
            expanded={expandComputerSkill}
            onChange={() => setExpandComputerSkill((prev) => !prev)}
          >
            <AccordionSummaryCustom>
              <Typography sx={{ width: "80%", flexShrink: 0 }}>
                {i18n(`${i18nField}.PanelComputerSkill`)}
              </Typography>
            </AccordionSummaryCustom>
            <AccordionDetailsCustom>
              {(computerSkillContext || []).map((item: IComputerSkill, index) => {
                return (
                  <MyComputerSkill
                    item={item}
                    index={index}
                    key={index}
                    form={form}
                  />
                );
              })}
              <Stack
                sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
                direction="row"
                justifyContent="center"
              >
                <BtnRadiusProfile
                  txt={i18n(`${i18nField}.AddWorkDetail`)}
                  onClick={handleAddComputerSkill}
                />
              </Stack>
            </AccordionDetailsCustom>
          </AccordionCustom>

          <AccordionCustom
            sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
            expanded={expandDiverLicense}
            onChange={() => setExpandDiverLicense((prev) => !prev)}
          >
            <AccordionSummaryCustom>
              <Typography sx={{ width: "80%", flexShrink: 0 }}>
                {i18n(`${i18nField}.PanelDriving`)}
              </Typography>
            </AccordionSummaryCustom>
            <AccordionDetailsCustom>
              <Box
                component={Paper}
                sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
              >
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item container spacing={2} xs={12}>
                    <Grid item xs={12}>
                      <FormGroup>

                        <div style={{ display: "inherit", paddingBottom: "10px" }}>
                          <FormControlLabel
                            style={{ width: "200px" }}
                            control={
                              <Checkbox
                                checked={selectCar}
                                name="Car"
                                onChange={(e) => {
                                  setSelectLicense({
                                    ...selectLicense,
                                    selectCar: e.target.checked,
                                  });
                                  if (!e.target.checked) {
                                    form.clearErrors("CarNo");
                                  }
                                }}
                              />
                            }
                            label={i18n(`${i18nField}.Car`)}
                          />
                          <Grid item md={4} xs={12}>
                            {selectCar ? (
                              <Input
                                name={"CarNo"}
                                small
                                required
                                maxLength={50}
                                label={
                                  <Typography
                                    sx={{ fontWeight: 400 }}
                                    component="label"
                                  >
                                    {i18n(`${i18nField}.LicenseNo`)}
                                  </Typography>
                                }
                              />
                            ) : null}
                          </Grid>
                        </div>
                        <div style={{ display: "inherit", paddingBottom: "10px" }}>
                          <FormControlLabel
                            style={{ width: "200px" }}
                            control={
                              <Checkbox
                                checked={selectMotorcyc}
                                name="Motorcycle"
                                onChange={(e) => {
                                  setSelectLicense({
                                    ...selectLicense,
                                    selectMotorcyc: e.target.checked,
                                  });
                                  if (!e.target.checked) {
                                    form.clearErrors("MotorcycleNo");
                                  }
                                }}
                              />
                            }
                            label={i18n(`${i18nField}.Motorcycle`)}
                          />
                          <Grid item md={4} xs={12}>
                            {selectMotorcyc ? (
                              <Input
                                name={"MotorcycleNo"}
                                small
                                required
                                maxLength={50}
                                label={
                                  <Typography
                                    sx={{ fontWeight: 400 }}
                                    component="label"
                                  >
                                    {i18n(`${i18nField}.LicenseNo`)}
                                  </Typography>
                                }
                              />
                            ) : null}
                          </Grid>
                        </div>

                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectOther}
                              name="Other"
                              onChange={(e) => {
                                setSelectLicense({
                                  ...selectLicense,
                                  selectOther: e.target.checked,
                                });
                                if (!e.target.checked) {
                                  form.clearErrors("OtherName");
                                  form.clearErrors("OtherNo");
                                }
                              }}
                            />
                          }
                          label={i18n(`${i18nField}.Other`)}
                        />
                        {selectOther ? (
                          <Grid container spacing={1} >
                            <Grid item md={4} xs={12}>
                              <Input
                                name={"OtherName"}
                                small
                                required
                                maxLength={50}
                              />
                            </Grid>
                            <Grid item md={4} xs={12}>
                              <Input
                                name={"OtherNo"}
                                small
                                required
                                maxLength={50}
                                label={
                                  <Typography
                                    sx={{ fontWeight: 400 }}
                                    component="label"
                                  >
                                    {i18n(`${i18nField}.LicenseNo`)}
                                  </Typography>
                                }
                              />
                            </Grid>
                          </Grid>
                        ) : null}
                      </FormGroup>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </AccordionDetailsCustom>
          </AccordionCustom>

          <AccordionCustom
            sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
            expanded={expandedJobApply}
            onChange={() => setExpandedJobApply((prev) => !prev)}
          >
            <AccordionSummaryCustom>
              <Typography sx={{ width: "80%", flexShrink: 0 }}>
                {i18n(`${i18nField}.PanelJobApply`)}
              </Typography>
            </AccordionSummaryCustom>
            <AccordionDetailsCustom>
              {(jobPositionContext || []).map((item: IMyJobPosition, index) => {
                return (
                  <MyJobPositon item={item} index={index} key={index} form={form} />
                );
              })}
              <Stack
                sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
                direction="row"
                justifyContent="center"
              >
                <BtnRadiusProfile
                  txt={i18n(`${i18nField}.AddWorkDetail`)}
                  onClick={handleAddJobAppiy}
                />
              </Stack>
            </AccordionDetailsCustom>
          </AccordionCustom>

          <Box component={Paper} sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}>
            <Grid
              container
              spacing={1}
              sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
            >
              <Grid item xs={12}>
                <FormControl>
                  <RadioGroup
                    value={isStartNow ? "now" : "late"}
                    onChange={(e, v) => {
                      setIsStartNow(v === "now");
                      if (v === "now") {
                        form.setValue("DayStart", null);
                        form.clearErrors("DayStart");
                      }
                    }}
                  >
                    <FormControlLabel
                      value="now"
                      control={<Radio />}
                      label={i18n(`${i18nField}.StartNow`)}
                    />
                    <div style={{ display: "inherit" }}>
                      <FormControlLabel
                        style={{ width: "800px" }}
                        value="late"
                        control={<Radio />}
                        label={i18n(`${i18nField}.StartLate`)}
                      />
                      <Input
                        name={"DayStart"}
                        small
                        sx={{ m: 1, width: "10ch" }}
                        disabled={isStartNow}
                        required={!isStartNow}
                        endAdornment={
                          <InputAdornment position="end">
                            {i18n(`${i18nField}.Day`)}
                          </InputAdornment>
                        }
                      />
                    </div>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <InputNumber
                  name={"CurrentSalary"}
                  small
                  label={i18n(`${i18nField}.CurrentSalary`)}
                />
              </Grid>
            </Grid>

            <AccordionCustom
              sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
              expanded={expandedAbility}
              onChange={() => setExpandedAbility((prev) => !prev)}
            >
              <AccordionSummaryCustom>
                <Typography sx={{ flexShrink: 0 }}>
                  {i18n(`${i18nField}.PanelTag`)}
                </Typography>
              </AccordionSummaryCustom>
              <AccordionDetailsCustom>
                <Grid
                  sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
                  item
                  md={12}
                  xs={12}
                >
                  <AutoCompleteRenderItem
                    isFreeCreate
                    name={"Tag"}
                    fullWidth
                    label={i18n(`${i18nField}.TagName`)}
                    options={[]}
                  />
                  <FormHelperText sx={{ color: "grey" }}>
                    {i18n(`${i18nField}.MsgTag`)}
                  </FormHelperText>
                </Grid>
              </AccordionDetailsCustom>
            </AccordionCustom>
          </Box>
          <Stack
            spacing={1}
            sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
            direction="row"
            justifyContent="right"
            alignItems="right"
          >
            <BtnSubmit
              onClick={form.handleSubmit(onSubmit, (err) =>
                console.log("error", err)
              )}
              txt={i18n(`${i18nCommon}.save`)}
            />
            {objJobDetail && (
              <BtnBack
                onClick={() => {
                  history(
                    objJobDetail && objJobDetail.sFromPage === "MyJobInterested"
                      ? "/MyJobInterested"
                      : "/SearchJob"
                  );
                }}
                txt={i18n(`${i18nCommon}.back`)}
              />
            )}
          </Stack>
        </Grid>
      </Hidden>
    </FormProvider >
  );
};

export default MyProfile;