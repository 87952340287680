import Layout_Font from "layout/CommonLayout/Layout_Font";
import SetupTemplate from "view/font/SetupTemplate/SetupTemplate";

const SetupTemplateRoute = [
  {
    path: "/SetupTemplate",
    component: SetupTemplate,
    layout: Layout_Font,
    exact: true,
    propsData: {
      imgBGType: 5,
      // isPaddingTop: true,
    }
  },
];

export default SetupTemplateRoute;