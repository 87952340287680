import { useEffect, useContext, useState } from "react";
import { FormProvider } from "react-hook-form";
import { Grid, Box, Typography } from "@mui/material";
import { getLanguage, i18n } from "i18n";
import { ProfileContext } from "components/SeekerProfile/Context/ProfileContext";
import AutoCompleteSelect from "components/Common/ElementInForm/AutoCompleteSelect";
import { IPorpsMyAddress } from "./Interface";
import { AxiosGet } from "service/CommonFunction/TS_function";
import Input from "components/Common/ElementInForm/InputCustomLabelFormItem";
import secureLocalStorage from "react-secure-storage";

const i18nField = "entities.SeekerProfile";
const MyAddress = (props: IPorpsMyAddress) => {
  const { form, onChangeContry, setIsInterMain } = props;

  const {
    addresssContext,
    setAddress,
    optionCountry,
    setOptionCountry,
    optionReion,
    setOptionRegion,
    optionProvice,
    setOptionProvince,
    optionDistrict,
    optionSubDistrict,
  } = useContext(ProfileContext);

  const [rawCountry, setRawCountry] = useState([]);
  const [rawReion, setRawnRegion] = useState([]);
  const [rawProvince, setRawnProvince] = useState([]);
  const [IsInter, setIsInter] = useState(false);
  const [ProvinceParent, setProvinceParent] = useState(0);

  const lang = (secureLocalStorage.getItem("language").toString() || "TH").toUpperCase();

  const handleSetData = () => {
    if (addresssContext) {
      form.setValue("Country", addresssContext.Country);
      form.setValue("Region", addresssContext.Region);
      form.setValue("Province", addresssContext.Province);
      form.setValue("District", addresssContext.District);
      form.setValue("SubDistrict", addresssContext.SubDistrict);
      form.setValue("AddressNo", addresssContext.AddressNo);
      form.setValue("Moo", addresssContext.Moo);
      form.setValue("Street", addresssContext.Street);
      form.setValue("PostCode", addresssContext.PostCode);
    }
  };

  const handleChangeValue = (form) => {
    setAddress({
      Country: form.getValues("Country"),
      Region: form.getValues("Region"),
      Province: form.getValues("Province"),
      District: form.getValues("District"),
      SubDistrict: form.getValues("SubDistrict"),
      AddressNo: form.getValues("AddressNo"),
      Moo: form.getValues("Moo"),
      Street: form.getValues("Street"),
      PostCode: form.getValues("PostCode"),
    });
  };

  const fetchCountry = () => {
    AxiosGet("DataCollect/GetCountry", {}, (res) => {
      setRawCountry(res.Data);
    });
  };
  const fetchRegion = () => {
    AxiosGet("DataCollect/GetRegion", {}, (res) => {
      setRawnRegion(res.Data);
    });
  };
  const fetchProvince = () => {
    let lang = "";
    let isTH = (getLanguage().id === "th");
    isTH ? lang = "th" : lang = "en"
    AxiosGet(`DataFilter/SetFilter_SelectProvince`, { lang }, (res) => {
      setRawnProvince(res.lstOptionAdmin);
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchCountry();
      await fetchRegion();
      await fetchProvince();
    };
    fetchData();
    handleSetData();
    if (addresssContext) {
      if (addresssContext.Country && addresssContext.Country["value"] !== 519) {
        setIsInter(true);
      } else {
        setIsInter(false);
      }
    }
  }, []);

  useEffect(() => {
    handleSetData();

    if (addresssContext) {
      if (addresssContext.Country && addresssContext.Country["value"] != 519) {
        setIsInter(true);
      } else {
        setIsInter(false);
      }
    }
  }, [addresssContext]);

  useEffect(() => {
    setIsInterMain && setIsInterMain(IsInter);
  }, [IsInter]);

  return (
    <>
      <FormProvider {...form}>
        <Box sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            <Grid item container spacing={2} xs={12}>
              <Grid item md={4} xs={12}>
                <AutoCompleteSelect
                  name={"Country"}
                  fullWidth
                  label={i18n(`${i18nField}.Country`)}
                  options={optionCountry}
                  required
                  onChange={(value) => {
                    if (value && value.value !== 519) {
                      setIsInter(true);
                      onChangeContry && onChangeContry(false);
                      form.clearErrors("Region");
                      form.clearErrors("Province");
                      form.setValue("Region", null);
                      form.setValue("Province", null);
                      setAddress({
                        ...addresssContext,
                        Region: null,
                        Province: null,
                      });
                    } else {
                      setIsInter(false);
                      onChangeContry && onChangeContry(true);
                    }
                    form.setValue("Country", value);
                    handleChangeValue(form);
                  }}
                />
              </Grid>
              {IsInter ? null : (
                <>
                  <Grid item md={4} xs={12}>
                    <AutoCompleteSelect
                      name={"Region"}
                      required
                      fullWidth
                      label={i18n(`${i18nField}.Region`)}
                      options={optionReion.filter((f) => f.value !==1779)}
                      onChange={(v) => {
                        if (v && v.value !== "Region") {
                          form.clearErrors("Province");
                          form.setValue("Province", 0);
                        } else {
                          form.clearErrors("Province");
                          form.setValue('Province', v ? v.value : 0);
                        }
                        handleChangeValue(form);
                        setProvinceParent(v ? v.value : 0);
                      }}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <AutoCompleteSelect
                      name={"Province"}
                      required
                      fullWidth
                      label={i18n(`${i18nField}.Province`)}
                      options={rawProvince.filter(
                        (f) => f.sParent == ProvinceParent
                      )}
                      onChange={(v) => {
                        form.setValue("Province", v ? v : null);
                        handleChangeValue(form);
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    md={2}
                    xs={12}
                    sx={props.IsNotShowDetail && { display: "none" }}
                  >
                    <Input
                      name={"PostCode"}
                      fullWidth
                      maxLength={5}
                      required
                      small={true}
                      label={
                        <Typography sx={{ fontWeight: 400 }} component="label">
                          {i18n(`${i18nField}.PostCode`)}
                        </Typography>
                      }
                      onBlur={(value) => {
                        handleChangeValue(form);
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                    sx={props.IsNotShowDetail && { display: "none" }}
                  >
                    <Input
                      name={"Street"}
                      fullWidth
                      maxLength={500}
                      small={true}
                      required
                      label={
                        <Typography sx={{ fontWeight: 400 }} component="label">
                          {i18n(`${i18nField}.Street`)}
                        </Typography>
                      }
                      onBlur={(value) => {
                        handleChangeValue(form);
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    md={2}
                    xs={12}
                    sx={props.IsNotShowDetail && { display: "none" }}
                  >
                    <Input
                      name={"Moo"}
                      fullWidth
                      maxLength={3}
                      small={true}
                      required
                      label={
                        <Typography sx={{ fontWeight: 400 }} component="label">
                          {i18n(`${i18nField}.Moo`)}
                        </Typography>
                      }
                      onBlur={(value) => {
                        handleChangeValue(form);
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    md={2}
                    xs={12}
                    sx={props.IsNotShowDetail && { display: "none" }}
                  >
                    <Input
                      name={"AddressNo"}
                      fullWidth
                      maxLength={20}
                      required
                      small={true}
                      label={
                        <Typography sx={{ fontWeight: 400 }} component="label">
                          {i18n(`${i18nField}.AddressNo`)}
                        </Typography>
                      }
                      onBlur={(value) => {
                        handleChangeValue(form);
                      }}
                    />
                  </Grid>
                </>
              )}
              <Grid item md={8} xs={12}></Grid>
            </Grid>
          </Grid>
        </Box>
      </FormProvider>
    </>
  );
};

export default MyAddress;