import React, { useEffect, useState } from 'react'
import { Grid, Typography } from "@mui/material"
import InputCustomLabel from 'components/Common/ElementInForm/InputCustomLabelFormItem'
import { useForm, FormProvider } from 'react-hook-form'
import AutoCompleteSelect from 'components/Common/ElementInForm/AutoCompleteSelect'
import { ButtonBase } from "@mui/material"
import SelectGroupForm from 'components/Common/ElementInForm/SelectGroupForm'
import AutoCompleteRenderItem from 'components/Common/ElementInForm/AutoCompleteRenderItem'
import { BtnSearch } from "components/Common/Button"
import { AxiosPost } from 'service/CommonFunction/TS_function'
import { FnBlock_UI } from 'service/SystemFunction/SystemFunction'
import { useDispatch } from 'react-redux'
import { DialogActionCreators } from 'store/redux/DialogAlert'
import MultiSelectGroupForm from 'components/Common/ElementInForm/MultiSelectGroupForm'
import MultiSelectWithChip from 'components/Common/ElementInForm/MultiSelectWithChip'
import { AutoCompleteSelectWithSlider } from 'components/Common/ElementInForm/AutoCompleteWithSlider'
import { i18n } from 'i18n'
import moment from 'moment'
import FilterTabs from 'components/JobSeekerRegisJob/FilterTabs'

export const th = {
    search: "ชื่อตำแหน่ง,ชื่อบริษัทที่ทำงาน,คีย์เวิร์ด",
    search_without_position: "ชื่อบริษัทที่ทำงาน, คีย์เวิร์ด",
    exp: "ประสบการณ์การทำงาน",
    group_work: "กลุ่ม/ประเภทงาน",
    addr: "จังหวัดที่อาศัย",
    education: "ระดับการศึกษา",
    update_when: "ช่วงเวลาอัปเดต",
    salary: "ช่วงเงินเดือนที่ต้องการ (บาท)",
    search_jobseeker: "ค้นหาผู้สมัคร",
    jobseekerApply: "ผู้สมัครงาน",
    industry: "ประเภทอุตสาหกรรม",
    firstoption :"น้อยกว่า 1 ปี"
}

export const en = {
    search: "Job title, Keyword, Company",
    search_without_position: "Company name, Keyword",
    exp: "Total years of experience",
    group_work: "Job Function/Job Function Detail",
    addr: "Lives in",
    education: "Education Level",
    update_when: "Last update",
    salary: "Salary (THB)",
    search_jobseeker: "Search jobseeker",
    jobseekerApply: "Jobseeker candidate",
    industry: "Industry",
    firstoption :"Less than 1 year"
}
export default function Filter({ setLstData, setLoading, dataFilter, objPaging, setPaging, isChangePage, setChangePage, valueTabs = 0, onChangeTabs = (value, i) => { } }) {

    const sPathName = window.location.pathname;
    const isEmployerSearch = sPathName.includes("/EmployerSearch");
    const ControllerApi = isEmployerSearch ? "EmployerSearch" : "JobSeekerRegisJob";
    const i18nField = "EmployerSearch.Filter."
    const i18nForBtn = "searchJob.btn.";
    const { BlockUI, UnBlockUI } = FnBlock_UI();
    const form = useForm({
        shouldFocusError: true,
        defaultValues: {
            arrEducation: [],
            arrIndustry: []
        },
    });


    const [isFullSearch, setFullSearch] = useState(false)

    const dispatch = useDispatch()

    useEffect(() => {
        if (isChangePage) {
            onSearch()
            setChangePage(false)
        }
    }, [isChangePage])



    useEffect(() => {
        onSearch();
    }, [])

    const onSearch = () => {
        const { sSearch, objExp, arrEducation, arrWorkType, arrIndustry, objUpdate, objSalary, arrAddress }: any = form.getValues();
        let objReq: any = {
            sSearch: sSearch
        }
        if (isFullSearch) {
            if (objExp) {
                if (objExp.isCustomValue) {
                    objReq.arrExp = [objExp.valueCustomStart, objExp.valueCustomEnd]
                } else {
                    objReq.arrExp = [objExp.valueOptionStart, objExp.valueOptionEnd]
                }
            }
            if (arrEducation && arrEducation.length > 0) objReq.arrEducation = arrEducation;
            if (arrIndustry && arrIndustry.length > 0) objReq.arrIndustry = arrIndustry;
            if (arrWorkType && arrWorkType.length > 0) {
                let filterWithOutParent = arrWorkType.filter(f => !dataFilter.lstJobFunction.some(s => s.isParent && s.value == f))
                objReq.arrWorkType = filterWithOutParent;
            }
            if (arrAddress && arrAddress.length > 0) {
                let filterOptionAll = dataFilter.lstAddr
                let lstFilterAddr = [];
                let lstParendId = filterOptionAll.filter(f => arrAddress.includes(f.value)).map(m => m.sParentID)
                let lstParendIdNotDistinst = [...new Set(lstParendId)];
                lstParendIdNotDistinst = lstParendIdNotDistinst.filter(f => f != "");
                let lstParentData = filterOptionAll.filter(f => f.isParent && lstParendIdNotDistinst.includes(f.value));
                lstParentData.forEach(item => {
                    let lstChild = filterOptionAll.filter(f => f.sParentID == item.value);
                    lstChild.forEach(itemChild => {
                        if(arrAddress.some(s => s == itemChild.value)){
                            let objFilterAddr = {
                                nProvince: parseInt(itemChild.value),
                                nRegion: parseInt(item.value)
                            }
                            lstFilterAddr.push(objFilterAddr)
                        }
                    });
                });
                if(arrAddress.some(s => s == "-1")){
                    //กรณีเลือกต่างประเทศ
                    let objFilterAddr = {
                        nProvince: 0,
                        nRegion: 0
                    }
                    lstFilterAddr.push(objFilterAddr)
                }
                objReq.arrAddressJobseeker = lstFilterAddr;
            }
            if (objUpdate) {
                objReq.dUpdateData = moment().add('hour', parseInt(objUpdate.value) * -1).toDate().getTime();
                objReq.nValueFilterDate = objUpdate.value;
            }
            if (objSalary) {
                if (objSalary.isCustomValue) {
                    objReq.arrSalary = [objSalary.valueCustomStart, objSalary.valueCustomEnd]
                } else {
                    objReq.arrSalary = [objSalary.valueOptionStart, objSalary.valueOptionEnd]
                }
            }
        }
        if (!isEmployerSearch) {
            objReq.nTabSelect = valueTabs;
        }
        setLoading(true)
        AxiosPost(`${ControllerApi}/SearchJobSeeker`, { ...objReq, ...objPaging }, (res) => {
            setPaging({
                ...objPaging,
                nTotal: res.Data.nCount,
            });
            setLstData(res.Data.lstJobSeekerData);

           
        }, (err) => dispatch(DialogActionCreators.OpenDialogWarning(err.Message) as any), () => setLoading(false))
    }

    return (
        // <Grid
        //     container
        //     spacing={3}
        //     direction="column"
        //     className="fade-bg-color"
        // >
        <Grid container padding={0} direction="column" gap="10px">
            <Grid  >
                <Typography variant={'caption'} className="!text-[1em] !font-[500]"  >
                    {isEmployerSearch ? i18n(`${i18nField}search_jobseeker`) : i18n(`${i18nField}jobseekerApply`)}
                </Typography>
            </Grid>
            <div className='border-b border-b-[#a1a1a1]  ' />
            <Grid container item direction="column" gap={1}>
                <FormProvider {...form}>
                    <Grid container item direction={"row"} gap={1} mb={1} alignItems={"center"}>
                        <Grid item flex={1}   >
                            {/* <InputCustomLabel name='sSearch' fullWidth label={!arrIdTabNotJobPost.includes(valueTabs) && !isEmployerSearch ? i18n(`${i18nField}search_without_position`) : i18n(`${i18nField}search`)} /> */}
                            <InputCustomLabel name='sSearch' fullWidth label={i18n(`${i18nField}search`)} onKeyDown={(e: any) => {
                                if (e.key == "Enter") {
                                    setPaging({
                                        ...objPaging,
                                        nPage: 1,
                                    });
                                    setChangePage(true)
                                }
                            }} />
                        </Grid>
                        {!isFullSearch && (<Grid item  >
                            <BtnSearch txt={i18n("common.search")} onClick={() => {
                                setPaging({
                                    ...objPaging,
                                    nPage: 1,
                                });
                                setChangePage(true)
                            }} />
                        </Grid>)}

                    </Grid>
                    {isFullSearch && (<>
                        <Grid container direction={"row"} spacing={2} >
                            <Grid item xs={12} sm={6} md={4}  >
                            <AutoCompleteSelectWithSlider sAboveText={i18n(`common.more_than`)}
                                    sBetweenText={i18n(`common.between`)}
                                    sTextChangeMode={i18n(`common.custom_value`)}
                                    name='objExp'
                                    fullWidth
                                    sLabelFirstOptionCustom={i18n(`${i18nField}firstoption`)}
                                    sEndLabelOption={i18n(`common.year`)}
                                    options={dataFilter?.lstExp ?? []}
                                    label={i18n(`${i18nField}exp`)}
                                    maxValueSlider={20} />
                                {/* <AutoCompleteSelect isOptionTwoLanguage name='objExp' fullWidth options={dataFilter?.lstExp ?? []} label={i18n(`${i18nField}exp`)} /> */}
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}  >
                                <MultiSelectGroupForm
                                    name="arrWorkType"
                                    fullWidth
                                    isOptionTwoLanguage
                                    options={dataFilter?.lstJobFunction ?? []}
                                    label={i18n(`${i18nField}group_work`)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}  >
                                <MultiSelectGroupForm
                                    name="arrAddress"
                                    fullWidth
                                    isOptionTwoLanguage
                                    options={dataFilter?.lstAddr ?? []}
                                    label={i18n(`${i18nField}addr`)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} >
                                <MultiSelectWithChip
                                    valueData={form.watch("arrEducation")}
                                    isOptionTwoLanguage
                                    name="arrEducation"
                                    fullWidth
                                    options={dataFilter?.lstEducation ?? []}
                                    label={i18n(`${i18nField}education`)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} >
                                <AutoCompleteSelect isOptionTwoLanguage name='objUpdate' fullWidth options={dataFilter?.lstUpdateWhen ?? []} label={i18n(`${i18nField}update_when`)} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} >
                                <AutoCompleteSelectWithSlider sAboveText={i18n(`common.more_than`)}
                                    sBetweenText={i18n(`common.between`)}
                                    sTextChangeMode={i18n(`common.custom_value`)}
                                    name='objSalary'
                                    fullWidth
                                    options={dataFilter?.lstSalary ?? []}
                                    label={i18n(`${i18nField}salary`)}
                                    maxValueSlider={130000} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} >
                                <MultiSelectWithChip
                                    valueData={form.watch("arrIndustry")}
                                    isOptionTwoLanguage
                                    name="arrIndustry"
                                    fullWidth
                                    options={dataFilter?.lstIndustry ?? []}
                                    label={i18n(`${i18nField}industry`)}
                                />
                            </Grid>
                        </Grid>
                    </>)}

                    {isFullSearch && <Grid container item direction={"row"} justifyContent="end">
                        <BtnSearch txt={i18n("common.search")} onClick={() => {
                            setPaging({
                                ...objPaging,
                                nPage: 1,
                            });
                            setChangePage(true)
                        }} />
                    </Grid>}
                    <Grid item container justifyContent={"flex-end"}>
                        <ButtonBase onClick={(e) => setFullSearch(!isFullSearch)} sx={{ borderRadius: "20px" }}>
                            <Typography component="p">
                                {i18n(
                                    `${i18nForBtn}${isFullSearch ? "shortSearch" : "fullSearch"
                                    }`
                                )}
                            </Typography>
                        </ButtonBase>
                    </Grid>
                </FormProvider>
            </Grid>
            {!isEmployerSearch && <FilterTabs handleChange={onChangeTabs} value={valueTabs} />}
        </Grid>
        // </Grid>
    )
}
