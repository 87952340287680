import { useState } from 'react';
import { Box, SxProps, Container, Grid, Hidden } from "@mui/material"
import { useNavigate } from "react-router-dom";
import ModalViewImage from "components/Common/ModalViewImage/ModalViewImage"
import { MarginOutlined } from '@mui/icons-material';
import ReactPlayer from 'react-player';
import PicSlide from 'view/font/Home/PicSlide';

const objStyle = {
    image_cover: {
        display: "flex", minHeight: 300, maxWidth: 1500, backgroundSize: "cover"
    } as SxProps,
    image: {
        minHeight: 330,
        maxWidth: 350,
        attachment: "fixed",
        position: "right",
        backgroundSize: "cover",
    } as SxProps,
    imagePosition2: {
        minHeight: 200,
        maxWidth: 350,
        attachment: "fixed",
        position: "right",
        backgroundSize: "cover",

    } as SxProps,
    imagePosition3: {
        minHeight: 220,
        maxWidth: 1400,
        margin: "center",
        backgroundSize: "cover",

    } as SxProps,
    imagePosition4: {
        minHeight: 220,
        maxWidth: 970,
        margin: "center",
        backgroundSize: "cover",

    } as SxProps,
    container: {
        " > div": {
            marginTop: "2rem"
        }
    } as SxProps,

}


export default function DetailComponent({
    sFileImg, sFileImg2, sFileImg3, sFileImg4, sFileImg5, sFileurl, isCanBack = false, imgAlt = "", isDashboard = false }) {
    const history = useNavigate();
    const [isViewImage, setIsViewImage] = useState(false);
    const [imageView, setImageView] = useState("");
    return (
        <>
            <ModalViewImage
                image={imageView}
                isOpen={isViewImage}
                handleClose={() => setIsViewImage(false)}
            />

            <Container
                sx={objStyle.container}>
                {!isDashboard && (
                    <>

                        {(sFileImg && sFileImg[0]) && (
                            <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                            >

                                {(sFileurl[0][sFileurl[0].length - 1] === "mp4" || sFileurl[0][sFileurl[0].length - 1] === "mp4 ") ? (
                                    <video
                                        contextMenu="return false;"
                                        src={sFileImg}
                                        controls
                                        autoPlay
                                        muted
                                        controlsList="nodownload"
                                        style={{
                                            width: "100%",
                                            height: "13vw",
                                            // height: "auto",
                                            objectFit: "cover",
                                        }}
                                    />
                                ) :

                                    <img
                                        className={"zoom-image-banner"}
                                        src={sFileImg}
                                        alt="banner"
                                        style={{
                                            width: "100%",
                                            height: "13vw",
                                            // height: "auto",
                                            objectFit: "cover",
                                        }}
                                    />

                                }
                            </Grid>
                        )}

                        {(sFileImg2 && sFileImg2[0]) && (
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-end"

                            >
                                <Grid item md={4} xs={6}>
                                    {(sFileurl[0][sFileurl[0].length - 1] === "mp4" || sFileurl[0][sFileurl[0].length - 1] === "mp4 ") ? (
                                        <video
                                            contextMenu="return false;"
                                            src={sFileImg2}
                                            controls
                                            autoPlay
                                            muted
                                            controlsList="nodownload"
                                            style={{
                                                width: "100%",
                                                height: "33vw",
                                                justifyContent: "flex-end",
                                                objectFit: "cover",
                                                marginTop:"250px",
                                                marginLeft:"140px",
                                                marginBottom:"100px"
                                            }}
                                        />

                                    ) :
                                        <img src={sFileImg2}
                                            className={"zoom-image-banner"}
                                            alt="banner"
                                            style={{
                                                width: "100%",
                                                height: "33vw",
                                                justifyContent: "flex-end",
                                                objectFit: "cover",
                                                marginTop:"250px",
                                                marginLeft:"140px",
                                                marginBottom:"100px"
                                            }}
                                        />
                                    }
                                    
                                </Grid>
                            </Grid>
                        )}
                        {(sFileImg3 && sFileImg3[0]) && (
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-end"

                            >
                                <Grid item md={4} xs={6}>
                                    {(sFileurl[0][sFileurl[0].length - 1] === "mp4" || sFileurl[0][sFileurl[0].length - 1] === "mp4 ") ? (
                                        <video
                                            contextMenu="return false;"
                                            src={sFileImg3}
                                            controls
                                            autoPlay
                                            muted
                                            controlsList="nodownload"
                                            style={{
                                                width: "100%",
                                                height: "24.5vw",
                                                // height: "auto",
                                                objectFit: "cover",
                                                marginTop:"500px",
                                                marginLeft:"140px",
                                                marginBottom:"100px"
                                            }}
                                        />
                                    ) :

                                        <img
                                            className={"zoom-image-banner"}
                                            src={sFileImg3}
                                            alt="banner"
                                            style={{
                                                width: "100%",
                                                height: "24.5vw",
                                                // height: "auto",
                                                objectFit: "cover",
                                                marginTop:"500px",
                                                marginLeft:"140px",
                                                marginBottom:"100px"
                                            }}
                                        />

                                    }
                                </Grid>
                            </Grid>
                        )}

                        {(sFileImg4 && sFileImg4[0]) && (
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                            >
                                {(sFileurl[0][sFileurl[0].length - 1] === "mp4" || sFileurl[0][sFileurl[0].length - 1] === "mp4 ") ? (
                                    <video
                                        contextMenu="return false;"
                                        src={sFileImg4}
                                        controls
                                        autoPlay
                                        muted
                                        controlsList="nodownload"
                                        style={{
                                            width: "100%",
                                            height: "14vw",
                                            objectFit: "cover",
                                            marginTop:"700px",
                                            marginBottom:"100px"
                                        }}
                                    />
                                ) :
                                    <img
                                        className={"zoom-image-banner"}
                                        src={sFileImg4}
                                        alt="banner"
                                        style={{
                                            width: "100%",
                                            height: "14vw",
                                            // height: "auto",
                                            objectFit: "cover",
                                            marginTop:"700px",
                                            marginBottom:"100px"
                                        }}
                                    />
                                }
                            </Grid>
                        )}
                        {(sFileImg5 && sFileImg5[0]) && (
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                            >
                                {(sFileurl[0][sFileurl[0].length - 1] === "mp4" || sFileurl[0][sFileurl[0].length - 1] === "mp4 ") ? (
                                    <video
                                        contextMenu="return false;"
                                        src={sFileImg5}
                                        controls
                                        autoPlay
                                        muted
                                        controlsList="nodownload"
                                        style={{
                                            width: "80%",
                                            height: "15vw",
                                            // height: "auto",
                                            objectFit: "cover",
                                            marginTop:"900px",
                                            marginBottom:"100px"
                                        }}
                                    />
                                ) :
                                    <img
                                        className={"zoom-image-banner"}
                                        src={sFileImg5}
                                        alt="banner"
                                        style={{
                                            width: "80%",
                                            height: "15vw",
                                            // height: "auto",
                                            objectFit: "cover",
                                            marginTop:"900px",
                                            marginBottom:"100px"
                                        }}
                                    />
                                }
                            </Grid>
                        )}

                    </>
                )}
            </Container >
        </>
    );
}