const RegisterEmployerEn = {
    TitleRegister: "New Employer Registration",
    RegisterName: "Name - Surname Register",
    CompanyName: "Employer Name",
    Password: "Password",
    ConfirmPass: "Confirm Password",
    InvalidPass: "Invalid Confirm Password",
    Email: "Email",
    Phone: "Tel",
    ConfirmRegisDoc: "I want to receive news and related information",
    ConfirmRegis: "I accept all terms and conditions set by BPS",
    ErrorConsent: "Please 'Accept' all terms and conditions to continue using the system.",
    BtnRegister: "Register",
    Industry: "Industry",
    Country: "Country",
    Region: "Region/Bangkok",
    Province: "Province/District",
    PostCode: "Post Code",
    Street: "Street",
    Moo: "Moo",
    AddressNo: "Address No.",
    ErrorEmail: "This email is already in used",
    ErrorRegistName: "This Name is already in used",
    ErrorEmployer: "This company already has an account",
    Policy: "By creating a profile, I confirm I have read and accept ",
    Policy2: "Privacy Statement",
    Policy3: "and",
    Policy4: "Terms and Conditions.",
    Policy5: " ",
    RegisterNewEmployee: "Company",
    phonenumber: "Phone Number",
}

const RegisterEmployerTh = {
    TitleRegister: "การลงทะเบียนผู้ประกอบการใหม่",
    RegisterName: "ชื่อ - นามสกุลผู้ลงทะเบียน",
    CompanyName: "ชื่อผู้ประกอบการ",
    Password: "รหัสผ่าน",
    ConfirmPass: "ยืนยันรหัสผ่าน",
    InvalidPass: "ยืนยันรหัสผ่านไม่ถูกต้อง",
    Email: "อีเมล",
    Phone: "เบอร์โทรศัพท์",
    ConfirmRegisDoc: "ต้องการรับข่าวสาร และข้อมูลที่เกี่ยวข้องต่างๆ",
    ConfirmRegis: "ข้าพเจ้าขอยอมรับทุกข้อกำหนดและเงื่อนไขต่างๆที่ BPS กำหนด",
    ErrorConsent: "กรุณา 'ยอมรับ' ข้อกำหนดและเงื่อนไขเพื่อใช้งานระบบต่อไป",
    BtnRegister: "ลงทะเบียน",
    Industry: "ประเภทอุตสาหกรรม",
    Country: "ประเทศ",
    Region: "ภูมิภาค/กรุงเทพมหานคร",
    Province: "จังหวัด/เขต",
    PostCode: "รหัสไปรษณีย์",
    Street: "ถนน",
    Moo: "หมู่",
    AddressNo: "เลขที่",
    ErrorEmail: "Email นี้ถูกใช้งานแล้ว",
    ErrorRegistName: "ชื่อนี้ถูกใช้งานแล้ว",
    ErrorEmployer: "บริษัทนี้มีบัญชีอยู่แล้ว",
    ErrMsg: "",
    Policy: "เมื่อคุณคลิกสร้างโปรไฟล์ คุณได้ยอมรับ ",
    Policy2: "ประกาศความเป็นส่วนบุคคล",
    Policy3: "และ",
    Policy4: "ข้อกำหนดและเงื่อนไข",
    Policy5: "แล้ว",
    RegisterNewEmployee: "ผู้ประกอบการ",
    phonenumber: "เบอร์โทรศัพท์ ",
}

export { RegisterEmployerEn, RegisterEmployerTh };