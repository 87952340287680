import React, { useEffect, Fragment, useState } from "react";
import {
  Autocomplete,
  TextField,
  Box,
  Chip,
  Typography,
  FormHelperText,
  FormControl,
  Tooltip,
} from "@mui/material";
import PropTypes, { InferProps } from "prop-types";
import { useFormContext, useWatch } from "react-hook-form";
import secureLocalStorage from "react-secure-storage";
export default function AutoCompleteRenderItem({
  options,
  isClearable,
  defaultValue,
  label,
  isFreeCreate,
  disabled,
  name,
  prefixText,
  limitTag,
  required,
  subLabel,
  autoSelect,
  small,
  fullWidth,
  isOptionTwoLanguage,
  maxLength,

}: InferProps<typeof AutoCompleteRenderItem.propTypes>) {
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();
  const langCode = secureLocalStorage.getItem("language").toString() || "th";

  return (
    <FormControl fullWidth>
      <Autocomplete
        size={small ? "small" : "medium"}
        {...register(name)}
        multiple
        fullWidth={fullWidth}
        onChange={(e, v: any) => {
          if (!(v || []).some(a => a == e.currentTarget.textContent))
            setValue(name, (v || []).map(item => typeof (item) == "object" ? item.label : item)
              , { shouldValidate: true });

        }}
        getOptionLabel={(itemOption: any) => {
          return `${isOptionTwoLanguage ? itemOption[`label_${langCode}`] : itemOption.label}`
        }}
        isOptionEqualToValue={(option, value) => {
          if (typeof (option) == "object") {
            return option.value === value.value
          } else {
            return option === value
          }
        }}
        limitTags={limitTag || undefined}
        disableClearable={!isClearable}
        options={options}
        defaultValue={defaultValue || undefined}
        value={watch(name) || []}
        freeSolo={isFreeCreate || undefined}
        disabled={disabled || undefined}
        renderTags={(value: readonly any[], getTagProps) => {

          return value.map((option: any, index: number) => (
            <Box
              key={`box_${name}_${index}`}
              sx={{ marginTop: "5px", marginBottom: "5px" }}
            >
              <Tooltip title={`${prefixText}${typeof (option) == "object" ? option.label : option}`}>
                <Chip
                  size={small ? "small" : "medium"}
                  variant="outlined"
                  style={{ maxWidth: "500px", color: (typeof (option) == "object" ? option.label : option).length > maxLength ? "red" : null }}
                  label={`${prefixText}${typeof (option) == "object" ? option.label : option}`}
                  {...getTagProps({ index })}

                />
              </Tooltip>
            </Box>
          ));
        }}
        renderInput={(params) => (
          <TextField
            size={small ? "small" : "medium"}
            inputRef={register(name).ref}
            {...params}
            fullWidth={fullWidth}
            // InputProps={{
            //   ...params.InputProps,
            //   inputProps: { ...params.inputProps, maxLength: 10 },
            // }}
            sx={{
              " > label": {
                " > span": {
                  fontSize: "12px",
                  " ::before": {
                    content: `"${required ? " *" : ""}"`,
                    color: "red",
                  },
                },
              },
              "label.MuiInputLabel-shrink": {
                backgroundColor: "rgba(255,255,255,0.95)",
              },
              // ".MuiOutlinedInput-notchedOutline > legend": {
              //   fontSize: "0.01em",
              // },
            }}
            error={Boolean(errors[name])}
            label={
              <Fragment>
                {label}
                <Typography
                  fontWeight={600}
                  component="span"

                >{` ${subLabel}`}</Typography>
              </Fragment>
            }
          />
        )}
      />

      {errors && errors[name] ? (
        <FormHelperText sx={{ color: "red" }}>
          {errors[name].message}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
}

AutoCompleteRenderItem.propTypes = {
  required: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  hint: PropTypes.string,
  isClearable: PropTypes.bool,
  options: PropTypes.array.isRequired,
  defaultValue: PropTypes.array,
  isFreeCreate: PropTypes.bool,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  limitTag: PropTypes.number,
  prefixText: PropTypes.string,
  subLabel: PropTypes.string,
  autoSelect: PropTypes.bool,
  small: PropTypes.bool,
  fullWidth: PropTypes.bool,
  isOptionTwoLanguage: PropTypes.bool,
  maxLength: PropTypes.number,

};

AutoCompleteRenderItem.defaultProps = {
  isClearable: true,
  required: false,
  defaultValue: [],
  handleRender: null,
  isFreeCreate: false,
  disabled: false,
  multiple: false,
  prefixText: "",
  subLabel: "",
  autoSelect: false,
  small: true,
  fullWidth: false,
  isOptionTwoLanguage: false
};
