import MyJobViewedCOMP from "components/MyJob/MyJobViewed";

const MyJobViewed = () => {
    return (
      <>
        <MyJobViewedCOMP />
      </>
    );
  };
  
  export default MyJobViewed;  