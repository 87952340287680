import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import LogTableHoldPackage from './LogTableHoldPackage';
import { Modal, Box, Tooltip, CircularProgress, Typography, Grid, Button, Fade } from "@mui/material"
import { Close } from '@mui/icons-material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "1100px",
    bgcolor: 'background.paper',
    border: '0px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function TransitionsModal({ isOpen, setOpen, sId }) {
    return (
        <div>
            <Modal
                open={isOpen}
            >
                <Fade in={isOpen}>
                    <Box sx={style}>
                        <div className="flex flex-[1] justify-end  ">
                            <Tooltip title="ปิด">
                                <div onClick={() => {
                                    setOpen(false)

                                }} className='rounded-[50%] bg-[#d1d1d1] w-[1.5em] h-[1.5em] flex cursor-pointer ease-linear duration-[100ms]  hover:bg-[#8c8c8c]'>
                                    <Close fontSize='small' className='m-[auto] text-[white]' />
                                </div>
                            </Tooltip>
                        </div>
                        <Grid xs={12} >
                            <LogTableHoldPackage sId={sId} />
                        </Grid>

                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}