import {
  Autocomplete,
  ButtonBase,
  FormControl,
  Grid,
  Paper,
  Skeleton,
  TextField,
  Typography,
  Chip,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import Input from "components/Common/ElementInForm/InputCustomLabelFormItem";
import { getLanguage, i18n } from "i18n";
import AutoCompleteSelect from "components/Common/ElementInForm/AutoCompleteSelect";
import { BtnSearch } from "components/Common/Button";
import { AxiosGet, AxiosPost } from "service/CommonFunction/TS_function";
import MultiSelectGroupForm from "components/Common/ElementInForm/MultiSelectGroupForm";
import { AutoCompleteSelectWithSlider } from "components/Common/ElementInForm/AutoCompleteWithSlider";
import secureLocalStorage from "react-secure-storage";

export enum EPageType {
  SearchJob,
  Apply,
  Interested,
  Invitation,
  Viewed,
}
export interface ICriteriaSearchProps {
  onSearch: Function;
  pageType?: EPageType;
  page?: number;
  objSearchFromHome?: Object;
  isFindCompanyName?: string;
}

const i18nForthisPage = "searchJob.criteria.";
const i18nHome = "home";
const i18nForBtn = "searchJob.btn.";
const ControllerAPI = "SearchJob";
const CriteriaSearch: FC<ICriteriaSearchProps> = ({
  onSearch,
  pageType = EPageType.SearchJob,
  page = 1,
  objSearchFromHome = null,
  isFindCompanyName,
}) => {
  const [isFullSearch, setFullSearch] = useState(pageType === EPageType.Viewed);
  const [rawIndustry, setRawIndustry] = useState<any>([]);
  const [Employment, setEmployment] = useState<any>([]);
  const [Position, setPosition] = useState<any>([]);
  const [objOption, setOption] = useState({
    lstOptJobFunction: [],
    lstOptJobDetail: [],
    lstOptWorkLocation: [],
    lstOptEducationLevel: [],
    lstOptEmploymentType: [],
    lstOptIndustryType: [],
    lstOptCareerLevel: [],
    lstOptTime: [],
    lstOptSalaryStart: [],
    lstOptSalaryEnd: [],
    lstOptJobTree: [],
    lstOptLocationTree: [],
  });
  const [isLoading, setLoading] = useState(false);
  const [isGuest, setIsGuest] = useState(false);
  const [resultFilter, setResultFilter] = useState(null);

  const language = secureLocalStorage.getItem("language").toString();
  const form = useForm({
    defaultValues: {
      arrCareerLevelID: [],
      arrEducationID: [],
      arrEmploymentTypeID: [],
      arrIndustryTypeID: [],
      arrJobFunctionID: [],
      arrLocationID: [],
      nTime: null,
      nSaralyStart: null,
      nSaralyEnd: null,
      sSearch: "",
    },
  });

  const onSubmit = (values, isSearch = true) => {
    let objSalary = values.objSalary;
    if (objSalary) {
      if (objSalary.isCustomValue) {
        values.nSaralyStart = objSalary.valueCustomStart;
        values.nSaralyEnd = objSalary.valueCustomEnd;
      } else {
        values.nSaralyStart = objSalary.valueOptionStart;
        values.nSaralyEnd = objSalary.valueOptionEnd;
      }
    }
    onSearch({ ...values },isSearch ?1 : null);
  };

  useEffect(() => {
    const GetOptionCriteria = () => {
      setLoading(true);
      let lang = "";
      let isTH = (getLanguage().id === "th");
      isTH ? lang = "th" : lang = "en"
      AxiosGet(
        `${ControllerAPI}/GetOptionCriteria`, { lang }, (res) => {
          console.log(res)
          setIsGuest(res.isGuest);
          setOption({
            ...res,
            lstOptSalaryEnd: res.lstOptSalaryEnd.map((item) => ({
              ...item,
              value: item.isAbove ? item.value + 1 : item.value,
            })),
          });
        },
        null,
        () => setLoading(false)
      );
    };
    GetOptionCriteria();

    const getFilterOption = () => {
      let lang = "";
      let isTH = (getLanguage().id === "th");
      isTH ? lang = "th" : lang = "en"
      setLoading(true);

      AxiosPost(
        `EmployerSearch/GetFilterOption`,
        { lang },
        (res) => {
          setResultFilter(res.Data);
          //Search from Home

        },
        null,
        () => setLoading(false)
      );
    };
    getFilterOption();
    onGetIndustry();
  }, []);

  useEffect(() => {
    if (objSearchFromHome && objOption.lstOptJobTree.length > 0 && objOption.lstOptLocationTree.length > 0) {
      form.setValue("sSearch", objSearchFromHome["sSearch"]);
      form.setValue("arrLocationID", objSearchFromHome["arrLocationID"]);
      form.setValue("arrJobFunctionID", objSearchFromHome["arrJobFunctionID"]);
    }
  }, [objOption.lstOptJobTree, objOption.lstOptLocationTree])

  useEffect(() => {
    if (isFindCompanyName) {
      form.setValue("sSearch", isFindCompanyName);
      onSubmit(form.getValues(), true)
    }
  }, [isFindCompanyName])

  useEffect(() => {
    onSubmit(form.getValues(),false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const onGetIndustry = () => {
    let lang = "";
    let isTH = (getLanguage().id === "th");
    isTH ? lang = "th" : lang = "en"
    AxiosGet(`DataFilter/SetFilter_SelectIndustrytype`, { lang }, (res) => {
      setRawIndustry(res.lstOptionAdmin || []);
      setEmployment(res.lstOptionEmployment || []);
      setPosition(res.lstOptionPosition || [])
    });
  };
  return (
    <>
      <Grid container>
        <Grid item xs>
          <Paper
            sx={{
              p: 2,
              margin: "auto",
              marginBottom: "3rem",
              flexGrow: 1,
              backgroundColor: (theme) =>
                theme.palette.mode === "dark" ? "#1A2027" : "#fff",
            }}
          >
            <FormProvider {...form}>
              <Grid container spacing={2}>
                <Grid item container spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                    {isLoading ? (
                      <Skeleton height={40} />
                    ) : (
                      <Input
                        name="sSearch"
                        label={<>{i18n(`${i18nForthisPage}search`)}</>}
                        fullWidth
                        small
                        onKeyPress={(e) => {
                          e.key === "Enter" && onSubmit(form.getValues());
                        }}
                      />
                    )}
                  </Grid>
                  {/* {pageType !== EPageType.Viewed && ( */}
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    {isLoading ? (
                      <Skeleton height={40} />
                    ) : (
                      <MultiSelectGroupForm
                        name={"arrJobFunctionID"}
                        // label={i18n(`home.function`)}
                        label={i18n(`home.functionALL`)}
                        fullWidth
                        isOptionTwoLanguage
                        options={objOption.lstOptJobTree ?? []}

                      />
                    )}
                  </Grid>
                  {/* )} */}
                  <Grid item sx={{ flexGrow: 1 }}>
                    {isLoading ? (
                      <Skeleton height={40} />
                    ) : (
                      <MultiSelectGroupForm
                        name={"arrLocationID"}
                        // label={i18n(`${i18nForthisPage}location`)}
                        label={i18n(`home.placeholderWhereALL`)}
                        fullWidth
                        isOptionTwoLanguage
                        options={objOption.lstOptLocationTree ?? []}
                      />
                    )}
                  </Grid>
                  {!isFullSearch && (
                    <Grid item>
                      {isLoading ? (
                        <Skeleton height={40} width={80} />
                      ) : (
                        <BtnSearch
                          txt={i18n(`${i18nForthisPage}btnsearch`)}
                          onClick={form.handleSubmit((e) => onSubmit(e))} />
                      )}
                    </Grid>
                  )}
                </Grid>
                {isFullSearch && (
                  <Grid item container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                      {isLoading ? (
                        <Skeleton height={40} />
                      ) : (
                        <AutoCompleteSelect
                          label={i18n(`${i18nForthisPage}date.${pageType}`)}
                          fullWidth
                          name={"nTime"}
                          options={objOption.lstOptTime.map((item) => ({
                            value: item.value,
                            label: item[`label_${language}`],
                          }))}
                          customValue={{ key: "value" }}
                        />
                      )}
                    </Grid>
                    {/* {pageType !== EPageType.Viewed && ( */}
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <AutoCompleteSelectWithSlider
                        sAboveText={i18n(`common.more_than`)}
                        sBetweenText={i18n(`common.between`)}
                        sTextChangeMode={i18n(`common.custom_value`)}
                        name="objSalary"
                        fullWidth
                        options={resultFilter?.lstSalary ?? []}
                        label={i18n(`EmployerSearch.Filter.salary`)}
                        maxValueSlider={130000}
                      />
                    </Grid>
                    {/* )} */}
                    <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                      {isLoading ? (
                        <Skeleton height={40} />
                      ) : (
                        <AutoCompleteSelectForSearch
                          label={i18n(`${i18nForthisPage}employmentType`)}
                          fullWidth
                          name={"arrEmploymentTypeID"}
                          // options={objOption.lstOptEmploymentType.map(
                          //   (item) => ({
                          //     value: item.value,
                          //     label: item[`label_${language}`],
                          //   })
                          // )}
                          options={Employment}
                        />
                      )}
                    </Grid>
                    {/* {pageType !== EPageType.Viewed && ( */}
                    <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                      {isLoading ? (
                        <Skeleton height={40} />
                      ) : (
                        <AutoCompleteSelectForSearch
                          label={i18n(`${i18nForthisPage}career`)}
                          fullWidth
                          name={"arrCareerLevelID"}
                          options={Position}
                        // options={objOption.lstOptCareerLevel.map(
                        //   (item) => ({
                        //     value: item.value,
                        //     label: item[`label_${language}`],
                        //   })
                        // )}
                        />
                      )}
                    </Grid>
                    {/* )} */}
                    {/* {pageType !== EPageType.Viewed && ( */}
                    <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                      {isLoading ? (
                        <Skeleton height={40} />
                      ) : (
                        <AutoCompleteSelectForSearch
                          label={i18n(`${i18nForthisPage}education`)}
                          fullWidth
                          name={"arrEducationID"}
                          options={objOption.lstOptEducationLevel.map(
                            (item) => ({
                              value: item.value,
                              label: item[`label_${language}`],
                            })
                          )}
                        />
                      )}
                    </Grid>
                    {/* )} */}
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                      {isLoading ? (
                        <Skeleton height={40} />
                      ) : (
                        <AutoCompleteSelectForSearch
                          label={i18n(`${i18nForthisPage}industryType`)}
                          fullWidth
                          name={"arrIndustryTypeID"}
                          options={rawIndustry}
                        // options={objOption.lstOptIndustryType.map((item) => ({
                        //   value: item.value,
                        //   label: item[`label_${language}`],
                        // }))}
                        />
                      )}
                    </Grid>
                    <Grid item sx={{ marginLeft: "auto" }}>
                      {isLoading ? (
                        <Skeleton height={40} width={80} />
                      ) : (
                        <BtnSearch
                          txt={i18n(`${i18nForthisPage}btnsearch`)}
                          onClick={form.handleSubmit((e) => onSubmit(e))} />
                      )}
                    </Grid>
                  </Grid>
                )}
                {/* {pageType !== EPageType.Viewed && ( */}
                <Grid item container justifyContent={"flex-end"}>
                  {isLoading ? (
                    <Skeleton height={40} width={150} />
                  ) : (
                    <ButtonBase onClick={(e) => setFullSearch(!isFullSearch)}>
                      <Typography component="p">
                        {i18n(
                          `${i18nForBtn}${isFullSearch ? "shortSearch" : "fullSearch"
                          }`
                        )}
                      </Typography>
                    </ButtonBase>
                  )}
                </Grid>
                {/* )} */}
              </Grid>
            </FormProvider>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default CriteriaSearch;

const AutoCompleteSelectForSearch = (props) => {
  const { register, setValue, watch } = useFormContext();

  return (
    <FormControl
      sx={{
        width: "100%",
        "label.MuiInputLabel-shrink": {
          top: "0!important",
        },
        "label.MuiInputLabel-formControl > span": {
          color: "red",
        },
      }}
    >
      <Autocomplete
        {...props}
        disableCloseOnSelect
        multiple
        renderOption={(props, option: any) => {
          return (
            <li {...props} key={option.value}>
              {option.label}
            </li>
          );
        }}
        value={
          props.options.filter(
            (f) => watch(props.name).indexOf(f.value) > -1
          ) || []
        }
        fullWidth
        size={"small"}
        {...register(props.name)}
        onChange={(e, v) => {
          let value = v ? v.map((item) => item.value) : [];
          setValue(props.name, value, { shouldValidate: true });
          props.onChange && props.onChange(value);
        }}
        getOptionLabel={(itemOption: any) => {
          return `${itemOption.label}`;
        }}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        renderInput={(params) => <TextField label={props.label} {...params} />}
        renderTags={(tagValue, getTagProps) => {
          if (tagValue != undefined) {
            return (
              <>
                {tagValue.slice(0, 1).map((option: any, index) => (
                  <Chip
                    key={option.value}
                    size={"small"}
                    {...getTagProps({ index })}
                    label={
                      option.label.length > 15
                        ? option.label.substring(0, 15) + ".."
                        : option.label
                    }
                  />
                ))}
                {tagValue.length > 1 ? (
                  <Chip size={"small"} label={"+" + (tagValue.length - 1)} />
                ) : (
                  <></>
                )}
              </>
            );
          }
        }}
      />
    </FormControl>
  );
};

