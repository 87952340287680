import React, { useEffect, useRef, useState } from 'react'
import { Modal, Box, Tooltip, CircularProgress, Grid, Typography } from "@mui/material"
import { Close } from "@mui/icons-material"
import { i18n } from 'i18n';
import { useForm, FormProvider } from "react-hook-form"
import InputCustomLabel from 'components/Common/ElementInForm/InputCustomLabelFormItem';
import { BtnSearch } from "components/Common/Button"
import { AxiosGet, AxiosPost } from 'service/CommonFunction/TS_function';
import moment from 'moment';
import WorkIcon from '@mui/icons-material/Work';
import SchoolIcon from '@mui/icons-material/School';
import RoomIcon from '@mui/icons-material/Room';
import BadgeIcon from '@mui/icons-material/Badge';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import PaidIcon from '@mui/icons-material/Paid';
import { useDispatch } from 'react-redux';
import { DialogActionCreators } from 'store/redux/DialogAlert';
import { BtnBack, BtnSave } from "components/Common/Button"
import { JobPostSentGroupStatus } from 'service/CommonInterface/CommonInterface';
import AutoCompleteRenderItem from 'components/Common/ElementInForm/AutoCompleteRenderItem';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup"
import yupFormSchemas from 'components/Common/yup/yupFormSchemas';
export default function ModalIntroduction({ isOpen, onClose, sIntroduction = "" }) {
    const i18nField = "EmployerSearch.Info."
    const styles = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        borderRadius: "10px",
        p: 2,
        "@media screen and (min-width:640px)": {
            width: 600,
        }
    };

    return (
        <Modal
            open={isOpen}
            onClose={() => {
                onClose()
            }}
            closeAfterTransition
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Box sx={{ ...styles, width: 350, maxHeight: "80vh", overflow: "hidden" }} className={" flex flex-col"} >
                <div className="flex flex-row ">
                    <div className="flex  ">
                        <Typography variant='h6' >{i18n(`${i18nField}introduction`)}</Typography>
                    </div>
                    <div className="flex flex-[1] justify-end  ">
                        <Tooltip title={i18n(`common.close`)}>
                            <div onClick={() => {
                                onClose()
                            }} className='rounded-[50%] bg-[#d1d1d1] w-[1.5em] h-[1.5em] flex cursor-pointer ease-linear duration-[100ms]  hover:bg-[#8c8c8c]'>
                                <Close fontSize='small' className='m-[auto] text-[white]' />
                            </div>
                        </Tooltip>
                    </div>
                </div>
                <div className='flex flex-col gap-[10px] py-[1em] overflow-hidden'>
                    <div className='flex flex-1'>
                        <label>{sIntroduction}</label>
                    </div>
                </div>
            </Box>
        </Modal>
    )
}
