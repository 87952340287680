const SetupTemplateEN = {
  label: {
    UploadLogo: "Upload Logo",
    UploadBanner: "Upload Banner",
    Content: "Entrepreneur's business",
    AddParagraphAdd: "Add Paragraph",
    ChangePassword: "Change Password",
    send: "Send",
    oldPass: "Old Password",
    close: "Close",
    newPass: "New Password",
    confirmPass: "Confirm Password",
    HelperTXTContent: "Introduce your companies' profiles here to let potential job seekers know your companies!",
    Or: "or"
  },
};

const SetupTemplateTH = {
  label: {
    UploadLogo: "อัปโหลด โลโก้",
    UploadBanner: "อัปโหลด แบนเนอร์",
    Content: "ธุรกิจของผู้ประกอบการ",
    AddParagraphAdd: "เพิ่มเนื้อหา",
    ChangePassword: "เปลี่ยนรหัสผ่าน",
    send: "ส่ง",
    oldPass: "รหัสผ่านเดิม",
    close: "ปิด",
    newPass: "รหัสผ่านใหม่",
    confirmPass: "ยืนยันรหัสผ่าน",
    HelperTXTContent: "แนะนำบริษัทของคุณที่นี่ เพื่อให้ผู้สมัครงานรู้จักบริษัทของคุณ!",
    Or: "หรือ"
  },
};

export { SetupTemplateTH, SetupTemplateEN };