import Layout_Back_BPS from "layout/CommonLayout/Layout_Back_BPS/index_BPS";
import AdminCompany from "view/back/AdminCompany/AdminCompany";
import AdminCompanyBanner from "view/back/AdminCompany/AdminCompanyBanner";
import AdminCompanyBannerDetail from "view/back/AdminCompany/AdminCompanyBannerDetail";
import AdminCompanyInfo from "view/back/AdminCompany/AdminCompanyInfo";
import AdminCompanyJob from "view/back/AdminCompany/AdminCompanyJob";
import AdminCompanyJobDetail from "view/back/AdminCompany/AdminCompanyJobDetail";
import AdminCompanyMember from "view/back/AdminCompany/AdminCompanyMember";
import AdminCompanyOrder from "view/back/AdminCompany/AdminCompanyOrder";
import AdminCompanyOrderDetail from "view/back/AdminCompany/AdminCompanyOrderDetail";
import AdminCompanySetting from "view/back/AdminCompany/AdminCompanySetting";

const AdminCompanyRoute = [
  {
    path: "/AdminCompany",
    component: AdminCompany,
    layout: Layout_Back_BPS,
    exact: true,
  },
  {
    path: "/AdminCompanyInfo",
    component: AdminCompanyInfo,
    layout: Layout_Back_BPS,
    exact: true,
  },
  {
    path: "/AdminCompanyJob",
    component: AdminCompanyJob,
    layout: Layout_Back_BPS,
    exact: true,
  },
  {
    path: "/AdminCompanyJobDetail",
    component: AdminCompanyJobDetail,
    layout: Layout_Back_BPS,
    exact: true,
  },
  {
    path: "/AdminCompanyBanner",
    component: AdminCompanyBanner,
    layout: Layout_Back_BPS,
    exact: true,
  },
  {
    path: "/AdminCompanyBannerDetail",
    component: AdminCompanyBannerDetail,
    layout: Layout_Back_BPS,
    exact: true,
  },
  {
    path: "/AdminCompanyOrder",
    component: AdminCompanyOrder,
    layout: Layout_Back_BPS,
    exact: true,
  },
  {
    path: "/AdminCompanyOrderDetail",
    component: AdminCompanyOrderDetail,
    layout: Layout_Back_BPS,
    exact: true,
  },
  {
    path: "/AdminCompanySetting",
    component: AdminCompanySetting,
    layout: Layout_Back_BPS,
    exact: true,
  },
  {
    path: "/AdminCompanyMember",
    component: AdminCompanyMember,
    layout: Layout_Back_BPS,
    exact: true,
  }
];

export default AdminCompanyRoute;
