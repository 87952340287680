import { useState } from "react";
import {
  Stack,
  Typography,
  SxProps,
  CardMedia,
  Grid,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ModalViewImage from "components/Common/ModalViewImage/ModalViewImage";
import ParseHTML from "html-react-parser";

const objStyle = {
  w_600: {
    fontWeight: 600,
  } as SxProps,
  w_500: {
    fontWeight: 500,
  } as SxProps,
  fs_12: {
    marginTop: "1em",
    fontWeight: 400
  } as SxProps,
  text_date: {
    color: "#1ca5fc",
    fontSize: "0.8em",
  } as SxProps,
  image_cover: {
    display: "flex",
    flex: 1,
    minHeight: 400,
    backgroundSize: "cover",
  } as SxProps,
  container: {
    // marginTop: "10vh",
    // " > div": {
    //     marginTop: "2rem"
    // }
  } as SxProps,
  youtube_style: {
    width: "60vw;",
    height: "calc((60vw)*9/16);",
    maxHeight: "100vw",
    maxWidth: "100%",
    " @media (max-width:767px) ": {
      width: "80vw;",
      height: "calc((80vw)*9/16);",
    },
  } as SxProps,
  row_tag: {
    padding: "0px 1rem",
    marginTop: "5px",
  } as SxProps,
  img_list: {
    minWidth: "20vw",
    maxWidth: "30vw",
    minHeight: "85%",
    maxHeight: "25vh",
    padding: "5px 10px",
    paddingTop: "10px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    " @media (max-width:1100px) ": {
      minWidth: "15vw",
      minHeight: "90%",
      maxWidth: "25vw",
    },
    " @media (max-width:767px) ": {
      minWidth: "30vw",
      minHeight: "90%",
      maxWidth: "50vw",
    },
  } as SxProps,
};

export default function BPS_Content({ lstContent }) {
  let history = useNavigate();
  const [isViewImage, setIsViewImage] = useState(false);
  const [imageView, setImageView] = useState("");
  return (
    <>
      <ModalViewImage
        image={imageView}
        isOpen={isViewImage}
        handleClose={() => setIsViewImage(false)}
      />
      <Grid sx={objStyle.container}>
        {lstContent.map((item, key) => {
          switch (item.nTypeId) {
            case 1:
              return (
                <Stack marginBottom={2} key={`type_1_${key}`} direction="row">
                  <div>{ParseHTML(item.sContent)}</div>
                </Stack>
              );
            case 2:
              return (
                <Stack key={`type_2_${key}`} direction="row">
                  <Stack direction="column" alignItems="center" flex={1}>
                    <CardMedia
                      onClick={() => {
                        // setImageView(item.sImageOrVideo)
                        setImageView(item.sFileNameSys);
                        setIsViewImage(true);
                      }}
                      component={"img"}
                      sx={{
                        objectFit: "contain",
                        width: "auto",
                        maxWidth: "100%",
                        maxHeight: "calc(90vh - 45px)",
                        cursor: "pointer",
                      }}
                      src={item.sFileNameSys}
                    />
                    <Typography sx={objStyle.fs_12} variant="body1">
                      {item.sContent}
                    </Typography>
                  </Stack>
                </Stack>
              );
            case 3:
              return (
                <Stack key={`type_3_${key}`} direction="row">
                  <Stack direction="column" alignItems="center" flex={1}>
                    {/* <CardMedia component="video" controls height="400px" src={item.sImageOrVideo} /> */}
                    <CardMedia
                      component="video"
                      controls
                      height="400px"
                      src={item.sFileNameSys}
                    />
                    <Typography sx={objStyle.fs_12} variant="body1">
                      {item.sContent}
                    </Typography>
                  </Stack>
                </Stack>
              );
            case 4:
              const splitPath = item.sUrl.split("/");
              const endPath = splitPath[splitPath.length - 1];
              let codeYoutubeSave = endPath;
              if (endPath.indexOf("watch") != -1) {
                codeYoutubeSave = endPath.split("=", endPath.length)[1];
              }
              return (
                <Stack key={`type_4_${key}`} direction="row">
                  <Stack direction="column" alignItems="center" flex={1}>
                    <CardMedia
                      sx={objStyle.youtube_style}
                      component="iframe"
                      allow={
                        "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      }
                      allowFullScreen
                      frameBorder={0}
                      src={`https://www.youtube.com/embed/${codeYoutubeSave}`}
                    />
                    <Typography sx={objStyle.fs_12} variant="body1">
                      {item.sContent}
                    </Typography>
                  </Stack>
                </Stack>
              );
          }
        })}
      </Grid>
    </>
  );
}