import * as yup from "yup";
import { i18n } from "../../../i18n";
import moment from "moment";
import { IsNullOrUndefined } from "service/CommonFunction/TS_function";

const yupFormSchemas = {
  generic(label) {
    return yup.mixed().label(label);
  },
  string(label, config?) {
    config = config || {};
    let yupChain = yup
      .string()
      .transform((cv, ov) => {
        return ov === "" ? null : cv;
      })
      .nullable(true)
      .trim()
      .label(label);

    if (config.required) {
      if (config.isAdmin) {
        yupChain = yupChain.required(("กรุณาระบุ ${ path }"));
      } else {
        yupChain = yupChain.required(i18n("validation.string.required"));
      }
    }

    if (config.min || config.min === 0) {
      yupChain = yupChain.min(config.min);
    }

    if (config.max) {
      yupChain = yupChain.max(config.max);
    }

    if (config.regex) {
      let Msg = IsNullOrUndefined(config.matches) ? "" : config.matches;
      yupChain = yupChain.matches(config.regex, Msg);
    }

    if (config.matches) {
      yupChain = yupChain.matches(config.matches, config.labelmatches);
    }

    return yupChain;
  },
  boolean(label, config?) {
    return yup.bool().default(false).label(label);
  },
  object(label, config?) {
    config = config || {};
    if (config.required) {
      if (config.isAdmin) {
        config.msg = "กรุณากรอกข้อมูล " + label;
        return yup
          .object()
          .label(label)
          .required("กรุณาระบุ ${path}")
          .nullable();
      } else {
        config.msg = "กรุณากรอกข้อมูล " + label;
        return yup
          .object()
          .label(label)
          .required(i18n("validation.object.required"))
          .nullable();
      }

    } else {
      return yup.object().label(label).nullable(true);
    }
    // if (config.required) {
    //   // config.msg = "กรุณากรอกข้อมูล " + label;
    //   yupChain = yupChain.required(i18n("validation.string.required"));
    // }
  },
  relationToOne(label, config?) {
    config = config || {};

    let yupChain = yup
      .mixed()
      .nullable(true)
      .label(label)
      .transform((value, originalValue) => {
        if (!originalValue) {
          return null;
        }

        return originalValue.id;
      });

    if (config.required) {
      yupChain = yupChain.required();
    }

    return yupChain;
  },
  stringArray(label, config?) {
    config = config || {};

    let yupChain = yup
      .array()
      .compact()
      .ensure()
      .of(
        yup
          .string()
          .transform((cv, ov) => {
            return ov === "" ? null : cv;
          })
          .trim()
      )
      .label(label);
    if (config.min || config.min === 0) {
      yupChain = yupChain.min(config.min);
    } else if (config.required) {
      yupChain = yupChain.min(1, i18n("validation.object.required"));
    }

    if (config.max) {
      yupChain = yupChain.max(config.max);
    }

    return yupChain;
  },
  relationToMany(label, config?) {
    config = config || {};

    let yupChain = yup
      .array()
      .nullable(true)
      .label(label)
      .transform((value, originalValue) => {
        if (!originalValue || !originalValue.length) {
          return [];
        }

        return originalValue.map((item) => item.id);
      });

    if (config.required || config.min) {
      yupChain = yupChain.required();
    }

    if (config.min || config.min === 0) {
      yupChain = yupChain.min(config.min);
    } else if (config.required) {
      yupChain = yupChain.min(1);
    }

    if (config.max) {
      yupChain = yupChain.max(config.max);
    }

    return yupChain;
  },
  integer(label, config?) {
    config = config || {};

    let yupChain = yup
      .number()
      .transform((cv, ov) => {
        return ov === "" ? null : cv;
      })
      .integer()
      .nullable(true)
      .label(label);

    if (config.required) {
      if (config.isAdmin) {
        yupChain = yupChain.required(("กรุณาระบุ ${ path }"));
      } else {
        yupChain = yupChain.required(i18n("validation.string.required"));
      }
    }
    if (config.min || config.min === 0) {
      if (config.isAdmin) {
        yupChain = yupChain.min(config.min, ("${path} ต้องมากกว่า ${min}"));
      } else {
        yupChain = yupChain.min(config.min, i18n("validation.number.min"));
      }
    }

    if (config.max) {
      if (config.isAdmin) {
        yupChain = yupChain.max(config.max, ("${path} ต้องน้อยกว่า ${max}"));
      } else {
        yupChain = yupChain.max(config.max, i18n("validation.number.max"));
      }
    }

    return yupChain;
  },
  images(label, config?) {
    config = config || {};

    let yupChain = yup.array().nullable(true).label(label);

    if (config.required || config.min) {
      yupChain = yupChain.required();
    }

    if (config.min || config.min === 0) {
      yupChain = yupChain.min(config.min);
    } else if (config.required) {
      yupChain = yupChain.min(1);
    }

    if (config.max) {
      yupChain = yupChain.max(config.max);
    }

    return yupChain;
  },
  files(label, config?) {
    config = config || {};

    let yupChain = yup.array().compact().ensure().nullable(true).label(label);

    if (config.required || config.min) {
      yupChain = yupChain.required();
    }

    if (config.min || config.min === 0) {
      yupChain = yupChain.min(config.min);
    } else if (config.required) {
      yupChain = yupChain.min(1);
    }

    if (config.max) {
      yupChain = yupChain.max(config.max);
    }

    return yupChain;
  },
  enumerator(label, config?) {
    config = config || {};

    let yupChain = yup
      .string()
      .transform((cv, ov) => {
        return ov === "" ? null : cv;
      })
      .label(label)
      .nullable(true)
      .oneOf([null, ...(config.options || [])]);

    if (config.required) {
      yupChain = yupChain.required(i18n("validation.string.selected"));
    }

    return yupChain;
  },
  email(label, config?) {
    config = config || {};

    let yupChain = yup
      .string()
      .transform((cv, ov) => {
        return ov === "" ? null : cv;
      })
      .nullable(true)
      .trim()
      .label(label)
      .email(i18n("validation.string.email"));

    if (config.required) {
      if (config.isAdmin) {
        yupChain = yupChain.required(("กรุณาระบุ ${ path }"));
      } else {
        yupChain = yupChain.required(i18n("validation.string.required"));
      }
    }

    if (config.min || config.min === 0) {
      yupChain = yupChain.min(config.min);
    }

    if (config.max) {
      yupChain = yupChain.max(config.max);
    }

    if (config.matches) {
      yupChain = yupChain.matches(config.matches);
    }

    return yupChain;
  },
  decimal(label, config?) {
    config = config || {};
    let yupChain = yup
      .number()
      .transform((cv, ov) => {
        return ov === "" ? null : cv;
      })
      .nullable(true)
      .label(label);

    if (config.required) {
      if (config.isAdmin) {
        yupChain = yupChain.required(("กรุณาระบุ ${ path }"));
      } else {
        yupChain = yupChain.required(i18n("validation.string.required"));
      }
    }

    if (config.min || config.min === 0) {
      if (config.isAdmin) {
        yupChain = yupChain.min(config.min, ("${path} ต้องมากกว่า ${min}"));
      } else {
        yupChain = yupChain.min(config.min, i18n("validation.decimal.min"));
      }
    }

    if (config.max) {
      if (config.isAdmin) {
        yupChain = yupChain.max(config.max, ("${path} ต้องน้อยกว่า ${max}"));
      } else {
        yupChain = yupChain.max(config.max, i18n("validation.decimal.max"));
      }
    }

    return yupChain;
  },
  datetime(label, config?) {
    config = config || {};
    let yupChain = yup
      .mixed()
      .nullable(true)
      .label(label)
      .transform((value, originalValue) =>
        value
          ? moment(originalValue, "YYYY-MM-DDTHH:mm:ss").toISOString()
          : null
      );

    if (config.required) {
      yupChain = yupChain.required(i18n("validation.string.required"));
    }

    return yupChain;
  },
  datetimeRange(label, config?) {
    config = config || {};
    let yupChain = yup.array().of(
      yup
        .mixed()
        .nullable(true)
        .label(label)
        .transform((value, originalValue) =>
          value ? moment(value).format("YYYY-MM-DDTHH:mm:ss") : null
        )
    );

    if (config.required) {
      yupChain = yupChain.required(i18n("validation.string.required"));
    }

    return yupChain;
  },
  dateRange(label, config?) {
    config = config || {};
    let objYup = yup
      .mixed()
      .label(label)
      .transform((value, originalValue) =>
        value ? moment(value).format("YYYY-MM-DD") : null
      );

    if (config.required) {
      objYup = objYup.required();
    }
    let yupChain = yup.array().of(objYup);

    return yupChain;
  },
  date(label, config?) {
    config = config || {};
    let yupChain = yup
      .mixed()
      .nullable(true)
      .label(label)
      .test("is-date", i18n("validation.mixed.default"), (value) => {
        if (!value) {
          return true;
        }

        return moment(value, "YYYY-MM-DD").isValid();
      })
      .transform((value) =>
        value ? moment(value).format("YYYY-MM-DD") : null
      );

    if (config.required) {
      yupChain = yupChain.required(i18n("validation.string.required"));
    }

    return yupChain;
  },
  confirmPassword(label, config?) {
    config = config || {};
    let yupChain = yup
      .string()
      .transform((cv, ov) => {
        return ov === "" ? null : cv;
      })
      .nullable(true)
      .trim()
      .label(label);

    if (config.required) {
      yupChain = yupChain.required();
    }

    if (config.min || config.min === 0) {
      yupChain = yupChain.min(config.min);
    }

    if (config.max) {
      yupChain = yupChain.max(config.max);
    }

    if (config.regex) {
      let Msg = IsNullOrUndefined(config.matches) ? "" : config.matches;
      yupChain = yupChain.matches(config.regex, Msg);
    }

    if (config.matchefield) {
      let Msg = IsNullOrUndefined(config.matchelabel)
        ? "Passwords don't match."
        : config.matchelabel;
      yupChain = yupChain.oneOf([yup.ref(config.matchefield), null], Msg);
    }

    return yupChain;
  },
};

export default yupFormSchemas;

export const RegexpPassword = () => {
  let regexp1 = "((?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,}))"; //พิมพ์ใหญ่และตัวเลขและอักขระพิเศษ
  let regexp2 = "((?=.*[A-Z])(?=.*[0-9])(?=.*[a-z])(?=.{8,}))";
  let regexp3 = "((?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,}))";
  let regexp4 = "((?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,}))";
  let regexp5 = "((?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,}))";
  let regexp6 = "((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,}))"; //พิมพ์เล็กและพิมพ์ใหญ่และตัวเลขและอักขระพิเศษ
  // let egexp = "^(" + regexp1 + "|" + regexp2 + "|" + regexp3 + "|" + regexp4 + "|" + regexp5 + "|" + regexp6 + ")"
  let egexp = "^(" + regexp6 + ")";
  return egexp;
};
export const MsgValidate = {
  PleaseSpecify: "Please Specify ",
  // Password:(i18n("validation.string.Password")),
  Password: `${"Passwords must have at least 8 characters and contain all of the following: uppercase letters, lowercase letters, numbers, symbols. (The symbols can use !, @, #, $, %, ^, &, * )"}`,
  InvalidEmail: "Invalid Email",
  ConfirmPassword: "Invalid Password",
  Url: `Url must contain in the following: uppercase letters, lowercase letters, numbers, symbols. (The symbols can use - , _ )`,
};
