import { useState, useEffect } from 'react';
import { DataGridMui as Table, FilterFieldInterface, initRows, PaginationInterface } from "components/Common/Table/DataGridMui";
import { GridColumns } from "@mui/x-data-grid";
import { AxiosPost, GetPermission, ResultAPI } from "service/CommonFunction/TS_function";
import { useNavigate } from "react-router-dom";
import { BtnAddOnTable, BtnEditOnTable } from "components/Common/Button"
import { useDispatch } from 'react-redux';
import { DialogActionCreators } from 'store/redux/DialogAlert';
import { i18n } from 'i18n';
import { Tooltip } from '@mui/material';

const CoinPackageTable = () => {
  const i18nField = 'entities.Package';
  const dispatch = useDispatch();
  const history = useNavigate();
  const [nPermission, setPermission] = useState(0);
  const [loadingTable, setLoadingTable] = useState(true);
  const [isDataActive, setIsActive] = useState(true);
  const [isDelete, setIsDelete] = useState(false);

  const [dataRow, setDataRow] = useState<PaginationInterface>({
    ...initRows,
    sSortExpression: "sLastUpDate",
    sSortDirection: "asc"
  });

  const filter: FilterFieldInterface[] = [
    { sTypeFilterMode: "input", sFieldName: "sPackageName" },
    { sTypeFilterMode: "input", sFieldName: "nExDurationAmoun" },
    { sTypeFilterMode: "input", sFieldName: "sCoinAmoun" },
    { sTypeFilterMode: "input", sFieldName: "sSpecialPrice" },
    { sTypeFilterMode: "input", sFieldName: "sVat" },
    { sTypeFilterMode: "input", sFieldName: "sPriceAfterVat" },
  ]

  useEffect(() => {
    GetPermission(3, setPermission);
    loadData(dataRow);
  }, []);
  const loadData = (p: PaginationInterface) => {
    setLoadingTable(true)
    AxiosPost("AdminUserPackage/SearchData_TableDataPackage", { ...p, nTypeTable: 2 }, (result) => {
      setLoadingTable(false)
      setIsDelete(result.lstMaster.isOrder)
      setDataRow({
        ...p,
        arrRows: result.lstMaster,
        nDataLength: result.nDataLength,
        nPageIndex: result.nPageIndex,
      });
    })
  }

  const dataColumn: GridColumns = [
    {
      field: "sPackageName",
      headerName: i18n(`${i18nField}.Package`),
      headerAlign: "center",
      align: "left",
      flex: 3,
      resizable: false,
      minWidth: 200,
      renderCell: (item) => {
        return [
          (
            <Tooltip placement="top" title={item.row.sPackageName}>
              <div id={item.row.sID}
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '300px',
                }}>
                {item.row.sPackageName}
              </div>
            </Tooltip>
          )
        ];
      }
    },
    {
      field: "nExDurationAmoun",
      headerName: i18n(`${i18nField}.ExpiryDuration`),
      headerAlign: "center",
      align: "center",
      flex: 3,
      resizable: false,
      minWidth: 200
    },
    {
      field: "sCoinAmoun",
      headerName: i18n(`${i18nField}.CointAmount`),
      headerAlign: "center",
      align: "center",
      flex: 3,
      resizable: false,
      minWidth: 180,
    },
    {
      field: "sSpecialPrice",
      headerName: i18n(`${i18nField}.SpecialPrice`),
      headerAlign: "center",
      align: "center",
      flex: 3,
      resizable: false,
      minWidth: 180,
    },
    {
      field: "sVat",
      headerName: i18n(`${i18nField}.Vatname`),
      headerAlign: "center",
      align: "center",
      flex: 3,
      resizable: false,
      minWidth: 180,
    },
    {
      field: "sPriceAfterVat",
      headerName: i18n(`${i18nField}.Net`),
      headerAlign: "center",
      align: "right",
      flex: 3,
      resizable: false,
      minWidth: 180,
    }
  ];

  return (
    <Table
      isLoading={loadingTable}
      filterField={filter}
      Rows={dataRow}
      Columns={dataColumn}
      OnLoadData={(p) => loadData(p)}
      isCoinPackage={true}
      isAddCondition={true}
      TermsType={5}//T_Page_Content: id
    />
  );
}

export default CoinPackageTable