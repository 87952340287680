import { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Stack, styled, Tab, Tabs, Typography } from '@mui/material';
import { AxiosGet } from 'service/CommonFunction/TS_function';
import { useLocation } from "react-router-dom";
import { i18n } from 'i18n';
import BannerDetail from './AdminBannerCard';
import React from 'react';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import AdminBannerMasterTable from './AdminBannerMasterTable';
import AdminBannerTable from './AdminBannerTable';

const Banner = () => {

    const location = useLocation();
    const [arrCard, setarrCard] = useState([]);
    const [arrPosition, setarrPosition] = useState([]);
    const [arrColor, setarrColor] = useState([]);
    const searchParams = location.search;
    const sID = new URLSearchParams(searchParams).get('sID');
    const i18nField = 'entities.Banner';
    const [value, setValue] = useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    const [expanded, setExpanded] = React.useState<string | true>('panel1');
    interface TabPanelProps {
        children?: React.ReactNode;
        index: number;
        value: number;
    }

    const TabPanel = (props: TabPanelProps) => {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box >
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }
    const TabsClick = (index: number) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const Accordion = styled((props: AccordionProps) => (
        <MuiAccordion disableGutters elevation={0} square {...props} />
    ))(() => ({
        '&:before': {
            display: 'none',

        },
    }));

    const handleChangeshow = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : true);
    };

    useEffect(() => {
        OnGetData();
        GetData();
    }, []);


    const OnGetData = () => {
        AxiosGet("FromBanner/GetData_DropDown", {}, (result) => {
            setarrPosition(result.lstOptionPosition || []);
            setarrColor(result.lstTotalbanner || []);
        });
    }
    const GetData = () => {
        AxiosGet("FromBanner/GetData_Show", {}, (result) => {
            setarrCard(result.lstShow || []);

        });
    }
    return (
        <Stack spacing={2} width={"100%"}
            sx={{
                marginTop: "2%",
                border: "2px #eeeeee solid",
                backgroundColor: "rgba(255,255,255,0.75)",
                borderRadius: 5, p: 1
            }}>
            <Grid item xs={12}
                sx={{
                    p: 1,
                    borderRadius: 5,
                    boxShadow: 3,
                    bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff')
                }}>
                <Tabs value={value} onChange={handleChange} textColor="primary" allowScrollButtonsMobile variant="scrollable" TabIndicatorProps={{ style: { display: "none" } }} >
                    <Tab
                        label={
                            value == 0 ?
                                <span style={{ color: '#f9fafd' }}>{"เพิ่มตำแหน่งใหม่"}</span> :
                                <span style={{ color: '#474747' }}>{"เพิ่มตำแหน่งใหม่"}</span>}
                        {...TabsClick(0)}
                        sx={{
                            minWidth: "50%",
                            borderRadius: 5,
                            backgroundColor: value == 0 ? "#003dc6" : "#fff"
                        }}
                    />
                    <Tab
                        label={
                            value == 1 ?
                                <span style={{ color: '#f9fafd' }}>{"ค้นหาและประวัติ"}</span> :
                                <span style={{ color: '#474747' }}>{"ค้นหาและประวัติ"}</span>}
                        {...TabsClick(1)}
                        sx={{
                            minWidth: "50%",
                            borderRadius: 5,
                            backgroundColor: value == 1 ? "#003dc6" : "#fff"
                        }}
                    />
                </Tabs>
            </Grid>
            <TabPanel value={value} index={0}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {arrPosition.map((item1, index1) => {
                            let arrData = arrCard.filter(f => f.nPosition_ID == item1.value)
                            let arrColordata = arrColor.filter(f => f.nPosition_ID == item1.value)
                            let sName = 'panel' + (index1 + 1);
                            return (
                                <Grid container key={index1}>
                                    <Grid item md={12} xs={12} >
                                        <Accordion expanded={expanded === sName}
                                            onChange={handleChangeshow(sName)} >
                                            {arrColordata.map((itemsub1, indexsub1) => (
                                                <Grid item md={12} xs={12} key={indexsub1}
                                                    sx={{
                                                        marginTop: 2
                                                    }}>
                                                    <Typography align="center" color="#FFFFFF" fontSize={17}
                                                        sx={{
                                                            border: "1px solid",
                                                            borderRadius: 5, p: 1,
                                                            backgroundColor: itemsub1.sColor
                                                        }}>
                                                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                                            {item1.label}
                                                        </AccordionSummary>
                                                    </Typography>
                                                </Grid>
                                            ))}
                                            <Grid container spacing={4}  sx={{mt: "1px"}}>
                                                {arrData != null && arrData.length > 0 ? arrData.map((item, index) => (
                                                    <Grid item justifyContent="flex-start" md={6} xs={12} key={index}
                                                        sx={{
                                                            borderRadius: 5,                                                       
                                                        }}>

                                                        <BannerDetail sNameBox={item} />

                                                    </Grid>
                                                )) : null}
                                                <Grid container spacing={2}></Grid>
                                            </Grid>
                                        </Accordion>
                                    </Grid>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Grid>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <AdminBannerTable />
            </TabPanel>
        </Stack >
    );
}
export default Banner
