const MyJobEn = {
  JobApply: "Applied Job",
  JobInterested: "Interested Job",
  JobIgnore: "Ignore",
  CompViewd: "Interested in you",
  CompInvited: "Invite Job",
  ViewJobDetail: "View Job Detail",
  ViewCompProfile: "View Company Profile",
  JobAccept: "Accept",
  JobDecline: "Decline",
  GotoPage: "Go to Page",
};

const MyJobTh = {
  JobApply: "งานที่สมัคร",
  JobInterested: "งานที่สนใจ",
  JobIgnore: "ลบออกจากงานที่สนใจ",
  CompViewd: "บริษัทที่ซื้อประวัติคุณ",
  CompInvited: "คำเชิญจากบริษัท",
  ViewJobDetail: "ดูรายละเอียดงาน",
  ViewCompProfile: "ดูข้อมูลบริษัท",
  JobAccept: "ตอบรับ",
  JobDecline: "ปฏิเสธ",
  GotoPage: "ไปที่หน้านี้",
};

export { MyJobTh, MyJobEn };
