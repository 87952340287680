import { useState, useEffect } from "react";
import { Box, Grid, Stack, Tab, Tabs, Typography } from "@mui/material";
import AdminCompanyInfo from "./AdminCompanyInfo";
import AdminCompanyJob from "./AdminCompanyJob";
import AdminCompanyBanner from "./AdminCompanyBanner";
import AdminCompanyOrder from "./AdminCompanyOrder";
import AdminCompanySetting from "./AdminCompanySetting";
import { useNavigate, useLocation } from "react-router-dom";

const AdminCompanyTabPanel = (props: { tabNo }) => {
  const { tabNo } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = location.search;
  const sID = new URLSearchParams(searchParams).get("sID");

  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        navigate(`/AdminCompanyInfo?sID=${encodeURI(sID)}`);
        break;
      case 1:
        navigate(`/AdminCompanyJob?sID=${encodeURI(sID)}`);
        break;
      case 2:
        navigate(`/AdminCompanyBanner?sID=${encodeURI(sID)}`);
        break;
      case 3:
        navigate(`/AdminCompanyOrder?sID=${encodeURI(sID)}`);
        break;
      case 4:
        navigate(`/AdminCompanySetting?sID=${encodeURI(sID)}`);
        break;
      case 5:
        navigate(`/AdminCompanyMember?sID=${encodeURI(sID)}`);
        break;
    }
  };

  const TabsClick = (index: number) => {
    return {      
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  useEffect(() => {
    TabsClick(tabNo);
    setValue(tabNo);
  }, []);

  return (
    <Grid
      item
      sx={{
        p: 1,
        borderRadius: 5,
        boxShadow: 3,
        bgcolor: (theme) =>
          theme.palette.mode === "dark" ? "#101010" : "#fff",
      }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="primary"
        allowScrollButtonsMobile
        variant="scrollable"
        TabIndicatorProps={{ style: { display: "none" } }}
      >
        <Tab
          label={
            <span style={{ color: value === 0 ? "#f9fafd" : "#474747" }}>
              ข้อมูลผู้ประกอบการ
            </span>
          }
          {...TabsClick(0)}
          sx={{
            flex: 1,
            borderRadius: 5,
            backgroundColor: value === 0 ? "#003dc6" : "#fff",
          }}
        />

        <Tab
          label={
            <span style={{ color: value === 1 ? "#f9fafd" : "#474747" }}>
              ประกาศงาน
            </span>
          }
          {...TabsClick(0)}
          sx={{
            flex: 1,
            borderRadius: 5,
            backgroundColor: value === 1 ? "#003dc6" : "#fff",
          }}
        />

        <Tab
          label={
            <span style={{ color: value === 2 ? "#f9fafd" : "#474747" }}>
              ลงโฆษณาแบนเนอร์
            </span>
          }
          {...TabsClick(0)}
          sx={{
            flex: 1,
            borderRadius: 5,
            backgroundColor: value === 2 ? "#003dc6" : "#fff",
          }}
        />

        <Tab
          label={
            <span style={{ color: value === 3 ? "#f9fafd" : "#474747" }}>
              คำสั่งซื้อ
            </span>
          }
          {...TabsClick(0)}
          sx={{
            flex: 1,
            borderRadius: 5,
            backgroundColor: value === 3 ? "#003dc6" : "#fff",
          }}
        />

        <Tab
          label={
            <span style={{ color: value === 4 ? "#f9fafd" : "#474747" }}>
              ตั้งค่าผู้ประกอบการ
            </span>
          }
          {...TabsClick(0)}
          sx={{
            flex: 1,
            borderRadius: 5,
            backgroundColor: value === 4 ? "#003dc6" : "#fff",
          }}
        />

        <Tab
          label={
            <span style={{ color: value === 5 ? "#f9fafd" : "#474747" }}>
              สมาชิก
            </span>
          }
          {...TabsClick(0)}
          sx={{
            flex: 1,
            borderRadius: 5,
            backgroundColor: value === 5 ? "#003dc6" : "#fff",
          }}
        />
      </Tabs>
    </Grid>
  );
};
export default AdminCompanyTabPanel;
