import { useEffect, useRef, useState } from "react";
import {
  Grid,
  Skeleton,
  Typography,
} from "@mui/material";
import { AxiosPost } from "service/CommonFunction/TS_function";
import { i18n } from "i18n";
import CriteriaSearch from "components/SearchJob/CriteriaSearch";
import { useNavigate, useLocation } from "react-router-dom";
import JobTab from "./JobTab";
import secureLocalStorage from "react-secure-storage";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const ControllerAPI = "SearchJob";
const i18nForthisPage = "searchJob.";

const SearchJobCOMP = () => {
  const language = secureLocalStorage.getItem("language").toString();
  const navigate = useNavigate();

  const location = useLocation();
  const objSearchFromHome = location?.state
    ? location.state["objSearchFromHome"]
    : null;
  const [lstJob, setJob] = useState([]);
  const [NewsPage, setNewsPage] = useState() as any;
  const [Page, setPage] = useState(1);
  const [objPaging, setPaging] = useState({ nTotal: 0 });
  const [isLoading, setLoading] = useState(false);
  const [isFindCompanyName, setCompanyName] = useState("");
  const [nPerpageLast, setPerpageLast] = useState(0);
  const [nPerpageFirst, setPerpageFirst] = useState(0);
  const [isShowJobNum, setJobNum] = useState(true);
  const oFormRef = useRef() as any;

  const objSchema = {};

  const [objSchemaContext, setSchemaContext] = useState(objSchema);
  const schema = yup.object().shape(objSchemaContext);
  const form = useForm({
    resolver: yupResolver(schema),
    shouldUnregister: false,
    shouldFocusError: true,
    mode: "all",
    defaultValues: {
      nPage: Page
    } as any
  });

  const GetJob = (param, nPageParam = null) => {
    setLoading(true);
    let nPageFocus = nPageParam ? nPageParam : Page
    if (nPageFocus != Page) {
      setPage(nPageFocus)
    } else {
      param = {
        nPage: nPageFocus,
        ...param,
      };
      AxiosPost(
        `${ControllerAPI}/SearchJob`,
        param,
        (res) => {
          setLoading(false);
          if (res.ShowJoblist == "true") {
            setJobNum(true);
          } else {
            setJobNum(false);
          }

          let nCountPage = (res.nTotal) / 10;
          let nPageNews = Math.ceil(nCountPage);
          setNewsPage(nPageNews);
          setJob([...res.lstJob]);

          setPaging({
            nTotal: res.nTotal,
          });

          let nLastTotal = Page * 10;
          let nFirstTotal = (Page - 1) * 10 + 1;

          if (nFirstTotal < 1) {
            setPerpageFirst(Page);
          } else {
            setPerpageFirst(nFirstTotal)
          }

          if (Page == nPageNews) {
            setPerpageLast(res.nTotal);
          } else {
            setPerpageLast(nLastTotal);
          }
        },
        null
      );
    }
  };

   // const PER_PAGE = 10;
  // const count = NewsPage;
  // const _DATA = Pagination_Custom(NewsPage || [], PER_PAGE);
  
  // //pagination
  // const handleChange = (e, p) => {
  //   setPage(p);
  //   _DATA.jump(p);
  //   if (oFormRef.current != null) {
  //     window.scrollTo({
  //       top: oFormRef.current.offsetTop - 100,
  //       left: 0,
  //       behavior: "smooth",
  //     });
  //   }
  // };

  useEffect(() => {
    if (objSearchFromHome && (objSearchFromHome["arrJobFunctionID"] || objSearchFromHome["arrLocationID"] || objSearchFromHome["sSearch"])) {
      let param = {
        arrJobFunctionID: objSearchFromHome["arrJobFunctionID"],
        arrLocationID: objSearchFromHome["arrLocationID"],
        sSearch: objSearchFromHome["sSearch"]
      };

      setTimeout(() => {
        GetJob(param);
      }, 1000);
    } else if (isFindCompanyName) { // Click at company name
      let param = {
        sSearch: isFindCompanyName
      };
      GetJob(param);
    }
  }, [isFindCompanyName]);

  return (
    <>
      <CriteriaSearch
        objSearchFromHome={objSearchFromHome}
        onSearch={(val, nPage) => { GetJob(val, nPage) }}
        page={Page}
        isFindCompanyName={isFindCompanyName}
      />
      <Typography variant="subtitle1" gutterBottom>
        {isLoading ? (
          <Skeleton width={"20%"} />
        ) : isShowJobNum ? (
          i18n(`${i18nForthisPage}perpage`, nPerpageFirst) + " - " +
          i18n(`${i18nForthisPage}perpageLast`, nPerpageLast) +
          i18n(`${i18nForthisPage}foundData`, objPaging.nTotal)
        ) : null}
      </Typography>
      <Grid container spacing={2}>
        <JobTab lstJob={lstJob} isLoading={isLoading} setCompanyName={setCompanyName} NewsPage={NewsPage} Page={Page} setPage={setPage} />
      </Grid>

      {/* <Grid container justifyContent={"center"} spacing={2} sx={{ my: "2%" }}>
        <Pagination
          count={count}
          page={Page}
          color="primary"
          onChange={handleChange}
          showFirstButton
          showLastButton
        />
      </Grid> */}
    </>
  );
};
export default SearchJobCOMP;