import { useEffect, useContext, useState } from 'react';
import { FormProvider } from 'react-hook-form'
import { Grid, Box, IconButton, Paper } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { i18n } from 'i18n';
import { ProfileContext } from 'components/SeekerProfile/Context/ProfileContext';
import AutoCompleteSelect from 'components/Common/ElementInForm/AutoCompleteSelect';
import { ILanguageSkill, IPropsLanguageSkill } from './Interface';
import { DialogActionCreators } from "store/redux/DialogAlert";
import { useDispatch } from "react-redux";

const i18nField = 'entities.SeekerProfile';
const i18nCommon = 'common';
const LanguageSkill = (props: IPropsLanguageSkill) => {
    const { form, item, index } = props;
    const dispatch = useDispatch();

    const { languageSkillContext, setLanguageSkill,
        optionLangSkill, optionLanguage
    } = useContext(ProfileContext);

    const [isClean, setIsClean] = useState(false);

    const handleSetData = (itemIndex) => {
        languageSkillContext.forEach((data) => {
            if (data.Index === itemIndex) {
                form.setValue("Language" + data.Index, data.Language || '');
                form.setValue("ConvsSkill" + data.Index, data.ConvsSkill || '');
                form.setValue("ReadSkill" + data.Index, data.ReadSkill || '');
                form.setValue("WriteSkill" + data.Index, data.WriteSkill || '');
            }
        });
        setIsClean(false);
    }

    const handleChangeValue = (form, index) => {
        let newData = languageSkillContext.map((item: ILanguageSkill) => {
            if (item.Index === index) {
                item.Language = form.getValues("Language" + index);
                item.ConvsSkill = form.getValues("ConvsSkill" + index);
                item.ReadSkill = form.getValues("ReadSkill" + index);
                item.WriteSkill = form.getValues("WriteSkill" + index);
            }
            return item;
        });

        setLanguageSkill(newData);
    }

    const handleRemoveLanguageSkill = (itemIndex: number) => {
        dispatch(DialogActionCreators.OpenDialogSubmit(i18n(`${i18nCommon}.AlertConfrimDel`), () => {
            dispatch(DialogActionCreators.CloseDialogSubmit() as any);
            dispatch(DialogActionCreators.LoadSubmit("Waiting...") as any);
            setIsClean(true);
            let newData = languageSkillContext.filter((data) => { return data.Index !== itemIndex });
            setLanguageSkill(newData);
            form.unregister("Language" + itemIndex);
            form.unregister("ConvsSkill" + itemIndex);
            form.unregister("ReadSkill" + itemIndex);
            form.unregister("WriteSkill" + itemIndex);
        }));

    }

    useEffect(() => {
        handleSetData(item.Index);
    }, [isClean]);

    useEffect(() => {
        handleSetData(item.Index);
    }, [languageSkillContext]);

    return (
        <>
            <FormProvider {...form} key={index}>
                <Box component={Paper} sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}>
                    {index === 0 ? null :
                        <IconButton aria-label="delete" color="error" onClick={() => handleRemoveLanguageSkill(item.Index)}>
                            <CloseIcon />
                        </IconButton>}
                    <Grid container justifyContent="center" alignItems="center" spacing={1} >
                        <Grid item container spacing={2} xs={12} >
                            <Grid item md={3} xs={12} >
                                <AutoCompleteSelect
                                    name={"Language" + item.Index}
                                    label={i18n(`${i18nField}.Language`)}
                                    options={optionLanguage}
                                    onChange={(value) => {
                                        handleChangeValue(form, item.Index);
                                    }}
                                    freeSolo
                                    required
                                />
                            </Grid>
                            <Grid item md={3} xs={12} >
                                <AutoCompleteSelect
                                    name={"ConvsSkill" + item.Index}
                                    label={i18n(`${i18nField}.ConvsSkill`)}
                                    options={optionLangSkill}
                                    onChange={(value) => {
                                        handleChangeValue(form, item.Index);
                                    }}
                                    required
                                />
                            </Grid>
                            <Grid item md={3} xs={12} >
                                <AutoCompleteSelect
                                    name={"ReadSkill" + item.Index}
                                    label={i18n(`${i18nField}.ReadSkill`)}
                                    options={optionLangSkill}
                                    onChange={(value) => {
                                        handleChangeValue(form, item.Index);
                                    }}
                                    required
                                />
                            </Grid>
                            <Grid item md={3} xs={12} >
                                <AutoCompleteSelect
                                    name={"WriteSkill" + item.Index}
                                    label={i18n(`${i18nField}.WriteSkill`)}
                                    options={optionLangSkill}
                                    onChange={(value) => {
                                        handleChangeValue(form, item.Index);
                                    }}
                                    required
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </FormProvider>
        </>
    )
}

export default LanguageSkill;