import { useState, useEffect, useContext } from "react";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Grid,
  Typography,
  Stack,
  Box,
  FormControlLabel,
  Checkbox,
  Button,
  Skeleton,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Paper,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {
  ButtonAddDetail,
  AccordionCustom,
  AccordionDetailsCustom,
  AccordionSummaryCustom,
} from "components/SeekerProfile/ElementCustom";
import { i18n } from "i18n";
import yupFormSchemas from "components/Common/yup/yupFormSchemas";
import Input from "components/Common/ElementInForm/InputCustomLabelFormItem";
import { ProfileContext } from "components/SeekerProfile/Context/ProfileContext";
import WorkExperience from "components/SeekerProfile/WorkExperience";
import MyAddress from "components/SeekerProfile/MyAddress";
import { IWorkExperience } from "components/SeekerProfile/Interface";
import AutoCompleteSelect from "components/Common/ElementInForm/AutoCompleteSelect";
import { BtnRadiusProfile, BtnSubmit } from "components/Common/Button";

interface IPorpsProfileSecond {
  children?: React.ReactNode;
  onSuccessCreate: Function;
}
const i18nField = "entities.SeekerProfile";

const ProfileSecond = (props: IPorpsProfileSecond) => {
  const { onSuccessCreate } = props;

  const {
    isLoading,
    arrWorkExperienceContext,
    setWorkExperience,
    addresssContext,
    setAddress,
    isWorkExperience,
    setIsWorkExperience,
    yearExperience,
    setYearExperience,
    optionWorkEXP,
  } = useContext(ProfileContext);

  const [expandedWork, setExpandedWork] = useState(true);
  const [expandedAddress, setExpandedAddress] = useState(true);
  const [selectWorkEXP, setselectWorkEXP] = useState("yes");
  const [isThai, setIsThai] = useState(true);

  const [schemaWork, setSchemaWork] = useState({});

  const loopSetSchemaWork = () => {
    const schemaTemp = {}
    arrWorkExperienceContext.forEach((item) => {
      schemaTemp[`Position${item.Index}`] = yupFormSchemas.object(
        i18n(`${i18nField}.Position`),
        { required: isWorkExperience }
      );
      schemaTemp[`Company${item.Index}`] = yupFormSchemas.object(
        i18n(`${i18nField}.Company`),
        { required: isWorkExperience }
      );
      schemaTemp[`WorkDate${item.Index}`] = yupFormSchemas.dateRange(
        i18n(`${i18nField}.StartDate`),
        { required: isWorkExperience }
      );
      schemaTemp[`StartDate${item.Index}`] = yupFormSchemas.datetime(
        i18n(`${i18nField}.StartDate`),
        { required: isWorkExperience }
      );
      schemaTemp[`EndDate${item.Index}`] = yupFormSchemas.datetime(
        i18n(`${i18nField}.EndDate`),
        { required: isWorkExperience && !item.IsCurrent }
      );
      schemaTemp[`JobFunc${item.Index}`] = yupFormSchemas.object(
        i18n(`${i18nField}.JobFunction`),
        { required: isWorkExperience }
      );
      schemaTemp[`JobDetail${item.Index}`] = yupFormSchemas.object(
        i18n(`${i18nField}.JobDetail`),
        { required: isWorkExperience }
      );
      schemaTemp[`JobIndustry${item.Index}`] = yupFormSchemas.object(
        i18n(`${i18nField}.JobIndustry`),
        { required: isWorkExperience }
      );
      schemaTemp[`WorkSalary${item.Index}`] = yupFormSchemas.decimal(
        i18n(`${i18nField}.WorkSalary`),
        { required: isWorkExperience }
      );
      schemaTemp[`WorkRemark${item.Index}`] = yupFormSchemas.string(
        i18n(`${i18nField}.WorkRemark`),
        { required: isWorkExperience }
      );
      schemaTemp[`LeaveRemark${item.Index}`] = yupFormSchemas.string(
        i18n(`${i18nField}.LeaveRemark`)
      );
    });
    formSchema["WorkEXP"] = yupFormSchemas.object(i18n(`${i18nField}.WorkEXP`), {
      required: true,
    });
    setSchemaWork(schemaTemp)
  }

  useEffect(() => {

    if (!isWorkExperience) {
      setFormSchema(schemaArddress)
    } else {
      loopSetSchemaWork()

    }
  }, [isWorkExperience, arrWorkExperienceContext])

  const arrSchemaNameWorkExp = [
    "Position",
    "Company",
    "WorkDate",
    "StartDate",
    "EndDate",
    "JobFunc",
    "JobDetail",
    "JobIndustry",
    "WorkSalary",
    "WorkRemark",
    "LeaveRemark",
  ];

  useEffect(() => {
    for (const [key, value] of Object.entries(formSchema)) {
      if (arrSchemaNameWorkExp.some((a) => key.includes(a))) {
        delete formSchema[key];
      }
    }
    setFormSchema({ ...formSchema, ...schemaWork });
  }, [schemaWork]);

  const schemaArddress = {
    Country: yupFormSchemas.object(i18n(`${i18nField}.Country`), {
      required: true,
    }),
    Region: yupFormSchemas.object(i18n(`${i18nField}.Region`), {
      required: isThai,
    }),
    Province: yupFormSchemas.object(i18n(`${i18nField}.Province`), {
      required: isThai,
    }),
  };

  useEffect(() => {
    formSchema['Region'] = yupFormSchemas.object(i18n(`${i18nField}.Region`), {
      required: isThai,
    });
    formSchema['Province'] = yupFormSchemas.object(i18n(`${i18nField}.Province`), {
      required: isThai,
    });
    setFormSchema({ ...formSchema })
  }, [isThai])

  const [formSchema, setFormSchema] = useState<any>({
    WorkEXP: yupFormSchemas.object(i18n(`${i18nField}.WorkEXP`), {
      required: isWorkExperience,
    }),
    ...schemaWork,
    ...schemaArddress,
  });

  const schema = yup.object().shape(formSchema);

  const form = useForm({
    resolver: yupResolver(schema),
    shouldUnregister: false,
    shouldFocusError: true,
    mode: "all",
  });

  const handleAddWorkUnit = () => {
    let newData = [
      ...arrWorkExperienceContext,
      {
        Index:
          Math.max.apply(
            Math,
            arrWorkExperienceContext.map((item, index) => {
              return item.Index;
            })
          ) + 1,
        Position: null,
        Company: null,
        StartDate: null,
        EndDate: null,
        IsCurrent: false,
        JobFunc: null,
        JobDetail: null,
        JobIndustry: null,
        WorkRemark: "",
      },
    ] as Array<IWorkExperience>;
    setWorkExperience(newData);
  };

  const onSubmitWithExperience = (values) => {
    setWorkExperience(arrWorkExperienceContext);
    onSuccessCreate();
  };

  const onSubmitNoExperience = () => {
    setWorkExperience(arrWorkExperienceContext);
    onSuccessCreate();
  };

  // useEffect(() => {
  //   setFormSchema({ ...schemaWork });
  // }, [schemaWork])

  useEffect(() => {
    setWorkExperience([
      {
        Index: 0,
        Position: null,
        Company: null,
        StartDate: null,
        EndDate: null,
        IsCurrent: false,
        JobFunc: null,
        JobDetail: null,
        JobIndustry: null,
        WorkRemark: "",
        WorkSalary: null,
        LeaveRemark: "",
      },
    ]);
  }, []);

  useEffect(() => {
    if (!isWorkExperience) {
      form.setValue("WorkEXP", "0");
      setWorkExperience([]);
    } else {
      form.setValue("WorkEXP", "");
      setWorkExperience([
        {
          Index: 0,
          Position: null,
          Company: null,
          StartDate: null,
          EndDate: null,
          IsCurrent: false,
          JobFunc: null,
          JobDetail: null,
          JobIndustry: null,
          WorkRemark: "",
          WorkSalary: null,
          LeaveRemark: "",
        },
      ]);
    }
  }, [isWorkExperience]);

  return (
    // isLoading ? (
    //   <Box sx={{ border: "10px", p: 1, flex: 1, m: "0 2% 2% 0" }}>
    //     <Skeleton width={"100%"} height={118} animation="wave" />
    //   </Box>
    // ) : (
    <>
      <FormProvider {...form}>
        <Box component={Paper} sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}>
          <Grid
            sx={{ border: "10px", p: 1, flex: 1, m: "0 2% 2% 0" }}
            container
            justifyContent="center"
            alignItems="center"
            spacing={3}
            xs={12} sm={12} md={12}
          >
            <Grid item container spacing={2} xs={12}>
              <Grid item xs={12}>
                <FormControl>
                  <RadioGroup
                    row
                    value={selectWorkEXP}
                    onChange={(e, v) => {
                      setIsWorkExperience(v === "yes");
                      setselectWorkEXP(v);
                    }}
                  >
                    <FormControlLabel
                      value={"yes"}
                      control={<Radio />}
                      label={i18n(`${i18nField}.HaveWorkEXP`)}
                    />
                    <FormControlLabel
                      value={"no"}
                      control={<Radio />}
                      label={i18n(`${i18nField}.NotWordEXP`)}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {isWorkExperience ? (
                <Grid item xs={12} sm={12} md={6} id="workExperience">
                  <AutoCompleteSelect
                    name={"WorkEXP"}
                    fullWidth
                    label={i18n(`${i18nField}.WorkEXP`)}
                    options={optionWorkEXP}
                    onChange={(value) => {
                      setYearExperience(value);
                    }}
                    required
                  />
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Box>

        <Box component={Paper} sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}>
          {isWorkExperience ? (
            <AccordionCustom
              sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
              expanded={expandedWork}
              onChange={() => setExpandedWork((prev) => !prev)}
            >
              <AccordionSummaryCustom>
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  {i18n(`${i18nField}.PanelWorkEXP`)}
                </Typography>
              </AccordionSummaryCustom>
              <AccordionDetailsCustom>
                {(arrWorkExperienceContext || []).map((item, index) => {
                  return (
                    <WorkExperience
                      item={item}
                      index={index}
                      key={index}
                      form={form}
                      setSchemaWork={setSchemaWork}
                      schemaWork={schemaWork}
                      isRequired={isWorkExperience}
                    />
                  );
                })}
                {!isWorkExperience ? null : (
                  <Stack
                    sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
                    direction="row"
                    justifyContent="center"
                  >
                    <BtnRadiusProfile
                      onClick={handleAddWorkUnit}
                      txt={i18n(`${i18nField}.AddWorkDetail`)}
                    />
                  </Stack>
                )}
              </AccordionDetailsCustom>
            </AccordionCustom>
          ) : null}

          <AccordionCustom
            sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
            expanded={expandedAddress}
            onChange={() => setExpandedAddress((prev) => !prev)}
          >
            <AccordionSummaryCustom>
              <Typography sx={{ width: "33%", flexShrink: 0 }}>
                {i18n(`${i18nField}.PanelAddress`)}
              </Typography>
            </AccordionSummaryCustom>
            <AccordionDetailsCustom>
              <MyAddress form={form} onChangeContry={setIsThai} IsNotShowDetail />
            </AccordionDetailsCustom>
          </AccordionCustom>

          <Stack
            spacing={1}
            sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <BtnSubmit
              onClick={
                !isWorkExperience
                  ? form.handleSubmit(onSubmitNoExperience)
                  : form.handleSubmit(onSubmitWithExperience)
              }
              txt={i18n(`${i18nField}.BtnCreateProfile`)}
            />
          </Stack>
        </Box>
      </FormProvider>
    </>
  );
};
export default ProfileSecond;