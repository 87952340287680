import React, { useEffect, useState, Fragment } from "react";
import {
  Autocomplete,
  TextField,
  Box,
  Chip,
  Typography,
  FormHelperText,
  Checkbox,
  ListItem,
  ListItemButton,
  Collapse,
  List,
} from "@mui/material";
import PropTypes, { InferProps } from "prop-types";
import { useFormContext } from "react-hook-form";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import secureLocalStorage from "react-secure-storage";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function MultiSelectGroupForm({
  options,
  label,
  disabled,
  name,
  limitTag,
  required,
  subLabel,
  autoSelect,
  fullWidth,
  onChange,
  onToggleOption,
  defaultSelectAll,
  onClearOptions,
  selectAllLabel = "เลือกทั้งหมด",
  shrink,
  sxCustomLabelChip,
  isOptionTwoLanguage,
  isBorderRadius,
  isNotShowLabel,
}: InferProps<typeof MultiSelectGroupForm.propTypes>) {
  const {
    register,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  const [arrParentIDExpand, setArrParentIDExpand] = useState([]);
  const [lang, setLang] = useState("");
  const handleToggleSelectAll = (selectedOptions) => {
    const allSelected = options.length === (getValues(name) || []).length;
    if (!allSelected) {
      setValue(name, [...options.map((m) => m.value)]);
      onChange && onChange(options);
    } else {
      setValue(name, []);
      onChange && onChange([]);
    }
  };

  const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  };

  const handleChange = (event, selectedOptions, reason) => {
    let nValue = event.target.value + "";
    if (!nValue) {
      return;
    }

    setValue("input_" + name, "");

    if (reason === "selectOption" || reason === "removeOption") {
      //Select All
      if (selectedOptions.find((option) => option && option.value === "0")) {
        handleToggleSelectAll(selectedOptions);
      } else {
        setValue(
          name,
          selectedOptions.map((m) => m.value),
          { shouldValidate: true }
        );
        
        //Parent Selected
        let arrAllParent = options.filter((f) => f.isParent);
        let arrAllParentID = arrAllParent.map((f) => f.value);
        let isParentSelected = arrAllParentID.includes(nValue);
        if (reason == "selectOption") {
          //Parent
          if (isParentSelected) {
            let arrOptionSelectedParent = options.filter(
              (f) => !f.isParent && f.sParentID == nValue
            );
            if (arrOptionSelectedParent.length == 0) {
              arrOptionSelectedParent = options.filter(f => f.value == nValue)
            }
            let arrSelectedAllParent = [
              ...watch(name),
              ...arrOptionSelectedParent.map((m) => m.value),
            ];
            setValue(name, arrSelectedAllParent.filter(onlyUnique), {
              shouldValidate: true,
            });
            if (arrOptionSelectedParent.length > 0) {
              setExpand(nValue);
            }
          } else {
            //Children
            let objParent = options.find((f) => f.value == nValue);
            let sParentID = objParent ? objParent.sParentID : "0";
            setExpand(sParentID);
            let nCountChildrenOfParent = options.filter(
              (f) => f.sParentID == sParentID
            ).length;
            //Children Selected in Parent
            let nCountChildrenSelectedOfParent = selectedOptions.filter(
              (f) => f.sParentID == sParentID
            ).length;
            let arrSelectedAllParent = [...selectedOptions.map((m) => m.value)];
            if (nCountChildrenSelectedOfParent >= nCountChildrenOfParent) {
              arrSelectedAllParent.push(sParentID);
            }
            setValue(name, arrSelectedAllParent.filter(onlyUnique), {
              shouldValidate: true,
            });
          }
        } else if (reason == "removeOption") {
          //Parent
          if (isParentSelected) {
            let arrOptionIsParentID = options
              .filter((f) => !f.isParent && f.sParentID == nValue)
              .map((m) => m.value);

            if (!options.some(s => !s.isParent && s.sParentID == nValue)) {
              arrOptionIsParentID = [nValue]
            }

            let arrSelectedID = watch(name).filter(
              (f) => !arrOptionIsParentID.includes(f)
            );
            setValue(name, arrSelectedID, { shouldValidate: true });
            removeExpand(nValue);
          } else {
            //Children
            let objParent = options.find((f) => f.value == nValue);
            let sParentID = objParent ? objParent.sParentID : "0";
            // setExpand(sParentID);
            setValue(
              name,
              selectedOptions.map((m) => m.value).filter((f) => f != sParentID),
              { shouldValidate: true }
            );
          }
        }

        onChange && onChange(selectedOptions);
      }
    } else if (reason === "clear") {
      onClearOptions && onClearOptions();
      setValue(name, [], { shouldValidate: true });
      onChange && onChange([]);
      setArrParentIDExpand([]);
    }
  };

  const setExpand = (sParentID) => {
    setArrParentIDExpand([...arrParentIDExpand, sParentID]);
  };
  const removeExpand = (sParentID) => {
    let arrCloneParentID = arrParentIDExpand;
    arrCloneParentID = arrCloneParentID.filter((f) => f !== sParentID);
    setArrParentIDExpand([...arrCloneParentID]);
  };

  const valueFrom = () => {
    let sValue = watch(name);
    if (sValue && options) {
      return sValue.map((value) =>
        options.find((option) => option.value === value)
      );
    }
    return [];
  };

  useEffect(() => {
    setLang(secureLocalStorage.getItem("language").toString());
    if (defaultSelectAll && options.length > 0) {
      handleToggleSelectAll(options);
      defaultSelectAll = false;
    }

    //Select Parent if children select all
    if (options.length > 0) {
      let arrAllParent = options.filter((f) => f.isParent);
      let arrChildrenSeleted = getValues(name) || [];

      //Loop Parent
      arrAllParent.forEach((iP) => {
        let arrChildrenOfParent = options.filter(
          (f) => f.sParentID == iP.value
        );

        let isSelectAllChildren = true;

        if (arrChildrenOfParent.length == 0) {
          isSelectAllChildren = false;
        }
        //Loop Children
        arrChildrenOfParent.forEach((iC) => {
          if (!arrChildrenSeleted.includes(iC.value)) {
            isSelectAllChildren = false;
          }

        });
        if (isSelectAllChildren) {
          arrChildrenSeleted.push(iP.value);
        }
      });

      setValue(name, arrChildrenSeleted.filter(onlyUnique));
    }
  }, [options]);

  const handleExpand = (sParentID) => {
    let arrClone = arrParentIDExpand;
    let isExist = arrClone.findIndex((f) => f == sParentID) != -1;
    if (!isExist) {
      arrClone.push(sParentID);
    } else {
      arrClone = arrClone.filter((f) => f != sParentID);
    }
    setArrParentIDExpand([...arrClone]);
  };

  const handleAutoCompleteChange = (e) => {
    let sText = e.target.value;
    setValue("input_" + name, sText);
  };

  const langCode = secureLocalStorage.getItem("language") || "th";

  return (
    <>
      <Autocomplete
        size={"small"}
        id={name}
        {...register(name)}
        multiple
        disableCloseOnSelect
        selectOnFocus
        onChange={handleChange}
        limitTags={limitTag || 1}
        disableClearable={disabled || false}
        getOptionLabel={(option: any) =>
          isOptionTwoLanguage ? option[`label_${langCode}`] : option.label
        }
        options={options}
        value={valueFrom()}
        isOptionEqualToValue={(option, value) => {
          if (value) {
            return option.value === value.value;
          }
        }}
        getOptionDisabled={() => disabled || false}
        disabled={disabled || undefined}
        noOptionsText={"No Data"}
        renderTags={(tagValue, getTagProps) => {
          let arrParent = tagValue.filter(f => f.isParent)
          let arrParentNotChildren = []
          arrParent.forEach(f => {
            if (!options.some(s => s.sParentID == f.value && !f.isParent)) {
              arrParentNotChildren.push(f)
            }
          })

          // tagValue = tagValue.filter((f) => f && !f.isParent);
          tagValue = [...tagValue.filter((f) => f && !f.isParent), ...arrParentNotChildren]
          if (tagValue != undefined) {
            return (
              <React.Fragment>
                {tagValue.slice(0, limitTag).map((option: any, index) => (
                  <Chip
                    key={option.value}
                    sx={{ ".MuiChip-label": { ...sxCustomLabelChip } }}
                    size={"small"}
                    {...getTagProps({ index })}
                    label={
                      isOptionTwoLanguage
                        ? option[`label_${langCode}`]
                        : option.label
                    }
                    disabled={disabled || false}
                    onDelete={null}
                  />
                ))}
                {tagValue.length > limitTag ? (
                  <Chip
                    size={"small"}
                    label={"+" + (tagValue.length - limitTag)}
                    disabled={disabled || false}
                    onDelete={null}
                  />
                ) : (
                  <React.Fragment></React.Fragment>
                )}
              </React.Fragment>
            );
          }
        }}
        renderOption={(props, option, { selected }) =>
          option.isParent ||
            (isOptionTwoLanguage ? option[`label_${langCode}`] : option.label) ===
            selectAllLabel ? (
            <ListItemButton key={option.value} style={{ padding: 0 }}>
              <ListItem {...props} key={option.value} value={option.value}>
                <>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    value={option.value}
                    checked={
                      ((getValues(name) || []).length > 0 &&
                        option.value === "0" &&
                        options.length === (getValues(name) || []).length) ||
                        selected
                        ? true
                        : selected
                    }
                  />
                  {isOptionTwoLanguage
                    ? option[`label_${langCode}`]
                    : option.label}
                </>
              </ListItem>
              {options.some(s => s.sParentID == option.value && !s.isParent) ?
                (arrParentIDExpand.findIndex((f) => f == option.value) !== -1 ||
                  getValues("input_" + name).length > 0) ? (
                  <Box
                    sx={{ pl: 1, pr: 1, pt: 1.5, pb: 1.5 }}
                    onClick={() => handleExpand(option.value)}
                  >
                    <ExpandLess />
                  </Box>
                ) : (
                  <Box
                    sx={{ pl: 1, pr: 1, pt: 1.5, pb: 1.5 }}
                    onClick={() => handleExpand(option.value)}
                  >
                    <ExpandMore />
                  </Box>
                )
                : <></>}

            </ListItemButton>
          ) : (
            <Collapse
              key={option.value}
              in={
                arrParentIDExpand.findIndex((f) => f == option.sParentID) !==
                -1 || getValues("input_" + name).length > 0
              }
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                <ListItem {...props} key={option.value} value={option.value}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    value={option.value}
                    checked={
                      ((getValues(name) || []).length > 0 &&
                        option.value === "0" &&
                        options.length === (getValues(name) || []).length) ||
                        selected
                        ? true
                        : false
                    }
                  />
                  {isOptionTwoLanguage
                    ? option[`label_${langCode}`]
                    : option.label}
                </ListItem>
              </List>
            </Collapse>
          )
        }
        renderInput={(params) => {
          return (
            <TextField
              size={"small"}
              name={"input_" + name}
              {...register("input_" + name)}
              // inputRef={register("input_" + name).ref}
              {...params}
              fullWidth={fullWidth}
              style={{ width: "100%" }}
              error={Boolean(errors[name])}
              InputLabelProps={{
                disabled: disabled || false,
                shrink: shrink ? true : undefined,
              }}
              onChange={handleAutoCompleteChange}
              placeholder={
                isNotShowLabel
                  ? watch(name) && watch(name).length === 0
                    ? lang == "th" ? label + " " + selectAllLabel : selectAllLabel + " " + label
                    : ""
                  : undefined
              }
              sx={{
                " > label": {
                  " > span": {
                    fontSize: "12px",
                    " ::before": {
                      content: `"${required ? " *" : ""}"`,
                      color: "red",
                    },
                  },
                },
                "label.MuiInputLabel-shrink": {
                  backgroundColor: "rgba(255,255,255,0.95)",
                },
                ".MuiOutlinedInput-notchedOutline > legend": {
                  fontSize: "0.01em",
                },
                ".MuiInputBase-sizeSmall": {
                  borderRadius: isBorderRadius ? "50px" : "auto",
                },
              }}
              label={
                !isNotShowLabel ? (
                  <Fragment>
                    {label}
                    <Typography
                      fontWeight={600}
                      component="span"
                    >{` ${subLabel}`}</Typography>
                  </Fragment>
                ) : null
              }
            />
          );
        }}
      />
      {errors && errors[name] ? (
        <FormHelperText sx={{ color: "red" }}>
          {errors[name].message}
        </FormHelperText>
      ) : null}
    </>
  );
}

MultiSelectGroupForm.propTypes = {
  required: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  hint: PropTypes.string,
  options: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  // multiple: PropTypes.bool,
  limitTag: PropTypes.number,
  subLabel: PropTypes.string,
  autoSelect: PropTypes.bool,
  shrink: PropTypes.bool,
  fullWidth: PropTypes.bool,
  onChange: PropTypes.any,
  onToggleOption: PropTypes.any,
  defaultSelectAll: PropTypes.bool,
  onClearOptions: PropTypes.any,
  selectAllLabel: PropTypes.string,
  sxCustomLabelChip: PropTypes.any,
  isOptionTwoLanguage: PropTypes.bool,
  isBorderRadius: PropTypes.bool,
  isNotShowLabel: PropTypes.bool,
};

MultiSelectGroupForm.defaultProps = {
  required: false,
  disabled: false,
  isOptionTwoLanguage: false,
  // multiple: false,
  prefixText: "",
  subLabel: "",
  autoSelect: false,
  fullWidth: true,
  limitTag: 1,
  defaultSelectAll: false,
  isBorderRadius: false,
  isNotShowLabel: false,
};
