import Typography from '@mui/material/Typography';
import { Card, CardContent, Divider, Grid } from '@mui/material';
import { i18n } from 'i18n';
import ReactPlayer from "react-player";

const BannerDetail = (item) => {
  const i18nField = 'entities.Banner'

  return (
    < Card sx={{ borderRadius: 5, boxShadow: "0px 0px 7px 2px #C9C9C9", }}>
      <CardContent sx={{ width: "100%" }} >
        <Grid container >
          <Grid item xs={5}>
            {(item.sNameBox.lstUrl[item.sNameBox.lstUrl.length - 1] == 'mp4') ? (
              <ReactPlayer
                url={item.sNameBox.sURL}
                muted
                width="85%"
                height="100%"
                playing
              />
            ) :
              <div className="info-cover" style={{
                width: "100%",
                height: "100%"
              }}>
                <div className="info-cover-detail"
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundImage: `url(${item.sNameBox.sURL})`,//รูป
                    cursor: "pointer",
                  }}
                />
              </div>
              }


          </Grid>

          <Grid item xs={7} >
            <Grid container>
              <Grid item xs={12}>
                <Grid container sx={{ margin: "5%" }} >
                  <Grid item md={3} sm={5} xs={11}>
                    <Typography fontSize={12} color="text.secondary"  >
                      {"ผู้ว่าจ้าง"}
                      <Divider />
                    </Typography>
                  </Grid>
                  <Grid item md={8} sm={6} xs={11}>
                    <Typography fontSize={12} color="#1c54b2" align="right">
                      {item.sNameBox.sEmployer_Name}
                      <Divider />
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container sx={{ margin: "5%" }}>
                  <Grid item md={5} sm={5} xs={11}>
                    <Typography fontSize={12} color="text.secondary">
                      {"หมายเลขใบสั่งซื้อ"}
                      <Divider />
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={6} xs={11}>
                    <Typography fontSize={12} color="#1c54b2" align="right">
                      {item.sNameBox.sOrder_Name}
                      <Divider />
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container sx={{ margin: "5%" }}>
                  <Grid item md={4} sm={5} xs={11}>
                    <Typography fontSize={12} color="text.secondary">
                      {"เริ่ม - สิ้นสุด"}
                      <Divider />
                    </Typography>
                  </Grid>
                  <Grid item md={7} sm={6}  xs={12} >
                    <Typography fontSize={12} color="#1c54b2" align="right" >
                      {item.sNameBox.dStartDate}-{item.sNameBox.dEnd_Date}
                      <Divider />
                    </Typography>
                    <Grid item md={1} xs={1} > </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card >
  );
}
export default BannerDetail

