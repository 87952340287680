import MyJobInterested from "components/MyJob/MyJobInterested";

const MyJobInterest = () => {
  return (
    <>
      <MyJobInterested />
    </>
  );
};

export default MyJobInterest;
