import Layout_Font from "layout/CommonLayout/Layout_Font";

import SettingSeeker from "view/font/SettingSeeker/SettingSeeker";

const Setting = [
  {
    path: "/SettingSeeker",
    component: SettingSeeker,
    layout: Layout_Font,
    exact: true,
  },
];

export default Setting;