import { useState, useEffect, useContext, useRef } from "react";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Grid,
  Typography,
  Stack,
  Box,
  FormControlLabel,
  Checkbox,
  Button,
  FormControl,
  RadioGroup,
  Radio,
  OutlinedInput,
  InputAdornment,
  Paper,
  FormHelperText,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  ButtonAddDetail,
  AccordionCustom,
  AccordionDetailsCustom,
  AccordionSummaryCustom,
} from "components/SeekerProfile/ElementCustom";
import { i18n } from "i18n";
import yupFormSchemas from "components/Common/yup/yupFormSchemas";
import Input from "components/Common/ElementInForm/InputCustomLabelFormItem";
import { ProfileContext } from "components/SeekerProfile/Context/ProfileContext";
import WorkExperience from "components/SeekerProfile/WorkExperience";
import MyAddress from "components/SeekerProfile/MyAddress";
import EducationHistory from "components/SeekerProfile/EducationHistory";
import MyCertificate from "components/SeekerProfile/MyCertificate";
import AutoCompleteRenderItem from "components/Common/ElementInForm/AutoCompleteRenderItem";
import MyJobPositon from "components/SeekerProfile/MyJobPositon";
import UploadFile from "components/Common/ElementInForm/UploadFile/UploadFile";
import {
  ICertificate,
  IEducation,
  ILanguageSkill,
  IWorkExperience,
  IMyJobPosition,
} from "components/SeekerProfile/Interface";
import LanguageSkill from "components/SeekerProfile/LanguageSkill";
import { AxiosPostCreateProfile, AxiosGet, Extension, ResultAPI, AxiosPost } from "service/CommonFunction/TS_function";
import moment, { now } from "moment";
import { DialogActionCreators } from "store/redux/DialogAlert";
import AutoCompleteSelect from "components/Common/ElementInForm/AutoCompleteSelect";
import { BtnRadiusProfile, BtnSubmit } from "components/Common/Button";
import { FnBlock_UI } from "service/SystemFunction/SystemFunction";
import InputNumber from "components/Common/ElementInForm/InputNumber";
import { any } from "prop-types";

interface IPorpsProfileThird {
  children?: React.ReactNode;
  onSuccessCreate: Function;
}
const i18nField = "entities.SeekerProfile";

const ProfileThird = (props: IPorpsProfileThird) => {
  const { BlockUI, UnBlockUI } = FnBlock_UI();

  const dispatch = useDispatch();
  const history = useNavigate();
  const { onSuccessCreate } = props;
  const {
    myProfileContext,
    setMyProfile,
    arrWorkExperienceContext,
    setWorkExperience,
    addresssContext,
    setAddress,
    isWorkExperience,
    setIsWorkExperience,
    yearExperience,
    setYearExperience,
    educationContext,
    setEducation,
    certificateContext,
    setCertificate,
    ability,
    setAbility,
    languageSkillContext,
    setLanguageSkill,
    jobPositionContext,
    setJobPosition,
    fileUploadContext,
    setFileUpload,
    optionWorkEXP,
  } = useContext(ProfileContext);

  const [expandedWork, setExpandedWork] = useState(true);
  const [expandedAddress, setExpandedAddress] = useState(true);
  const [expandedEducation, setExpandedEducation] = useState(true);
  const [expandedCertificate, setExpandedCertificate] = useState(true);
  const [expandedAbility, setExpandedAbility] = useState(true);
  const [expandedLangSkill, setExpandedLangSkill] = useState(true);
  const [expandedJobApply, setExpandedJobApply] = useState(true);
  const [expandedFile, setExpandedFile] = useState(true);
  const [selectWorkEXP, setselectWorkEXP] = useState(
    isWorkExperience ? "yes" : "no"
  );
  const [isStartNow, setIsStartNow] = useState(true);
  const oFormRef = useRef(null);
  const [isThai, setIsThai] = useState(
    //ประเทศไทย
    addresssContext.Country && addresssContext.Country["value"] === 519
  );

  useEffect(() => {
    formSchema['Region'] = yupFormSchemas.object(i18n(`${i18nField}.Region`), {
      required: isThai,
    });
    formSchema['Province'] = yupFormSchemas.object(i18n(`${i18nField}.Province`), {
      required: isThai,
    });
    setFormSchema({ ...formSchema })
  }, [isThai])


  useEffect(() => {
    if (!isWorkExperience) {
      form.setValue("WorkEXP", "0");
      setWorkExperience([]);
    } else {
      form.setValue("WorkEXP", "");
    }
  }, [isWorkExperience]);




  const [schemaWork, setSchemaWork] = useState({});

  // Set up Schema Work Experience 
  const loopSetSchemaWork = () => {
    const schemaTemp = {};
    arrWorkExperienceContext.forEach((item) => {
      schemaTemp[`Position${item.Index}`] = yupFormSchemas.object(
        i18n(`${i18nField}.Position`),
        { required: isWorkExperience }
      );
      schemaTemp[`Company${item.Index}`] = yupFormSchemas.object(
        i18n(`${i18nField}.Company`),
        { required: isWorkExperience }
      );
      schemaTemp[`WorkDate${item.Index}`] = yupFormSchemas.dateRange(
        i18n(`${i18nField}.StartDate`),
        { required: isWorkExperience }
      );
      schemaTemp[`StartDate${item.Index}`] = yupFormSchemas.datetime(
        i18n(`${i18nField}.StartDate`),
        { required: isWorkExperience }
      );
      schemaTemp[`EndDate${item.Index}`] = yupFormSchemas.datetime(
        i18n(`${i18nField}.EndDate`),
        { required: isWorkExperience && !item.IsCurrent }
      );
      schemaTemp[`JobFunc${item.Index}`] = yupFormSchemas.object(
        i18n(`${i18nField}.JobFunction`),
        { required: isWorkExperience }
      );
      schemaTemp[`JobDetail${item.Index}`] = yupFormSchemas.object(
        i18n(`${i18nField}.JobDetail`),
        { required: isWorkExperience }
      );
      schemaTemp[`JobIndustry${item.Index}`] = yupFormSchemas.object(
        i18n(`${i18nField}.JobIndustry`),
        { required: isWorkExperience }
      );
      schemaTemp[`WorkSalary${item.Index}`] = yupFormSchemas.decimal(
        i18n(`${i18nField}.WorkSalary`),
        { required: isWorkExperience }
      );
      schemaTemp[`WorkRemark${item.Index}`] = yupFormSchemas.string(
        i18n(`${i18nField}.WorkRemark`),
        { required: isWorkExperience }
      );
      schemaTemp[`LeaveRemark${item.Index}`] = yupFormSchemas.string(
        i18n(`${i18nField}.LeaveRemark`)
      );
    });
    setSchemaWork(schemaTemp);
  };
  //

  // Set up Schema Skill
  const [schemaLanguageSkill, setSchemaLanguageSkill] = useState({});
  const loopSetSchemaLanguageSkill = () => {
    const schemaTemp = {};
    (languageSkillContext || []).forEach((item) => {
      schemaTemp[`Language${item.Index}`] = yupFormSchemas.object(
        i18n(`${i18nField}.Language`),
        { required: true }
      );
      schemaTemp[`ConvsSkill${item.Index}`] = yupFormSchemas.object(
        i18n(`${i18nField}.ConvsSkill`),
        { required: true }
      );
      schemaTemp[`ReadSkill${item.Index}`] = yupFormSchemas.object(
        i18n(`${i18nField}.ReadSkill`),
        { required: true }
      );
      schemaTemp[`WriteSkill${item.Index}`] = yupFormSchemas.object(
        i18n(`${i18nField}.WriteSkill`),
        { required: true }
      );
    });
    setSchemaLanguageSkill(schemaTemp);
  }
  //

  // Set up Schema Position Job 
  const [shcemaPositionJob, setShcemaPositionJob] = useState({});
  const loopSetSchemaPositionJob = () => {
    const schemaTemp = {};
    jobPositionContext.forEach((item) => {
      schemaTemp[`ApplyPosition${item.Index}`] = yupFormSchemas.string(
        i18n(`${i18nField}.ApplyPosition`),
        { required: true }
      );
      schemaTemp[`ApplyJobFunction${item.Index}`] = yupFormSchemas.object(
        i18n(`${i18nField}.ApplyJobFunction`),
        { required: true }
      );
      schemaTemp[`ApplyJobDetail${item.Index}`] = yupFormSchemas.object(
        i18n(`${i18nField}.ApplyJobDetail`),
        { required: true }
      );
      schemaTemp[`ApplyJobType${item.Index}`] = yupFormSchemas.object(
        i18n(`${i18nField}.ApplyJobType`),
        { required: true }
      );
      schemaTemp[`ApplyIndustryType${item.Index}`] = yupFormSchemas.object(
        i18n(`${i18nField}.JobIndustry`),
        { required: true }
      );
      schemaTemp[`ApplySalaryStart${item.Index}`] = yupFormSchemas.integer(
        i18n(`${i18nField}.ApplySalaryStart`),
        { required: true }
      );
      schemaTemp[`ApplySalaryEnd${item.Index}`] = yupFormSchemas.integer(
        i18n(`${i18nField}.ApplySalaryEnd`)
      );
    });
    setShcemaPositionJob(schemaTemp);
  };
  //

  // Set up Schema Education
  const [schemaEducation, setSchemaEducation] = useState({});
  const loopSetSchemaEducation = () => {
    const schemaTemp = {};
    (educationContext || []).forEach((item) => {
      schemaTemp[`University${item.Index}`] = yupFormSchemas.object(
        i18n(`${i18nField}.University`),
        { required: true }
      );
      schemaTemp[`EducateLevel${item.Index}`] = yupFormSchemas.object(
        i18n(`${i18nField}.EducateLevel`),
        { required: true }
      );
      schemaTemp[`Faculty${item.Index}`] = yupFormSchemas.string(
        i18n(`${i18nField}.Faculty`),
        { required: false }
      );
      schemaTemp[`Major${item.Index}`] = yupFormSchemas.string(
        i18n(`${i18nField}.Major`),
        { required: true }
      );
      schemaTemp[`GraduationYear${item.Index}`] = yupFormSchemas.datetime(
        i18n(`${i18nField}.GraduationYear`),
        { required: true }
      );
      schemaTemp[`RemarkEdu${item.Index}`] = yupFormSchemas.string(
        i18n(`${i18nField}.EduRemark`)
      );
    });
    setSchemaEducation(schemaTemp);
  };
  //

  // Remove old schema 

  // -> Work Experience
  const arrSchemaNameWorkExp = [
    "Position",
    "Company",
    "WorkDate",
    "StartDate",
    "EndDate",
    "JobFunc",
    "JobDetail",
    "JobIndustry",
    "WorkSalary",
    "WorkRemark",
    "LeaveRemark",
  ];

  useEffect(() => {
    for (const [key, value] of Object.entries(formSchema)) {
      if (arrSchemaNameWorkExp.some((a) => key.includes(a))) {
        delete formSchema[key];
      }
    }
    setFormSchema({ ...formSchema, ...schemaWork });
  }, [schemaWork]);


  // -> Position Job
  const arrSchemaNamePositionJob = [
    "ApplyPosition",
    "ApplyJobFunction",
    "ApplyJobDetail",
    "ApplyJobType",
    "ApplyIndustryType",
    "ApplySalaryStart",
    "ApplySalaryEnd",
  ];

  useEffect(() => {
    for (const [key, value] of Object.entries(formSchema)) {
      if (arrSchemaNamePositionJob.some((a) => key.includes(a))) {
        delete formSchema[key];
      }
    }
    setFormSchema({ ...formSchema, ...shcemaPositionJob });
  }, [shcemaPositionJob]);

  // -> Education
  const arrSchemaNameEducation = [
    "University",
    "EducateLevel",
    "Faculty",
    "Major",
    "GraduationYear",
    "RemarkEdu",
  ];

  useEffect(() => {
    for (const [key, value] of Object.entries(formSchema)) {
      if (arrSchemaNameEducation.some((a) => key.includes(a))) {
        delete formSchema[key];
      }
    }
    setFormSchema({ ...formSchema, ...schemaEducation });
  }, [schemaEducation]);
  //

  // -> Language Skill
  const arrSchemaNameLanguageSkill = [
    "ConvsSkill",
    "ReadSkill",
    "Language",
    "WriteSkill",
  ];

  useEffect(() => {
    for (const [key, value] of Object.entries(formSchema)) {
      if (arrSchemaNameLanguageSkill.some((a) => key.includes(a))) {
        delete formSchema[key];
      }
    }
    setFormSchema({ ...formSchema, ...schemaLanguageSkill });
  }, [schemaLanguageSkill]);
  //

  // useEffact set new schema 
  useEffect(() => {
    loopSetSchemaPositionJob();
  }, [jobPositionContext])
  useEffect(() => {
    loopSetSchemaWork();
  }, [isWorkExperience, arrWorkExperienceContext]);
  useEffect(() => {
    loopSetSchemaEducation();
  }, [educationContext]);
  useEffect(() => {
    loopSetSchemaLanguageSkill();
  }, [languageSkillContext]);
  //


  const schemaCert = {};
  (certificateContext || []).forEach((item) => {
    schemaCert[`CertName${item.Index}`] = yupFormSchemas.string(
      i18n(`${i18nField}.CertName`)
    );
    schemaCert[`ApproveBy${item.Index}`] = yupFormSchemas.string(
      i18n(`${i18nField}.ApproveBy`)
    );
    schemaCert[`ApproveDate${item.Index}`] = yupFormSchemas.datetime(
      i18n(`${i18nField}.ApproveDate`)
    );
    schemaCert[`ExpirdDate${item.Index}`] = yupFormSchemas.datetime(
      i18n(`${i18nField}.ExpirdDate`)
    );
    schemaCert[`checkNoExpire${item.Index}`] = yupFormSchemas.boolean(
      i18n(`${i18nField}.checkNoExpire`)
    );
    schemaCert[`CertRemark${item.Index}`] = yupFormSchemas.string(
      i18n(`${i18nField}.CertRemark`)
    );
  });



  const [formSchema, setFormSchema] = useState<any>({
    WorkEXP: yupFormSchemas.object(i18n(`${i18nField}.WorkEXP`), {
      required: isWorkExperience,
    }),
    Country: yupFormSchemas.object(i18n(`${i18nField}.Country`), {
      required: true,
    }),
    Region: yupFormSchemas.object(i18n(`${i18nField}.Region`), {
      required: isThai,
    }),
    Province: yupFormSchemas.object(i18n(`${i18nField}.Province`), {
      required: isThai,
    }),
    // Ability: yupFormSchemas.stringArray(i18n(`${i18nField}.Ability`)),
    DayStart: yupFormSchemas.integer(i18n(`${i18nField}.Day`), { required: false }),
    CurrentSalary: yupFormSchemas.decimal(i18n(`${i18nField}.CurrentSalary`)),

    ...schemaWork,
    ...schemaEducation,
    ...schemaCert,
    ...schemaLanguageSkill,
    ...shcemaPositionJob,
  });

  const schema = yup.object().shape(formSchema);

  const form = useForm({
    resolver: yupResolver(schema),
    shouldUnregister: false,
    shouldFocusError: true,
    mode: "all",
  });

  const setFocusNewField = (sName) => {
    setTimeout(() => {
      try {
        form.setFocus(sName);
      } catch (error) { }
    }, 500);
  };

  const handleAddWorkUnit = () => {
    let nGenIndex = arrWorkExperienceContext.length == 0 ? 1 :
      (Math.max.apply(
        Math,
        arrWorkExperienceContext.map((item, index) => {
          return item.Index;
        })
      ) || 0) + 1;
    let newData = [
      ...arrWorkExperienceContext,
      {
        Index: nGenIndex,
        Position: "",
        Company: "",
        StartDate: null,
        EndDate: null,
        IsCurrent: false,
        JobFunc: "",
        JobDetail: "",
        JobIndustry: "",
        WorkRemark: "",
        WorkSalary: null,
        LeaveRemark: "",
      },
    ] as Array<IWorkExperience>;

    setWorkExperience(newData);
    setFocusNewField(`Position${nGenIndex}`);
  };

  const handleAddEducate = () => {
    let nGenIndex = educationContext.length == 0 ? 1 :
      (Math.max.apply(
        Math,
        educationContext.map((item: IEducation, index) => {
          return item.Index;
        })
      ) || 0) + 1;
    let newData = [
      ...educationContext,
      {
        Index: nGenIndex,
        Academy: "",
        EducateLevel: "",
        University: "",
        Faculty: "",
        Major: "",
        GraduationYear: null,
        Remark: "",
      },
    ] as Array<IEducation>;
    setEducation(newData);
  };

  const handleAddCertificate = () => {
    let nGenIndex = certificateContext.length == 0 ? 1 :
      (Math.max.apply(
        Math,
        certificateContext.map((item: ICertificate, index) => {
          return item.Index;
        })
      ) || 0) + 1;

    let newData = [
      ...certificateContext,
      {
        Index: nGenIndex,
        CertName: "",
        ApproveBy: "",
        ApproveDate: null,
        ExpirdDate: null,
        IsNotExpird: false,
        CertRemark: "",
      },
    ] as Array<ICertificate>;
    setCertificate(newData);
  };

  const handleAddLanguageSkill = () => {
    let nGenIndex = languageSkillContext.length == 0 ? 1 :
      (Math.max.apply(
        Math,
        languageSkillContext.map((item: ILanguageSkill, index) => {
          return item.Index;
        })
      ) || 0) + 1;
    let newData = [
      ...languageSkillContext,
      {
        Index: nGenIndex,
        Language: null,
        ConvsSkill: null,
        ReadSkill: null,
        WriteSkill: null,
      },
    ] as Array<ILanguageSkill>;
    setLanguageSkill(newData);
  };

  const handleAddJobAppiy = () => {
    let nGenIndex = jobPositionContext.length == 0 ? 1 :
      (Math.max.apply(
        Math,
        jobPositionContext.map((item: IMyJobPosition, index) => {
          return item.Index;
        })
      ) || 0) + 1;
    let newData = [
      ...jobPositionContext,
      {
        Index: nGenIndex,
        ApplyPosition: null,
        ApplyJobFunction: null,
        ApplyJobDetail: null,
        ApplyJobType: null,
        ApplyIndustryType: null,
        ApplySalaryStart: null,
        ApplySalaryEnd: null,
      },
    ] as Array<IMyJobPosition>;
    setJobPosition(newData);
  };

  useEffect(() => {
    setTimeout(() => {
      handleAddEducate();
      handleAddCertificate();
      handleAddLanguageSkill();
      handleAddJobAppiy();
    }, 300);
    form.setValue("WorkEXP", yearExperience);
  }, []);

  useEffect(() => {
    let arrAbility = [];
    arrAbility = form.getValues("Ability")
    if (arrAbility.length > 0) {
      arrAbility.forEach(f => {
        if (f.length > 1000) {
          dispatch(DialogActionCreators.OpenDialogWarning(i18n(`${i18nField}.TaxAbility`)));
        }
      })
    }

  }, [form.getValues()]);

  const onSubmit = (values) => {
    let arrWorkEXP = [];
    if (selectWorkEXP === "yes") {
      arrWorkEXP = arrWorkExperienceContext.map((item) => {
        return {
          nWorkExperienceId: item.Index || 0,
          sPosition: item.Position && (item.Position["label"] || ""),
          sCompany: (item.Company && item.Company["label"]) || "",
          // dStartDate: moment(item.StartDate).toDate(),
          // dEndDate: item.EndDate ? moment(item.EndDate).toDate() : null,
          nStartDate: moment(item.StartDate).valueOf(),
          nEndDate: moment(item.EndDate).valueOf(),
          IsCurrent: item.IsCurrent,
          nJobFunction: item.JobFunc["value"] || 0,
          nJobDetail: item.JobDetail["value"] || 0,
          nJobIndustry: item.JobIndustry["value"] || 0,
          sRemark: item.WorkRemark,
          nWorkSalary: item.WorkSalary,
          sLeaveRemark: item.LeaveRemark,
        };
      });
    }

    const arrEducateion = educationContext.map((item) => {
      return {
        nEducationId: 0,
        sEducateName: (item.University && item.University["label"]) || "",
        nEducationLevel: item.EducateLevel["value"] || 0,
        nUniversityId: item.University["value"] || 0,
        sFaculty: item.Faculty,
        sMajor: item.Major,
        // nGraduationYear: moment(item.GraduationYear).toDate(),
        nGraduationYear: moment(item.GraduationYear).valueOf(),
        sEducateRemark: item.EduRemark,
      };
    });

    const arrCertificate = certificateContext.map((item) => {
      return {
        nCertificateId: 0,
        sCertificateName: item.CertName,
        sApproveCert: item.ApproveBy,
        dApproveDate: item.ApproveDate
          ? moment(item.ApproveDate).toDate()
          : null,
        dExpireDate: item.IsNotExpird ? null : moment(item.ExpirdDate).toDate(),
        IsNotExpire: item.IsNotExpird,
        sCertRemark: item.CertRemark,
        nApproveDate: moment(item.ApproveDate).valueOf(),
        nExpireDate: moment(item.ExpirdDate).valueOf(),
      };
    });

    const arrLangSkill = languageSkillContext.map((item) => {
      return {
        nLanguageSkillId: 0,
        nLanguageId:
          item.Language["value"] === undefined ? -1 : item.Language["value"],
        nSpeekingId: item.ConvsSkill["value"],
        nReadingId: item.ReadSkill["value"],
        nWritingId: item.WriteSkill["value"],
        sOther:
          item.Language["value"] === undefined ? item.Language["label"] : "",
      };
    });

    const arrJobPosition = jobPositionContext.map((item) => {
      return {
        nPositionId: 0,
        sJobPositionName: item.ApplyPosition,
        nJobFunctionId: item.ApplyJobFunction["value"],
        nJobDetailId: item.ApplyJobDetail["value"],
        nJobTypeId: item.ApplyJobType["value"],
        nIndustryTypeId: item.ApplyIndustryType["value"],
        nSalaryStart: item.ApplySalaryStart,
        nSalaryEnd: item.ApplySalaryEnd || null,
        IsNotSpecified: item.IsNotSpeccified || false,
      };
    });

    //Check validate salary end
    let isInvalid = false;
    let nIndex = -1;
    arrJobPosition.forEach((f) => {
      if (f.nSalaryEnd && f.nSalaryEnd < +f.nSalaryStart) {
        nIndex = f.nPositionId;
        isInvalid = true;
        return;
      }
    });

    if (isInvalid) {
      form.setFocus("ApplySalaryEnd" + nIndex);
      form.setError("ApplySalaryEnd" + nIndex, {
        type: "custom",
      });
      return false;
    }

    const dataProfile = {
      sName: myProfileContext.Name,
      sSurname: myProfileContext.Surname,
      sNameEN: myProfileContext.NameEN,
      sSurnameEN: myProfileContext.SurnameEN,
      sEmail: myProfileContext.Email,
      sPWD: myProfileContext.PWD,
      IsGetNews: myProfileContext.IsGetNews,
      IsUnderage: myProfileContext.IsUnderage,
      sTel: myProfileContext.Tel,
      IsWorkExperience: isWorkExperience,
      nYearWorkExperience: yearExperience ? yearExperience["value"] || 0 : 0,
      IsWork: isStartNow,
      nDay: isStartNow ? 0 : values.DayStart,
      nLast_Salary: values.CurrentSalary || 0,
      // nLast_Salary: values.CurrentSalary || null,
      nCountryId: addresssContext.Country && addresssContext.Country["value"] || 0,
      nRegionId: addresssContext.Region
        ? addresssContext.Region["value"] || null
        : null,
      nProvince: addresssContext.Province
        ? addresssContext.Province["value"] || null
        : null,
      listAbility: values.Ability || [],
      listWorkExperience: arrWorkEXP,
      listEducation: arrEducateion,
      listCertificate: arrCertificate,
      listLanguage: arrLangSkill,
      listFile: fileUploadContext,
      listPosition: arrJobPosition,
    };

    dispatch(
      DialogActionCreators.OpenDialogSubmit(
        i18n(`${i18nField}.ConfirmMsgCreate`),
        () => {
          dispatch(DialogActionCreators.CloseDialogSubmit() as any);
          dispatch(DialogActionCreators.LoadSubmit("Waiting...") as any);
          BlockUI();
          AxiosGet("Authen/GetTokenRegister", {}, (res) => {
            AxiosPostCreateProfile(`Guest/CreateProfile`, res, dataProfile,
              (result) => {
                const res = result.Data;
                if (res.IsSuccess) {
                  UnBlockUI();
                  ResultAPI(result, i18n(`${i18nField}.MsgCreated`), () => { //Saved successfully 
                    dispatch(
                      DialogActionCreators.OpenDialogSuccess(
                        i18n(`${i18nField}.MsgCreated`),//Saved successfully     
                        () => onBack()
                      ) as any
                    );
                  });
                }
                UnBlockUI();
              },
              (err) => {
                if (!err.response) {
                  dispatch(DialogActionCreators.OpenDialogWarning(err.Message));
                  // history("/error", null);
                  return;
                }
              }
            );
          });

        }
      ) as any
    );
  };

  const onBack = () => {
    history("/");
    onScroll();
  }

  const onScroll = () => {
    window.scrollTo({
      top: - 100,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <FormProvider {...form}>
        <Box component={Paper} sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}>
          <Grid
            container
            sx={{ border: "10px", p: 1, flex: 1, m: "0 2% 2% 0" }}
            justifyContent="center"
            alignItems="center"
            spacing={3}
            xs={12} sm={12} md={12}
          >
            <Grid item container spacing={2} xs={12}>
              <Grid item xs={12}>
                <FormControl>
                  <RadioGroup
                    row
                    value={selectWorkEXP}
                    onChange={(e, v) => {
                      setIsWorkExperience(v === "yes");
                      setselectWorkEXP(v);
                      if (v === "yes") {
                        setWorkExperience([
                          {
                            Index: 0,
                            Position: null,
                            Company: null,
                            StartDate: null,
                            EndDate: null,
                            IsCurrent: false,
                            JobFunc: null,
                            JobDetail: null,
                            JobIndustry: null,
                            WorkRemark: "",
                            WorkSalary: null,
                            LeaveRemark: "",
                          },
                        ]);
                      }
                    }}
                  >
                    <FormControlLabel
                      value={"yes"}
                      control={<Radio />}
                      label={i18n(`${i18nField}.HaveWorkEXP`)}
                    />
                    <FormControlLabel
                      value={"no"}
                      control={<Radio />}
                      label={i18n(`${i18nField}.NotWordEXP`)}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {isWorkExperience ? (
                <Grid item xs={12} sm={12} md={6}>
                  <AutoCompleteSelect
                    name={"WorkEXP"}
                    fullWidth
                    label={i18n(`${i18nField}.WorkEXP`)}
                    options={optionWorkEXP}
                    onChange={(value) => {
                      setYearExperience(value.value);
                    }}
                    required
                  />
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Box>

        {isWorkExperience ? (
          <AccordionCustom
            sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
            expanded={expandedWork}
            onChange={() => setExpandedWork((prev) => !prev)}
          >
            <div ref={oFormRef}>
              <AccordionSummaryCustom>
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  {i18n(`${i18nField}.PanelWorkEXP`)}
                </Typography>
              </AccordionSummaryCustom>
            </div>
            <AccordionDetailsCustom>
              {(arrWorkExperienceContext || []).map(
                (item: IWorkExperience, index) => {
                  return (
                    <WorkExperience
                      item={item}
                      schemaWork={schemaWork}
                      setSchemaWork={setSchemaWork}
                      index={index}
                      key={index}
                      form={form}
                      isRequired={isWorkExperience}
                    />
                  );
                }
              )}
              {!isWorkExperience ? null : (
                <Stack
                  sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
                  direction="row"
                  justifyContent="center"
                >
                  <BtnRadiusProfile
                    txt={i18n(`${i18nField}.AddWorkDetail`)}
                    onClick={handleAddWorkUnit}
                  />
                </Stack>
              )}
            </AccordionDetailsCustom>
          </AccordionCustom>
        ) : null}

        <AccordionCustom
          sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
          expanded={expandedAddress}
          onChange={() => setExpandedAddress((prev) => !prev)}
        >
          <AccordionSummaryCustom>
            <Typography sx={{ width: "33%", flexShrink: 0 }}>
              {i18n(`${i18nField}.PanelAddress`)}
            </Typography>
          </AccordionSummaryCustom>
          <AccordionDetailsCustom>
            <MyAddress form={form} onChangeContry={setIsThai} IsNotShowDetail />
          </AccordionDetailsCustom>
        </AccordionCustom>

        <AccordionCustom
          sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
          expanded={expandedEducation}
          onChange={() => setExpandedEducation((prev) => !prev)}
        >
          <AccordionSummaryCustom>
            <Typography sx={{ width: "33%", flexShrink: 0 }}>
              {i18n(`${i18nField}.PanelEducation`)}
            </Typography>
          </AccordionSummaryCustom>
          <AccordionDetailsCustom>
            {(educationContext || []).map((item: IEducation, index) => {
              return (
                <EducationHistory
                  item={item}
                  index={index}
                  key={index}
                  form={form}
                />
              );
            })}
            <Stack
              sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
              direction="row"
              justifyContent="center"
            >
              <BtnRadiusProfile
                txt={i18n(`${i18nField}.AddWorkDetail`)}
                onClick={handleAddEducate}
              />
            </Stack>
          </AccordionDetailsCustom>
        </AccordionCustom>

        <AccordionCustom
          sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
          expanded={expandedCertificate}
          onChange={() => setExpandedCertificate((prev) => !prev)}
        >
          <AccordionSummaryCustom>
            <Typography sx={{ width: "33%", flexShrink: 0 }}>
              {i18n(`${i18nField}.PanelCert`)}
            </Typography>
          </AccordionSummaryCustom>
          <AccordionDetailsCustom>
            {(certificateContext || []).map((item: ICertificate, index) => {
              return (
                <MyCertificate
                  item={item}
                  index={index}
                  key={index}
                  form={form}
                />
              );
            })}
            <Stack
              sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
              direction="row"
              justifyContent="center"
            >
              <BtnRadiusProfile
                txt={i18n(`${i18nField}.AddWorkDetail`)}
                onClick={handleAddCertificate}
              />
            </Stack>
          </AccordionDetailsCustom>
        </AccordionCustom>

        <AccordionCustom
          sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
          expanded={expandedAbility}
          onChange={() => setExpandedAbility((prev) => !prev)}
        >
          <AccordionSummaryCustom>
            <Typography sx={{ width: "33%", flexShrink: 0 }}>
              {i18n(`${i18nField}.PanelAbility`)}
            </Typography>
          </AccordionSummaryCustom>
          <AccordionDetailsCustom>
            <Grid sx={{ border: "10px", p: 1, flex: 1, m: "2%" }} item xs={12}>
              <AutoCompleteRenderItem
                isFreeCreate
                name={"Ability"}
                fullWidth
                label={i18n(`${i18nField}.Ability`)}
                options={[]}
                maxLength={1000}
              />
              <FormHelperText sx={{ color: "grey" }}>
                {i18n(`${i18nField}.MsgSkill`)}
              </FormHelperText>
            </Grid>
          </AccordionDetailsCustom>
        </AccordionCustom>

        <AccordionCustom
          sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
          expanded={expandedLangSkill}
          onChange={() => setExpandedLangSkill((prev) => !prev)}
        >
          <AccordionSummaryCustom>
            <Typography sx={{ width: "33%", flexShrink: 0 }}>
              {i18n(`${i18nField}.PanelLangPro`)}
            </Typography>
          </AccordionSummaryCustom>
          <AccordionDetailsCustom>
            {(languageSkillContext || []).map((item: ILanguageSkill, index) => {
              return (
                <LanguageSkill
                  item={item}
                  index={index}
                  key={index}
                  form={form}
                />
              );
            })}
            <Stack
              sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
              direction="row"
              justifyContent="center"
            >
              <BtnRadiusProfile
                txt={i18n(`${i18nField}.AddWorkDetail`)}
                onClick={handleAddLanguageSkill}
              />
            </Stack>
          </AccordionDetailsCustom>
        </AccordionCustom>

        <AccordionCustom
          sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
          expanded={expandedFile}
          onChange={() => setExpandedFile((prev) => !prev)}
        >
          <AccordionSummaryCustom>
            <Typography sx={{ width: "33%", flexShrink: 0 }}>
              {i18n(`${i18nField}.PanelMyFile`)}
            </Typography>
          </AccordionSummaryCustom>
          <AccordionDetailsCustom>
            <Grid sx={{ border: "10px", p: 1, flex: 1, m: "2%" }} item xs={12}>
              <UploadFile
                id="myFile"
                keyID={1}
                arrFile={fileUploadContext || []}
                setarrFile={setFileUpload}
                nLimitFile={2} //ขนาดไฟล์ไม่เกิน 2 Mb
                nLimitCountFile={5} //Upload ได้ 5 file
                IsFolder={false}
                IsFile={true}
                IsMultiple={true}
                Extension={["docx", "pptx", "doc", "ppt", "pdf"]}
                IsDrag={false}
                isSetupEmployer={false}
              />
            </Grid>
          </AccordionDetailsCustom>
        </AccordionCustom>

        <AccordionCustom
          sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
          expanded={expandedJobApply}
          onChange={() => setExpandedJobApply((prev) => !prev)}
        >
          <AccordionSummaryCustom>
            <Typography sx={{ width: "33%", flexShrink: 0 }}>
              {i18n(`${i18nField}.PanelJobApply`)}
            </Typography>
          </AccordionSummaryCustom>
          <AccordionDetailsCustom>
            {(jobPositionContext || []).map((item: IMyJobPosition, index) => {
              return (
                <MyJobPositon
                  item={item}
                  index={index}
                  key={index}
                  form={form}
                />
              );
            })}
            <Stack
              sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
              direction="row"
              justifyContent="center"
            >
              <BtnRadiusProfile
                txt={i18n(`${i18nField}.AddWorkDetail`)}
                onClick={handleAddJobAppiy}
              />
            </Stack>
          </AccordionDetailsCustom>
        </AccordionCustom>

        <Box component={Paper} sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormControl>
                <RadioGroup
                  value={isStartNow ? "now" : "late"}
                  onChange={(e, v) => {
                    setIsStartNow(v === "now");
                    if (v === "now") {
                      form.setValue("DayStart", null);
                      formSchema["DayStart"] = yupFormSchemas.integer(i18n(`${i18nField}.Day`), { required: false });
                      setFormSchema({ ...formSchema });
                    } else {
                      formSchema["DayStart"] = yupFormSchemas.integer(i18n(`${i18nField}.Day`), { required: true });
                      setFormSchema({ ...formSchema });
                    }
                  }}
                >
                  <FormControlLabel
                    value="now"
                    control={<Radio />}
                    label={i18n(`${i18nField}.StartNow`)}
                  />
                  <div style={{ display: "inherit" }}>
                    <FormControlLabel
                      style={{ width: "800px" }}
                      value="late"
                      control={<Radio />}
                      label={i18n(`${i18nField}.StartLate`)}
                    />
                    <InputNumber
                      name={"DayStart"}
                      required
                      label={i18n(`${i18nField}.Day`)}
                      // onBlur={(value) => {
                      //   handleChangeValue(form, item.Index);
                      // }}
                      disabled={isStartNow}
                      labelString={""}
                    />
                    {/* <Input
                      name={"DayStart"}
                      small
                      required
                      type="number"
                      sx={{ m: 1, width: "10ch" }}
                      disabled={isStartNow}
                      endAdornment={
                        <InputAdornment position="end">
                          {i18n(`${i18nField}.Day`)}
                        </InputAdornment>
                      }
                    /> */}
                  </div>
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <InputNumber
                name={"CurrentSalary"}
                small
                // type="number"
                label={
                  <Typography sx={{ fontWeight: 400 }} component="label">
                    {i18n(`${i18nField}.CurrentSalary`)}
                  </Typography>
                }
              />
            </Grid>
          </Grid>
        </Box>
        <Stack
          spacing={1}
          sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <BtnSubmit
            onClick={form.handleSubmit(onSubmit, (error) =>
              console.log("err", error)
            )}
            txt={i18n(`${i18nField}.BtnCreateProfile`)}
          />
        </Stack>
      </FormProvider>
    </>
  );
};
export default ProfileThird;
