import React, { useEffect, useState } from "react";
import {
  Grid,
  Stack,
  Typography,
  InputAdornment,
  IconButton,
  Button,
  FormGroup,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  Card,
} from "@mui/material";
import { i18n } from "i18n";

import { BtnSubmit } from "components/Common/Button";
import yupFormSchemas, {
  RegexpPassword,
} from "components/Common/yup/yupFormSchemas";
import {
  AxiosGet,
  AxiosPost,
  GetPermission,
  ResultAPI,
} from "service/CommonFunction/TS_function";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import Input from "components/Common/ElementInForm/InputCustomLabelFormItem";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import { useDispatch } from "react-redux";
import { DialogActionCreators } from "store/redux/DialogAlert";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { mt } from "date-fns/locale";
import secureLocalStorage from "react-secure-storage";

const Setting = () => {
  const i18nField = "SettingSeeker";
  const i18nField_Common = "common";

  const location = useLocation();
  const searchParams = location.search;
  const sID = new URLSearchParams(searchParams).get("sID");

  const history = useNavigate();
  const dispatch = useDispatch();

  const [IsChangePass, setIsChangePass] = useState(false);

  const [showPwdOld, setShowPwdOld] = useState(false);
  const [showPwdNew, setShowPwdNew] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);

  const [ObjData, setObjData] = useState<any>({});

  const objSchema = {
    sPass_Old: yupFormSchemas.string(i18n(`${i18nField}.PwdOld`), {
      required: IsChangePass,
    }),
    sUser_PW: yupFormSchemas.string(i18n(`${i18nField}.NewPwd`), {
      required: IsChangePass,
      matches: RegexpPassword(),
      labelmatches: i18n("validation.string.Password"),
    }),
    sConfirmPWD: yupFormSchemas
      .string(i18n(`${i18nField}.ConfirmPass`), {
        required: IsChangePass,
      })
      .oneOf([yup.ref("sUser_PW")], i18n(`${i18nField}.InvalidPass`)),
  };

  //Yup object
  const schema = yup.object().shape(objSchema);

  //schema to From
  const form = useForm({
    resolver: yupResolver(schema),
    shouldUnregister: false,
    shouldFocusError: true,
    mode: "all",
  });

  const onClearUserAccount = () => {
    if (ObjData.IsDeleteAccount) {
      secureLocalStorage.removeItem("isBack");
      secureLocalStorage.removeItem("sUserId");
      secureLocalStorage.removeItem("sUserFullNameEN");
      secureLocalStorage.removeItem("sUserFullNameTH");
      secureLocalStorage.removeItem("nGroupType");
      secureLocalStorage.removeItem("sUserNickName");
      secureLocalStorage.removeItem(process.env.REACT_APP_JWT_KEY as string);
      secureLocalStorage.removeItem("sFocusIndex");
      history("/");
      window.location.reload();
    }
  };

  useEffect(() => {
    OnGetData();
  }, []);

  const OnGetData = () => {
    AxiosGet(
      "SettingSeeker/GetDate_Jobseeker",
      {},
      (result) => {
        setObjData({ ...result });
      },
      (err) => {
        if (!err.response) {
          history("/error", { replace: true });
          return;
        }
      }
    );
  };

  const handleChangePass = (e) => {
    let isChecked = e.target.value === "1" ? true : false;
    setIsChangePass(isChecked);
    if (!isChecked) {
      form.clearErrors("sPass_Old");
      form.clearErrors("sUser_PW");
      form.clearErrors("sConfirmPWD");

      form.setValue("sPass_Old", "");
      form.setValue("sUser_PW", "");
      form.setValue("sConfirmPWD", "");
    }
  };

  const handleChangeDisclosure = (e) => {
    ObjData.IsDisclosure = e.target.value === "1" ? true : false;
    setObjData({ ...ObjData });
  };

  const handleChangeDeleteAccount = (e) => {
    ObjData.IsDeleteAccount = e.target.value === "1" ? false : true;
    setObjData({ ...ObjData });
  };

  const handleChangeGetNews = (e) => {
    ObjData.IsGetNews = e.target.value === "1" ? true : false;
    setObjData({ ...ObjData });
  };

  const handleChangeConsent = (e) => {
    ObjData.IsUnderage = e.target.value === "1" ? true : false;
    setObjData({ ...ObjData });
  };

  //Submit data
  const onSubmit = (e) => {
    dispatch(
      DialogActionCreators.OpenDialogSubmit(
        i18n(`${i18nField_Common}.AlertConfrimSave`),
        () => {
          dispatch(DialogActionCreators.CloseDialogSubmit() as any);

          ObjData.IsChangePassword = IsChangePass;
          ObjData.sPW = e["sPass_Old"] || "";
          ObjData.sPW_New = e["sUser_PW"] || "";

          setObjData({ ...ObjData });

          AxiosPost("SettingSeeker/SaveData_FromSettingSeeker", ObjData, (result) => {
            ResultAPI(result, i18n(`${i18nField_Common}.msgAlertSaveSuccess`), () => {
              dispatch(DialogActionCreators.OpenDialogSuccess(i18n(`${i18nField_Common}.msgAlertSaveSuccess`), () => {
                onClearUserAccount()
                window.location.reload();
              }) as any);
            });

            // ResultAPI(result, i18n(`${i18nField_Common}.msgAlertSaveSuccess`), () => {
            //   dispatch(DialogActionCreators.OpenDialogSuccess(i18n(`${i18nField_Common}.msgAlertSaveSuccess`), () =>
            //     onClearUserAccount()
            //   ) as any);
            //   setIsChangePass(false);
            //   form.setValue("sPass_Old", "");
            //   form.setValue("sUser_PW", "");
            //   form.setValue("sConfirmPWD", "");
            // });
          },
            (err) => {
              if (err.Message != "") {
                if (err.Status == 409) {
                  dispatch(
                    DialogActionCreators.OpenDialogWarning(
                      i18n(`${i18nField}.msgPaswwordDup`)
                    ) as any
                  );
                }
              }
            }
          );
        }
      ) as any
    );
  };

  return (
    <FormProvider {...form}>
      <Card
        sx={{
          padding: "2em",
          borderRadius: "1em",
          position: "relative",
          // background: "white"
        }}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
        // className="fade-bg-color"
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >

            <Grid item md={8} >
              <Typography sx={{ fontWeight: 900 }}>
                {i18n(`${i18nField}.Tile`)}

              </Typography>
            </Grid>

            <Grid item md={4} >
              <Typography variant="subtitle1">
                {i18n(`${i18nField}.UpdateOn`)} : {ObjData.sUpdate_Date}{" "}
                {i18n(`${i18nField}.Time`)} : {ObjData.sUpdate_Time}

              </Typography>
            </Grid>
            <Grid item md={12} xs={12}>
              <Divider />
            </Grid>
          </Grid>

          <Grid item container xs={12}>
            <Grid item md={12} xs={12}>
              <Grid container spacing={2}>
                <Grid item md={12} xs={12}>
                  <FormControl>
                    <FormLabel id="DataDisclosure"></FormLabel>
                    <RadioGroup
                      aria-labelledby="Data"
                      value={ObjData.IsDisclosure === true ? "1" : "2"}
                      name="radio-buttons-group"
                      onChange={handleChangeDisclosure}
                    >
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label={i18n(`${i18nField}.Disclosure`)}
                      />
                      <Typography >
                        {i18n(`${i18nField}.DisclosureDetail`)}
                      </Typography>
                      <FormControlLabel
                        value="2"
                        control={<Radio />}
                        label={i18n(`${i18nField}.NonDisclosure`)}
                      />
                      <Typography>
                        {i18n(`${i18nField}.NonDisclosureDetail`)}
                      </Typography>
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid item md={12} xs={12} sx={{ marginTop: "2%" }}>
              <Typography sx={{ fontWeight: 900 }}>
                {i18n(`${i18nField}.TileChangePass`)}
                <Divider />
              </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <FormControl>
                    <FormLabel id="sDataChangePass"></FormLabel>
                    <RadioGroup
                      aria-labelledby="Data"
                      value={IsChangePass === true ? "1" : "2"}
                      name="radio-buttons-group"
                      onChange={handleChangePass}
                    >
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label={i18n(`${i18nField}.ChangePass`)}
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio />}
                        label={i18n(`${i18nField}.NonChangePass`)}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {IsChangePass && (
            <Grid
              item
              container
              md={12}
              xs={12}
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              sx={{
                marginTop: "1%",
              }}
            >
              <Grid item md={12} xs={12}>
                <Input
                  name={"sPass_Old"}
                  labelString={i18n(`${i18nField}.PwdOld`)}
                  required={IsChangePass}
                  fullWidth
                  type={showPwdOld ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                        onClick={() => setShowPwdOld(!showPwdOld)}
                      >
                        {showPwdOld ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label={
                    <>
                      <Typography sx={{ fontWeight: 400 }} component="label">
                        {i18n(`${i18nField}.PwdOld`)}
                      </Typography>
                    </>
                  }
                />
              </Grid>

              <Grid item md={12} xs={12}>
                <Input
                  name={"sUser_PW"}
                  labelString={i18n(`${i18nField}.NewPwd`)}
                  required={IsChangePass}
                  fullWidth
                  type={showPwdNew ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                        onClick={() => setShowPwdNew(!showPwdNew)}
                      >
                        {showPwdNew ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label={
                    <>
                      <Typography sx={{ fontWeight: 400 }} component="label">
                        {i18n(`${i18nField}.NewPwd`)}
                      </Typography>
                    </>
                  }
                />
              </Grid>

              <Grid item md={12} xs={12}>
                <Input
                  name={"sConfirmPWD"}
                  labelString={i18n(`${i18nField}.ConfirmPass`)}
                  required={IsChangePass}
                  fullWidth
                  type={showCPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle p0assword visibility"
                        edge="end"
                        onClick={() => setShowCPassword(!showCPassword)}
                      >
                        {showCPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label={
                    <>
                      <Typography sx={{ fontWeight: 400 }} component="label">
                        {i18n(`${i18nField}.ConfirmPass`)}
                      </Typography>
                    </>
                  }
                />
              </Grid>
            </Grid>
          )}

          <Grid item container xs={12}>
            <Grid item md={12} xs={12} sx={{ marginTop: "2%" }}>
              <Typography sx={{ fontWeight: 900 }} >
                {i18n(`${i18nField}.TitleDeleteAcc`)}
                <Divider />
              </Typography>
            </Grid>

            <Grid item md={12} xs={12}>
              <Grid container spacing={2}>
                <Grid item md={12} xs={12}>
                  <FormControl>
                    <FormLabel id="sDataAcc"></FormLabel>
                    <RadioGroup
                      aria-labelledby="Data"
                      value={ObjData.IsDeleteAccount === true ? "2" : "1"}
                      name="radio-buttons-group"
                      onChange={handleChangeDeleteAccount}
                    >
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label={i18n(`${i18nField}.UsingAccount`)}
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio />}
                        label={i18n(`${i18nField}.DeleteAccount`)}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item container xs={12}>
            <Grid item md={12} xs={12} sx={{ marginTop: "2%" }}>
              <Typography sx={{ fontWeight: 900 }}>
                {i18n(`${i18nField}.TitleChooseInformation`)}
                <Divider />
              </Typography>
            </Grid>

            <Grid item md={6} xs={12}>
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <FormControl>
                    <FormLabel id="sDataNew"></FormLabel>
                    <RadioGroup
                      aria-labelledby="Data"
                      value={ObjData.IsGetNews === true ? "1" : "2"}
                      name="radio-buttons-group"
                      onChange={handleChangeGetNews}
                    >
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label={i18n(`${i18nField}.ChooseInformation`)}
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio />}
                        label={i18n(`${i18nField}.NonChooseInformation`)}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item container xs={12}>
            <Grid item md={12} xs={12} sx={{ marginTop: "2%" }}>
              <Typography sx={{ fontWeight: 900 }}>
                {i18n(`${i18nField}.TitleConsent`)}
                <Divider />
              </Typography>
            </Grid>
            <Grid item md={12} xs={12}>
              <FormControl>
                <RadioGroup
                  aria-labelledby="Data"
                  value={ObjData.IsUnderage === true ? "1" : "2"}
                  name="radio-buttons-group"
                  onChange={handleChangeConsent}
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label={i18n(`${i18nField}.Underage`)}
                  />

                  <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label={i18n(`${i18nField}.NotUnderage`)}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>

          <Grid item container xs={12}>
            <Grid item md={12} xs={12} sx={{ mt: "10px" }}>
              <BtnSubmit
                txt={i18n(`${i18nField_Common}.save`)}
                onClick={form.handleSubmit((e) => {
                  onSubmit(e);
                })}
              />
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </FormProvider>
  );
};
export default Setting;
