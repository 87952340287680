import React, { useCallback, useEffect, useRef, useState } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
// import Image from 'material-ui-image'
import { DialogContent, Grid, Box } from "@mui/material";
import Slider from "@mui/material/Slider";
import { withStyles } from "@mui/styles";
// import { getOrientation } from 'get-orientation/browser'
import { Cropper, ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import { BtnDownload } from "../../../Button";
import { Extension } from "service/CommonFunction/TS_function";
import { PDFViewer } from "react-view-pdf";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: "relative",
      backgroundImage:
        "linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(25,29,30,1) 51%)",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    body: {
      backgroundColor: "#191d1e",
    },
    cropContainer: {
      position: "relative",
      width: "100%",
    },
    cropButton: {
      flexShrink: 0,
      marginLeft: 16,
    },
    controls: {
      padding: 16,
      display: "flex",
      width: "50%",
      flexDirection: "column",
      alignItems: "stretch",
      [theme.breakpoints.up("sm")]: {
        flexDirection: "row",
        alignItems: "center",
      },
    },
    sliderContainer: {
      display: "flex",
      flex: "1",
      alignItems: "center",
    },
    sliderLabel: {
      color: "white",
      [theme.breakpoints.down("xs")]: {
        minWidth: 65,
      },
    },
    slider: {
      padding: "22px 0px",
      marginLeft: 16,
      [theme.breakpoints.up("sm")]: {
        flexDirection: "row",
        alignItems: "center",
        margin: "0 16px",
      },
    },
    HeadLable: {
      fontSize: 15,
      color: "#868686",
    },
    SubLable: {
      fontSize: 13,
      color: "#E3E3E3",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  })
);
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  // return <Slide direction="up" in={true}  ref={ref} {...props} mountOnEnter unmountOnExit />;
  return null;
});
const set = {
  height: 617.3896668208098,
  rotate: 38,
  scaleX: 1,
  scaleY: 1,
  width: 615.549487416803,
  x: 723.9696038675858,
  y: 607.9067623229733,
};
export default function FilePopup(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [image, setImage] = React.useState<any>(
    // process.env.REACT_APP_API_URL + props.file.sUrl
    props.file.sFileLink
  );
  const [cropper, setCropper] = useState<Cropper>();
  const [rotation, setRotation] = useState<any>(0);
  const [zoom, setZoom] = useState<any>(0);
  const [autoCrop, setautoCrop] = useState<any>(true);
  const cropperRef = useRef<HTMLImageElement>(null);
  //const [SourceCrop, SetSourceCrop] = useState<any>( || "")
  const [DataImg, SetDataImg] = useState<any>();
  useEffect(() => { }, [props]);

  useEffect(() => {
    setImageDetail();
  }, [cropper]);

  useEffect(() => {
    setOpen(props.open);
  }, [props.file]);

  useEffect(() => {
    if (typeof cropper !== "undefined") {
      cropper.zoomTo(zoom);
    }
  }, [zoom]);

  useEffect(() => {
    if (typeof cropper !== "undefined") {
      cropper.rotateTo(rotation);
    }
  }, [rotation]);

  const setImageDetail = () => {
    if (typeof cropper !== "undefined") {
      cropper.setData(props.SourceCrop || "");
    }
  };

  const GetDatail = () => {
    if (typeof cropper !== "undefined") {
      let Result = {
        sUrl: cropper.getCroppedCanvas().toDataURL(),
        SourceCrop: cropper.getData(),
      };
      props.Result(Result);
    }
  };

  const resetCropData = () => {
    if (typeof cropper !== "undefined") {
      cropper.reset();
      cropper.rotateTo(0);
    }
  };

  const onCrop = () => {
    const imageElement: any = cropperRef.current;
    const cropper: any = imageElement.cropper;
    setRotation(cropper.imageData.rotate || 0);
    SetDataImg(cropper);
  };

  const Download = () => {
    if (typeof cropper !== "undefined") {
      var a = document.createElement("a"); //Create <a>
      a.href = cropper.getCroppedCanvas().toDataURL() + ""; //Image Base64 Goes here
      a.download = "Image.png"; //File name Here
      a.click(); //Downloaded file
    }
  };

  const DownloadOriginal = () => {
    var a = document.createElement("a"); //Create <a>
    a.href = image; //Image Goes here
    a.download = props.file.sFileName; //File name Here
    a.click(); //Downloaded file
  };
  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        className={classes.body}
        onClose={props.Fn}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Grid container spacing={2} justifyContent="space-between">
              <Grid
                container
                spacing={4}
                lg={8}
                md={8}
                justifyContent="flex-start"
                style={{ paddingTop: "10px" }}
              >
                <Grid item lg={4} className={classes.HeadLable}>
                  Name:{" "}
                  <div className={classes.SubLable}>{props.file.sFileName}</div>
                </Grid>
                <Grid item lg={2} className={classes.HeadLable}>
                  Type:{" "}
                  <div className={classes.SubLable}>{props.file.sFileType}</div>
                </Grid>
                {/* {Extension.Image.indexOf(props.file.sFileType) > -1 && (
                  <Grid item lg={2} className={classes.HeadLable}>
                    Dimensions:{" "}
                    <div className={classes.SubLable}>
                      {DataImg ? DataImg.imageData.height.toFixed(0) : ""} x{" "}
                      {DataImg ? DataImg.imageData.width.toFixed(0) : ""}
                    </div>
                  </Grid>
                )} */}
              </Grid>
              <Grid container lg={4} md={4} justifyContent="flex-end">
                <BtnDownload
                  txt="Download"
                  onClick={(c) => {
                    DownloadOriginal();
                  }}
                />
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={props.Fn}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <DialogContent className={classes.body} dividers>
          <div>
            <React.Fragment>
              <div className={classes.cropContainer}>
                <div style={{ textAlign: "center" }}>
                  {/* Image */}
                  {Extension.Image.indexOf(props.file.sFileType) > -1 && (
                    <Box sx={{ height: "90vh", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                        <img height="90vh" src={image} alt="" />
                      </Box>
                    </Box>
                  )}

                  {/* Video */}
                  {Extension.Video.indexOf(props.file.sFileType) > -1 && (
                    <Box sx={{ height: "90vh", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                        <video height="90vh" autoPlay controls>
                          <source src={image} />
                        </video>
                      </Box>
                    </Box>
                  )}
                  {/* PDF */}
                  {Extension.PDF.indexOf(props.file.sFileType) > -1 && (
                    <div
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <PDFViewer url={image} />
                    </div>
                  )}
                </div>
              </div>
            </React.Fragment>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
