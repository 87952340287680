const JobPostEn = {
  ListPage: {
    Header: "Employer > Job announcement",
    dataColumn: {
      No: "No",
      Position: "Position",
      Candidate: "Candidate",
      StartDate: "Start Date",
      EndDate: "End Date",
      Status: "Status",
      View: "View",
      ButtonAdd: "Button (Add/Edit)",
      Edit: "Edit"
    },
    button: {
      btnNewPost: "Post new job",
    },
    filter: {
      search: "Key search",
      status: "Status",
      Draft: "Draft",
      Active: "Active",
      Scheduled: "Scheduled",
      Expire: "Expire",
      Deactivate: "Deactivate",
      date: "Start date - End date",
    },
  },
  ModifyPage: {
    Header: "Post new job",
    JobDesc: "Job Description/Qualification",
    JobDescHelptxt: "Please enter job description/qualification",
    form: {
      label: {
        otherInfomation: "Other Information",
        package: "Package",
        jobTitleEn: "Job Title (EN)",
        jobTitleTh: "Job Title (TH)",
        appManagement: "Application Management (Receive Application E-mail)",
        emailReceiver: "E-mail Receiver",
        sumForJob: "Conclude key point or key word of job ad for job seekers",
        pointForJobDetail: "Job Keyword",
        industry: "Select Industry",
        postDate: "Post Date",
        expireDate: "Expire Date",
        workLocation: "Select Work Location",
        workTraveiling: "Select Travelling",
        employmentType: "Employment Type",
        salaryInfo: "Salary Info",
        salaryType: "Select salary type",
        salaryStart: "Salary Start",
        salaryEnd: "Salary End",
        timeWork: "Time Work",
        timeWorkMF: "Mon - Fri",
        timeWorkMS: "Mon - Sat",
        timeWorkShift: "Work in shifts",
        timeWorkShiftOther: "Othes",
        timeWorkOther: "Others",
        timeWorkOtherTxtBox: "Others",
        benefits: "Benefits",
        careerLevel: "Select Career Level",
        minimumEducation: "Minimum Education Level",
        minimumYearExp: "Minimum Year of experience",
        minumumYearExpStart: "Start",
        minumumYearExpEnd: "End",
        tag: "Select Tag",
        helpTag: "Identify tags to make it easier for job seekers to find your job. Please type the tag you want to use, then press Enter",
        jobSeekerAllow: "Job Seeker allowed to work in",
        country: "Country",
        selectRegister: "Require applicants to fill out an application on the entrepreneur's website",
        url: "URL",
        jobDescription: "Job Description",
        jobFunction: "Job Function",
        jobDetail: "Job Function Detail",
        yearExp: "Start year of experience cannot greater than end year of experience",
        OtherBenefits: "Others",
        SalaryValid: "Start salary cannot greater than end salary",
        checkYear: "The announcement date has passed. Please select date again.",
        minYearStart: "less than 1 year",
        maxYearEnd: "More than 20 years",
        DateLowerThanToday: "Unable to select post date less than current date."
      },
    },
    validate: {
      package: "Package",
      jobTitle: "Job Title (EN)",
      industry: "Industry",
      postDate: "Post Date",
      workLocation: "Work Location",
      workTraveiling: "Work Traveiling",
      salaryType: "Salary Type",
      salaryStart: "Salary Start",
      salaryEnd: "Salary End",
      careerLevel: "Career Level",
      minimumEducation: "Minimum Education",
      minumumYearExpStart: "Year Start",
      minumumYearExpEnd: "Year End",
      employmentType: "Employment Type is required",
      Benefit: "Benefits is required",
      Country: "Country",
      Region: "Region/Bangkok",
      Province: "Province/District",
      PostCode: "PostCode",
      Street: "Street",
      Moo: "Moo",
      AddressNo: "Address No.",
      Frequency: "Frequency (Boost Post)",
      minYearStart: "less than 1 year",
      maxYearEnd: "More than 20 years",
      notcoin: "Package job post is not enough. Please select a new package.",
      PackageExpire: " Package has been expired. Please select a new package.",
      PackagePause: "Package has been temporarily suspended. Please select a new package.",
      PackageHold: "Package has been holded. Please select a new package."
    },
    button: {
      addParagraph: "Add Paragraph",
      addMailReceiver: "Add",
      addSumPointForJob: "Add",
      addJobFunction: "Add",
      draft: "Save Draft",
      Back: "Back",
      publish: "Publish",
      Deactivate: "Deactivate",
      BoostPost: "Boost Post"
    },
  },
};

const JobPostTh = {
  ListPage: {
    Header: "ผู้ประกอบการ > ประกาศงาน",
    dataColumn: {
      No: "เลขที่ประกาศ",
      Position: "ตำแหน่ง",
      Candidate: "ผู้สมัคร",
      StartDate: "วันที่เริ่มต้น",
      EndDate: "วันที่สิ้นสุด",
      Status: "สถานะ",
      View: "ดูรายละเอียด",
      ButtonAdd: "ปุ่ม (เพิ่ม/แก้ไข)",
      Edit: "แก้ไข"

    },
    button: {
      btnNewPost: "ประกาศตำแหน่งงานใหม่",
    },
    filter: {
      search: "ค้นหา",
      status: "สถานะ",
      Draft: "แบบร่าง",
      Active: "กำลังประกาศ",
      Scheduled: "รอประกาศ",
      Expire: "หมดอายุ",
      Deactivate: "ลบประกาศ",
      date: "วันที่เริ่ม - วันที่สิ้นสุด",
    },
  },
  ModifyPage: {
    Header: "ตำแหน่งงานใหม่",
    JobDesc: "รายละเอียดงาน/คุณสมบัติ",
    JobDescHelptxt: "กรุณาใส่รายละเอียดงาน/คุณสมบัติ",
    form: {
      label: {
        otherInfomation: "ข้อมูลอื่นๆ",
        package: "แพ็กเกจ",
        jobTitleEn: "ชื่องาน (ภาษาอังกฤษ)",
        jobTitleTh: "ชื่องาน (ภาษาไทย)",
        appManagement: "อีเมลที่รับเรื่อง",
        emailReceiver: "ผู้รับอีเมล",
        sumForJob: "ลักษณะเด่นของงานที่ประกาศ",
        pointForJobDetail: "ลักษณะเด่นของงาน",
        industry: "เลือกประเภทอุตสาหกรรม",
        postDate: "วันที่ประกาศ",
        expireDate: "วันที่สิ้นสุด",
        workLocation: "พื้นที่ปฏิบัติงาน",
        workTraveiling: "เลือกการเดินทาง",
        employmentType: "ลักษณะการจ้าง",
        salaryInfo: "เงินเดือน",
        salaryType: "เลือกประเภทเงินเดือน",
        salaryStart: "เงินเดือนเริ่มต้น",
        salaryEnd: "เงินเดือนสิ้นสุด",
        timeWork: "เวลางาน",
        timeWorkMF: "จันทร์ - ศุกร์",
        timeWorkMS: "จันทร์ - เสาร์",
        timeWorkShift: "ทำงานเป็นกะ",
        timeWorkShiftOther: "อื่นๆ",
        timeWorkOther: "อื่นๆ",
        timeWorkOtherTxtBox: "อื่นๆ",
        benefits: "สวัสดิการ",
        careerLevel: "เลือกระดับตำแหน่งงาน",
        minimumEducation: "ระดับการศึกษาขั้นต่ำ",
        minimumYearExp: "ประสบการณ์ขั้นต่ำ(ปี)",
        minumumYearExpStart: "เริ่ม",
        minumumYearExpEnd: "สิ้นสุด",
        tag: "เลือกแท็ก",
        helpTag: "ระบุแท็กเพื่อให้ Job Seeker ค้นหางานของคุณได้ง่ายขึ้น โดยพิมพ์แท็กที่ต้องการใช้ จากนั้นกด Enter",
        jobSeekerAllow: "ผู้หางานได้รับอนุญาตให้ทำงานใน",
        country: "ประเทศ",
        selectRegister: "ต้องการให้ผู้สมัครกรอกใบสมัครในเว็บของผู้ประกอบการ",
        url: "URL",
        jobDescription: "รายละเอียดงาน",
        jobFunction: "กลุ่มงาน",
        jobDetail: "รายละเอียดงาน",
        yearExp: "ประสบการณ์ทำงานเริ่มต้นไม่สามารถมากกว่าประสบการณ์ทำงานสิ้นสุดได้",
        OtherBenefits: "อื่นๆ",
        SalaryValid: "เงินเดือนเริ่มต้นไม่สามารถมากกว่าเงินเดือนสิ้นสุดได้",
        checkYear: "วันที่ประกาศผ่านไปแล้ว กรุณาเลือกวันอีกครั้ง",
        minYearStart: "น้อยกว่า 1 ปี",
        maxYearEnd: "มากกว่า 20 ปี",
        DateLowerThanToday: "ไม่สามารถเลือกวันที่ประกาศงานน้อยกว่าวันที่ปัจจุบันได้"
      },
    },
    validate: {
      package: "แพ็กเกจ",
      jobTitleEn: "ชื่องาน (ภาษาอังกฤษ)",
      jobTitleTh: "ชื่องาน (ภาษาไทย)",
      industry: "ประเภทอุตสาหกรรม",
      postDate: "วันที่ประกาศ",
      workLocation: "สถานที่ทำงาน",
      workTraveiling: "การเดินทาง",
      salaryType: "ประเภทเงินเดือน",
      salaryStart: "เงินเดือนเริ่มต้น",
      salaryEnd: "เงินเดือนสิ้นสุด",
      careerLevel: "ระดับตำแหน่งงาน",
      minimumEducation: "ระดับการศึกษาขั้นต่ำ",
      minumumYearExpStart: "ปีที่เริ่ม",
      minumumYearExpEnd: "ปีที่สิส้นสุด",
      employmentType: "กรุณาระบุข้อมูล ลักษณะการจ้างงาน",
      Benefit: "กรุณาระบุข้อมูล สวัสดิการ",
      Country: "ประเทศ",
      Region: "ภูมิภาค/กรุงเทพมหานคร",
      Province: "จังหวัด/เขต",
      PostCode: "รหัสไปรษณีย์",
      Street: "ถนน",
      Moo: "หมู่",
      AddressNo: "เลขที่",
      Frequency: "กำหนดระยะเวลา Boost Post",
      minYearStart: "น้อยกว่า 1 ปี",
      maxYearEnd: "มากกว่า 20 ปี",
      notcoin: "จำนวนประกาศงานคงเหลือไม่เพียงพอ กรุณาเลือกแพ็กเกจใหม่",
      PackageExpire: "แพ็กเกจหมดอายุ กรุณาเลือกแพ็กเกจใหม่",
      PackagePause: "แพ็กเกจถูกระงับการใช้งานชั่วคราว กรุณาเลือกแพ็กเกจใหม่",
      PackageHold: "แพ็กเกจถูกชะลอการใช้งานชั่วคราว กรุณาเลือกแพ็กเกจใหม่"
    },
    button: {
      addParagraph: "เพิ่มเนื้อหา",
      addMailReceiver: "เพิ่ม",
      addSumPointForJob: "เพิ่ม",
      addJobFunction: "เพิ่ม",
      draft: "บันทึกร่าง",
      Back: "ย้อนกลับ",
      publish: "เผยแพร่",
      Deactivate: "ลบประกาศ",
      BoostPost: "Boost Post"
    },
  },
};

export { JobPostTh, JobPostEn };
