import { useEffect, useState } from 'react';
import {
    Box, Grid, Stack, Typography, InputAdornment,
    IconButton,
    FormControlLabel,
    FormGroup,
    Checkbox,
    Radio,
    Button,
} from '@mui/material';
import { AxiosGet, AxiosPost, GetPermission, ResultAPI } from "service/CommonFunction/TS_function";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from 'react-router-dom';
import yupFormSchemas, { RegexpPassword } from 'components/Common/yup/yupFormSchemas';
import * as yup from "yup";
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { BtnBack, BtnCustomIcon, BtnSave, styleX } from '../Common/Button';
import { FnBlock_UI } from 'service/SystemFunction/SystemFunction';
import { DialogActionCreators } from 'store/redux/DialogAlert';
import { RestartAlt, Visibility, VisibilityOff } from '@mui/icons-material';
import IOSSwitch from 'components/Common/ElementInForm/IosSwitch';
import { i18n } from 'i18n';
import { GridColumns } from '@mui/x-data-grid';
import {
    DataGridMui as Table,
    initRows,
    PaginationInterface
} from "components/Common/Table/DataGridMui";
import MultiSelect from "components/Common/ElementInForm/MultiSelectWithChip"
import AutoCompleteSelect from "components/Common/ElementInForm/AutoCompleteSelect";
import Input from "components/Common/ElementInForm/InputCustomLabelFormItem";

//ประกาศตัวแปร
const CustomerUser = () => {
    const i18nField = 'entities.UserGroup';
    const i18nCommon = 'common';
    const dispatch = useDispatch();
    const history = useNavigate();
    const { BlockUI, UnBlockUI } = FnBlock_UI();
    const [arrGroup, setarrGroup] = useState([]);
    const [showPassword, setShowPassword] = useState(false);
    const [showCPassword, setShowCPassword] = useState(false);
    const [isActive, setIsActive] = useState(true);
    const onBack = () => { history("/AdminUserTable") }
    const location = useLocation();
    const searchParams = location.search;
    const sID = new URLSearchParams(searchParams).get('sID');
    const isEditMode = sID ? true : false;
    const [lstCheck, setLstCheck] = useState([]);
    const [arrCompany, setarrCompany] = useState([]);
    const [nPermission, setPermission] = useState(0);
    const [selectedValue, setSelectedValue] = useState({});
    const [dataRow, setDataRow] = useState<PaginationInterface>({
        ...initRows,

    });
    const [IsCheckall, setIsCheckall] = useState(false);
    // const [lstRadioGroup, setlstRadioGroup] = useState([]);
    // const [isFormUser, setIsFormUser] = useState<boolean>(true);
    // const [isLoadingSentForgot, setIsLoadingSendForgot] = useState<boolean>(false);
    // const [isOpenForgot, setOpenForgot] = useState<boolean>(false)
    const objSchema = {
        sGroup_ID: yupFormSchemas.object("กลุ่ม", { required: true, isAdmin: true }),
        sUser_Name: yupFormSchemas.string("ชื่อ - นามสกุล", { required: true, isAdmin: true }),
        sUser_UserName: yupFormSchemas.string("ชื่อผู้ใช้", { required: true, isAdmin: true }),
        sUser_Email: yupFormSchemas.email("อีเมล", { required: true, isAdmin: true }),
        sUser_Tel: yupFormSchemas.string("เบอร์โทรติดต่อ", { required: true, isAdmin: true }),
        arrEmployer: yupFormSchemas.stringArray("ผู้ว่าจ้าง", { required: false, isAdmin: true }),
    }

    const schema = yup.object().shape(objSchema);

    const form = useForm({
        resolver: yupResolver(schema),
        shouldUnregister: false,
        shouldFocusError: true,
        mode: "all"
    });

    //Checkbox เช็ค
    const handleChangeCheckbox = (e) => {
        let oUPD = lstCheck.filter((f) => f.nData_ID == e.target.value)[0];

        if (oUPD) {
            oUPD.ischeck = e.target.checked
        }
        let oCompany = lstCheck.filter((f) => f.ischeck).map(value => value.nData_ID);
        let nEmployer = form.getValues("arrEmployer")
        let CheckCompany = arrCompany.filter(f => nEmployer.includes(f.value))
        let filter = CheckCompany.filter(f => !oCompany.includes(f.nIndustryType_ID)).map(value => value);
        form.setValue("arrEmployer", filter);

        let checkall = lstCheck.filter((f) => !f.ischeck)
        setLstCheck([...lstCheck])
        setIsCheckall(checkall.length == 0)
    };

    //Checkbox เช็คทั้งหมด
    const handleChangeCheckboxAll = (e) => {
        // lstCheck.map((item) => {
        //     item.ischeck = e.target.checked
        // })

        lstCheck.forEach(item => {
            item.ischeck = e.target.checked
        });

        form.setValue("arrEmployer", []);
        setLstCheck([...lstCheck])
        setIsCheckall(e.target.checked);
    };

    //Radio
    const handleChange = (e, item) => {
        selectedValue[item.sID] = e.target.value;
        setSelectedValue({ ...selectedValue });

        let tempList = dataRow;
        setDataRow({
            ...tempList,
        });
    };

    //#endregion
    useEffect(() => {
        GetPermission(10, setPermission)
        OnGetData()
        onGetDetail(dataRow)
    }, []);

    const OnGetData = () => {
        BlockUI();
        AxiosGet("UserPermission/GetData_DropDown", {}, (result) => {
            UnBlockUI();
            setarrGroup(result.lstOption || []);
            setLstCheck(result.lstCheck || []);
            setarrCompany(result.lstCompany || []);
        },
        );
    }

    const OnGetDataGroup = () => {
        let nGroup_ID = (form.getValues("sGroup_ID").value) + ""
        AxiosPost("UserPermission/Getdata_Group", { sID: nGroup_ID }, (result) => {

            let objPermission = {};
            result.lstPermissionGroup.forEach(element => {
                objPermission[element.sID] = element.nPRMS
            });
            setSelectedValue(objPermission);

            let oCompany = result.lstindustryGroup.filter((f) => f.ischeck).map(value => value.nData_ID);
            let nEmployer = form.getValues("arrEmployer")
            let CheckCompany = arrCompany.filter(f => nEmployer.includes(f.value))
            let filter = CheckCompany.filter(f => !oCompany.includes(f.nIndustryType_ID)).map(value => value);
            form.setValue("arrEmployer", filter);
            setLstCheck(result.lstindustryGroup || []);

            let check = lstCheck.filter((f) => f.ischeck)
            if (check.length > 0) {
                let checkall = lstCheck.filter((f) => f.ischeck)
                setIsCheckall(checkall.length == 0)
            }
        },
        );
    }

    const onGetDetail = (p: PaginationInterface) => {
        AxiosPost("UserPermission/EditData_FormUser", { sID: sID }, (res) => {
            if (isEditMode) {
                form.setValue("sGroup_ID", res.objSave.lstGroupper);
                form.setValue("sUser_Name", res.objSave.sUser_Name);
                form.setValue("sUser_UserName", res.objSave.sUser_UserName);
                form.setValue("sUser_PW", res.objSave.sUser_PW);
                form.setValue("sConfirmPWD", res.objSave.sUser_PW);
                form.setValue("sUser_Email", res.objSave.sUser_Email);
                form.setValue("sUser_Tel", res.objSave.sUser_Tel);
                setIsActive(res.objSave.isActive || false);
                form.setValue("arrEmployer", res.objSave.lstOnsaveEmployer);
                let objPermission = {};
                res.lstPermission.forEach(element => {
                    objPermission[element.sID] = element.nPRMS
                });
                setSelectedValue(objPermission);
                setLstCheck(res.lstindustry || []);
            }
            setDataRow({
                ...p,
                arrRows: res.lstPermission,
                nDataLength: res.nDataLength,
                nPageIndex: res.nPageIndex,
            });
        },
        );
    };

    //saveData
    const onSubmit = (e) => {
        let arrValueCheck = [];
        let objnData = lstCheck.filter((f) => f.ischeck)
        let arrIndustry = objnData.map(value => value.nData_ID);
        for (const [key, value] of Object.entries(arrIndustry)) {
            let objData = {
                sID: key,
                nData_ID: value
            }
            arrValueCheck.push(objData)
        }

        let arrValue = [];
        for (const [key, value] of Object.entries(selectedValue)) {
            let objPRMS = {
                sID: key,
                nPRMS: value
            }
            arrValue.push(objPRMS)
        }

        let arrPRMS = arrValue.filter(f => f.nPRMS != 0)
        if (arrPRMS.length == 0) {
            dispatch(DialogActionCreators.OpenDialogWarning("กรุณาระบุสิทธิ์การเข้าถึงอย่างน้อยหนึ่งเมนู"));
            return;
        }

        dispatch(DialogActionCreators.OpenDialogSubmit("ต้องการบันทึกข้อมูลหรือไม่ ?", () => {
            dispatch(DialogActionCreators.CloseDialogSubmit() as any)

            //บันทึก
            let objSave = {
                sID: isEditMode ? sID : "",
                nGroup_ID: +(form.getValues("sGroup_ID").value),
                sUser_Name: form.getValues("sUser_Name"),
                sUser_UserName: form.getValues("sUser_UserName"),
                sUser_PW: form.getValues("sUser_PW") || "",
                sUser_Email: form.getValues("sUser_Email"),
                sUser_Tel: form.getValues("sUser_Tel"),
                isActive: isActive,
                lstOnsavePRMS: arrValue,
                OnsaveIndustry: arrValueCheck,
                lstOnsaveEmployer: form.getValues("arrEmployer"),
            }

            AxiosPost("UserPermission/SaveData_FormUser", objSave, (result) => {
                ResultAPI(result, "บันทึกข้อมูลสำเร็จ", () => {
                    dispatch(DialogActionCreators.OpenDialogSuccess("บันทึกข้อมูลสำเร็จ",
                        () => onBack()
                    ) as any);
                });
            }, (err) => {
                if (err.Status === 409) {
                    dispatch(DialogActionCreators.OpenDialogWarning("อีเมลนี้ถูกใช้งานในระบบแล้ว", () => {
                    }) as any)
                }
                else if
                    (err.Status === 400) {
                    dispatch(DialogActionCreators.OpenDialogWarning("ผู้ใช้งานนี้ถูกใช้งานในระบบแล้ว", () => {
                    }) as any)
                }
            });
        }) as any);
    }
    const dataColumn: GridColumns = [
        {
            field: "nNo",
            headerName: "ลำดับ",
            headerAlign: "center",
            editable: false,
            width: 80,
            resizable: false,
            sortable: false,
            align: "center",
        },
        {
            field: "sMenuNameTH",
            headerName: "ชื่อเมนู",
            headerAlign: "center",
            align: "left",
            resizable: false,
            sortable: false,
            flex: 4,
            minWidth: 200,
            renderCell: (item) => item.value || "",
        },
        {
            field: "",
            headerName: "ไม่มีสิทธิ์เข้าถึง",
            headerAlign: "center",
            align: "center",
            resizable: false,
            sortable: true,
            minWidth: 200,
            renderCell: (item) => {
                return (
                    nPermission == 2 ? (
                        <Radio
                            checked={selectedValue[item.row.sID] == 0}
                            value={0}
                            onChange={(e) => handleChange(e, item.row)}
                            name={"Disabled-button" + item.row.sID}
                        />
                    ) : (
                        <Radio
                            disabled
                            checked={selectedValue[item.row.sID] == 0}
                            value={0}
                            onChange={(e) => handleChange(e, item.row)}
                            name={"Disabled-button" + item.row.sID}
                        />
                    )
                )
            },
        },
        {
            field: "isView",
            headerName: "อ่านเท่านั้น",
            headerAlign: "center",
            align: "center",
            resizable: false,
            sortable: false,
            minWidth: 200,
            renderCell: (item) => {
                return (
                    nPermission == 2 ? (
                        (item.row.isView ? (<Radio
                            checked={selectedValue[item.row.sID] == 1}
                            value={1}
                            onChange={(e) => handleChange(e, item.row)}
                            name={"ReadOnly-button" + item.row.sID}
                        />) : (<Radio
                            disabled={true}
                        />))
                    ) : (
                        <Radio
                            disabled
                            checked={selectedValue[item.row.sID] == 1}
                            value={1}
                            onChange={(e) => handleChange(e, item.row)}
                            name={"ReadOnly-button" + item.row.sID}
                        />
                    )
                )
            },
        },
        {
            field: "isEdit",
            headerName: "เพิ่ม/ลบ/แก้ไข",
            headerAlign: "center",
            align: "center",
            resizable: false,
            sortable: false,
            minWidth: 200,
            renderCell: (item) => {
                return (
                    nPermission == 2 ? (
                        (item.row.isEdit ? (<Radio
                            checked={selectedValue[item.row.sID] == 2}
                            value={2}
                            onChange={(e) => handleChange(e, item.row)}
                            name={"Enabled-button" + item.row.sID}
                        />) : (<Radio
                            disabled={true}
                        />))
                    ) : (
                        <Radio
                            disabled
                            checked={selectedValue[item.row.sID] == 2}
                            value={2}
                            onChange={(e) => handleChange(e, item.row)}
                            name={"Enabled-button" + item.row.sID}
                        />
                    )
                )
            },
        },
    ];

    return (
        <FormProvider {...form}>
            <Stack sx={{ border: "3px", p: 1, flex: 1, mx: "2%" }}>
                <Grid container >
                    <Grid container spacing={2} sx={{
                        border: "1px #eeeeee solid", backgroundColor: "rgba(255,255,255,0.75)",
                        borderRadius: "20px", p: 2,
                    }}
                    >
                        <Grid item md={6} xs={12}>
                            <AutoCompleteSelect
                                disabled={nPermission !== 2}
                                required
                                name={'sGroup_ID'}
                                label={"กลุ่ม"}
                                options={arrGroup}
                                onChange={(v) => {
                                    form.setValue('sGroup_ID', v ? v : null); OnGetDataGroup();
                                }}
                            />

                        </Grid>
                        <Grid item md={6} xs={12}></Grid>
                        <Grid item md={6} xs={12}>
                            <Input
                                name={"sUser_Name"}
                                labelString={"ชื่อ - นามสกุล"}
                                required
                                fullWidth
                                maxLength={300}
                                maxRow={1}
                                label={
                                    <Typography sx={{ fontWeight: 400 }} component="label">
                                        {"ชื่อ - นามสกุล"}
                                    </Typography>
                                }
                                disabled={nPermission !== 2}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}></Grid>
                        <Grid item md={6} xs={12}>
                            <Input
                                name={"sUser_UserName"}
                                labelString={"ชื่อผู้ใช้"}
                                required
                                fullWidth
                                maxLength={100}
                                maxRow={1}
                                label={
                                    <Typography sx={{ fontWeight: 400 }} component="label">
                                        {"ชื่อผู้ใช้"}
                                    </Typography>
                                }
                                disabled={nPermission !== 2}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}></Grid>
                        <Grid item md={6} xs={12}>
                            <Input
                                name={"sUser_Email"}
                                required
                                fullWidth
                                maxLength={100}
                                maxRow={1}
                                label={
                                    <Typography sx={{ fontWeight: 400 }} component="label">
                                        {"อีเมล"}
                                    </Typography>
                                }
                                disabled={nPermission !== 2}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}> </Grid>
                        <Grid item md={6} xs={12}>
                            <Input
                                name={"sUser_Tel"}
                                required
                                fullWidth
                                maxLength={20}
                                maxRow={1}
                                label={
                                    <Typography sx={{ fontWeight: 400 }} component="label">
                                        {"เบอร์โทรติดต่อ"}
                                    </Typography>
                                }
                                disabled={nPermission !== 2}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>  </Grid>
                        <Grid item xs={12}>
                            <Grid container sx={{ marginTop: "3%" }}>
                                <Grid item xs={12} md={4}>
                                    <Typography component="label" sx={{ fontWeight: 600 }}>
                                        {"เลือกรายชื่อประเภทอุตสาหกรรมที่รับผิดชอบ"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <FormControlLabel control={<Checkbox
                                        checked={IsCheckall}
                                        onChange={handleChangeCheckboxAll}
                                        disabled={nPermission !== 2}
                                    />
                                    }
                                        label={"เลือกทั้งหมด"} />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <Button variant="contained" disabled={nPermission !== 2} onClick={(e => { OnGetDataGroup() })} sx={{ borderRadius: "20px" }}>{"สิทธิตามกลุ่ม"}</Button>

                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <FormProvider {...form}>
                                <FormGroup>
                                    <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
                                        {lstCheck != null && lstCheck.length > 0 ? lstCheck.map((item, index) => (
                                            <Grid item xs={12} sm={4} md={4} key={index}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            disabled={nPermission !== 2}
                                                            checked={item.ischeck}
                                                            onChange={(e) => handleChangeCheckbox(e)}
                                                            value={item.nData_ID} />
                                                    }
                                                    label={item.sData_Name_TH} />
                                            </Grid>
                                        )) : null}
                                    </Grid>
                                </FormGroup>
                            </FormProvider >
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <MultiSelect
                                name={'arrEmployer'}
                                valueData={form.watch("arrEmployer") || []}
                                options={arrCompany.filter(f => !lstCheck.filter((f) => f.ischeck).map(m => m.nData_ID).includes(f.nIndustryType_ID))}
                                label={"ค้นหารายชื่อผู้ประกอบการที่รับผิดชอบ"}
                                notSetMargin={true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{ width: "100%" }}>
                                <Table
                                    Rows={dataRow}
                                    Columns={dataColumn}
                                    isNotShowTotal={true}
                                    isNotShowPagination={true}
                                    OnLoadData={(p) => onGetDetail(p)}

                                />
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>
                                <span style={{ fontWeight: 600 }}> {"สถานะ"} </span>
                                <span style={{ color: "red" }}> *</span>
                            </Typography>
                            <Typography>
                                <FormControlLabel
                                    control={
                                        <IOSSwitch
                                            disabled={nPermission !== 2}
                                            sx={{ m: 1 }}
                                            onChange={() => setIsActive(!isActive)}
                                            checked={isActive}
                                        />
                                    }
                                    label={isActive ? "ใช้งาน" : "ไม่ใช้งาน"}
                                />
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{ height: "20px" }}></div>
                    </Grid>
                    <Grid item container xs={12} >
                        <Grid item xs={6} justifyContent={"start"}>
                            <Box sx={{ flex: 1 }}>
                                <BtnBack txt={'ย้อนกลับ'} onClick={() => onBack()} />
                            </Box>
                        </Grid>
                        <Grid item container xs={6} justifyContent={"end"}>
                            <BtnSave txt={'บันทึก'} IsDisabled={nPermission !== 2} onClick={form.handleSubmit(e => { onSubmit(e) })} />
                        </Grid>
                    </Grid>
                </Grid>
            </Stack >
        </FormProvider >
    );
}
export default CustomerUser