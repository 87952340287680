import { useEffect, useState} from "react";
import {
    DataGridMui as Table,
    initRows,
    PaginationInterface,
    FilterFieldInterface
} from "components/Common/Table/DataGridMui";
import { GridColumns } from "@mui/x-data-grid";
import { AxiosPost } from "service/CommonFunction/TS_function";
import { useNavigate } from "react-router-dom";
import { BtnAddOnTable, BtnEditOnTable } from "components/Common/Button"

export default function CustomerTable() {
  // const dispatch = useDispatch();
  const history = useNavigate();
  const [loadingTable, setLoadingTable] = useState(true);
  // const [switchInTable, setSwitchInTable] = useState([]);
  // const [switchInTableIsShowContactUs, setSwitchInTableIsShowContactUs] = useState([]);
  const [dataRow, setDataRow] = useState<PaginationInterface>({
    ...initRows,
    sSortExpression: "sCreateDate",
    sSortDirection: "asc"
  });
  
  const [nPermission, setPermission] = useState(0);

  const filter: FilterFieldInterface[] = [
    { sTypeFilterMode: "input", sFieldName: "sSection" },
    {
      sTypeFilterMode: "daterange", sFieldName: "sLastUpdate"
    },
    {
      sTypeFilterMode: "select", sFieldName: "sStatus", lstDataSelect: [
        { value: 1, keyId: 1, label: "Active" },
        { value: 2, keyId: 2, label: "Inactive" },
      ]
    },
    {
      sTypeFilterMode: "select", sFieldName: "sStatusShowContactUs", lstDataSelect: [
        { value: 1, keyId: 1, label: "Active" },
        { value: 2, keyId: 2, label: "Inactive" },
      ]
    },
  ]
  const dataColumn: GridColumns = [
    {
      renderHeader: (item) => <BtnAddOnTable
        onClick={() => { history('/CustomerUserForm') }}
        txt={"Add"}
      />,
      headerName: "Button (Add/Edit)",
      field: "Button (Add/Edit)",
      type: "actions",
      width: 70,
      resizable: false,
      sortable: false,
      getActions: (item) => {
        return [
          (
            <BtnEditOnTable
              txt="Edit"
              onClick={() => goToEdit(item.row.sID, item.row)}
            />
          )
        ];
      },
    },
    {
      field: "sCusCode",
      headerName: "Customer code",
      headerAlign: "center",
      align: "left",
      flex: 3,
      resizable: true,
      minWidth: 200,
    },
    {
      field: "sCusName",
      headerName: "Customer name",
      headerAlign: "center",
      align: "left",
      flex: 3,
      resizable: true,
      minWidth: 200,
    },
    {
      field: "sCusUnitName",
      headerName: "Unit name",
      headerAlign: "center",
      align: "left",
      flex: 3,
      resizable: true,
      minWidth: 200,
    },
    {
      field: "sCreateBy",
      headerName: "Create by",
      headerAlign: "center",
      align: "left",
      flex: 3,
      resizable: true,
      minWidth: 200,
    },
    {
      field: "sCreateDate",
      headerName: "Create date",
      headerAlign: "center",
      align: "left",
      flex: 3,
      resizable: true,
      minWidth: 200,
    }       
  ];
  useEffect(() => {
    loadData(dataRow);
  }, []);  
  const loadData = (p: PaginationInterface) => {
    setLoadingTable(true)    
    AxiosPost("MasterDataType/SearchData_MasterDataType", p, (result) => {
      setLoadingTable(false)
      setDataRow({
        ...p,
        // rows: result.obj,
        arrRows: result.obj,
        nDataLength: result.nDataLength,
        nPageIndex: result.nPageIndex,
      });
    })
  }

  const goToEdit = (sID: string, item: any) => {
    // window.localStorage.setItem("sSubRouteName", item.sSection);
    history(`/CustomerUserForm?sID=${encodeURI(sID)}`);
  };

  return (    
    <Table
      isLoading={loadingTable}
      filterField={filter}
      Rows={dataRow}
      Columns={dataColumn}
      OnLoadData={(p) => loadData(p)}
    />
  );
}
