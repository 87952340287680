import FrontRoute from "router/Front";
import BackRoute from "router/Back";
import Layout_Font from "layout/CommonLayout/Layout_Font";
import EmptyLayout from "layout/CommonLayout/EmptyLayout";
import HomeFont from "view/font/Home/Home";
import CreateJobPackage from "components/AdminUser/Package/Job/CreateJobPackage";
import CreateCoinPackage from "components/AdminUser/Package/Coin/CreateCoinPackage";
import CreateBannerPackage from "components/AdminUser/Package/Banner/CreateBannerPackage ";
import AdminBannerAdd from "components/Banner/AdminBannerAdd";
import AdminBannerShow from "components/Banner/AdminBannerShow";
import AdminBannerTable from "components/Banner/AdminBannerTable";
import ShowListPackage from "components/AdminUser/Package/Show/ShowListPackage";
import CreatePackage from "components/AdminUser/Package/Show/CreatePackage ";
import Layout_Back_BPS from "layout/CommonLayout/Layout_Back_BPS/index_BPS";
import AdminBannerSearch from "components/Banner/AdminBannerSearch";
import Admin_Form from "view/back/BPS/BPS_Admin/Admin_Form";
import Admin_Table from "view/back/BPS/BPS_Admin/Admin_Table";
import Group_Table from "view/back/BPS/BPS_Group/Group_Table";
import Group_Form from "view/back/BPS/BPS_Group/Group_Form";
import CustomerUser from "components/AdminUserPermission/AdminUser";
import AdminContent from "components/Banner/AdminContent/AdminContent";
import BoxContent from "components/Banner/AdminContent/BoxContent";
import ContentTable from "components/Banner/AdminContent/ContentTable";
import AdminBannerbox from "components/Banner/AdminBannerShowCard";
import AdminCandidateTable from "components/AdminCandidate/AdminCandidateTable";
import AdminCandidate from "components/AdminCandidate/AdminCandidate";
import AdminCandidateViewed from "components/AdminCandidate/AdminCandidateViewed";
import AdminBannerShowPreview from "components/Banner/AdminBannerShowPreview";
import BPS_GroupTable from "components/BPS/BPS_Group/BPS_GroupTable";
import AdminUser from "components/AdminUserPermission/AdminUser";
import CustomerTable from "components/CustomerUser/CustomerTable";
import CustomerCreateForm from "components/CustomerUser/CustomerCreateForm";
import AdminResetPWD from "components/AdminUserPermission/AdminResetPWD";
import AdminBannerShowCard from "components/Banner/AdminBannerShowCard";
import OrderEmployerForm from "view/font/OrderEmployer/OrderEmployerForm";
import RegisterEmployer from "./Front/RegisterEmployer";
import LoginAdminPage from "view/font/Login/LoginAdminPage";
import ExampleComponent from "components/ExampleComponent";
import EmployerSearch from "view/font/EmployerSearch";
import AdminUserTable from "components/AdminUserPermission/AdminUserTable";
import JobApply from "view/font/JobApply/JobApply";
import JobApplySalary from "view/font/JobApply/JobApplySalary";
import AdminCandidateProfile from "components/AdminCandidate/AdminCandidateProfile";
import JobSeekerRegisJob from "view/font/JobSeekerRegisJob";
import ForgotPassword from "view/font/Login/ForgotPassword";
import ContentPage from "view/font/Home/ContentPage";
import DemoComponent from "./DemoComponent";
import LoginForm from "view/font/Login/LoginForm";
import ContentConditions from "view/font/Home/ContentConditions";
import ContentPrivacy from "view/font/Home/ContentPrivacy";
import Email from "components/Banner/Email";
import AzureLogin from "view/font/Login/AzureLogin";
import EmailOrder from "components/Banner/EmailOrder";
import MemberTable from "components/CompanyEmployee/MemberTable";
import MemberRegister from "components/CompanyEmployee/MemberRegister";
import MemberEdit from "components/CompanyEmployee/MemberEdit";
import ModalDownload from "components/EmployerSearch/ModalDownload";
import ConditionOrder from "view/font/Home/ConditionOrder";
import HoldPackage from "components/Admin/HoldPackageAdd";
import AdminHoldPackage from "components/Admin/AdminHoldPackage";
import HoldPackageAdd from "components/Admin/HoldPackageAdd";
import PausePackageAdd from "components/Admin/PausePackageAdd";
import AdminPausePackage from "components/Admin/AdminPausePackage";
import ModalLog from "components/Admin/ModalLogHold";
import ModalLogHold from "components/Admin/ModalLogHold";
import MemberProfileSetting from "view/font/Member_ProfileSetting/MemberProfileSetting";

// import Content from "view/font/Home/Content";

const route = [
  ...FrontRoute,
  ...BackRoute,
  {
    path: "/",
    component: HomeFont,
    layout: Layout_Font,
    exact: true,
    propsData: {
      imgBGType: 2,
    },
  },
  {
    path: "/AdminLogin",
    component: LoginAdminPage,
    layout: EmptyLayout,
    exact: true
  },
  {
    path: "/ForgotPassword",
    component: ForgotPassword,
    layout: EmptyLayout,
    exact: true,
    // propsData: {
    //   imgBGType: 2,
    // }
  },
  {
    path: "/ShowListPackage",
    component: ShowListPackage,
    layout: Layout_Back_BPS,
    exact: true,
  },
  {
    path: "/CreatePackage",
    component: CreatePackage,
    layout: Layout_Back_BPS,
    exact: true,
  },
  {
    path: "/CreateJobPackage",
    component: CreateJobPackage,
    layout: Layout_Back_BPS,
    exact: true,
  },
  {
    path: "/CreateCoinPackage",
    component: CreateCoinPackage,
    layout: Layout_Back_BPS,
    exact: true,
  },
  {
    path: "/CreateBannerPackage",
    component: CreateBannerPackage,
    layout: Layout_Back_BPS,
    exact: true,
  },
  {
    path: "/AdminBannerAdd",
    component: AdminBannerAdd,
    layout: Layout_Back_BPS,
    exact: true,
  },
  {
    path: "/AdminBannerTable",
    component: AdminBannerTable,
    layout: Layout_Back_BPS,
    exact: true,
  },
  {
    path: "/AdminBannerShow",
    component: AdminBannerShow,
    layout: Layout_Back_BPS,
    exact: true,
  },
  {
    path: "/AdminBannerShowCard",
    component: AdminBannerShowCard,
    layout: Layout_Back_BPS,
    exact: true,
  },
  {
    path: "/AdminBannerShowPreview",
    component: AdminBannerShowPreview,
    layout: Layout_Back_BPS,
    exact: true,
  },
  {
    path: "/AdminBannerSearch",
    component: AdminBannerSearch,
    layout: Layout_Back_BPS,
    exact: true,
  },
  {
    path: "/AdminTable_BPS",
    component: Admin_Table,
    layout: Layout_Back_BPS,
    exact: true,
  },
  {
    path: "/AdminForm_BPS",
    component: Admin_Form,
    layout: Layout_Back_BPS,
    exact: true,
  },
  {
    path: "/CustomerUser",
    component: CustomerUser,
    layout: Layout_Back_BPS,
    exact: true,
  },
  {
    path: "/GroupTable_BPS",
    component: Group_Table,
    layout: Layout_Back_BPS,
    exact: true,
  },
  {
    path: "/GroupForm_BPS",
    component: Group_Form,
    layout: Layout_Back_BPS,
    exact: true,
  },

  {
    path: "/AdminContent",
    component: AdminContent,
    layout: Layout_Back_BPS,
    exact: true,
  },
  {
    path: "/BoxContent",
    component: BoxContent,
    layout: Layout_Back_BPS,
    exact: true,
  },
  {
    path: "/ContentTable",
    component: ContentTable,
    layout: Layout_Back_BPS,
    exact: true,
  },

  {
    path: "/AdminBannerbox",
    component: AdminBannerbox,
    layout: Layout_Back_BPS,
    exact: true,
  },
  {
    path: "/AdminCandidateTable",
    component: AdminCandidateTable,
    layout: Layout_Back_BPS,
    exact: true,
  },
  {
    path: "/AdminCandidate",
    component: AdminCandidate,
    layout: Layout_Back_BPS,
    exact: true,
  },
  {
    path: "/AdminCandidateViewed",
    component: AdminCandidateViewed,
    layout: Layout_Back_BPS,
    exact: true,
  },
  {
    path: "/CustomerCreateForm",
    component: CustomerCreateForm,
    layout: Layout_Back_BPS,
    exact: true,
  },
  {
    path: "/CustomerTable",
    component: CustomerTable,
    layout: Layout_Back_BPS,
    exact: true,
  },
  {
    path: "/AdminUserTable",
    component: AdminUserTable,
    layout: Layout_Back_BPS,
    exact: true,
  },
  {
    path: "/BPS_GroupTable",
    component: BPS_GroupTable,
    layout: Layout_Back_BPS,
    exact: true,
  },
  {
    path: "/AdminUser",
    component: AdminUser,
    layout: Layout_Back_BPS,
    exact: true,
  },

  {
    path: "/SubmitResetPWD",
    component: AdminResetPWD,
    layout: Layout_Font,
  },
  {
    path: "/OrderEmployerForm",
    component: OrderEmployerForm,
    layout: null,
    exact: true,
  },
  {
    path: "/RegisterEmployer",
    component: RegisterEmployer,
    layout: Layout_Back_BPS,
    exact: true,
  },
  {
    path: "/ExampleComponent",
    component: ExampleComponent,
    layout: Layout_Font,
    exact: true,
  },
  {
    path: "/EmployerSearch",
    component: EmployerSearch,
    layout: Layout_Font,
    exact: true,
    propsData: {
      imgBGType: 5,
      // isPaddingTop: true,
    },
  },
  {
    path: "/EmployerSearchViewJobseeker",
    component: AdminCandidateProfile,
    layout: Layout_Font,
    exact: true,
  },
  {
    path: "/JobApply",
    component: JobApply,
    layout: Layout_Font,
    exact: true,
  },
  {
    path: "/JobseekerRegisJob",
    component: JobSeekerRegisJob,
    layout: Layout_Font,
    exact: true,
    propsData: {
      imgBGType: 5,
      // isPaddingTop: true,ContentConditions
    },
  },
  {
    path: "/JobApplySalary",
    component: JobApplySalary,
    layout: Layout_Font,
    exact: true,
  },
  {
    path: "/Content",
    component: ContentPage,
    layout: Layout_Font,
    exact: true,
    propsData: {
      imgBGType: 5,
      // isPaddingTop: true,
    },
  },
  {
    path: "/Component",
    component: DemoComponent,
    layout: null,
    exact: true,
  },
  {
    path: "/ContentConditions",
    component: ContentConditions,
    layout: null,
    exact: true,

  },
  {
    path: "/ContentPrivacy",
    component: ContentPrivacy,
    layout: null,
    exact: true,
  },
  {
    path: "/Email",
    component: Email,
    layout: null,
    exact: true,
  },
  {
    path: "/EmailOrder",
    component: EmailOrder,
    layout: null,
    exact: true,
  },
  {
    path: "/MemberEmployeeRegister",
    component: MemberRegister,
    layout: Layout_Font,
    exact: true,
    // propsData: {
    //   imgBGType: 5,
    //   isPaddingTop: true,
    // }
  },
  {
    path: "/MemberEmployeeTable",
    component: MemberTable,
    layout: Layout_Font,
    exact: true,
    // propsData: {
    //   imgBGType: 5,
    //   // isPaddingTop: true,
    // }
  },
  {
    path: "/MemberEmployeeEdit",
    component: MemberEdit,
    layout: Layout_Font,
    exact: true,
    // propsData: {
    //   imgBGType: 5,
    //   // isPaddingTop: true,
    // }
  },
  {
    path: "/MemberProfileSetting",
    component: MemberProfileSetting,
    layout: Layout_Font,
    exact: true,
    // propsData: {
    //   imgBGType: 5,
    //   // isPaddingTop: true,
    // }
  },
  {
    path: "/ConditionOrder",
    component: ConditionOrder,
    layout: null,
    exact: true,
  },
  {
    path: "/AdminHoldPackage",
    component: AdminHoldPackage,
    layout: Layout_Back_BPS,
    exact: true,
  },
  {
    path: "/HoldPackageAdd",
    component: HoldPackageAdd,
    layout: Layout_Back_BPS,
    exact: true,
  },
  {
    path: "/PausePackageAdd",
    component: PausePackageAdd,
    layout: Layout_Back_BPS,
    exact: true,
  },
  {
    path: "/AdminPausePackage",
    component: AdminPausePackage,
    layout: Layout_Back_BPS,
    exact: true,
  },
  {
    path: "/ModalDownload",
    component: ModalDownload,
    layout: null,
    exact: true,
  },
  {
    path: "/ModalLogHold",
    component: ModalLogHold,
    layout: null,
    exact: true,
  },
];

export default route;