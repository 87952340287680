import Layout_Font from "layout/CommonLayout/Layout_Font"
import MyPageEmployer from "view/font/MyPageEmployer/MyPageEmployer"

const MyPageEmployerRoute = [{
    path: "/MyPageEmployer",
    component: MyPageEmployer,
    layout: Layout_Font,
    exact: true,
    propsData: {
        imgBGType: 5,
        // isPaddingTop: true,
      }
}]

export default MyPageEmployerRoute