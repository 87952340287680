import {
    DataGridMui as Table,
    initRows,
    PaginationInterface,
    FilterFieldInterface
} from "components/Common/Table/DataGridMui";
import { GridColumns } from "@mui/x-data-grid";
import { BtnAddOnTable, BtnEditOnTable } from "components/Common/Button";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { AxiosPost, GetPermission, ResultAPI } from "service/CommonFunction/TS_function";
import { DialogActionCreators } from "store/redux/DialogAlert";
import { Button, FormControlLabel, Grid, Tooltip } from "@mui/material";
import AutoCompleteInTable from "components/Common/Table/AutoCompleteInTable";
import IOSSwitch from "components/Common/ElementInForm/IosSwitch";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import yupFormSchemas from "components/Common/yup/yupFormSchemas";
import * as yup from "yup";
import { i18n } from 'i18n';
import '../BPS_Admin/BPS_Admin.css'
import { FnBlock_UI } from "service/SystemFunction/SystemFunction";

export default function Admin_MasterData(props: { nDataType_ID, onGetdata, dataRow, setDataRow }) {
    const i18nField = 'entities.AdminMasterTable';
    const i18nCommon = 'common';
    const i18nDialog = 'dialog';
    const { BlockUI, UnBlockUI } = FnBlock_UI();
    const { nDataType_ID, onGetdata, dataRow, setDataRow } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isHead = nDataType_ID == 6 || nDataType_ID == 8 ? true : false;
    const [loadingTable, setLoadingTable] = useState(true);
    const [ButtChange, setButtChange] = useState(true);
    const [nPermission, setPermission] = useState(0);
    const [nOderSort, setnOderSort] = useState(0);

    const filter: FilterFieldInterface[] = isHead ? [
        { sTypeFilterMode: "input", sFieldName: "sDataTypeTH" },
        { sTypeFilterMode: "input", sFieldName: "sNameTH" },
        { sTypeFilterMode: "input", sFieldName: "sNameENG" },
        { sTypeFilterMode: "daterange", sFieldName: "sUpdateDate" },
        {
            sTypeFilterMode: "select", sFieldName: "isActive", lstDataSelect: [
                { value: "1", keyId: 1, label: "ใช้งาน" },
                { value: "0", keyId: 0, label: "ไม่ใช้งาน" },
            ],
        },
    ] : [
        { sTypeFilterMode: "input", sFieldName: "sNameTH" },
        { sTypeFilterMode: "input", sFieldName: "sNameENG" },
        { sTypeFilterMode: "daterange", sFieldName: "sUpdateDate" },
        {
            sTypeFilterMode: "select", sFieldName: "isActive", lstDataSelect: [
                { value: "1", keyId: 1, label: "ใช้งาน" },
                { value: "0", keyId: 0, label: "ไม่ใช้งาน" },
            ],
        },
    ]
    const [objSchema, setObjSchema] = useState({
        sNameTH: yupFormSchemas.string("ชื่อ (TH)", { required: true }),
        sNameENG: yupFormSchemas.string("ชื่อ (ENG)", { required: true }),
        sDataTypeName: yupFormSchemas.object("ประเภทข้อมูล (TH)", { required: true })
    });
    const schema = yup.object().shape(objSchema);
    const form = useForm({
        resolver: yupResolver(schema),
        shouldUnregister: false,
        shouldFocusError: true,
        mode: "all"
    });

    useEffect(() => {
        GetPermission(12, setPermission);
        loadData(dataRow);
    }, [nDataType_ID]);

    const loadData = (p: PaginationInterface) => {
        setLoadingTable(true)
        const objFilter = { ...p, nDataType_ID: nDataType_ID }

        AxiosPost("AdminBPS/SearchData_AdminDataType", objFilter, (result) => {
            setLoadingTable(false)
            setDataRow({
                ...p,
                arrRows: result.lstAdminData,
                nDataLength: result.nDataLength,
                nPageIndex: result.nPageIndex,
            });
        }, (err) => {
            if (err.response && err.response.status === 401) {
                dispatch(DialogActionCreators.OpenDialogWarning("Token expired.", () => {
                    navigate("/")
                }) as any)
            }
        })
    }

    const onEdit = (sID: string, item: any) => {
        navigate(`/AdminForm_BPS?sID=${encodeURI(sID)}`);
    }

    const OnDelete = (e) => {
        dispatch(DialogActionCreators.OpenDialogSubmit(i18n(`${i18nCommon}.AlertConfrimDel`), () => {
            dispatch(DialogActionCreators.CloseDialogSubmit() as any);
            dispatch(DialogActionCreators.LoadSubmit("Waiting...") as any);

            AxiosPost(`AdminBPS/EditData_RemoveDataTable`, e, (result) => {
                dispatch(DialogActionCreators.CloseDialogSubmit() as any);
                ResultAPI(result, i18n(`${i18nDialog}.DialogRemoveSuccess`), () => {
                    dispatch(DialogActionCreators.OpenDialogSuccess(i18n(`${i18nDialog}.DialogRemoveSuccess`)) as any)
                    loadData(dataRow);
                });
            })
        }) as any)
    }

    const onChangeStatus = (e, item) => {
        let arrRowcopy = [...dataRow.arrRows]
        let sStatus = arrRowcopy.filter(f => f.sID == item.row.sID)[0];
        if (sStatus) {
            sStatus.isActive = e.target.checked
        }

        let objData = {
            isActive: sStatus.isActive,
            sID: item.row.sID,
        }

        AxiosPost("AdminBPS/EditData_ToggleActive", objData, (res) => {
            ResultAPI(res, i18n(`${i18nCommon}.msgAlertSaveSuccess`), () => { onGetdata(); }); //Saved successfully
        });
    }

    const dataColumn: GridColumns = [
        {
            field: "ปุ่ม (เพิ่ม/แก้ไข)",
            headerName: "",
            type: "actions",
            resizable: false,
            sortable: false,
            getActions: (item) => {
                return [
                    (
                        <BtnEditOnTable
                            IsDisabled={nPermission !== 2}
                            txt={'แก้ไข'}
                            onClick={() => onEdit(item.row.sID, item.row)}
                        />
                    )
                ];
            },
        },
        {
            field: "nOrder",
            headerName: "ลำดับ",
            headerAlign: "center",
            align: "center",
            resizable: false,
            sortable: true,
            flex: 1,
            minWidth: 100,
            renderCell: (item) => {

                const lstDataOrder = [];

                if (nDataType_ID == 0) {
                    item.row.lstOrder.forEach((e, index) => {
                        lstDataOrder.push({
                            label: index + 1,
                            value: index,
                        });
                    });
                } else {
                    item.row.lstOrder.forEach((e, index) => {
                        lstDataOrder.push({
                            label: e,
                            value: e,
                        });
                    });
                }
                return (
                    <AutoCompleteInTable
                        small
                        disabled={nPermission !== 2}
                        objValue={{
                            label: item.row.nOrder,
                            value: item.row.nOrder
                        }}

                        funcOnChange={(newValue: any) => {

                            AxiosPost("AdminBPS/EditData_ChangeOrder", {
                                sID: item.row.sID,
                                nOrder: newValue.target.innerText,
                                nDataType_ID: item.row.nDataType_ID
                            }, (res) => {
                                if (res.Status === 200) {
                                    loadData(dataRow);
                                } else {
                                    dispatch(DialogActionCreators.OpenDialogWarning(res.Message))
                                }
                            });
                        }}
                        lstOption={lstDataOrder}

                        disClearable
                    />
                );
            },
        },
        {
            field: "sDataTypeTH",
            headerName: "ประเภทข้อมูล",
            headerAlign: "center",
            align: "left",
            resizable: false,
            sortable: true,
            flex: 2,
            minWidth: 200,
        },
        {
            field: "sNameTH",
            headerName: "ชื่อ (TH)",
            headerAlign: "center",
            align: "left",
            resizable: false,
            sortable: true,
            flex: 3,
            minWidth: 350,
        },
        {
            field: "sNameENG",
            headerName: "ชื่อ (ENG)",
            headerAlign: "center",
            align: "left",
            resizable: false,
            sortable: true,
            flex: 3,
            minWidth: 350,
        },
        {
            field: "sUpdateDate",
            headerName: "ปรับปรุงล่าสุด",
            headerAlign: "center",
            align: "center",
            resizable: false,
            sortable: true,
            flex: 1,
            minWidth: 180,
        },
        {
            field: "isActive",
            headerName: "สถานะ",
            headerAlign: "center",
            align: "center",
            resizable: false,
            sortable: true,
            flex: 1,
            minWidth: 180,
            renderCell: (item) => {
                let sStatust = dataRow.arrRows.filter(f => f.sID == item.row.sID)[0];
                return (
                    <>
                        <FormControlLabel
                            control={<IOSSwitch disabled={nPermission !== 2} sx={{ m: 1 }} />}
                            label={sStatust.isActive ? "ใช้งาน" : "ไม่ใช้งาน"}
                            checked={sStatust.isActive}
                            onClick={
                                (e) => { onChangeStatus(e, item) }
                            }
                        />
                    </>
                );
            },
        }
    ];

    return (
        <Table
            isLoading={loadingTable}
            filterField={filter}
            Rows={dataRow}
            Columns={dataColumn}
            OnLoadData={(p) => loadData(p)}
            isShowCheckBox={nPermission == 2}
            onDelete={OnDelete}
        />
    );
}