import React, { useState, useEffect } from 'react';
import {
  Box, Stack, Typography,
  SxProps, CardMedia, Container,
  ImageList, ImageListItem
} from "@mui/material"
import { BtnBack } from "components/Common/Button";
import { useNavigate } from "react-router-dom";
import { AxiosPost, ResultAPI } from 'service/CommonFunction/TS_function';
import ModalViewImage from 'components/Common/ModalViewImage/ModalViewImage';
import ParseHTML from "html-react-parser";

const objStyle = {
  w_600: {
    fontWeight: 600,
  } as SxProps,
  w_500: {
    fontWeight: 500,
  } as SxProps,
  fs_12: {
    marginTop: "1em",
    fontWeight: 400,
  } as SxProps,
  text_date: {
    color: "#1ca5fc",
    fontSize: "0.8em",
  } as SxProps,
  image_cover: {
    display: "flex", flex: 1, minHeight: 400, backgroundSize: "cover"
  } as SxProps,
  container: {
    " > div": {
      marginTop: "2rem",
    }
  } as SxProps,
  youtube_style: {
    width: "60vw;",
    height: "calc((60vw)*9/16);",
    maxHeight: "100vw",
    maxWidth: "100%",
    " @media (max-width:767px) ": {
      width: "80vw;",
      height: "calc((80vw)*9/16);",
    }
  } as SxProps,
  row_tag: {
    padding: "0px 1rem",
    marginTop: "5px"
  } as SxProps,
  img_list: {
    minWidth: "20vw",
    maxWidth: "30vw",
    minHeight: "85%",
    maxHeight: "25vh",
    padding: "5px 10px",
    paddingTop: "10px",
    textAlign: "center",
    display: 'flex',
    justifyContent: "center",
    " @media (max-width:1100px) ": {
      minWidth: "15vw",
      minHeight: "90%",
      maxWidth: "25vw",
    },
    " @media (max-width:767px) ": {
      minWidth: "30vw",
      minHeight: "90%",
      maxWidth: "50vw",
    },
  } as SxProps
}

const ContentPage = () => {
  const [imageView, setImageView] = useState("");
  const [lstContentTerm, setlstContentTerm] = useState([]);
  const history = useNavigate();
  const [isViewImage, setIsViewImage] = useState(false);

  useEffect(() => {
    OnGetData();
  }, []);

  const OnGetData = () => {
    let param = {
      nID: 1
    };
    AxiosPost("HomeFront/getData_ContentDetail", param, (result) => {
      setlstContentTerm(result.contentShow || []);
    },
      (err) => {
        if (!err.response) {
          history("/error", { replace: true })
          return;
        }
      });
  }

  return (
    <>
      <ModalViewImage
        image={imageView}
        isOpen={isViewImage}
        handleClose={() => setIsViewImage(false)}
      />
      <Container sx={objStyle.container}>
        {lstContentTerm.map((item, key) => {
          switch (item.nType) {
            case 1:
              return (
                <Stack key={`type_1_${key}`} direction="row">
                  <div>{ParseHTML(item.sContent)}</div>
                </Stack>
              )
            case 2:
              return (
                <Stack key={`type_2_${key}`} direction="row" >
                  <Stack direction="column" alignItems="center" flex={1}>
                    <CardMedia onClick={() => {
                      setImageView(item.sFileNameSys)
                      setIsViewImage(true)
                    }} component={"img"} sx={{
                      objectFit: "contain",
                      width: "auto",
                      maxWidth: "100%",
                      maxHeight: "calc(90vh - 45px)",
                      cursor: "pointer"
                    }} src={item.sFileNameSys} />
                    <Typography sx={objStyle.fs_12} variant="body1" >{item.sContent}</Typography>
                  </Stack>
                </Stack>
              )
          }
        })}

      </Container >
      {/* {
        isCanBack === true && <Stack direction="row" sx={{ marginTop: "0px", marginLeft: "10%", paddingBottom: "10px" }}>
          <BtnBack onClick={() => history(-1)} />
        </Stack>
      } */}
    </>
  );
}
export default ContentPage;