import { JobPostTh } from "./JobPost";
import { OrderEmployerTh } from "./OrderEmployer";
import { RegisterEmployerTh } from "./RegisterEmployer";
import { JobDetailTh } from "./JobDetail";
import { SettingSeekerTh } from "./SettingSeeker";
import { SeekerProfileTH } from "./SeekerProfile";
import { SearchJobTh } from "./SearchJob";
import { HomeTh } from "./Home";
import { MyPageEmployerTH } from "./MyPageEmployer";

import { TableOrderEmployerTh } from "./OrderEmployer";
import { TablePackageTh } from "./OrderEmployer";
import { TableBannerTh } from "./OrderEmployer";
import { MyJobInvitation_TH } from "./MyJobInvitation";
import { SetupTemplateTH } from "./SetupTemplate";
import { MyJobInterestTH } from "./MyJobInterest";
import { MyJobTh } from "./MyJob";

import { labelI18nTh } from "layout/CommonLayout/Layout_Font/Modal_Forgot";
import * as EmployerSearchFilter from "components/EmployerSearch/Filter";
import * as EmployerSearchCard from "components/EmployerSearch/CardItem";
import * as EmployerSearchModal from "components/EmployerSearch/ModalInvite";
import * as EmployerInformation from "components/EmployerSearch/InfomationJobSeeker";
import * as JobseekerRegisJobModal from "components/JobSeekerRegisJob/ModalAddTag";
import * as JobseekerFilterTabs from "components/JobSeekerRegisJob/FilterTabs";
import * as ModalBuyProfile from "components/EmployerSearch/ModalBuyProfile";
import { MyJobApplyTH } from "./MyJobApply";
import { Search } from "@mui/icons-material";
const th = {
  TablePackage: {
    ...TablePackageTh,
  },
  TableBanner: {
    ...TableBannerTh,
  },
  TableOrderEmployer: {
    ...TableOrderEmployerTh,
  },
  searchJob: {
    ...SearchJobTh,
  },
  home: {
    ...HomeTh,
  },
  MyPageEmployer: {
    ...MyPageEmployerTH,
  },
  SettingSeeker: {
    ...SettingSeekerTh,
  },
  jobPost: {
    ...JobPostTh,
  },
  OrderEmployer: {
    ...OrderEmployerTh,
  },
  RegisterEmployer: {
    ...RegisterEmployerTh,
  },
  jobDetail: {
    ...JobDetailTh,
  },
  myJobInvitation: {
    ...MyJobInvitation_TH,
  },
  SetupTemplate: {
    ...SetupTemplateTH,
  },
  MyJob: {
    ...MyJobTh,
  },
  common: {
    active: "ใช้งาน",
    inactive: "ไม่ใช้งาน",
    or: "or",
    cancel: "Cancel",
    reset: "Reset",
    back: "ย้อนกลับ",
    save: "บันทึก",
    search: "ค้นหา", //Search
    close: "ปิด",
    preview: "ดูตัวอย่าง",
    LastUpdate: "อัปเดตเมื่อ",
    edit: "แก้ไข", //Edit
    new: "ใหม่", //New
    export: "Export to Excel",
    noDataToExport: "No data to export",
    import: "Import",
    discard: "ทิ้ง", //Discard
    yes: "Yes",
    no: "No",
    pause: "หยุด", //Pause
    areYouSure: "Are you sure?",
    view: "View",
    destroy: "ลบ", //Delete
    mustSelectARow: "Must select a row",
    start: "Start",
    end: "End",
    select: "Select",
    continue: "Continue",
    filters: "ตัวกรอง",
    status: "สถานะ",
    description: "Description",
    unit: "Unit",
    selected: "รายการ",
    AlertRequestSuccess: "ส่งคำขอสำเร็จ กรุณาตรวจสอบอีเมลของคุณ",
    AlertConfrimDel: "คุณต้องการลบข้อมูลหรือไม่ ?",
    AlertConfrimSave: "คุณต้องการบันทึกข้อมูลหรือไม่ ?",
    AlertConfrimSendEmail: "คุณต้องการส่งอีเมลหรือไม่ ?",
    AlertConfrimDeactivate: "คุณต้องการลบประกาศหรือไม่ ?",
    AlertConfrimSaveEdit: "คุณต้องการส่งคำเชิญหรือไม่ ?",
    msgAlertDelSuccess: "ลบข้อมูลสำเร็จ",
    msgAlertSaveSuccess: "บันทึกข้อมูลสำเร็จ",
    msgAlertSendEmail: "ส่งอีเมลสำเร็จ",
    msgAlertSpecificData: "กรุณากรอกข้อมูล",
    msgAlertInvitation: "ส่งคำเชิญสำเร็จ",
    more_than: "มากกว่า",
    between: "ถึง",
    custom_value: "กำหนดค่า",
    year: "ปี",
    baht: "บาท",
    remove: "ลบ",
    add: "เพิ่ม",
    fileSizeLimit: "ขนาดไฟล์สูงสุดที่อัพได้คือ",
    AlertConfrimEdit: "คุณต้องการเปลี่ยนรหัสผ่านหรือไม่ ?",
    msgAlertLogout: "คุณต้องการออกจากระบบหรือไม่ ?",
    myJobApply: "งานที่สมัคร",
    myJobInterested: "งานที่สนใจ",
    myJobViewed: "บริษัทที่ซื้อประวัติคุณ",
    myJobInvitation: "คำเชิญจากบริษัท",
    msgAlertIncorrectPassword: "รหัสผ่านไม่ถูกต้อง",
    msgAlertDataNotFound: "ไม่พบข้อมูล",
    msgAlertUsernameOrPassWrong: "ชื่อผู้ใช้งานหรือรหัสผ่านไม่ถูกต้อง",
    msgAlertLoginTypeWrong: "ประเภทการเข้าสู่ระบบไม่ถูกต้อง",
    msgAlertMemberWarning: "กรุณากำหนดสิทธิ์การใช้งานให้สมาชิก",
    msAlertStatus: "ไม่พบผู้ใช้งานนี้ในระบบ",
    msAlerlRegister: "ไม่สามารถลงทะเบียนเข้าใช้งานได้ เนื่องจากคำเชิญนี้ถูกยกเลิกโดยผู้ดูแลระบบ",
    forgotPassword: "เปลี่ยนรหัสผ่านใหม่",
    requestExpired: "คำขอหมดอายุ!",
    Expand: "งานอื่นๆ",
    AlertConfrimUpload: "ไม่มี Logo แล้วจะไม่สามารถดูรายละเอียดของบริษัทได้",
    magAlertPackageDeleted: "ไม่สามารถบันทึกได้เนื่องจากแพ็กเกจถูกลบไปแล้ว",
    SessionExpired: "เซสชั่นหมดอายุ กรุณาเข้าสู่ระบบอีกครั้ง",
    ButtonAdd: "ปุ่ม (เพิ่ม/แก้ไข)",
    duplicateButt: "สำเนาประกาศ",
    email: "อีเมล",
    sendEmail: "ส่งอีเมล",
    buypackage: "สั่งซื้อแพ็กเกจ",
    register: "ลงทะเบียน",
    loginformember: "เข้าสู่ระบบสำหรับผู้ที่เป็นสมาชิก",
    memberName: "อีเมล",
    memberPass: "รหัสผ่าน",
    previous: "ก่อนหน้า",
    next: "ถัดไป",
    buypackageforemployer: "สั่งซื้อแพ็กเกจสำหรับผู้ประกอบการ",
    msgEmailDup: "อีเมลนี้ถูกใช้งานแล้ว",
    loginmember: "เข้าสู่ระบบ",

    //alert confirm post job page
    AlertDuplicatePostJob: "คุณต้องการบันทึกสำเนาประกาศหรือไม่ ?",
    AlertSaveDraftPostJob: "คุณต้องการบันทึกแบบร่างหรือไม่ ?",
    AlertPublishPostJob: "คุณต้องการประกาศงานหรือไม่ ?",
    AlertBoostPostJob: "คุณต้องการ Boost Post หรือไม่ ?",
    AlertDeactivatePostJob: "คุณต้องการลบประกาศงานหรือไม่ ?",

    //alert confirm order employer side
    AlertSendProofPayment: "คุณต้องการส่งหลักฐานการชำระเงินหรือไม่ ?",
    AlertCancelOrder: "คุณต้องการยกเลิกการสั่งซื้อหรือไม่ ?",

  },
  ForgotPw: {
    ...labelI18nTh,
  },
  app: {
    title: "BPS Job Board",
  },
  EmployerSearch: {
    Filter: { ...EmployerSearchFilter.th },
    Info: { ...EmployerSearchCard.th },
    ModalInvite: { ...EmployerSearchModal.th },
    Information: { ...EmployerInformation.th },
    ModalBuyProfile: { ...ModalBuyProfile.th },
  },
  JobSeekerRegisJob: {
    ModalAddTag: { ...JobseekerRegisJobModal.sTh },
    FilterTabs: { ...JobseekerFilterTabs.th },
  },
  // auth.login.fields
  entities: {
    ...SeekerProfileTH,
    ...MyJobInterestTH,
    ...MyJobApplyTH,
    auth: {
      login: {
        fields: {
          Username: "Username",
          Password: "Password",
          Email: "Email",
          OldPassword: "OldPassword",
          NewPassword: "NewPassword",
          ConfirmNewPassword: "ConfirmNewPassword",
        },
        labels: {
          OldPassword: "Old Password",
          NewPassword: "New Password",
          ConfirmNewPassword: "Confirm New Password",
        },
      },
    },
    graph: {
      fields: {
        BoxNo: "BoxNo",
        ActionDate: "ActionDate",
        Patient: "Patient",
        BackDate: "BackDate",
        Frame: "Frame",
        Display: "Display",
        Zoom: "Zoom",
      },
    },
    rolepermission: {
      fields: {
        Role: "Role",
      },
    },
    note: {
      fields: {
        note: "note",
      },
    },
    userinfo: {
      fields: {
        FirstName: "First Name",
        LastName: "Last Name",
        UserName: "Username",
        Email: "Email",
        Status: "Status",
        Hospital: "Hospital",
        SignatureCode: "Signature Code",
        MaxConcurrent: "Max Concurrent",
        HospitalRole: "Role",
      },
    },
    filteruserinfo: {
      fields: {
        Search: "Name / Email",
        Hospital: "Hospital",
        Role: "Role",
        Status: "Status",
      },
    },
    SignModal: {
      fields: {
        Docter: "Docter Name",
        Electronic: "Signature Code",
        Status: "Status",
        Parent: "Parent",
      },
    },
    ParentModal: {
      fields: {
        Parent: "Parent",
      },
    },
    LoadData: {
      fields: {
        Remark: "Remark",
      },
    },
    UserPermission: {
      fields: {
        Status: "Status",
        GroupUser: "Group User",
        note: "Note",
        unit: "Unit",
        active: "Active",
        inactive: "Inactive",
        Empno: "Employee",
        name: "Name",
        Division: "Unit",
        LastUpdate: "Last Updated",
        warnNotEdit: "Not edit found.",
        hTableEmpCode: "Code",
        hTableGroupUser: "Group User",
        Col_UpdateBy: "Updated By",
      },
    },
    RegisterTime: {
      fields: {
        Col_No: "No.",
        Col_Year: "Year",
        Col_SDate: "Start Date",
        Col_EDate: "End Date",
        Col_LastUpdate: "Last Updated",
        Col_UpdateBy: "Updated By",
        Col_Status: "Status",
        Status: "Status",
        Year: "Year",
        Active: "Active",
        Inactive: "Inactive",
        SDate: "Start Date",
        EDate: "End Date",
        Note: "Note",
        Frequency: "Frequency",
        RegisterTimeLine: "Register Timeline",
        TimeLineRound: "Timeline Round",
      },
    },
    Criteria: {
      fields: {
        Col_Order: "Order",
        Col_Year: "Year",
        Col_MainImpact: "Main Impact",
        Col_SubImp: "Sub Impact Name",
        Col_SubImpact: "Level",
        Col_LastUpdate: "Last Updated",
        Col_Status: "Status",
        Col_Note: "Note",
        Status: "Status",
        Year: "Year",
        Active: "Active",
        Inactive: "Inactive",
        Note: "Note",
        Order: "Order",
        CriteriaName: "Criteria Name",
        MainImpact_name: "Main Impact Name",
        SubCriteriaName: "Sub Impact Name",
        Col_UpdateBy: "Updated By",
      },
    },
    Likelihood: {
      fields: {
        Col_Order: "Order",
        Col_Year: "Year",
        Col_MainImpact: "Sub Impact",
        Col_SubImpact: "Likelihood Level",
        Col_LastUpdate: "Last Updated",
        Col_Status: "Status",
        Col_Note: "Note",
        Col_Level: "Level",
        Status: "Status",
        Year: "Year",
        Active: "Active",
        Inactive: "Inactive",
        Note: "Note",
        Order: "Order",
        LikelihoodName: "Likelihood Name",
        SubLikelihoodName: "Sub Likelihood Name",
        Col_UpdateBy: "Updated By",
      },
    },
    MasterType: {
      fields: {
        No: "No",
        Name: "Master Data Type Name",
        LastUpdate: "Last Updated",
        LastUpdateBy: "Updated By",
        Status: "Status",
        Order: "Order",
        SubType: "SubType",
        Active: "Active",
        Inactive: "Inactive",
        Note: "Note",
        Col_SubData: "Master Data",
      },
    },
    MasterData: {
      fields: {
        No: "No",
        Name: "Master Data Name",
        Code: "Code",
        LastUpdateBy: "Updated By",
        LastUpdate: "Last Updated",
        Status: "Status",
        Order: "Order",
        Active: "Active",
        Inactive: "Inactive",
        Note: "Note",
        Type: "Type",
        Unit: "Unit",
        Sub: "Sub",
        Col_Order: "Order",
        Col_SubData: "Sub Master Data",
        Col_LastUpdate: "Last Updated",
        Col_Status: "Status",
        Col_Note: "Description",
        Col_SubCode: "Sub Code",
        Col_Unitname: "Unit Name",
      },
    },
    ERM: {
      fields: {
        CorporateArea: "Corporate Area",
        DivSec: "Div./Sec.",
        RiskOwner: "Risk Owner",
        DivisionManager: "Division Manager",
        DepartmentManager: "Department Manager",
        RiskInformation: "Risk Information",
        RiskName: "Risk Name",
        RiskDescription: "Risk Description",
        RiskIdentification: "Risk Identification",
        RiskFactor: "Risk Factor",
        RiskArea: "Risk Area",
        RiskSource: "Risk Source",
        TopQty: "Top Quartile Objectives",
        Frequency: "Frequency",
        RiskCategorization: "Risk Categorization",
        Approval: "Approval",
        // BusinessProcessandOperation: "Business Process and Operation",
        RiskSubImpactCategory: "Risk Sub Impact Category",
        RiskLikelihoodCategory: "Risk Likelihood Category",
        QualitativeMeasurement: "Qualitative Measurement",
        InherentImpact: "Inherent Impact (1-4)",
        InherentImpact2: "Inherent Impact",
        ResidualImpact: "Residual Impact",
        Description: "Description",
        InherentLikelihood: "Inherent Likelihood",
        ResidualLikelihood: "Residual Likelihood",
        InherentRiskLevel: "Inherent Risk Level",
        ResidualRiskLevel: "Residual Risk Level",
        InherentRiskRating: "Inherent Risk Rating",
        ResidualRiskRating: "Residual Risk Rating",
        CurrentlyImpact: "Currently Impact",
        CurrentlyRiskLevel: "Currently Risk Level",
        CurrentlyRiskRating: "Currently Risk Rating",
        CurrentlyLikelihood: "Currently Likelihood",
        Treatment: "Treatment",
        ControlMitigation: "Control/Mitigation Plan",
        MyControl: "My Control",
        Control: "Control",
        OtherDivisionSection: "Other Division/Section",
        RiskOwnerName: "Risk Owner Name",
        RiskOwnerCode: "Risk Owner Code",
        DivisionSection: "Division/Section",
        Active: "Active",
        Inactive: "Inactive",
        Inherent: "Inherent",
        Residual: "Residual",
        Year: "Year",
        Month: "Month",
        SectionManager: "Section Manager",
        AddControl: "Add Control",
        Clear: "Clear",
        Status: "Status",
        Submit: "Submit & Send ERM Risk",
        SubmitRisk: "Submit & Send Risk",
        Save: "Save ERM Risk",
        SaveRisk: "Save Risk",
        Cancel: "Cancel",
        Process: "Process",
        SubProcess: "Sub Process",
        ContinuityRisk: "Continuity Risk",
        RiskConcernArea: "Risk Concern Area",
        SMConcerned: "SM Concerned",
        IIFConcerned: "IIF Concerned",
        StakeholderConcerned: "Stakeholder Concerned",
        OpExElementsConcerned: "OpEx Elements Concerned",
        Reject: "Reject",
        Col_RiskId: "Risk ID",
        Col_RiskName: "Risk Name",
        Col_DivSec: "Div./Sec.",
        Col_Currently: "Currently",
        Col_Frequency: "Frequency",
        Col_Year: "Year",
        Col_Inherent: "Inherent",
        Col_Residual: "Residual",
        Col_Control: "Control",
        Col_DeployDate: "Deploy Date",
        Col_Status: "Status",
        Col_ControlId: "Control ID",
        Col_ControlCode: "Control Code",
        Col_ControlName: "Control Name",
        Col_ControlDescription: "Control Description",
        Col_Date: "Date",
        Col_Comment: "Comment",
        ControlEffect: "Control Effectiveness",
        ProgressMitigationPlan: "Progress/Mitigation Plan",
        SaveAndSendEmail: "Save & Create New Record",
        SendToApprove: "SEND TO APPROVE",
      },
    },
    RiskControl: {
      fields: {
        Col_ControlId: "Control ID",
        Col_ControlCode: "Control Code",
        Col_ControlDescription: "Control Description",
        Col_ControlOwner: "Control Owner",
        Col_DivSec: "Div./Sec.",
        Col_Date: "Date",
        Col_Note: "Note",
        Col_CloseControl: "Closed Control",
        Col_Status: "Status",
        ControlEffect: "Control Effectiveness",
        ProgressMitigationPlan: "Progress/Mitigation Plan",
      },
    },
    LogHeader: {
      fields: {
        hLogTableActionName: "Action Name",
        hLogTableActionBy: "Action By",
        hLogTableActionDate: "Action Date",
        hLogTableActionBefore: "Before",
        hLogTableActionAfter: "After",
        hLogTableDetail: "Detail",
        hLogTableComment: "Comment",
      },
    },
    Email: {
      fields: {
        Year: "ปี", //Year
        Frequency: "Frequency",
        Round: "Round",
        ScheduleDate: "Schedule Date",
        Subject: "หัวข้อ", //Subject
        Description: "คำอธิบาย", //Description
        EmailToByRole: "E-Mail To By Role",
        EmailCCByRole: "E-Mail CC By Role",
        EmailBCCByRole: "E-Mail BCC By Role",
        EmailTo: "E-Mail To",
        EmailCC: "E-Mail CC",
        EmailBCC: "E-Mail BCC",
        Content: "Content E-mail",
        Status: "สถานะ", //Status
        Active: "ใช้งาน", //Active
        Inactive: "ไม่ใช้งาน", //Inactive
      },
    },
    Banner: {
      No: "ลำดับ",
      Employer: "ผู้ว่าจ้าง",
      Po: "หมายเลขใบสั่งซื้อ",
      Order: "หมายเลขออเดอร์",
      BannerDay: "ระยะเวลา(เดือน)",
      StartDate: "วันที่เริ่ม",
      EndDate: "วันที่สิ้นสุด",
      Position: "ตำแหน่งโฆษณา",
      BannerLink: "เชื่อมโยงไปยัง",
      Note: "หมายเหตุ",
      Status: "สถานะ",
      Active: " ใช้งาน",
      LastEapire: "หมดอายุล่าสุด",
      EmployerName: "ผู้ว่าจ้าง",
      OrderName: "ออเดอร์",
      Start_End: "เริ่ม - สิ้นสุด",
      Inactive: "ไม่ใช้งาน",
      AddNew: "เพิ่มตำแหน่งใหม่",
      Search: "ค้นหาและประวัติ",
      Expire: "วันหมดอายุ",
      Link: "ลิงก์",
      Statustb: "สถานะ",
      PoNo: "หมายเลขใบสั่งซื้อ",
      UploadFile: "อัปโหลดไฟล์",
      Start: "วันที่เริ่มต้น",
      Linkto: "เชื่อมโยงไปยัง",
      ActiveName: "ใช้งาน",
      InactiveName: "ไม่ใช้งาน",
      PostJob: "ตำแหน่งงาน"
    },
    Package: {
      DurationMonth: "ระยะเวลา (เดือน)",
      Duration: "ระยะเวลา",
      Month: " เดือน",
      Pricename: "ราคาแพ็กเกจ BANNER",
      Package: "แพ็กเกจ",
      Price: "ราคารวมทั้งสิ้น",
      Edit: "แก้ไข",
      Banner: "แพ็กเกจ BANNER",
      Coin: "แพ็กเกจ COIN",
      PackageName: "ชื่อแพ็กเกจ",
      CoinAmount: "จำนวน COIN ",
      ExDurationAmoun: " ระยะเวลาหมดอายุ (เดือน)",
      TotalPrice: "ราคารวมทั้งสิ้น",
      Save: "ส่วนลด",
      Savename: "ส่วนลด (%)",
      Vat: "ภาษี",
      Vatname: "ภาษีมูลค่าเพิ่ม 7%",
      NetPrice: "รวมเป็นเงิน",
      PriceAfterVat: "ราคารวมทั้งสิ้น",
      Note: "หมายเหตุ",
      StartDate: "วันที่เริ่ม",
      EndDate: "วันที่สิ้นสุด",
      Order: "ลำดับ",
      Job: "แพ็กเกจ JOB POST",
      Select: "เลือกแพ็กเกจ",
      JobPackage: "แพ็กเกจ JOB POST",
      CoinPackage: "แพ็กเกจ COIN ",
      BannerPackage: "แพ็กเกจ BANNER ",
      PostAmount: "จำนวน JOB POST",
      JobPostname: "แพ็กเกจ JOB POST ",
      Coinname: "แพ็กเกจ COIN",
      Banername: "แพ็กเกจ BANNER",
      Employer: "บริษัทว่าจ้าง :",
      Active: "สถานะ :",
      AllPackage: "แพ็กเกจ ทั้งหมด :",
      ActionDate: "วันที่แก้ไข",
      ActionBy: "แก้ไขโดย",
      ActionName: "ผู้แก้ไข",
      EditButt: "แก้ไข",
      JobPost: "Job Post",
      ExpiryDuration: "ระยะเวลา (เดือน)",
      CoinHeadName: "Coin",
      LastUpdate: "แก้ไขล่าสุด",
      AddButt: "เพิ่ม",
      Status: "สถานะ",
      StatusActive: "ใช้งาน",
      StatusInactive: "ไม่ใช้งาน",
      PostJob: "ตำแหน่งงาน",
      NormalPrice: "ราคาปกติ",
      CointAmount: "จำนวนเหรียญ",
      SpecialPrice: "ราคาพิเศษ",
      Net: "ราคาสุทธิ"
    },
    UserGroup: {
      Group: "กลุ่ม",
      FirstName: "ชื่อ - นามสกุล",
      LastName: "นามสกุล",
      UserName: "ชื่อผู้ใช้",
      Password: "รหัสผ่าน",
      Confirm: "ยืนยันรหัสผ่าน",
      Invalid: "ยืนยันรหัสผ่านหม่ถูกต้อง",
      Position: "ตำแหน่ง",
      Email: "อีเมล",
      Phone: "เบอร์โทรติดต่อ",
      Employer: "ค้นหารายชื่อผู้ประกอบการที่รับผิดชอบ",
      Status: "สถานะ",
      Active: "ใช้งาน",
      Inactive: "ไม่ใช้งาน",
      ResetPassword: "เปลี่ยนรหัสผ่าน",
      SelectIndustryType: "เลือกรายชื่อประเภทอุตสาหกรรมที่รับผิดชอบ",
      SelectAllIndustry: "เลือกทั้งหมด",
      GroupPermission: "สิทธิตามกลุ่ม",
      Number: "ลำดับ",
      Menu: "ชื่อเมนู",
      Disabled: "ไม่มีสิทธิ์เข้าถึง",
      ReadOnly: "ดูอย่างเดียว",
      Enabled: "เพิ่ม/ลบ/แก้ไข",
      BackButt: "ย้อนกลับ",
      SaveButt: "บันทึก",
      forgot_success: "ส่งคำขอสำเร็จ \nโปรดตรวจสอบที่อีเมลของคุณ",
    },

    AdminMaster: {
      NameTH: "ชื่อ (TH)",
      NameEN: "ชื่อ (ENG)",
      Datatype: "ประเภทข้อมูล (TH)",
      Note: "หมายเหตุ",
      Addnew: "เพิ่ม",
      AllList: "ทั้งหมด",
      Educationlevel: "ระดับการศึกษา",
      Employment: "ลักษณะการจ้าง",
      Group: "กลุ่มอุตสาหกรรม",
      Position: "ระดับปฏิบัติงาน",
      Region: "ภูมิภาค/กรุงเทพมหานคร",
      Province: "จังหวัด/เขต",
      Groupwork: "กลุ่มงาน",
      work: "งาน",
      travel: "การเดินทาง",
      benefit: "สวัสดิการ",
      country: "ประเทศ",
      WorkLocation: "สถานที่ทำงาน",
      salaryInfo: "เงินเดือน",
      Status: "สถานะ",
      Active: "ใช้งาน",
      Inactive: "ไม่ใช้งาน",
      BackButt: "ย้อนกลับ",
      SaveButt: "บันทึก",
      Institution: "สถาบันการศึกษา",

    },
    AdminMasterTable: {
      EditTable: "แก้ไข",
      Number: "ลำดับ",
      DataType: "ประเภทข้อมูล",
      NameTH: "ชื่อ (TH)",
      NameENG: "ชื่อ (ENG)",
      LastUpdate: "ปรับปรุงล่าสุด",
      Status: "สถานะ",
      StatusActive: "ใช้งาน",
      StatusInactive: "ไม่ใช้งาน",
    },
    GroupForm: {
      Number: "ลำดับ",
      GroupName: "ชื่อกลุ่มผู้ใช้งานระบบ",
      GroupNameTH: "(ชื่อกลุ่ม)",
      MenuName: "ชื่อเมนู",
      Permission: "กำหนดสิทธิ์การเข้าใช้งานระบบ",
      Disabled: "ไม่มีสิทธิ์เข้าถึง",
      ReadOnly: "อ่านเท่านั้น",
      Enabled: "เพิ่ม/ลบ/แก้ไข",
      SelectIndustryType: "เลือกรายชื่อประเภทอุตสาหกรรมที่รับผิดชอบ",
      SelectAll: "เลือกทั้งหมด",
      Status: "สถานะ",
      Active: "ใช้งาน",
      Inactive: "ไม่ใช้งาน",
      BackButt: "ย้อนกลับ",
      SaveButt: "บันทึก",
    },
    Candidate: {
      Data: "ข้อมูลสำหรับติดต่อ",
      Update: "อัปเดตเมื่อ",
      Time: "เวลา",
      Sex: "เพศ",
      Status: "สถานะ",
      NameTH: "ชื่อ",
      SurnameTH: "นามสกุล",
      NameEN: "Name",
      SurNameEN: "Surname",
      Mobile: " โทรศัพท์มือถือ",
      Email: "อีเมล",
      Socail: "ช่องทางติดต่อื่นๆ",
      WorkExperience: "ประสบการณ์",
      JobTitle: " ตำแหน่งงาน",
      Company: " บริษัท",
      StartWork: "เริ่มทำงาน",
      EndWork: " สิ้นสุดการทำงาน",
      JobPosition: "ตำแหน่งงานที่ต้องการ",
      JobFunction: "กลุ่มงาน",
      JobFunctionDetail: " งาน",
      Industry: " ประเภทอุตสาหกรรม",
      JobDescription: "รายละเอียดงาน",
      Education: "การศึกษา",
      Institution: " ชื่อสถาบันศึกษา",
      Qualificationlevel: "ระดับการศึกษา",
      Major: "สาขาวิชา",
      finishedyear: "ปีที่จบการศึกษา",
      CourseHighlights: "ผ่านหลักสูตร",
      IndividualData: "ประวัติส่วนตัว",
      Print: "สั่งพิมพ์",
      Nationality: "สัญชาติ",
      Country: "ประเทศ",
      Region: "ภูมิภาค/กรุงเทพมหานคร",
      Province:  "จังหวัด/เขต",
      District: "อำเภอ",
      LangugeSkill: "ความสามารถทางภาษา",
      Languge: "ภาษา",
      Skill: "ระดับทักษะ",
      ReadingSkill: "ทักษะการอ่าน",
      WritingSkill: "ทักษะการเขียน",
      SpeakingSkill: "ทักษะการพูด",
      OtherAbility: "ความสามารถอื่นๆ",
      Ability: "ความสามารถอื่นๆ",
      MoreData: "- ข้อมูลเพิ่มเติม",
      JobInterested: " ตำแหน่งงานที่สนใจ",
      EmployerType: "ลักษณะการทำงาน",
      MinMax: "ช่วงเงินเดือน",
      ExpectSalary: "เงินเดือนที่ต้องการ",
      License: "ความสามารถในการขับขี่",
      StartDate: "สามารถเริ่มงานได้",
      AttachFile: "เอกสารแนบ",
      ChangePassword: "เปลี่ยนรหัสผ่าน/Change Password",
      Subscrit:
        "การเลือกรับข่าวสารผ่านช่องทางอีเมลของเว็บไซต์ และบริษัทในเครือ",
      jobInformation: "ข้อมูลผู้สมัครงาน",
      Applyforwork: "งานที่สมัคร",
      Interestedjob: "งานที่สนใจ",
      History: "บริษัทที่ซื้อประวัติคุณ",
      Sendinvitation: "คำเชิญจากบริษัท",
      Recommended: "งานแนะนำ",
      Setting: "การตั้งค่าผู้สมัคร",
      NumberPost: "เลขที่ประกาศ",
      Job_TH: "ตำแหน่งงาน",
      Company_Name: "ชื่อผู้ประกอบการ",
      SendDate: "วันที่สมัคร",
      PostDate: "วันที่ลงประกาศ",
      ComfirmDate: "วันที่ตอบรับคำเชิญ",
      CreateDate: "วันที่สนใจ",
      Comfirm: "ตอบรับ/ปฎิเสธ",
      ComfirmRefuseDate: "วันที่ตอบรับ/ปฎิเสธ",
      SendInvitationDate: "ส่งคำเชิญเมื่อ",
      IndustryType_Name: "ประเภทอุตสาหกรรม",
      CountryName: "ประเทศ",
      Province_Name: "จังหวัด/เขต",
      Region_Name: "ภูมิภาค/กรุงเทพมหานคร",
      Consent:
        "การเลือกรับข่าวสารผ่านช่องทางอีเมลของเว็บไซต์ และบริษัทในเครือ",
      ConsentLine: "รับข่าวสารผ่านช่องทางอีเมลของเว็บไซต์",
      Keyword: "คีย์เวิร์ด",
      Certificate: "ใบอนุญาต หรือประกาศนียบัตร",
      Present: "ปัจจุบัน",
      ContactOther: "อื่นๆ",
      NotSpecific: "ไม่ระบุ",
      Age: "อายุ",
      ComputerSkill: "ทักษะทางคอมพิวเตอร์",
      AgeYear: "ปี",
      LeaveRemark: "สาเหตุที่ลาออกจากงาน",
      TitleConsent: "ความยินยอมจากบิดา/มารดา หรือผู้ปกครอง",
      ConsentLine3:
        "ขณะทำรายการมีอายุไม่ต่ำกว่า 20 ปี หรือได้รับความยินยอมจากผู้ปกครอง",
      Name: "ชื่อ - นามสกุล",
      ActiveName: "ใช้งาน",
      InactiveName: "ไม่ใช้งาน",
      PostDateName: "วันที่ซื้อโปรไฟล์",
      Phone: "เบอร์โทรติดต่อ",
      PostCode: "รหัสไปรษณีย์",
      Street: "ถนน",
      Moo: "หมู่",
      AddressNo: "เลขที่",
      title: "ดาวน์โหลดประวัติของผู้สมัคร",

    },
    OrderEmployer: {
      Package: "แพ็กเกจ",
      Invoice: "เอกสารใบแจ้งหนี้",
      Payment: "หลักฐานการชำระเงิน",
      Tax: "ใบกำกับภาษี"
    },
    LayoutFront: {
      sNameTH: "ทดสอบเมนู",
      signIn: "เข้าสู่ระบบ",
      signUp: "ลงทะเบียน",
      Register: "ลงทะเบียน",
      LoginText: "เข้าสู่ระบบ BPS Job Board Platform",
      OwnerLogin: "ผู้ประกอบการเข้าสู่ระบบ",
      JobSeeker: "สมาชิก",
      Employer: "ผู้ประกอบการ",
      forgot_success: "ส่งคำขอสำเร็จ \nโปรดตรวจสอบที่อีเมลของคุณ",
      RegisterJobSeeker: "ลงทะเบียนสมาชิก",
      RegisterEmployer: "ลงทะเบียนผู้ประกอบการ",
    },
    LoginForm: {
      Username: "อีเมล",
      Password: "รหัสผ่าน",
      BackButt: "ย้อนกลับ",
      FotgetPassword: "ลืมรหัสผ่าน?",
      RememberMe: "ให้ฉันอยู่ในระบบต่อไป",
      LoginButt: "เข้าสู่ระบบ",
      Or: "หรือ",
      SignUp: "ลงทะเบียน",
      Register: "ลงทะเบียน",
      AnotherWay: "ด้วยวิธีอื่น?",
    },
    GroupFormLog: {
      ActionDate: "วันที่แก้ไข",
      ActionName: "สถานะ",
      ActionBy: "แก้ไขโดย",
    },
    AdminLog: {
      ActionDate: "วันที่แก้ไข",
      ActionName: "สถานะ",
      ActionBy: "แก้ไขโดย",
    },
    AdminUser: {
      AddButt: "เพิ่ม",
      EditButt: "แก้ไข",
      Number: "ลำดับ",
      Name: "ชื่อ-นามสกุล",
      GroupName: "ชื่อกลุ่มผู้ใช้งานระบบ",
      Email: "อีเมล",
      PhoneNumber: "เบอร์โทรศัพท์",
      UpdateBy: "แก้ไขโดย",
      UpdateDate: "วันที่แก้ไข",
      Status: "สถานะ",
      Active: "ใช้งาน",
      Inactive: "ไม่ใช้งาน",
    },
    AdminContent: {
      AddButt: "เพิ่ม",
      EditButt: "แก้ไข",
      Number: "ลำดับ",
      PageName: "ชื่อหน้า",
      CreateBy: "ผู้สร้าง",
      CreateDate: "วันที่สร้าง",
      UpdateDate: "วันที่แก้ไข",
      Content: "เนื้อหา",
      ParagraphAdding: "เพิ่มเนื้อหา",
      BackButt: "ย้อนกลับ",
      PreviewButt: "ดูตัวอย่าง",
      SaveButt: "บันทึก",
      ContentText: "ข้อความ",
      PictureText: "รูปภาพ",
      RemoveButt: "ลบ",
      TextUnderPic: "ข้อความใต้ภาพ",
      TextUnderVideo: "ข้อความใต้วิดีโอ",
    },
    AdminCandidate: {
      ViewDetail: "ดูรายละเอียด",
      JobSeekerNumber: "หมายเลขผู้สมัคร",
      FullName: "ชื่อผู้สมัครงาน",
      WorkName: "กลุ่มงาน/งาน",
      Email: "อีเมล",
      PhoneNumber: "เบอร์ติดต่อ",
      UpdateDate: "วันทีปรับปรุง",
      ActiveName: "ใช้งาน",
      InactiveName: "ไม่ใช้งาน",
      Status: "สถานะ",
      forgot_success: "ส่งคำขอสำเร็จ \nโปรดตรวจสอบที่อีเมลของคุณ",
    },
    GroupTable: {
      AddButt: "เพิ่ม",
      EditButt: "แก้ไข",
      GroupName: "ชื่อกลุ่มผู้ใช้งานระบบ",
      Status: "สถานะ",
      Active: "ใช้งาน",
      Inactive: "ไม่ใช้งาน",
      UserNameCount: "ผู้ใช้งาน",
      DataIndustryCount: "ประเภทอุตสาหกรรม",
      UpdateDate: "ปรับปรุงล่าสุด",
      Terms: "ข้อกำหนดและเงื่อนไข",
      Showcolumns: "คอลัมน์"
    },
    Admincompany: {
      CompanyNo: "เลขที่ผู้ประกอบการ",
      CompanyName: "ชื่อผู้ประกอบการ",
      IndustryType: "ประเภทอุตสาหกรรม",
      ProvAndDist: "จังหวัด/เขต",
      UpdateDate: "วันที่ปรับปรุง",
      ActiveName: "ใช้งาน",
      InactiveName: "ไม่ใช้งาน",
      Region: "ภูมิภาค/กรุงเทพมหานคร"
    },
  },

  dialog: {
    DialogConfirm: "ยืนยัน",
    DialogWarning: "แจ้งเตือน",
    CloseButt: "ปิด",
    DialogSuccess: "สำเร็จ",
    DialogRemoveSuccess: "ลบสำเร็จ",
    DialogSpecify: "กรุณาระบุเนื้อหา",
    Terms: "ข้อกำหนดและเงื่อนไข",
    privacy: "นโยบายความเป็นส่วนตัว",
    Search: "ค้นหา",
    Showcolumns: "คอลัมน์",
    Searchcolumns: "ค้นหาคอลัมน์",
    Hide: "ซ่อนทั้งหมด",
    ShowAll: "แสดงทั้งหมด",
    Select: "เลือก",
    Clearfilters: "Clear filters",
    Perpage: "หน้า",
    Goto: "ไปที่"
  },

  CompanyEmployee: {
    Name: "ชื่อ-นามสกุล",
    Email: "อีเมล",
    Role: "บทบาทหน้าที่",
    Status: "สถานะ",
    InviteDate: "วันที่ส่งคำเชิญ",
    LastUse: "วันที่เข้าใช้งานล่าสุด",
    Inactive: "ไม่ใช้งาน",
    Active: "ใช้งาน",
    SendRequest: "ส่งคำเชิญ",
    sendemail: "ส่งคำเชิญ",
    admin: "ผู้ดูแลระบบ",
    user: "ผู้ใช้งาน",
    adminpermission: "ผู้ดูแลระบบ จะได้รับสิทธิ์เข้าใช้งานทุกเมนู",
    assignpermission: "กำหนดสิทธิ์ต่างๆให้กับผู้ใช้งาน",
    postjob: "ประกาศงาน",
    postjobDetail: "สามารถดู แก้ไข และประกาศงานได้",
    jobseeker: "ค้นหาและจัดการผู้สมัครงาน",
    jobseekerDetail: " สามารถค้นหาผู้สมัครงานและจัดการผู้สมัครงานได้ทั้งเมนูค้นหาผู้สมัครงานและผู้สมัครงาน",
    searchjobseeker: "ค้นหาผู้ใช้งาน",
    searchjobseekerDetail: " ติดต่อผู้หางานใน 'ค้นหาผู้สมัคร' รวมถึงส่งตำแหน่งงาน",
    buyandreportpayment: "ซื้อและแจ้งรายการชำระเงิน",
    buyandreportpaymentDetail: " สามารถซื้อแพ็กเกจออนไลน์ ดูใบแจ้งข้อมูล สรุปรายละเอียด Account",
    RegisterMemberName: "ชื่อ - นามสกุลสมาชิก",
    phonenumber: "เบอร์โทรศัพท์",
    memberinfo: "ข้อมูลสมาชิก"
  },

  navbar: {
    Name: "a",
  },

  file: {
    UploadFile: {
      UploadFile: "อัปโหลดไฟล์",
      AllowFile: "ประเภทไฟล์ที่สามารถอัปโหลดได้: ",
      FileLimited: "ขนาดไฟล์ต้องไม่เกิน ",
      fileCountLimit: "อัปโหลดสูงสุดได้",
      recomandBannerSize: "ขนาดแบนเนอร์ที่แนะนำคือ ",
      recomandLogoSize: "ขนาดโลโก้ที่แนะนำคือ ",
      profilepicSize: "ขนาดรรูปที่แนะนำคือ",
      UploadFileOnly: "อัปโหลดไฟล์ได้แค่ประเภท",
      recomandNoLogo: " หากไม่เพิ่มโลโก้จะไม่สามารถคลิกเพื่อไปหน้าประวัติของบริษัทได้",
      Terms: "ข้อกำหนดและเงื่อนไข",
    },
  },

  errors: {
    backToHome: "กลับหน้าหลัก", //Back to home
    403: `คุณไม่สามารถเข้าถึงหน้านี้ได้`,
    404: "ไม่พบหน้าที่คุณต้องการ",
    500: "เซิร์ฟเวอร์ทำงานผิดพลาด",
    429: "คำขอมีมากเกินไป โปรดลองอีกครั้งในภายหลัง",
    forbidden: {
      message: "Forbidden",
    },
    validation: {
      message: "เกิดข้อผิดพลาด",
    },
    defaultErrorMessage: "เกิดข้อผิดพลาด", //Ops, an error occurred
  },
  // See https://github.com/jquense/yup#using-a-custom-locale-dictionary
  /* eslint-disable */
  validation: {
    mixed: {
      default: "${path}",
      required: "${path}",
      oneOf: "${path} ต้องเป็นหนึ่งในค่าต่อไปนี้: ${values}", //must be one of the following values:
      notOneOf: "${path} ต้องไม่ใช่ค่าต่อไปนี้ ${values}", //must not be one of the following values:
      notType: ({ path, type, value, originalValue }) => {
        return `${path} must be a ${type}`;
      },
    },
    string: {
      // length: "${path} must be exactly ${length} characters",
      // min: "${path} must be at least ${min} characters",
      // max: "${path} must be at most ${max} characters",
      // matches: '${path} must match the following: "${regex}"',
      // email: "${path} must be a valid email",
      // url: "${path} must be a valid URL",
      // trim: "${path} must be a trimmed string",
      // lowercase: "${path} must be a lowercase string",
      // uppercase: "${path} must be a upper case string",
      // selected: "${path} must be selected",

      length: "${path} must be exactly ${length} characters",
      min: "${path} must be at least ${min} characters",
      max: "${path} must be at most ${max} characters",
      matches: '${path} must match the following: "${regex}"',
      email: "กรุณาระบุอีเมลที่ถูกต้อง",
      url: "${path} must be a valid URL",
      trim: "${path} must be a trimmed string",
      lowercase: "${path} ต้องเป็นตัวพิมพ์เล็ก",
      uppercase: "${path} ต้องเป็นตัวพิมพ์ใหญ่",
      selected: "กรุณาเลือก ${path}",
      required: "กรุณาระบุข้อมูล ${path}",
      Password:
        "รหัสผ่านต้องมีอักขระอย่างน้อย 8 ตัวและประกอบด้วยสิ่งต่อไปนี้ทั้งหมด: ตัวพิมพ์ใหญ่ ตัวพิมพ์เล็ก ตัวเลข สัญลักษณ์ (สัญลักษณ์สามารถใช้ !, @, #, $, %, ^, &, *)",
      CPassword: "การยืนยันรหัสผ่านไม่ถูกต้อง",
    },
    number: {
      min: "${path} ต้องมากกว่าหรือเท่ากับ ${min}",
      max: "${path} ต้องน้อยกว่าหรือเท่ากับ ${max}",
      lessThan: "${path} ต้องน้อยกว่า ${less}",
      moreThan: "${path} ต้องมากกว่า ${more}",
      notEqual: "${path} ต้องไม่เท่ากับ ${notEqual}",
      positive: "${path} ต้องเป็นจำนวนบวก",
      negative: "${path} ต้องเป็นจำนวนลบ",
      integer: "${path} ต้องเป็นตัวเลขเท่านั้น",
    },
    date: {
      min: "${path} ต้องมากกว่า ${min}", //${path} field must be later than ${min}
      max: "${path} ต้องน้อยกว่า ${max}", //${path} field must be at earlier than ${max}
    },
    decimal: {
      min: "${path} ต้องมากกว่า ${min}",
      max: "${path} ต้องน้อยกว่า ${max}",
    },
    boolean: {},
    object: {
      required: "กรุณาระบุ ${path}",
      noUnknown:
        "${path} field cannot have keys not specified in the object shape",
    },
    array: {
      min: ({ min, path }) =>
        min === 1
          ? `กรุณากรอกข้อมูล ${path}`
          : `กรุณาระบุ ${path} ขั้นต่ำ  ${min}`, //${path} field must have at least ${min} items
      max: "กรุณาระบุ ${path} ไม่เกิน  ${max}", //${path} field must have less than or equal to ${max} items
    },
  },
  integer: {
    min: " กรุณาระบุ ${path} ขั้นต่ำ  ${min} ",
    max: "กรุณาระบุ ${path} ไม่เกิน  ${max} ",
    required: "กรุณากรอกข้อมูล ${path}",
  },
  autocomplete: {
    loading: "Loading...",
    noOptions: "ไม่พบข้อมูล", //No data found
  },

  table: {
    // noData: "No records found",
    noData: "ไม่พบข้อมูล",
    loading: "Loading...",
  },

  pagination: {
    labelDisplayedRows: "{0}-{1} ของ {2}", //{0}-{1} of {2}
    labelRowsPerPage: "จำนวนแถว:", //Per page:
    of: "ของ", //of
    Goto: "ไปที่"
  },
  cookie: {
    text: "เว็บไซต์นี้ใช้คุกกี้เพื่อปรับปรุงประสบการณ์ผู้ใช้ หากต้องการข้อมูลเพิ่มเติมโปรดอ่าน",
    policy: "นโยบายความเป็นส่วนตัว",
    cookieButton: "ยอมรับ"
  }
};

export default th;
