import { Box, Button, Grid, Tab, Tabs, Tooltip } from "@mui/material";
import Admin_MasterData from "components/BPS/BPS_Admin/Admin_MasterData";
import Navigate_Layout_BPS from "layout/CommonLayout/Layout_Back_BPS/Navigate_Layout_BPS";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AxiosGet, GetPermission } from "service/CommonFunction/TS_function";
import { i18n } from 'i18n';
import { useHistory } from "react-router";
import { FnBlock_UI } from "service/SystemFunction/SystemFunction";
import { FilterFieldInterface, initRows, PaginationInterface } from "components/Common/Table/DataGridMui";
import { BtnRadius } from "components/Common/Button";

function TabsClick(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Admin_Table(props) {
  const i18nField = 'entities.AdminMaster';
  const navigate = useNavigate();

  const [nDataType_ID, setnDataType_ID] = useState(0);
  const isHead = nDataType_ID == 6 || nDataType_ID == 8 ? true : false;
  const [nPermission, setPermission] = useState(0);
  const [arrData, setarrData] = useState({} as any);
  const { BlockUI, UnBlockUI } = FnBlock_UI();
  const location = useLocation();
  const searchParams = location.search;
  const sID = new URLSearchParams(searchParams).get('sID');
  const [dataRow, setDataRow] = useState<PaginationInterface>({
    ...initRows,
    sSortExpression: "nOrder",
    sSortDirection: "asc"
  })

  useEffect(() => {
    GetPermission(1, setPermission);
    if (sID) {
      setnDataType_ID(parseInt(sID));
    }
  }, [])

  const handleChange = (event, nNewValue: number) => {

    setnDataType_ID(nNewValue);
    setDataRow({
      ...initRows,
      sSortExpression: "nOrder",
      sSortDirection: "asc"
    })
  };

  const onCreate = () => {
    navigate("/AdminForm_BPS");
    // this.props.navigate.push('/AdminForm_BPS')
  }

  useEffect(() => {
    onGetdata();

  }, []);

  const onGetdata = () => {
    AxiosGet(`AdminBPS/SetFilter_SelectOption`, {}, (res) => {
      setarrData(res.oTotaldata || {});
    }
    );
  };

  // go to table component 
  const Memo = useMemo(() => {
    return <Admin_MasterData dataRow={dataRow} setDataRow={setDataRow} nDataType_ID={nDataType_ID} onGetdata={onGetdata} />
  }, [nDataType_ID, onGetdata])

  return (
    <Grid container>
      <Grid item className="table" sx={{ mx: "2%", width: "100%" }}>
        {/* Add button */}
        {nPermission == 2 ? (
          <Grid item xs={2} >
            <BtnRadius onClick={() => onCreate()} txt={'เพิ่ม'} />
          </Grid>

        ) : (
          <>
          </>
        )}
        {/* End Add button */}

        {/* tab */}
        <Grid item sx={{ borderRadius: 5, boxShadow: 3, bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'), marginBottom: "2%" }}>

          <Tabs value={nDataType_ID} onChange={handleChange} textColor="primary" allowScrollButtonsMobile variant="scrollable" TabIndicatorProps={{ style: { display: "none" } }} >
            <Tab
              label={
                nDataType_ID == 0 ? //ทั้งหมด
                  <span style={{ color: '#f9fafd' }}>{"ทั้งหมด"}</span> :
                  <span style={{ color: '#474747' }}>{"ทั้งหมด"}</span>}
              {...TabsClick(0)}
              sx={{ flex: 1, borderRadius: 5, backgroundColor: nDataType_ID == 0 ? "#003dc6" : "#fff", fontSize: 13 }}
              value={0}
            />

            <Tab
              label={
                nDataType_ID == 1 ? //ระดับการศึกษา
                  <p style={{ color: '#f9fafd' }}>{"ระดับการศึกษา"} <br /> [{arrData.nEducation ? arrData.nEducation : 0}]</p> :
                  <p style={{ color: '#474747' }}>{"ระดับการศึกษา"} <br /> [{arrData.nEducation ? arrData.nEducation : 0}]</p>}
              {...TabsClick(1)}
              sx={{ flex: 1, borderRadius: 5, backgroundColor: nDataType_ID == 1 ? "#003dc6" : "#fff", fontSize: 13 }}
              value={1}
            />

            <Tab
              label={
                nDataType_ID == 2 ? //ลักษณะการจ้าง
                  <p style={{ color: '#f9fafd' }}>{"ลักษณะการจ้าง"} <br /> [{arrData.nEmployment ? arrData.nEmployment : 0}]</p> :
                  <p style={{ color: '#474747' }}>{"ลักษณะการจ้าง"} <br /> [{arrData.nEmployment ? arrData.nEmployment : 0}]</p>}
              {...TabsClick(2)}
              sx={{ flex: 1, borderRadius: 5, backgroundColor: nDataType_ID == 2 ? "#003dc6" : "#fff", fontSize: 13 }}
              value={2}
            />
            <Tab
              label={
                nDataType_ID == 3 ? //กลุ่มอุตสาหกรรม
                  <p style={{ color: '#f9fafd' }}>{"กลุ่มอุตสาหกรรม"} <br /> [{arrData.nIndustry ? arrData.nIndustry : 0}]</p> :
                  <p style={{ color: '#474747' }}>{"กลุ่มอุตสาหกรรม"} <br /> [{arrData.nIndustry ? arrData.nIndustry : 0}]</p>}
              {...TabsClick(3)}
              sx={{ flex: 1, borderRadius: 5, backgroundColor: nDataType_ID == 3 ? "#003dc6" : "#fff", fontSize: 13 }}
              value={3}
            />
            <Tab
              label={
                nDataType_ID == 4 ? //ระดับปฏิบัติงาน
                  <p style={{ color: '#f9fafd' }}>{"ระดับปฏิบัติงาน"} <br /> [{arrData.nPosition ? arrData.nPosition : 0}]</p> :
                  <p style={{ color: '#474747' }}>{"ระดับปฏิบัติงาน"} <br /> [{arrData.nPosition ? arrData.nPosition : 0}]</p>}
              {...TabsClick(4)}
              sx={{ wflex: 1, borderRadius: 5, backgroundColor: nDataType_ID == 4 ? "#003dc6" : "#fff", fontSize: 13 }}
              value={4}
            />
            <Tab
              label={
                nDataType_ID == 5 ? //ภูมิภาค
                  <p style={{ color: '#f9fafd' }}>{"ภูมิภาค/กรุงเทพมหานคร"} <br /> [{arrData.nRegion ? arrData.nRegion : 0}]</p> :
                  <p style={{ color: '#474747' }}>{"ภูมิภาค/กรุงเทพมหานคร"} <br /> [{arrData.nRegion ? arrData.nRegion : 0}]</p>}
              {...TabsClick(5)}
              sx={{ flex: 1, borderRadius: 5, backgroundColor: nDataType_ID == 5 ? "#003dc6" : "#fff", fontSize: 13 }}
              value={5}
            />
            <Tab
              label={
                nDataType_ID == 6 ? //จังหวัด/อำเภอ
                  <p style={{ color: '#f9fafd' }}>{"จังหวัด/เขต"} <br /> [{arrData.nProvince ? arrData.nProvince : 0}]</p> :
                  <p style={{ color: '#474747' }}>{"จังหวัด/เขต"} <br /> [{arrData.nProvince ? arrData.nProvince : 0}]</p>}
              {...TabsClick(6)}
              sx={{ flex: 1, borderRadius: 5, backgroundColor: nDataType_ID == 6 ? "#003dc6" : "#fff", fontSize: 13 }}
              value={6}
            />
            <Tab
              label={
                nDataType_ID == 7 ? //กลุ่มงาน   
                  <p style={{ color: '#f9fafd' }}>{"กลุ่มงาน"} <br /> [{arrData.nWorkGroup ? arrData.nWorkGroup : 0}]</p> :
                  <p style={{ color: '#474747' }}>{"กลุ่มงาน"} <br /> [{arrData.nWorkGroup ? arrData.nWorkGroup : 0}]</p>}
              {...TabsClick(7)}
              sx={{ flex: 1, borderRadius: 5, backgroundColor: nDataType_ID == 7 ? "#003dc6" : "#fff", fontSize: 13 }}
              value={7}
            />
            <Tab
              label={
                nDataType_ID == 8 ? //งาน
                  <p style={{ color: '#f9fafd' }}>{"งาน"} <br /> [{arrData.nWork ? arrData.nWork : 0}]</p> :
                  <p style={{ color: '#474747' }}>{"งาน"} <br /> [{arrData.nWork ? arrData.nWork : 0}]</p>}
              {...TabsClick(8)}
              sx={{ flex: 1, borderRadius: 5, backgroundColor: nDataType_ID == 8 ? "#003dc6" : "#fff", fontSize: 13 }}
              value={8}
            />
            <Tab
              label={
                nDataType_ID == 9 ? //การเดินทาง
                  <p style={{ color: '#f9fafd' }}>{"การเดินทาง"} <br /> [{arrData.nTravel ? arrData.nTravel : 0}]</p> :
                  <p style={{ color: '#474747' }}>{"การเดินทาง"} <br /> [{arrData.nTravel ? arrData.nTravel : 0}]</p>}
              {...TabsClick(9)}
              sx={{ flex: 1, borderRadius: 5, backgroundColor: nDataType_ID == 9 ? "#003dc6" : "#fff", fontSize: 13 }}
              value={9}
            />
            <Tab
              label={
                nDataType_ID == 10 ? //สวัสดิการ
                  <p style={{ color: '#f9fafd' }}>{"สวัสดิการ"} <br /> [{arrData.nBenefit ? arrData.nBenefit : 0}]</p> :
                  <p style={{ color: '#474747' }}>{"สวัสดิการ"} <br /> [{arrData.nBenefit ? arrData.nBenefit : 0}]</p>}
              {...TabsClick(10)}
              sx={{ flex: 1, borderRadius: 5, backgroundColor: nDataType_ID == 10 ? "#003dc6" : "#fff", fontSize: 13 }}
              value={10}
            />
            <Tab
              label={
                nDataType_ID == 11 ? //ประเทศ
                  <p style={{ color: '#f9fafd' }}>{"ประเทศ"} <br /> [{arrData.nCountry ? arrData.nCountry : 0}]</p> :
                  <p style={{ color: '#474747' }}>{"ประเทศ"} <br /> [{arrData.nCountry ? arrData.nCountry : 0}]</p>}
              {...TabsClick(11)}
              sx={{ flex: 1, borderRadius: 5, backgroundColor: nDataType_ID == 11 ? "#003dc6" : "#fff", fontSize: 13 }}
              value={11}
            />
            <Tab
              label={
                nDataType_ID == 13 ? //เงินเดือน
                  <p style={{ color: '#f9fafd' }}>{"เงินเดือน"} <br /> [{arrData.nSalaryInfo ? arrData.nSalaryInfo : 0}]</p> :
                  <p style={{ color: '#474747' }}>{"เงินเดือน"} <br /> [{arrData.nSalaryInfo ? arrData.nSalaryInfo : 0}]</p>}
              {...TabsClick(12)}
              sx={{ flex: 1, borderRadius: 5, backgroundColor: nDataType_ID == 13 ? "#003dc6" : "#fff", fontSize: 13 }}
              value={13}
            />
            <Tab
              label={
                nDataType_ID == 16 ? //เงินเดือน
                  <p style={{ color: '#f9fafd' }}>{"สถาบันการศึกษา"} <br /> [{arrData.nInstitution ? arrData.nInstitution : 0}]</p> :
                  <p style={{ color: '#474747' }}>{"สถาบันการศึกษา"} <br /> [{arrData.nInstitution ? arrData.nInstitution : 0}]</p>}
              {...TabsClick(12)}
              sx={{ flex: 1, borderRadius: 5, backgroundColor: nDataType_ID == 16 ? "#003dc6" : "#fff", fontSize: 13 }}
              value={16}
            />
          </Tabs>

        </Grid>
        {/* end tab */}

        {/* <Admin_MasterData nDataType_ID={nDataType_ID} onGetdata={onGetdata} /> */}
        {Memo} {/*  go to table  */}

      </Grid>
    </Grid >
  );
}