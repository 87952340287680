import JobPostModify from "components/JobPost/JobPostModify"

const JobPostModifyView = () => {
    return (
        <>
            <JobPostModify />
        </>
    )
}

export default JobPostModifyView