import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  FormHelperText,
  OutlinedInput,
  SxProps,
} from "@mui/material";
import { useFormContext } from "react-hook-form";
import PropTypes from "prop-types";
export default function InputNumber(props) {
  const {
    label,
    name,
    labelString,
    sx,
    required,
    fullWidth,
    type,
    autoFocus,
    prefix,
    disabled,
    onKeyDown,
    onKeyUp,
    onKeyPress,
    onBlur,
    autoComplete,
    onChange,
    startAdornment,
    endAdornment,
    maxRow,
    multiline,
    rows,
    id,
    margin,
    maxLength,
    small,
    onPaste,
    shrink,
    sxLabel,
    hidden,
    rounded,
    isNumberOnly,
    decimalPoint,
  } = props;

  const {
    register,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useFormContext();

  const [sValueText, setValueText] = useState<string>("");

  useEffect(() => {
    let sValueSet = getValues(name);
    if (sValueSet || (typeof sValueSet != "string" && sValueSet == 0)) {
      if (typeof sValueSet == "number") {
        setValueText(checkValueSet(sValueSet.toString()));
      } else {
        setValueText(checkValueSet(sValueSet));
      }
    } else {
      setValueText(null)
    }
  }, [watch(name)]);

  const checkValueSet = (sValue) => {
    var txt = /\B(?=(\d{3})+(?!\d))/g;
    let sValueRegex = sValue;
    if (decimalPoint == 0) {
      sValueRegex = sValueRegex.replace(regexNumber, "");
      sValueRegex = sValueRegex.replace(txt, ",");
    } else {
      sValueRegex = sValueRegex.replace(regexDecimal, "");
      let convertToFloat = parseFloat(sValueRegex);
      if (convertToFloat) {
        sValueRegex = convertToFloat
          .toFixed(decimalPoint)
          .replace(txt, ",");
      }
    }
    return sValueRegex;
  };

  const maxLengthText: SxProps = {
    "::after": {
      content: maxLength
        ? `"${sValueText ? sValueText.length : 0}/${maxLength}"`
        : '""',
      position: "absolute",
      bottom: "2px",
      right: "10px",
      color: "#989898",
      fontSize: "10px",
    },
  };

  const regexNumber = new RegExp("[^0-9]", "g");
  const regexDecimal = new RegExp("[^0-9.]", "g");

  return (
    <FormControl
      sx={{
        width: "100%",
      }}
      hiddenLabel={hidden || false}
      disabled={disabled}
      size={small ? "small" : "medium"}
    >
      <InputLabel
        hidden={hidden || false}
        htmlFor="input"
        shrink={shrink}
        sx={{
          "::after": {
            content: required ? '" * "' : '""',
            color: "red",
            fontWeight: 500,
          },
          color: errors && errors[name] ? "red !important" : "",
          ...sxLabel,
        }}
      >
        {label}
      </InputLabel>
      <OutlinedInput
        hidden={hidden || false}
        inputRef={register(name).ref}
        {...register(name)}
        type={type}
        fullWidth={fullWidth}
        required={required}
        onPaste={onPaste || undefined}
        error={Boolean(errors[name])}
        name={name}
        size={small ? "small" : "medium"}
        label={labelString ? labelString : label}
        sx={[{ ...maxLengthText }, sx]}
        autoFocus={autoFocus}
        prefix={prefix || undefined}
        disabled={disabled}
        onKeyDown={(e) => {
          //   if (e.key == "enter" && !multiline)
          //     setValue(name, e.currentTarget.value, { shouldValidate: true });
          onKeyDown && onKeyDown(e);
        }}
        onKeyUp={onKeyUp || undefined}
        onKeyPress={onKeyPress || undefined}
        onBlur={(e) => {
          var txt = /\B(?=(\d{3})+(?!\d))/g;
          let sValueRegex = e.target.value;
          if (decimalPoint == 0) {
            if (sValueRegex || sValueRegex == "0") {
              sValueRegex = sValueRegex.replace(txt, ",");
              setValue(name, parseInt(e.target.value.replaceAll(",", "")), { shouldValidate: true });
            } else {
              setValue(name, null, { shouldValidate: true });
            }
          } else {
            let convertToFloat = parseFloat(sValueRegex.replaceAll(",", ""));
            if (convertToFloat || (typeof convertToFloat == "number" && convertToFloat == 0)) {
              let convertToStringCheckDigit = convertToFloat
                .toFixed(decimalPoint);
              sValueRegex = convertToStringCheckDigit
                .replace(txt, ",");
              setValueText(sValueRegex);
              setValue(name, parseFloat(convertToStringCheckDigit), { shouldValidate: true });
            } else {
              setValue(name, null, { shouldValidate: true });
            }
          }
          onBlur && onBlur(e);
        }}
        autoComplete={autoComplete || "new-password"}
        onChange={(e) => {
          let sValueRegex = e.target.value;
          if (decimalPoint == 0) {
            sValueRegex = sValueRegex.replace(regexNumber, "");
          } else {
            sValueRegex = sValueRegex.replace(regexDecimal, "");
          }
          setValueText(sValueRegex);
          onChange && onChange(sValueRegex);
        }}
        startAdornment={startAdornment || undefined}
        endAdornment={endAdornment || undefined}
        maxRows={maxRow || undefined}
        value={sValueText || ""}
        multiline={multiline || undefined}
        rows={rows || undefined}
        id={id}
        margin={margin}
        inputProps={{
          type: isNumberOnly ? "number" : type,
          style: { textAlign: "end" },
          maxLength: maxLength || 250,
        }}
      />
      {errors && errors[name] ? (
        <FormHelperText sx={{ color: "red !Important" }}>
          {errors[name].message}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
}

InputNumber.defaultProps = {
  type: "text",
  required: false,
  disabled: false,
  small: true,
  isNumberOnly: false,
  decimalPoint: 0,
  autoComplete: "off",
};

InputNumber.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  type: PropTypes.string,
  label: PropTypes.any,
  labelString: PropTypes.string,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  sx: PropTypes.any,
  prefix: PropTypes.string,
  onKeyDown: PropTypes.any,
  onKeyPress: PropTypes.any,
  onKeyUp: PropTypes.any,
  onBlur: PropTypes.any,
  autoComplete: PropTypes.string,
  onChange: PropTypes.func,
  startAdornment: PropTypes.any,
  endAdornment: PropTypes.any,
  maxRow: PropTypes.number,
  maxLength: PropTypes.number,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  id: PropTypes.string,
  margin: PropTypes.string,
  fullWidth: PropTypes.bool,
  small: PropTypes.bool,
  onPaste: PropTypes.func,
  shrink: PropTypes.bool,
  hidden: PropTypes.bool,
  rounded: PropTypes.bool,
  sxLabel: PropTypes.any,
  decimalPoint: PropTypes.number,
};
