import { useState, useEffect, useContext } from "react";
import { FormProvider } from "react-hook-form";
import {
  Grid,
  Typography,
  Box,
  Paper,
  IconButton,
  FormControlLabel,
  Checkbox,
  FormHelperText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { i18n } from "i18n";
import Input from "components/Common/ElementInForm/InputCustomLabelFormItem";
import AutoCompleteSelect from "components/Common/ElementInForm/AutoCompleteSelect";
import { ProfileContext } from "./Context/ProfileContext";
import { IPropsMyJobApply } from "./Interface";
import { DialogActionCreators } from "store/redux/DialogAlert";
import { useDispatch } from "react-redux";
import { AxiosGet } from "service/CommonFunction/TS_function";
import InputNumber from "components/Common/ElementInForm/InputNumber";
import secureLocalStorage from "react-secure-storage";

const i18nField = "entities.SeekerProfile";
const i18nCommon = "common";
const MyJobPositon = (props: IPropsMyJobApply) => {
  const { item, index, form } = props;
  const dispatch = useDispatch();
  const {
    jobPositionContext,
    setJobPosition,
    optionPosition,
    optionJobFunction,
    optionJobDetail,
    setOptionJobDeatail,
    optionJobIndustry,
    optionJobType,
  } = useContext(ProfileContext);

  const [isClean, setIsClean] = useState(false);
  const [isSpeccified, setIsSpeccified] = useState(false);
  const [rawJobDetail, setRawJobDetail] = useState([]);
  const [isInvalidSalaryEnd, setIsInvalidSalaryEnd] = useState(false);
  const [Parent, setParent] = useState(0);

  const lang = secureLocalStorage.getItem("language") || "th";
  const fetchJobDetail = () => {
    AxiosGet("DataCollect/GetJobDetail", {}, (res) => {
      setRawJobDetail(res.Data);
    });
  };

  const handleSetData = (itemIndex) => {
    jobPositionContext.forEach((data) => {
      if (data.Index === itemIndex) {
        form.setValue("ApplyPosition" + data.Index, data.ApplyPosition || "");
        form.setValue(
          "ApplyJobFunction" + data.Index,
          data.ApplyJobFunction || ""
        );
        form.setValue("ApplyJobDetail" + data.Index, data.ApplyJobDetail || "");
        form.setValue("ApplyJobType" + data.Index, data.ApplyJobType || "");
        form.setValue(
          "ApplyIndustryType" + data.Index,
          data.ApplyIndustryType || null
        );
        form.setValue(
          "ApplySalaryStart" + data.Index,
          data.ApplySalaryStart || ""
        );
        form.setValue("ApplySalaryEnd" + data.Index, data.ApplySalaryEnd || "");
        form.setValue(
          "checkNotSpeccified" + data.Index,
          data.IsNotSpeccified || false
        );
      }
    });
    setIsClean(false);
  };

  const handleChangeValue = (form, index) => {
    let newData = jobPositionContext.map((item) => {
      if (item.Index === index) {
        item.ApplyPosition = form.getValues("ApplyPosition" + index);
        if (form.getValues("ApplyJobFunction" + index).value) {
          item.ApplyJobFunction = form.getValues("ApplyJobFunction" + index);
        }
        if (form.getValues("ApplyJobDetail" + index).value) {
          item.ApplyJobDetail = form.getValues("ApplyJobDetail" + index);
        }
        item.ApplyIndustryType = form.getValues("ApplyIndustryType" + index);
        item.ApplyJobType = form.getValues("ApplyJobType" + index);
        item.ApplySalaryStart = form.getValues("ApplySalaryStart" + index);
        item.ApplySalaryEnd = form.getValues("ApplySalaryEnd" + index);
        item.IsNotSpeccified = form.getValues("checkNotSpeccified" + index);
      }
      return item;
    });

    setJobPosition(newData);
  };

  const handleRemoveJobApply = (itemIndex: number) => {
    dispatch(
      DialogActionCreators.OpenDialogSubmit(
        i18n(`${i18nCommon}.AlertConfrimDel`),
        () => {
          dispatch(DialogActionCreators.CloseDialogSubmit() as any);
          dispatch(DialogActionCreators.LoadSubmit("Waiting...") as any);
          setIsClean(true);
          let newData = jobPositionContext.filter((data) => {
            return data.Index !== itemIndex;
          });
          setJobPosition(newData);

          form.unregister("ApplyPosition" + itemIndex);
          form.unregister("ApplyJobFunction" + itemIndex);
          form.unregister("ApplyJobDetail" + itemIndex);
          form.unregister("ApplyJobType" + itemIndex);
          form.unregister("ApplyIndustryType" + itemIndex);
          form.unregister("ApplySalaryStart" + itemIndex);
          form.unregister("ApplySalaryEnd" + itemIndex);
          form.unregister("checkNotSpeccified" + itemIndex);
        }
      )
    );
  };

  useEffect(() => {
    handleSetData(item.Index);
  }, [isClean]);

  useEffect(() => {
    handleSetData(item.Index);
    setIsSpeccified(item.IsNotSpeccified);
  }, [jobPositionContext]);

  useEffect(() => {
    const fetchData = async () => {
      await fetchJobDetail();
    };
    fetchData();
  }, []);

  return isClean ? null : (
    <FormProvider {...form} key={index}>
      <Box component={Paper} sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}>
        {index === 0 ? null : (
          <IconButton
            aria-label="delete"
            color="error"
            onClick={() => handleRemoveJobApply(item.Index)}
          >
            <CloseIcon />
          </IconButton>
        )}
        <Grid container justifyContent="center" alignItems="center" spacing={1}>
          <Grid item container spacing={2} xs={12}>
            <Grid item xs={12} sm={6} md={6}>
              <Input
                name={"ApplyPosition" + item.Index}
                small
                fullWidth
                label={
                  <Typography sx={{ fontWeight: 400 }} component="label">
                    {i18n(`${i18nField}.ApplyPosition`)}
                  </Typography>
                }
                required
                onBlur={(value) => {
                  handleChangeValue(form, item.Index);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <AutoCompleteSelect
                name={"ApplyJobFunction" + item.Index}
                fullWidth
                label={i18n(`${i18nField}.ApplyJobFunction`)}
                options={optionJobFunction}
                required
                onChange={(value) => {
                  if (value && value.value !== "ApplyJobFunction") {
                    form.clearErrors("ApplyJobDetail");
                    form.setValue("ApplyJobDetail", 0);
                  } else {
                    form.clearErrors("ApplyJobDetail");
                    form.setValue("ApplyJobDetail", value ? value.value : 0);
                  }
                  handleChangeValue(form, item.Index);
                  setParent(value ? value.value : 0);

                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <AutoCompleteSelect
                name={"ApplyJobDetail" + item.Index}
                label={i18n(`${i18nField}.ApplyJobDetail`)}
                onChange={(value) => {
                  handleChangeValue(form, item.Index);
                }}
                options={optionJobDetail.filter((f) => f.sParent === Parent)}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <AutoCompleteSelect
                name={"ApplyIndustryType" + item.Index}
                fullWidth
                label={i18n(`${i18nField}.JobIndustry`)}
                options={optionJobIndustry}
                required
                onChange={(value) => {
                  form.setValue("ApplyIndustryType" + item.Index, value);
                  handleChangeValue(form, item.Index);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <AutoCompleteSelect
                name={"ApplyJobType" + item.Index}
                fullWidth
                label={i18n(`${i18nField}.ApplyJobType`)}
                options={optionJobType}
                required
                onChange={(value) => {
                  form.setValue("ApplyJobType" + item.Index, value);
                  handleChangeValue(form, item.Index);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <InputNumber
                name={"ApplySalaryStart" + item.Index}
                small
                fullWidth
                label={i18n(`${i18nField}.ApplySalaryStart`)}
                required
                onBlur={(value) => {
                  handleChangeValue(form, item.Index);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <InputNumber
                name={"ApplySalaryEnd" + item.Index}
                small
                fullWidth
                label={i18n(`${i18nField}.ApplySalaryEnd`)}
                onBlur={(value) => {
                  if (value && value.target.value) {
                    if (
                      +value.target.value <
                      (form.watch("ApplySalaryStart" + item.Index) || 0)
                    ) {
                      setIsInvalidSalaryEnd(true);
                      form.setError("ApplySalaryEnd" + item.Index, {
                        type: "custom",
                      });
                    } else {
                      setIsInvalidSalaryEnd(false);
                      form.clearErrors("ApplySalaryEnd" + item.Index);
                    }
                  }
                  handleChangeValue(form, item.Index);
                }}
              />
              {isInvalidSalaryEnd && (
                <FormHelperText sx={{ color: "red" }}>
                  {i18n(`${i18nField}.MsgInvalidSalaryEnd`)}
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name={"checkNotSpeccified" + item.Index}
                    checked={isSpeccified}
                    onChange={(e, value) => {
                      setIsSpeccified((prev) => !prev);
                      form.setValue("checkNotSpeccified" + item.Index, value);
                      handleChangeValue(form, item.Index);
                    }}
                  />
                }
                label={i18n(`${i18nField}.ApplySalarySpeccified`)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </FormProvider >
  );
};

export default MyJobPositon;