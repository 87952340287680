import { styled, Theme, CSSObject } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Box, Collapse, Typography } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { AxiosGet, ResultAPI } from "service/CommonFunction/TS_function";
import Icons from "@mui/material/Icon";
import { Link, useNavigate } from "react-router-dom";
import themes from "../../../themes";
import { useDispatch } from "react-redux";
import { DialogActionCreators } from "store/redux/DialogAlert";
import { useLocation } from "react-router-dom";

const drawerWidth = 270;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const DrawerStyles = (theme: Theme, isOpen: boolean): CSSObject => ({
  " > button ": {
    opacity: isOpen ? 1 : 0,
  },
  " > div ": {
    opacity: isOpen ? 1 : 0,
  },
  justifyContent: "center",
  color: "black",
  padding: "17px 8px",
  marginTop: "30%",
});

const DrawerOpen = (theme: Theme, isOpen: boolean): CSSObject => ({
  " > button ": {
    opacity: isOpen ? 1 : 0,
  },
  " > div ": {
    opacity: isOpen ? 1 : 0,
  },
  justifyContent: "center",
  color: "black",
  paddingTop: "80%",
  marginTop: "50%",
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Drawer_Layout_BPS({
  handleDrawerOpen,
  handleDrawerClose,
  open,
  FocusMenu,
}) {
  const i18nField = "DrawerBackMenu";

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [lstLevel1, setlstLevel1] = useState([]);
  const [lstotherLevel, setlstotherLevel] = useState([]);
  const [lstMenuAll, setlstMenuAll] = useState([]);
  const [lstOpen, setlstOpen] = useState([]);
  const [nPermission, setPermission] = useState(0);
  const location = useLocation();

  useEffect(() => {
    AxiosGet(
      "MenuBPS/SearchData_BackMenuBPS",
      {},
      (result) => {
        ResultAPI(result, "", () => {
          let Level1 = result.lstMenu.filter(
            (f) => f.nLevel === 1 && f.nHeader == 0
          );
          let otherLevel = result.lstMenu.filter(
            (f) => f.nLevel != 1 && f.nHeader != 0
          );
          let arrStatus = [];

          result.lstMenu.forEach((element) => {
            let arrChild = result.lstMenu.filter(
              (f) => f.sHeaderId == element.sID
            );

            if (arrChild.length > 0) {
              let submenu = {
                sID: element.sID,
                menuStatus: false,
              };
              arrStatus.push(submenu);
            }
          });
          setlstOpen(arrStatus);
          setlstLevel1(Level1);
          setlstotherLevel(otherLevel);
          setlstMenuAll(result.lstMenuAll);
        });
      },
      (err) => {
        if (err.response && err.response.status === 401) {
          dispatch(
            DialogActionCreators.OpenDialogWarning("Token expired.", () => {
              navigate("/");
            }) as any
          );
        }
      }
    );
  }, [FocusMenu]);

  const setMenuActive = (sUrl) => {
    let isActive = false;
    if (location.pathname == sUrl) {
      isActive = true;
    }

    let objMenu = lstMenuAll.find((f) => f.sUrl == location.pathname);
    if (objMenu && objMenu.sUrlHeader == sUrl) {
      isActive = true;
    }

    return isActive;
  };

  return (
    <Drawer variant="permanent" open={open}>
      {open ? (
        <DrawerHeader sx={DrawerStyles(themes, open)}>
          <div
            onClick={handleDrawerClose}
            style={{
              position: "absolute",
              // left: 50,
              color: "#202020",
              cursor: "pointer",
            }}
          >
            <Box>
              <MenuIcon style={{ marginBottom: "4px", fontSize: "25px" }} />
              &nbsp;
              <Typography
                variant="h6"
                noWrap
                component="span"
                sx={{ fontWeight: "600", color: "#202020" }}
              >
                Menu
              </Typography>
            </Box>
          </div>
        </DrawerHeader>
      ) : (
        <DrawerHeader onClick={handleDrawerOpen} sx={DrawerOpen(themes, open)}>
          <MenuIcon
            sx={{ position: "absolute", cursor: "pointer", color: "#202020" }}
          />
        </DrawerHeader>
      )}

      <List>
        {lstLevel1.map((text, index) => {
          return (
            <React.Fragment key={`key_${index}`}>
              <Link to={text.sUrl}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    ":hover": {
                      background: "#E9F6FF",
                      borderRight: "6px solid #1E90FF",
                    },
                  }}
                  style={
                    location.pathname && setMenuActive(text.sUrl)
                      ? {
                          background: "#D9EEFD",
                          borderRight: "6px solid #1E90FF",
                        }
                      : {}
                  }
                  onClick={() => {
                    if (lstOpen.some((s) => s.sID == text.sID)) {
                      setlstOpen((prev) => {
                        prev.find((item) => item.sID == text.sID).menuStatus =
                          !prev.find((item) => item.sID == text.sID).menuStatus;
                        return [...prev];
                      });
                    } else {
                      navigate(text.sUrl);
                    }
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <Icons sx={{ color: "#02006f" }}>{text.sIcon}</Icons>
                  </ListItemIcon>
                  <ListItemText
                    primary={text.sNameTH}
                    sx={{ opacity: open ? 1 : 0, color: "#2a2a2a" }}
                  />
                  {lstotherLevel.some(
                    (item) => item.nLevel == 2 && item.sHeaderId == text.sID
                  ) ? (
                    lstOpen[index] ? (
                      <ExpandMore />
                    ) : (
                      <ExpandLess />
                    )
                  ) : null}
                </ListItemButton>
              </Link>

              {lstotherLevel.some(
                (item) => item.nLevel == 2 && item.sHeaderId == text.sID
              ) ? (
                <Collapse
                  in={
                    lstOpen.length > 0 &&
                    lstOpen.some((item) => item.sID == text.sID)
                      ? lstOpen.find((item) => item.sID == text.sID).menuStatus
                      : false
                  }
                >
                  <List component="div" disablePadding>
                    {lstotherLevel
                      .filter((f) => f.nLevel == 2 && f.sHeaderId == text.sID)
                      .map((m) => (
                        <ListItemButton
                          sx={{
                            pl: 6,
                            justifyContent: open ? "initial" : "center",
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : "auto",
                              justifyContent: "center",
                            }}
                          >
                            <Icons>{m.sIcon}</Icons>
                          </ListItemIcon>
                          <ListItemText
                            primary={m.sNameTH}
                            sx={{ opacity: open ? 1 : 0 }}
                          />
                        </ListItemButton>
                      ))}
                  </List>
                </Collapse>
              ) : null}
            </React.Fragment>
          );
        })}
      </List>
    </Drawer>
  );
}
