import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  Hidden,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AxiosPost } from "service/CommonFunction/TS_function";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import moment from "moment";
import { Close, CalendarMonth } from "@mui/icons-material";
import SkeletonMyJob from "components/Common/ElementInForm/SkeletonMyJob";
import { FnBlock_UI } from "service/SystemFunction/SystemFunction";
import { DialogActionCreators } from "store/redux/DialogAlert";
import { useDispatch } from "react-redux";

//i18n
import { i18n } from "i18n";
import secureLocalStorage from "react-secure-storage";
const i18nInvite = "myJobInvitation.";
const i18nMyjob = "MyJob";

const MyJob = () => {
  const dispatch = useDispatch();
  const { BlockUI, UnBlockUI } = FnBlock_UI();

  const lang = secureLocalStorage.getItem("language").toString().toUpperCase() || "TH";
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);

  const [arrJobApply, setArrJobApply] = useState([]);
  const [arrJobApplyJobFun, setArrJobApplyJobFun] = useState([]);
  const [arrJobApplyJobFunDetail, setarrJobApplyJobFunDetail] = useState([]);
  const [arrJobInterested, setArrJobInterested] = useState([]);
  const [arrJobDetailInterested, setArrJobDetailInterested] = useState([]);
  const [arrJobFunInterested, setArrJobFunInterested] = useState([]);
  const [arrViewResume, setArrViewResume] = useState([]);
  const [arrInvitation, setArrInvitation] = useState<{ [key: string]: any }>({});
  const [isAccept, setAccept] = useState(false);
  const [detail, setDetail] = useState("");
  // const [detail, setDetail] = useState<{ [key: string]: any }>({});
  const [objPaging] = useState({
    nPage: 1,
    nPageSize: 10,
    nTotal: 0,
    nSumFetch: 0,
  });

  const GetMyJobApply = () => {
    let param = {
      ...objPaging,
      ...{},
    };

    AxiosPost("MyJob/GetMyJobApply", param, (res) => {

      if (res.Data) {
        const jobItems = res.Data.items || [];
        setArrJobApply([...jobItems]);
      }
      setArrJobApplyJobFun(res.lstJobFunction);
      setarrJobApplyJobFunDetail(res.lstJobFunctionDetail);

    });
  };
  const GetMyJobInterest = () => {
    let param = {
      ...objPaging,
      ...{},
    };
    AxiosPost("MyJob/GetMyJobInterest", param, (res) => {
      if (res.Data) {
        setLoading(false);
        const jobItems = res.Data.items || [];
        setArrJobInterested([...jobItems]);
      }
      setArrJobFunInterested(res.lstJobFunction)
      setArrJobDetailInterested(res.lstJobFunctionDetail)
    });
  };

  const GetJobViewed = () => {
    let param = {
      ...objPaging,
      ...{},
    };
    AxiosPost("MyJob/SearchJobViewed", param, (res) => {
      if (res) {
        setArrViewResume([...res.lstJob]);
      }
    });
  };

  const GetJobInvite = () => {
    let param = {
      ...objPaging,
      ...{},
    };
    AxiosPost("MyJob/SearchJobInvite", param, (res) => {
      if (res) {
        setArrInvitation([...res.lstJob]);
      }
    });
  };

  const getData = () => {
    GetMyJobApply();
    GetMyJobInterest();
    GetJobViewed();
    GetJobInvite();
  };

  const onFavourite = (sID) => {
    dispatch(
      DialogActionCreators.OpenDialogSubmit(
        i18n(`entities.MyJobInterest.Confirm.DisInterest`),
        (c) => {
          dispatch(DialogActionCreators.LoadSubmit(true) as any);
          BlockUI();
          AxiosPost(
            "JobDetail/favourite",
            { sID: sID, isFavourite: false },
            (res) => {
              UnBlockUI();
              if (res.Status === 200) {
                dispatch(
                  DialogActionCreators.OpenDialogSuccess(
                    i18n(`common.msgAlertSaveSuccess`),
                    () => GetMyJobInterest()
                  ) as any
                );
              } else {
                dispatch(
                  DialogActionCreators.OpenDialogError(res.Message) as any
                );
              }
            },
            (err) => {
              if (err.Status === 401) {
                UnBlockUI();
                dispatch(
                  DialogActionCreators.OpenDialogWarning(
                    "Token expired.",
                    () => {
                      navigate("/");
                    }
                  ) as any
                );
              } else {
                dispatch(
                  DialogActionCreators.OpenDialogError(err.Message) as any
                );
              }
            },
            () => {
              dispatch(DialogActionCreators.LoadSubmit(false) as any);
            }
          );
        }
      ) as any
    );
  };

  const onAcceptInvitation = (sID, isAccept, sJob_th, sJob_en) => {
    dispatch(
      DialogActionCreators.OpenDialogSubmit(
        i18n(`${i18nInvite}confirm.${isAccept ? "accept" : "decline"}`),
        async (c) => {
          let request = {
            sID: sID,
            isAccept: isAccept,
            sJob_th: sJob_th,
            sJob_en: sJob_en
          }
          dispatch(DialogActionCreators.LoadSubmit(true) as any);
          // await AxiosPost(`MyJob/SubmitJobInvite/${encodeURIComponent(sID)}/${isAccept}`, null, (res) => {
          // await AxiosPost(`MyJob/SubmitJobInvite?sID=${sID}&isAccept?=${isAccept}`, null, (res) => {
          await AxiosPost(`MyJob/SubmitJobInvite`, request, (res) => {
            if (res.Status === 200) {
              dispatch(
                DialogActionCreators.OpenDialogSuccess(
                  i18n(`common.msgAlertSaveSuccess`),
                  () => {
                    GetJobInvite()
                    window.location.reload();
                  }
                ) as any
              );
            }
          },
            null,
            () => {
              dispatch(DialogActionCreators.LoadSubmit(false) as any);
            }
          );
        }
      ) as any
    );
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Grid
      container
      // className="fade-bg-color"
      spacing={2}
      sx={{ mt: "1%" }}
    >
      {isLoading ? (
        <SkeletonMyJob />
      ) : (
        <>
          {/* งานที่สมัคร */}
          <Grid item xs={12} sm={6} md={3}>
            <Box
              sx={{
                m: "5px",
                backgroundColor: "white",
                borderRadius: "20px",
                boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.26)",
                p: "5px",
              }}
            >
              {/* Head */}
              <Grid container sx={{ justifyContent: "space-between" }}>
                <Typography
                  sx={{ m: "5px", color: "#4d96ff", fontSize: "16px" }}
                >
                  {i18n(`${i18nMyjob}.JobApply`)}
                </Typography>
                <Box>
                  <Tooltip
                    title={i18n(`${i18nMyjob}.GotoPage`)}
                    placement="right"
                  >
                    <Button
                      onClick={() => {
                        navigate("/MyJobApply");
                      }}
                    >
                      <MoreHorizIcon fontSize="medium" sx={{ color: "gray" }} />
                    </Button>
                  </Tooltip>
                </Box>
              </Grid>

              {/* Item */}
              {arrJobApply.map((item) => (
                <Tooltip
                  title={i18n(`${i18nMyjob}.ViewJobDetail`)}
                  placement="right"
                >
                  <Box
                    sx={{
                      m: "5px",
                      backgroundColor: "white",
                      borderRadius: "20px",
                      p: "12px",
                      boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.26)",
                      cursor: "pointer",
                      mb: "10px",
                    }}
                    onClick={() => {
                      navigate("/JobDetail?sID=" + item.sEncryptJobPostID);
                    }}
                  >
                    <Grid item xs={12}>
                      {item.sLogoURL && (
                        <>
                          <Hidden smDown>
                            <Box
                              sx={{
                                width: "50px",
                                height: "50px",
                                backgroundColor: "white",
                                boxShadow: "0px 0px 5px 1px rgba(0,0,0,0.26)",
                                borderRadius: "50px",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                }}
                              >
                                <img
                                  style={{
                                    maxWidth: "35px",
                                  }}
                                  alt="company"
                                  src={item.sLogoURL}
                                />
                              </Box>
                            </Box>
                          </Hidden>
                          <Hidden smUp>
                            <img
                              style={{
                                maxWidth: "10vw",
                                maxHeight: "10vw",
                              }}
                              alt="company"
                              src={item.sLogoURL}
                            />
                          </Hidden>
                        </>
                      )}
                    </Grid>
                    <Grid item xs={12} marginTop={"2px"}>
                      <Typography sx={{ fontSize: "13px", fontWeight: "500" }}>
                        {item[`sJobName${lang}`]}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} display={"flex"}>
                      <Typography
                        style={{ fontSize: "11px", fontWeight: "500" }}
                      >
                        {item[`sCompanyName`]}
                      </Typography>
                    </Grid>

                    {arrJobApplyJobFun.filter((f) => f.sCompanyPostId == item.sEncryptJobPostID).map((m) => (
                      <>
                        <Grid item xs={12} display={"flex"}>
                          <Typography
                            style={{
                              fontSize: "11px",
                              fontWeight: "400",
                              color: "#bdbdc1",
                            }}
                          >
                            -{" "} {m[`sJobFunction${lang}`]},

                            {arrJobApplyJobFunDetail.filter((De) => De.sCompanyPostId == m.sCompanyPostId && De.nJobFunctionID == m.nJobFunctionID).map((Detail) => (
                              <>
                                {Detail[`sJobDetail${lang}`]}
                              </>
                            ))}
                          </Typography>
                        </Grid >
                      </>

                    ))}


                    <Grid item xs={12}>
                      <Typography
                        style={{ fontSize: "13px", fontWeight: "500" }}
                      >
                        {item[`sProvince${lang}`]}
                      </Typography>
                    </Grid>
                    <Grid container sx={{ justifyContent: "space-between" }}>
                      <Typography
                        style={{
                          fontSize: "12px",
                          fontWeight: "500",
                          color: "#bdbdc1",
                        }}
                      >
                        {/*apply date */}
                        {/* {moment(item.dPostDate).fromNow()} */}
                        {moment(item.dApplyDate).fromNow()}
                      </Typography>
                      <Box
                        sx={{
                          backgroundColor: "#f1f2f6",
                          borderRadius: "3px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "11px",
                            fontWeight: "500",
                            padding: "2px",
                          }}
                        >
                          {item[`sJobType${lang}`]}
                        </Typography>
                      </Box>
                    </Grid>
                  </Box>
                </Tooltip>
              ))}
            </Box>
            {/* <BtnCustomIcon txt="Button" sxCustom={{ width: "100%" }} /> */}
          </Grid>

          {/* งานที่สนใจ */}
          <Grid item xs={12} sm={6} md={3}>
            <Box
              sx={{
                m: "5px",
                backgroundColor: "white",
                borderRadius: "20px",
                boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.26)",
                p: "5px",
              }}
            >
              {/* Head */}
              <Grid container sx={{ justifyContent: "space-between" }}>
                <Typography sx={{ m: "5px", color: "#4d96ff" }}>
                  {i18n(`${i18nMyjob}.JobInterested`)}
                </Typography>
                <Box>
                  <Tooltip
                    title={i18n(`${i18nMyjob}.GotoPage`)}
                    placement="right"
                  >
                    <Button
                      onClick={() => {
                        navigate("/MyJobInterested");
                      }}
                    >
                      <MoreHorizIcon fontSize="medium" sx={{ color: "gray" }} />
                    </Button>
                  </Tooltip>
                </Box>
              </Grid>

              {/* Item */}
              {arrJobInterested.map((item) => (
                <Box
                  sx={{
                    m: "5px",
                    backgroundColor: "white",
                    borderRadius: "20px",
                    p: "12px",
                    boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.26)",
                    mb: "10px",
                    cursor: "pointer",
                  }}
                >
                  <Grid container>
                    <Grid item xs={11}>
                      <Tooltip
                        title={i18n(`${i18nMyjob}.ViewJobDetail`)}
                        placement="right"
                      >
                        <Box
                          onClick={() => {
                            navigate("/JobDetail?sID=" + item.sCompanyPostId);
                          }}
                        >
                          <Grid item xs={12}>
                            {item.sLogoURL && (
                              <>
                                <Hidden smDown>
                                  <Box
                                    sx={{
                                      width: "50px",
                                      height: "50px",
                                      backgroundColor: "white",
                                      boxShadow:
                                        "0px 0px 5px 1px rgba(0,0,0,0.26)",
                                      borderRadius: "50px",
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <img
                                        style={{
                                          maxWidth: "35px",
                                        }}
                                        alt="company"
                                        src={item.sLogoURL}
                                      />
                                    </Box>
                                  </Box>
                                </Hidden>
                                <Hidden smUp>
                                  <img
                                    style={{
                                      maxWidth: "10vw",
                                      maxHeight: "10vw",
                                    }}
                                    alt="company"
                                    src={item.sLogoURL}
                                  />
                                </Hidden>
                              </>
                            )}
                          </Grid>
                          
                          <Grid item xs={12} marginTop={"2px"}>
                            <Typography sx={{ fontSize: "13px", fontWeight: "500" }}>
                              {item[`sJobName${lang}`]}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} display={"flex"}>
                            <Typography
                              style={{ fontSize: "11px", fontWeight: "500" }}
                            >
                              {item[`sCompanyName`]}
                            </Typography>
                          </Grid>

                          {arrJobFunInterested.filter((f) => f.sCompanyPostId == item.sCompanyPostId).map((m) => (
                            <>
                              <Grid item xs={12} display={"flex"}>
                                <Typography
                                  style={{
                                    fontSize: "11px",
                                    fontWeight: "400",
                                    color: "#bdbdc1",
                                  }}
                                >
                                  -{" "} {m[`sJobFunction${lang}`]},
                                  {arrJobDetailInterested.filter((De) => De.sCompanyPostId == m.sCompanyPostId && De.nJobFunctionID == m.nJobFunctionID).map((Detail) => (
                                    <>
                                      {Detail[`sJobDetail${lang}`]}
                                    </>
                                  ))}
                                </Typography>
                              </Grid >
                            </>

                          ))}

                          < Grid item xs={12} >
                            <Typography
                              style={{ fontSize: "13px", fontWeight: "500" }}
                            >
                              {/* {item[`sLocation${lang}`]} */}
                              {item[`sProvince${lang}`]}
                            </Typography>
                          </Grid>
                          <Grid
                            container
                            sx={{ justifyContent: "space-between" }}
                          >
                            <Typography
                              style={{
                                fontSize: "12px",
                                fontWeight: "500",
                                color: "#bdbdc1",
                              }}
                            >
                              {moment(item.dInterestedDate).fromNow()}
                            </Typography>
                            <Box
                              sx={{
                                backgroundColor: "#f1f2f6",
                                borderRadius: "3px",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "11px",
                                  fontWeight: "500",
                                  padding: "2px",
                                }}
                              >
                                {item[`sJobType${lang}`]}
                              </Typography>
                            </Box>
                          </Grid>
                        </Box>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={1}>
                      <Box>
                        <Tooltip
                          title={i18n(`${i18nMyjob}.JobIgnore`)}
                          placement="right"
                        >
                          <Close
                            sx={{
                              fontSize: "15px",
                              fontWeight: "500",
                              color: "red",
                              cursor: "pointer",
                            }}
                            onClick={() => onFavourite(item.sCompanyPostId)}
                          />
                        </Tooltip>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              ))}
            </Box>
            {/* <BtnCustomIcon txt="Button" sxCustom={{ width: "100%" }} /> */}
          </Grid>

          {/* บริษัทที่ซื้อประวัติคุณ */}
          <Grid item xs={12} sm={6} md={3}>
            <Box
              sx={{
                m: "5px",
                backgroundColor: "white",
                borderRadius: "20px",
                boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.26)",
                p: "5px",
              }}
            >
              {/* Head */}
              <Grid container sx={{ justifyContent: "space-between" }}>
                <Typography sx={{ m: "5px 5px 0 5px", color: "#4d96ff" }}>
                  {i18n(`${i18nMyjob}.CompViewd`)}
                </Typography>
                <Box>
                  <Tooltip
                    title={i18n(`${i18nMyjob}.GotoPage`)}
                    placement="right"
                  >
                    <Button
                      onClick={() => {
                        navigate("/MyJobViewed");
                      }}
                    >
                      <MoreHorizIcon fontSize="medium" sx={{ color: "gray" }} />
                    </Button>
                  </Tooltip>
                </Box>
              </Grid>

              {/* Item */}
              {arrViewResume.map((item) => (
                <Box
                  sx={{
                    m: "0 5px",
                    backgroundColor: "white",
                    borderRadius: "20px",
                    boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.26)",
                    mb: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate(
                      `/Guest/Company?sID=${item.sEncryptCompanyID}&isFromCompanyID=1`
                    );
                  }}
                >
                  <Tooltip
                    title={i18n(`${i18nMyjob}.ViewCompProfile`)}
                    placement="right"
                  >
                    <Box sx={{ m: "5px 0 0 0", p: "12px" }}>
                      <Grid item xs={12}>
                        {item.sUrlComImg && (
                          <>
                            <Hidden smDown>
                              <Box
                                sx={{
                                  width: "50px",
                                  height: "50px",
                                  backgroundColor: "white",
                                  boxShadow: "0px 0px 5px 1px rgba(0,0,0,0.26)",
                                  borderRadius: "50px",
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                  }}
                                >
                                  <img
                                    style={{
                                      maxWidth: "35px",
                                    }}
                                    alt="company"
                                    src={item.sUrlComImg}
                                  />
                                </Box>
                              </Box>
                            </Hidden>
                            <Hidden smUp>
                              <img
                                style={{
                                  maxWidth: "10vw",
                                  maxHeight: "10vw",
                                }}
                                alt="company"
                                src={item.sUrlComImg}
                              />
                            </Hidden>
                          </>
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        marginTop={"10px"}
                        marginBottom={"15px"}
                      >
                        <Typography
                          sx={{ fontSize: "19px", fontWeight: "500" }}
                        >
                          {item[`sCompanyName`]}
                        </Typography>
                      </Grid>
                      {/* <Divider sx={{ width: "100%" }} />
                      <Grid item xs={12} paddingTop="10px">
                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: "500",
                            color: "#0065ff",
                          }}
                        >
                          {moment(item.dViewed).format("D MMMM yyyy")}
                        </Typography>
                      </Grid> */}
                    </Box>
                  </Tooltip>

                  <Box sx={{ paddingTop: "10px" }}>
                    <Tooltip
                      title={i18n(`${i18nMyjob}.ViewCompProfile`)}
                      placement="right"
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          height: "45px",
                          backgroundColor: "#4d96ff",
                          borderRadius: "0px 0px 20px 20px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          navigate(
                            `/Guest/Company?sID=${item.sEncryptCompanyID}&isFromCompanyID=1`
                          );
                        }}
                      >
                        <Typography
                          sx={{ textAlign: "center", color: "white" }}
                        >
                          {i18n(`${i18nMyjob}.ViewCompProfile`)}
                        </Typography>
                      </Box>
                    </Tooltip>
                  </Box>
                </Box>
              ))}
            </Box>
            {/* <BtnCustomIcon txt="Button" sxCustom={{ width: "100%" }} /> */}
          </Grid>

          {/* คำเชิญจากบริษัท */}
          <Grid item xs={12} sm={6} md={3}>
            <Box
              sx={{
                m: "5px",
                backgroundColor: "white",
                borderRadius: "20px",
                boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.26)",
                p: "5px",
              }}
            >
              {/* Head */}
              <Grid container sx={{ justifyContent: "space-between" }}>
                <Typography sx={{ m: "5px", color: "#4d96ff" }}>
                  {i18n(`${i18nMyjob}.CompInvited`)}
                </Typography>
                <Box>
                  <Tooltip
                    title={i18n(`${i18nMyjob}.GotoPage`)}
                    placement="right"
                  >
                    <Button
                      onClick={() => {
                        navigate("/MyJobInvitation");
                      }}
                    >
                      <MoreHorizIcon fontSize="medium" sx={{ color: "gray" }} />
                    </Button>
                  </Tooltip>
                </Box>
              </Grid>

              {/* Item */}
              {(arrInvitation && arrInvitation.length > 0) ? arrInvitation.map((item) => (
                <Box
                  sx={{
                    m: "0 5px",
                    backgroundColor: "white",
                    borderRadius: "20px",
                    boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.26)",
                    mb: "10px",
                  }}
                >
                  {/* Detail */}
                  <Tooltip
                    title={i18n(`${i18nMyjob}.ViewJobDetail`)}
                    placement="right"
                  >
                    <Grid
                      item
                      xs={12}
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate("/JobDetail?sID=" + item.sEncryptJobPostID);
                      }}
                    >
                      <Grid container sx={{ m: "5px 0 0 0", p: "12px" }}>
                        <Grid item xs={4}>
                          <Box>
                            {item.sUrlComImg && (
                              <>
                                <Hidden smDown>
                                  <Box
                                    sx={{
                                      width: "50px",
                                      height: "50px",
                                      backgroundColor: "white",
                                      boxShadow:
                                        "0px 0px 5px 1px rgba(0,0,0,0.26)",
                                      borderRadius: "50px",
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <img
                                        style={{
                                          maxWidth: "35px",
                                        }}
                                        alt="company"
                                        src={item.sUrlComImg}
                                      />
                                    </Box>
                                  </Box>
                                </Hidden>
                                <Hidden smUp>
                                  <img
                                    style={{
                                      maxWidth: "10vw",
                                      maxHeight: "10vw",
                                    }}
                                    alt="company"
                                    src={item.sUrlComImg}
                                  />
                                </Hidden>
                              </>
                            )}
                          </Box>
                        </Grid>
                        <Grid item xs={8} display="flex">
                          <Grid item xs={11}>
                            <Grid item xs={12}>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box>
                                  <Typography
                                    sx={{ fontSize: "14px", fontWeight: "500" }}
                                  >
                                    {item[`sJob_${lang.toLowerCase()}`]}
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>

                            <Grid item xs={12} display={"flex"}>
                              <CalendarMonth
                                sx={{
                                  fontSize: "22px",
                                  color: "#3683ff",
                                }}
                              />
                              <Typography
                                sx={{
                                  fontSize: "13px",
                                  fontWeight: "500",
                                  color: "#475f7d",
                                  pl: "2px",
                                  pt: "1px",
                                }}
                              >
                                {moment(item.dSendDate).format("D MMMM yyyy")}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Tooltip>

                  {/* Confirm */}
                  <Grid container>
                    {item.isConfirm == null ? (
                      <>
                        <Grid item xs={6}>
                          <Tooltip
                            title={i18n(`${i18nMyjob}.JobAccept`)}
                            placement="right"
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                height: "45px",
                                backgroundColor: "#0065ff",
                                borderRadius: "0 0 0 20px",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                onAcceptInvitation(
                                  item.sEncryptCompanyInvitationID,
                                  true,
                                  // setAccept(true)
                                  item.sJob_th,
                                  item.sJob_en,

                                )
                              }
                            >
                              <Typography
                                sx={{ textAlign: "center", color: "white" }}
                              >
                                {i18n(`${i18nMyjob}.JobAccept`)}
                              </Typography>
                            </Box>
                          </Tooltip>
                        </Grid>
                        <Grid item xs={6}>
                          <Tooltip
                            title={i18n(`${i18nMyjob}.JobDecline`)}
                            placement="right"
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                height: "45px",
                                backgroundColor: "#262346",
                                borderRadius: "0 0 20px 0",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                onAcceptInvitation(
                                  item.sEncryptCompanyInvitationID,
                                  false,
                                  item.sJob_th,
                                  item.sJob_en,
                                )
                              }
                            >
                              <Typography
                                sx={{ textAlign: "center", color: "white" }}
                              >
                                {i18n(`${i18nMyjob}.JobDecline`)}
                              </Typography>
                            </Box>
                          </Tooltip>
                        </Grid>
                      </>
                    ) : item.isConfirm === true ? (
                      <>
                        <Grid item xs={12}>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              height: "45px",
                              backgroundColor: "#6ccb77",
                              borderRadius: "0 0 20px 20px",
                            }}
                          >
                            <Typography
                              sx={{ textAlign: "center", color: "white" }}
                            >
                              {i18n(`${i18nMyjob}.JobAccept`)}
                            </Typography>
                          </Box>
                        </Grid>
                      </>
                    ) : (
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            height: "45px",
                            backgroundColor: "#ff6b6b",
                            borderRadius: "0 0 20px 20px",
                          }}
                        >
                          <Typography
                            sx={{ textAlign: "center", color: "white" }}
                          >
                            {i18n(`${i18nMyjob}.JobDecline`)}
                          </Typography>
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                </Box>
              )) : null}
            </Box>
          </Grid>
        </>
      )
      }
    </Grid >
  );
};

export default MyJob;
