import React, { useState, useEffect } from 'react'
import {
    FormControl, InputLabel,
    FormHelperText, OutlinedInput,
    SxProps
} from '@mui/material';
import { useFormContext } from 'react-hook-form'
import PropTypes from "prop-types";
export default function InputCustomLabel(props) {

    const { label, name, labelString, sx,
        required, fullWidth, type,
        autoFocus, prefix, disabled, onKeyDown, onKeyUp,
        onKeyPress, onBlur, autoComplete, onChange, startAdornment,
        endAdornment, maxRow, multiline, rows, id, margin, maxLength, small, onPaste, shrink, sxLabel, hidden,
        rounded, isNumberOnly } = props;

    const {
        register,
        setValue,
        getValues,
        watch,
        formState: { errors },
    } = useFormContext();

    const [sValueText, setValueText] = useState<string>(watch(name));

    useEffect(() => {
        setValueText(getValues(name))
    }, [watch(name)])

    const maxLengthText: SxProps = {

        "::after": {
            content: maxLength ? `"${sValueText ? sValueText.length : 0}/${maxLength}"` : '""',
            position: "absolute",
            bottom: "2px",
            right: "10px",
            color: "#989898",
            fontSize: '10px'
        }
    }


    return (
        <FormControl hiddenLabel={hidden || false} disabled={disabled} sx={{
            "label.MuiInputLabel-shrink": {
                top: "0!important",
            },
            "div.MuiOutlinedInput-root fieldset.MuiOutlinedInput-notchedOutline": {
                borderColor: Boolean(errors[name]) ? 'red' : '',
            },
            "div.MuiOutlinedInput-root": {
                borderRadius: rounded ? '2em' : '',
            },
            width: "100%",
            "input::-webkit-outer-spin-button,input::-webkit-inner-spin-button": {
                appearance: isNumberOnly ? 'none' : '',
                MozAppearance: isNumberOnly ? 'none' : '',
                margin: isNumberOnly ? 0 : '',
            },
            "input[type=number]": {
                MozAppearance: isNumberOnly ? 'textfield' : '',
            }
        }}>
            <InputLabel hidden={hidden || false} htmlFor="input"
                shrink={shrink}
                sx={{
                    top: small ? "-8px" : "0",
                    " > label": {
                        "::after": {
                            content: required ? '" * "' : '""',
                            color: "red",
                            fontWeight: 500,
                        },
                    },
                    color: errors && errors[name] ? "red !important" : "", ...sxLabel
                }}>
                {label}
            </InputLabel>
            <OutlinedInput
                hidden={hidden || false}
                inputRef={register(name).ref}
                {...register(name)}
                type={type}
                fullWidth={fullWidth}
                required={required}
                onPaste={onPaste || undefined}
                error={Boolean(errors[name])}
                name={name}
                size={small ? "small" : "medium"}
                label={labelString ? labelString : label}
                sx={[{ ...maxLengthText }, sx]}
                autoFocus={autoFocus}
                prefix={prefix || undefined}
                disabled={disabled}

                onKeyDown={(e) => {
                    if (e.keyCode === 13 && !multiline) setValue(name, e.currentTarget.value, { shouldValidate: true })
                    onKeyDown && onKeyDown(e);
                }}
                onKeyUp={onKeyUp || undefined}
                onKeyPress={(onKeyPress || undefined)}
                onBlur={(e) => {
                    setValue(name, e.target.value, { shouldValidate: true });
                    onBlur && onBlur(e)
                }}
                autoComplete={autoComplete || 'new-password'}
                onChange={(e) => {
                    setValueText(e.target.value)
                    onChange && onChange(e.target.value);
                }}
                startAdornment={startAdornment || undefined}
                endAdornment={endAdornment || undefined}
                maxRows={maxRow || undefined}
                value={sValueText || ''}
                multiline={multiline || undefined}
                rows={rows || undefined}
                id={id}
                margin={margin}
                inputProps={{
                    type: isNumberOnly ? 'number' : type,
                    maxLength: maxLength || 250,
                }}

            />
            {errors && errors[name] ?
                <FormHelperText sx={{ color: "red !Important" }} >{errors[name].message}</FormHelperText>
                :
                null}
        </FormControl>
    )
}


InputCustomLabel.defaultProps = {
    type: "text",
    required: false,
    disabled: false,
    small: true,
    isNumberOnly: false,
};

InputCustomLabel.propTypes = {
    name: PropTypes.string.isRequired,
    required: PropTypes.bool,
    type: PropTypes.string,
    label: PropTypes.any,
    labelString: PropTypes.string,
    autoFocus: PropTypes.bool,
    disabled: PropTypes.bool,
    sx: PropTypes.any,
    prefix: PropTypes.string,
    onKeyDown: PropTypes.any,
    onKeyPress: PropTypes.any,
    onKeyUp: PropTypes.any,
    onBlur: PropTypes.any,
    autoComplete: PropTypes.string,
    onChange: PropTypes.func,
    startAdornment: PropTypes.any,
    endAdornment: PropTypes.any,
    maxRow: PropTypes.number,
    maxLength: PropTypes.number,
    multiline: PropTypes.bool,
    rows: PropTypes.number,
    id: PropTypes.string,
    margin: PropTypes.string,
    fullWidth: PropTypes.bool,
    small: PropTypes.bool,
    onPaste: PropTypes.func,
    shrink: PropTypes.bool,
    hidden: PropTypes.bool,
    rounded: PropTypes.bool,
    sxLabel: PropTypes.any,
};
