import { useState } from 'react';
import { Box, Grid, Stack, Tab, Tabs, Typography } from '@mui/material';
import { useLocation } from "react-router-dom";
import AdminCandidateApplyTable from './AdminCandidateApplyTable';
import AdminCandidateJobFavourite from './AdminCandidateJobFavourite';
import AdminCandidateProfile from './AdminCandidateProfile';
import AdminCandidateViewed from './AdminCandidateViewed';
import AdminCandidateInvitation from './AdminCandidateInvitation';
import AdminCandidateSeting from './AdminCandidateSeting';

const Profile = () => {
    const i18nField = 'entities.Candidate'
    const location = useLocation();
    const [value, setValue] = useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    interface TabPanelProps {
        children?: React.ReactNode;
        index: number;
        value: number;
    }

    const TabPanel = (props: TabPanelProps) => {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    const TabsClick = (index: number) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <Grid container>
            <Grid item className="table" sx={{ mx: "2%", width: "100%" }}>
                <Stack width={"100%"} sx={{ marginTop: "2%", border: "2px #eeeeee solid", backgroundColor: "rgba(255,255,255,0.75)", borderRadius: 5, p: 3 }}>
                    <Grid item sx={{ p: 1, borderRadius: 5, boxShadow: 3, bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff') }}>
                        <Tabs value={value} onChange={handleChange} textColor="primary" allowScrollButtonsMobile variant="scrollable" TabIndicatorProps={{ style: { display: "none" } }} >
                            <Tab
                                label={
                                    value == 0 ?
                                        <span style={{ color: '#f9fafd' }}>ข้อมูลผู้สมัครงาน</span> :
                                        <span style={{ color: '#474747' }}>ข้อมูลผู้สมัครงาน</span>}
                                {...TabsClick(0)}
                                sx={{ flex: 1, borderRadius: 5, backgroundColor: value == 0 ? "#003dc6" : "#fff" }}
                            />

                            <Tab
                                label={
                                    value == 1 ?
                                        <span style={{ color: '#f9fafd' }}>งานที่สมัคร</span> :
                                        <span style={{ color: '#474747' }}>งานที่สมัคร</span>}
                                {...TabsClick(1)}
                                sx={{ flex: 1, borderRadius: 5, backgroundColor: value == 1 ? "#003dc6" : "#fff" }}
                            />

                            <Tab
                                label={
                                    value == 2 ?
                                        <span style={{ color: '#f9fafd' }}>งานที่สนใจ</span> :
                                        <span style={{ color: '#474747' }}>งานที่สนใจ</span>}
                                {...TabsClick(1)}
                                sx={{ flex: 1, borderRadius: 5, backgroundColor: value == 2 ? "#003dc6" : "#fff" }}
                            />
                            <Tab
                                label={
                                    value == 3 ?
                                        <span style={{ color: '#f9fafd' }}>บริษัทที่ซื้อประวัติคุณ</span> :
                                        <span style={{ color: '#474747' }}>บริษัทที่ซื้อประวัติคุณ</span>}
                                {...TabsClick(1)}
                                sx={{ flex: 1, borderRadius: 5, backgroundColor: value == 3 ? "#003dc6" : "#fff" }}
                            />
                            <Tab
                                label={
                                    value == 4 ?
                                        <span style={{ color: '#f9fafd' }}>คำเชิญจากบริษัท</span> :
                                        <span style={{ color: '#474747' }}>คำเชิญจากบริษัท</span>}
                                {...TabsClick(1)}
                                sx={{ flex: 1, borderRadius: 5, backgroundColor: value == 4 ? "#003dc6" : "#fff" }}
                            />

                            <Tab
                                label={
                                    value == 5 ?
                                        <span style={{ color: '#f9fafd' }}>การตั้งค่าผู้สมัคร</span> :
                                        <span style={{ color: '#474747' }}>การตั้งค่าผู้สมัคร</span>}
                                {...TabsClick(1)}
                                sx={{ flex: 1, borderRadius: 5, backgroundColor: value == 5 ? "#003dc6" : "#fff" }}
                            />

                            {/* <Tab
                                label={
                                    value == 6 ?
                                        <span style={{ color: '#f9fafd' }}>สมาชิก</span> :
                                        <span style={{ color: '#474747' }}>สมาชิก</span>}
                                {...TabsClick(1)}
                                sx={{ flex: 1, borderRadius: 5, backgroundColor: value == 6 ? "#003dc6" : "#fff" }}
                            /> */}
                        </Tabs>
                    </Grid>
                    <TabPanel value={value} index={0}>
                        <AdminCandidateProfile isAdmin={true} />
                    </TabPanel>

                    <TabPanel value={value} index={1}>
                        <AdminCandidateApplyTable />
                    </TabPanel>

                    <TabPanel value={value} index={2}>
                        <AdminCandidateJobFavourite />
                    </TabPanel>

                    <TabPanel value={value} index={3}>
                        <AdminCandidateViewed />
                    </TabPanel>
                    
                    <TabPanel value={value} index={4}>
                        <AdminCandidateInvitation />
                    </TabPanel>

                    <TabPanel value={value} index={5}>
                        < AdminCandidateSeting />
                    </TabPanel>

                    {/* <TabPanel value={value} index={6}>
                        < AdminCandidateEmployerMember />
                    </TabPanel> */}
                </Stack >
            </Grid>
        </Grid>
    );
}
export default Profile
