import JobPostList from "components/JobPost/JobPostList"

const JobPostListView = () => {
    return (
        <>
            <JobPostList />
        </>
    )
}

export default JobPostListView
