import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BtnBack } from "components/Common/Button";
import { AxiosGet } from "service/CommonFunction/TS_function";
import {
  Divider,
  Grid,
  Stack,
  Typography,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  TableBody,
  Paper,
  Link,
} from "@mui/material";
import Content from "../../font/JobDetail/Content";
import AdminCompanyTabPanel from "./AdminCompanyTabPanel";
import { FnBlock_UI } from "service/SystemFunction/SystemFunction";

const AdminCompanyOrderDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = location.search;
  const sID = new URLSearchParams(searchParams).get("sID");
  const sOrderID = new URLSearchParams(searchParams).get("sOrderID");

  const [detail, setDetail] = useState<{ [key: string]: any }>({});
  const [isBanner, setIsBanner] = useState(false);
  const { BlockUI, UnBlockUI } = FnBlock_UI();

  const onGetDetail = () => {
    BlockUI();
    AxiosGet(
      "AdminCompany/detail_order",
      { sID: sID, sOrderID: sOrderID },
      (res) => {
        if (res.nPakageType_ID === 3) setIsBanner(true);
        setDetail(res);
        UnBlockUI();
      }
    );
  };

  const onBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    onGetDetail();
  }, []);

  const ShowDetail_by_Type = () => {
    if (isBanner) {
      return (
        <TableHead>
          <TableRow>
            <TableCell>Grand Total</TableCell>
            <TableCell align="right">{detail.sAfterVat}</TableCell>
            <TableCell align="left">บาท</TableCell>
          </TableRow>
        </TableHead>
      );
    } else {
      return (
        <TableHead>
          <TableRow hidden={true}>
            <TableCell>Price per Job</TableCell>
            <TableCell align="right">{detail.sPrice}</TableCell>
            <TableCell align="left">บาท</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Discount</TableCell>
            <TableCell align="right">{detail.sDiscount}</TableCell>
            <TableCell align="left">บาท</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Total Price</TableCell>
            <TableCell align="right">{detail.sNetPrice}</TableCell>
            <TableCell align="left">บาท</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Vat 7%</TableCell>
            <TableCell align="right">{detail.sVat}</TableCell>
            <TableCell align="left">บาท</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Grand Total</TableCell>
            <TableCell align="right">{detail.sAfterVat}</TableCell>
            <TableCell align="left">บาท</TableCell>
          </TableRow>
        </TableHead>
      );
    }
  };

  return (
    <Grid container>
      <Grid item className="table" sx={{ mx: "2%", width: "100%" }}>
        <AdminCompanyTabPanel tabNo={3} />
        <Stack
          spacing={2}
          width={"100%"}
          sx={{
            marginTop: "2%",
            border: "2px #eeeeee solid",
            backgroundColor: "rgba(255,255,255,0.75)",
            borderRadius: 5,
            p: 3,
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="subtitle1">ข้อมูลคำสั่งซื้อ</Typography>
            </Grid>
            <Grid item md={12}>
              <Divider />
            </Grid>
            <Grid container>
              <Grid item md={12} xs={12} sx={{ marginTop: "2%" }}>
                {/* <Typography variant="subtitle1">
                  {detail.sPakageType} [{detail.sOrder_Package_Name}]
                </Typography> */}
                <Stack direction={"column"} flex={1} >
                  <Stack direction={"column"} alignItems="start" sx={{ mt: "0px !important", gap: "5px" }} >
                    {detail.sPakageType} {detail.lstOrder_Package_Name ? (detail.lstOrder_Package_Name).map((m, index) =>
                      <Typography key={index} fontWeight={400} variant="subtitle2" >{m}<br /></Typography>) : null}
                  </Stack>
                </Stack>

              </Grid>
              <Grid item md={12} xs={12} sx={{ marginTop: "2%" }}>
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    {ShowDetail_by_Type()}
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item md={12} xs={12} sx={{ marginTop: "2%" }}>
                <Typography variant="subtitle1">Note</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <Typography variant="subtitle1">{detail.sNote}</Typography>
              </Grid>
              <Grid item md={12} xs={12} sx={{ marginTop: "2%" }}>
                <Typography variant="subtitle1">Attach File</Typography>
              </Grid>
              {detail.lstOrderFile != null && detail.lstOrderFile.length > 0
                ? detail.lstOrderFile.map((item, index) => {
                  return (
                    <Grid key={index} item md={12} xs={12}>
                      <Link href={item.sUrl}>- {item.sFileName}</Link>
                    </Grid>
                  );
                })
                : "-"}
            </Grid>
          </Grid>
        </Stack>
        <Grid
          item
          xs={12}
          justifyContent={"start"}
          marginTop={3}
          marginBottom={3}
        >
          <BtnBack 
           txt="ย้อนกลับ" onClick={() => onBack()} />
        </Grid>
      </Grid>
    </Grid>
  );
};
export default AdminCompanyOrderDetail;
