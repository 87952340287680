import React, { useEffect, useState } from "react";
import { Button, Divider, Grid, Skeleton, Stack, Typography, Box } from "@mui/material";
import { i18n, getLanguage } from "i18n";
import PrintIcon from "@mui/icons-material/Print";

import { AxiosPostFilePDF, AxiosGet, AxiosPost, GetPermission } from "service/CommonFunction/TS_function";

import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { DialogActionCreators } from "store/redux/DialogAlert";
import Link from "@mui/material/Link";
import BadgeIcon from '@mui/icons-material/Badge';
import "./styles.css";
import ImageIcon from '@mui/icons-material/Image';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { BtnBack } from "components/Common/Button"
import moment from "moment";
import secureLocalStorage from "react-secure-storage";

const Profile = (props) => {
  const { isAdmin } = props;
  const i18nField = "entities.Candidate";
  const history = useNavigate();
  const dispatch = useDispatch();

  const location = useLocation();
  const searchParams = location.search;
  const sID = new URLSearchParams(searchParams).get("sID");
  const [nPermission, setPermission] = useState(0);
  const [dataInfo, setDataInfo] = useState<{ [key: string]: any }>({});
  const [nCountryID, setCountry] = useState(0);
  const [dataWorkExp, setDataWorkExp] = useState([]);

  const [dataEducational, setDataEducational] = useState([]);
  const [dataLanguageProficiency, setDataLanguageProficiency] = useState([]);
  const [dataAbility, setDataAbility] = useState([]);
  const [dataCertificate, setDataCertificate] = useState([])
  const [dataJobSeekerPosition, setDataJobSeekerPosition] = useState([]);
  const [dataLicense, setDataLicense] = useState([]);
  const [dataJobSeekerFile, setDataJobSeekerFile] = useState([]);;
  const [isLoadProfile, setLoadProfile] = useState(false);
  const [dataComputerSkill, setDataComputerSkill] = useState([])
  const [dataKeyword, setDataKeyword] = useState([]);
  const isEmployer = window.location.pathname.includes("/EmployerSearchViewJobseeker");
  const langeCode = isEmployer ? (secureLocalStorage.getItem("language").toString() || "EN").toLocaleUpperCase() : "TH"

  const ExportPDF = () => {

    let fileName = dataInfo.sFullNameTH.replace(" ", "_")

    AxiosPostFilePDF(
      fileName,
      "AdminCandidate/GetReportCandidateProfile",
      { sID: sID, sLang: langeCode },
      (result) => {

        var blob = new Blob([result], {
          type: "application/pdf",
        },);

        const link = document.createElement("a");
        link.target = "_blank";
        var objectUrl = URL.createObjectURL(blob);
        window.open(objectUrl, "_blank");



        // link.href = (objectUrl);
        // link.setAttribute("visibility", "hidden");
        // // link.setAttribute("download", fileName);
        // link.click();
        // link.remove();

        // window.open(objectUrl, "_blank");


        // const link = document.createElement("a");
        // link.download = fileName;

        // link.target = "_blank";
        // var objectUrl = URL.createObjectURL(blob);
        // // link.href = (objectUrl);
        // window.open(objectUrl,"test")
        // // link.setAttribute("visibility", "hidden");
        // // link.click();
        // // link.remove();
      },
      (err) => {
        if (err.Message != "") {
          dispatch(
            DialogActionCreators.OpenDialogWarning(
              err.Message
            ) as any
          );
        }
      }
    );
  };

  const forceDownload = (blob, filename) => {
    debugger
    var a = document.createElement('a');
    // a.download = filename;
    a.setAttribute("visibility", filename);
    a.href = blob;
    // For Firefox https://stackoverflow.com/a/32226068
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  const onGetDetail = () => {
    setLoadProfile(true)
    AxiosGet("AdminCandidate/GetInfo", { sID: sID }, (res) => {
      setDataInfo(res.JobSeekerInfo);
      setDataWorkExp(res.lstWorkExperience || []);
      setDataEducational(res.lstEducational || []);
      setDataLanguageProficiency(res.lstLanguageProficiency || []);
      setDataAbility(res.lstAbility || []);
      setDataJobSeekerPosition(res.lstJobSeekerPosition || []);
      setDataJobSeekerFile(res.lstJobSeekerFile || []);

      setDataLicense(res.lstLicense || []);
      setDataKeyword(res.lstTag || [])
      setDataCertificate(res.lstCertificate || [])
      setDataComputerSkill(res.lstComputerSkill || [])
    }, (err) => dispatch(DialogActionCreators.OpenDialogWarning(err.Message)), () => setLoadProfile(false));
  };

  const handleCalAge = (sBirthDate) => {
    const age = moment().diff(sBirthDate, 'years', false);
    return age + " " + i18n(`${i18nField}.AgeYear`);
  }
  const getYear = (sDate) => {
    if (langeCode == "TH") {
      return (moment(sDate).year() + 543).toString();
    } else {
      return (moment(sDate).year()).toString();
    }
  }

  useEffect(() => {
    //setLang(window.localStorage.getItem("language"));
    onGetDetail();
    GetPermission(1, setPermission);
  }, []);

  return (
    <Grid container>
      <Grid item className="table" sx={{ mx: "2%", width: "100%" }}>
        <Stack
          spacing={2}
          width={"100%"}
          justifyContent="center"
          sx={{
            marginTop: "2%",
            border: "2px #eeeeee solid",
            backgroundColor: "rgba(255,255,255,0.75)",
            borderRadius: 5,
            p: 3,
          }}
        >
          {isLoadProfile ? (
            <>
              <Stack direction={"row"} justifyContent="space-between">
                <Skeleton height={50} width={140} />
                <Skeleton height={50} width={100} />
              </Stack>
              <Stack direction={"row"} justifyContent="space-between" sx={{ mt: "5px !important" }}>
                <Skeleton height={50} width={200} />
                <Skeleton height={50} width={100} />
              </Stack>
            </>) : (
            <>
              <Grid container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-start" >
                <Button
                  onClick={() => ExportPDF()}
                  variant="outlined"
                  startIcon={<PrintIcon />}
                  sx={{ borderRadius: "20px" }}
                  disabled={nPermission !== 2 && !isEmployer}
                >
                  {i18n(`${i18nField}.Print`)}
                </Button>
              </Grid>
              <Stack direction={"row"}  >
                <Divider sx={{ width: "100%", backgroundColor: "#03a9f4" }} />
              </Stack>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Typography color={"#03a9f4"} fontWeight={500} fontSize={18}>
                  {i18n(`${i18nField}.IndividualData`)}
                </Typography>
                <Typography fontSize={12} variant="subtitle1">
                  {i18n(`${i18nField}.Update`)}
                  :  {dataInfo.sUpdate_Date}{" "}
                  {i18n(`${i18nField}.Time`)}  {dataInfo.sUpdate_Time}
                </Typography>
              </Grid>
              <Grid
                container>
                <Grid
                  item md={10} xs={8}
                >
                  <Stack direction={"row"} sx={{ mt: "5px !important" }} justifyContent="space-between" >
                    <Stack direction={"column"} justifyContent="start" sx={{ width: "215px", marginLeft: "20px" }}>
                      <Typography flex={1} fontWeight={500} variant="subtitle2">
                        {i18n(`${i18nField}.Name`)}
                      </Typography>
                    </Stack>
                    <Stack direction={"column"} flex={1}>
                      <Stack direction={"row"} alignItems="center" sx={{ mt: "0px !important", gap: "5px" }} >
                        <Typography fontWeight={400} variant="subtitle2">{`${dataInfo["sFName_" + langeCode]} ${dataInfo["sLName_" + langeCode]}`}</Typography>
                      </Stack>
                    </Stack>
                  </Stack>

                  <Stack direction={"column"} sx={{ mt: "5px !important", gap: "5px" }} >
                    <Stack direction={"row"} sx={{ gap: "1em" }}>
                      <Stack direction={"column"} justifyContent="center" sx={{ width: "200px", marginLeft: "20px" }}>
                        <Typography fontWeight={500} variant="subtitle2">
                          {i18n(`${i18nField}.Sex`)}
                        </Typography>
                      </Stack>
                      <Stack direction={"column"} flex={1}>
                        <Stack direction={"row"} alignItems="center" sx={{ mt: "0px !important", gap: "5px" }} >
                          <Typography fontWeight={400} variant="subtitle2">{dataInfo["sSex_" + langeCode]}</Typography>
                        </Stack>
                      </Stack>
                    </Stack>

                    <Stack direction={"row"} sx={{ gap: "1em" }}>
                      <Stack direction={"column"} justifyContent="start" sx={{ width: "200px", marginLeft: "20px" }}>
                        <Typography fontWeight={500} variant="subtitle2">
                          {i18n(`${i18nField}.Status`)}
                        </Typography>
                      </Stack>
                      <Stack direction={"column"} flex={1}>
                        <Stack direction={"row"} alignItems="center" sx={{ mt: "0px !important", gap: "5px" }} >
                          <Typography fontWeight={400} variant="subtitle2">{dataInfo["sStatus_" + langeCode]}</Typography>
                        </Stack>
                      </Stack>
                    </Stack>

                    <Stack direction={"row"} sx={{ gap: "1em" }}>
                      <Stack direction={"column"} justifyContent="start" sx={{ width: "200px", marginLeft: "20px" }}>
                        <Typography fontWeight={500} variant="subtitle2">
                          {i18n(`${i18nField}.Age`)}
                        </Typography>
                      </Stack>
                      <Stack direction={"column"} flex={1}>
                        <Stack direction={"row"} alignItems="center" sx={{ mt: "0px !important", gap: "5px" }} >
                          <Typography fontWeight={400} variant="subtitle2">{`${dataInfo.dBirthDate != null ? handleCalAge(dataInfo.dBirthDate) : i18n(`${i18nField}.NotSpecific`)} (${moment(dataInfo.dBirthDate).format("DD/MM/YYYY")})`}</Typography>
                        </Stack>
                      </Stack>
                    </Stack>

                    <Stack direction={"row"} sx={{ gap: "1em" }}>
                      <Stack direction={"column"} justifyContent="start" sx={{ width: "200px", marginLeft: "20px" }}>
                        <Typography fontWeight={500} variant="subtitle2">
                          {i18n(`${i18nField}.Country`)}
                        </Typography>
                      </Stack>
                      <Stack direction={"column"} flex={1}>
                        <Stack direction={"row"} alignItems="center" sx={{ mt: "0px !important", gap: "5px" }} >
                          <Typography fontWeight={400} variant="subtitle2">{`${dataInfo["sCountry_Name_" + langeCode]}`}</Typography>
                        </Stack>
                      </Stack>
                    </Stack>

                    {dataInfo.nCountryID === 519 ? (
                      <Stack direction={"row"} sx={{ gap: "1em" }}>
                        <Stack direction={"column"} justifyContent="start" sx={{ width: "200px", marginLeft: "20px" }}>
                          <Typography fontWeight={500} variant="subtitle2">
                            {i18n(`${i18nField}.Region`)}
                          </Typography>
                        </Stack>
                        <Stack direction={"column"} flex={1}>
                          <Stack direction={"row"} alignItems="center" sx={{ mt: "0px !important", gap: "5px" }} >
                            <Typography fontWeight={400} variant="subtitle2">{`${dataInfo["sRegion_Name_" + langeCode]}`}</Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                    ) : null}
                    {dataInfo.nCountryID === 519 ? (
                      <Stack direction={"row"} sx={{ gap: "1em" }}>
                        <Stack direction={"column"} justifyContent="start" sx={{ width: "200px", marginLeft: "20px" }}>
                          <Typography fontWeight={500} variant="subtitle2">
                            {i18n(`${i18nField}.Province`)}
                          </Typography>
                        </Stack>
                        <Stack direction={"column"} flex={1}>
                          <Stack direction={"row"} alignItems="center" sx={{ mt: "0px !important", gap: "5px" }} >
                            <Typography fontWeight={400} variant="subtitle2">{`${dataInfo["sProvince_Name_" + langeCode]}`}</Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                    ) : null}
                  </Stack>


                </Grid>
                {(dataInfo.sUrl) && (
                  <Grid
                    item md={2} xs={4}
                  >
                    <img src={dataInfo.sUrl} alt=""
                      style={{
                        width: "10vw", //10vw or 151px
                        height: "13vw", //13vw or 227px
                        objectFit: "cover",
                      }}
                    />
                  </Grid>
                )}
              </Grid>
              <Stack direction={"row"}  >
                <Divider sx={{ width: "100%", backgroundColor: "#03a9f4" }} />
              </Stack>
              <Stack direction={"row"} alignItems="end" justifyContent="space-between">
                <Typography color={"#03a9f4"} fontWeight={500} fontSize={18}>
                  {i18n(`${i18nField}.Data`)}
                </Typography>
              </Stack>
              <Stack direction={"column"} sx={{ mt: "5px !important", gap: "5px" }} >
                <Stack direction={"row"} sx={{ gap: "1em" }}>
                  <Stack direction={"column"} justifyContent="start" sx={{ width: "200px", marginLeft: "20px" }}>
                    <Typography fontWeight={500} variant="subtitle2">
                      {i18n(`${i18nField}.Mobile`)}
                    </Typography>
                  </Stack>
                  <Stack direction={"column"} flex={1}>
                    <Stack direction={"row"} alignItems="center" sx={{ mt: "0px !important", gap: "5px" }} >
                      <Typography fontWeight={400} variant="subtitle2">{dataInfo.sTel}</Typography>
                    </Stack>
                  </Stack>
                </Stack>

                <Stack direction={"row"} sx={{ gap: "1em", marginTop: "0px !important" }}>
                  <Stack direction={"column"} justifyContent="start" sx={{ width: "200px", marginLeft: "20px" }}>
                    <Typography fontWeight={500} variant="subtitle2">
                      {i18n(`${i18nField}.Email`)}
                    </Typography>
                  </Stack>
                  <Stack direction={"column"} flex={1}>
                    <Stack direction={"row"} alignItems="center" sx={{ mt: "0px !important", gap: "5px" }} >
                      <Typography fontWeight={400} variant="subtitle2">{dataInfo.sEmail}</Typography>
                    </Stack>
                  </Stack>
                </Stack>

                <Stack direction={"row"} sx={{ gap: "1em", marginTop: "0px !important" }}>
                  <Stack direction={"column"} justifyContent="start" sx={{ width: "200px", marginLeft: "20px" }}>
                    <Typography fontWeight={500} variant="subtitle2">
                      {i18n(`${i18nField}.ContactOther`)}
                    </Typography>
                  </Stack>

                  <Stack direction={"column"} flex={1}>
                    <Stack direction={"row"} alignItems="center" sx={{ mt: "0px !important", gap: "5px" }} >
                      <Typography fontWeight={400} variant="subtitle2">{dataInfo.sOtherContact}</Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>

              <Stack direction={"row"}  >
                <Divider sx={{ width: "100%", backgroundColor: "#03a9f4" }} />
              </Stack>

              <Stack direction={"row"} alignItems="end" justifyContent="space-between">
                <Typography color={"#03a9f4"} fontWeight={500} fontSize={18}>
                  {i18n(`${i18nField}.WorkExperience`)}
                </Typography>
              </Stack>

              <Stack direction={"column"} sx={{ mt: "5px !important", gap: "5px" }} >
                {dataWorkExp.map((item, index) => {
                  return (
                    <Stack direction={"row"} sx={{ gap: "1em" }}>
                      <Stack direction={"column"} justifyContent="start" sx={{ width: "200px", marginLeft: "20px" }}>
                        <Typography fontWeight={500} fontSize={17}>
                          {item.IsWorkCurrent ? `${moment(item.dStart_Date).format("MMMM YYYY")} - ${i18n(`${i18nField}.Present`)}` : `${moment(item.dStart_Date).format("MMMM YYYY")} - ${moment(item.dEnd_Date).format("MMMM YYYY")}`}
                        </Typography>
                      </Stack>
                      <Stack direction={"column"} flex={1}>
                        <Stack direction={"row"} alignItems="center" sx={{ mt: "0px !important", gap: "5px" }} >
                          <Typography fontWeight={500} fontSize={17}>{item.sPosition_Name}</Typography>
                        </Stack>
                        <Stack direction={"row"} alignItems="center" sx={{ mt: "0px !important", gap: "5px" }} >
                          <Typography fontWeight={500} fontSize={17}>{item.sCompany_Name}</Typography>
                        </Stack>

                        <Stack direction={"row"} sx={{ gap: "1em", marginTop: "0px !important" }}>
                          <Stack direction={"column"} justifyContent="start" sx={{ width: "150px" }}>
                            <Typography fontWeight={500} variant="subtitle2">
                              {i18n(`${i18nField}.Industry`)}
                            </Typography>
                          </Stack>
                          <Stack direction={"row"} flex={1} alignItems="start" sx={{ mt: "0px !important", gap: "5px" }} >
                            <Typography fontWeight={400} variant="subtitle2"> {`${item["sIndustryType_Name_" + langeCode]}`}</Typography>
                          </Stack>
                        </Stack>

                        <Stack direction={"row"} sx={{ gap: "1em", marginTop: "0px !important" }}>
                          <Stack direction={"column"} justifyContent="start" sx={{ width: "150px" }}>
                            <Typography fontWeight={500} variant="subtitle2">
                              {i18n(`${i18nField}.JobFunction`)}
                            </Typography>
                          </Stack>

                          <Stack direction={"row"} flex={1} alignItems="start" sx={{ mt: "0px !important", gap: "5px" }} >
                            <Typography fontWeight={400} variant="subtitle2"> {`${item["sJobFunction_Name_" + langeCode]}`}</Typography>
                          </Stack>
                        </Stack>

                        <Stack direction={"row"} sx={{ gap: "1em", marginTop: "0px !important" }}>
                          <Stack direction={"column"} justifyContent="start" sx={{ width: "150px" }}>
                            <Typography fontWeight={500} variant="subtitle2">
                              {i18n(`${i18nField}.JobFunctionDetail`)}
                            </Typography>
                          </Stack>
                          <Stack direction={"row"} flex={1} alignItems="start" sx={{ mt: "0px !important", gap: "5px" }} >
                            <Typography fontWeight={400} variant="subtitle2"> {`${item["sJobDetail_Name_" + langeCode]}`}</Typography>
                          </Stack>
                        </Stack>

                        <Stack direction={"row"} sx={{ gap: "1em", marginTop: "0px !important" }}>
                          <Stack direction={"column"} justifyContent="start" sx={{ width: "150px", height: "100%" }}>
                            <Typography fontWeight={500} variant="subtitle2">
                              {i18n(`${i18nField}.JobDescription`)}
                            </Typography>
                          </Stack>
                          <Stack direction={"row"} flex={1} alignItems="start" sx={{ mt: "0px !important", gap: "5px", whiteSpace: "pre-wrap", wordBreak: "break-all", maxWidth: "100%", overflow: "auto" }} >
                            <Typography fontWeight={400} sx={{ maxWidth: "100%" }} variant="subtitle2">{item.sRemark}</Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Stack>
                  );
                })}
              </Stack>

              <Stack direction={"row"}  >
                <Divider sx={{ width: "100%", backgroundColor: "#03a9f4" }} />
              </Stack>

              <Stack direction={"row"} alignItems="end" justifyContent="space-between">
                <Typography color={"#03a9f4"} fontWeight={500} fontSize={18}>
                  {i18n(`${i18nField}.Education`)}
                </Typography>
              </Stack>

              <Stack direction={"column"} sx={{ mt: "5px !important", gap: "5px" }} >
                {dataEducational.map((item, index) => {
                  return (
                    <Stack direction={"row"} sx={{ gap: "1em" }}>
                      <Stack direction={"column"} justifyContent="start" sx={{ width: "200px", marginLeft: "20px" }}>
                        <Typography fontWeight={500} fontSize={17}>
                          {`${item.nEducational_Year}`}
                        </Typography>
                      </Stack>
                      <Stack direction={"column"} flex={1}>
                        <Stack direction={"row"} alignItems="center" sx={{ mt: "0px !important", gap: "5px" }} >
                          <Typography fontWeight={500} fontSize={17}>{item["sUniversity_Name_" + langeCode]}</Typography>
                        </Stack>
                        <Stack direction={"row"} alignItems="center" sx={{ mt: "0px !important", gap: "5px" }} >
                          <Typography fontWeight={500} fontSize={17}>{`${item["sEducationalType_Name_" + langeCode]}`}</Typography>
                        </Stack>
                        <Stack direction={"row"} sx={{ gap: "1em", marginTop: "0px !important" }}>
                          <Stack direction={"column"} justifyContent="start" sx={{ width: "150px" }}>
                            <Typography fontWeight={500} variant="subtitle2">
                              {i18n(`${i18nField}.Major`)}
                            </Typography>
                          </Stack>
                          <Stack direction={"row"} alignItems="center" sx={{ mt: "0px !important", gap: "5px" }} >
                            <Typography fontWeight={400} variant="subtitle2"> {`${item.sEducational_Branch}`}</Typography>
                          </Stack>
                        </Stack>
                        <Stack direction={"row"} sx={{ gap: "1em", marginTop: "0px !important", maxWidth: "50vw" }}>
                          <Stack direction={"column"} justifyContent="start" sx={{ width: "150px" }}>
                            <Typography fontWeight={500} variant="subtitle2">
                              {i18n(`${i18nField}.CourseHighlights`)}
                            </Typography>
                          </Stack>
                          <Stack direction={"row"} flex={1} alignItems="center" sx={{ mt: "0px !important", gap: "5px", whiteSpace: "pre-wrap", wordBreak: "break-all", maxWidth: "70%", overflow: "auto" }} >
                            <Typography fontWeight={400} sx={{ maxWidth: "100%" }} variant="subtitle2">{`${item.sRemark}`}</Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Stack>
                  );
                })}
              </Stack>

              <Stack direction={"row"}  >
                <Divider sx={{ width: "100%", backgroundColor: "#03a9f4" }} />
              </Stack>

              <Stack direction={"row"} alignItems="end" justifyContent="space-between">
                <Typography color={"#03a9f4"} fontWeight={500} fontSize={18}>
                  {i18n(`${i18nField}.Certificate`)}
                </Typography>
              </Stack>

              <Stack direction={"column"} sx={{ mt: "5px !important", gap: "5px" }} >
                {dataCertificate.map((item, index) => {
                  return (

                    <Stack direction={"row"} sx={{ gap: "1em" }}>
                      <Stack direction={"column"} justifyContent="start" sx={{ width: "200px", marginLeft: "20px" }}>
                        <Typography fontWeight={500} fontSize={17}>
                          {moment(item.dCreateDate).format("YYYY")}
                        </Typography>
                      </Stack>
                      <Stack direction={"column"} flex={1}>
                        <Stack direction={"row"} alignItems="center" sx={{ mt: "0px !important", gap: "5px" }} >
                          <Typography variant="subtitle2">{item.sCertificate_Name}</Typography>
                        </Stack>
                        <Stack direction={"row"} alignItems="center" sx={{ mt: "0px !important", gap: "5px" }} >
                          <Typography variant="subtitle2">{item.sInstitution_Name}</Typography>
                        </Stack>
                        <Stack direction={"row"} alignItems="center" sx={{ mt: "0px !important", gap: "5px" }} >
                          <Typography fontWeight={400} variant="subtitle2">{item.sRemark}</Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                  );
                })}
              </Stack>

              <Stack direction={"row"}  >
                <Divider sx={{ width: "100%", backgroundColor: "#03a9f4" }} />
              </Stack>

              <Stack direction={"row"} alignItems="end" justifyContent="space-between">
                <Typography color={"#03a9f4"} fontWeight={500} fontSize={18}>
                  {i18n(`${i18nField}.ComputerSkill`)}
                </Typography>
              </Stack>

              <Stack direction={"column"} sx={{ mt: "5px !important", gap: "5px" }} >
                {dataComputerSkill.map((item, index) => {
                  return (
                    <Stack direction={"row"} sx={{ gap: "1em" }}>
                      <Stack direction={"column"} justifyContent="start" sx={{ width: "200px", marginLeft: "20px" }}>
                        <Typography fontWeight={500} variant="subtitle2">
                          {item.sSkillName}
                        </Typography>
                      </Stack>
                      <Stack direction={"column"} flex={1}>
                        <Stack direction={"row"} alignItems="center" sx={{ mt: "0px !important", gap: "5px" }} >
                          <Typography variant="subtitle2">{item["sProficiency_" + langeCode]}</Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                  );
                })}
              </Stack>

              <Stack direction={"row"}  >
                <Divider sx={{ width: "100%", backgroundColor: "#03a9f4" }} />
              </Stack>

              <Stack direction={"row"} alignItems="end" justifyContent="space-between">
                <Typography color={"#03a9f4"} fontWeight={500} fontSize={18}>
                  {i18n(`${i18nField}.OtherAbility`)}
                </Typography>
              </Stack>

              <Stack direction={"row"} flexWrap={"wrap"} sx={{ mt: "5px !important", gap: "1em" }}>
                {dataAbility.map((item, index) => {
                  return (
                    <Box className="border  border-[#1976d2] rounded-[1em] min-w-[55px] px-[5px] py-[1] border-w-[2px] flex justify-center items-center ">
                      <Typography variant="subtitle2">{item.sAbility_Name}</Typography>
                    </Box>
                  );
                })}
              </Stack>

              <Stack direction={"row"}  >
                <Divider sx={{ width: "100%", backgroundColor: "#03a9f4" }} />
              </Stack>

              <Stack direction={"row"} alignItems="end" justifyContent="space-between">
                <Typography color={"#03a9f4"} fontWeight={500} fontSize={18}>
                  {i18n(`${i18nField}.LangugeSkill`)}
                </Typography>
              </Stack>

              <Stack direction={"column"} sx={{ mt: "5px !important", gap: "5px" }} >
                {dataLanguageProficiency.map((item, index) => {
                  return (
                    <Stack direction={"row"} sx={{ gap: "1em" }}>
                      <Stack direction={"column"} justifyContent="start" sx={{ width: "200px", marginLeft: "20px" }}>
                        <Typography fontWeight={500} fontSize={17}>
                          {`${item["sLanguage_Name_" + langeCode]}`}
                        </Typography>
                      </Stack>

                      <Stack direction={"column"} flex={1}>
                        <Stack direction={"row"} sx={{ gap: "1em", marginTop: "0px !important" }}>
                          <Stack direction={"column"} justifyContent="start" sx={{ width: "150px" }}>
                            <Typography fontWeight={500} variant="subtitle2">
                              {i18n(`${i18nField}.SpeakingSkill`)}
                            </Typography>
                          </Stack>
                          <Stack direction={"row"} alignItems="center" sx={{ mt: "0px !important", gap: "5px" }} >
                            <Typography fontWeight={400} variant="subtitle2"> {`${item["sSpeeking_Name_" + langeCode]}`}</Typography>
                          </Stack>
                        </Stack>
                        <Stack direction={"row"} sx={{ gap: "1em", marginTop: "0px !important" }}>
                          <Stack direction={"column"} justifyContent="start" sx={{ width: "150px" }}>
                            <Typography fontWeight={500} variant="subtitle2">
                              {i18n(`${i18nField}.ReadingSkill`)}
                            </Typography>
                          </Stack>
                          <Stack direction={"row"} alignItems="center" sx={{ mt: "0px !important", gap: "5px" }} >
                            <Typography fontWeight={400} variant="subtitle2"> {`${item["sReading_Name_" + langeCode]}`}</Typography>
                          </Stack>
                        </Stack>

                        <Stack direction={"row"} sx={{ gap: "1em", marginTop: "0px !important" }}>
                          <Stack direction={"column"} justifyContent="start" sx={{ width: "150px" }}>
                            <Typography fontWeight={500} variant="subtitle2">
                              {i18n(`${i18nField}.WritingSkill`)}
                            </Typography>
                          </Stack>
                          <Stack direction={"row"} alignItems="center" sx={{ mt: "0px !important", gap: "5px" }} >
                            <Typography fontWeight={400} variant="subtitle2"> {`${item["sWriting_Name_" + langeCode]}`}</Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Stack>
                  );
                })}
              </Stack>

              <Stack direction={"row"}  >
                <Divider sx={{ width: "100%", backgroundColor: "#03a9f4" }} />
              </Stack>

              <Stack direction={"row"} alignItems="end" justifyContent="space-between">
                <Typography color={"#03a9f4"} fontWeight={500} fontSize={18}>
                  {i18n(`${i18nField}.JobPosition`)}
                </Typography>
              </Stack>
              {dataJobSeekerPosition.map((item, index) => {
                return (
                  <Stack direction={"column"} sx={{ mt: "5px !important", gap: "5px" }} >
                    <Stack direction={"row"} sx={{ gap: "1em" }}>
                      <Stack direction={"column"} justifyContent="start" sx={{ width: "200px", marginLeft: "20px" }}>
                        <Typography fontWeight={500} fontSize={17}>
                          {item.sPosition_Name}
                        </Typography>
                      </Stack>
                      <Stack direction={"column"} flex={1}>
                        <Stack direction={"row"} alignItems="center" sx={{ mt: "0px !important", gap: "5px" }} >
                          <Typography fontWeight={500} fontSize={17}>{item["sPositionType_Name_" + langeCode]}</Typography>
                        </Stack>
                        <Stack direction={"row"} alignItems="center" sx={{ mt: "0px !important", gap: "5px" }} >
                          <Typography fontWeight={500} fontSize={17}>{item["sWorkType_Name_" + langeCode]}</Typography>
                        </Stack>

                        <Stack direction={"row"} sx={{ gap: "1em", marginTop: "0px !important" }}>
                          <Stack direction={"column"} justifyContent="start" sx={{ width: "150px" }}>
                            <Typography fontWeight={500} variant="subtitle2">
                              {i18n(`${i18nField}.EmployerType`)}
                            </Typography>
                          </Stack>
                          <Stack direction={"row"} alignItems="center" sx={{ mt: "0px !important", gap: "5px" }}>
                            {/* <Typography fontWeight={400} variant="subtitle2"> {`${item.sEmploymentType_Name_TH + langeCode}`}</Typography> */}

                            {langeCode == "EN" ?
                              <Typography fontWeight={400} variant="subtitle2"> {`${item.sEmploymentType_Name_EN}`}</Typography>
                              :
                              <Typography fontWeight={400} variant="subtitle2"> {`${item.sEmploymentType_Name_TH}`}</Typography>
                            }

                          </Stack>
                        </Stack>

                        <Stack direction={"row"} sx={{ gap: "1em", marginTop: "0px !important" }}>
                          <Stack direction={"column"} justifyContent="start" sx={{ width: "150px" }}>
                            <Typography fontWeight={500} variant="subtitle2">
                              {i18n(`${i18nField}.ExpectSalary`)}
                            </Typography>
                          </Stack>
                          <Stack direction={"row"} alignItems="center" sx={{ mt: "0px !important", gap: "5px" }} >
                            <Typography fontWeight={400} variant="subtitle2"> {`${item.sSaraly_Start}${item.sSaraly_End ? ` - ${item.sSaraly_End}` : ""}`}</Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Stack>
                );
              })}

              <Stack direction={"row"}  >
                <Divider sx={{ width: "100%", backgroundColor: "#03a9f4" }} />
              </Stack>
              <Stack direction={"row"} alignItems="end" justifyContent="space-between">
                <Typography color={"#03a9f4"} fontWeight={500} fontSize={18}>
                  {i18n(`${i18nField}.License`)}
                </Typography>
              </Stack>
              <Stack direction={"column"} sx={{ mt: "5px !important", gap: "5px" }} >
                {dataLicense.map((item, index) => {
                  return (
                    <Stack direction={"row"} sx={{ gap: "1em" }}>
                      <Stack direction={"column"} justifyContent="start" sx={{ width: "200px", marginLeft: "20px" }}>
                        <Typography fontWeight={500} variant="subtitle2">
                          {item["sLicenseName_" + langeCode]}
                        </Typography>
                      </Stack>
                      <Stack direction={"column"} flex={1}>
                        <Stack direction={"row"} alignItems="center" sx={{ mt: "0px !important", gap: "5px" }} >
                          <Typography variant="subtitle2">{item.sLicenseNo}</Typography>
                        </Stack>
                      </Stack>
                    </Stack>

                  );
                })}
              </Stack>
              <Stack direction={"row"}  >
                <Divider sx={{ width: "100%", backgroundColor: "#03a9f4" }} />
              </Stack>

              <Stack direction={"row"} alignItems="end" justifyContent="space-between">
                <Typography color={"#03a9f4"} fontWeight={500} fontSize={18}>
                  {i18n(`${i18nField}.Keyword`)}
                </Typography>
              </Stack>
              <Stack direction={"row"} sx={{ mt: "5px !important", gap: "1em" }} >
                {dataKeyword.map((item, index) => {
                  return (
                    <Box className="border  border-[#1976d2] rounded-[1em] min-w-[55px] px-[5px] py-[1] border-w-[2px] flex justify-center items-center ">
                      <Typography variant="subtitle2">{item}</Typography>
                    </Box>
                  );
                })}
              </Stack>

              <Stack direction={"row"}  >
                <Divider sx={{ width: "100%", backgroundColor: "#03a9f4" }} />
              </Stack>

              <Stack direction={"row"} alignItems="end" justifyContent="space-between">
                <Typography color={"#03a9f4"} fontWeight={500} fontSize={18}>
                  {i18n(`${i18nField}.AttachFile`)}
                </Typography>
              </Stack>

              <Stack direction={"row"} sx={{ mt: "5px !important", gap: "1em" }} >
                {dataJobSeekerFile.map((itemData, indexData) => {
                  return (
                    <Link href={itemData.sUrl} target="_blank" key={`item_data_type_info_file_${indexData}`} className={`border gap-[5px] ease-linear   duration-[200ms]
                ${(itemData.sFileName as string).toLocaleLowerCase().includes(".pdf") ? `bg-[#ff7028] border-[#ff7028] hover:bg-[#d64700]` : `bg-[#515edb] border-[#515edb] hover:bg-[#2330a9]`}
                !text-[white] border-[#1976d2] rounded-[1em] min-w-[120px] h-[35px] px-[5px] py-[1] border-w-[2px] flex justify-center items-center `}>
                      {(itemData.sFileName as string).toLocaleLowerCase().includes(".pdf") ? <PictureAsPdfIcon /> : <ImageIcon />}
                      <label className='text-[0.65em] cursor-[pointer] font-[500]' >{itemData.sFileName}</label>
                    </Link>
                  );
                })}
              </Stack>
            </>
          )}
        </Stack >
      </Grid>
    </Grid >
  )
};
export default Profile;