import { Stack } from "@mui/material";
import AdminOrderDetailCom from "../../../components/AdminWorkflow/AdminOrder/AdminOrderDetail";


export default function AdminSpecialDetail() {
 
  return (
    <Stack sx={{ mx: "1%" }}>
     <AdminOrderDetailCom nType_ID={1} SpecialEdit={true}/>
    </Stack>
  );
}