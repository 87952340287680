import { Grid, Stack } from "@mui/material";
import { BtnRadius } from "components/Common/Button";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import TableHoldPackage from "./TableHoldPackage";

export default function FromMasterTable() {
  const navigate = useNavigate();
  const [nPermission, setPermission] = useState(0);
  const onCreate = () => {
    navigate("/HoldPackageAdd");
  }
  return (
    <Stack sx={{ mx: "1%" }}>
      {nPermission !== 2 ? (
        <Grid item md={2} xs={6} >
          <BtnRadius
            onClick={() => onCreate()} txt={'เพิ่ม'} />
        </Grid>
      ) : null}
      <Grid item >
        <TableHoldPackage />
      </Grid>
    </Stack>
  );
}