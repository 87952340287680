import { createSelector } from 'reselect';

const selectRaw = (state) => state.googleReducer;


const getProvider = createSelector(
    [selectRaw],
    (googleReducer) => googleReducer.ProviderGoogle
)

const GoogleSelector = {
    selectRaw,
    getProvider
}
export default GoogleSelector;