import { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  InputAdornment,
  IconButton,
  Button,
  FormControlLabel,
  FormControl,
  FormGroup,
  Checkbox,
  Divider,
  FormHelperText,
  ButtonProps,
  Dialog,
  Toolbar,
  AppBar,
} from "@mui/material";
import { blue } from '@mui/material/colors';
import { i18n, getLanguage } from "i18n";

import { useLocation, useNavigate } from "react-router-dom";
import yupFormSchemas, {
  RegexpPassword,
} from "components/Common/yup/yupFormSchemas";
import {
  AxiosGet,
  AxiosPost,
  ResultAPI,
} from "service/CommonFunction/TS_function";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import Input from "components/Common/ElementInForm/InputCustomLabelFormItem";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import { useDispatch } from "react-redux";
import { DialogActionCreators } from "store/redux/DialogAlert";
import { styled } from '@mui/material/styles';
import AutoCompleteSelect from "components/Common/ElementInForm/AutoCompleteSelect";
import ContentPage from "../Home/ContentPage";
import CloseIcon from "@mui/icons-material/Close";
import { objContent } from "components/Banner/AdminContent/ManageContent";
import secureLocalStorage from "react-secure-storage";
import { FnBlock_UI } from "service/SystemFunction/SystemFunction";
import { HubConnectionState, HubConnectionBuilder } from '@microsoft/signalr';

interface saveData {
  sRegister_Name: string;
  sCompany_Name: string;
  sUser_PW: string;
  sEmail: string;
  sUser_Tel: string;
  nIndustryTypeID: number;
  nCountryID: number;
  nRegionID: number;
  nProvinceID: number;
  sStreet: string;
  sMoo: string;
  sAddressNo: string;
  sPostCode: string;
  isGetNews: boolean;
}

//declare variables
const RegisterEmployerForm = () => {
  const i18nField = "RegisterEmployer";
  const i18nFieldCommon = "common";
  const i18nCommon = 'common';
  const history = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = location.search;
  const sType = new URLSearchParams(searchParams).get('sType'); //buy package newregister

  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);

  const [isCheckedConsent1, SetIsCheckedConsent1] = useState<boolean>(true);
  const [isCheckedConsent2, SetIsCheckedConsent2] = useState<boolean>(true);
  const [isShowPreview, setIsShowPreview] = useState<boolean>(false);
  const [errorConsent, setErrorConsent] = useState(false);

  const [rawIndustrytype, setRawIndustrytype] = useState<any>([]);
  const [rawCountry, setRawCountry] = useState<any>([]);
  const [rawRegion, setRawRegion] = useState<any>([]);
  const [rawProvince, setRawProvince] = useState<any>([]);
  const [IsInter, setIsInter] = useState<boolean>(false);
  const [ProvinceParent, setProvinceParent] = useState(0);
  const [lstContent, setLstPreviewContent] = useState<objContent[]>([]);
  const [isEmailDup, setIsEmailDup] = useState(false);
  const [isEmployerDup, setIsEmployerDup] = useState(false);
  const [lstContentTerm, setlstContentTerm] = useState([]);
  const { BlockUI, UnBlockUI } = FnBlock_UI();
  const [isLogout, setLogout] = useState<boolean>(false);
  const [isLoadMenu, SetisLoadMenu] = useState<Boolean>(false);
  const [nFocusIndex, setFocusIndex] = useState(null);

  const lang = secureLocalStorage.getItem('language').toString() || "th";

  const ButtonBlueRadius1 = styled(Button)<ButtonProps>(({ theme }) => ({
    color: theme.palette.getContrastText(blue[700]),
    backgroundColor: blue['A400'],
    width: "250px",
    height: "45px",
    fontSize: "18px",
    borderRadius: "60px",
    '&:hover': {
      backgroundColor: blue['A700'],
    },
  }));

  //Yup validate
  const objSchema = {
    sEmail: yupFormSchemas.email(i18n(`${i18nField}.Email`), {
      required: true,
    }),
    sRegister_Name: yupFormSchemas.string(i18n(`${i18nField}.RegisterName`), {
      required: true,
    }),
    sCompany_Name: yupFormSchemas.string(i18n(`${i18nField}.CompanyName`), {
      required: true,
    }),
    nCountryID: yupFormSchemas.object(i18n(`${i18nField}.Country`), {
      required: true,
    }),
    nRegionID: yupFormSchemas.object(i18n(`${i18nField}.Region`), {
      required: !IsInter,
    }),
    nProvinceID: yupFormSchemas.object(i18n(`${i18nField}.Province`), {
      required: !IsInter,
    }),
    sPostCode: yupFormSchemas.string(i18n(`${i18nField}.PostCode`), {
      required: !IsInter,
    }),
    sStreet: yupFormSchemas.string(i18n(`${i18nField}.Street`), {
      required: !IsInter,
    }),
    sMoo: yupFormSchemas.string(i18n(`${i18nField}.Moo`), {
      required: !IsInter,
    }),
    sAddressNo: yupFormSchemas.string(i18n(`${i18nField}.AddressNo`), {
      required: !IsInter,
    }),
    nIndustryTypeID: yupFormSchemas.object(i18n(`${i18nField}.Industry`), {
      required: true,
    }),
    sUser_Tel: yupFormSchemas.string(i18n(`${i18nField}.Phone`), {
      required: true,
    }),
    sUser_PW: yupFormSchemas.string(i18n(`${i18nField}.Password`), {
      required: true,
      matches: RegexpPassword(),
      labelmatches: i18n("validation.string.Password"),
    }),
    sConfirmPWD: yupFormSchemas
      .string(i18n(`${i18nField}.ConfirmPass`), {
        required: true,
      })
      .oneOf([yup.ref("sUser_PW")], i18n(`${i18nField}.InvalidPass`)),
  };

  //Yup object
  const schema = yup.object().shape(objSchema);

  //schema to From
  const form = useForm({
    resolver: yupResolver(schema),
    shouldUnregister: false,
    shouldFocusError: true,
    mode: "all",
  });

  const connection = new HubConnectionBuilder()
    .withUrl(process.env.REACT_APP_API_URL + "hubs/auth")
    .withAutomaticReconnect()
    .build();

  useEffect(() => {
    if (connection.state != HubConnectionState.Connected) {
      connection
        .start()
        .then((result) => {
          connection.on("ReceiveLogin", (sConnectionId, isLogin) => {
            _onReceiveLoginHub(sConnectionId, isLogin)
            //เคลีย sessionStorage เก่าออก
            // secureLocalStorage.clear();
            secureLocalStorage.removeItem('isSignOut_bps');
            secureLocalStorage.removeItem('sUrlQrCode');
            secureLocalStorage.removeItem('isVerify');
          });
        })
        .catch((e) => {
          console.log("Connection failed");
        });
    } else {
      connection.on("ReceiveLogin", (sConnectionId, isLogin) => {
        _onReceiveLoginHub(sConnectionId, isLogin)
        //เคลีย sessionStorage เก่าออก
        // secureLocalStorage.clear();
        secureLocalStorage.removeItem('isSignOut_bps');
        secureLocalStorage.removeItem('sUrlQrCode');
        secureLocalStorage.removeItem('isVerify');
      });
    }
  }, [])

  useEffect(() => {
    onGetIndustry();
    onGetCountry();
    onGetRegion();
    onGetProvince();
  }, [])

  const onPreview = async (nID) => {
    let param = {
      nID,
    };

    let lstPreviewContent = [];
    let show = [];
    await AxiosPost(
      `HomeFront/getData_ContentDetail`,
      param,
      (res) => {
        ResultAPI(res, "", () => {
          setlstContentTerm(res.contentShow);
          show = res.contentShow;
        });
      },
      (err) => {
        if (!err.response) {
          return;
        }
      }
    );
    show.forEach((f, i) => {
      const nType = f.nType;
      let sContent = f.sContent;
      let fileContent = f.sFilePath;
      let objViewContent = {
        nType: nType,
        sContent: sContent,
        sFileNameSys: fileContent,
      };
      lstPreviewContent.push(objViewContent);
    });
    setLstPreviewContent(lstPreviewContent);
    setIsShowPreview(true);
  };


  const CheckExistMail = (value) => {
    const param = {
      sEmail: value,
    };
    AxiosPost("Guest/CheckAlreadyMailEmployer", param, (res) => {
      const data = res.Data;
      if (data.IsAlready) {
        setIsEmailDup(true);
        form.setError("Email", { type: "custom" });
      } else {
        setIsEmailDup(false);
        // form.clearErrors();
        form.clearErrors("Email");
      }
    });
  };

  const CheckExistEmployerName = (value) => {
    const param = {
      sCompany_Name: value,
    };
    AxiosPost("Guest/GetAlreadyEmployerName", param, (res) => {
      const data = res.Data;
      if (data.IsAlready) {
        setIsEmployerDup(true);
        form.setError("Company Name", { type: "custom" });
      } else {
        setIsEmployerDup(false);
        // form.clearErrors();
        form.clearErrors("Company Name");
      }
    });
  };

  //Submit data
  const onSubmit = (e) => {
    if (isEmailDup) {
      form.setError("Email", { type: "custom" });
      return;
    }
    else if (isEmployerDup) {
      form.setError("Company Name", { type: "custom" });
      return;
    }
    if (isCheckedConsent2) {
      dispatch(
        DialogActionCreators.OpenDialogSubmit(i18n(`${i18nFieldCommon}.AlertConfrimSave`), () => { //Do you want to save

          let objData: saveData = {
            sRegister_Name: e["sRegister_Name"],
            sCompany_Name: e["sCompany_Name"],
            sUser_PW: e["sUser_PW"],
            sEmail: e["sEmail"],
            sUser_Tel: e["sUser_Tel"],
            nIndustryTypeID: +(form.getValues("nIndustryTypeID").value),
            nCountryID: +(form.getValues("nCountryID").value),
            nRegionID: +(form.getValues("nRegionID").value) || 0,
            nProvinceID: +(form.getValues("nProvinceID").value) || 0,
            sStreet: form.getValues("sStreet") || null,
            sMoo: form.getValues("sMoo") || null,
            sAddressNo: form.getValues("sAddressNo") || null,
            sPostCode: form.getValues("sPostCode") || null,
            isGetNews: isCheckedConsent1,
          };
          AxiosPost(
            "RegisterEmployer/SaveData_FromRegisterEmployer",
            objData,
            (result) => {
              // ResultAPI(result, i18n(`${i18nFieldCommon}.msgAlertSaveSuccess`), () => { //Saved successfully 
              dispatch(
                DialogActionCreators.OpenDialogSuccess(
                  i18n(`${i18nFieldCommon}.msgAlertSaveSuccess`), () => { onBack(e); } //Saved successfully 
                ) as any
              );
              // });
            },
            (err) => {
              if (err.Message != "") {
                dispatch(
                  DialogActionCreators.OpenDialogWarning(
                    err.Message
                  ) as any
                );
              }
            }
          );
        }) as any
      );
    } else {
      setErrorConsent(true);
    }
  };

  const _onReceiveLoginHub = (sConId, isLoginAgain) => {
    let sUserId = secureLocalStorage.getItem("sUserId");
    if (sConId == secureLocalStorage.getItem("connectionID_bps") && secureLocalStorage.getItem("isSignOut_bps") != "1" && sUserId) {
      if (isLoginAgain) {
        dispatch(DialogActionCreators.CloseDialogWarning() as any);
        // window.location.reload();
        // history("/OrderEmployer");
      } else {
        dispatch(DialogActionCreators.OpenDialogWarning("กรุณาเข้าสู่ระบบอีกครั้ง", () => {
          SetisLoadMenu(val => !val);
          setLogout(true);
          setFocusIndex(null);
          history("/")
        }) as any)
      }
    }
  }

  const onLogin = (e) => {
    BlockUI();
    // handleLoginClose();
    let userlogin = {
      sUserName: e["sEmail"],
      sUserCode: e["sUser_PW"],
      isRemember: false,
      nType: 2,
    }

    AxiosPost("Authen/NormalAuthen", userlogin, (res) => {
      secureLocalStorage.setItem("isSignOut_bps", "1");
      secureLocalStorage.setItem("sUserId", res.sUserKeys)
      secureLocalStorage.setItem("sUserFullNameEN", res.sFullNameEN)
      secureLocalStorage.setItem("sUserFullNameTH", res.sFullNameTH)
      secureLocalStorage.setItem("nGroupType", res.nGroupType)
      secureLocalStorage.setItem(
        process.env.REACT_APP_JWT_KEY as string,
        res.sToken
      );

      let sConnectionId = secureLocalStorage.getItem("connectionID_bps");
      setLogout(false);
      if (connection.state != HubConnectionState.Connected) {
        connection
          .start()
          .then(() => {
            // if cannot find connection id
            if (!sConnectionId) {
              secureLocalStorage.setItem("connectionID_bps", connection.connectionId);
            }
            connection.send("OnSignOutSignal", sConnectionId ?? connection.connectionId, true)
            // window.location.reload();
            // history('/OrderEmployer');
          })
          .catch((e) => {
            console.log("Connection failed");
          });
      } else if (res.Status === 404) {
        dispatch(DialogActionCreators.OpenDialogWarning(
          i18n(`${i18nCommon}.msgAlertLoginTypeWrong`), () => { //Login Type is Wrong
          }) as any)
      }
      else {
        connection.send("OnSignOutSignal", sConnectionId ?? connection.connectionId, false)
      }

      // dispatch(
      //   DialogActionCreators.OpenDialogSuccess(
      //     i18n(`${i18nFieldCommon}.msgAlertSaveSuccess`), () => {
      history("/OrderEmployer")
      setTimeout(() => {
        window.location.reload();
      }, 1000);
      //   } //Saved successfully 
      // ) as any
      // );
    }, (err) => {
      if (err.Status === 409) {
        dispatch(DialogActionCreators.OpenDialogWarning(
          i18n(`${i18nCommon}.msgAlertUsernameOrPassWrong`), () => { //Username or Password is Wrong
          }) as any)
      } else {
        dispatch(DialogActionCreators.OpenDialogWarning(err.Message) as any)
      }
    }, () => UnBlockUI())
  }


  const onBack = (e) => {
    if (sType == '1') {
      onLogin(e);
    } else {
      history("/");
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  const onGetIndustry = () => {
    let lang = "";
    let isTH = (getLanguage().id === "th");
    isTH ? lang = "th" : lang = "en"
    AxiosGet(`DataFilter/SetFilter_SelectIndustrytype`, { lang }, (res) => {
      setRawIndustrytype(res.lstOptionAdmin || []);
    });
  };

  const onGetCountry = () => {
    let lang = "";
    let isTH = (getLanguage().id === "th");
    isTH ? lang = "th" : lang = "en"
    AxiosGet(`DataFilter/SetFilter_SelectCountry`, { lang }, (res) => {
      setRawCountry(res.lstOptionAdmin || []);
      // setTimeout(() => {
      form.setValue('nCountryID', res.lstThailand[0]);
      // }, 1000);
    });
  };

  const onGetRegion = () => {
    let lang = "";
    let isTH = (getLanguage().id === "th");
    isTH ? lang = "th" : lang = "en"
    AxiosGet(`DataFilter/SetFilter_SelectRegion`, { lang }, (res) => {
      setRawRegion(res.lstOptionAdmin);
    });
  };

  const onGetProvince = () => {
    let lang = "";
    let isTH = (getLanguage().id === "th");
    isTH ? lang = "th" : lang = "en"
    AxiosGet(`DataFilter/SetFilter_SelectProvince`, { lang }, (res) => {
      setRawProvince(res.lstOptionAdmin);
    });
  };

  return (
    <FormProvider {...form}>
      <Grid container spacing={2}>
        <Grid item container xs={12}>
          <Grid item md={12}>
            <Typography variant="subtitle1">
              {i18n(`${i18nField}.TitleRegister`)}
              <Divider />
            </Typography>
          </Grid>
        </Grid>

        <Grid item md={12} xs={12}>
          <Input
            name={"sEmail"}
            labelString={i18n(`${i18nField}.Email`)}
            required
            fullWidth
            onBlur={(value) => {
              CheckExistMail(value.target.value);
              // CheckExist(value.target.value);
            }}
            label={
              <>
                <Typography sx={{ fontWeight: 400 }} component="label">
                  {i18n(`${i18nField}.Email`)}
                </Typography>
              </>
            }
          />
          {isEmailDup ? (
            <FormHelperText style={{ color: "red" }}>
              {i18n(`${i18nField}.ErrorEmail`)}
            </FormHelperText>
          ) : null}
        </Grid>

        <Grid item md={12} xs={12}>
          <Input
            name={"sRegister_Name"}
            labelString={i18n(`${i18nField}.RegisterName`)}
            required
            fullWidth
            // onBlur={(value) => {
            //   CheckExistRegistName(value.target.value);
            //   // CheckExist(value.target.value);
            // }}
            label={
              <>
                <Typography sx={{ fontWeight: 400 }} component="label">
                  {i18n(`${i18nField}.RegisterName`)}
                </Typography>
              </>
            }
          />
          {/* {isRegistDup ? (
            <FormHelperText style={{ color: "red" }}>
              {i18n(`${i18nField}.ErrorRegistName`)}
            </FormHelperText>
          ) : null} */}
        </Grid>

        <Grid item md={12} xs={12}>
          <Input
            name={"sCompany_Name"}
            labelString={i18n(`${i18nField}.CompanyName`)}
            required
            fullWidth
            onBlur={(value) => {
              CheckExistEmployerName(value.target.value);
              // CheckExist(value.target.value);
            }}
            label={
              <>
                <Typography sx={{ fontWeight: 400 }} component="label">
                  {i18n(`${i18nField}.CompanyName`)}
                </Typography>
              </>
            }
          />
          {isEmployerDup ? (
            <FormHelperText style={{ color: "red" }}>
              {i18n(`${i18nField}.ErrorEmployer`)}
            </FormHelperText>
          ) : null}
        </Grid>

        <Grid item md={12} xs={12}>
          <AutoCompleteSelect
            required
            fullWidth
            name={"nIndustryTypeID"}
            label={i18n(`${i18nField}.Industry`)}
            options={rawIndustrytype}
            onChange={(v) => {
              form.setValue('nIndustryTypeID', v)
            }}
          />
        </Grid>

        {/* Address */}
        <Grid item md={12} xs={12}>
          <Grid item container spacing={2} xs={12}>
            <Grid item md={4} xs={12} >
              <AutoCompleteSelect
                required
                fullWidth
                name={"nCountryID"}
                label={i18n(`${i18nField}.Country`)} //country
                options={rawCountry}
                onChange={(v) => {
                  if (v && v.value !== '519') {
                    setIsInter(true);
                    form.clearErrors("nRegionID");
                    form.clearErrors("nProvinceID");
                    form.setValue("nRegionID", 0);
                    form.setValue("nProvinceID", 0);
                  } else {
                    setIsInter(false);
                  }
                  form.setValue('nCountryID', v)
                }}
              />
            </Grid>
            {IsInter ?
              null : (
                <>
                  <Grid item md={4} xs={12} >
                    <AutoCompleteSelect
                      fullWidth
                      required
                      name={"nRegionID"}
                      label={i18n(`${i18nField}.Region`)} //region
                      options={rawRegion.filter((f) => f.value != 1779)}
                      onChange={(v) => {
                        if (v && v.value !== "nRegionID") {
                          form.clearErrors("nProvinceID");
                          form.setValue("nProvinceID", 0);
                        } else {
                          form.clearErrors("nProvinceID");
                          form.setValue('nProvinceID', v ? v : 0);
                        }
                        setProvinceParent(v ? v.value : 0);
                      }}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <AutoCompleteSelect
                      fullWidth
                      required
                      name={"nProvinceID"}
                      label={i18n(`${i18nField}.Province`)} //province
                      options={rawProvince.filter(f => f.sParent == ProvinceParent)}
                      onChange={(v) => {
                        form.setValue('nProvinceID', v ? v : null)
                      }}
                    />
                  </Grid>
                </>)}
          </Grid>
        </Grid>

        <Grid item md={12} xs={12}>
          <Grid item container spacing={2} xs={12}>{IsInter ?
            null : (
              <>
                <Grid item md={2} xs={12}>
                  <Input
                    required
                    fullWidth
                    name={"sPostCode"}
                    label={
                      <>
                        <Typography sx={{ fontWeight: 400 }} component="label">
                          {i18n(`${i18nField}.PostCode`)}
                        </Typography>
                      </>
                    }
                    // label={i18n(`${i18nField}.PostCode`)} //PostCode
                    maxLength={5}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Input
                    required
                    fullWidth
                    name={"sStreet"}
                    label={
                      <>
                        <Typography sx={{ fontWeight: 400 }} component="label">
                          {i18n(`${i18nField}.Street`)}
                        </Typography>
                      </>
                    }
                    // label={i18n(`${i18nField}.Street`)} //street
                    maxLength={500}
                  />
                </Grid>
                <Grid item md={2} xs={12}>
                  <Input
                    required
                    fullWidth
                    name={"sMoo"}
                    label={
                      <>
                        <Typography sx={{ fontWeight: 400 }} component="label">
                          {i18n(`${i18nField}.Moo`)}
                        </Typography>
                      </>
                    }
                    // label={i18n(`${i18nField}.Moo`)} //moo
                    maxLength={3}
                  />
                </Grid>
                <Grid item md={2} xs={12}>
                  <Input
                    required
                    fullWidth
                    name={"sAddressNo"}
                    label={
                      <>
                        <Typography sx={{ fontWeight: 400 }} component="label">
                          {i18n(`${i18nField}.AddressNo`)}
                        </Typography>
                      </>
                    }
                    // label={i18n(`${i18nField}.AddressNo`)} //address no.
                    maxLength={20}
                  />
                </Grid>
              </>)}
          </Grid>
        </Grid>

        <Grid item md={12} xs={12}>
          <Input
            name={"sUser_Tel"}
            labelString={i18n(`${i18nField}.Phone`)}
            required
            fullWidth
            label={
              <>
                <Typography sx={{ fontWeight: 400 }} component="label">
                  {i18n(`${i18nField}.Phone`)}
                </Typography>
              </>
            }
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <Input
            name={"sUser_PW"}
            labelString={i18n(`${i18nField}.Password`)}
            required
            fullWidth
            type={showPassword ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  edge="end"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label={
              <>
                <Typography sx={{ fontWeight: 400 }} component="label">
                  {i18n(`${i18nField}.Password`)}
                </Typography>
              </>
            }
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <Input
            name={"sConfirmPWD"}
            labelString={i18n(`${i18nField}.ConfirmPass`)}
            required
            fullWidth
            type={showCPassword ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle p0assword visibility"
                  edge="end"
                  onClick={() => setShowCPassword(!showCPassword)}
                >
                  {showCPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label={
              <>
                <Typography sx={{ fontWeight: 400 }} component="label">
                  {i18n(`${i18nField}.ConfirmPass`)}
                </Typography>
              </>
            }
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <FormControl required error={errorConsent} component="fieldset" variant="standard" >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isCheckedConsent1}
                    onChange={() => {
                      SetIsCheckedConsent1((prev) => !prev);
                      if (isCheckedConsent2) {
                        setErrorConsent(false)
                      }
                    }}
                  />
                }
                label={i18n(`${i18nField}.ConfirmRegisDoc`)}//"ต้องการรับข่าวสาร และข้อมูลที่เกี่ยวข้องต่างๆ"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isCheckedConsent2}
                    onChange={() => {
                      SetIsCheckedConsent2((prev) => !prev);
                      if (isCheckedConsent2) {
                        setErrorConsent(false)
                      }
                    }}
                  />
                }
                label={i18n(`${i18nField}.ConfirmRegis`)}//"ข้าพเจ้าขอยอมรับทุกข้อกำหนดและเงื่อนไขต่างๆที่ BPS กำหนด"
              />
            </FormGroup>
            <Typography sx={{ color: "#b2b2b2", fontSize: "14px", pt: "15px" }}>
              {i18n(`${i18nField}.Policy`)}{" "}

              <span
                style={{ color: "#2979ff", cursor: "pointer" }}
                onClick={() => onPreview(3)}
              >
                {i18n(`${i18nField}.Policy2`)}{" "}
              </span>

              {i18n(`${i18nField}.Policy3`)}{" "}

              <span
                style={{ color: "#2979ff", cursor: "pointer" }}
                onClick={() => onPreview(1)}
              >
                {i18n(`${i18nField}.Policy4`)}{" "}
              </span>

              {i18n(`${i18nField}.Policy5`)}
            </Typography>
            {errorConsent ? (<FormHelperText>{i18n(`${i18nField}.ErrorConsent`)}</FormHelperText>) : null}
          </FormControl>
        </Grid>

        {/* Button */}
        <Grid item xs={12}>
          <Grid container spacing={1} sx={{ justifyContent: "center" }}>
            <Grid item>
              <ButtonBlueRadius1 onClick={form.handleSubmit((e) => {
                onSubmit(e);
              })}>{i18n(`${i18nField}.BtnRegister`)}</ButtonBlueRadius1>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Dialog
        fullScreen
        open={isShowPreview}
        onClose={() => setIsShowPreview(false)}
      >
        <AppBar sx={{ position: "relative", marginBottom: "3rem" }}>
          <Toolbar>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => setIsShowPreview(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Grid container>
          {/* <Grid item sx={{ mx: "25%" }}> */}
          <Grid xs={12} item sx={{ mx: "11%" }}>
            <ContentPage lstContent={lstContent} OrderEmp={false} sContendHead={undefined} />
          </Grid>
        </Grid>
      </Dialog>
    </FormProvider>
  );
};
export default RegisterEmployerForm;
