import { Box, FormControlLabel, Grid, Stack, TextField, Typography } from '@mui/material';
import { FormProvider, useForm } from "react-hook-form";
import { useEffect, useState } from 'react';
import Input from "components/Common/ElementInForm/InputCustomLabelFormItem";
import { useLocation, useNavigate } from "react-router-dom";

// #region step 1 YUPValidate ใช้สำหรับ form  ทั่วไปที่ dymanic(dymanic : สามารถกด add ได้เลื่อยๆเราต้องทำเอง)
import yupFormSchemas from "../../../Common/yup/yupFormSchemas";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { BtnBack, BtnSave } from "../../../Common/Button";
import DatePickerFormItem from 'components/Common/ElementInForm/DatePickerFormItem';
import IOSSwitch from 'components/Common/ElementInForm/IosSwitch';
import { AxiosGet, AxiosPost, GetPermission, ResultAPI } from 'service/CommonFunction/TS_function';
import { useDispatch } from 'react-redux';
import { DialogActionCreators } from 'store/redux/DialogAlert';
import { PaginationInterface } from 'components/Common/Table/DataGridMui';
import BPS_JobPackageLogs from './BPS_JobPackageLogs';
import { i18n } from 'i18n';
import AutoCompleteSelect from "components/Common/ElementInForm/AutoCompleteSelect";
import moment from 'moment';
import { fromUnixTime } from 'date-fns';
import InputNumber from 'components/Common/ElementInForm/InputNumber';
import { Language } from '@mui/icons-material';

//#endregion
const FromCreateJobPackage = () => {
    //#region ตัวแปร
    const location = useLocation();
    const searchParams = location.search;
    const sID = new URLSearchParams(searchParams).get('sID');
    const [isDataActive, setIsActive] = useState(true);
    const history = useNavigate();
    const dispatch = useDispatch();
    const [arrDataType, setarrDataType] = useState([]);
    const [nPermission, setPermission] = useState(0);
    const [nOder_ID, setnOder_ID] = useState(0);
    const isEditMode = sID ? true : false;
    const i18nField = 'entities.Package';
    const onBack = () => {
        history("/ShowListPackage")
    }
    const [StartDate, setStartDate] = useState(moment());
    //#endregion

    //#region  step 2 ระบุบ filed ส่วนที่จำเป็นต้องมี
    //step 2.1 เอาข้อมูลชุดนี้ไปทำเป็น object
    const objSchema = {
        sPackageName: yupFormSchemas.string("ชื่อแพ็กเกจ (Free Token Package & Period)", { required: true, isAdmin: true }),
        nPostAmoun: yupFormSchemas.integer("จำนวน Job Post (Job Posting)", { required: true, min: 0, isAdmin: true }),
        nCoinAmoun: yupFormSchemas.integer("จำนวน Coin", { required: true, min: 0, isAdmin: true }),
        nExDurationAmoun: yupFormSchemas.integer("ระยะเวลาหมดอายุ (เดือน) (Period)", { required: true, isAdmin: true, min: 0, }),
        nTotalPrice: yupFormSchemas.decimal("ราคาสุทธิ (Net)", { required: true, isAdmin: true, min: 0, }),
        nSave: yupFormSchemas.decimal("ส่วนลด (% save)", { required: true, isAdmin: true, max: 100, min: 0, }),
        nNetPrice: yupFormSchemas.decimal("ราคาหลังคิดส่วนลด (Price Per Job)", { min: 0, isAdmin: true }),
        nVat: yupFormSchemas.decimal(" ภาษีมูลค่าเพิ่ม 7% (Vat 7%)", { isAdmin: true, min: 0, }),
        nPriceAfterVat: yupFormSchemas.decimal("ราคารวมทั้งสิ้น (Grand Total)", { isAdmin: true, min: 0, }),
        sNote: yupFormSchemas.string("หมายเหตุ"),
        dStartDate: yupFormSchemas.string("วันที่เริ่ม", { required: true, isAdmin: true }),
        dEndDate: yupFormSchemas.string("วันที่สิ้นสุด"),
        nNormalPrice: yupFormSchemas.decimal("ราคาปกติต่อ 1 Job (Normal Price/Job)", { isAdmin: true, min: 0, }),
        //  lstsOrderNo: yupFormSchemas.object(i18n(`${i18nField}.Order`), { required: true }),
    }


    //การนำข้อมูลมาทำเป็น yupobject
    // step 2.2 เอาข้อมูลชุดนี้ไปทำเป็น object
    const schema = yup.object().shape(objSchema);
    //#endregion

    //#region step 3 schema มาประกาศเป็น Form
    const form = useForm({
        resolver: yupResolver(schema),
        shouldUnregister: false,
        shouldFocusError: true,
        mode: "all",
    });
    //#endregion

    //#region event
    useEffect(() => {
        GetPermission(5, setPermission);
        setIsActive(isDataActive || false);
        onGetDropdown();
        form.setValue("nSave", "0");

    }, [])

    const onGetDropdown = () => {
        AxiosPost("AdminUserPackage/SetFilter_TableDataPackage", { nTypeTable: 1 },
            (res) => {
                setarrDataType(res.lstOptionOrer || []);
                onGetDetail();
            });
    };
    const onGetDetail = () => {
        AxiosPost("AdminUserPackage/GetDetail_CreatePackage", { sID: sID, nEdit: 1 }, (res) => {
            ResultAPI(res, "", () => {
                if (isEditMode) {
                    let resData = res.objDataPackage;
                    form.setValue("sPackageName", resData.sPackageName);
                    form.setValue("nPostAmoun", resData.nPostAmoun);
                    form.setValue("nCoinAmoun", resData.nCoinAmoun);
                    form.setValue("nExDurationAmoun", resData.nExDurationAmoun);
                    form.setValue("nTotalPrice", resData.nTotalPrice);
                    form.setValue("nSave", resData.nSave);
                    form.setValue("nNetPrice", resData.nNetPrice);
                    form.setValue("nVat", resData.nVat);
                    form.setValue("nPriceAfterVat", resData.nPriceAfterVat);
                    form.setValue("dStartDate", resData.dStartDate);
                    form.setValue("nNormalPrice", resData.nNormalPrice);
                    //   form.setValue("lstsOrderNo", { label: resData.lstsOrderNo, value: resData.lstsOrderNo });
                    //form.setValue("nOder_ID", resData.lstsOrderNo);
                    form.setValue("sNote", resData.sNote);
                    setIsActive(resData.isDataActive || false);
                    nSumnNetPrice();
                }
            });
        });
    }

    const onSubmit = (e) => {
        let nExDurationAmoun = (form.getValues("nExDurationAmoun"));
        let dStartDate = moment(form.getValues("dStartDate"));
        let sEnd = dStartDate.add(nExDurationAmoun, "month");
        form.setValue("dEndDate", sEnd);
        let objSaveData = {
            sPackageName: form.getValues("sPackageName"),
            nPostAmoun: parseInt(form.getValues("nPostAmoun")),
            nCoinAmoun: parseInt(form.getValues("nCoinAmoun")),
            nExDurationAmoun: parseInt(form.getValues("nExDurationAmoun")),
            nTotalPrice: parseFloat(form.getValues("nTotalPrice")),
            nSave: parseFloat(form.getValues("nSave")),
            nNetPrice: parseFloat(form.getValues("nNetPrice")),
            nVat: parseFloat(form.getValues("nVat")),
            nPriceAfterVat: parseFloat(form.getValues("nPriceAfterVat")),
            nNormalPrice: parseFloat(form.getValues("nNormalPrice")),
            sNote: form.getValues("sNote"),
            sStartDate: (form.getValues("dStartDate")),
            dEndDate: moment(form.getValues("dEndDate")).toDate(),
            nStartDate: moment(form.getValues("dStartDate")).valueOf(),
            nEndDate: moment(form.getValues("dEndDate")).valueOf(),
            isDataActive: isDataActive,
            nType: 1,
            // lstsOrderNo: +(form.getValues("lstsOrderNo").value),
            sID: isEditMode ? sID : "",
            nMenuID: 4
        }

        dispatch(DialogActionCreators.OpenDialogSubmit("คุณต้องการบันทึกข้อมูลหรือไม่ ?", () => {
            dispatch(DialogActionCreators.CloseDialogSubmit() as any);
            AxiosPost("AdminUserPackage/SaveData_FromCreatePackage", objSaveData, (result) => {
                ResultAPI(result, i18n(`common.AlertConfrimSave`), () => {
                    dispatch(DialogActionCreators.OpenDialogSuccess("บันทึกข้อมูลสำเร็จ",
                        () => onBack()
                    ) as any);
                });
            }, (err) => {
                if (err.Status === 409) {
                    dispatch(DialogActionCreators.OpenDialogWarning("ชื่อแพ็กเกจนี้ถูกใช้งานในระบบแล้ว", () => {
                    }) as any)
                }
            });

        }) as any);
    };
    // const onBack = () => {
    //     history(-1)
    // };

    const nSumnNetPrice = () => {
        let nPostAmoun = form.getValues("nPostAmoun");
        let nNetPrice = form.getValues("nNetPrice");
        let nSave = form.getValues("nSave");
        let nTotalPrice = nNetPrice * nPostAmoun;
        let nVat = ((nTotalPrice * 7) / 100);
        let nPriceAfterVat = nTotalPrice + nVat;
        form.setValue("nTotalPrice", nTotalPrice);
        form.setValue("nVat", nVat);
        form.setValue("nPriceAfterVat", nPriceAfterVat);
        form.setValue("nSave", nSave);
    };

    //#endregion
    return (
        <FormProvider {...form}>
            <Grid className="FromCreateJobPackage">
                <Stack spacing={3} direction="column" >
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Input
                                    name={"sPackageName"}
                                    fullWidth
                                    maxLength={500}
                                    required
                                    label={"ชื่อแพ็กเกจ (Free Token Package & Period)"}
                                    disabled={nPermission !== 2}
                                />
                            </Grid>
                            <Grid item lg={4} xs={12} >
                                <InputNumber
                                    name={"nPostAmoun"}
                                    required
                                    label={"จำนวน Job Post (Job Posting)"}
                                    disabled={nPermission !== 2}

                                    fullWidth
                                />
                            </Grid>
                            <Grid item lg={4} xs={12}>
                                <InputNumber
                                    name={"nCoinAmoun"}
                                    required
                                    label={"จำนวน Coin"}
                                    disabled={nPermission !== 2}

                                    fullWidth
                                />
                            </Grid>
                            <Grid item lg={4} xs={12}>
                                <Input
                                    name={"nExDurationAmoun"}
                                    required
                                    label={"ระยะเวลาหมดอายุ (เดือน) (Period)"}
                                    disabled={nPermission !== 2}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item lg={4} xs={12}>
                                <InputNumber
                                    name={"nNormalPrice"}
                                    required
                                    label="ราคาปกติต่อ 1 Job (Normal Price/Job)"
                                    fullWidth
                                    //   type="number"
                                    disabled={nPermission !== 2}
                                    decimalPoint={2}
                                />

                            </Grid>
                            <Grid item lg={4} xs={12}>
                                <Input
                                    name={"nSave"}
                                    required
                                    label={"ส่วนลด (% save)"}//ส่วนลด (%)
                                    //   onBlur={nSumnNetPrice}
                                    type="number"
                                    disabled={nPermission !== 2}
                                />
                            </Grid>
                            <Grid item lg={4} xs={12}>
                                <InputNumber
                                    name={"nNetPrice"}
                                    required
                                    fullWidth
                                    // type="number"
                                    label="ราคาหลังคิดส่วนลด (Price Per Job)"
                                    onBlur={nSumnNetPrice}
                                    disabled={nPermission !== 2}
                                    decimalPoint={2}
                                />
                            </Grid>
                            <Grid item lg={4} xs={12}>
                                <InputNumber
                                    name={"nTotalPrice"}
                                    required
                                    label={"ราคาสุทธิ (Net)"}//ราคารวมหลังคิดส่วนลด * job
                                    //  type="number"
                                    disabled={true}
                                    decimalPoint={2}
                                    fullWidth
                                />

                            </Grid>
                            <Grid item lg={4} xs={12}>
                                <InputNumber
                                    name={"nVat"}
                                    required
                                    label={"ภาษีมูลค่าเพิ่ม 7% (Vat 7%)"} //ภาษี
                                    disabled={true}
                                    fullWidth
                                    decimalPoint={2}
                                />
                            </Grid>
                            <Grid item lg={4} xs={12}>
                                <InputNumber
                                    name={"nPriceAfterVat"}
                                    required
                                    label={"ราคารวมทั้งสิ้น (Grand Total)"}//ราคารวมทั้งสิ้น
                                    decimalPoint={2}
                                    disabled={true}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item lg={2} xs={12}>
                                <DatePickerFormItem
                                    name={"dStartDate"}
                                    label={"วันที่เริ่ม"}
                                    required
                                    disabled={nPermission !== 2}
                                    minDate={StartDate}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Input
                                    name={"sNote"}
                                    rows={3}
                                    multiline
                                    maxLength={3000}
                                    label={"หมายเหตุ"}
                                    disabled={nPermission !== 2}
                                />
                            </Grid>
                        </Grid>
                    </Grid>



                    <Grid item spacing={3} xs={12}>
                        <Typography>
                            <span style={{ fontWeight: 600 }}> สถานะ </span>
                            <span style={{ color: "red" }}> *</span>
                        </Typography>
                        <Typography>
                            <FormControlLabel
                                control={
                                    <IOSSwitch
                                        sx={{ m: 1 }}
                                        onChange={() => setIsActive(!isDataActive)}
                                        checked={isDataActive}
                                        disabled={nPermission !== 2}
                                    />
                                }
                                label={isDataActive ? "ใช้งาน" : "ไม่ใช้งาน"}
                            />
                        </Typography>
                    </Grid>

                    <Grid item spacing={3} xs={12}>
                        <Stack direction="row" spacing={3}>
                            <Box sx={{ flex: 1 }}>

                                <BtnBack
                                    txt='ย้อนกลับ'
                                    onClick={() => onBack()} />
                            </Box>
                            <BtnSave
                                txt='บันทึก'
                                IsDisabled={nPermission !== 2} onClick={form.handleSubmit(e => (
                                    onSubmit(e)
                                ))} />
                        </Stack>
                    </Grid>
                </Stack>

                <Grid item spacing={50} sx={{ marginTop: "1%", marginBottom: "1%", width: "100%" }}>
                </Grid>
            </Grid>

        </FormProvider>

    );
}
export default FromCreateJobPackage
