import React, { useState, useEffect, Fragment } from 'react';
import { useLocation, useNavigate } from "react-router-dom"
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { DialogActionCreators } from 'store/redux/DialogAlert';
import { Stack, Typography, Divider, Button, Box, FormControl, FormControlLabel, Radio, RadioGroup, Grid } from "@mui/material"
import Input from "components/Common/ElementInForm/InputCustomLabelFormItem"
import UploadFile from "components/Common/ElementInForm/UploadFile/UploadFile"
import { Extension, ResultAPI } from "service/CommonFunction/TS_function";
import { BtnBack, BtnSave, BtnPreview } from "components/Common/Button"
import AutoComplete from "components/Common/ElementInForm/AutoCompleteRenderItem"
import { Add } from "@mui/icons-material"
import { cFile } from 'service/CommonInterface/CommonInterface';
import yupFormSchemas from 'components/Common/yup/yupFormSchemas';
import { useDispatch } from "react-redux"
import { AxiosPost, GetPermission } from "service/CommonFunction/TS_function";
import DialogPreview from "components/DialogPreview/DialogPreview";
// import ArticleDetailContent from "view/FontSite/Article/ArticleDetailComponent"
import { MainHome } from "styles_font"
import {
    saveData, objContent, dataSource, dataContent,
    saveDataContent, saveDataGallery, saveDataSource
} from "./ManageContent"

import BoxContent from './BoxContent';
import { idID } from '@mui/material/locale';
import BPS_Content from 'view/font/BPS/BPS_Content';
import moment from 'moment';
import { i18n } from 'i18n';
// import { dataContent, dataSource, objContent } from './ManageContent';

export default function AdminContent() {
    const i18nField = 'entities.AdminContent';
    const i18nommon = 'common';
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useNavigate();
    const searchParams = location.search;
    const sID = new URLSearchParams(searchParams).get('sID');
    const isEditMode = sID ? true : false;
    // const bIsArticle = location.pathname.toLocaleLowerCase() == "/managearticledetail";
    // const sPath = bIsArticle ? "ManageArticle" : "ManageNews";
    const [lstOrder, setLstOrder] = useState<number[]>([1]);
    const [arrFile, setArrFile] = useState([]);
    const [lstSeverityData, setLstSeverityData] = useState([]);

    const [objStateContent, setObjStateContent] = useState({
        nTypeContent_1: 1
    });
    const [arrFilleGallery, setArrFilleGallery] = useState([]);
    const [lstComponentSource, setLstComponentSource] = useState<dataSource[]>([]);
    const [lstOrderSource, setLstOrderSource] = useState<number[]>([]);
    const [vSeverity, setvSeverity] = useState<any>();
    const [lstComponent, setLstComponent] = useState<dataContent[]>([]);
    const [isShowPreview, setIsShowPreview] = useState<boolean>(false);
    const [lstContentPreview, setLstPreviewContent] = useState<objContent[]>([]);
    const [lstSourceContent, setLstSourceContent] = useState<dataSource[]>([])
    const [lstUrlGalleryPreview, setLstUrlGallertPreview] = useState<any[]>([]);
    const [isAddAction, setIsAddAction] = useState(false);
    const [isChangeOrder, setIsChangeOrder] = useState(false);
    const [initialValues] = useState(() => {
        return {
            lstTag: []
        };
    });
    const [nPermission, setPermission] = useState(0);
    const [objSchema, setObjSchema] = useState({
    });
    const schema = yup.object().shape(objSchema);
    const form = useForm({
        resolver: yupResolver(schema),
        shouldUnregister: false,
        shouldFocusError: true,
        mode: "all",
        defaultValues: initialValues as any,
    });
    const dateNow = new Date();
    const sDateNow = moment(dateNow).format('DD MMM yyyy');

    useEffect(() => {
        GetPermission(14, setPermission);
        AxiosPost("BPSAdminContent/GetDetail_AdminDataType", { sID: sID }, (res) => {
            if (res.Status == 200) {
                if (isEditMode) {
                    let resData = res.lstEdit[0];
                    form.setValue("sPageName", resData.sPageName);

                    let lstTmpComponent: dataContent[] = [];
                    let lstOrder = [];
                    resData.lstSaveDataContent.forEach((e, i) => {
                        objStateContent[`nTypeContent_${i + 1}`] = e.nTypeID
                        if (e.nTypeID == 2 || e.nTypeID == 3) {
                            e.fImageOrVideo.sFileType = e.fImageOrVideo.sSysFileName.split('.')[1]
                            objStateContent[`${e.nTypeID == 2 ? "fFileImg_" : "fFileVideo_"}${i + 1}`] = [e.fImageOrVideo]
                        }
                        let dataComponent: dataContent = {
                            nType: e.nTypeID,
                            fFileImageOrVideo: e.nTypeID == 2 || e.nTypeID == 3 ? [e.fImageOrVideo] : [],
                            sContent: e.sContent,
                            nOrder: i + 1,
                            id: i + 1
                        }

                        switch (e.nTypeID) {
                            case 1:
                                objStateContent[`sContentOnly_${i + 1}`] = e.sContent
                                break;
                            case 2:
                                objStateContent[`sContentBottomImg_${i + 1}`] = e.sContent
                                break;
                            case 3:
                                objStateContent[`sContentBottomVideo_${i + 1}`] = e.sContent
                                break;
                            case 4:
                                objStateContent[`sUrl_${i + 1}`] = e.sUrl
                                objStateContent[`sContentBottomUrl_${i + 1}`] = e.sContent
                                break;
                        }
                        setObjStateContent({ ...objStateContent })
                        lstTmpComponent.push(dataComponent)
                        lstOrder.push(i + 1);
                    });
                    setLstOrder(lstOrder)
                    setLstComponent(lstTmpComponent)
                    setvSeverity(resData.sIDSeverity)
                    setIsChangeOrder(true)
                } else {
                    setLstComponent([
                        { nType: 1, sContent: "", fFileImageOrVideo: [], id: 1, nOrder: 1 }
                    ]);
                }
            } else {
                dispatch(DialogActionCreators.OpenDialogWarning("Data not found.") as any)
            }
        }, (err) => {
            if (err.response && err.response.status === 401) {
                dispatch(DialogActionCreators.OpenDialogWarning("Token expired.", () => {
                    history("/")
                }) as any)
            }
        });
    }, [])

    const onSubmit = (e) => {
        dispatch(DialogActionCreators.OpenDialogSubmit("คุณต้องการบันทึกข้อมูลหรือไม่", () => {
            dispatch(DialogActionCreators.CloseDialogSubmit() as any);
            let lstDataContent: saveDataContent[] = [];
            lstComponent.forEach((f, i) => {
                let objDataContent: saveDataContent = {
                    nOrder: i + 1,
                    nTypeID: objStateContent[`nTypeContent_${f.id}`]
                }
                switch (objStateContent[`nTypeContent_${f.id}`]) {
                    case 1:
                        objDataContent.sContent = objStateContent[`sContentOnly_${f.id}`];
                        break;
                    case 2:
                        objDataContent.fImageOrVideo = objStateContent[`fFileImg_${f.id}`][0];
                        objDataContent.sContent = e[`sTextBottomImage_${f.id}`];
                        break;
                    case 3:
                        objDataContent.fImageOrVideo = objStateContent[`fFileVideo_${f.id}`][0];
                        objDataContent.sContent = e[`sTextBottomVideo_${f.id}`];
                        break;
                    case 4:
                        objDataContent.sContent = e[`sTextBottomUrl_${f.id}`];
                        break;
                }
                lstDataContent.push(objDataContent)
            })

            let objSaveData: saveData = {
                lstDataContent: lstDataContent,
                sID: isEditMode ? sID : "",
                sPageName: e['sPageName']
            }

            AxiosPost("BPSAdminContent/SaveData_ContentDetails", objSaveData, (res) => {
                if (res.Status == 200) {
                    dispatch(DialogActionCreators.OpenDialogSuccess("บันทึกข้อมูลสำเร็จ", () => onBack()) as any)
                } else {
                    dispatch(DialogActionCreators.OpenDialogWarning(res.Message) as any)
                }
            }, (err) => {
                if (!err.response) {
                    history("/error", null)
                    return;
                }
                if (err.response && err.response.status === 401) {
                    dispatch(DialogActionCreators.OpenDialogWarning("Token expired.", () => {
                        history("/")
                    }) as any)
                }
            });
        }) as any);
    }

    const AddComponent = () => {
        const maxId = lstComponent.reduce(
            (max, objItem) => (objItem.id > max ? objItem.id : max),
            lstComponent[0].id
        );

        let objDataComponent: dataContent = {
            nType: 1, sContent: "", fFileImageOrVideo: [], id: maxId + 1, nOrder: lstOrder.length + 1
        }
        setIsAddAction(true);
        setObjStateContent({ ...objStateContent });
        setLstComponent([...lstComponent, objDataComponent])
        setLstOrder([...lstOrder, lstOrder.length + 1])
    }

    const onPreview = () => {
        let lstPreviewContent: objContent[] = []
        lstComponent.forEach((f, i) => {
            const nType = objStateContent[`nTypeContent_${f.id}`]
            let sContent = ""
            switch (nType) {
                case 1:
                    sContent = objStateContent[`sContentOnly_${f.id}`]
                    break;
                case 2:
                    sContent = form.getValues(`sTextBottomImage_${f.id}`);
                    break;
                case 3:
                    sContent = form.getValues(`sTextBottomVideo_${f.id}`);
                    break;
                case 4:
                    sContent = form.getValues(`sTextBottomUrl_${f.id}`);
                    break;
            }
            let fileContent = "";
            if (nType == 2 && objStateContent[`fFileImg_${f.id}`] && objStateContent[`fFileImg_${f.id}`].length > 0) {
                fileContent = objStateContent[`fFileImg_${f.id}`][0].sFileLink;
            } else if (nType == 3 && objStateContent[`fFileVideo_${f.id}`] && objStateContent[`fFileVideo_${f.id}`].length > 0) {
                fileContent = objStateContent[`fFileVideo_${f.id}`][0].sFileLink;
            }
            let objViewContent: objContent = {
                nTypeId: nType,
                sContent: sContent,
                sFileNameSys: fileContent
            }
            lstPreviewContent.push(objViewContent)
        })
        setLstPreviewContent(lstPreviewContent)
        setIsShowPreview(true)
    }

    const onBack = () => {
        history(-1);
    }

    return <FormProvider {...form}>
        <DialogPreview open={isShowPreview}
            handleClose={() => setIsShowPreview(false)}
            Content={() => (<Box className="Prompt" sx={MainHome}>
                <BPS_Content
                    lstTag={form.getValues("lstTag")}
                    sImg={arrFile.length > 0 ? `${arrFile[0].sFileLink}` : ""}
                    sHeadline={form.getValues("sPageName")}
                    sUpdateDate={false}
                    sDesc={null}
                    lstContent={lstContentPreview}
                    lstSource={null}
                    lstGallery={null}
                />
            </Box>)
            }
        />

        <Grid container>
            <Grid item xs={12} sx={{ mx: "2%" }}>
                <Grid item>
                    <Grid item sx={{ marginBottom: "2%" }}>
                        <Input
                            disabled={nPermission !== 2}
                            name={"sPageName"}
                            labelString={"ชื่อหน้า"}
                            fullWidth
                            maxLength={100}
                            multiline={true}
                            // rows={3}
                            label={
                                <>
                                    <Typography sx={{ fontWeight: 600 }} component="label">{"ชื่อหน้า"}</Typography>
                                </>
                            }
                        />
                    </Grid>
                    <Grid item>
                        <Typography sx={{ fontWeight: 600 }} component="label">{"เนื้อหา"}</Typography>
                        <Typography component="label" sx={{ color: "red", margin: "0 2px" }}>{" * "}</Typography>
                    </Grid>
                    <Stack sx={{ marginTop: "0px !important", border: "1px #eeeeee solid", backgroundColor: "rgba(255,255,255,0.75)", borderRadius: "5px", p: 2, flex: 1 }} spacing={2} direction="column">
                        {lstComponent.map((item, index) => {
                            return (<Fragment key={`f_${index}`}>
                                {index > 0 ? <Divider key={`divider_c_${index}`} sx={{ marginTop: "24px !important" }} /> : null}
                                <BoxContent key={`box_form_c_${index}`} objData={item} lstComponent={lstComponent} setLstComponent={setLstComponent} menuID={12}
                                    objSchema={objSchema}
                                    setSchema={setObjSchema}
                                    objStateContent={objStateContent}
                                    setObjStateContent={setObjStateContent}
                                    lstOrder={lstOrder} setLstOrder={setLstOrder} nOrder={index + 1}
                                    disabled={nPermission !== 2}
                                    isAddAction={isAddAction}
                                    setIsAddToDefault={() => setIsAddAction(false)}
                                    setIsChangeOrder={(e) => setIsChangeOrder(e)}
                                    isChangeOrder={isChangeOrder}
                                    isAddPic={true}
                                    isEmployerSetting={false}
                                />
                            </Fragment>)
                        })}
                        <Stack direction="row">
                            <Button sx={{ borderRadius: "20px" }} startIcon={<Add />} onClick={() => AddComponent()} disabled={nPermission != 2} fullWidth variant="contained" >{"เพิ่มเนื้อหา"}</Button>
                        </Stack>
                    </Stack>
                </Grid>
            </Grid>

            <Divider />

            <Grid container sx={{ my: "2%", mx: "2%" }}>
                
                <Grid item xs={6} justifyContent={"start"}>
                    <BtnBack txt={'ย้อนกลับ'} onClick={() => onBack()} />
                </Grid>
                <Grid container justifyContent={"end"}>
                    <Grid item sx={{ mx: "2%" }}>
                        <BtnPreview IsDisabled={nPermission !== 2} txt={'ตัวอย่าง'} onClick={() => onPreview()} />
                    </Grid>

                    <Grid item justifyContent={"end"}>
                        <BtnSave IsDisabled={nPermission !== 2} txt={'บันทึก'} onClick={form.handleSubmit(e => (
                            onSubmit(e)
                        ))} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </FormProvider >;
}