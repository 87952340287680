import { Autocomplete, Box, FormControlLabel, Grid, Stack, TextField, Typography } from '@mui/material';
import { FormProvider, useForm } from "react-hook-form";
import { useState, useEffect } from 'react';
import Input from "components/Common/ElementInForm/InputCustomLabelFormItem";
import { useNavigate, useLocation } from "react-router-dom";

// #region step 1 YUPValidate ใช้สำหรับ form  ทั่วไปที่ dymanic(dymanic : สามารถกด add ได้เลื่อยๆเราต้องทำเอง)
import yupFormSchemas from "../../../Common/yup/yupFormSchemas";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { BtnBack, BtnSave } from "../../../Common/Button";
import DatePickerFormItem from 'components/Common/ElementInForm/DatePickerFormItem';
import IOSSwitch from 'components/Common/ElementInForm/IosSwitch';
import { AxiosPost, GetPermission, ResultAPI } from 'service/CommonFunction/TS_function';
import { useDispatch } from 'react-redux';
import { DialogActionCreators } from 'store/redux/DialogAlert';
import BPS_CoinPackageLogs from './BPS_CoinPackageLogs';
import { i18n } from 'i18n';
import moment from 'moment';
import AutoCompleteSelect from "components/Common/ElementInForm/AutoCompleteSelect";
import InputNumber from 'components/Common/ElementInForm/InputNumber';


//#endregion


const FromCreateCoinPackage = () => {
    //#region ตัวแปร
    const location = useLocation();
    const searchParams = location.search;
    const sID = new URLSearchParams(searchParams).get('sID');
    const history = useNavigate();
    const dispatch = useDispatch();
    const [isDataActive, setIsActive] = useState(true);
    const [arrDataType, setarrDataType] = useState([]);
    const isEditMode = sID ? true : false;
    const [nPermission, setPermission] = useState(0);
    const [nOder_ID, setnOder_ID] = useState(0);
    const i18nField = 'entities.Package';
    const onBack = () => {
        history("/ShowListPackage")
    }
    const [StartDate, setStartDate] = useState(moment());
    //#endregion

    //#region  step 2 ระบุบ filed ส่วนที่จำเป็นต้องมี
    const objSchema = {
        sPackageName: yupFormSchemas.string("ชื่อแพ็กเกจ", { required: true, isAdmin: true }),
        nCoinAmoun: yupFormSchemas.integer("จำนวน Coin (ตามแพ็กเกจ)", { required: true, isAdmin: true, min: 0 }),
        nCoinFree: yupFormSchemas.integer("จำนวน Coin (Free)", { required: true, isAdmin: true, min: 0 }),
        nTotalCoin: yupFormSchemas.integer("จำนวน Coin ", { required: true, isAdmin: true, min: 0 }),
        nExDurationAmoun: yupFormSchemas.integer("ระยะเวลาหมดอายุ (เดือน) (Period)", { required: true, isAdmin: true, min: 0 }),
        nTotalPrice: yupFormSchemas.decimal("ราคาสุทธิ (Special Price)", { required: true, isAdmin: true, min: 0 }),
        nSave: yupFormSchemas.decimal("ส่วนลด (% save)", { required: true, isAdmin: true, max: 100, min: 0 }),
        nNetPrice: yupFormSchemas.decimal("รวมเป็นเงิน", { isAdmin: true, min: 0 }),
        nVat: yupFormSchemas.decimal("ภาษีมูลค่าเพิ่ม 7% (Vat 7%)", { isAdmin: true, min: 0 }),
        nPriceAfterVat: yupFormSchemas.decimal("ราคารวมทั้งสิ้น (Grand Total)", { isAdmin: true, min: 0 }),
        sNote: yupFormSchemas.string("หมายเหตุ"),
        dStartDate: yupFormSchemas.string("วันที่เริ่ม", { required: true, isAdmin: true }),
        dEndDate: yupFormSchemas.string(i18n(`${i18nField}.EndDate`)),
        nNormalPrice: yupFormSchemas.decimal(i18n(`${i18nField}.NormalPrice`), { isAdmin: true, min: 0 }),
        // lstsOrderNo: yupFormSchemas.object(i18n(`${i18nField}.Order`), { required: true }),
    }

    const schema = yup.object().shape(objSchema);
    //#endregion

    const form = useForm({
        resolver: yupResolver(schema),
        shouldUnregister: false,
        shouldFocusError: true,
        mode: "all",
    });

    //#endregion

    //#region event
    useEffect(() => {
        GetPermission(5, setPermission)
        setIsActive(isDataActive || false);
        onGetDropdown();
        form.setValue("nSave", "0");
    }, [])

    const onGetDropdown = () => {
        AxiosPost("AdminUserPackage/SetFilter_TableDataPackage", { nTypeTable: 2 },
            (res) => {
                setarrDataType(res.lstOptionOrer || []);
                onGetDetail();
            });
    };
    const onGetDetail = () => {
        AxiosPost("AdminUserPackage/GetDetail_CreatePackage", { sID: sID, nEdit: 2 }, (res) => {
            ResultAPI(res, "", () => {
                if (isEditMode) {
                    let resData = res.objDataPackage;
                    form.setValue("sPackageName", resData.sPackageName);
                    form.setValue("nCoinAmoun", resData.nCoinAmoun);
                    form.setValue("nCoinFree", resData.nCoinFree);
                    form.setValue("nTotalCoin", resData.nTotalCoin);
                    form.setValue("nExDurationAmoun", resData.nExDurationAmoun);
                    form.setValue("nTotalPrice", resData.nTotalPrice);
                    form.setValue("nSave", resData.nSave);
                    form.setValue("nNetPrice", resData.nNetPrice);
                    form.setValue("nVat", resData.nVat);
                    form.setValue("nPriceAfterVat", resData.nPriceAfterVat);
                    form.setValue("dStartDate", resData.dStartDate);
                    //   form.setValue("lstsOrderNo", { label: resData.lstsOrderNo, value: resData.lstsOrderNo });
                    form.setValue("sNote", resData.sNote);
                    form.setValue("nNormalPrice", resData.nNormalPrice)
                    setIsActive(resData.isDataActive || false);
                    nSumnNetPrice();
                }
            });
        });
    }

    const onSubmit = (e) => {
        let nExDurationAmoun = (form.getValues("nExDurationAmoun"));
        let dStartDate = moment(form.getValues("dStartDate"));
        let sEnd = dStartDate.add(nExDurationAmoun, "month");
        form.setValue("dEndDate", sEnd);
        let objSaveData = {
            sPackageName: form.getValues("sPackageName"),
            nCoinAmoun: parseInt(form.getValues("nCoinAmoun")),
            nCoinFree: parseInt(form.getValues("nCoinFree")),
            nTotalCoin: parseInt(form.getValues("nTotalCoin")),
            nExDurationAmoun: parseInt(form.getValues("nExDurationAmoun")), //save in month
            // nExDurationAmoun: parseInt(form.getValues("nExDurationAmoun")) * 29, //save in day
            nTotalPrice: parseFloat(form.getValues("nTotalPrice")),
            nSave: parseFloat(form.getValues("nSave")),
            nNetPrice: parseFloat(form.getValues("nNetPrice")),
            nVat: parseFloat(form.getValues("nVat")),
            nPriceAfterVat: parseFloat(form.getValues("nPriceAfterVat")),

            sNote: form.getValues("sNote"),
            nStartDate: moment(form.getValues("dStartDate")).valueOf(),
            nEndDate: moment(form.getValues("dEndDate")).valueOf(),
            sStartDate: (form.getValues("dStartDate")),
            dEndDate: moment(form.getValues("dEndDate")).toDate(),
            isDataActive: isDataActive,
            nType: 2,
            //   lstsOrderNo: +(form.getValues("lstsOrderNo").value),
            sID: isEditMode ? sID : "",
            nMenuID: 5
        }
        dispatch(DialogActionCreators.OpenDialogSubmit("คุณต้องการบันทึกข้อมูลหรือไม่ ?", () => {
            dispatch(DialogActionCreators.CloseDialogSubmit() as any);
            AxiosPost("AdminUserPackage/SaveData_FromCreatePackage", objSaveData, (result) => {
                ResultAPI(result, "คุณต้องการบันทึกข้อมูลหรือไม่ ?", () => {
                    dispatch(DialogActionCreators.OpenDialogSuccess("บันทึกข้อมูลสำเร็จ",
                        () => onBack()
                    ) as any);
                });
            }, (err) => {
                if (err.Status === 409) {
                    dispatch(DialogActionCreators.OpenDialogWarning("ชื่อแพ็กเกจนี้ถูกใช้งานในระบบแล้ว", () => {
                    }) as any)
                }
            });

        }) as any);
    };

    const nSumnNetPrice = () => {
        let nNetPrice = form.getValues("nNetPrice");
        let nVat = ((nNetPrice * 7) / 100);
        let nPriceAfterVat = nNetPrice + nVat;
        form.setValue("nVat", nVat);
        form.setValue("nPriceAfterVat", nPriceAfterVat);
    }

    const nSumnCoin = () => {
        let nCoin = form.getValues("nCoinAmoun");
        let nCoinFree = form.getValues("nCoinFree");
        let nTotalCoin = nCoin + nCoinFree
        form.setValue("nTotalCoin", nTotalCoin);
    }
    //#endregion

    return (
        ////step 3  การประกาศ form ส่วนที่เรา form Provider มาครอบS
        <FormProvider {...form}>
            <Grid className="FromCreateCoinPackage">
                <Stack spacing={3} direction="column" >
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Input
                                    name={"sPackageName"}
                                    maxLength={500}
                                    required
                                    label={"ชื่อแพ็กเกจ"}
                                    disabled={nPermission !== 2}

                                />
                            </Grid>

                            <Grid item lg={3} xs={12}>
                                <InputNumber
                                    name={"nCoinAmoun"}
                                    required
                                    label={"จำนวน Coin (ตามแพ็กเกจ)"}
                                    disabled={nPermission !== 2}

                                />
                            </Grid>
                            <Grid item lg={3} xs={12}>
                                <InputNumber
                                    name={"nCoinFree"}
                                    required
                                    label={"จำนวน Coin (Free)"}
                                    disabled={nPermission !== 2}
                                    onBlur={nSumnCoin}
                                />
                            </Grid>
                            <Grid item lg={3} xs={12}>
                                <InputNumber
                                    name={"nTotalCoin"}
                                    required
                                    label={"จำนวน Coin"}
                                    disabled

                                />
                            </Grid>
                            <Grid item lg={3} xs={12}>
                                <Input
                                    name={"nExDurationAmoun"}
                                    required
                                    label={"ระยะเวลาหมดอายุ (เดือน) (Period)"}
                                    disabled={nPermission !== 2}

                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={3} >
                            <Grid item lg={3} xs={12}>
                                <InputNumber
                                    name={"nTotalPrice"}
                                    required
                                    label={"ราคาสุทธิ (Special Price)"}
                                    disabled={nPermission !== 2}
                                    decimalPoint={2}
                                />
                            </Grid>
                            <Grid item lg={3} xs={12}>
                                <Input
                                    name={"nSave"}
                                    required
                                    label={"ส่วนลด (% save)"}
                                    disabled={nPermission !== 2}
                                />
                            </Grid>
                            <Grid item lg={3} xs={12}>
                                <InputNumber
                                    name={"nNetPrice"}
                                    required
                                    label={"รวมเป็นเงิน"}
                                    onBlur={nSumnNetPrice}
                                    disabled={nPermission !== 2}
                                    decimalPoint={2}
                                />
                            </Grid>

                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item lg={3} xs={12}>
                                <InputNumber
                                    name={"nVat"}
                                    required
                                    label={"ภาษีมูลค่าเพิ่ม 7% (Vat 7%)"}
                                    disabled={true}
                                    decimalPoint={2}
                                />
                            </Grid>
                            <Grid item lg={3} xs={12}>
                                <InputNumber
                                    name={"nPriceAfterVat"}
                                    required
                                    label={"ราคารวมทั้งสิ้น (Grand Total)"}
                                    disabled={true}
                                    decimalPoint={2}
                                />
                            </Grid>
                            <Grid item lg={3} xs={12}>
                                <DatePickerFormItem
                                    name={"dStartDate"}
                                    label={"วันที่เริ่ม"}
                                    required
                                    disabled={nPermission !== 2}
                                    minDate={StartDate}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Input
                                    name={"sNote"}
                                    rows={3}
                                    multiline
                                    fullWidth
                                    maxLength={3000}
                                    label={"หมายเหตุ"}
                                    disabled={nPermission !== 2}
                                />
                            </Grid>
                        </Grid>

                    </Grid>



                    <Grid item spacing={3} xs={12}>
                        <Typography>
                            <span style={{ fontWeight: 600 }}> สถานะ </span>
                            <span style={{ color: "red" }}> *</span>
                        </Typography>
                        <Typography>
                            <FormControlLabel
                                control={
                                    <IOSSwitch
                                        sx={{ m: 1 }}
                                        onChange={() => setIsActive(!isDataActive)}
                                        checked={isDataActive}
                                        disabled={nPermission !== 2}
                                    />
                                }
                                label={isDataActive ? "ใช้งาน" : "ไม่ใช้งาน"}
                            />
                        </Typography>
                    </Grid>
                    <Grid item spacing={3} xs={12}>
                        <Stack direction="row" spacing={2}>
                            <Box sx={{ flex: 1 }}>
                                <BtnBack
                                    txt='ย้อนกลับ'
                                    onClick={() => onBack()} />
                            </Box>
                            <BtnSave
                                txt='บันทึก'
                                IsDisabled={nPermission !== 2} onClick={form.handleSubmit(e => (
                                    onSubmit(e)
                                ))} />
                        </Stack>
                    </Grid>
                </Stack>
                {/* <Grid item className="table" sx={{ marginTop: "1%", marginBottom: "1%", width: "100%" }}>
                    {isEditMode ? <BPS_CoinPackageLogs /> : null}
                </Grid> */}
                <Grid item spacing={50} sx={{ marginTop: "1%", marginBottom: "1%", width: "100%" }}>
                </Grid>
            </Grid>

        </FormProvider>

    );
}
export default FromCreateCoinPackage
