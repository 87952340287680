import { Card, CardContent, Divider, Grid, Typography } from "@mui/material";
import BPS_GroupTable from "components/BPS/BPS_Group/BPS_GroupTable";
import { initRows, PaginationInterface } from "components/Common/Table/DataGridMui";
import { i18n } from "i18n";
import { useEffect, useState } from "react";
import { AxiosPost } from "service/CommonFunction/TS_function";

function TabsClick(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Group_Table(item) {
  const i18nField = 'entities.AdminMaster';
  const [ShowCard, setCard] = useState([]);
  const [dataRow, setDataRow] = useState<PaginationInterface>({
    ...initRows,
    sSortExpression: "sGroupName",
    sSortDirection: "asc"
  });

  useEffect(() => {
    loadData(dataRow);
  }, []);
  const loadData = (p: PaginationInterface) => {
    AxiosPost("GroupBPS/SearchData_Permission", p, (result) => {
      setDataRow({
        ...p,
        arrRows: result.lstGroup,
        nDataLength: result.nDataLength,
        nPageIndex: result.nPageIndex,
      });
      setCard(result.lstGroup);
    })
  }

  return (
    <Grid container>
      <Grid item className="table" sx={{ mx: "2%", width: "100%" }}>
        <BPS_GroupTable />
      </Grid>
    </Grid>
  );
}