import React, { useEffect, useState } from "react";
import {
  DataGridMui as Table,
  initRows,
  PaginationInterface,
  FilterFieldInterface,
} from "components/Common/Table/DataGridMui";
import { GridColumns } from "@mui/x-data-grid";
import { BtnEditOnTable } from "components/Common/Button";
import { useDispatch } from "react-redux";
import { FormControlLabel, Grid } from "@mui/material";
import {
  AxiosPost,
  GetPermission,
  ResultAPI,
} from "service/CommonFunction/TS_function";
import { DialogActionCreators } from "store/redux/DialogAlert";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { i18n } from "i18n";

export default function AdminCandidateApplyTable() {
  const dispatch = useDispatch();
  const history = useNavigate();
  const i18nField = 'entities.Candidate';
  const location = useLocation();
  const searchParams = location.search;
  const sID = new URLSearchParams(searchParams).get("sID");

  const [nPermission, setPermission] = useState(0);
  const [loadingTable, setLoadingTable] = useState(true);
  const [dataRow, setDataRow] = useState<PaginationInterface>({
    ...initRows,
     sSortExpression: "dPost_Date",
     sSortDirection: "desc",
  });
  //const isHead = nBanner_ID == 6 || nBanner_ID == 8 ? true : false;
  const onBack = () => {
    history(-1);
  };

  const form = useForm({
    shouldUnregister: false,
    shouldFocusError: true,
    mode: "all",
  });

  useEffect(() => {
    // GetPermission(8, setPermission)
    loadData(dataRow);
  }, []);

  const loadData = (p: PaginationInterface) => {
    setLoadingTable(true);
    AxiosPost(
      "AdminCandidate/SearchDataJobFavourite",
      { ...p, sID: sID },
      (result) => {
        setLoadingTable(false);

        setDataRow({
          ...p,
          arrRows: result.lstJobFavourite || [],
          nDataLength: result.nDataLength,
          nPageIndex: result.nPageIndex,
        });
      }
    );
  };

  // filter
  const filter: FilterFieldInterface[] = [
    { sTypeFilterMode: "input", sFieldName: "sNumber_Post" },
    { sTypeFilterMode: "input", sFieldName: "sJob_TH" },
    { sTypeFilterMode: "input", sFieldName: "sCompany_Name" },
    { sTypeFilterMode: "daterange", sFieldName: "sCreate_Date" },
    { sTypeFilterMode: "daterange", sFieldName: "sPost_Date" },
 //   { sTypeFilterMode: "input", sFieldName: "sStatus" },
    {
      sTypeFilterMode: "select", sFieldName: "sStatus", lstDataSelect: [
        { value: "1", keyId: 0, label: i18n(`${i18nField}.ActiveName`) },
        { value: "0", keyId: 1, label: i18n(`${i18nField}.InactiveName`) },
      ],
    },
  ];

  //สร้างตาราง
  const dataColumn: GridColumns = [
    {
      field: "sNumber_Post",
      headerName: "เลขที่ประกาศ",
      headerAlign: "center",
      align: "center",
      resizable: true,
      width: 200,
    },
    {
      field: "sJob_TH",
      headerName: "ตำแหน่งงาน",
      headerAlign: "center",
      align: "left",
      resizable: true,
      flex: 1,
      minWidth: 250,
    },
    {
      field: "sCompany_Name",
      headerName: "ชื่อผู้ประกอบการ",
      headerAlign: "center",
      align: "left",
      resizable: true,
      flex: 1,
      minWidth: 250,
    },
    {
      field: "sCreate_Date",
      headerName: "วันที่สนใจ",
      headerAlign: "center",
      align: "center",
      resizable: true,
      minWidth: 230,
    },
    {
      field: "sPost_Date",
      headerName: "วันที่ลงประกาศ",
      headerAlign: "center",
      align: "center",
      resizable: true,
      minWidth: 230,
    },
    {
      field: "sStatus",
      headerName: "สถานะ",
      headerAlign: "center",
      align: "center",
      resizable: true,
      minWidth: 120,

    }
  ];

  return (
    <Table
      isLoading={loadingTable}
      filterField={filter}
      Rows={dataRow}
      Columns={dataColumn}
      OnLoadData={(p) => loadData(p)}
    //isShowCheckBox={nPermission == 2}
    //onDelete={OnDelete}
    />
  );
}
