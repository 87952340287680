import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BtnBack } from "components/Common/Button";
import { AxiosGet } from "service/CommonFunction/TS_function";
import {
  Divider,
  Grid,
  Stack,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import Content from "../../font/JobDetail/Content";
import AdminCompanyTabPanel from "./AdminCompanyTabPanel";
import { FnBlock_UI } from "service/SystemFunction/SystemFunction";

const AdminCompanyJobDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = location.search;
  const sID = new URLSearchParams(searchParams).get("sID");
  const sJobID = new URLSearchParams(searchParams).get("sJobID");

  const [detail, setDetail] = useState<{ [key: string]: any }>({});
  const { BlockUI, UnBlockUI } = FnBlock_UI();

  const onGetDetail = () => {
    BlockUI();
    AxiosGet("AdminCompany/detail_job", { sID: sID, sJobID: sJobID }, (res) => {
      setDetail(res);
      UnBlockUI();
    });
  };

  const onBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    onGetDetail();
  }, []);

  return (
    <Grid container>
      <Grid item className="table" sx={{ mx: "2%", width: "100%" }}>
        <Stack
          spacing={2}
          width={"100%"}
          sx={{
            marginTop: "2%",
            border: "2px #eeeeee solid",
            backgroundColor: "rgba(255,255,255,0.75)",
            borderRadius: 5,
            p: 3,
          }}
        >
          <AdminCompanyTabPanel tabNo={1} />
          <Stack
            spacing={2}
            width={"100%"}
            sx={{
              marginTop: "2%",
              border: "2px #eeeeee solid",
              backgroundColor: "rgba(255,255,255,0.75)",
              borderRadius: 5,
              p: 3,
            }}
          >

            <Grid item md={12}>
              <Stack direction={"row"} justifyContent="space-between">
                <Typography fontWeight={500} fontSize={18}>
                  ข้อมูลตำแหน่ง
                </Typography>
                <Typography variant="subtitle2" alignItems="end" >
                  ประกาศเมื่อ : {detail.sUpdateDate}
                </Typography>
              </Stack>
              <Stack direction={"row"}  >
                <Divider sx={{ width: "100%", backgroundColor: "#03a9f4" }} />
              </Stack>
            </Grid>

            <Stack direction={"column"} sx={{ mt: "15px !important", gap: "15px" }} >
              <Stack direction={"row"} sx={{ gap: "1em" }}>
                <Stack direction={"column"} justifyContent="center" sx={{ width: "250px", marginLeft: "20px" }}>
                  <Typography fontWeight={500} variant="subtitle1" color={"#03a9f4"}>
                    แพ็กเกจ
                  </Typography>
                </Stack>
                <Stack direction={"column"} flex={1}>
                  <Stack direction={"row"} alignItems="center" sx={{ mt: "0px !important", gap: "5px" }} >
                    <Typography fontWeight={400} variant="subtitle2">{detail.sPackageTypeName} </Typography>
                  </Stack>
                </Stack>
              </Stack>

              <Stack direction={"row"} sx={{ gap: "1em" }}>
                <Stack direction={"column"} justifyContent="center" sx={{ width: "250px", marginLeft: "20px" }}>
                  <Typography fontWeight={500} variant="subtitle1" color={"#03a9f4"}>
                    ชื่องาน
                  </Typography>
                </Stack>
                <Stack direction={"column"} flex={1}>
                  <Stack direction={"row"} alignItems="center" sx={{ mt: "0px !important", gap: "5px" }} >
                    <Typography fontWeight={400} variant="subtitle2">  {detail.sJob_TH} </Typography>
                  </Stack>
                </Stack>
              </Stack>
              <Stack direction={"row"} sx={{ gap: "1em" }}>
                <Stack direction={"column"} justifyContent="start" sx={{ width: "250px", marginLeft: "20px" }}>
                  <Typography fontWeight={500} variant="subtitle1" color={"#03a9f4"}>
                    รายละเอียดงาน
                  </Typography>
                </Stack>
                <Stack direction={"column"} flex={1}>
                  <Stack direction={"row"} alignItems="center" sx={{ mt: "0px !important", gap: "5px" }} >
                    <Typography fontWeight={400} variant="subtitle2">  {detail.lstJobContent != null &&
                      detail.lstJobContent.length > 0 ? (
                      <Content lstContent={detail.lstJobContent} />
                    ) : (
                      ""
                    )} </Typography>
                  </Stack>
                </Stack>
              </Stack>

              <Stack direction={"row"} sx={{ gap: "1em" }}>
                <Stack direction={"column"} justifyContent="center" sx={{ width: "250px", marginLeft: "20px" }}>
                  <Typography fontWeight={500} variant="subtitle1" color={"#03a9f4"}>
                    อีเมลรับเรื่อง
                  </Typography>
                </Stack>
                <Stack direction={"column"} flex={1}>
                  <Stack direction={"row"} alignItems="center" sx={{ mt: "0px !important", gap: "5px" }} >
                    <Typography fontWeight={400} variant="subtitle2">
                      <List>
                        {detail.lstEmail != null && detail.lstEmail.length > 0
                          ? detail.lstEmail.map((item, index) => {
                            return (
                              <ListItem key={index} sx={{ ml: 2, p: 0 }}>
                                <ListItemText
                                  primary={`${index + 1}. ${item.sEmail}`}
                                />
                              </ListItem>
                            );
                          })
                          : null}
                      </List>
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
              <Stack direction={"row"} sx={{ gap: "1em" }}>
                <Stack direction={"column"} justifyContent="start" sx={{ width: "250px", marginLeft: "20px" }}>
                  <Typography fontWeight={500} variant="subtitle1" color={"#03a9f4"}>
                    คุณสมบัติสำคัญของผู้สมัคร 3 ข้อ
                  </Typography>
                </Stack>
                <Stack direction={"column"} flex={1}>
                  <Stack direction={"row"} alignItems="center" sx={{ mt: "0px !important", gap: "5px" }} >
                    <Typography fontWeight={400} variant="subtitle2">  <List>
                      {detail.lstJobPoint != null &&
                        detail.lstJobPoint.length > 0
                        ? detail.lstJobPoint.map((item, index) => {
                          return (
                            <ListItem key={index} sx={{ ml: 2, p: 0 }}>
                              <ListItemText
                                primary={`${index + 1}. ${item.sPoint_Detail
                                  }`}
                              />
                            </ListItem>
                          );
                        })
                        : null}
                    </List> </Typography>
                  </Stack>
                </Stack>
              </Stack>

              <Grid item md={12}>
                <Stack direction={"row"} justifyContent="space-between">
                  <Typography fontWeight={500} fontSize={18}>
                    รายละเอียดตำแหน่ง
                  </Typography>
                </Stack>
                <Stack direction={"row"}  >
                  <Divider sx={{ width: "100%", backgroundColor: "#03a9f4" }} />
                </Stack>
              </Grid>

              <Stack direction={"row"} sx={{ gap: "1em" }}>
                <Stack direction={"column"} justifyContent="center" sx={{ width: "250px", marginLeft: "20px" }}>
                  <Typography fontWeight={500} variant="subtitle1" color={"#03a9f4"}>
                    ประเภทงาน
                  </Typography>
                </Stack>
                <Stack direction={"column"} flex={1}>
                  <Stack direction={"row"} alignItems="center" sx={{ mt: "0px !important", gap: "5px" }} >
                    <Typography fontWeight={400} variant="subtitle2">{detail.lstJobFunction != null &&
                      detail.lstJobFunction.length > 0
                      ? detail.lstJobFunction.join(", ")
                      : "-"} </Typography>
                  </Stack>
                </Stack>
              </Stack>

              <Stack direction={"row"} sx={{ gap: "1em" }}>
                <Stack direction={"column"} justifyContent="center" sx={{ width: "250px", marginLeft: "20px" }}>
                  <Typography fontWeight={500} variant="subtitle1" color={"#03a9f4"}>
                    งาน
                  </Typography>
                </Stack>
                <Stack direction={"column"} flex={1}>
                  <Stack direction={"row"} alignItems="center" sx={{ mt: "0px !important", gap: "5px" }} >
                    <Typography fontWeight={400} variant="subtitle2">
                      {detail.lstJobFunctionDetail != null &&
                        detail.lstJobFunctionDetail.length > 0
                        ? detail.lstJobFunctionDetail.join(", ")
                        : "-"}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
              <Stack direction={"row"} sx={{ gap: "1em" }}>
                <Stack direction={"column"} justifyContent="center" sx={{ width: "250px", marginLeft: "20px" }}>
                  <Typography fontWeight={500} variant="subtitle1" color={"#03a9f4"}>
                    ประเภทอุตสาหกรรม
                  </Typography>
                </Stack>
                <Stack direction={"column"} flex={1}>
                  <Stack direction={"row"} alignItems="center" sx={{ mt: "0px !important", gap: "5px" }} >
                    <Typography fontWeight={400} variant="subtitle2">
                      {detail.sIndustry_TH}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
              <Stack direction={"row"} sx={{ gap: "1em" }}>
                <Stack direction={"column"} justifyContent="center" sx={{ width: "250px", marginLeft: "20px" }}>
                  <Typography fontWeight={500} variant="subtitle1" color={"#03a9f4"}>
                    สถานที่ปฏิบัติงาน
                  </Typography>
                </Stack>
                <Stack direction={"column"} flex={1}>
                  <Stack direction={"row"} alignItems="center" sx={{ mt: "0px !important", gap: "5px" }} >
                    <Typography fontWeight={400} variant="subtitle2">
                      {detail.sLocation}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
              <Stack direction={"row"} sx={{ gap: "1em" }}>
                <Stack direction={"column"} justifyContent="center" sx={{ width: "250px", marginLeft: "20px" }}>
                  <Typography fontWeight={500} variant="subtitle1" color={"#03a9f4"}>
                    การเดินทาง
                  </Typography>
                </Stack>
                <Stack direction={"column"} flex={1}>
                  <Stack direction={"row"} alignItems="center" sx={{ mt: "0px !important", gap: "5px" }} >
                    <Typography fontWeight={400} variant="subtitle2">
                      {detail.sTraveling}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>

              <Stack direction={"row"} sx={{ gap: "1em" }}>
                <Stack direction={"column"} justifyContent="center" sx={{ width: "250px", marginLeft: "20px" }}>
                  <Typography fontWeight={500} variant="subtitle1" color={"#03a9f4"}>
                    ลักษณะการจ้างงาน
                  </Typography>
                </Stack>
                <Stack direction={"column"} flex={1}>
                  <Stack direction={"row"} alignItems="center" sx={{ mt: "0px !important", gap: "5px" }} >
                    <Typography fontWeight={400} variant="subtitle2">
                      {detail.sSalaryType_TH}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
              <Stack direction={"row"} sx={{ gap: "1em" }}>
                <Stack direction={"column"} justifyContent="center" sx={{ width: "250px", marginLeft: "20px" }}>
                  <Typography fontWeight={500} variant="subtitle1" color={"#03a9f4"}>
                    เงินเดือน
                  </Typography>
                </Stack>
                <Stack direction={"column"} flex={1}>
                  <Stack direction={"row"} alignItems="center" sx={{ mt: "0px !important", gap: "5px" }} >
                    <Typography fontWeight={400} variant="subtitle2">
                      {detail.sSalaryRange}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
              <Stack direction={"row"} sx={{ gap: "1em" }}>
                <Stack direction={"column"} justifyContent="center" sx={{ width: "250px", marginLeft: "20px" }}>
                  <Typography fontWeight={500} variant="subtitle1" color={"#03a9f4"}>
                    ระดับปฏิบัติงาน
                  </Typography>
                </Stack>
                <Stack direction={"column"} flex={1}>
                  <Stack direction={"row"} alignItems="center" sx={{ mt: "0px !important", gap: "5px" }} >
                    <Typography fontWeight={400} variant="subtitle2">
                      {detail.lstCareerLevel_TH}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
              <Stack direction={"row"} sx={{ gap: "1em" }}>
                <Stack direction={"column"} justifyContent="center" sx={{ width: "250px", marginLeft: "20px" }}>
                  <Typography fontWeight={500} variant="subtitle1" color={"#03a9f4"}>
                    ระดับการศึกษา (ขั้นต่ำ)
                  </Typography>
                </Stack>
                <Stack direction={"column"} flex={1}>
                  <Stack direction={"row"} alignItems="center" sx={{ mt: "0px !important", gap: "5px" }} >
                    <Typography fontWeight={400} variant="subtitle2">
                      {detail.lstEducation_TH}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>

              <Stack direction={"row"} sx={{ gap: "1em" }}>
                <Stack direction={"column"} justifyContent="center" sx={{ width: "250px", marginLeft: "20px" }}>
                  <Typography fontWeight={500} variant="subtitle1" color={"#03a9f4"}>
                    ประสบการณ์ทำงาน
                  </Typography>
                </Stack>
                <Stack direction={"column"} flex={1}>
                  <Stack direction={"row"} alignItems="center" sx={{ mt: "0px !important", gap: "5px" }} >
                    <Typography fontWeight={400} variant="subtitle2">
                      {detail.sYearsExperience}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
              <Stack direction={"row"} sx={{ gap: "1em" }}>
                <Stack direction={"column"} justifyContent="center" sx={{ width: "250px", marginLeft: "20px" }}>
                  <Typography fontWeight={500} variant="subtitle1" color={"#03a9f4"}>
                    สวัสดิการ
                  </Typography>
                </Stack>
                <Stack direction={"column"} flex={1}>
                  <Stack direction={"row"} alignItems="center" sx={{ mt: "0px !important", gap: "5px" }} >
                    <Typography fontWeight={400} variant="subtitle2">
                      {detail.lstJobBenefit_TH != null &&
                        detail.lstJobBenefit_TH.length > 0
                        ? detail.lstJobBenefit_TH.join(", ")
                        : ""}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>

              <Stack direction={"row"} sx={{ gap: "1em" }}>
                <Stack direction={"column"} justifyContent="center" sx={{ width: "250px", marginLeft: "20px" }}>
                  <Typography fontWeight={500} variant="subtitle1" color={"#03a9f4"}>
                    คำค้นหาที่เกี่ยวข้องกับผู้ประกอบการ
                  </Typography>
                </Stack>
                <Stack direction={"column"} flex={1}>
                  <Stack direction={"row"} alignItems="center" sx={{ mt: "0px !important", gap: "5px" }} >
                    <Typography fontWeight={400} variant="subtitle2">
                      {detail.lstTag != null && detail.lstTag.length > 0
                        ? detail.lstTag.join(", ")
                        : ""}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>

              <Stack direction={"row"} sx={{ gap: "1em" }}>
                <Stack direction={"column"} justifyContent="center" sx={{ width: "250px", marginLeft: "20px" }}>
                  <Typography fontWeight={500} variant="subtitle1" color={"#03a9f4"}>
                    มีใบอนุญาตการทำงานในประเทศ
                  </Typography>
                </Stack>
                <Stack direction={"column"} flex={1}>
                  <Stack direction={"row"} alignItems="center" sx={{ mt: "0px !important", gap: "5px" }} >
                    <Typography fontWeight={400} variant="subtitle2">
                      {detail.sJobAllow}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>

            </Stack>


            <Grid container>
              {/* <Grid item xs={9}>
              <Typography variant="subtitle1">ข้อมูลตำแหน่ง</Typography>
            </Grid>
            <Grid item md={3}>
              <Typography variant="subtitle2" align="right">
                ประกาศเมื่อ : {detail.sUpdateDate}
              </Typography>
            </Grid>
            <Grid item md={12}>
              <Divider />
            </Grid> */}
              <Grid container>
                {/* <Grid item md={12} xs={12}>
                <Grid container>
                  <Grid item md={6} xs={12} sx={{ marginTop: "2%" }}>
                    <Typography variant="subtitle2" color="#03a9f4">
                      แพ็กเกจ
                    </Typography>
                    <Typography variant="subtitle2">
                      {detail.sPackageTypeName}
                    </Typography>
                  </Grid>
                  <Grid item md={6} xs={12} sx={{ marginTop: "2%" }}>
                    <Typography variant="subtitle2" color="#03a9f4">
                      ชื่องาน
                    </Typography>
                    <Typography variant="subtitle2">
                      {detail.sJob_TH}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={12} xs={12}>
                <Grid container>
                  <Grid item md={12} xs={12} sx={{ marginTop: "2%" }}>
                    <Typography variant="subtitle2" color="#03a9f4">
                      รายละเอียดงาน
                    </Typography>
                    <Typography variant="subtitle2">
                      {detail.lstJobContent != null &&
                        detail.lstJobContent.length > 0 ? (
                        <Content lstContent={detail.lstJobContent} />
                      ) : (
                        ""
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid> */}
                {/* <Grid item md={12} xs={12}>
                <Grid container>
                  <Grid item md={12} xs={12} sx={{ marginTop: "2%" }}>
                    <Typography variant="subtitle2" color="#03a9f4">
                      อีเมลรับเรื่อง
                    </Typography>
                    <Typography variant="subtitle2">
                      <List>
                        {detail.lstEmail != null && detail.lstEmail.length > 0
                          ? detail.lstEmail.map((item, index) => {
                            return (
                              <ListItem key={index} sx={{ ml: 2, p: 0 }}>
                                <ListItemText
                                  primary={`${index + 1}. ${item.sEmail}`}
                                />
                              </ListItem>
                            );
                          })
                          : null}
                      </List>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={12} xs={12}>
                <Grid container>
                  <Grid item md={12} xs={12} sx={{ marginTop: "2%" }}>
                    <Typography variant="subtitle2" color="#03a9f4">
                      คุณสมบัติสำคัญของผู้สมัคร 3 ข้อ
                    </Typography>
                    <Typography variant="subtitle2">
                      <List>
                        {detail.lstJobPoint != null &&
                          detail.lstJobPoint.length > 0
                          ? detail.lstJobPoint.map((item, index) => {
                            return (
                              <ListItem key={index} sx={{ ml: 2, p: 0 }}>
                                <ListItemText
                                  primary={`${index + 1}. ${item.sPoint_Detail
                                    }`}
                                />
                              </ListItem>
                            );
                          })
                          : null}
                      </List>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid> */}
              </Grid>
            </Grid>

          </Stack>
          <Grid
            item
            xs={12}
            justifyContent={"start"}
            marginTop={3}
            marginBottom={3}
          >
            <BtnBack 
            txt="ย้อนกลับ"
            onClick={() => onBack()} />
          </Grid>
        </Stack>
      </Grid>
    </Grid>
  );
};
export default AdminCompanyJobDetail;
