import { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Button,
  Step,
  StepLabel,
  Stepper,
} from "@mui/material";
import { StepLineColorConnector } from "components/SeekerProfile/ElementCustom";
import ProfileFirst from "./ProfileFirst";
import ProfileSecond from "./ProfileSecond";
import ProfileThird from "./ProfileThird";
import ProfileProvider from "components/SeekerProfile/Context/ProfileContext";
import { i18n } from "i18n";
import { FnBlock_UI } from "service/SystemFunction/SystemFunction";

const i18nField = "entities.SeekerProfile";
const steps = [
  i18n(`${i18nField}.Step1`),
  i18n(`${i18nField}.Step2`),
  i18n(`${i18nField}.Step3`),
];

const ResgisterSeeker = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set<number>());
  const { BlockUI, UnBlockUI } = FnBlock_UI();

  useEffect(() => {
    UnBlockUI();
  }, [])

  const isStepOptional = (step: number) => {
    return step === 1;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const renderStep = (activeStep: number) => {
    switch (activeStep) {
      case 0:
        return <ProfileFirst onSuccessCreate={handleNext} />;
      case 1:
        return <ProfileSecond onSuccessCreate={handleNext} />;
      case 2:
        return <ProfileThird onSuccessCreate={handleNext} />;
      default:
        return <></>;
    }
  };

  return (
    <ProfileProvider>
      <Box sx={{ width: "100%" }}>
        <Stepper
          sx={{ m: "2%" }}
          activeStep={activeStep}
          connector={<StepLineColorConnector />}
          alternativeLabel
        >
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === steps.length ? (
          <>
          </>
        ) : (
          <>
            {renderStep(activeStep)}
          </>
        )}
      </Box>
    </ProfileProvider>
  );
};

export default ResgisterSeeker;
